// React imports
import { useState, useEffect } from 'react';

// 3rd party
import FileUploader from 'devextreme-react/file-uploader';

// App imports
import { Bar } from '../../base/bar/bar';
import { Loader } from '../../base/loader/loader';
import { Icon, icons } from '../../base/icon/icon';
import { translate } from "../../../utils/translation";
import { helpers } from "../../../utils/helpers";
import { upload as uploadModule } from '../../../modules/upload';

export function ExcelUploader({excelFile, updateExcelFile}) {

    const [uploadQueueId, setUploadQueueId] = useState('');
    const [fileUploaderId, setFileUploaderId] = useState('');
    const [dropZoneId, setDropZoneId] = useState('');
    const [dropZoneRef, setDropZoneRef] = useState('');
    const [loaded, setLoaded] = useState(true)

    useEffect(() => {
        setUploadQueueId(crypto.randomUUID());
        setFileUploaderId(`file-uploader-${crypto.randomUUID()}`);

        const ran = crypto.randomUUID();
        setDropZoneId(`uploader-drop-zone-${ran}`);
        setDropZoneRef(`#uploader-drop-zone-${ran}`);
    }, [])

    const uploadFile = async (file) => {
        setLoaded(false);

        //Check file extensions
        const allowedExtensions = ["xlsx", "xls", "txt", "csv"];
        var regExpresson = new RegExp(".(" + allowedExtensions.join("|") + ")$", "i");
        if (allowedExtensions.length > 0 && !(regExpresson).test(file.name)) {
            alert(translate('file_type_not_allowed'));
            return false;
        }

        //Check file Size
        if (file.size > 10000000) {
            alert(translate('file_size_too_large'));
            return false;
        }

        //Upload file chunk
        var tempFileId = await uploadModule.uploadFileChunk(file, {
            uploadQueueId: uploadQueueId,
            fileId: crypto.randomUUID(),
            contentType: file.type,
        });

        //Add file to queue
        var queueFileId = await uploadModule.addFileToQueueFromTempDirectory({
            uploadQueueId: uploadQueueId,
            fileId: tempFileId,
            fileName: file.name,
            contentType: file.type
        });
        
        var response = await uploadModule.publish({
            uploadQueueId: uploadQueueId,
            data: [],
        });

        if (response != null && response.items != null && response.items.length > 0) {

            /* TBD: validate the file - always throws an error (does this in KLI 1.0 also)
            const taskGUID = crypto.randomUUID();
            var validResults = await uploadModule.validateFromFile({
                taskGUID: taskGUID,
                fileId: response.items[0].resultId,
                fileName: file.name
            });
            */

            var uploadedFile = {
                id: queueFileId,
                tempFileId: tempFileId,
                resultId: response.items[0].resultId,
                name: file.name,
                type: file.type,
                size: file.size
            };
            
            updateExcelFile(uploadedFile);
        }
        else 
            updateExcelFile(null);

        setLoaded(true);

        return queueFileId;
    };

    const deleteFile = async (file) => {
        /*
        uploadModule.deleteFileFromQueue({
            queueFileId: file.id,
            tempFileId: file.tempFileId,
            uploadQueueId: uploadQueueId 
        });
        */

        uploadModule.deleteFile({
            fileId: file.resultId
        });

        updateExcelFile(null);
    }
    
    return <>
        <div className="app-uploader-container">
            <Loader loaded={loaded}></Loader>
            { !loaded ? '' :
                <div className="app-uploader-file-region">
                    { excelFile != null && excelFile.resultId.length > 0 ?
                        <div className='app-option-selector app-tools-bulk-bar-edit'>
                            <div className='app-option-selector-check'>
                                <Icon icon={icons.trash} />
                            </div>
                            <Bar className='app-tools-bulk-bar-buttons app-tools-bulk-bar-medlong'
                                text={`${excelFile.name.length > 30 ? excelFile.name.substring(0, 30).trim() + '...' : excelFile.name}\n\n${helpers.convertFileSize(excelFile.size)}`} 
                                tooltip={translate('delete')}
                                active={true} 
                                onClick={() => {
                                    console.log('delete file');
                                    deleteFile(excelFile); 
                                }} 
                            /> 
                        </div>
                    :
                        <div>
                            <div id={dropZoneId} className="app-uploader-drop-zone">
                                {translate("default_file_drop_zone_message")}
                            </div>
                            <FileUploader
                                id={fileUploaderId}
                                multiple={false}
                                accept={'*'}
                                uploadMode={'instantly'}
                                uploadFile={uploadFile}
                                dialogTrigger={dropZoneRef}
                                dropZone={dropZoneRef}
                                visible={false}
                            />  
                        </div>
                    }
                </div>
            }
        </div>
    </>
}