// React imports
import { useEffect, useState, useCallback, useMemo, useRef, Fragment } from 'react';

// 3rd party
import ReactSlider from 'react-slider';

// App imports
import { errorToast } from '../../../base/toast/toast';
import { activityHub } from '../../activityHub/activityHub';
import { Loader } from '../../../base/loader/loader';
import { Button } from '../../../base/button/button';
import { ConfirmButton } from '../../../base/confirmButton/confirmButton';
import { Bar } from '../../../base/bar/bar';
import { DropDown } from '../../../base/dropDown/dropDown';
import { Clickable } from '../../../base/clickable/clickable';
import { ModalCard } from '../../../base/modalCard/modalCard';
import { Tooltip } from '../../../base/tooltip/tooltip';
import { helpers } from '../../../../utils/helpers';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { constants } from '../../../../utils/constants';
import { trafficMetrix } from '../../../../modules/trafficMetrix';
import { map } from '../../map/map';
import { TMXSavePreset } from './tmxSavePreset';

const _ = require("lodash");

export function TMXForm({ location, onOpen }){

    const [loaded, setLoaded] = useState(false);
    const [tmxResults, setTMXResults] = useState(null);
    const [trafficPresets, setTrafficPresets] = useState([]);
    const [selectedPreset, setSelectedPreset] = useState('');
    const [defaultPreset, setDefaultPreset] = useState('');
    const [sections, setSections] = useState([]);
    const [directionList, setDirectionList] = useState([]);
    const [activeSection, setActiveSection] = useState(0);
    const [hourStart, setHourStart] = useState(0);
    const [hourEnd, setHourEnd] = useState(23);
    const [daysOfTheWeek, setDaysOfTheWeek] = useState([]);
    const [heatMapDirection1, setHeatMapDirection1] = useState([]);
    const [heatMapDirection2, setHeatMapDirection2] = useState([]);
    const [averageDailyTraffic, setAverageDailyTraffic] = useState('');
    const [selectedDailyTraffic1, setSelectedDailyTraffic1] = useState('');
    const [selectedDailyTraffic2, setSelectedDailyTraffic2] = useState('');
    const [savePresetDialog, setSavePresetDialog] = useState(false);

    const presetOptionNone = "99999999-9999-9999-9999-999999999999";

    const directionLetters = useMemo(() => [
        'N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'
    ], []);

    const direction_names = useMemo(() => [
        translate('north'), translate('northeast'), translate('east'), translate('southeast'), translate('south'), translate('southwest'), translate('west'), translate('northwest')
    ], []);

    const day_names = useMemo(() => [
        translate('sunday'), translate('monday'), translate('tuesday'), translate('wednesday'), translate('thursday'), translate('friday'), translate('saturday')
    ], []);

    const day_shorts = useMemo(() => [
        translate('sunday_short'), translate('monday_short'), translate('tuesday_short'), translate('wednesday_short'), translate('thursday_short'), translate('friday_short'), translate('saturday_short')
    ], []);

    const day_mins = useMemo(() => [
        translate('sunday_min'), translate('monday_min'), translate('tuesday_min'), translate('wednesday_min'), translate('thursday_min'), translate('friday_min'), translate('saturday_min')
    ], []);
    
    const preset_none_list = useMemo(() => [
        "None", "لا أحد", "Ninguno", "Aucun", "Tidak ada", "Nessuno", "なし", "tiada", "Nenhum", "Никто"
    ], []);

    const loadPreset = useCallback(
        (o) => {

            const preset = _.find(trafficPresets, function (preset) {
                return preset.id == o.presetId;
            });

            if (!_.isObject(preset))
                return;

            var newWeekDays = [...daysOfTheWeek];

            preset.days.forEach(presetDay => {
                var savedDay = _.find(newWeekDays, function (day) {
                    return day.dayAbrv == presetDay.dayAbrv;
                });

                if (_.isObject(savedDay)) {
                    savedDay.checked = presetDay.checked;
                }
            });

            setHourStart(preset.hours[0]);
            setHourEnd(preset.hours[1]);
            setDaysOfTheWeek(newWeekDays);

    }, [trafficPresets, daysOfTheWeek]);

    const savePreset = useCallback(
        async (o) => {

            if (!o.isNone && preset_none_list.includes(o.preset.name)) {
                errorToast(translate('save_preset_none_error'));
                return;
            }

            setLoaded(false);

            const presets = await trafficMetrix.saveTrafficPreset({ preset: o.preset });

            var presetId = '';

            const preset = _.find(presets, function (preset) {
                return preset.name == o.preset.name;
            });

            if (_.isObject(preset))
                presetId = preset.id;

            await populatePresets({ presets: presets, selected: presetId });

            setLoaded(true);

    }, [populatePresets, preset_none_list]);

    const deletePreset = useCallback(
        async (o) => {

            setLoaded(false);

            const presets = await trafficMetrix.removeTrafficPreset({ presetId: o.preset.id });

            await populatePresets({ presets: presets, selected: '' });

            setLoaded(true);

    }, [populatePresets]);

    const saveDefaultPreset = useCallback(
        async (o) => {

            setLoaded(false);

            const presets = await trafficMetrix.setDefaultTrafficPreset({ presetId: o.presetId, isDefault: o.isDefault });

            await populatePresets({ presets: presets, selected: o.presetId });

            setLoaded(true);

    }, [populatePresets]);

    // declare with var instead of const to avoid "Cannot access 'populatePresets' before initialization" error at runtime
    // because savePreset, deletePreset and saveDefaultPreset call populatePresets (and populatePresets calls save and delete)
    var populatePresets = useCallback(
        async (o) => {

            if (!_.isArray(o.presets) || o.presets.length === 0)
                return;

            var hasNone = false;
            var oldNonePreset = null;
    
            o.presets.forEach(preset => {
                if (preset.id == presetOptionNone) {
                    preset.name = translate('preset_none');
                    hasNone = true;
                }

                if (preset.name == translate('preset_none')) {
                    oldNonePreset = preset;
                }
            });

            if (!hasNone) {
                if (_.isObject(oldNonePreset)) {
                    await deletePreset({ preset: oldNonePreset });
                }
                else {
                    const nonePreset = {
                        id: presetOptionNone,
                        name: translate('preset_none'),
                        isDefault: false,
                        hours: [0, 23],
                        days: [
                            { dayAbrv: day_shorts[0], checked: true },
                            { dayAbrv: day_shorts[1], checked: true },
                            { dayAbrv: day_shorts[2], checked: true },
                            { dayAbrv: day_shorts[3], checked: true },
                            { dayAbrv: day_shorts[4], checked: true },
                            { dayAbrv: day_shorts[5], checked: true },
                            { dayAbrv: day_shorts[6], checked: true }
                        ]
                    };
    
                    await savePreset({ preset: nonePreset, isNone: true });
                }
            }
            else {
                // None Preset
                const noneIndex = _.findIndex(o.presets, function (preset) {
                    return preset.id == presetOptionNone;
                });

                if (noneIndex >= 0) {
                    o.presets.unshift(o.presets.splice(noneIndex, 1)[0]);
                }

                // Default Preset
                var defPreset = _.find(o.presets, function (preset) {
                    return preset.isDefault;
                });

                if (_.isObject(defPreset)) {
                    setDefaultPreset(defPreset.id);
                }
                else {
                    setDefaultPreset('');
                }

                // Selected Preset
                var selPreset;

                if (o.selected.length > 0) {
                    selPreset = _.find(o.presets, function (preset) {
                        return preset.id === o.selected;
                    });
                }

                if (!_.isObject(selPreset) && _.isObject(defPreset)) {
                    selPreset = defPreset;
                }

                if (!_.isObject(selPreset)) {
                    selPreset = _.find(o.presets, function (preset) {
                        return preset.id == presetOptionNone;
                    });
                }

                if (_.isObject(selPreset))
                    setSelectedPreset(selPreset.id);
        
                setTrafficPresets([...o.presets]);
            }

    }, [day_shorts, deletePreset, savePreset]);

    const highlightSegment = useCallback( 
        (o) => {

            const temporaryLayer = map.layers.find(layer => layer.type === constants.layers.types.temporary);
            temporaryLayer.clear();

            var highlights = [];

            const zoom = map.zoom;
            const rangeWidths = [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 4, 6, 8, 10, 12, 14, 14, 14, 14, 14, 14];
            var strokeWidth = 8;
            if (zoom >= 0 && zoom < rangeWidths.length)
                strokeWidth = rangeWidths[zoom];

            _.each(o.lineSegments, function (line) {

                var locations = [];

                _.each(line, function (location) {
                    _.each(location, function (geoPoint) {
                        locations.push({ lat: geoPoint.lat, lon: geoPoint.lon });
                    });
                });

                var segment = temporaryLayer.addEntity({
                    type: constants.entities.polyline,
                    paths: locations,
                    strokeColor: { r: 43, g: 50, b: 140, a: 1.0 }
                });

                segment.strokeWidth = strokeWidth;

                highlights.push(segment);

            });

            return highlights;

    }, []);

    useEffect(() =>{
        (async () => {
    
            if (!_.isObject(location))
                return;

            setLoaded(false);

            const results = await trafficMetrix.getTMXBulkInformation({ latitude: location.lat, longitude: location.lon });

            activityHub.title(results.name.length > 0 ? results.name : '');

            const highlights = highlightSegment({ lineSegments: results.lineSegments });

            setTMXResults(results);

            if (_.isFunction(onOpen))
                onOpen({ highlights: highlights });

        })();
    }, [location, highlightSegment, onOpen]);

    const getTrafficPresets = useCallback(
        async () => {

            const presets = await trafficMetrix.getTrafficPresets();

            var selectedId = '';

            var defPreset = _.find(presets, function (preset) {
                return preset.isDefault;
            });

            var weekDays = [];
            day_shorts.forEach(day => {
                weekDays.push({ dayAbrv: day, checked: true });
            });

            if (_.isObject(defPreset)) {
                selectedId = defPreset.id;

                defPreset.days.forEach(defDay => {
                    var weekDay = _.find(weekDays, function (day) {
                        return day.dayAbrv == defDay.dayAbrv;
                    });
    
                    if (_.isObject(weekDay)) {
                        weekDay.checked = defDay.checked;
                    }
                });

                setHourStart(defPreset.hours[0]);
                setHourEnd(defPreset.hours[1]);
            }

            setDaysOfTheWeek(weekDays);

            await populatePresets({ presets: presets, selected: selectedId });

            setLoaded(true);
            
    }, [populatePresets, day_shorts]);

    useEffect(() =>{
    
        if (tmxResults === null || sections.length > 0)
            return;

        if (tmxResults.information && _.isArray(tmxResults.information.Tabs) && tmxResults.information.Tabs.length > 0 &&
            _.isArray(tmxResults.information.Tabs[0].Pages) && tmxResults.information.Tabs[0].Pages.length > 0 && 
            _.isArray(tmxResults.information.Tabs[0].Pages[0].Sections) && tmxResults.information.Tabs[0].Pages[0].Sections.length > 0) {

            getTrafficPresets();

            setSections([...tmxResults.information.Tabs[0].Pages[0].Sections]);
        }

    }, [tmxResults, sections, getTrafficPresets]);

    useEffect(() =>{
    
        if (tmxResults === null)
            return;

        var directions = [];
        _.some([1, 2], function (direction) {

            var name = direction == 1 ? tmxResults.direction1 : tmxResults.direction2;
            var dirIndex = directionLetters.indexOf(name);
            if (dirIndex >= 0)
                name = direction_names[dirIndex];

            directions.push({
                id: direction,
                name: name,
                isSelected: direction == 1
            })

            if (tmxResults.oneway)
                return true;
        });

        setDirectionList(directions);
    
    }, [tmxResults, directionLetters, direction_names]);
    
    useEffect(() =>{
    
        if (tmxResults === null)
            return;

        const avgDaily = tmxResults.avgDailyTrafficD1 + tmxResults.avgDailyTrafficD2;

        var directions = "";
        _.some([1, 2], function (direction) {

            var dir = "";
            var dirIndex = directionLetters.indexOf(direction == 1 ? tmxResults.direction1 : tmxResults.direction2);
            if (dirIndex >= 0)
                dir = direction_names[dirIndex];

            directions = directions == "" ? dir : (dir == "" ? directions : directions + " + " + dir);

            if (tmxResults.oneway)
                return true;
        });

        setAverageDailyTraffic(`${(directions + " " + translate('avg_daily_traffic')).trim()}: ${Math.round(avgDaily * 24).toLocaleString()}`);

    }, [tmxResults, directionLetters, direction_names]);

    useEffect(() =>{
    
        if (tmxResults === null || sections.length === 0)
            return;

        var heatMapDaysD1 = [];
        var heatMapDaysD2 = [];
        
        sections.forEach((section, s) => {
            section.Rows.forEach((row, r) => {

                var currentHour = 0;
                var dayIndex = -1;
                var dayField = _.find(row.Fields, function (field) {
                    return field.Label.toLowerCase().startsWith("day");
                });

                if (_.isObject(dayField))
                    dayIndex = parseInt(dayField.RenderedValue.value);

                if (dayIndex >= 0) {
                    if (s === 0) {
                        heatMapDaysD1.push({ dayAbrv: day_shorts[dayIndex], hours: [] });
                    }
                    else {
                        heatMapDaysD2.push({ dayAbrv: day_shorts[dayIndex], checked: true, hours: [] });
                    }
                }

                row.Fields.forEach((field, f) => {
                    
                    if (field.Label.toLowerCase().startsWith("hour"))
                        currentHour = field.RenderedValue.value;

                    if (field.Label.toLowerCase().startsWith("d1_traffic") || field.Label.toLowerCase().startsWith("d2_traffic")) {
                        if (s == 0)
                            heatMapDaysD1[heatMapDaysD1.length - 1].hours.push({ hour: currentHour, traffic: field.RenderedValue.value });
                        else
                            heatMapDaysD2[heatMapDaysD2.length - 1].hours.push({ hour: currentHour, traffic: field.RenderedValue.value });
                    }
                });
            });
        });

        setHeatMapDirection1(heatMapDaysD1);
        setHeatMapDirection2(heatMapDaysD2);

    }, [tmxResults, sections, day_shorts]);
    
    useEffect(() =>{

        if (heatMapDirection1.length === 0 && heatMapDirection2.length === 0)
            return;

        var hour1 = hourStart;
        var hour2 = hourEnd;

        _.some([0, 1], function (direction) {

            var dayCount = 0;
            var volume = 0;

            _.each(daysOfTheWeek, function (day) {
                if (day.checked) {
                    dayCount++;

                    var directionDay;

                    if (direction === 0) {
                        directionDay = _.find(heatMapDirection1, function (dir) {
                            return dir.dayAbrv == day.dayAbrv;
                        });
                    }
                    else {
                        directionDay = _.find(heatMapDirection2, function (dir) {
                            return dir.dayAbrv == day.dayAbrv;
                        });
                    }

                    if (directionDay) {
                        _.each(directionDay.hours, function (hour) {
                            if (hour.hour >= hour1 && hour.hour <= hour2)
                                volume += parseFloat(hour.traffic);
                        });
                    }
                }
            });

            var average = dayCount > 0 ? volume / dayCount : 0;

            if (direction === 0)
                setSelectedDailyTraffic1(`${translate('avg_daily_traffic_selected')}: ${Math.round(average).toLocaleString()}`);
            else
                setSelectedDailyTraffic2(`${translate('avg_daily_traffic_selected')}: ${Math.round(average).toLocaleString()}`);
        });
        
    }, [hourStart, hourEnd, daysOfTheWeek, heatMapDirection1, heatMapDirection2]);

    const renderDayOfWeek = useCallback((day) => {

        return <Clickable
            className={'app-tmx-week-day'}
            active={day.checked}
            onClick={(e) => {
                const newDays = daysOfTheWeek.map(newDay => {
                    if (newDay.dayAbrv == day.dayAbrv)
                        return {...newDay, checked: !newDay.checked};
                    else
                        return newDay;
                });

                setDaysOfTheWeek(newDays);
            }}
        >
            <strong>{day.dayAbrv}</strong>
        </Clickable>

    }, [daysOfTheWeek]);

    const renderGridValue = useCallback((o) =>{

        if (!_.isObject(o.field.RenderedValue))
            return '';
        
        const value = Math.round(parseFloat(Intl.NumberFormat("en").format(o.field.RenderedValue.value).replaceAll(",", "")));

        var range = null;
        if (_.isArray(tmxResults.hourlyThemeRanges))
            range = _.find(tmxResults.hourlyThemeRanges, function (rng) {
                return value < rng.RangeValue;
            });

        if (!_.isObject(range))
            range = { Red: 0, Green: 0, Blue: 0, Alpha: 1 };

        const id = `cell_${o.rowNumber}_${o.field.Label}`;

        return <td>
            <div id={id} className='app-tmx-grid-cell' style={{background:`rgb(${range.Red},${range.Green},${range.Blue})`}}></div>
            <Tooltip target={`#${id}`} renderContent={() => { 
                return (
                    <div className='app-tmx-tool-tip'>
                        <div>{o.currentDay} @ {o.currentHour}</div>
                        <div>{value.toLocaleString()} {translate('avg_traffic_vehicles')}</div>
                    </div>
                );
            }} />
        </td>

    }, [tmxResults]);

	return <>
        <Loader className='app-form-loader' loaded={loaded} />
        <table className='app-form-body-table'>
            <tbody>
                <tr>
                    <td>
                        <div className='app-form-content'>
                            <div className='app-form-page'>
                                <div className='app-tmx-avg-daily-traffic'>{averageDailyTraffic}</div>
                                <div className='app-tmx-avg-daily-traffic'><hr/></div>
                                <div className='app-tmx-input-container'>
                                { trafficPresets.length === 0 ? '' :
                                <>
                                    <div className='app-option-selector'>
                                        <Bar className={'app-tmx-default-bar'} 
                                            icon={icons.star}
                                            tooltip={translate('default_preset')}
                                            active={defaultPreset === selectedPreset} 
                                            onClick={() => { saveDefaultPreset({ presetId: selectedPreset, isDefault: defaultPreset !== selectedPreset }); }} 
                                            disabled={false} 
                                        /> 
                                    </div>
                                    <DropDown className='app-preset-drop-down' items={trafficPresets} display='name' valueProperty='id' selected={selectedPreset} 
                                        onChange={(o) => { loadPreset({ presetId: o.value }); setSelectedPreset(o.value); }} 
                                    />
                                    <Button theme={'secondary'} size={'small'} icon={icons.refresh} tooltip={translate('reload_preset')} 
                                        onClick={() => { loadPreset({ presetId: selectedPreset }); }} 
                                    />
                                    <Button theme={'secondary'} size={'small'} icon={icons.save} tooltip={translate('save_preset')} disabled={selectedPreset.length === 0}
                                        onClick={() => { setSavePresetDialog(true); }}
                                    />
                                    <ConfirmButton theme='secondary' size='small' icon={icons.trash} tooltip={translate('delete_preset')} clearText={translate('delete')} disabled={selectedPreset === presetOptionNone}
                                        onConfirm={()=>{ 
                                            const selPreset = _.find(trafficPresets, function (preset) {
                                                return preset.id == selectedPreset;
                                            });
                                    
                                            if (!_.isObject(selPreset) || selPreset.id == presetOptionNone)
                                                return;

                                            deletePreset({ preset: selPreset });
                                        }} 
                                    />
                                </>
                                }
                                </div>
                                <div className='app-tmx-input-container'>
                                    <DropDown className='app-direction-drop-down' items={directionList} width='140px' display='name' valueProperty='id' selected={activeSection + 1} onChange={(o) => { 
                                        setActiveSection(o.value - 1); 
                                    }} />
                                    <div className='app-tmx-labels'>{translate('traffic_hour_selector')}:</div>
                                    <ReactSlider
                                        className="app-slider"
                                        thumbClassName="app-slider-thumb"
                                        trackClassName="app-slider-track"
                                        min={0}
                                        max={23}
                                        step={1}
                                        value={[hourStart, hourEnd]}
                                        ariaLabel={['Start Hour', 'End Hour']}
                                        ariaValuetext={state => `Hour ${state.valueNow}`}
                                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                        pearling
                                        minDistance={0}
                                        onChange={(value) => { setHourStart(value[0]); setHourEnd(value[1]); }}
                                    />
                                </div>
                                <div className='app-tmx-input-container'>
                                { daysOfTheWeek.length === 0 ? '' :
                                    daysOfTheWeek.map((day, d) =>{
                                        return <Fragment key={d}>
                                            { renderDayOfWeek(day) }
                                        </Fragment>
                                    })
                                }
                                </div>
                                <div className='app-tmx-sel-daily-traffic'>{activeSection === 0 ? selectedDailyTraffic1 : selectedDailyTraffic2}</div>
                                { sections.length === 0 ? '' :
                                    sections.map((section, s) =>{
                                        
                                        return <Fragment key={s}>
                                            { activeSection !== s ? '' :
                                            <table className='app-form-content-section app-selectable-text'>
                                                <tbody>
                                                {
                                                    section.Rows.map((row, r) =>{
                                                        var currentHour = 0;
                                                        var dayIndex = -1;
                                                        var dayField = _.find(row.Fields, function (field) {
                                                            return field.Label.toLowerCase().startsWith("day");
                                                        });
                                        
                                                        if (_.isObject(dayField))
                                                            dayIndex = parseInt(dayField.RenderedValue.value);
                                                                    
                                                        return <tr key={r} className={'app-tmx-grid-row'}>
                                                        {
                                                            row.Fields.map((field, f) =>{
                                                            
                                                                if (field.Label.toLowerCase().startsWith("hour"))
                                                                    currentHour = field.RenderedValue.value;

                                                                return <Fragment key={f}>
                                                                    { field.Label.toLowerCase().startsWith("header") || 
                                                                        (field.Label.toLowerCase().startsWith("hour") && r == 0) ?

                                                                        <td><div>{field.RenderedValue.value}</div></td>

                                                                        : field.Label.toLowerCase().startsWith("day") ?

                                                                        <td><div>{day_mins[parseInt(field.RenderedValue.value)]}</div></td>

                                                                        : field.Label.toLowerCase().startsWith("d1_traffic") || 
                                                                            field.Label.toLowerCase().startsWith("d2_traffic") ?

                                                                        renderGridValue({ field: field, rowNumber: r, currentDay: day_names[dayIndex], currentHour: currentHour })

                                                                        : ''
                                                                    }
                                                                </Fragment>;
                                                            })
                                                        }
                                                        </tr>;
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                            }
                                        </Fragment>
                                    })
                                }
                            </div>
                            { !savePresetDialog ? '' :
                            <div className='app-tmx-save-modal' style={{display: savePresetDialog ? 'flex' : 'none' }}>
                                <ModalCard loaded={true} open={savePresetDialog}>
                                    <TMXSavePreset active={savePresetDialog} presetId={selectedPreset} presets={trafficPresets} saveDisabled={selectedPreset === presetOptionNone}
                                        onClose={()=>{ setSavePresetDialog(false); }} 
                                        onSaveAs={(o)=>{ 

                                            const selPreset = _.find(trafficPresets, function (preset) {
                                                return preset.id == selectedPreset;
                                            });
                                    
                                            if (!_.isObject(selPreset)) {
                                                setSavePresetDialog(false); 
                                                return;
                                            }

                                            var preset = _.cloneDeep(selPreset);

                                            preset.id = helpers.emptyGuid();
                                            preset.name = o.name;
                                            preset.isDefault = false;
            
                                            preset.hours[0] = hourStart;
                                            preset.hours[1] = hourEnd;

                                            _.each(preset.days, function (day) {
                                                const curDay = _.find(daysOfTheWeek, function (weekDay) {
                                                    return weekDay.dayAbrv == day.dayAbrv;
                                                });
                                                if (_.isObject(curDay))
                                                    day.checked = curDay.checked;
                                            });                                        

                                            savePreset({ preset: preset, isNone: false });
                                            setSavePresetDialog(false); 

                                        }} 
                                        onSave={(o)=>{

                                            const selPreset = _.find(trafficPresets, function (preset) {
                                                return preset.id == selectedPreset;
                                            });
                                    
                                            if (!_.isObject(selPreset)) {
                                                setSavePresetDialog(false); 
                                                return;
                                            }

                                            var preset = _.cloneDeep(selPreset);

                                            preset.name = o.name;
            
                                            preset.hours[0] = hourStart;
                                            preset.hours[1] = hourEnd;

                                            _.each(preset.days, function (day) {
                                                const curDay = _.find(daysOfTheWeek, function (weekDay) {
                                                    return weekDay.dayAbrv == day.dayAbrv;
                                                });
                                                if (_.isObject(curDay))
                                                    day.checked = curDay.checked;
                                            });                                        

                                            savePreset({ preset: preset, isNone: false });
                                            setSavePresetDialog(false); 

                                        }} 
                                    />
                                </ModalCard>	
                            </div>
                            }
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </>
}  