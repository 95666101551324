// App imports
import { map } from '../components/app/map/map';
import { constants } from '../utils/constants';
import { userPreferences } from '../components/app/app';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { layerActions } from '../components/app/layers/layer/layer';
import { translate } from '../utils/translation';
import { icons } from '../components/base/icon/icon';
import { customerMaps } from './customerMaps';
import { inputBoxTypes, TextBox } from '../components/base/textBox/textBox';
import { Button } from '../components/base/button/button';

const _ = require("lodash");

export const thematics = {
    get: async () =>{
        const themes = await legacyEndpoints.service({ name: 'GetThemeServices' });
		const maps = await customerMaps.get();				
		return [...maps, ...themes];
    },
	getLayers: () =>{
		return map.layers.filter(layer => layer.group === constants.layers.groups.thematic &&
			layer.type !== constants.layers.types.customerMaps.pin && 
			layer.type !== constants.layers.types.customerMaps.desireLine && 
			layer.type !== constants.layers.types.customerMaps.marketShareMap && 
			layer.type !== constants.layers.types.customerMaps.heat
		);
	},
    getSelectedIds: () => {
        return thematics.getLayers().map(layer => {return layer.id;});
    },
	getActiveLayers: () =>{
		return thematics.getLayers().filter(layer => layer.active);
	},
	toggleLayer: (o) =>{		
		o.layer.subText = o.active ? translate('loading') : translate('layer_is_off');
		o.layer.active = o.active;
		o.layer.visible = o.active;
		o.layer.legend = [];
	},
	turnThematicsOff: (o) =>{
		switch(o.type){
			default:
				switch(o.subType){
					default:
						break;
					case constants.layers.types.thematics.subTypes.range:
						thematics.getActiveLayers().filter(layer => layer.subType === o.subType && layer.id !== o.id).forEach(layer =>{
							if (layer.type !== constants.layers.types.thematics.parcel && layer.type !== constants.layers.types.thematics.trafficMetrix)
								thematics.toggleLayer({ layer: layer, active: false });
						});
						break;
					case constants.layers.types.thematics.subTypes.dotDensity:
						if (!userPreferences.AllowMultipleDotDensity)
							thematics.getActiveLayers().filter(layer => layer.subType === o.subType && layer.id !== o.id).forEach(layer =>{
								thematics.toggleLayer({ layer: layer, active: false });
							});
						break;
				}
				break;
			case constants.layers.types.customerMaps.heatMap:
			case constants.layers.types.customerMaps.marketShareMap:
			case constants.layers.types.thematics.parcel:
			case constants.layers.types.thematics.trafficMetrix:
				thematics.getActiveLayers().filter(layer => layer.type === o.type && layer.id !== o.id).forEach(layer =>{
					thematics.toggleLayer({ layer: layer, active: false });
				});
				break;
		}
	},
	useBoundingBoxTiles: (layer) =>{

		if (layer.type === constants.layers.types.thematics.parcel && layer.type === constants.layers.types.thematics.trafficMetrix)
			return false;

		if (layer.type === constants.layers.types.thematics.standardGeography && layer.subType === constants.layers.types.thematics.subTypes.dotDensity)
			return true;

		if (userPreferences.UseBoundingBoxTiles)
			return true;

		return layer.labeled || layer.data.useBoundingBoxTiles || layer.data.hasLabel;
	},
	getDotDensityToolbar: (layer, id, newValue, ignoreOnChange) =>{
		return [{
			actions: [
				<TextBox 
					className={'app-legend-layer-toolbar-input'} 
					height={'24px'} 
					value={ignoreOnChange ? newValue : layer.metaData.pinPointCount}
					type={inputBoxTypes.numeric}
					allowNegative={false}
					allowDecimals={false}
					allowZero={false}
					allowLeadingZeros={false}
					onChange={(options) => { 
						if (options.userChanged && !ignoreOnChange) {

							// When processing a user entered value, replace the current toolbar so it doesn't fire an update
							// and revert the layer back to the old pin point count. We could just set toolbars to an empty array,
							// but by creating this "dummy" toolbar, the UI doesn't flicker and we display the new value.
							layer.toolbars = customerMaps.getCustomerPinToolbar(layer, id, options.value, true);
							
							layer.metaData.pinPointCount = options.value;

							thematics.refresh({ 
								layers: [layer]
							});
						}
					}} 
				/>,
				<Button className='app-legend-layer-toolbar-action' theme='simple' icon={icons.clockRotateLeft} tooltip={translate('reset')} 
					onClick={()=>{
						layer.metaData.pinPointCount = layer.metaData.defaultPinPointCount;

						thematics.refresh({ 
							layers: [layer]
						});
					}} 
				/>
			]
		}];
	},
    refresh: async (o) =>{

        var refreshLayers = o.layers ? o.layers : thematics.getActiveLayers().map((layer) => {
            return{
                id: layer.id,
				text: layer.text,
				type: layer.thematicType,
				subType: layer.thematicSubType,
				pinPointCount: layer.metaData.pinPointCount
            };
        });

        if (refreshLayers.length === 0)
            return;

		var layersToLoad = [];
		var layersToCreate = refreshLayers.filter(x => thematics.getSelectedIds().indexOf(x.id) === -1);

		var refresh = () =>{
			o.onRefresh({ layers: thematics.getLayers() });
		};

		if (layersToCreate.length === 0)
			layersToLoad = thematics.getActiveLayers().filter(activeLayer => refreshLayers.map(refreshLayer => {return refreshLayer.id;}).indexOf(activeLayer.id) !== -1);
		else
			_.reverse(layersToCreate).forEach(layerToCreate =>{

				if (layerToCreate.data.isCustomerMap)
					customerMaps.refreshDynamic({ layer: layerToCreate });
				else {

					thematics.turnThematicsOff({ id: layerToCreate.id, type: layerToCreate.type, subType: layerToCreate.subType});

					var layer = null;

					var layerOptions = {
						id: layerToCreate.id,
						group: constants.layers.groups.thematic,
						type: layerToCreate.type,
						subType: layerToCreate.subType,
						text: layerToCreate.text,
						subText: translate('loading'),
						active: !_.isUndefined(layerToCreate.visible) ? layerToCreate.visible : true,
						visible: !_.isUndefined(layerToCreate.visible) ? layerToCreate.visible : true,
						labeled: (_.isBoolean(layerToCreate.labeled) && layerToCreate.labeled) || (_.isBoolean(layerToCreate.data.isLabeled) && layerToCreate.data.isLabeled),
						actions: [
						{
							id: layerActions.active,
							getActive: () => { return layer.active; },
							onClick: () =>{
	
								thematics.toggleLayer({layer: layer, active: !layer.active });
								thematics.turnThematicsOff({ id: layer.id, type: layer.type, subType: layer.subType});
	
								layer.visible = layer.active;
	
								if (layer.active)
									thematics.refresh({
										onRefresh: o.onRefresh,
										layers:[layer]
									});
								else
									refresh();

								layer.refresh();
							}
						},
						{
							id: layerActions.delete,
							onClick: () =>{
								layer.dispose();
							}
						}],
						onChange: ()=>{
							refresh();
						}
					};

					if (layerToCreate.type === constants.layers.types.thematics.standardGeography && layerToCreate.subType === constants.layers.types.thematics.subTypes.range)
						layerOptions.actions.push({
							id: layerActions.visible,
							getActive: () => { return layer.visible; },
							getHalfActive: () => { return layer.oppositeVisibilityEntities.length > 0; },
							onClick: () =>{
								layer.visible = !layer.visible;
								layer.refresh();
							}
						});

					if (layerToCreate.type === constants.layers.types.thematics.standardGeography && layerToCreate.subType === constants.layers.types.thematics.subTypes.dotDensity) {
						const count = layerToCreate.pinPointCount ? layerToCreate.pinPointCount : -1;
						layerOptions.metaData = { defaultPinPointCount: count, pinPointCount: count };
					}
					else
						layerOptions.metaData = { defaultPinPointCount: -1, pinPointCount: -1 };

					if (layerToCreate.data.hasLabel)
						layerOptions.actions.push({
							id: layerActions.label,
							getActive: () => { return layer.labeled; },
							onClick: () => {
								layer.labeled = !layer.labeled;
								layer.refresh();
							}
						});

					var skipTile = (layer) =>{
						return (!layer.visible && layer.oppositeVisibilityEntities.length === 0) || !layer.active || layer.outOfRange;
					};
					
					if (thematics.useBoundingBoxTiles(layerToCreate))
						layerOptions.imageUrl = async ({ bounds, zoom, layer }) =>{

							if (skipTile(layer))
								return '';

							return legacyEndpoints.handlers.getTileBoundingBoxUrl({
								id: layer.id,
								upperLeftLat: bounds.northWest.lat,
								upperLeftLon: bounds.northWest.lon,
								lowerRightLat: bounds.southEast.lat,
								lowerRightLon: bounds.southEast.lon,
								zoom: zoom,
								allowLabels: layer.labeled,
								hiddenRanges: layer.oppositeVisibilityEntities,
								pinPointCount: layer.metaData.pinPointCount
							})
						};
					else
						layerOptions.tileUrl = ({ quadkey, layer }) =>{

							if (skipTile(layer))
								return '';

							return legacyEndpoints.handlers.getTileUrl({ id: layer.id, quadkey: quadkey, hiddenRanges: layer.oppositeVisibilityEntities });
						};

					layer = map.addLayer(layerOptions);
					
					layersToLoad.push(layer);
				}
			});

		layersToLoad.forEach(layer =>{

			const getEntityVisibility = (groupId) =>{ return layer.oppositeVisibilityEntities.indexOf(groupId) > -1 ? !layer.visible : layer.visible; };

			layer.loading = layer.active;

			legacyEndpoints.service({
				name: 'GetThemeLegend',
				parameters: {
					aThemeId: layer.id,
					aZoom: map.zoom,
					aPinPointCount: layer.metaData.pinPointCount
				},
				success: (thematic) =>{
					
					var layer = thematics.getLayers().find(layer => { return layer.id === thematic.Id });

					// if we requested the default pin point count (-1), save the actual pin point count
					// so we can restore it when the user clicks the default button
					if (layer.metaData.pinPointCount === -1)
						layer.metaData.defaultPinPointCount = thematic.PinPointCount;

					layer.metaData.pinPointCount = thematic.PinPointCount;

					if (!layer.active)
						return;
					
					layer.loading = false;
					layer.subText = thematic.SubTitle;
					layer.outOfRange = thematic.IsOutOfRange;
					layer.refresh();

					if (layer.type === constants.layers.types.thematics.standardGeography && layer.subType === constants.layers.types.thematics.subTypes.dotDensity) {
						layer.toolbars = thematics.getDotDensityToolbar(layer, layer.id, -1, false);
					}

					if (_.isArray(layer.legend) && layer.legend.length > 0 && _.isArray(thematic.LegendItems) && thematic.LegendItems.length > 0 && layer.oppositeVisibilityEntities.length > 0){
						var oppositeVisibilityEntities = [];

						thematic.LegendItems.forEach((newLegendItem, i) => {

							if (layer.legend.length <= i)
								return;

							if (layer.oppositeVisibilityEntities.indexOf(layer.legend[i].id) === -1)
								return;

							oppositeVisibilityEntities.push(newLegendItem.Sequence);							
						});

						if (oppositeVisibilityEntities.length > 0)
							layer.oppositeVisibilityEntities = oppositeVisibilityEntities;
					}

					layer.legend = thematic.LegendItems?.map(legend =>{

						var actions = [];
						
						if ((layer.type === constants.layers.types.thematics.standardGeography && layer.subType === constants.layers.types.thematics.subTypes.range) ||
							(layer.type === constants.layers.types.thematics.sqlDataLayer && layer.subType === constants.layers.types.thematics.subTypes.polygon))
							actions.push({
								id: layerActions.visible,
								getActive: () => { return getEntityVisibility(legend.Sequence); },
								onClick: () =>{
	
									if (layer.oppositeVisibilityEntities.indexOf(legend.Sequence) > -1)
										layer.oppositeVisibilityEntities = layer.oppositeVisibilityEntities.filter(x => x !== legend.Sequence);
									else
										layer.oppositeVisibilityEntities.push(legend.Sequence);

									if (layer.visible === false && layer.oppositeVisibilityEntities.length > 0){
										
										var newRanges = [];
										thematic.LegendItems.forEach(range =>{
											if (layer.oppositeVisibilityEntities.indexOf(range.Sequence) === -1)
												newRanges.push(range.Sequence); 
										});

										layer.visible = true;
										layer.oppositeVisibilityEntities = newRanges;
									}

									layer.refresh();
								}
							});

						return {
							id: legend.Sequence,
							text: legend.LegendText,
							icon: legend.LegendSymbol ? legacyEndpoints.handlers.getSymbolUrl({ imageUrl: legend.LegendSymbol, symbolType: 0 }) : '',
							color: legend.HTMLColor,
							iconWidth: legend.SymbolWidth,
							actions: actions
						};
					});
					
					if (!layer.legend)
						layer.legend = [];

					if (!layer.outOfRange && layer.legend.length === 0)
						layer.legend = [{
							icon: icons.infoCircle,
							text: translate('no_legend_available')
						}];
				}
			});
		});
    }
};