// React imports
import { useState } from 'react';

// App imports
import { Bar } from '../../../base/bar/bar';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { Panel } from '../../../base/panel/panel';
import { ciFilter } from '../mobilityData';
import { competitiveInsights } from '../../../../modules/competitiveInsights';
import { CustomChannels } from './customChannels/customChannels';
import { CustomChannelEditor } from './customChannels/customChannelEditor';

const _ = require("lodash");

export function CompetitiveInsights({open, onClose}) {
    
    const [_open, setOpen] = useState(open);
    const [activePanel, setActivePanel] = useState(null);
    const [additionalData, setAdditionalData] = useState(null);

    function setActivePanelBridge(o) {
        if (_.isEmpty(o)) {
            setActivePanel(null);
            setAdditionalData({});
        }
        else {
            setActivePanel(_.isNumber(o.panelId) ? o.panelId : null);
            setAdditionalData(_.isEmpty(o.additionalData) ? {} : o.additionalData);
        }
    }
        
    const close = () =>{
        setOpen(false);
        onClose();
    };

	return <Panel text={translate('competitive_insights')} open={_open} onClose={() => { close(); }}>
        <Bar text={translate("open")} icon={icons.folderOpen} onClick={() => {
            competitiveInsights.open();
        }} />
        <Bar text={translate("channel_filter")} icon={icons.filter} onClick={() => {
            close();
            ciFilter.show();
        }} />
        <Bar text={translate("custom_channels")} icon={icons.store} onClick={() => { setActivePanelBridge({ panelId: 1 }); }} />
        {(activePanel === 1 ? <CustomChannels text={translate("custom_channels")} setPanelContents={setActivePanelBridge} onClose={() => { setActivePanelBridge(null); }}/> : '')}
        {(activePanel === 2 ? <CustomChannelEditor 
            customChannelId={additionalData.id}
            text={translate("custom_channels")} 
            setPanelContents={setActivePanelBridge} 
            onClose={() => { setActivePanelBridge({panelId: 1}); }}
        /> : ''
        )}                
    </Panel>
}