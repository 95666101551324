// React imports
import { useEffect, useState } from 'react';

// Third party imports
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

// App imports
import { legacyEndpoints } from "../../../services/legacyEndpoints";
import { Loader } from '../../base/loader/loader';
import { DropDown } from '../../base/dropDown/dropDown';
import { translate } from '../../../utils/translation';

export function Dashboards({visible}) {
    
    const [dashboards, setDashboards] = useState([]);
    const [dashboard, setDashboard] = useState(null);
    const [embedInfo, setEmbedInfo] = useState(null);
    //const [report, setReport] = useState(null);
    const [dashboardsLoaded, setDashboardsLoaded] = useState(false);
    const [dashboardLoaded, setDashboardLoaded] = useState(false);

    useEffect(()=>{

        if (visible && dashboards.length === 0)
            setDashboardsDropdown();

    }, [visible, dashboards.length]);

    const setDashboardsDropdown = async ()=>{

        setDashboardsLoaded(false);

        var dbs = await legacyEndpoints.service({
            name: 'GetESitePowerBIDashboards'
        });

        setDashboards(dbs);
        setDashboardsLoaded(true);
    };

    useEffect(()=>{

        if (dashboards.length === 0)
            return;

        setDashboard(dashboards[0]);
        setPowerBiReport(dashboards[0]);

    }, [dashboards]);

    const setPowerBiReport = async (o)=>{
        
        setDashboardLoaded(false);

        var ei = await legacyEndpoints.service({
            name: 'GetESitePowerBIEmbedInfo',
            parameters: {
                workspaceId: o.workspaceId,
                reportId: o.reportId
            }
        });

        setEmbedInfo(ei);
        setDashboardLoaded(true);
    };

    return <>
        <Loader loaded={dashboardsLoaded} />
        <div className='app-dashboards'>
            {!dashboardsLoaded || dashboard === null ? '' : 
                <DropDown 
                    className='app-dashboards-dropdown' 
                    items={dashboards} 
                    selected={dashboard.reportId}
                    display='name' 
                    valueProperty="reportId" 
                    label={translate('dashboards')}
                    onChange={(o) => { 
                        var db = dashboards.find(x => x.reportId === o.value);
                        if (db !== undefined) {
                            setDashboard(db);
                            setPowerBiReport(db);
                        }
                    }}
                />
            }
            {!dashboardLoaded || embedInfo === null ? '' : 
               <PowerBIEmbed
                    embedConfig = {{
                        type: 'report',   // Supported types: report, dashboard, tile, visual, and qna.
                        id: embedInfo.reportId,
                        embedUrl: embedInfo.embedUrl,
                        accessToken: embedInfo.accessToken,
                        tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                }
                            },
                            background: models.BackgroundType.Default,
                        }
                    }}

                    eventHandlers = {
                        new Map([
                            ['loaded', function () { 
                                //console.log('Report Loaded'); 
                            }],
                            ['rendered', function () { 
                                //console.log('Report Rendered'); 
                            }],
                            ['error', function (event) { 
                                console.log(event.detail); 
                            }]
                        ])
                    }

                    cssClassName = { "app-dashboards-powerbi" }

                    getEmbeddedComponent = { (embeddedReport) => {
                        //setReport(embeddedReport);
                    }}
                />
            }
        </div>
    </>;
}