// React imports
import { useEffect, useState, useRef, useCallback } from 'react';

// App imports
import { Hideable } from '../../base/hideable/hideable';
import { ConfirmButton } from "../../base/confirmButton/confirmButton";
import { Button } from '../../base/button/button';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { helpers } from '../../../utils/helpers';
import { trip2Trade as trip2TradeModule } from '../../../modules/trip2Trade';
import { Trip2TradeJob } from './trip2TradeJob/trip2TradeJob';
import { Icon } from '../../base/icon/icon';
import { RendererWizard } from './rendererWizard/rendererWizard';
import { Loader } from '../../base/loader/loader';
import { DataList } from '../../base/dataList/dataList'
import { constants } from '../../../utils/constants';
import { navigation } from '../navigation/navigation';
import { successToast } from '../../base/toast/toast';

const _ = require('lodash');

export function Trip2Trade({entity,hide}){

    const [loaded, setLoaded] = useState(false);
    const [updating, setUpdating] = useState(false);
    const trip2TradeJobId = useRef(null);
    const [isEdit, setIsEdit] = useState(true);
    const [trip2TradeJob, setTrip2TradeJob] = useState(null);
    const [hideTrip2TradeJobStatus, setHideTrip2TradeJobStatus] = useState(false);
    const [hideTrip2TradeJob, setHideTrip2TradeJob] = useState(true);
    const [newRenderer, setNewRenderer] = useState(null);
    const [hideCreateRendererWizard, setHideCreateRendererWizard] = useState(true);
    const [selectedRenderers, setSelectedRenderers] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [jobStatus, setJobStatus] = useState(null);
    const [isJobComplete, setIsJobComplete] = useState(false);
    const [autoLoadRenderers, setAutoLoadRenderers] = useState([]);

    useEffect(() => {
        return () => {
            navigation.unsubscribeFromNotifications({id: trip2TradeJobId.current});
        };
    }, []);

    var getTrip2TradeJobById = async () =>{
        var data = await trip2TradeModule.get({ aServiceID: entity.layer.parentId, sPointID:  entity.id })

        if (data) {        
            var jobs = await trip2TradeModule.getTrip2TradeJobs(data.results[0].id);
            return jobs[0];
        }
        else
            return null;
    };

    const loadTrip2TradeJob = useCallback(
        async () =>{
            setLoaded(false);

            const job = await getTrip2TradeJobById();

            setHideTrip2TradeJobStatus(job !== null);

            if (job !== null) {                                   
                setTrip2TradeJob(job);
                setJobStatus(job.status);
                setIsEdit(true);

                if (job.Status === constants.jobs.statuses.completed) {
                    setIsJobComplete(true);                   
                } 
                else {
                    trip2TradeJobId.current = job.schedulerJobId;
                    subscribeToNotifications(job.schedulerJobId);
                }

                updatedSelectedRenderersFromLayers(job);  
            } 

            setLoaded(true);
        },
        [entity]
    );

    useEffect(() =>{
        loadTrip2TradeJob();
    }, [loadTrip2TradeJob]);    

    var showCreateTrip2TradeJob = () =>{
        setHideTrip2TradeJob(false);
    };

    var editTrip2TradeJob = async () =>{
        setHideTrip2TradeJob(false);
    };

    var closeTrip2TradeJob = () =>{ 
        setHideTrip2TradeJob(true);
    };   
    
    var closeCreateRendererWizard = () =>{ 
        setHideCreateRendererWizard(true);
    }; 

    const updateRenderer = async (o) =>{
        setNewRenderer(o);
    };

    const subscribeToNotifications = (jobId) => {
        navigation.subscribeToNotifications(
            {
                id: jobId,
                async action(notifications) {
                    const t2tNotifications = notifications.Jobs.filter(x => (x.CustomTaskType === constants.jobs.types.customTypes.trip2Trade));
                    var notification = t2tNotifications?.find(x => x.Id === jobId);
        
                    if (_.isObject(notification)) {

                        setJobStatus(notification.Status);

                        if (notification.Status === constants.jobs.statuses.completed) {
                            setIsEdit(true);
                            setIsJobComplete(true);
                            
                            var autoLoadJob = getAutoLoadJob();
                            if (autoLoadJob) {
                                successToast(`${translate('complete_job')}:${notification.Name}`);
                            }

                            const trip2TradeJob = await getTrip2TradeJobById();
                            if(trip2TradeJob) {
                                setTrip2TradeJob(trip2TradeJob);
                                setHideTrip2TradeJobStatus(true);
                            }

                            navigation.unsubscribeFromNotifications({id: jobId});
                        }            
                    }                
                }
            }
        );                 
    };

    var saveTrip2TradeJob = async (o) =>{
        setUpdating(true);

        if (o.id !== helpers.emptyGuid()) {
            await trip2TradeModule.updateTrip2TradeJob(o);
        } else {
            setIsEdit(false)

            o.renderers = [trip2TradeModule.getDefaultRenderer()];

            setTrip2TradeJob(o);
            setAutoLoadRenderers(_.cloneDeep(o.renderers.map(x => x.name)));              

            trip2TradeModule.data.autoLoadJobs.push({id: helpers.emptyGuid(), pointId: o.pointId, renderers: _.cloneDeep(o.renderers.map(x => x.name))});
        }

        closeTrip2TradeJob();
        setHideTrip2TradeJobStatus(true);
        setUpdating(false);
    }; 

    var processNewTrip2TradeJob = async () =>{
        var newTrip2TradeJob = _.cloneDeep(trip2TradeJob);

        newTrip2TradeJob.isEdit = false;
        newTrip2TradeJob.dataSourceId = entity.layer.parentId;
        newTrip2TradeJob.sourceId = entity.id;
        newTrip2TradeJob.sourceLabel = entity.text;
        newTrip2TradeJob.points.push(entity.location); 
     
        await createTrip2TradeJob(newTrip2TradeJob);
    };    

    var getAutoLoadJob = () => {   
        return trip2TradeModule.data.autoLoadJobs.find(x => x.pointId === entity.id)
    }
    
    var createTrip2TradeJob = async (trip2TradeJob) =>{

        setJobStatus(constants.jobs.statuses.processing);

        var result = await trip2TradeModule.createTrip2TradeJob(trip2TradeJob);

        trip2TradeJobId.current = result;
        subscribeToNotifications(result);
        setIsJobComplete(false);

        var autoLoadJob = getAutoLoadJob();
        if (autoLoadJob)
            autoLoadJob.id = result
    };    
    
    var deleteTrip2TradeJob = async () =>{
        setUpdating(true);

        await trip2TradeModule.deleteTrip2TradeJob(trip2TradeJob.id);
        removeRendererFromLayers(trip2TradeJob.renderers);
        setHideTrip2TradeJobStatus(false);
        setTrip2TradeJob(null);

        setUpdating(false);
    };

    var deleteRenderer = async (renderer) =>{ 
        setUpdating(true);

        var updatedTrip2TradeJob = _.cloneDeep(trip2TradeJob);

        updatedTrip2TradeJob.renderers = updatedTrip2TradeJob.renderers.filter(x => x.name !== renderer.name); //todo using name since id is not set for new renderers

        setTrip2TradeJob(_.cloneDeep(updatedTrip2TradeJob));

        if(trip2TradeJob.id !== helpers.emptyGuid()) {
            await trip2TradeModule.updateTrip2TradeJob(updatedTrip2TradeJob);
            removeRendererFromLayers([renderer]);
        }

        setUpdating(false);
    };    

    var removeRendererFromLayers = async (renderers) =>{
        renderers.forEach(renderer => {            
            var layerToRemove = trip2TradeModule.getLayers().find(item => item.id === renderer.id);
            if (layerToRemove)
                layerToRemove.dispose();
        });
    };

    var saveRenderer = async () =>{ 
        setUpdating(true);

        if (trip2TradeJob.id !== helpers.emptyGuid()) {
            var trip2TradeJobCopy = _.cloneDeep(trip2TradeJob);

            var rendererToUpdate = trip2TradeJobCopy.renderers.find(r => r.name === newRenderer.name);
    
            if (_.isObject(rendererToUpdate)) {
                trip2TradeJobCopy.renderers = trip2TradeJobCopy.renderers.map((renderer) => renderer.id === newRenderer.id ? newRenderer : renderer);
            } else {
                trip2TradeJobCopy.renderers.push(newRenderer);
            }

            var result = await trip2TradeModule.updateTrip2TradeJob(trip2TradeJobCopy);
            setTrip2TradeJob(_.cloneDeep(result));

            var layer = trip2TradeModule.getLayers().find(layer => layer.id === newRenderer.id);
            if (layer) {
                await layer.dispose();
                await trip2TradeModule.showLayers([newRenderer.id]);
            }            
        }
        else {
            var newJobRenderer = trip2TradeJob.renderers.find(r => r.tempId === newRenderer.tempId);
            
            if (_.isObject(newJobRenderer)) {
                setTrip2TradeJob(prevState => ({
                    ...prevState,
                    renderers: prevState.renderers.map(renderer =>
                      renderer.id === newRenderer.id ? { ...renderer, ...newRenderer } : renderer
                    )
                    }));
            } else {
                setTrip2TradeJob({ ...trip2TradeJob, renderers: [ ...trip2TradeJob.renderers, _.cloneDeep(newRenderer) ] });
                            
        }}

        closeCreateRendererWizard();
        setUpdating(false);        
    };           
    
    var createRenderer = () =>{
        var renderer = trip2TradeModule.getDefaultRenderer();
        renderer.name = '';
        renderer.description = '';
        
        setNewRenderer(renderer);

        setHideCreateRendererWizard(false);
    };  

    var editRenderer = (id) =>{ 
        var renderer = trip2TradeJob?.renderers.find(r => r.id === id);

        if(renderer) {
            setNewRenderer(renderer);    
            setHideCreateRendererWizard(false);        
        }
    };  

    var updatedSelectedRenderersFromLayers = (o) => {
        var items = [];
        const layers = trip2TradeModule.getLayers();
        layers.forEach(layer => {
            const foundLayer = o.renderers.find(x => x.id === layer.id);
            if (foundLayer)
                items.push(foundLayer.id)
        });
        setSelectedItems(items);
    };

    var showRenderers = async () =>{ 
        setUpdating(true);

        if (trip2TradeJob.id !== helpers.emptyGuid()) {
            var layersNoLongerSelectedForLocation = trip2TradeModule.getLayers().filter(layer => trip2TradeJob.renderers.some(x => x.id === layer.id ) && !selectedRenderers.some(rendererId => rendererId === layer.id))
    
            layersNoLongerSelectedForLocation.forEach(layer => {
                layer.dispose();
            });
    
            if (selectedRenderers.length > 0) {
                await trip2TradeModule.showLayers(selectedRenderers);
            }
        }
        else {           
            processNewTrip2TradeJob();
            setHideTrip2TradeJobStatus(false);
        }

        setUpdating(false);
    };    

    const updateAutoLoadJobs = (rendererName) => {
        const autoLoadJob = getAutoLoadJob();

        if (autoLoadJob.renderers.includes(rendererName)) {
            autoLoadJob.renderers = autoLoadJob.renderers.filter(r => r !== rendererName);
            setAutoLoadRenderers(autoLoadRenderers.filter(x => x !== rendererName)) 
        } 
        else {
            setAutoLoadRenderers([rendererName])
            autoLoadJob.renderers = [rendererName];
        }      

    }

    const deleteFailedTrip2TradeJob = async () => {
        deleteTrip2TradeJob();
    }

    const reprocessFailedTrip2TradeJob = async () => {
        const copyTrip2TradeJob = _.cloneDeep(trip2TradeJob);
        navigation.unsubscribeFromNotifications({id: trip2TradeJobId.current});

        await trip2TradeModule.deleteTrip2TradeJob(trip2TradeJobId.current);
        setHideTrip2TradeJobStatus(false);

        copyTrip2TradeJob.name = 'retry' + _.random(1, 1000);
        setTrip2TradeJob(copyTrip2TradeJob);
        createTrip2TradeJob(copyTrip2TradeJob);
    }

    const headerActionsSection = () =>{
         
        if (trip2TradeJob === null) {
            return <Button className='app-trip-2-trade-actions-button' theme='primary' size='small' icon={icons.plus} tooltip={translate('add')} onClick={() =>{ showCreateTrip2TradeJob() }}/>;
        }
        else if (jobStatus !== constants.jobs.statuses.processing) {
            return <>
                <Button className='app-trip-2-trade-actions-button' theme='primary' size='small' icon={icons.edit} tooltip={translate('edit')} onClick={() =>{ editTrip2TradeJob() }}/>         
                <Button className='app-trip-2-trade-actions-button' theme='secondary' size='small' icon={icons.trash} tooltip={translate('delete')} onClick={() =>{ deleteTrip2TradeJob() }}/>
            </>
        }
    };     

    const jobStatusSection = () =>{
        
            if (trip2TradeJob === null) {
                return translate('create_trip_2_trade_job')
            } else if (jobStatus === constants.jobs.statuses.processing) {
                return <div>
                            <span className='app-trip-2-trade-job-status'>{translate('processing_job')}</span>
                            <Loader theme='generate' loaded={false} />
                        </div>
            }  else if (jobStatus === constants.jobs.statuses.failed) {
                return <div>
                            {translate('job_failed')}
                            <Button className='app-trip-2-trade-job-status-actions' theme='secondary' size='small' icon={icons.trash} tooltip={translate('delete')} onClick={() =>{ deleteFailedTrip2TradeJob() }}/>
                            <Button className='app-trip-2-trade-job-status-actions' theme='secondary' size='small' icon={icons.rotate} tooltip={translate('retry')} onClick={() =>{ reprocessFailedTrip2TradeJob() }}/>
                        </div>                
            }  else if (jobStatus === constants.jobs.statuses.completed) {
                return <div>
                            {translate('complete_job')}
                        </div>                
            }
    };      

    const rendererDataListRow = (renderer) =>{

        return <div key={renderer.id}>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Icon className={'app-trip-2-trade-renderers-icon'} icon={renderer.gridType === 0 ? icons.hexagon : icons.grid}  /> 
                                </td>
                                <td className='app-trip-2-trade-renderers-name'>
                                    {renderer.name} 
                                </td>
                                <td className='app-trip-2-trade-renderers-actions'> 
                                    <div className='app-trip-2-trade-renderers-actions-buttons'>                                        
                                        {isEdit === true  ? '' : 
                                            <Button className='app-trip-2-trade-renderers-actions-button' theme='simple' text={translate('auto_load')}
                                                icon={autoLoadRenderers.includes(renderer.name) ? icons.toggleOn : icons.toggleOff}                                             
                                                onClick={() =>{ updateAutoLoadJobs(renderer.name); }}/>
                                        }
                                        <Button className='app-trip-2-trade-renderers-actions-button' theme='simple' icon={icons.edit} tooltip={translate('edit')} 
                                            onClick={() =>{ editRenderer(renderer.id) }}
                                        />
                                        <ConfirmButton className='app-trip-2-trade-renderers-actions-button'  align='right' theme='simple' disabled={trip2TradeJob?.renderers.length === 1} icon={icons.trash} tooltip={translate('delete')} clearText={translate('delete')} 
                                            onConfirm={()=>{ deleteRenderer(renderer) }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>        
    };         
       
	return <>
        <Hideable className='app-element' hide={hide}> 
            <Loader theme='generate' loaded={!updating} />
            <Loader loaded={loaded} />            
            <Hideable className='app-trip-2-trade' hide={!hideTrip2TradeJob}>
                <div className='app-trip-2-trade-header'>      
                    <img className='app-trip-2-trade-actions-logo' src="https://cdn.tradeareasystems.net/Images/Components/Misc/Trip2TradeLogo.png" alt='' />
                    {
                        headerActionsSection()          
                    }
                </div>  
                <div className='app-element'>
                {                       
                    !hideTrip2TradeJobStatus ? <div className='app-trip-2-trade-renderers-empty'>
                        {jobStatusSection()}                        
                    </div> :
                        <div className='app-trip-2-trade-job-container'>                                
                            <table className='app-trip-2-trade-job-detail'>
                            <tbody>
                                <tr>
                                    <td>
                                        <ul className='app-trip-2-trade-job-detail-layout'>
                                            <li><label>{translate('name')}</label><div>{trip2TradeJob.name}</div></li>
                                            <li><label>{translate('description')}</label><div>{trip2TradeJob.description}</div></li>
                                            { isEdit ? <li><label>{translate('created')}</label><div>{trip2TradeJob.createdBy.Name} </div></li> : '' }
                                        </ul>  
                                    </td>
                                    <td>
                                        <ul className='app-trip-2-trade-job-detail-layout'>
                                            <li><label>{translate('radius')}</label><div>{trip2TradeJob.radius}</div></li>
                                            <li><label>{translate('trip_type')}</label><div>{trip2TradeModule.getTripTypes().find(t => t.id === trip2TradeJob.tripType)?.name}</div></li> 
                                            { isEdit ? <li><label>{translate('modified')}</label><div>{helpers.formatDate({ date: helpers.convertJSONDate(trip2TradeJob.dateModified), format: 'MM/DD/YYYY h:mm a' })} </div></li> : '' }                                            
                                        </ul>                                              
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div className='app-trip-2-trade-renderers-search'>
                                <div className='app-trip-2-trade-renderers-create'>
                                    <Button theme='primary' size='small' className='app-trip-2-trade-actions-button' icon={icons.check} 
                                            tooltip={isEdit ? translate('show_renderers') : translate('save')} 
                                            onClick={showRenderers}/>
                                    <Button theme='secondary' size='small' className='app-trip-2-trade-actions-button' icon={icons.plus} tooltip={translate('create_renderer')} onClick={createRenderer}/>
                                </div>
                            </div>
                            <DataList
                                keyExpr={'id'}
                                dataSource={trip2TradeJob.renderers}
                                height={200}
                                itemRender={rendererDataListRow}
                                selectionMode={isEdit === true ? 'all' : 'none'}
                                selectedItemsChange={(o)=> setSelectedRenderers(o)}
                                selectedItems={selectedItems}
                                selectByClick={false}
                                focusStateEnabled={false}
                            />
                        </div>         
                }
                </div> 
            </Hideable>
            { !hideTrip2TradeJob ? (                
                <Hideable className='app-trip-2-trade' hide={hideTrip2TradeJob}>
                    <Trip2TradeJob entity={entity} trip2TradeJob={trip2TradeJob} onSave={(o) => saveTrip2TradeJob(o)} onClose={closeTrip2TradeJob} />
                </Hideable>
            ) : null }

        { !hideCreateRendererWizard ? (                
                <Hideable className='app-trip-2-trade' hide={hideCreateRendererWizard}>
                    <RendererWizard trip2TradeJob={trip2TradeJob} renderer={newRenderer} onUpdateRenderer={updateRenderer} onClose={closeCreateRendererWizard} onSave={saveRenderer} />
                </Hideable>
            ) : null }                 
        </Hideable>                 
    </>
}
