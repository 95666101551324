// React imports
import { useEffect, useState } from 'react';

// App imports
import { errorToast, successToast } from '../../base/toast/toast';
import { Bar } from '../../base/bar/bar';
import { icons } from '../../../components/base/icon/icon';
import { constants } from '../../../utils/constants';
import { translate } from "../../../utils/translation";
import { MobilityReports } from './mobilityReports/mobilityReports';
import { CompetitiveInsights } from './competitiveInsights/competitiveInsights';
import { CIChannelFilterPanel } from './competitiveInsights/ciChannelFilterPanel';
import { navigation } from '../navigation/navigation';
import { userPreferences } from '../app';

export var ciFilter;

export function MobilityData() {
    
    const [activePanel, setActivePanel] = useState(null);
    const [showCiFilter, setShowCiFilter] = useState(false);
    
    useEffect(()=>{
        ciFilter = {
            show: () =>{

                navigation.forceExpand({ section: constants.navigation.sections.mobilityData });
                setActivePanel(null);
                setShowCiFilter(true);                
            },
            hide: () =>{
                setShowCiFilter(false);
            }
        };
    }, []);

    return  <>
        {showCiFilter ? <CIChannelFilterPanel onClose={()=>{setShowCiFilter(false)}} /> : ''}
        <Bar text={translate("competitive_insights")} icon={icons.binoculars} notLicensed={!userPreferences.AllowCompetitiveInsights} onClick={() => { setActivePanel(1); }} />
        {( activePanel === 1 ? <CompetitiveInsights open={true} onClose={()=>{ setActivePanel(null); }} /> : '' )} 
        <Bar text={translate("mobility_reports")} icon={icons.mobile} notLicensed={!userPreferences.AllowMobilityData} onClick={() => { setActivePanel(2); }} />
        {( activePanel === 2 ? <MobilityReports reportCategory={constants.reportCategories.uberRetail} onClose={(o) => { 
            
            if (!o.cancel && o.success)
                if (o.success)
                    successToast(o.message);
                else
                    errorToast(o.message);

            setActivePanel(null); 

        }} /> : '' )}         
    </>
}