import { Details, Column, FileManager as DevExtFileManager, ItemView, Notifications, Permissions, Item, FileSelectionItem } from 'devextreme-react/file-manager';
import { translate } from "../../../utils/translation";
import { Toolbar } from 'devextreme-react';
import { Loader } from '../loader/loader.js';
import { adHocManagement as adHocModule } from '../../../modules/adHocManagement';
import { useEffect, useState, useRef, createRef } from 'react';
import { ModalCard } from '../modalCard/modalCard.js';
import { constants } from '../../../utils/constants.js';
import { SharedUsers } from '../../app/sharedUsers/sharedUsers.js';

const _ = require('lodash');

export default function FileManager({setPanelContents}) {
  const [loaded, setLoaded] = useState(true);
  const [fileSystem, setFileSystem] = useState(null);
  const [showSharedUsers, setShowSharedUsers] = useState({show: false, adHocId: null});
  const fileManagerRef = useRef(createRef());

  //Overwrite methods for FileManager interactions
  const onContextMenuShowing = function (options) {
    if(_.isUndefined(options.fileSystemItem))
      showContextMenuTools(undefined)
    else
      showContextMenuTools(options.fileSystemItem.dataItem)
  }

  const onContextMenuItemClick = function (options) {
    adHocModule.runFileManagerCommand({ object: options.fileSystemItem.dataItem, 
                                        command: options.itemData.command, 
                                        dataType: options.itemData.dataType, 
                                        setPanelContents: setPanelContents, 
                                        setShowSharedUsers: setShowSharedUsers 
                                      })
  }

  const onSelectionChanged = function (options) {
    if (options.selectedItems.length === 1) {
      showItemTools(options.selectedItems[0].dataItem);
    }
    else if (options.selectedItems.length > 1) {
      showMultiItemTools(options.selectedItems)
    }
  }

  const onDirectoryCreating = function(options) {
    options.cancel = true;
    adHocModule.createAdHocFolder({ name: options.name });
    getFileSystem();
  }

  const onItemMoving = function (options) {
    if (options.item.isDirectory) {
        options.cancel = true;
    }
    else if (options.destinationDirectory.dataItem.shared) {
        options.cancel = true;
    }
    else {
        adHocModule.moveAdHocToFolder({
          adHocs: [options.item.dataItem.id], 
          folderId: _.isUndefined(options.destinationDirectory.dataItem) ? "" : options.destinationDirectory.dataItem.id
        });  
    }
  }

  const onItemRenaming = function (options) {    
    if (options.item.isDirectory)
      adHocModule.renameAdhocFolder({id: options.item.dataItem.id, name: options.newName})
    else 
      options.cancel = true;
  }

  const onItemDeleting = function (options) {
    adHocModule.deleteAdHocObject(options.item);
  }

  //Show toolbar buttons for selected item
  const showItemTools = function (selectedItem) {
    var fileSelectionItems = ["refresh", "clearSelection"];
    //folder selected actions
    if (selectedItem.isDirectory) {
      if (selectedItem.ownedByMe) {
        fileSelectionItems.push("rename");

        if (!selectedItem.isDefault) {
          fileSelectionItems.push("delete");
        }
      }
    }
    //file selection actions
    else {
      //moving
      if (selectedItem.ownedByMe) {
        fileSelectionItems.push({ name: "move", options: { text: "Move", hint: "Move" } });
      }
      //edit
      if (selectedItem.allowEdit) {
        //edit
        fileSelectionItems.push({
          widget: "dxButton",
          options: {
            text: translate("edit"),
            hint: translate("edit"),
            icon: "edit",
            onClick: function () {
              adHocModule.runFileManagerCommand({ object: selectedItem, command: "Edit", setPanelContents: setPanelContents})
            }
          }
        })
        //symbolize
        //fileSelectionItems.push({
        //  widget: "dxButton",
        //  options: {
        //    text: translate("symbol_manager"),
        //    hint: translate("symbol_manager"),
        //    icon: "palette",
        //    onClick: function () {
        //      adHocModule.runFileManagerCommand({ object: selectedItem, command: "Symbolize"})
        //    }
        //  }
        //})
      };
      if (selectedItem.ownedByMe) {
        //delete
        fileSelectionItems.push({ name: "delete", options: { text: translate("delete"), hint: translate("delete") } });

        //Sharing
        if (selectedItem.allowSharing) {
          fileSelectionItems.push({
            widget: "dxButton",
            options: {
              icon: "share",
              text: translate("share"),
              hint: translate("share"),
            },
            onClick: function () {
              adHocModule.runFileManagerCommand({ object: selectedItem, command: "Share", setShowSharedUsers: setShowSharedUsers})
            }
          })
        };
      };
      //Map Data
      fileSelectionItems.push({
        widget: "dxButton",
        options: {
          text: translate("map_data"),
          hint: translate("map_data"),
          icon: "map",
          onClick: function () {
            adHocModule.runFileManagerCommand({ object: selectedItem, command: "MapData"})
          }
        }
      })
      //Run dropdown
      //if (selectedItem.allowBulkDemographics || selectedItem.allowClosestStores || selectedItem.allowMapBooks) {
      //  var items = []
      //  //Bulk demos
      //  if (selectedItem.allowBulkDemographics) {
      //    items.push({ text: translate("demographics"), command: "BulkDemos", icon: "https://cdn.tradeareasystems.net/Images/TASOnline/Reports/DemographicSummary.png" })
      //  }
      //
      //  //Closest stores
      //  if (selectedItem.allowClosestStores) {
      //    items.push({ text: translate("closest_stores"), command: "ClosestStores", icon: "https://cdn.tradeareasystems.net/Images/TASOnline/Tools/ClosestStore.png" })
      //  }
      //
      //  //Map books
      //  if (selectedItem.allowMapBooks) {
      //    items.push({ text: translate("map_books"), command: "MapBooks", icon: "https://cdn.tradeareasystems.net/Images/TASOnline/Reports/MapBook_16x16.png" })
      //  }
      //
      //  fileSelectionItems.push({
      //    widget: "dxDropDownButton",
      //    options: {
      //      text: translate("bulk_process"),
      //      icon: "runner",
      //      dropDownOptions: { width: 150 },
      //      displayExpr: "text",
      //      stylingMode: "text",
      //      items: items,
      //      onItemClick: function (options) {
      //        adHocModule.runFileManagerCommand({ object: selectedItem, command: options.itemData.command});
      //      }
      //    }
      //  })
      //}
      //Download dropdown
      //if (selectedItem.allowExport) {
      //  fileSelectionItems.push({
      //    widget: "dxDropDownButton",
      //    options: {
      //      text: translate("export_data"),
      //      icon: "download",
      //      hint: translate("export_data"),
      //      dropDownOptions: { width: 200 },
      //      displayExpr: "text",
      //      stylingMode: "text",
      //      items: [
      //        { text: translate("csv_comma_delimited"), dataType: "CSV", icon: "https://cdn.tradeareasystems.net/Images/TASOnline/Multimedia/CSV.png" },
      //        { text: "XLSX", dataType: "XLSX", icon: "https://cdn.tradeareasystems.net/Images/TASOnline/Multimedia/XLS.png" }
      //      ],
      //      onItemClick: function (options) {
      //        adHocModule.runFileManagerCommand({ object: selectedItem, command: options.command, dataType: options.dataType})
      //      }
      //    }
      //  });
      //}
      //publishable
      //if (selectedItem.type === TASOnline.Utilities.Types.adHocDataSource.user && selectedItem.isPersistent && myInstance.publisherAccess) {
      //    fileSelectionItems.push({
      //        widget: "dxCheckBox",
      //        options: {
      //            value: selectedItem.publishable,
      //            text: translate("publishable"),
      //            hint: translate("publishable")
      //        },
      //        onClick: function () {
      //          adHocModule.runFileManagerCommand({ object: selectedItem, command: "Publish"})
      //        }
      //    })
      //}
    }
    fileManagerRef.current.instance.option("toolbar.fileSelectionItems", fileSelectionItems);
  }

  //Show toolbar buttons for multiple selected item
  const showMultiItemTools = function (selectedItems) {
    var containsDirectory = false;
        var containsShared = false;
        var fileSelectionItems = [];

        _.each(selectedItems, function (options) {
            if (options.isDirectory)
                containsDirectory = true;
            if (!options.dataItem.ownedByMe)
                containsShared = true;
        });

        if (!containsDirectory) {
            fileSelectionItems.push("move");
        }

        if (!containsShared) {
            fileSelectionItems.push("delete");
        }

        fileManagerRef.current.instance.option("toolbar.fileSelectionItems", fileSelectionItems);
  }

  //Show context menu for selected item
  const showContextMenuTools = function (selectedItem) {
    var contextMenuItems = [];
    //non folder/adhoc context menu
    if(_.isUndefined(selectedItem)) {
      contextMenuItems.push("refresh");
      fileManagerRef.current.instance.option("contextMenu.items", contextMenuItems);
      return;
    }

    //folder selected actions
    if (selectedItem.isDirectory) {
      if (selectedItem.ownedByMe) {
        contextMenuItems.push("rename");

        if (!selectedItem.isDefault) {
          contextMenuItems.push("delete");
        }
      }
      contextMenuItems.push("refresh");
    }

    //file selection actions
    else {
      //moving
      if (selectedItem.ownedByMe) {
        contextMenuItems.push({ name: "move", options: { text: "Move", hint: "Move" } });
      }

      //edit
      if (selectedItem.allowEdit) {
        //edit
        contextMenuItems.push({ text: translate("edit"), icon: "edit", command: "Edit" });
        //symbolize
        //contextMenuItems.push({ text: translate("symbol_manager"), icon: "palette", command: "Symbolize" });
      };

      if (selectedItem.ownedByMe) {
        //delete
        contextMenuItems.push("delete");

        //Sharing
        if (selectedItem.allowSharing) {
          contextMenuItems.push({ text: translate("share"), command: "Share", icon: "share" });
        };
      };
      
      //map data
      contextMenuItems.push({ text: translate("map_data"), command: "MapData", icon: "map", beginGroup: true });
      
      //Bulk demos
      //if (selectedItem.allowBulkDemographics) {
      //  contextMenuItems.push({ text: translate("demographics"), command: "BulkDemos", icon: "https://cdn.tradeareasystems.net/Images/TASOnline/Reports/DemographicSummary.png" });
      //}

      //Closest stores
      //if (selectedItem.allowClosestStores) {
      //  contextMenuItems.push({ text: translate("closest_stores"), command: "ClosestStores", icon: "https://cdn.tradeareasystems.net/Images/TASOnline/Tools/ClosestStore.png" });
      //}

      //Map books
      //if (selectedItem.allowMapBooks) {
      //  contextMenuItems.push({ text: translate("map_books"), command: "MapBooks", icon: "https://cdn.tradeareasystems.net/Images/TASOnline/Reports/MapBook_16x16.png" })
      //}

      //Download dropdown
      //if (selectedItem.allowExport) {
      //  contextMenuItems.push({
      //    text: translate("export_data"),
      //    icon: "download",
      //    items: [
      //      { text: translate("csv_comma_delimited"), command: "Export", dataType: "CSV", icon: "https://cdn.tradeareasystems.net/Images/TASOnline/Multimedia/CSV.png" },
      //      { text: "XLSX", command: "Export", dataType: "XLSX", icon: "https://cdn.tradeareasystems.net/Images/TASOnline/Multimedia/XLS.png" }
      //    ],
      //    beginGroup: true
      //  })
      //}
    }
    
    fileManagerRef.current.instance.option("contextMenu.items", contextMenuItems);
  }

  //File System Update
  const getFileSystem = async function () {
    setLoaded(false);
    setFileSystem(await adHocModule.getFileManagerFileSystem());
    await new Promise(resolve => setTimeout(resolve, 500)); //without this delay the folder tree won't populate properly
    setLoaded(true);
    if (!_.isNull(fileManagerRef.current) && !_.isNull(fileManagerRef.current.instance)){
      fileManagerRef.current.instance.refresh();
    }
  };

  useEffect(() => {
    getFileSystem()
  }, []);

  return (
    <div className='app-file-manager'>
      <Loader loaded={loaded}></Loader>
      <ModalCard className='app-shared-users-modal-card' loaded={true} open={showSharedUsers.show}>
        <SharedUsers objectId={showSharedUsers.adHocId} sharingObjectType={constants.sharingObjectType.adHocDataSource} onClose={() => { setShowSharedUsers({show: false, adHocId: null}); }} />
      </ModalCard>
      <DevExtFileManager
        height="100%"
        ref={fileManagerRef}
        fileSystemProvider={fileSystem}
        onContextMenuShowing={onContextMenuShowing}
        onContextMenuItemClick={onContextMenuItemClick}
        onSelectionChanged={onSelectionChanged}
        onDirectoryCreating={onDirectoryCreating}
        onItemMoving={onItemMoving}
        onItemRenaming={onItemRenaming}
        onItemDeleting={onItemDeleting}
      >
        <Permissions create={true} download={true} move={true} rename={true} delete={true} />
        <Notifications showPanel={false} showPopup={false} />
        <ItemView showParentFolder={false}>
          <Details>
            <Column dataField="thumbnail"></Column>
            <Column dataField="name" caption={translate('title')}></Column>
            <Column dataField="dataSourceTypeLabel" dataType="object" caption={translate('type')} width={100}></Column>
            <Column dataField="ownerName" dataType="object" caption={translate('owner')} width={200}></Column>
            <Column dataField='lastModified' dataType='datetime' caption={translate('last_modified')} width={150}></Column>
          </Details>
        </ItemView>
        <Toolbar>
          <Item name="create"></Item>
          <Item name="refresh"></Item>
          <FileSelectionItem></FileSelectionItem>
        </Toolbar>
      </DevExtFileManager>
    </div>
  );
}