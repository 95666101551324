// React imports
import { useEffect } from 'react';

//App imports
import { translate } from '../../../../../utils/translation';
import { TextBox } from '../../../../base/textBox/textBox';
import { TextArea } from '../../../../base/textArea/textArea';
import { DropDown } from '../../../../base/dropDown/dropDown';
import { icons } from '../../../../base/icon/icon';
import { Icon } from '../../../../base/icon/icon';
import { Bar } from '../../../../base/bar/bar';

const _ = require('lodash');

export function AdHocCreationWizardStep3({ dataSource, updateDataSource, table, updateTable, onReadyNext }) {

    useEffect(() => {
        if (!_.isString(dataSource.name) && _.isString(table.text))
        {
            dataSource.name = table.text;
            updateDataSource(_.cloneDeep(dataSource));
        }

        if (onReadyNext)
            onReadyNext(_.isString(table.name) && _.isString(dataSource.description) && !_.isEmpty(dataSource.description) && _.isString(dataSource.nullText) && !_.isEmpty(dataSource.nullText));
    }, [table, dataSource, updateDataSource, onReadyNext])

    return <div className='app-ad-hoc-creation-step-container'>
        <div>
            <div className='app-ad-hoc-creation-data-labels'>{translate("name_your_job")}</div>
            <TextBox className='app-ad-hoc-creation-step3-textbox' height='55px' label={translate('job_name')} value={dataSource.name}
                onChange={(o) => {
                    table.text = o.value;
                    dataSource.name = o.value;
                    updateTable(_.cloneDeep(table));
                    updateDataSource(_.cloneDeep(dataSource));
                }} />
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div className='app-option-selector'>
                                {table.autoLoad ?
                                    <div className='app-option-selector-check'><Icon icon={icons.check} /></div> : ''
                                }
                                <Bar className={'app-ad-hoc-columns-bar-button'}
                                    text={translate('load_on_map')}
                                    active={false}
                                    onClick={() => {
                                        table.autoLoad = !table.autoLoad;
                                        updateTable(_.cloneDeep(table));
                                    }}
                                    disabled={false}
                                />
                            </div>
                        </td>
                        <td>
                            <div className='app-option-selector'>
                                {table.isPersistent ?
                                    <div className='app-option-selector-check'><Icon icon={icons.check} /></div> : ''
                                }
                                <Bar className={'app-ad-hoc-columns-bar-button'}
                                    text={translate('persist_data')}
                                    active={false}
                                    onClick={() => {
                                        table.isPersistent = !table.isPersistent;
                                        updateTable(_.cloneDeep(table));
                                    }}
                                    disabled={false}
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='app-ad-hoc-creation-data-labels'>{translate('max_zoom')}</div>
            <DropDown className='app-ad-hoc-creation-step3-dropdown' height='55px' label={translate("zoom_level")} display={"name"} valueProperty={"key"} 
            items={
                [
                    { key: 3, name: "3 (" + translate("country") + ")" },
                    { key: 4, name: "4 (" + translate("country") + ")" },
                    { key: 5, name: "5 (" + translate("country") + ")" },
                    { key: 6, name: "6 (" + translate("region") + ")" },
                    { key: 7, name: "7 (" + translate("region") + ")" },
                    { key: 8, name: "8 (" + translate("region") + ")" },
                    { key: 9, name: "9 (" + translate("region") + ")" },
                    { key: 10, name: "10 (" + translate("region") + ")" },
                    { key: 11, name: "11 (" + translate("city") + ")" },
                    { key: 12, name: "12 (" + translate("city") + ")" },
                    { key: 13, name: "13 (" + translate("city") + ")" },
                    { key: 14, name: "14 (" + translate("city") + ")" },
                    { key: 15, name: "15 (" + translate("city") + ")" },
                    { key: 16, name: "16 (" + translate("street") + ")" },
                    { key: 17, name: "17 (" + translate("street") + ")" },
                    { key: 18, name: "18 (" + translate("street") + ")" },
                    { key: 19, name: "19 (" + translate("street") + ")" },
                    { key: 20, name: "20 (" + translate("street") + ")" }
                ]
            } selected={dataSource.maxZoom}
            onChange={(o) => {
                dataSource.maxZoom = o.value;
                updateDataSource(_.cloneDeep(dataSource));
            }} 
            />
            <div className='app-ad-hoc-creation-data-labels'>{translate('any_extra_detail')}</div>
            <TextArea className='app-ad-hoc-creation-step3-textbox' minHeight='220px' maxHeight='220px' label={translate('description')} placeholder={translate("description")}
                value={dataSource.description}
                onChange={(o) => {
                    dataSource.description = o.value;
                    updateDataSource(_.cloneDeep(dataSource));
                }}
            />
            <div className='app-ad-hoc-creation-data-labels'>{translate('define_null_text')}</div>
            <TextBox className='app-ad-hoc-creation-step3-textbox' height='55px' label={translate('null_text')}
                placeholder={translate("null_na")}
                value={dataSource.nullText}
                onChange={(o) => {
                    dataSource.nullText = o.value;
                    updateDataSource(_.cloneDeep(dataSource));
                }}
            />
        </div>
    </div>
}