import { useCallback, useState } from 'react';

import { Popover } from '../popover/popover';
import { helpers } from '../../../utils/helpers';
import { translate } from '../../../utils/translation';
import { ColorBox } from 'devextreme-react';
import { DropDown } from '../dropDown/dropDown';
import { TextBox } from '../textBox/textBox';
import { Button } from '../button/button';
import { icons } from '../icon/icon';
import { Clickable } from '../clickable/clickable';
import _ from 'lodash';

export function PolygonStyleSwatch({ className, style, textStyle, onChange}){
    
    const id = `a${helpers.newGuid().split('-')[0]}`;
    const currentStyle = style ?? textStyle;
    const transparentStyle = `rgba(0,0,0,0)`;

    var classes = ['app-polygon-style-swatch'];

    if (className)
        classes.push(className);

    const getPopover = () => {
        if (style)
            return <Popover 
                parentId={`#${id}`}
                title={translate('style')}
                height={220}
                width={250}
            >
                <ColorBox 
                    label={translate('fill_color')}                     
                    value={_.isObject(currentStyle.fillColor) ? `rgba(${currentStyle.fillColor.r},${currentStyle.fillColor.g},${currentStyle.fillColor.b},${currentStyle.fillColor.a})` : transparentStyle} 
                    editAlphaChannel={true}
                    onValueChange={(o) => {
                        var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                        onChange({...currentStyle, fillColor: { r: parseFloat(colors[0]), g:parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) }});
                    }} 
                />
                <ColorBox 
                    label={translate('line_color')}                     
                    value={_.isObject(currentStyle.lineColor) ? `rgba(${currentStyle.lineColor.r},${currentStyle.lineColor.g},${currentStyle.lineColor.b},${currentStyle.lineColor.a})` : transparentStyle} 
                    editAlphaChannel={true}
                    onValueChange={(o) => {
                        var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                        onChange({...currentStyle, lineColor: { r: parseFloat(colors[0]), g:parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) }});
                    }}
                />
                <DropDown 
                    label={translate('line_width')} 
                    selected={currentStyle.lineWidth} 
                    fieldRender={(data) => {
                        return <>
                            <img alt={translate('line_width')} className='app-shape-edit-line-width-image' src={data ? data.image : ''}/>
                            <TextBox readOnly={true} visible={false}/>
                        </>
                    }}
                    itemRender={(data) => { return <img alt={data.name} src={data.image} />}} 
                    valueProperty='value'                
                    items={[1, 2, 3, 4, 5, 6].map((width) => { 
                        return {
                            name: `LineWidth_${width}`,
                            value: width,
                            image: `https://tasonline.com/Content/Media/LineStyle/LineWidth_${width}.gif`
                        }
                    })}
                    onChange={(o) => {                        
                        onChange({...currentStyle, lineWidth: o.value});
                    }}
                />
            </Popover>
        else if (textStyle)
            return <Popover 
                parentId={`#${id}`}
                title={translate('style')}
                height={220}
                width={250}
            >
                <Button className='app-polygon-style-auto-label' theme='secondary' text={translate('label')} active={currentStyle.autoLabel} icon={icons.tag} onClick={()=>{
                    onChange({...currentStyle, autoLabel: !currentStyle.autoLabel});
                }} />
                <ColorBox 
                    label={translate('fill_color')}                     
                    value={_.isObject(currentStyle.label.style.background) ? `rgba(${currentStyle.label.style.background.r},${currentStyle.label.style.background.g},${currentStyle.label.style.background.b},${currentStyle.label.style.background.a})` : transparentStyle} 
                    editAlphaChannel={false}
                    onValueChange={(o) => {
                        currentStyle.label.style.background = helpers.stringToColor(o, 1);
                        onChange({...currentStyle });
                    }} 
                />
                <ColorBox 
                    label={translate('line_color')}                     
                    value={_.isObject(currentStyle.label.style.color) ? `rgba(${currentStyle.label.style.color.r},${currentStyle.label.style.color.g},${currentStyle.label.style.color.b},${currentStyle.label.style.color.a})` : transparentStyle} 
                    editAlphaChannel={false}
                    onValueChange={(o) => {
                        currentStyle.label.style.color = helpers.stringToColor(o, 1);
                        onChange({...currentStyle });
                    }}
                />
            </Popover>
    };
    
    const getStyle = () => {
        if (style)
            return <img className='app-polygon-style-swatch-color' src='https://cdn.tradeareasystems.net/Images/TASOnline/Misc/Blank.gif' style={{
                borderStyle: 'solid',
                borderWidth: `${currentStyle.lineWidth}px`,
                borderColor: _.isObject(currentStyle.lineColor) ? `rgba(${currentStyle.lineColor.r},${currentStyle.lineColor.g},${currentStyle.lineColor.b},${currentStyle.lineColor.a})` : transparentStyle,
                backgroundColor: _.isObject(currentStyle.fillColor) ? `rgba(${currentStyle.fillColor.r},${currentStyle.fillColor.g},${currentStyle.fillColor.b},${currentStyle.fillColor.a})` : transparentStyle
            }} />        
        else if (textStyle)
            return currentStyle.autoLabel ? <>
                <img className='app-polygon-style-swatch-color' src='https://cdn.tradeareasystems.net/Images/TASOnline/Misc/Blank.gif' style={{backgroundColor: _.isObject(currentStyle.label.style.background) ? `rgba(${currentStyle.label.style.background.r},${currentStyle.label.style.background.g},${currentStyle.label.style.background.b},${currentStyle.label.style.background.a})` : transparentStyle }} />
                <span className="app-polygon-style-swatch-text"  style={{
                    color: _.isObject(currentStyle.label.style.color) ? `rgba(${currentStyle.label.style.color.r},${currentStyle.label.style.color.g},${currentStyle.label.style.color.b},${currentStyle.label.style.color.a})` : transparentStyle
                }} 
                >T</span>
            </> : <Button theme='simple' icon={icons.ban} />;
    };

    return <>
        {getPopover()}
        <Clickable id={id} className={classes.join(' ')}>        
            <div className={style ? 'app-polygon-style-swatch-transparent' : currentStyle.autoLabel ? 'app-polygon-style-swatch-transparent' : ''}>
                {getStyle()}
            </div>
        </Clickable>
    </>
} 