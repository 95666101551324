// React imports
import { useEffect, useState, useMemo, useCallback, Fragment } from 'react';

// Third party imports
import ImageGallery from "react-image-gallery";

// App imports
import { Bar } from '../../base/bar/bar';
import { Button } from '../../base/button/button';
import { Loader }  from '../../base/loader/loader';
import { icons } from '../../base/icon/icon';
import { constants } from '../../../utils/constants';
import { translate } from '../../../utils/translation';
import { legacyEndpoints } from "../../../services/legacyEndpoints";
import { forms as formsModule} from '../../../modules/forms';

const _ = require("lodash");

export function Form({ form = null, flatten = false, onTabChange, onFieldRender }){
    
    const [tabs, setTabs] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [tabsLoaded, setTabsLoaded] = useState(true);

    useEffect(() =>{
        if (form === null)
            return;

        setTabs([...form.Tabs]);

    }, [form]);

    const classes = useMemo(() =>{
        var classes = ['app-form app-form-body'];

        if (form === null)
            return classes;

        if (form.TabsActive)
            switch (form.TabDisplay)
            {
                default:
                    break;
                case constants.forms.tabDisplays.textOnly:
                    classes.push('app-form-text');
                    break;
                case constants.forms.tabDisplays.imageOnly:
                    classes.push('app-form-image');
                    break;
                case constants.forms.tabDisplays.imageAndText:
                    classes.push('app-form-text-image');
                    break;
            }
        else
            classes.push('app-form-no-tabs');

        return classes;
    }, [form])

    const generateStyle = useCallback((o)=>{
        return formsModule.buildStyleObject(o);
    }, [form]);

    const renderValue = useCallback((field, section) =>{

        if (_.isFunction(onFieldRender))
            return onFieldRender(field, section);

        if (!_.isObject(field.RenderedValue))
            return '';
        
        var style = field.RenderedValue.fieldStyles?.find(x => x.key === field.RenderedValue.value);

        if (_.isObject(style))
            return <div className='app-form-value' style={generateStyle(field.ValueStyle)}>{style.value}</div>;

        var photo = field.RenderedValue.photos?.find(x => x.key === field.RenderedValue.value);
        if (_.isObject(photo))
            return <div className='app-form-photos-gallery'>
                <ImageGallery
                    showPlayButton={false}
                    showThumbnails={false}
                    showNav={true}
                    showFullscreenButton={false}
                    items={photo.photoIds.map((id) => {
                        return {
                            original:  legacyEndpoints.handlers.getPhotoUrl({ id: id, isUserPhoto: false }),
                            thumbnail: legacyEndpoints.handlers.getPhotoUrl({ id: id, isUserPhoto: false })
                        }
                    })}
                    renderLeftNav={(onClick, disabled) => { return <Button theme='simple' disabled={disabled} size='small' icon={icons.leftArrow} onClick={onClick} /> }}
                    renderRightNav={(onClick, disabled) => { return <Button className='app-form-photos-right-nav' theme='simple' disabled={disabled} size='small' icon={icons.rightArrow} onClick={onClick} /> }}
                />
            </div>;

        var link = field.RenderedValue.links?.find(x => x.key === field.RenderedValue.value);
        if (_.isObject(link))
            return <div className='app-form-value' style={generateStyle(field.ValueStyle)}>
                <a 
                    target="_blank" 
                    style={generateStyle(field.ValueStyle)}
                    rel='noopener noreferrer'
                    href={!link.value.toLowerCase().startsWith("http:") && !link.value.toLowerCase().startsWith("https:") && !link.value.toLowerCase().startsWith("//") ? `//${link.value}` : link.value}>
                        {link.linkText}
                </a>
            </div>;
            
        return <div className='app-form-value' style={generateStyle(field.ValueStyle)}>{field.RenderedValue.value}</div>;
    }, [form]);

    const tabChange = useCallback((tab, i) =>{
        (async () =>{

            setActiveTab(i);

            if (!_.isFunction(onTabChange))
                return;
    
            setTabsLoaded(false);

            tabs[i] = await onTabChange(tab);

            setTabsLoaded(true);
                        
            setTabs([...tabs]);

        })();        
    }, [onTabChange, tabs]);

	return <div className={classes.join(' ')}>
        <table className='app-form-body-table'>
            <tbody>
                <tr>
                    <td className='app-form-body-left-column'>
                        <div className='app-form-content-tabs'>
                        {
                            _.isNull(tabs) ? '' : tabs.map((tab, i) =>{
                                var numErrors = 0;
                                var numWarnings = 0;
                                
                                _.forEach(tab.Pages, (page) => {
                                    for (let i = 0; i < page.Sections.length; i++) {
                                        if (page.Sections[i].isCustom)
                                            continue;
    
                                        for (let j = 0; j < page.Sections[i].Rows.length; j++) {
                                            for (let k = 0; k < page.Sections[i].Rows[j].Fields.length; k++) {
                                                if (page.Sections[i].Rows[j].Fields[k].showError === true)
                                                    numErrors++;
                                                else if (page.Sections[i].Rows[j].Fields[k].showWarning === true)
                                                    numWarnings++;
                                            };
                                        };
                                    };
                                });
                                	
                                return tab.hidden ? '' 
                                : <div key={tab.Id == null ? i : tab.Id} className='app-form-tab-container'>
                                    <Bar    
                                        className={`${(numErrors > 0 ? 'app-form-content-tab-error' : numWarnings > 0 ? 'app-form-content-tab-warning' : '') } app-form-content-tab`}
                                        icon={tab.icon ?? legacyEndpoints.handlers.getSymbolUrl({ imageUrl: tab.ImageId, symbolType: tab.ImageType, isInfobox: true })}
                                        text={tab.Title}
                                        tooltip={tab.Title}
                                        active={activeTab===i}
                                        onClick={() => { tabChange(tab, i) }}
                                    />
                                    {
                                        numErrors > 0 ? <div className='app-form-content-tab-badge app-form-content-tab-badge-error'>{numErrors}</div> 
                                        : numWarnings > 0 ? <div className='app-form-content-tab-badge app-form-content-tab-badge-warning'>{numWarnings}</div> 
                                        : ''
                                    }
                                </div>
                            })
                        }
                        </div>
                    </td>
                    <td>
                        <div className='app-form-content'>
                            <Loader loaded={tabsLoaded} />
                            <div className={`${(flatten ? 'app-form-page-flatten ' : '') }app-form-page`}>
                            {
                                _.isNull(tabs) ? '' : tabs[activeTab]?.Pages[0]?.Sections?.map((section, i) =>{
                                    
                                    if (section.hidden)
                                        return '';

                                    if (section.isCustom)
                                        return <Fragment key={section.Id == null ? i : section.Id}>{section.customComponent()}</Fragment>;

                                    var numberOfVisibleFields = 0;

                                    var visibleSection = <Bar key={section.Id == null ? i : section.Id} text={section.Title} opened={true}>
                                        <table className='app-form-content-section app-selectable-text'>
                                            <tbody>
                                            {
                                                section.Rows.map((row, i) =>{
                                                    if (flatten)
                                                        return <Fragment key={row.Id == null ? i : row.Id}>
                                                        {
                                                            row.Fields.filter(field => field.Merged === false && (_.isUndefined(field.hidden) || field.hidden === false)).map((field, i) =>{
                                                                
                                                                numberOfVisibleFields++;
                                                                
                                                                return <Fragment key={row.Id == null ? i : row.Id}>
                                                                    {
                                                                        field.ShowLabel && field.Label.length > 0 ? <tr className='app-form-content-row'>
                                                                            <td className='app-form-content-column app-form-label-container'>
                                                                                <div className='app-form-label-flatten app-form-label' style={generateStyle(field.LabelStyle)}>{field.Label}</div>
                                                                            </td>
                                                                        </tr> : ''
                                                                    }
                                                                    <tr className='app-form-content-row'>
                                                                        <td className='app-form-content-column' colSpan={field.ShowLabel ? (field.Span * 2)-1 : field.Span * 2}>
                                                                            {renderValue(field, section)}
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>;
                                                            })
                                                        }
                                                        </Fragment>;
                                                    
                                                    return <tr key={row.Id == null ? i : row.Id} className={`app-form-content-row ${(i===0 ? 'app-form-content-row-first' : i===section.Rows.length-1 ? 'app-form-content-row-last' : '')}`}>
                                                    {
                                                        row.Fields.filter(field => field.Merged === false && (_.isUndefined(field.hidden) || field.hidden === false)).map((field, i) =>{

                                                            numberOfVisibleFields++;

                                                            return <Fragment key={field.Id == null ? i : field.Id}>
                                                                {
                                                                    field.ShowLabel ? <td className='app-form-content-column app-form-label-container'>
                                                                        <div className='app-form-label' style={generateStyle(field.LabelStyle)}>{field.Label}</div>
                                                                    </td> : ''
                                                                }
                                                                <td className='app-form-content-column' colSpan={field.ShowLabel ? (field.Span * 2)-1 : field.Span * 2}>
                                                                    {renderValue(field, section)}
                                                                </td>
                                                            </Fragment>;
                                                        })
                                                    }
                                                    </tr>;
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </Bar>;

                                    return numberOfVisibleFields === 0 ? null : visibleSection;
                                })
                            }
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
}