// React imports
import { useState, useEffect, useCallback } from 'react';

// App imports
import { Loader } from '../../../base/loader/loader';
import { DropDown } from '../../../base/dropDown/dropDown';
import { Button }  from '../../../base/button/button';
import { Description }  from '../../../base/description/description';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { userPreferences } from '../../app';
import { successToast, errorToast } from '../../../base/toast/toast';

export function UserBulkDriveTime() {

    const textBoxHeight = '55px';

    const [driveTimeEnginesLoaded, setDriveTimeEnginesLoaded] = useState(false);
    const [driveTimeEngines, setDriveTimeEngines] = useState([]);		
    const [driveTimeEngine, setDriveTimeEngine] = useState(null);		
    const [speedSpecification, setSpeedSpecification] = useState(null);		
    const [allowSave, setAllowSave] = useState(true);		

    const changeSpeedSpecification = useCallback(
        (o)=>{
            if (o.engine?.SpeedSpecifications?.length > 0 ) {
                var spec = undefined;

                if (o.setDefault)
                    spec = o.engine.SpeedSpecifications.find(s => s.IsSelected === true);
                else
                    spec = o.engine.SpeedSpecifications.find(s => s.Id === o.id);

                if (spec === undefined)
                    spec = o.engine.SpeedSpecifications[0];

                setSpeedSpecification(spec);
            }
        },
        []
    );

    const changeDriveTimeEngine = useCallback(
        (o)=>{
            if (driveTimeEngines?.length > 0 ) {
                var eng = undefined;

                if (o.setDefault)
                    eng = driveTimeEngines.find(e => e.IsSelected === true);
                else
                    eng = driveTimeEngines.find(e => e.Id === o.id);

                if (eng === undefined)
                    eng = driveTimeEngines[0];

                setDriveTimeEngine(eng);
                changeSpeedSpecification({ setDefault: true, engine: eng });
            }
        },
        [driveTimeEngines, changeSpeedSpecification]
    );

    useEffect(() => {

        if (driveTimeEngines.length > 0)
            return;

        legacyEndpoints.service({
            name: 'GetAvailableDriveTimeEngines',
            success: function(r) {
                if (r.length === 0) {
                    var spec = [];
                    spec.push({ Name: translate('not_available'), Id: -1, IsSelected: true });
                    r.push({Name: translate('not_available'), Id: -1, IsSelected: true, SpeedSpecifications: spec });
                    setAllowSave(false);
                }
                
                setDriveTimeEngines(r);
                setDriveTimeEnginesLoaded(true);
            }
        });

    }, [driveTimeEngines.length]);

    useEffect(() => {

        if (driveTimeEngines.length === 0)
            return;

        changeDriveTimeEngine({ setDefault: true });

    }, [driveTimeEngines, changeDriveTimeEngine]);

    return <>
        <Loader className='app-card-body-loader' loaded={driveTimeEnginesLoaded} />
        {!driveTimeEnginesLoaded ? '' : 
            <div className='app-user-preferences-card'>
                <DropDown className='app-user-preferences-inputs' items={driveTimeEngines} label={translate('engine')} height={textBoxHeight} display='Name' valueProperty='Id' 
                    selected={driveTimeEngine?.Id} disabled={!allowSave} onChange={(o) => { changeDriveTimeEngine({ setDefault: false, id: o.value }); }} 
                />
                <DropDown className='app-user-preferences-inputs' items={driveTimeEngine?.SpeedSpecifications} label={translate('speed_specification')} height={textBoxHeight} display='Name' valueProperty='Id' 
                    selected={speedSpecification?.Id} disabled={!allowSave} onChange={(o) => { changeSpeedSpecification({ setDefault: false, engine: driveTimeEngine, id: o.value }); }} 
                />
                <div className='app-user-preferences-save'>
                    <Button theme='tertiary' size='medium' icon={icons.check} tooltip={translate('save')} disabled={!allowSave} onClick={()=>{ 
                        legacyEndpoints.service({
                            name: 'SetDriveTimeEngineAndSpeedSpecification',
                            parameters: {
                                DriveTimeEngineId: driveTimeEngine.Id,
                                DriveTimeSettingId: speedSpecification.Id
                            },
                            success: function(r) {
                                if (r) {
                                    userPreferences.DriveTimeEngineID = driveTimeEngine.Id;
                                    userPreferences.DriveTimeSettingID = speedSpecification.Id;
                                    userPreferences.DriveTimeMode = speedSpecification.Mode;
                                    successToast(translate('success'));
                                }
                                else
                                    errorToast(translate('drive_time_settings_error_license'));
                            }
                        });
                    }} />
                </div>
                <Description description={translate('drive_time_engine_settings')} />
            </div>
        }
    </>
}