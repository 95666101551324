// React imports
import { useState, useEffect, useCallback, useMemo, useRef } from 'react'

// App imports
import { CIDashboard } from './ciDashboard';
import { CICustomerProfileDashboard } from './ciCustomerProfileDashboard';
import { CIDashboardReport } from './ciDashboardReport';
import { Tabs } from '../../../../base/tabs/tabs';
import { icons } from '../../../../base/icon/icon';
import { helpers } from '../../../../../utils/helpers';
import { constants } from '../../../../../utils/constants';
import { translate } from '../../../../../utils/translation';
import { competitiveInsights as ciModule } from '../../../../../modules/competitiveInsights';

const _ = require("lodash");

export var ciDashboards;

export function CIDashboards({entity}) {

    const [entityId, setEntityId] = useState(null);    
    const [defaultSettings, setDefaultSettings] = useState(null);    
    const [currentChannelList, setCurrentChannelList] = useState([]);
    const [renderedTabs, setRenderedTabs] = useState([]);
    const [currentTab, setCurrentTab] = useState(constants.competitiveInsights.dashboards.siteDetail);
    const [goToTab, setGoToTab] = useState(-1);
    const [renderedCharts, setRenderedCharts] = useState([]);
    const [exportMetadata, setExportMetadata] = useState({ sessionId: null, images: [], active: false, init: false, create: false });
    const [siteDetailsData, setSiteDetailsData] = useState(null);    
    const [crossShopData, setCrossShopData] = useState(null);    
    const [marketShareData, setMarketShareData] = useState(null);    
    const [demographicsReports, setDemographicsReports] = useState(null);    
    const [demographicsSettings, setDemographicsSettings] = useState(null);    
    const [demographicsData, setDemographicsData] = useState(null);    
    const [multiSiteData, setMultiSiteData] = useState(null);    
    const [loaded, setLoaded] = useState(true);    
    const [rankTypeGeoLevelId, setRankTypeGeoLevelId] = useState(null);
    const [marketTypeGeoLevelId, setMarketTypeGeoLevelId] = useState(null);

	const siteDetailChartRefs = useRef([]);
	const marketShareChartRefs = useRef([]);
	const multiSiteChartRefs = useRef([]);

    const percents = useMemo(() => [
        { id: 1, value: .8, text: '80%'}, 
        { id: 2, value: .75, text: '75%'}, 
        { id: 3, value: .7, text: '70%'}, 
        { id: 4, value: .65, text: '65%'}, 
        { id: 5, value: .6, text: '60%'}
    ], []);

    ciDashboards = {
        showMarketShare: async (list)  =>{
            if (list.length === 0) return;

            if (!list.some(item => item.id === entity.id)) {
                list.push({id: entity.id, name: entity.text});
            }
            setDefaultSettings({...defaultSettings, marketSiteList: list, dashboardType: constants.competitiveInsights.dashboards.marketShare, marketType: constants.competitiveInsights.marketType.site });
        },
        showMultiSite: (list) =>{
            if (list.length === 0) return;

            if (!list.some(item => item.id === entity.id)) {
                list.push({id: entity.id, name: entity.text});
            }
            setDefaultSettings({...defaultSettings, multiSiteList: list, dashboardType: constants.competitiveInsights.dashboards.multiSite, marketType: constants.competitiveInsights.marketType.site });
        },  
        showCustomerProfile: (list) =>{  
            if (list.length === 0) return;
                  
            setGoToTabByid(constants.competitiveInsights.dashboards.demographics);
            setCurrentTab(constants.competitiveInsights.dashboards.demographics);
            setDemographicsSettings({ ...demographicsSettings, dashboardType: constants.competitiveInsights.dashboards.demographics, list: list });  
        },        
    }

    // TBD: calling cache service doesn't seem to improve performance?
    /*
    useEffect(() => {

        ciModule.createCompetitiveInsightsCache({
            pointId:  entity.id,
            channelId: entity.metaData.competitiveInsights.channelId,
            channel: entity.metaData.competitiveInsights.channel,
            tradeAreaTypes: [],
            dashboardIds: [constants.competitiveInsights.dashboards.crossShop, constants.competitiveInsights.dashboards.marketShare, constants.competitiveInsights.dashboards.demographics, constants.competitiveInsights.dashboards.multiSite]
        });              

    }, [entity]);
    */
    
    const initDashboardSettings = useCallback(
        async () =>{

            setLoaded(false);
            
            // get dashboard default settings for the site
            var settings = await ciModule.getDashboardDefaultSettings({ siteId: entity.id, channelId: entity.metaData.competitiveInsights.channelId, channel: entity.metaData.competitiveInsights.channel });

            var rankGeoLevels = [{ id: constants.competitiveInsights.marketType.site, name: translate('site') }];
            var marketGeoLevels = [{ id: constants.competitiveInsights.marketType.site, name: translate('site') }];

            settings.geoLevelList.forEach(geoLevel => {
                rankGeoLevels.push({ id: geoLevel.id, name: geoLevel.name });
                marketGeoLevels.push({ id: geoLevel.id, name: geoLevel.name });
            });

            settings = { ...settings, rankGeoLevels: rankGeoLevels, marketGeoLevels: marketGeoLevels };

            setDefaultSettings(settings);
            setCurrentChannelList(_.cloneDeep(settings.channelList));

            // clear out old data when dashboards are loaded for a new site (entity)
            setSiteDetailsData(null);
            setCrossShopData(null);
            setMarketShareData(null);
            setDemographicsData(null);
            setMultiSiteData(null);
            setDemographicsSettings(null);
            setRankTypeGeoLevelId(null);
            setMarketTypeGeoLevelId(null);

            // set initial tab to site detail dashboard
            setRenderedTabs([constants.competitiveInsights.dashboards.siteDetail]);
            setGoToTab(0);
            setCurrentTab(constants.competitiveInsights.dashboards.siteDetail);
            setEntityId(entity.id);
        },
        [entity]
    );
    
    useEffect(() => {
        initDashboardSettings();
    }, [initDashboardSettings]);
    
    const updateRenderedCharts = useCallback(
        () => {

            // wait to ensure that the charts are updated, then save their refs for report generation
            setTimeout(() => {
                var newRenderedCharts = [];

                if (_.isObject(siteDetailsData)) {
                    siteDetailChartRefs.current.refs.current.forEach(ref => {
                        const renderedChart = _.find(newRenderedCharts, o => o.id === ref.id)
                        if (!_.isObject(renderedChart))
                            newRenderedCharts.push(ref);
                    });
                }
    
                if (_.isObject(marketShareData)) {
                    marketShareChartRefs.current.refs.current.forEach(ref => {
                        const renderedChart = _.find(newRenderedCharts, o => o.id === ref.id)
                        if (!_.isObject(renderedChart))
                            newRenderedCharts.push(ref);
                    });
                }
    
                if (_.isObject(multiSiteData)) {
                    multiSiteChartRefs.current.refs.current.forEach(ref => {
                        const renderedChart = _.find(newRenderedCharts, o => o.id === ref.id)
                        if (!_.isObject(renderedChart))
                            newRenderedCharts.push(ref);
                    });
                }
    
                setRenderedCharts([...newRenderedCharts]);
            }, 2000);

        }, [siteDetailsData, marketShareData, multiSiteData]
    );

    useEffect(() => {
        updateRenderedCharts();
    }, [updateRenderedCharts]);

    const updateDashboardData = useCallback(
        (o) => {
            o.forEach(p => {
                switch (p.id) {
                    case constants.competitiveInsights.dashboards.siteDetail:
                        setSiteDetailsData(p.data);
                        break;
                    case constants.competitiveInsights.dashboards.crossShop:
                        setCrossShopData(p.data);
                        break;
                    case constants.competitiveInsights.dashboards.marketShare:
                        setMarketShareData(p.data);
                        break;
                    case constants.competitiveInsights.dashboards.demographics:
                        //loadDemographicData();    // will be loaded when the Customer Profile tab is selected or when the demographic settings are changed
                        break;
                    case constants.competitiveInsights.dashboards.multiSite:
                        setMultiSiteData(p.data);
                        break;
                    default:
                        break;
                }  
            });
        }, []
    );

    const initDemographicData = useCallback(
        async () =>{

            // check to only run init once to set pointList for demographics, usual flow can rely on demographicsSettings but this can't
            if (demographicsSettings && demographicsSettings.dashboardType === constants.competitiveInsights.dashboards.demographics && demographicsSettings.pointList.length > 0)
                return;

            if (demographicsSettings && demographicsSettings.dashboardType !== constants.competitiveInsights.dashboards.demographics)
                return;

            var settings = { isDirty: true, pointList: [] };
            var newReports = [];

            const reports = await ciModule.getDemographicReports({ pointId: entity.id });

            if (demographicsSettings?.dashboardType === constants.competitiveInsights.dashboards.demographics) {
                settings.pointList = demographicsSettings.list;
                settings.dashboardType = demographicsSettings.dashboardType;
            }

            if (_.isObject(reports.defaultReport))
                settings.defaultReportId = parseInt(reports.defaultReport.ID);
            else 
                settings.defaultReportId = -1;

            if (!_.isNumber(settings.tradeAreaPercentage))
                settings.tradeAreaPercentage = .7;

            if (!_.isNumber(settings.demographicReportId))
                settings.demographicReportId = settings.defaultReportId;

            reports.reports.forEach((report, i) => {
                if (i === 0)
                    newReports.push({
                        disabled: true,
                        text: translate('competitive_insights')
                    });

                newReports.push({
                    id: report.ID,
                    text: report.Name
                });
            });

            reports.organizationReports.forEach((report, i) => {
                if (i === 0)
                    newReports.push({
                        disabled: true,
                        text: translate('organization_reports')
                    });

                newReports.push({
                    id: report.ID,
                    text: report.Name
                });
            });

            setDemographicsSettings(settings);
            setDemographicsReports(newReports);
    
        },
        [entity, demographicsSettings]
    );

    useEffect(() => {
        initDemographicData();
    }, [initDemographicData]);

    const loadDemographicData = useCallback(
        async () =>{
            
            if (currentTab !== constants.competitiveInsights.dashboards.demographics || !demographicsSettings || !demographicsSettings.isDirty)
                return;
            
            setLoaded(false);

            var comparePointIds = _.map(demographicsSettings?.pointList ?? [], "id");
            comparePointIds = _.without(comparePointIds, entity.id);

            var data = await ciModule.getDemographicData({
                pointId: entity.id,
                percent: demographicsSettings.tradeAreaPercentage,
                demographicId: demographicsSettings.demographicReportId,
                comparePointIds: comparePointIds,
                isDefault: demographicsSettings.demographicReportId == demographicsSettings.defaultReportId
            });

            var dataSource = {
                results: []
            };

            data.forEach(row => {
                var cols = {
                    fieldId: row.id,
                    isCategory: row.isCategory,
                    padding: row.padding
                };
                
                row.data.forEach((col, i) =>{
                    cols[i + 1] = col.value;
                });

                dataSource.results.push(cols);
            });

            // Handle CI Selections actions
            if(demographicsSettings.dashboardType != null) {
                setGoToTabByid(demographicsSettings.dashboardType);
                setCurrentTab(demographicsSettings.dashboardType);
            }

            setDemographicsSettings({ ...demographicsSettings, isDirty: false });       

            setDemographicsData({
                data: dataSource.results,
                totalCount: data.length
            });

            setLoaded(true);

        },
        [entity, currentTab, demographicsSettings]
    );
    
    useEffect(() => {
        loadDemographicData();
    }, [loadDemographicData]);

    const setGoToTabByid = async (id) => {

        switch (id) {
            case constants.competitiveInsights.dashboards.siteDetail:
                setGoToTab(0);
                break;
            case constants.competitiveInsights.dashboards.crossShop:
                setGoToTab(1);
                break;
            case constants.competitiveInsights.dashboards.marketShare:
                setGoToTab(2);
                break;
            case constants.competitiveInsights.dashboards.demographics:
                setGoToTab(3);
                break;
            case constants.competitiveInsights.dashboards.multiSite:
                setGoToTab(4);
                break;
            case constants.competitiveInsights.dashboards.report:
                setGoToTab(5);
                break;
            default:
                break;
        }  
    }

    const onTabClick = async (id) => {
        if (id === constants.competitiveInsights.dashboards.report) {
            updateRenderedCharts();
        }
        else if (renderedTabs.indexOf(id) === -1) {
            setLoaded(false);

            setRenderedTabs([ ...renderedTabs, id ]);

            const data = await ciModule.getDashboard({ dashboardId: id, siteId: entity.id, settings: defaultSettings });

            updateDashboardData([{ id: id, data: data }]);

            if (id === constants.competitiveInsights.dashboards.siteDetail)
                setRankTypeGeoLevelId(_.cloneDeep(defaultSettings.rankTypeGeoLevel.id));

            if (id === constants.competitiveInsights.dashboards.marketShare)
                setMarketTypeGeoLevelId(_.cloneDeep(defaultSettings.marketTypeGeoLevel.id));

            setLoaded(true);
        }

        setGoToTabByid(id);
        setCurrentTab(id);
    }

    useEffect(() => {
		(async ()=>{

            // The cross shop dashboard contains a channel filter that allows the user to change the channel list
            // If the channel list changes, request new data
            if (!defaultSettings || currentTab !== constants.competitiveInsights.dashboards.crossShop)
                return;

            const newChannels = defaultSettings.channelList.map(channel => { return channel.id; });
            const oldChannels = currentChannelList.map(channel => { return channel.id; });

            if (helpers.compareArrays(newChannels, oldChannels))
                return;

            setLoaded(false);

            const data = await ciModule.getDashboard({ dashboardId: currentTab, siteId: entity.id, settings: defaultSettings });

            updateDashboardData([{ id: currentTab, data: data }]);
            setCurrentChannelList(_.cloneDeep(defaultSettings.channelList));

            setLoaded(true);

        })();
    }, [entity, defaultSettings, currentChannelList, currentTab, updateDashboardData]);

    useEffect(() => {
        (async ()=>{

            // The site detail dashboard contains a geography filter that allows the user to change the geography level
            // If the geography level changes, request new data
            if (!defaultSettings || entityId !== entity.id || currentTab !== constants.competitiveInsights.dashboards.siteDetail || defaultSettings.rankTypeGeoLevel.id === rankTypeGeoLevelId)
                return;

            setLoaded(false);

            const data = await ciModule.getDashboard({ dashboardId: currentTab, siteId: entity.id, settings: defaultSettings });

            updateDashboardData([{ id: currentTab, data: data }]);
            setRankTypeGeoLevelId(_.cloneDeep(defaultSettings.rankTypeGeoLevel.id));

            setLoaded(true);

        })();
    }, [entity, defaultSettings, entityId, rankTypeGeoLevelId, currentTab, updateDashboardData]);

    useEffect(() => {
        (async ()=>{

            // The market share dashboard contains a geography filter that allows the user to change the geography level
            // If the geography level changes, request new data
            if (!defaultSettings || entityId !== entity.id || currentTab !== constants.competitiveInsights.dashboards.marketShare || defaultSettings.marketTypeGeoLevel.id === marketTypeGeoLevelId)
                return;
                
            setLoaded(false);

            const data = await ciModule.getDashboard({ dashboardId: currentTab, siteId: entity.id, settings: defaultSettings });

            updateDashboardData([{ id: currentTab, data: data }]);
            setMarketTypeGeoLevelId(_.cloneDeep(defaultSettings.marketTypeGeoLevel.id));

            setLoaded(true);

        })();
    }, [entity, defaultSettings, entityId, marketTypeGeoLevelId, currentTab, updateDashboardData]);

    useEffect(() => {
        (async ()=>{

            if (!defaultSettings)
                return;
        
            // Handle CI Selections actions
            if (defaultSettings.dashboardType != null) {
                setGoToTabByid(defaultSettings.dashboardType);

                const data = await ciModule.getDashboard({ dashboardId: defaultSettings.dashboardType, siteId: entity.id, settings: defaultSettings });

                updateDashboardData([{ id: defaultSettings.dashboardType, data: data }]);

                setCurrentTab(defaultSettings.dashboardType);

                setDefaultSettings({...defaultSettings, dashboardType: null });
                setRenderedTabs([]);
            }

        })();
    }, [entity, defaultSettings, currentTab, updateDashboardData]);

    const onChartFileSaving = async (o) => {

        if (!exportMetadata.active) {
            return;
        }

        o.cancel = true;

        var base64 = await helpers.blobToBase64(o.data);

        var data = await ciModule.saveDashboardChartImage({
            siteId: entity.id,
            chartId: o.fileName,
            sessionId: exportMetadata.sessionId,
            base64: base64,
            format: o.format
        });

        // TBD: would like to store image ids in the metadata, but the array does not get updated with mutliple saves firing quickly
        /*
        if (data) {
            const newExport = { sessionId: exportMetadata.sessionId, images: [ ...exportMetadata.images, data ], active: exportMetadata.active };
            setExportMetadata({ ...newExport });
        }
        */
    };

    // TBD: how to properly set fa icon (constructing svg content from icon parts below)?
    const tabs = [
        {
            id: constants.competitiveInsights.dashboards.siteDetail,
            text: translate('site_details'),
            icon: `<svg data-prefix="fas" class="svg-inline--fa" viewBox="0 0 ${icons.locationDot.icon[0]} ${icons.locationDot.icon[1]}"><path fill="currentColor" d="${icons.locationDot.icon[4]}"></svg>`,
            component: <CIDashboard 
                data={siteDetailsData} siteId={entity.id} dashboardId={constants.competitiveInsights.dashboards.siteDetail} 
                settings={defaultSettings} updateSettings={(o) => { setDefaultSettings(o); }}
                loaded={loaded} visible={currentTab === constants.competitiveInsights.dashboards.siteDetail || exportMetadata.active} 
                onChartFileSaving={onChartFileSaving} ref={siteDetailChartRefs}
            />
        },
        {
            id: constants.competitiveInsights.dashboards.crossShop,
            text: translate('cross_shop'),
            icon: `<svg data-prefix="fas" class="svg-inline--fa" viewBox="0 0 ${icons.arrowRightArrowLeft.icon[0]} ${icons.arrowRightArrowLeft.icon[1]}"><path fill="currentColor" d="${icons.arrowRightArrowLeft.icon[4]}"></svg>`,
            component: <CIDashboard 
                data={crossShopData} siteId={entity.id} dashboardId={constants.competitiveInsights.dashboards.crossShop} 
                settings={defaultSettings} updateSettings={(o) => { setDefaultSettings(o); }}
                loaded={loaded} visible={currentTab === constants.competitiveInsights.dashboards.crossShop}
            />
        },
        {
            id: constants.competitiveInsights.dashboards.marketShare,
            text: translate('market_share'),
            icon: `<svg data-prefix="fas" class="svg-inline--fa" viewBox="0 0 ${icons.pieChart.icon[0]} ${icons.pieChart.icon[1]}"><path fill="currentColor" d="${icons.pieChart.icon[4]}"></svg>`,
            component: <CIDashboard 
                data={marketShareData} siteId={entity.id} dashboardId={constants.competitiveInsights.dashboards.marketShare} 
                settings={defaultSettings} updateSettings={(o) => { setDefaultSettings(o); }}
                loaded={loaded} visible={currentTab === constants.competitiveInsights.dashboards.marketShare || exportMetadata.active} 
                onChartFileSaving={onChartFileSaving} ref={marketShareChartRefs}
            />
        },
        {
            id: constants.competitiveInsights.dashboards.demographics,
            text: translate('customer_profile'),
            icon: `<svg data-prefix="fas" class="svg-inline--fa" viewBox="0 0 ${icons.peopleGroup.icon[0]} ${icons.peopleGroup.icon[1]}"><path fill="currentColor" d="${icons.peopleGroup.icon[4]}"></svg>`,
            component: <CICustomerProfileDashboard 
                data={demographicsData} reports={demographicsReports} percents={percents} settings={demographicsSettings} siteId={entity.id} siteName={entity.text} 
                loaded={loaded} visible={currentTab === constants.competitiveInsights.dashboards.demographics} 
                onPercentChanged={(o) => { setDemographicsSettings({ ...demographicsSettings, isDirty: true, tradeAreaPercentage: percents.find((p) => p.id == o.value).value }) }}
                onReportChanged={(o) => { setDemographicsSettings({ ...demographicsSettings, isDirty: true, demographicReportId: parseInt(o.value) }) }}
            />
        },
        {
            id: constants.competitiveInsights.dashboards.multiSite,
            text: translate('multi_site'),
            icon: `<svg data-prefix="fas" class="svg-inline--fa" viewBox="0 0 ${icons.mapLocation.icon[0]} ${icons.mapLocation.icon[1]}"><path fill="currentColor" d="${icons.mapLocation.icon[4]}"></svg>`,
            component: <CIDashboard 
                data={multiSiteData} siteId={entity.id} dashboardId={constants.competitiveInsights.dashboards.multiSite} 
                settings={defaultSettings} loaded={loaded} visible={currentTab === constants.competitiveInsights.dashboards.multiSite || exportMetadata.active} 
                onChartFileSaving={onChartFileSaving} ref={multiSiteChartRefs}
            />
        },
        {
            id: constants.competitiveInsights.dashboards.report,
            text: translate('report'),
            icon: `<svg data-prefix="fas" class="svg-inline--fa" viewBox="0 0 ${icons.fileBarChart.icon[0]} ${icons.fileBarChart.icon[1]}"><path fill="currentColor" d="${icons.fileBarChart.icon[4]}"></svg>`,
            component: <CIDashboardReport 
                siteId={entity.id} settings={defaultSettings} updateSettings={(o) => { setDefaultSettings(o); }} renderedCharts={renderedCharts}
                title={entity.text} percents={percents} reports={demographicsReports} loaded={loaded} visible={currentTab === constants.competitiveInsights.dashboards.report} 
                exportMetadata={exportMetadata} updateExportMetadata={(o) => { setExportMetadata(o); }} updateDashboardData={updateDashboardData}
            />
        }
    ];

    return <>
        <Tabs tabs={tabs} selectedTab={goToTab} onTabClick={onTabClick} />
    </>
}