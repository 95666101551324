// React imports
import { useState, useEffect, Fragment } from 'react';

// App imports
import { LargePanel } from '../largePanel/largePanel';
import { Panel } from '../panel/panel';
import { Button }  from '../button/button';
import { translate } from '../../../utils/translation';
import { icons } from '../icon/icon';

const _ = require("lodash");

export function Wizard({className, steps, size='large', goToStep, completeText, onClose, onNextStep, onPreviousStep, onlyOneStep}) {

    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {

        if (goToStep >= 0 && goToStep < steps.length)
            setCurrentStep(goToStep);

    }, [goToStep, steps.length])

    const close = () =>{
        onClose(); 
    };

    const previousStep = (step) =>{
        if (step > 0) {
            setCurrentStep(step - 1);
            
            if (onPreviousStep) 
                onPreviousStep({ step: step - 1 });
        }
    };

    var nextStep = (step) =>{

        if (step < steps.length - 1)
            setCurrentStep(step + 1);

        if (onNextStep) 
            onNextStep({ step: step + 1 });
    };

    const getActionButtons = (step, i)=>{
        return <>
            { 
                onlyOneStep || i === 0 ? <Button
                    theme='secondary' 
                    size='small' 
                    icon={icons.x} 
                    className={'fa-flip-vertical'} 
                    tooltip={translate('close')} 
                    disabled={false}
                    onClick={() => { close(); }} 
                /> :
                    <Button
                        theme='secondary' 
                        size='small' 
                        icon={icons.arrowTurnDownLeft} 
                        className={'fa-flip-vertical'} 
                        tooltip={translate('back')} 
                        disabled={step.generating}
                        onClick={() => { previousStep(i); }} 
                    />
            }
            <Button 
                theme='primary' 
                size='small' 
                icon={i === steps.length - 1 ? icons.check : icons.arrowTurnDownRight} 
                className={i === steps.length - 1 ? '' : 'fa-flip-vertical'} 
                tooltip={i === steps.length - 1 ? completeText : translate('continue')} 
                disabled={step.continueDisabled || step.generating}
                onClick={() => { nextStep(i); }} 
            />
        </>
    };

    const getSteps = ()=>{
        
        if (onlyOneStep)
            return '';

        return steps.map((step, j) => {            
            var classes = ['app-wizard-step-indicator'];
            
            if (currentStep === j)
                classes.push('app-wizard-step-indicator-active');

            return <div key={j} className={classes.join(' ')} tooltip={step.text} />
        });

    };

    const getLargeTools = (step, i) =>{
        return <div className='app-wizard-header'>
            <div className='app-wizard-step-text'>{step.text}</div>
            <div className='app-wizard-step-progress'>
                {getSteps(step, i)}
            </div>
            <div className='app-wizard-step-actions'>
                {getActionButtons(step, i)}
            </div>
        </div>
    };

    const getSmallTools = (step, i) =>{
        return <div className='app-wizard-header-small'>
            <div className='app-wizard-step-text-small'>{step.text}</div>            
            <div className='app-wizard-step-actions-small'>
                {getActionButtons(step, i)}
            </div>
            <div className='app-wizard-step-progress-small'>
                {getSteps(step, i)}
            </div>
        </div>
    };

    return steps.map((step, i) => {

        switch (size){
            case 'large':
                className = _.isUndefined(className) ? 'app-wizard-tool-bar' : `${className} app-wizard-tool-bar`;
                return <Fragment key={i}>
                {                            
                    ( currentStep === i ? 
                        <LargePanel 
                            className={className}
                            text={step.title} 
                            tools={getLargeTools(step, i)}
                            loaded={step.loaded} 
                            generating={step.generating}                                 
                            children={ step.children }
                            onClose={() =>{ close(); }} 
                        />
                    : '' )
                } 
                </Fragment>
            case 'small':
                return <Fragment key={i}>
                {
                    ( currentStep === i ? 
                        <Panel
                            className={className}
                            open={currentStep === i}
                            text={step.title} 
                            tools={getSmallTools(step, i)}
                            loaded={step.loaded} 
                            generating={step.generating}                                 
                            children={ step.children }
                            onClose={() =>{ close(); }} 
                        />
                    : '' )
                } 
                </Fragment>
        }            
    })
}