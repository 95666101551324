// React imports
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DropDown } from '../../base/dropDown/dropDown';
import { Button } from '../../base/button/button';
import { constants } from '../../../utils/constants';
import { translate } from '../../../utils/translation';
import { icons } from '../../base/icon/icon';
import { RadioButtonSet } from '../../base/radioButtonSet/radioButtonSet';
import { TextBox } from '../../base/textBox/textBox';
import { ColorBox } from 'devextreme-react/color-box';
import { tradeAreas } from '../../../modules/tradeAreas';
import { Loader } from '../../base/loader/loader';
import { ConfirmButton } from '../../base/confirmButton/confirmButton';
import { successToast } from '../../base/toast/toast';
import { legacyEndpoints } from '../../../services/legacyEndpoints';

export function ProjectionSavedShape({ projection, model, onSave }) {
        
    const [newTradeArea, setNewTradeArea] = useState(null);
    const [savedShape, setSavedShape] = useState(translate('modeled_trade_area'));
    const [savedShapes, setSavedShapes] = useState([]);    
    const [saveShapeMode, setSaveShapeMode] = useState(0);   
    const [generating, setGenerating] = useState(false);    

    useEffect(() => {        
        (async () => {
            var defaults = await tradeAreas.getDefaults({ 
                dataSourceId: projection.layer.dataSourceId,
                customQueryId: projection.layer.customQueryId,
                pointId: projection.pointId,
                latitude: projection.location.lat,
                longitude: projection.location.lon
            });

            defaults.defaultTradeArea.fillColor = constants.polygons.selected.fillColor;
            defaults.defaultTradeArea.lineColor = constants.polygons.selected.strokeColor;
            
            setNewTradeArea({...defaults.defaultTradeArea});
            setSavedShape(defaults.savedTradeAreas.length > 0 ? defaults.savedTradeAreas[0] : null)
            setSavedShapes(defaults.savedTradeAreas);
            
        })();
    }, [projection]);

    const changeTradeArea = (tradeArea) => {
        switch(saveShapeMode)
        {
            case 0:
                setNewTradeArea({...tradeArea});
                break;
            case 1:
                setSavedShape({...tradeArea});
                break;
        }
    };

    const saveTradeArea = async () => {

        var tradeArea = null;

        switch(saveShapeMode)
        {
            case 0:
                tradeArea = newTradeArea;
                break;
            case 1:
                tradeArea = savedShape;
                break;
        }

        tradeArea = {...tradeArea, 
            pointId: projection.pointId,
            dataSourceGuid: projection.layer.dataSourceId, 
            type: constants.tradeAreas.types.geography,
            geographyVintage: model.geographyVintage.Id,
            geoLevel: model.geographyVintage.GeoLevels[0].Id,
            geographyIds: model.geographyData.filter(geo => geo.Value.selected).map((geo) => { return geo.Key; }),
            polygon:
            {
                polygonString: "",
                polygonType: constants.encodedString.google
            }
        }

        setGenerating(true);
        await legacyEndpoints.service({
            name: 'SaveAnalyticsProjectionShape',
            parameters: {
                shape: tradeArea
            }
        });
        setGenerating(false);
        successToast(translate('success'));
        onSave();
    };

    const currentTradeArea = useMemo(() =>{

        switch(saveShapeMode)
        {
            case 0:
                return newTradeArea;
            case 1:
                return savedShape;
        }
        
    }, [newTradeArea, savedShape, saveShapeMode]);
        

    return <>
        {
            newTradeArea === null ?  <Loader /> 
        : 
            <>
            <Loader theme='generate' loaded={!generating} />
            <div className='app-projections-save-shape-section-button'>
            {
                saveShapeMode === 0 ? 
                <Button theme='primary' size='tiny' icon={icons.check} onClick={async ()=>{await saveTradeArea()} }/>
                    :
                <ConfirmButton 
                    theme='primary' 
                    size='tiny'
                    icon={icons.check}
                    clearText={translate('overwrite')}
                    onConfirm={async () => { await saveTradeArea(); }}
                />
            }                
            </div>
            <div className='app-projections-save-shape-body'>                    
                <div className='app-projections-save-shape-section'>{translate('action')}</div>
                <RadioButtonSet>
                    <Button theme='secondary' size='medium' text={translate('new')} active={saveShapeMode === 0} icon={icons.star} onClick={()=>{setSaveShapeMode(0);}} />
                    <Button theme='secondary' size='medium' text={translate('overwrite')} active={saveShapeMode === 1} icon={icons.edit} disabled={savedShapes.length === 0} onClick={()=>{setSaveShapeMode(1);}} />
                </RadioButtonSet>
                <DropDown 
                    label={translate('existing_shapes')}
                    display='name'
                    selected={savedShape}
                    items={savedShapes}
                    disabled={saveShapeMode === 0}
                    onChange={(o) => {
                        setSavedShape(o.value);
                    }}
                />              
                <div className='app-projections-save-shape-section'>{translate('properties')}</div>            
                <TextBox label={translate('name')} value={currentTradeArea.name} onChange={(o) => {
                    if (o.userChanged)
                        changeTradeArea({...currentTradeArea, name: o.value}); 
                }} />
                <ColorBox 
                    label={translate('fill_color')} 
                    defaultValue={`rgba(${currentTradeArea.fillColor.r},${currentTradeArea.fillColor.g},${currentTradeArea.fillColor.b},${currentTradeArea.fillColor.a})`} 
                    value={`rgba(${currentTradeArea.fillColor.r},${currentTradeArea.fillColor.g},${currentTradeArea.fillColor.b},${currentTradeArea.fillColor.a})`} 
                    editAlphaChannel={true}
                    onValueChange={(o) => {
                        var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                        changeTradeArea({...currentTradeArea, fillColor: { r: parseFloat(colors[0]), g:parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) }});
                    }} 
                />
                <ColorBox 
                    label={translate('line_color')} 
                    defaultValue={`rgba(${currentTradeArea.lineColor.r},${currentTradeArea.lineColor.g},${currentTradeArea.lineColor.b},${currentTradeArea.lineColor.a})`} 
                    value={`rgba(${currentTradeArea.lineColor.r},${currentTradeArea.lineColor.g},${currentTradeArea.lineColor.b},${currentTradeArea.lineColor.a})`} 
                    editAlphaChannel={true}
                    onValueChange={(o) => {
                        var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                        changeTradeArea({...currentTradeArea, lineColor: { r: parseFloat(colors[0]), g:parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) }});
                    }} 
                />
                <DropDown 
                    label={translate('line_width')} 
                    selected={currentTradeArea.lineWidth} 
                    fieldRender={(data) => {
                        return <>
                            <img alt={translate('line_width')} className='app-shape-edit-line-width-image' src={data ? data.image : ''}/>
                            <TextBox readOnly={true} visible={false}/>
                        </>
                    }}
                    itemRender={(data) => { return <img alt={data.name} src={data.image} />}} 
                    valueProperty='value'
                    onChange={(o) => { 
                        changeTradeArea({...currentTradeArea, lineWidth: o.value});
                    }}
                    items={[1, 2, 3, 4, 5, 6].map((width) => { 
                        return {
                            name: `LineWidth_${width}`,
                            value: width,
                            image: `https://tasonline.com/Content/Media/LineStyle/LineWidth_${width}.gif`
                        }
                    })}
                />
            </div>
            </>
        }
        
        
    </>;    
};