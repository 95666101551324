// React imports
import { useState, useCallback, useRef } from 'react'

// 3rd party imports
import { Autocomplete } from 'devextreme-react/autocomplete';
import CustomStore from 'devextreme/data/custom_store';

// App imports
import { Popover } from '../../base/popover/popover';
import { Button } from '../../base/button/button';
import { TextArea } from '../../base/textArea/textArea';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { legacyEndpoints } from '../../../services/legacyEndpoints';

const _ = require("lodash");

export function AddStore({ parentId, searchId, onStoreSelected }) {

    const ref = useRef(null);

    const [searchQuery, setSearchQuery] = useState('');
	const [lastSearchResults, setLastSearchResults] = useState({data: [], totalCount: 0 });
    const [selectedStore, setSelectedStore] = useState(null);
    const [reasonText, setReasonText] = useState('');
	
	const handleSearchQuery = useCallback(async (e) => {
		setSearchQuery(e.value);
	}, []);

	const store = new CustomStore({		
		load: async (o) => {

			if (o.searchExpr === undefined)
				return lastSearchResults;

			var data = await legacyEndpoints.service({
				name: 'GetCustomQuerySearchList',
				parameters: { 
					SearchId: searchId,
					Search: o.searchValue
				}
			});
	
            const items = data.map((item) => {
                return {
                    id: item,
                    text: item
                };
            });

			var results = {
				data: items,
				totalCount: items.length
			};

			setLastSearchResults(results);

			return results;
		}
	});

    return <>
        <Popover 
            ref={ref}
            className='app-analogs-popup-wrapper'
            parentId={`#${parentId}`}
            title={translate('add_store')}
            height={470}
            width={320}
        >
            <>
                <div className='app-analogs-popup-actions'>
                    <Button 
                        className={'app-analogs-popup-buttons'} 
                        theme='secondary' 
                        size='tiny' 
                        icon={icons.x} 
                        tooltip={translate('cancel')} 
                        onClick={() => { 
                            setSelectedStore(null);
                            setReasonText('');
                            setSearchQuery('');

                            ref.current.instance.hide(); 
                        }} 
                    />
                    <Button 
                        className={'app-analogs-popup-buttons'} 
                        theme='primary' 
                        size='tiny' 
                        icon={icons.check} 
                        tooltip={translate('add')} 
                        disabled={selectedStore == null || reasonText.length === 0} 
                        onClick={() => { 
                            if (onStoreSelected)
                                onStoreSelected({ store: selectedStore, reason: reasonText });

                            setSelectedStore(null);
                            setReasonText('');
                            setSearchQuery('');

                            ref.current.instance.hide();
                        }} 
                    />
                </div>
                <div className='app-analogs-popup-content'>
                    <div className='app-text-box'>
                    <Autocomplete
                        dataSource={store}
                        value={searchQuery}
                        valueExpr="text"
                        minSearchLength={2}
                        height='34px'
                        placeholder={translate('search_store')}
                        onValueChanged={handleSearchQuery}
                        hoverStateEnabled={false}
                        onSelectionChanged={(e) =>{
                            if (_.isObject(e.selectedItem))
                                setSelectedStore(e.selectedItem.text);
                        }}
                    />
                    </div>
                    <TextArea 
                        className='app-analogs-popup-reason' 
                        label={translate('reason')} 
                        value={reasonText} 
                        minHeight={290} 
                        maxHeight={290} 
                        valueChangeEvent={'change keyup'}
                        onChange={(o) => { 
                            setReasonText(o.value); 
                        }} 
                    /> 
                </div>							
            </>
        </Popover>
    </>
}