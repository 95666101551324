import { Entity } from '../entity'; 
import { constants } from '../../utils/constants';
 
const _ = require("lodash");
 
export class GoogleEntity extends Entity {
    constructor(layer, o) {
        super(layer, o);
        this._private._child = this;
        var instance = this;

        const getPaths = (paths)=>{
            return paths.map(location =>{
                return _.isArray(location) ? getPaths(location) : new window.google.maps.LatLng(location.lat, location.lon);
            });
        };

        var activeFill = { r: 245, g: 156, b: 40, a: .3 }; 
        var activeStroke = { r: 245, g: 156, b: 40, a: .6 };

        var selectionFill = { r: 0, g: 165, b: 211, a: .3 };
        var selectionStroke = { r: 0, g: 165, b: 211, a: .6 };

        var focusedFill = { r: 43, g: 50, b: 140, a: .3 };
        var focusedStroke = { r: 43, g: 50, b: 140, a: .6 };

        switch(this._private._type){
            default:
                this._private._entity = { setOptions: () => {} };
                break;            
            case constants.entities.activePoint:                
                this._private._entity = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(this._private._location.lat, this._private._location.lon),
                    icon: {
                        url: `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="${this._private._height}" width="${this._private._width}"> \
                            <rect width='${this._private._width}' height='${this._private._height}' stroke='rgba(${activeStroke.r},${activeStroke.g},${activeStroke.b},${activeStroke.a})' stroke-width="2" fill='rgba(${activeFill.r},${activeFill.g},${activeFill.b},${activeFill.a})' /> \
                        </svg>`,
                        anchor: this._private._anchor ? new window.google.maps.Point(this._private._anchor.x, this._private._anchor.y) : null
                    },
                    map: this._private._layer.map._private._map,
                    visible: this._private._visible,
                    zIndex: this._private._zIndex,
                    clickable: this._private._draggable || this._private._clickable,
                    draggable: this._private._draggable,
                    crossOnDrag: false
                });
                break;
            case constants.entities.focusedPoint:
                this._private._entity = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(this._private._location.lat, this._private._location.lon),
                    icon: {
                        url: `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="${this._private._height}" width="${this._private._width}"> \
                            <rect width='${this._private._width}' height='${this._private._height}' stroke='rgba(${focusedStroke.r},${focusedStroke.g},${focusedStroke.b},${focusedStroke.a})' stroke-width="2" fill='rgba(${focusedFill.r},${focusedFill.g},${focusedFill.b},${focusedFill.a})' /> \
                        </svg>`,
                        anchor: this._private._anchor ? new window.google.maps.Point(this._private._anchor.x, this._private._anchor.y) : null
                    },
                    map: this._private._layer.map._private._map,
                    visible: this._private._visible,
                    zIndex: this._private._zIndex,
                    clickable: this._private._draggable || this._private._clickable,
                    draggable: this._private._draggable,
                    crossOnDrag: false
                });
                break;
            case constants.entities.selectedPoint:

                this._private._entity = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(this._private._location.lat, this._private._location.lon),
                    icon: {
                        url: `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="${this._private._height}" width="${this._private._width}"> \
                            <rect width='${this._private._width}' height='${this._private._height}' stroke='rgba(${selectionStroke.r},${selectionStroke.g},${selectionStroke.b},${selectionStroke.a})' stroke-width="2" fill='rgba(${selectionFill.r},${selectionFill.g},${selectionFill.b},${selectionFill.a})' /> \
                        </svg>`,
                        anchor: this._private._anchor ? new window.google.maps.Point(this._private._anchor.x, this._private._anchor.y) : null
                    },
                    map: this._private._layer.map._private._map,
                    visible: this._private._visible,
                    zIndex: this._private._zIndex,
                    clickable: this._private._draggable || this._private._clickable,
                    draggable: this._private._draggable,
                    crossOnDrag: false
                });
                break;            
            case constants.entities.label:
                this._private._entity = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(this._private._location.lat, this._private._location.lon),
                    icon: {
                        url: `data:image/svg+xml;utf8,${this._private._image}`,
                        anchor: this._private._anchor ? new window.google.maps.Point(this._private._anchor.x, this._private._anchor.y) : null,
                    },
                    map: this._private._layer.map._private._map,
                    visible: this._private._visible,
                    zIndex: this._private._zIndex,
                    clickable: this._private._clickable,
                    draggable: this._private._draggable,
                    crossOnDrag: false
                });
                break;            
            case constants.entities.point:
            case constants.entities.pushpin:
            case constants.entities.genericPoint:
                this._private._entity = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(this._private._location.lat, this._private._location.lon),
                    icon: {
                        url: this._private._image.startsWith("<svg") ? `data:image/svg+xml;utf8,${this._private._image}` : this._private._image,
                        anchor: this._private._anchor ? new window.google.maps.Point(this._private._anchor.x, this._private._anchor.y) : null
                    },
                    map: this._private._layer.map._private._map,
                    visible: this._private._visible,
                    zIndex: this._private._zIndex,
                    clickable: this._private._draggable || this._private._clickable,
                    draggable: this._private._draggable,
                    crossOnDrag: false
                });
                break;
            case constants.entities.cluster:
                this._private._entity = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(this._private._location.lat, this._private._location.lon),
                    icon: {
                        url: this._private._image.startsWith("<svg") ? `data:image/svg+xml;utf8,${this._private._image}` : this._private._image,
                        anchor: this._private._anchor ? new window.google.maps.Point(this._private._anchor.x, this._private._anchor.y) : null,
                        //labelOrigin: { x: this._private._anchor.x, y: this._private._width }
                    },
                    map: this._private._layer.map._private._map,
                    visible: this._private._visible,
                    zIndex: this._private._zIndex,
                    clickable: this._private._draggable || this._private._clickable,
                    draggable: this._private._draggable,
                    crossOnDrag: false,
                    label: {
                        text: this._private._label,
                        className: 'app-map-cluster-label',
                        color: this._private._labelColor ?? 'black',
                        fontSize: this._private._labelFontSize ?? '14px',
                        fontWeight: this._private._labelWeight ?? 'normal'
                     }
                });
                break;
            case constants.entities.geoJson:
                this._private._entity = {
                    id: o.id,
                    data: this._private._data,
                    visible: this._private._visible,
                    fillColor: this._private._fillColor,
                    strokeColor: this._private._strokeColor,
                    strokeWidth: this._private._strokeWidth,
                    clickable: this._private._clickable,
                    setMap: (map) => { 
                        if (map === null)
                            this._private._layer._private._layer.removeEntity(instance);
                    },
                    addListener: (type, event) =>{
                        this._private._layer._private._layer.addListenerToEntity(instance, type, event);
                    },
                    setVisible(value){                        
                        instance._private._layer._private._layer.setEntityVisibility(instance, value);
                    },
                    setOptions(o){
                        instance._private._layer._private._layer.setEntityOptions(instance, o);
                    }
                };
                
                this._private._layer._private._layer.addEntity(this);

                break;
            case constants.entities.polyline:
                this._private._entity = new window.google.maps.Polyline({
                    path: this._private._paths.map(location => { return new window.google.maps.LatLng(location.lat, location.lon) }),
                    map: this._private._layer.map._private._map,
                    visible: this._private._visible,
                    zIndex: this._private._zIndex,
                    strokeColor: `rgba(${this._private._strokeColor.r},${this._private._strokeColor.g},${this._private._strokeColor.b},${this._private._strokeColor.a})`,
                    geodesic: true,
                    clickable: this._private._clickable
                });
                break;
            case constants.entities.circle:
            case constants.entities.polygon:
            case constants.entities.rectangle:
            case constants.entities.standardGeography:
            case constants.entities.activeShape:
            case constants.entities.selectedShape:

                var paths = this._private._paths.length > 1 && _.isArray(this._private._paths[1]) ? getPaths(this._private._paths) : this._private._paths.map(x => { return new window.google.maps.LatLng(x.lat, x.lon) });

                var fillColor = `rgb(${this._private._fillColor.r},${this._private._fillColor.g},${this._private._fillColor.b})`;
                var fillOpacity = this._private._fillColor.a;
                var strokeColor = `rgb(${this._private._strokeColor.r},${this._private._strokeColor.g},${this._private._strokeColor.b})`;
                var strokeOpacity = this._private._strokeColor.a;

                switch(this._private._type){
                    case constants.entities.activeShape:
                        fillColor = `rgb(${activeFill.r},${activeFill.g},${activeFill.b})`;
                        fillOpacity = activeFill.a;
                        strokeColor = `rgb(${activeStroke.r},${activeStroke.g},${activeStroke.b})`;
                        strokeOpacity = activeStroke.a;
                        break;
                    case constants.entities.selectedShape:
                        fillColor = `rgb(${selectionFill.r},${selectionFill.g},${selectionFill.b})`;
                        fillOpacity = selectionFill.a;
                        strokeColor = `rgb(${selectionStroke.r},${selectionStroke.g},${selectionStroke.b})`;
                        strokeOpacity = selectionStroke.a;
                        break;
                }

                this._private._entity = new window.google.maps.Polygon({
                    paths: paths,
                    map: this._private._layer.map._private._map,
                    visible: this._private._visible,
                    zIndex: this._private._zIndex,
                    fillColor: fillColor,
                    fillOpacity: fillOpacity,
                    strokeColor: strokeColor,
                    strokeOpacity: strokeOpacity,
                    strokeWidth: this._private._strokeWidth,
                    geodesic: true,
                    clickable: this._private._clickable
                });

                break;
        }

        var convertGoogleEvent = (e) =>{
            return {
                event: e.domEvent,
                pixel: e.pixel,
                location: { lat: e.latLng.lat(), lon: e.latLng.lng() }
            };
        };
        
        if (_.isFunction(this._private._entity?.addListener)){
            this._private._entity.addListener("click", (e) => this._executeListeners({ type: constants.listeners.click, properties: convertGoogleEvent(e)}));

            this._private._entity.addListener("mouseover", (e) => this._executeListeners({ type: constants.listeners.mouseIn, properties: convertGoogleEvent(e)}));

            this._private._entity.addListener("mouseout", (e) => this._executeListeners({ type: constants.listeners.mouseOut, properties: convertGoogleEvent(e)}));

            if (this._private._draggable)
                this._private._entity.addListener("drag", (e) => {
                   
                    if (!e.pixel || !e.latLng)
                        return;

                    this._executeListeners({ type: constants.listeners.drag, properties: convertGoogleEvent(e)});
                });
        }
    }

    get anchor(){ return super.anchor; }
    set anchor(value){ 

        switch(this._private._type){
            default:
                this._private._entity.setOptions({ 
                    icon: {
                        url: this._private._image.startsWith("<svg") ? `data:image/svg+xml;utf8,${this._private._image}` : this._private._image, 
                        anchor: value ? new window.google.maps.Point(value.x, value.y) : null 
                    }  
                });
                break;
            case constants.entities.label:
                this._private._entity.setOptions({ 
                    icon: {
                        url: `data:image/svg+xml;utf8,${value}`, 
                        anchor: value ? new window.google.maps.Point(value.x, value.y) : null 
                    }  
                });
                break;
        }
        
        super.anchor = value;
    }

    get clickable(){ return super.clickable; }
    set clickable(value){ 
        this._private._entity.setOptions({ clickable: value });
        super.clickable = value;
    }

    get draggable(){ return super.draggable; }
    set draggable(value){ 
        this._private._entity.setOptions({ draggable: value });
        super.draggable = value;
    }

    get fillColor(){ return super.fillColor; }
    set fillColor(value){
        this._private._entity.setOptions({ fillColor: `rgb(${value.r},${value.g},${value.b})` });
        this._private._entity.setOptions({ fillOpacity: value.a });
        super.fillColor = value;
    }

    get image(){ return super.image; }
    set image(value){

        switch(this._private._type){
            default:
                this._private._entity.setOptions({ 
                    icon: {
                        url: value.startsWith("<svg") ? `data:image/svg+xml;utf8,${value}` : value, 
                        anchor: this._private._anchor ? new window.google.maps.Point(this._private._anchor.x, this._private._anchor.y) : null 
                    }  
                });
                break;
            case constants.entities.label:
                this._private._entity.setOptions({ 
                    icon: {
                        url: `data:image/svg+xml;utf8,${value}`, 
                        anchor: this._private._anchor ? new window.google.maps.Point(this._private._anchor.x, this._private._anchor.y) : null 
                    }  
                });
                break;
        }
        
        super.image = value;
    }

    get location(){ 

        if (this._private._entity === null)
            return super.location;

        if (this._private._type === constants.entities.label || this._private._layer.type === constants.layers.types.temporary){
            var location = this._private._entity.getPosition();
            super.location = { lat: location.lat(), lon: location.lng() };
        }

        return super.location; 
    }
    set location(value){

        switch(this.type){
            default:
                break;
            case constants.entities.pushpin:
            case constants.entities.point:
            case constants.entities.label:
            case constants.entities.cluster:
                this._private._entity.setOptions({ position: new window.google.maps.LatLng(value.lat, value.lon) });
                break;
        }

        super.location = value;
    }

    get paths(){ 

        if (this._private._entity === null)
            return super.paths;

        const getPaths = (paths)=>{
            return paths.map(location =>{                
                return _.isFunction(location.getArray) ? getPaths(location.getArray()) : { lat: location.lat(), lon: location.lng() };
            });
        };

        var paths = this.type === constants.entities.polyline ? getPaths(this._private._entity.getPath().getArray()) : getPaths(this._private._entity.getPaths().getArray());
        
        return paths.length === 1 ? paths[0] : paths;
    }

    set paths(value){

        switch(this.type){
            default:
                this._private._entity.setOptions({ paths: value.map(location => { return new window.google.maps.LatLng(location.lat, location.lon) }) });
                break;
            case constants.entities.polyline:
                this._private._entity.setOptions({ path: value.map(location => { return new window.google.maps.LatLng(location.lat, location.lon) }) });
                break;
        }
        
        super.paths = value.length === 1 ? value[0] : value;
    }

    get strokeColor(){ return super.strokeColor; }
    set strokeColor(value){        
        this._private._entity.setOptions({ strokeColor: `rgb(${value.r},${value.g},${value.b}` });
        this._private._entity.setOptions({ strokeOpacity: value.a });
        super.strokeColor = value;
    }

    get strokeStyle(){ return super.strokeStyle; }
    set strokeStyle(value) {
        super.strokeStyle = constants.polygons.defaults.strokeStyle;
    }
    
    get strokeWidth(){ return super.strokeWidth; }
    set strokeWidth(value) {
        this._private._entity.setOptions({ strokeWeight: value });
        super.strokeWidth = value;
    }
    
    get visible(){ return super.visible; }
    set visible(value){
        this._private._entity.setVisible(value);
        super.visible = value;
    }
    
    get zIndex(){ return super.zIndex; }
    set zIndex(value){
        if (_.isFunction(this._private._entity.setZIndex))
            this._private._entity.setZIndex(value);
        else if(_.isFunction(this._private._entity.setOptions))
            this._private._entity.setOptions({ zIndex: value });
        super.zIndex = value;
    }

    dispose(){        
        this._private._entity.setMap(null);
        super.dispose();
    };
};