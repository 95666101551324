import { constants } from "../../utils/constants";

const _ = require("lodash");

export class GoogleDataOverlay {

    constructor(o) {

        o.map._private._dataLayers.push(this);

        this._map = o.map;
        this._googleMap = o.googleMap;
        this._overlay = null;
        this._visible = o.visible;
        this._zIndex = o.zIndex;
        this._entities = [];
        this._clickEntities = [];
        this._mouseoutEntities = [];
        this._mouseoverEntities = [];

        this._overlay = new window.google.maps.Data();
        this._overlay.setMap(this._googleMap);        

        this.refresh = async () => {};
         
        this._map._private._dataLayers = _.sortBy(this._map._private._dataLayers, '_zIndex');

        var convertGoogleEvent = (e) => {  
            var location = { lat: e.latLng.lat(), lon: e.latLng.lng() };      
            return {
                domEvent: e.domEvent,
                event: e.domEvent,
                pixel: this._map.latLonToXY(location),
                location: location,
                latLng: e.latLng,
                entity: e.feature.getProperty('_googleEntity')
            };
        };

        this._overlay.addListener("click", (e) => o.layer._executeListeners({ type: constants.listeners.click, properties: convertGoogleEvent(e)}));
        this._overlay.addListener("mouseover", (e) => o.layer._executeListeners({ type: constants.listeners.mouseIn, properties: convertGoogleEvent(e)}));
        this._overlay.addListener("mouseout", (e) => o.layer._executeListeners({ type: constants.listeners.mouseOut, properties: convertGoogleEvent(e)}));

        o.layer.addListener({
            type: constants.listeners.click,
            action: (e) =>{
                var entity = this._clickEntities.find(x => x.entity.id === e.entity.id);

                if (_.isObject(entity))
                    entity.event(e);
            }
        });

        o.layer.addListener({
            type: constants.listeners.mouseIn,
            action: (e) =>{

                var dataEntity = this._entities.find(x => x.getProperty('_googleEntity').id === e.entity.id);

                this._overlay.overrideStyle(
                    dataEntity, 
                    {
                        fillColor: `rgb(${constants.polygons.hover.fillColor.r},${constants.polygons.hover.fillColor.g},${constants.polygons.hover.fillColor.b})`,
                        fillOpacity: constants.polygons.hover.fillColor.a,
                        strokeColor: `rgb(${constants.polygons.hover.strokeColor.r},${constants.polygons.hover.strokeColor.g},${constants.polygons.hover.strokeColor.b})`,
                        strokeOpacity: constants.polygons.hover.strokeColor.a
                    }
                );

                o.layer.addListener({
                    type: constants.listeners.mouseOut,
                    executeOnce: true,
                    action: () =>{
                        this._overlay.revertStyle(dataEntity);
                    }
                });
             }
        });
    }

    dispose() {

        if (this._overlay === null)
            return;

        this._overlay.setMap(null);
        this._overlay = null;

        this._map._private._dataLayers = this._map._private._dataLayers.filter(overlay => overlay != this);
    }

    addEntity(entity){
        
        entity.data.id = entity.id;

        var dataEntity = this._overlay.addGeoJson(entity.data)[0];
        dataEntity.setProperty('_googleEntity', entity);
        
        if (_.isObject(entity.fillColor))
            this.setEntityFill(entity, entity.fillColor);

        if (_.isObject(entity.strokeColor))
            this.setEntityStroke(entity, entity.strokeClor);

        this._entities.push(dataEntity);
        this.setEntityVisibility({ id: dataEntity.getId() }, entity.visible);
    }

    removeEntity(entity){
        var dataEntity = this._entities.find(x => x.getId() === entity.id);

        this._overlay.remove(dataEntity);
        this._entities = this._entities.filter(x => x != dataEntity);
    }

    setEntityVisibility(entity, visible) {
        var dataEntity = this._entities.find(x => x.getId() === entity.id);

        if (visible)
            this._overlay.add(dataEntity);
        else
            this._overlay.remove(dataEntity);
    }

    setEntityFill(entity, color) {  
        this.setEntityOptions(entity, { fillColor: color});            
    }

    setEntityStroke(entity, color) {
        this.setEntityOptions(entity, { strokeColor: color });
    }

    addListenerToEntity(entity, type, event){
        
        this.setEntityOptions(entity, { clickable: true });

        switch(type)
        {
            case "click":
                this._clickEntities.push({ entity: entity, event: event });
                break;
            case "mouseout":
                this._mouseoutEntities.push({ entity: entity, event: event });
                break;     
            case "mouseover":
                this._mouseoverEntities.push({ entity: entity, event: event });
                break;                   
        }        
    }

    setEntityOptions(entity, options)
    {
        this._overlay.setStyle((feature) => {
                                
            if (feature.getId() === entity.id)
            {
                if (_.isObject(options.fillColor))
                    entity.fillColor = options.fillColor;

                if (_.isObject(options.strokeColor))
                    entity.strokeColor = options.strokeColor;

                if (_.isObject(options.strokeWidth))
                    entity.strokeWidth = options.strokeWidth;

                if (_.isObject(options.clickable))
                    entity.clickable = options.clickable;

                feature.setProperty("_googleEntity", entity);
            }

            var savedEntity = feature.getProperty("_googleEntity", entity);            

            if (!_.isObject(savedEntity))
                return {};

            return {
                fillColor: `rgb(${savedEntity.fillColor.r},${savedEntity.fillColor.g},${savedEntity.fillColor.b})`,
                fillOpacity: savedEntity.fillColor.a,
                strokeColor: _.isObject(savedEntity.strokeColor) ? `rgb(${savedEntity.strokeColor.r},${savedEntity.strokeColor.g},${savedEntity.strokeColor.b})` : null,
                strokeOpacity: _.isObject(savedEntity.strokeColor) ? savedEntity.strokeColor.a : null,
                strokeWidth: _.isNumber(savedEntity.strokeWidth) ?savedEntity.strokeWidth : null,
                clickable: savedEntity.clickable
            };
        });
    }

    setVisible(value){

        if (this._visible === value)
            return;

        this._visible = value;
            
        this._map._private._dataLayers.forEach(layer => layer.refresh());
    }

    setZIndex(value){

        if (this._zIndex === value)
            return;

        this._zIndex = value;

        this._map._private._dataLayers = _.sortBy(this._map._private._dataLayers, '_zIndex');        
        this._map._private._dataLayers.forEach(layer => layer.refresh());
    }
}