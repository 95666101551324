// React imports
import { useState, useEffect } from 'react';

// App imports
import { Wizard } from '../../../../base/wizard/wizard';
import { AdHocCreationWizardStep1 } from './adHocCreationWizardStep1';
import { AdHocCreationWizardStep2 } from './adHocCreationWizardStep2';
import { AdHocCreationWizardStep3 } from './adHocCreationWizardStep3';
import { AdHocCreationWizardStep4 } from './adHocCreationWizardStep4';
import { translate } from "../../../../../utils/translation";
import { adHocManagement as adHocModule } from '../../../../../modules/adHocManagement';
import { constants } from '../../../../../utils/constants';
import { errorToast, successToast } from '../../../../base/toast/toast';

const _ = require('lodash');

export function AdHocCreationWizard({ importedFile = {}, availableGeocoders = [], isImport = false, isUpdate = false, isSpatial = false, onClose }) {

    const reportTitle = isImport ? translate("ad_hoc_import") : translate("ad_hoc_table_builder");
    const [loaded, setLoaded] = useState(true);
    const [continueDisabled, setContinueDisabled] = useState(true);
    const [generating, setGenerating] = useState(false);

    //ad hoc objects
    const [file, setFile] = useState(importedFile);
    const [table, setTable] = useState(_.isEmpty(importedFile) ? adHocModule.getDefaultAdHocTable() : importedFile.tables[0]);
    const [dataSource, setDataSource] = useState(_.isEmpty(importedFile) ? adHocModule.getDefaultAdHocDataSource() : importedFile.tables[0].dataSource);
    const [columns, setColumns] = useState(_.isEmpty(importedFile) ? [] : importedFile.tables[0].columns);
    const [geocoders, setGeocoders] = useState(availableGeocoders);

    const previousStep = (o) => {
    };

    const nextStep = async (o) => {
        if (o.step == 4){
            setGenerating(true);
            
            var response;

            if (isImport) {
                response = await adHocModule.importAdHocDataSource({
                    file: file,
                    table: table,
                    dataSource: dataSource,
                    columns: columns
                });

                response = response[0];
                
            }
            else if (isUpdate) {
                file.tables[0].dataSource = dataSource;
                file.tables[0].columns = columns;
                response = await adHocModule.updateAdHocDataSource({
                    file: file,
                    dataSourceId: file.tables[0].dataSource.id
                });
            }
            else {
                response = await adHocModule.createAdHocDataSource({
                    table: table,
                    dataSource: dataSource,
                    columns: columns
                });
            }
            
            setGenerating(false);

            if (_.isNull(response.errorMessage) || _.isEmpty(response.errorMessage)){   
                if (!_.isNull(response.customQuery?.id)){
                    if (response.customQuery?.autoLoad)
                        adHocModule.mapAdHocLayer({customQueryId: response.customQuery.id, name: response.name, dataSourceType: dataSource.symbolType == constants.symbols.types.shapeStyle ? constants.dataSourceType.UserAdHocShape : constants.dataSourceType.UserAdHocPoint})  
                }
                else {
                    //submitted as job
                    successToast(translate('successfully_submitted_job'));
                }

                onClose();
            }
            else
                errorToast(translate('error'));
        }
    };

    var steps = [
        {
            title: reportTitle, text: translate("ad_hoc_creation_wizard_step_1"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children:
                <AdHocCreationWizardStep1
                    isSpatial={isSpatial}
                    columns={columns}
                    geocoders={geocoders}
                    updateColumns={(value) => setColumns(value)}
                    dataSource={dataSource}
                    updateDataSource={(value) => setDataSource(value)}
                    table={table}
                    onReadyNext={(o) => { setContinueDisabled(!o); }}
                />
        },
        {
            title: reportTitle, text: translate("ad_hoc_creation_wizard_step_2"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children:
                <AdHocCreationWizardStep2 
                    isSpatial={isSpatial}
                    dataSource={dataSource}
                    updateDataSource={(value) => setDataSource(value)}
                    onReadyNext={(o) => { setContinueDisabled(!o); }}
                />
        },
        {
            title: reportTitle, text: translate("ad_hoc_creation_wizard_step_3"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children:
                <AdHocCreationWizardStep3
                    dataSource={dataSource}
                    updateDataSource={(value) => setDataSource(value)}
                    table={table}
                    updateTable={(value) => setTable(value)}
                    onReadyNext={(o) => { setContinueDisabled(!o); }}
                />
        },
        {
            title: reportTitle, text: translate("ad_hoc_creation_wizard_step_4"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children:
                <AdHocCreationWizardStep4 
                    dataSource={dataSource} 
                    columns={columns}
                />
        }
    ];

    return <>
        <Wizard steps={steps} onClose={() => { onClose(); }} onNextStep={(o) => { nextStep(o); }} onPreviousStep={(o) => { previousStep(o) }} />
    </>
}