// React imports
import { Fragment, useEffect, useRef, useState } from 'react';

// Third party imports
import { DndContext, closestCenter, KeyboardSensor,PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

// App imports
import { Layer } from './layer/layer';
import { Button } from '../../base/button/button';
import { TextBox } from '../../base/textBox/textBox';
import { SplitButton } from '../../base/splitButton/splitButton';
import { LayersMenu } from './layersMenu/layersMenu';
import { Bar } from '../../base/bar/bar';
import { map } from '../map/map';
import { icons } from '../../base/icon/icon';
import { constants } from '../../../utils/constants'
import { translate } from '../../../utils/translation';
import { sources } from '../../../modules/sources';
import { thematics } from '../../../modules/thematics';
import { cosmetic } from '../../../modules/cosmetic';
import { selections } from '../../../modules/selections';
import { favorites } from '../../../modules/favorites';
import { customerMaps as customerMapsModule } from '../../../modules/customerMaps';
import { userPreferences } from '../app';
import { hotSpotAnalysis as hotSpotAnalysisModule } from '../../../modules/hotSpotAnalysis';
import { Filters } from '../filters/filters';
import { helpers } from '../../../utils/helpers';

const _ = require("lodash");
const s = require("underscore.string");
export var layers;

export function Layers() {

	const [favoriteName, setFavoriteName] = useState('');
	const [poiLayers, setPoiLayers] = useState([]);
	const [selectionLayers, setSelectionLayers] = useState([]);
	const [cosmeticLayers, setCosmeticLayers] = useState([]);
	const [geofenceLayers, setGeofenceLayers] = useState([]);
	const [dataLayers, setDataLayers] = useState([]);
	const [thematicLayers, setThematicLayers] = useState([]);
	const [trip2TradeLayers, setTrip2TradeLayers] = useState([]);
	const [hotSpotAnalysis, setHotSpotAnalysis] = useState([]);
	const [ai, setAi] = useState([]);
	const [competitiveInsightsLayers, setCompetitiveInsightsLayers] = useState([]);
	const [geoFeedsLayers, setGeoFeedsLayers] = useState([]);
	const [customerMaps, setCustomerMaps] = useState([]);
	const [tradeAreaLayers, setTradeAreaLayers] = useState([]);
	const [filteredGroups, setFilteredGroups] = useState([]);
	const collapsableLayers = useRef([]);

	var availableGroups = [
		{ group: constants.layers.groups.poi, icon: icons.locationDot, layers: poiLayers },
		{ group: constants.layers.groups.selection, icon: icons.lasso, layers: selectionLayers, tooltip: translate('toggle_selections'), showFilter: true },
		{ group: constants.layers.groups.geoFence, icon: icons.fence, layers: geofenceLayers, tooltip: translate('toggle_geofence_layers') },
		{ group: constants.layers.groups.cosmetic, icon: icons.drafting, layers: cosmeticLayers, tooltip: translate('toggle_cosmetic_layers'), showFilter: true },
		{ group: constants.layers.groups.point, icon: icons.database, layers: dataLayers, tooltip: translate('toggle_data_layers'), remove: true, showFilter: true },
		{ group: constants.layers.groups.tradeArea, icon: icons.bullseye, layers: tradeAreaLayers, tooltip: translate('toggle_trade_areas'), remove: true, showFilter: true },
		{ group: constants.layers.groups.thematic, icon: icons.mapLocation, layers: thematicLayers, tooltip: translate('toggle_thematics'), remove: true, showFilter: true },
		{ group: constants.layers.groups.customerMap, icon: icons.peopleGroup, layers: customerMaps },
		{ group: constants.layers.groups.trip2Trade, icon: icons.suitcase, layers: trip2TradeLayers },
		{ group: constants.layers.groups.hotSpotAnalysis, icon: icons.fire, layers: hotSpotAnalysis },
		{ group: constants.layers.groups.ai, icon: icons.brainCircuit, layers: ai },
		{ group: constants.layers.groups.competitiveInsights, icon: icons.database, layers: competitiveInsightsLayers },
		{ group: constants.layers.groups.geoFeeds, icon: icons.braille, layers: geoFeedsLayers },
	];

	layers = {
		update: () =>{		
			setPoiLayers(map.layers.filter(layer => layer.group === constants.layers.groups.poi));
			setCosmeticLayers(map.layers.filter(layer => layer.type === constants.layers.types.cosmetic));
			setTradeAreaLayers(map.layers.filter(layer => layer.group === constants.layers.groups.tradeArea));
			setCustomerMaps(map.layers.filter(layer => layer.group === constants.layers.groups.customerMap));
			setGeofenceLayers(map.layers.filter(layer => layer.type === constants.layers.types.geoFence));
			setTrip2TradeLayers(map.layers.filter(layer => layer.group === constants.layers.groups.trip2Trade));
			setHotSpotAnalysis(map.layers.filter(layer => layer.group === constants.layers.groups.hotSpotAnalysis));
			setAi(map.layers.filter(layer => layer.group === constants.layers.groups.ai));
			setCompetitiveInsightsLayers(map.layers.filter(layer => layer.group === constants.layers.groups.competitiveInsights));
			setGeoFeedsLayers(map.layers.filter(layer => layer.group === constants.layers.groups.geoFeeds));

			if (helpers.isViewer())
				return;

			setSelectionLayers(map.layers.filter(layer => layer.type === constants.layers.types.selection));
		},
		refreshDataLayers: (o)=>{
			setDataLayers([...o.layers]);
		},
		refreshThematicLayers: (o)=>{
			setThematicLayers([...o.layers]);
		}
	};

	const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );	

	const handleDragEnd = (event)=>{ 
		const {active, over} = event;

		if (active.id !== over.id) {
			availableGroups.forEach(group => {	

				var activeLayer = group.layers.find(x => x.id === active.id);
				var overLayer = group.layers.find(x => x.id === over.id);

				if (activeLayer && overLayer) {
					var overZIndex = overLayer.zIndex;
					var layers = [];
					var increment = 0;

					if (activeLayer.zIndex < overZIndex) {
						layers = _.sortBy(group.layers, [function(o) { return o.zIndex; }]);
						increment = -1;
					} else {
						layers = _.sortBy(group.layers, [function(o) { return o.zIndex; }]).reverse();
						increment = 1;
					}

					var shift = false;
					for (var i = 0; i < layers.length; i++) {
						if (layers[i].id === activeLayer.id) {
							shift = true;
						}
						else if (shift) {
							layers[i].zIndex = layers[i].zIndex + increment;
								
							if (layers[i].id === overLayer.id) {
								break;
							}
						}
					}

					activeLayer.zIndex = overZIndex;
				}
			});
        }
	}	

	useEffect(()=>{

		cosmetic.create({
			onRefresh: (o) => { setCosmeticLayers([...o.layers]); }
		});	

		cosmetic.createPoi({
			onRefresh: (o) => { setPoiLayers([...o.layers]); }
		});

        map.addListener({
			type: constants.listeners.viewChange,
			action: () => {
		
				sources.refresh({
					onRefresh: (o) =>{ setDataLayers([...o.layers]); }
				});

				thematics.refresh({
					onRefresh: (o)=>{ setThematicLayers([...o.layers]); }
				});

				customerMapsModule.refreshDynamic({});
				hotSpotAnalysisModule.refresh({});

				map.hideEntityTooltip();
			}
        });

		selections.create({
			onRefresh: (o) => { 
				if (helpers.isViewer())
					return;
		
				setSelectionLayers([...o.layers]); 
			}
		});

    }, []);	

	return <>
		<Filters />
		<div className='app-legend-actions'>
			{
				helpers.isViewer() ? null : 
				<>
					<LayersMenu
						text={translate('data_layers')}
						icon={icons.database}
						getSelected={sources.getSelectedIds}
						onLoad={sources.get}
						onSave={(o) => {
							sources.refresh({
								layers: o.items,
								onRefresh: (o)=>{ setDataLayers([...o.layers]); }
							});
						}}
					/> 
					<LayersMenu
						text={translate('thematic_layers')}
						icon={icons.mapLocation}	
						getSelected={thematics.getSelectedIds}
						notLicensed={!userPreferences.AllowThematics}
						onLoad={thematics.get}
						onSave={(o) => {
							thematics.refresh({
								layers: o.items,
								onRefresh: (o)=>{ setThematicLayers([...o.layers]); }
							});
						}}
					/>
					<LayersMenu
						text={translate('favorite_locations')}
						icon={icons.star}
						showTags={false}
						toolbar={(o)=>{
							return <table className='app-layers-favorite-add'>
								<tbody>
									<tr>
										<td>
											<TextBox className='app-layers-favorite-add-text' placeholder={translate('enter_favorite_name')} value={favoriteName} onChange={(o) => { setFavoriteName(o.value); }} />
										</td>
										<td>
											<Button className='app-layers-favorite-add-button' theme='primary' icon={icons.plus} tooltip={translate('add')} onClick={async ()=>{
												await favorites.add(favoriteName);
												setFavoriteName('');
												o.reload();
											}}/>
										</td>
									</tr>
								</tbody>
							</table>
						}}
						onLoad={favorites.get}
						onLoadRender={(o) =>{
							return o.map(favorite =>{
								return { id: favorite.ID, text: favorite.Name, icon: icons.star, iconClass: 'app-layers-favorite-star', selected: false, zoom: favorite.ZoomLvl, location: { lat: favorite.Latitude, lon: favorite.Longitude } };
							});
						}}
						onDelete={async (o)=>{
							await favorites.delete(o.id);					
						}}
						onSelect={(o)=>{
							return o.items.map(item =>{
								item.selected = o.item.id === item.id;
							});
						}}
						onSave={(o) => {
							var selected = o.items.find(x => x.selected);
							if (!_.isObject(selected))
								return;

							map.zoom = selected.zoom;
							map.center = selected.location;					
						}}
					/>
					<Button className='app-legend-menu-button' theme='simple' icon={icons.chevronsUp} tooltip={translate('collapse_all')} onClick={() =>{ collapsableLayers.current.forEach(x => x?.collapse()); }}/>
					<Button className='app-legend-menu-button' theme='simple' icon={icons.gear} disabled={true} />
				</>
			}
			<div className='app-legend-filters'>
				<Button theme='simple' className='app-legend-actions-button' icon={icons.filter} active={filteredGroups.length === 0} tooltip={translate('toggle_all')} onClick={()=> {
				setFilteredGroups(filteredGroups.length === availableGroups.length ? [...[]] : [...availableGroups.map(x => x.group)]);
				}} />
				{
					availableGroups.filter(x => x.showFilter).map((layerGrouping, i) =>{
						var actions = [{ header: true, text: translate('actions') }, {
							icon: icons.filter,
							text: translate('filter'),
							onClick: () => {
								var groups = filteredGroups;
													
								if (groups.indexOf(layerGrouping.group) !== -1)
									groups = groups.filter(x => x !== layerGrouping.group);
								else
									groups.push(layerGrouping.group);
		
								setFilteredGroups([...groups]);
							}
						}];

						if (layerGrouping.remove)
							actions.push({
								icon: icons.trash,
								text: translate('remove'),
								onClick: () => {
									map.layers.filter(x => x.group===layerGrouping.group).forEach(layer => {
										layer.dispose();
									});
								}
							});

						return layerGrouping.remove ?
						<SplitButton key={i} theme='simple' className='app-legend-actions-button' icon={layerGrouping.icon} active={filteredGroups.indexOf(layerGrouping.group) === -1} tooltip={layerGrouping.tooltip} items={actions} onClick={actions[1].onClick} />
						:
						<Button key={i} theme='simple' className='app-legend-actions-button' icon={layerGrouping.icon} active={filteredGroups.indexOf(layerGrouping.group) === -1} tooltip={layerGrouping.tooltip} onClick={actions[1].onClick} />
					})
				}
			</div>
		</div>
		<div className='app-legend-layers-scroll'>
			<div className='app-legend-layers'>
			{
				availableGroups.map((layerGrouping, i) => {
					collapsableLayers.current = [];
					return filteredGroups.indexOf(layerGrouping.group) !== -1 && layerGrouping.layers.length > 0 ? 
						<Bar key={'filtered' + i} className='app-legend-filtered' text={layerGrouping.layers.length + ' ' + translate('filtered')} icon={layerGrouping.icon} tooltip={layerGrouping.tooltip} onClick={() => { setFilteredGroups([...filteredGroups.filter(x => x !== layerGrouping.group)]); }} /> 
					:  
						layerGrouping.group === constants.layers.groups.point || layerGrouping.group === constants.layers.groups.tradeArea || 
							layerGrouping.group === constants.layers.groups.geoFence || layerGrouping.group === constants.layers.groups.geoFeed || 
							layerGrouping.group === constants.layers.groups.trip2Trade ?
							<Fragment key={i}>
								<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}> 
									<SortableContext items={_.sortBy(layerGrouping.layers, [function(o) { return o.zIndex; }]).reverse()} strategy={verticalListSortingStrategy}>
									{
										_.sortBy(layerGrouping.layers, [function(o) { return o.zIndex; }]).reverse().map(layer =>{ 
											return <Layer 
												key={layer.id} 
												ref={(element) => { collapsableLayers.current.push(element);}} 
												layer={layer} 
												icon={layer?.metaData?.legendIcon ? layer.metaData.legendIcon : layerGrouping.icon} 
												draggable={true}
											/>
										})
									} 
									</SortableContext>
								</DndContext>
							</Fragment>
						:
							_.sortBy(layerGrouping.layers, [function(o) { return o.zIndex; }]).reverse().map(layer =>{ 
								return <Layer 
									key={layer.id} 
									ref={(element) => { collapsableLayers.current.push(element);}} 
									layer={layer} 
									icon={layer?.metaData?.legendIcon ? layer.metaData.legendIcon : layerGrouping.icon} 
									draggable={false}
								/>
							})
				})			
			}
			</div>
		</div>
		
	</>
}