// Third party imports
import { Chart, Size } from 'devextreme-react/chart';

export function LineChart({ data, series, exportOptions, title, width, commonSeriesSettings, argumentAxis, valueAxis, crosshair, legend, tooltip, dataPrepareSettings, chartRef, onFileSaving, onDrawn }) {

    return <Chart
        palette={['#902153', '#2B328C', '#5AB88D', '#C1D42F', '#F59C28', '#EC5D20', '#00A5D3']}
        dataSource={data}
        series={series}
        title={title}
        commonSeriesSettings={commonSeriesSettings}
        argumentAxis={argumentAxis}
        valueAxis={valueAxis}
        crosshair={crosshair}
        legend={legend}
        tooltip={tooltip}
        dataPrepareSettings={dataPrepareSettings}
        export={exportOptions}
        ref={chartRef}
        onFileSaving={onFileSaving}
        onDrawn={onDrawn}
    >
        <Size width={width} />
    </Chart>   
}