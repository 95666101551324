// App imports
import { icons, Icon } from '../../../../base/icon/icon';
import { translate } from '../../../../../utils/translation';
import { mapBooks } from '../../../../../modules/mapbooks';
import { Button } from '../../../../base/button/button';
import { Card } from '../../../../base/card/card';
import { Description } from '../../../../base/description/description';

const _ = require("lodash");

export function MapBookError({data,onClose}) {

    return <Card open={true}>
        { !_.isObject(data) ? '' :
        <div className='app-map-book-error-container'>
            <div className='app-map-book-error-title'>{translate('invalid_mapbook')}</div>
            <Description className='app-map-book-error-description' description={translate('map_book_description')}></Description>
            
            <div className='app-map-book-error-list-container'>
                <div className='app-map-book-error-list'>
                    { data.Errors.length === 0 ? '' : 
                        <div>
                            <div className='app-map-book-error-1st'>{translate('map_book')}</div>
                            <div className='app-map-book-error-mid'>{translate('base_object')}</div>  
                            {
                            data.Errors.map((error, index) => {
                                return ( 
                                        <div>
                                            <div className='app-map-book-error-2nd'>{error.ObjectName}</div>
                                            <div className='app-map-book-error-2nd'>{mapBooks.getErrorObjectTypeName(error.ObjectType)}</div>
                                            {
                                                error.ErrorCodes.map((errorCode,i) => {
                                                    return <div key={i} className='app-map-book-error-2nd'>
                                                        <Icon className='app-map-book-error-warning' icon={icons.warning} />
                                                        {mapBooks.getErrorCodeName(errorCode)}
                                                </div>
                                                })
                                            }
                                        </div>
                                )
                            }) 
                        }  
                        </div>             
                    }

                    { data.ErrorPages.length === 0 ? '' : 
                        <div className='app-map-book-error-error-pages-container'>
                            {
                                data.ErrorPages.map((error, index) => {
                                    return ( <div>                            
                                                <div>
                                                    {error.Bookmark}
                                                </div>
                                                <div>
                                                    {`${translate('page')} ${error.Sequence}`}
                                                </div>                                    
                                            
                                                {
                                                error.Errors.map((pageError) => {
                                                    return <div>
                                                        <div className='app-map-book-error-2nd'>{pageError.ObjectName}</div>
                                                        <div className='app-map-book-error-2nd app-map-book-error-mid'>{mapBooks.getPageErrorObjectName(pageError.ObjectType)}</div>
                                                        {                                            
                                                                pageError.ErrorCodes.map((errorCode,i) => {
                                                                    return <div key={i} className='app-map-book-error-2nd'>
                                                                        <Icon className='app-map-book-error-warning' icon={icons.warning} />
                                                                        {mapBooks.getErrorCodeName(errorCode)}
                                                                    </div>
                                                                })                                                    
                                                        }
                                                    </div>
                                                })
                                                }
                                        </div>
                                    )
                                }) 
                            
                            }
                        </div>             
                    }                  
                </div>        
            </div>

            <div className='app-map-book-error-actions'>
                <Button theme='secondary' size='small' icon={icons.x} tooltip={translate('close')} onClick={() =>{ onClose(); }}/>
            </div>

        </div>
        }       
    </Card>	    
}