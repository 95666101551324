// App imports
import { helpers } from '../utils/helpers';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { map } from '../components/app/map/map';
import { translate } from '../utils/translation';
import { layers } from '../components/app/layers/layers';
import { layerActions } from '../components/app/layers/layer/layer';
import { constants } from '../utils/constants';
import { icons } from '../components/base/icon/icon';
import { activityHub } from '../components/app/activityHub/activityHub';
import { ShapeEdit } from '../components/app/shapeEdit/shapeEdit';
import { cosmetic } from './cosmetic';
import { mapWindow } from '../components/app/map/mapWindow/mapWindow';

const _ = require("lodash");

export const geofences = {    
    getDefault: function(entity) {
        return {
            id: null,
            dataSourceId: entity.layer.parentId, 
            pointId: entity.id, 
            name: '', 
            description: '', 
            fillColor: {
                'r': 0,
                'g': 255,
                'b': 0,
                'a': 0.3
                }, 
            lineColor: {
                'r': 0,
                'g': 255,
                'b': 0,
                'a': 0.8
                }, 
            lineWidth: 1, 
            lineStyle: 'solid', 
            polygonString: '', 
            polygonType: constants.encodedString.google,//todo make dynamic,
            centroid: entity.location,
            allowNotifications: false
        }; 
    },
    get: async function(o) {
		return await legacyEndpoints.service({
			name: 'GetFilteredGeoFences',
            parameters: {
                DataSourceId: o.aServiceID,
                SourceId:  o.sPointID,
                Columns: 'Name,Date,CreatedBy',
                Page: 0,
                ResultCount: 15,
                Filter: '',
                SortColumn: 0,
                SortDirection: 'asc'
            }});
  	         
    },   
    createGeofence: async function(entity, geofence, locations) {
		return await legacyEndpoints.service({
			name: 'CreateGeoFence',
            parameters: {geoFence: {
                dataSourceId: entity.layer.parentId,
                pointId:  entity.id,
                name: geofence.text,
                description: '',
                fillColor: geofence.fillColor,
                lineColor: geofence.strokeColor,
                lineWidth: geofence.strokeWidth,
                lineStyle: geofence.strokeStyle,
                polygonString: helpers.encodedLocations(locations),
                polygonType: constants.encodedString.google,//todo make dynamic
                centroid: entity.map.center,
                allowNotifications: false,
            }
        }});
  	         
    },
    updateGeofence: async function(geofence, o) {
		return await legacyEndpoints.service({
			name: 'UpdateGeoFencePolygonInformation',
            parameters: {
                id: geofence.id,
                name: o.text,
                description: '',
                fillColor: o.fillColor,
                lineColor: o.strokeColor,
                lineWidth: o.strokeWidth,
                lineStyle: o.strokeStyle,
                point: geofence.centroid,          
            }});
  	         
    },
    updateGeoFencePolygon: async function(o) {
		return await legacyEndpoints.service({
			name: 'UpdateGeoFencePolygon',
            parameters: {
                id: o.id,
                polygonString: helpers.encodedLocations(o.locations),
                polygonType: constants.encodedString.google,//todo make dynamic
                point: o.centroid,          
            }});
  	         
    },     
    deleteGeofence: async function(id) {

		await legacyEndpoints.service({
            name: 'DeleteGeoFences',
            parameters: {idList: [ id ]
        }});  	

        geofences.deleteGeofenceEntity(id);

    },
    deleteGeofenceEntity: function(id) {

        var layer = geofences.getLayer();
        if (!layer)
            return;

        var entity = layer.entities.find(x => x.id === id);
        if (entity)
            entity.dispose();
                            
        layer.legend = layer.entities.map(entity => {
            return {
                text: entity.text,
                icon: icons.fence
            }
        });

    },    
    create: () => {
        const geofenceLayer = map.addLayer({
			text: translate('geofence_layer'),
			group: constants.layers.groups.temporary, 
			type: constants.layers.types.geoFence,
			actions: [{
				id: layerActions.visible,
				getActive: () => { 
					return geofenceLayer.visible; 
				},
				onClick: () => {
					geofenceLayer.visible = !geofenceLayer.visible;
				}
			},
			{
				id: layerActions.clear,
				onClick: () =>{
					geofenceLayer.dispose();
				}
			}]
		});

        return geofenceLayer;
    },       
    getLayer: () =>{
		return map.layers.find(layer => layer.type === constants.layers.types.geoFence);
	},     
    refresh: (o) => {        

        var layer = geofences.getLayer();   

        var refreshGeofences = o?.geofences ? o.geofences : layer.entities.map((entity) => {
            return{
                id: entity.id,
                name: entity.text,
                visible: entity.visible,
                polygonString: helpers.encodedLocations(entity.paths),
                fillColor: entity.fillColor,
                lineColor: entity.strokeColor,
                lineWidth: entity.strokeWidth,
                lineStyle: entity.strokeStyle,
                dataSourceId: entity.metaData.dataSourceId,
                pointTitle: entity.metaData.pointTitle,
                metaData: entity.metaData,
                centroid: entity.map.center
            };
        });

        if (!_.isUndefined(layer))
            layer.dispose();

        refreshGeofences.forEach(geofence => {
            geofences.loadGeofence(geofence, geofence.pointTitle, o?.locateGeofence);            
        });

    },      
    loadGeofence: async function (geofence, pointTitle, locateGeofence = true) {
        var layer = geofences.getLayer();
        
        if (!layer) {
            layer = map.addLayer({
                id: crypto.randomUUID(),
                group: constants.layers.groups.geoFence,
                type: constants.layers.types.geoFence,
                text: translate('geofences'),
                visible: geofence.visible,
                actions: [{
                    id: layerActions.visible,
                    getActive: () => { return layer.visible; },
                    getHalfActive: () => { return layer.oppositeVisibilityEntities.length > 0; },
                    onClick: () =>{
                        layer.visible = !layer.visible;
                    }
                },
                {
                    id: layerActions.delete,
                    onClick: () =>{
                        layer.dispose();
                    }
                }],
                onChange: ()=>{
                    layers.update();
                }
            });
        }

        if (!layer.entities.find(x => x.id === geofence.id)) {
            layer.addEntity({
                id: geofence.id,
                text: geofence.name,
                type: constants.entities.polygon,
                paths: geofence.polygonType === constants.encodedString.wkt ? helpers.parseWkt(geofence.polygonString) : helpers.decodeLocations(geofence.polygonString),
                fillColor: geofence.fillColor,
                strokeColor: geofence.lineColor,
                strokeWidth: geofence.lineWidth,
                lineStyle: geofence.lineStyle,    
                metaData: { pointTitle: pointTitle, dataSourceId: geofence.dataSourceId, isCompetitiveInsights: geofence.metaData?.isCompetitiveInsights  },
                onDelete: () =>{
                    geofences.deleteGeofenceEntity(geofence.id);
                },
                onEdit: (o) =>{
                    geofences.updateGeofence(geofence, o);
                    activityHub.selectDefaultTab();
                },        
                onCancel: () =>{
                    activityHub.selectDefaultTab();
                },                        
                onReshape: (o) =>{
                    geofences.updateGeoFencePolygon({id: geofence.id, locations: o.locations, centroid: geofence.centroid });
                },                
                listeners: [{
                    type: constants.listeners.click,
                    action: (e) =>{
                        cosmetic.showActivityHub(e.entity);
                    }
                }]                  
            });

            layer.legend = layer.entities.map(entity => {
                return {
                    text: entity.text,
                    icon: icons.fence,
                    actions: [{
                        id: layerActions.zoom,
                        onClick: () =>{
                            map.locate({ entity: entity });
                        }
                    },
                    {
                        id: layerActions.delete,
                        onClick: () =>{
                            entity.dispose();
                            geofences.refresh();                        
                        }
                    }]
                };
            });            

            layers.update();
        }

        if (locateGeofence)
            map.locate({ location: geofence.centroid, zoom: 19 });

        return layer;
    },   
    drawGeofence: (entity, onComplete) =>{

        const tempGeofences = geofences.create();
   
        map.startDrawing({
            type: constants.entities.polygon,
            onCancel: (o) =>{
                tempGeofences.dispose();
            },
            onFinish: (shape) =>{
    
                var color = helpers.randomColor();
                var layerEntity = tempGeofences.addEntity({
                    id: entity.id,
                    text: entity.name,
                    type: constants.entities.polygon,
                    paths: shape.locations,
                    fillColor: { r: color.r, g: color.g, b: color.b, a: .6 },
                    strokeColor: { r: color.r, g: color.g, b: color.b, a: .8 },  
                    onEdit: async (geofence) =>{                        
                        mapWindow.hide();
                        tempGeofences.dispose();
                    
                        var newGeofence = await geofences.createGeofence(entity, geofence, shape.locations);     
                        geofences.loadGeofence(newGeofence, entity.text);

                        onComplete();

                    },
                    onCancel: async () =>{
                        mapWindow.hide();
                        tempGeofences.dispose();
                    }
                });

                mapWindow.show({
                    content: <ShapeEdit entity={layerEntity} />,
                    title: translate('edit_polygon'),
                    height: '400px',
                    width: '600px',                    
                    resizable: false
                });                
            },          
        });
    },
    loadCompetitiveInsightsGeoFence: async function (o) {     
        const ciGeofence = await legacyEndpoints.service({
            name: 'GetCompetitiveInsightsGeoFence',
            parameters: {
                pointId: o.id
            }
        }); 
        
        ciGeofence.metaData = {isCompetitiveInsights: true};
        geofences.loadGeofence(ciGeofence, o);
    
    }
};
