// React imports
import { useState, useEffect} from 'react';
 
// App imports
import { Panel } from '../../../base/panel/panel';
import { constants } from '../../../../utils/constants';
import { mapBooks } from '../../../../modules/mapbooks';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { Button } from '../../../base/button/button';
import { List } from 'devextreme-react';
import { Icon } from '../../../base/icon/icon';
import { DropDown } from '../../../base/dropDown/dropDown';
import { ConfirmButton } from '../../../base/confirmButton/confirmButton';
import { userPreferences } from '../../app';
 
const _ = require("lodash");

export function MapBooksManager({text, setPanelContents, onClose}) {   
    const [generating, setGenerating] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [filterType, setFilterType] = useState(constants.mapBooks.mapBookGridFilter.all);
    const [items, setItems] = useState([]);

    useEffect(() => {        
        getMapBookList();
    }, [filterType])

    const getMapBookList = async () => {
        setLoaded(false);    
    
        var items = [];
        var data = await mapBooks.getFilteredMapBooksForUser({
            typeFilter: filterType,
            columns: "type,name",
            page: 0,
            resultCount: 100000,
            filter: "",
            sortColumn: 0,
            sortDirection: "asc"
        });
    
        data.mapBooks.forEach((item) => {
            items.push(item);
        });

        setItems([...items]);
    
        setLoaded(true);
    }

    const deleteMapBook = async (mapBook) => {
        setGenerating(true);
        await mapBooks.deleteMapBookForUser({id: mapBook.id});
        setGenerating(false);

        getMapBookList();
    }

    const mapBookDataListRow = (mapBook) =>{
        return <div key={mapBook.id} className='app-map-book-manager-list-item'>
            <div className='app-map-book-manager-list-name-container'>
                <div className='app-map-book-icon'><Icon className="" icon={mapBook.type == constants.mapBooks.type.admin ? icons.lock : icons.bookBookmark} /></div>
                <span className="app-map-book-manager-list-name">{mapBook.name}</span>
            </div>
            {
                userPreferences.AllowMapBookManagement ? 
                <div>
                    <Button theme='simple' icon={icons.edit} tooltip={translate('edit')} disabled={mapBook.type === constants.mapBooks.type.admin} onClick={() =>{setPanelContents({panelId: 5, additionalData: {id: mapBook.id}})}}/>
                    <ConfirmButton theme='simple' align='right' icon={icons.trash} tooltip={translate('delete')} disabled={mapBook.type === constants.mapBooks.type.admin} clearText={translate('delete')} onConfirm={()=>{ deleteMapBook(mapBook); }}/>                    
                </div> : ''
            }
        </div>        
    };

    return <Panel
        className={'app-map-book-manager-panel'} 
        open={true}
        tools={
            <div className='app-map-book-manager-tool-bar'>
                <DropDown label={translate('map_book_filter')} 
                    className={'app-map-book-manager-filter-drop-down'}
                    valueProperty={"key"}
                    display={"name"}
                    selected={filterType}
                    height={'37px'}
                    items={[
                        {key: constants.mapBooks.mapBookGridFilter.all, name: translate("all_available_map_books")},
                        {key: constants.mapBooks.mapBookGridFilter.user, name: translate("only_my_map_books")},
                        {key: constants.mapBooks.mapBookGridFilter.admin, name: translate("only_organization_map_books")}
                    ]}
                    onChange={(o) => {setFilterType(o.value)}}
                />
                <Button className='app-map-book-manager-header-button' theme='secondary' size='small' icon={icons.plus} tooltip={translate('create_new_map_book')} notLicensed={!userPreferences.AllowMapBookManagement} onClick={() =>{setPanelContents({panelId: 5, additionalData: {id: null}})}}/>
            </div>
        }
        text={text} 
        loaded={loaded} 
        generating={generating} 
        onClose={() =>{ onClose(); }}>
            {
                <List
                    className='app-map-book-manager-list'
                    keyExpr={'id'}
                    dataSource={items}
                    itemRender={mapBookDataListRow}
	            	grouped={false}
	            	collapsibleGroups={true}
	            	showSelectionControls={true}
	            	selectionMode={"none"}
	            	selectAllMode={'allPages'}
	            	displayExpr={'name'}
	            	searchEnabled={false}
	            	searchExpr={"name"}
	            	searchMode={'contains'}
	            	selectByClick={false}
	            	pageLoadMode={"scrollBottom"}
	            	focusStateEnabled={false}
	            	activeStateEnabled={false}
	            />
            }
    </Panel>
}
