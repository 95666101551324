import { constants } from './constants';

export var zIndexes = (o) =>{
	switch(o.type){
		default:
			switch(o.subType){
				default:
					return _zIndex[o.type]++;
				case constants.layers.types.thematics.subTypes.range:
					return _zIndex.rangeThematic;
				case constants.layers.types.thematics.subTypes.dotDensity:
					return _zIndex.dotDensityThematic++;
				case constants.layers.types.thematics.subTypes.polygon:
					return _zIndex.polygonThematic++;
			}		
		case constants.layers.types.heat:
		case constants.layers.types.marketShare:
		case constants.layers.types.thematics.parcel:
		case constants.layers.types.thematics.trafficMetrix:
			return _zIndex[o.type];
	}
};

var _zIndex = {
	rangeThematic: 10000,
	[constants.layers.types.customerMaps.marketShare]: 10001,
	[constants.layers.types.customerMaps.heat]: 10002,
	[constants.layers.types.thematics.parcel]: 10003,
	dotDensityThematic: 10004,
	polygonThematic: 11000,
	[constants.layers.types.customerMaps.pin]: 12000,
	[constants.layers.types.thematics.trafficMetrix]: 13000,
	[constants.layers.types.customerMaps.desireLine]: 15000,	
	[constants.layers.types.tradeArea]: 20000,
	[constants.layers.types.modelTradeArea]: 25000,
	[constants.layers.types.competitiveInsightsTradeArea]: 30000,
	[constants.layers.types.geoFence]: 35000,	
	[constants.layers.types.geoFeeds]: 40000,
	[constants.layers.types.trip2Trade]: 45000,
	[constants.layers.types.point]: 50000,	
	[constants.layers.types.label]: 55000,	
	[constants.layers.types.directions]: 60000,
	[constants.layers.types.selection]: 65000,
	[constants.layers.types.other]: 70000,
	[constants.layers.types.temporary]: 75000,
	[constants.layers.types.activeEntity]: 80000,
};