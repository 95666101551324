// React imports
import { useState, useEffect } from 'react';

// App imports
import { Bar } from '../../base/bar/bar';
import { AdHocManager } from './adHocManager/adHocManager';
import { AdHocCreationWizard } from './adHocManager/adHocCreationWizard/adHocCreationWizard';
import { AdHocFlatFileImport } from './adHocManager/adHocCreationWizard/adHocFlatFileImport';
import { AdHocSpatialFileImport } from './adHocManager/adHocCreationWizard/adHocSpatialFileImport';
import { BulkClosestStores } from './bulkClosestStores/bulkClosestStores';
import { BulkDemographics } from './bulkDemographics/bulkDemographics';
import { GeoFeeds } from './geoFeeds/geoFeeds';
import { DrivingDirections } from '../drivingDirections/drivingDirections';
import { GeoFeedCreationWizard } from './geoFeeds/geoFeedCreationWizard/geoFeedCreationWizard';
import { translate } from "../../../utils/translation";
import { icons } from '../../base/icon/icon';
import { userPreferences } from '../app';
import { navigation } from '../navigation/navigation';
import { constants } from '../../../utils/constants';
import { Panel } from '../../base/panel/panel';

const _ = require('lodash');

export var tools;

export function Tools() {

    const [activePanel, setActivePanel] = useState(null);
    const [additionalData, setAdditionalData] = useState(null);
    const [showDrivingDirections, setShowDrivingDirections] = useState(false);

    function setActivePanelBridge(o) {
        if (_.isEmpty(o)) {
            setActivePanel(null);
            setAdditionalData({});
        }
        else {
            setActivePanel(_.isNumber(o.panelId) ? o.panelId : null);
            setAdditionalData(_.isEmpty(o.additionalData) ? {} : o.additionalData);
        }
    }

    useEffect(()=>{
        tools = {            
            openAdhocDatasources: (o) => {
                navigation.forceExpand({                     
                    section: constants.navigation.sections.tools 
                });
                setActivePanelBridge({ panelId: 2 });
            },
            openDrivingDirections: (o) => {
                navigation.forceExpand({                     
                    section: constants.navigation.sections.tools 
                });
                setActivePanelBridge({ panelId: 11, additionalData: o });
            }
        };
    }, []);

    return <>    
        {( showDrivingDirections ? 
            <Panel className={'app-driving-direction-nav-section'} text={translate('driving_directions')} open={true} onClose={()=>{ setShowDrivingDirections(false); }}>
                <DrivingDirections entity={additionalData.entity} add={additionalData.add} onClose={() => { setShowDrivingDirections(false); }} />
            </Panel> : null
        )}
        <Bar text={translate("ad_hoc")} icon={icons.folderArrowUp} notLicensed={!userPreferences.AllowAdHocImporting} onClick={() => { setActivePanelBridge({ panelId: 2 }); }} />      
        <Bar text={translate("bulk_closest_stores")} icon={icons.store} onClick={() => { setActivePanelBridge({ panelId: 6 }); }}/> 
        <Bar text={translate("bulk_demographics")} icon={icons.peopleGroup} notLicensed={!userPreferences.AllowDemoReports} onClick={() => { setActivePanelBridge({ panelId: 7 })}}/> 
        <Bar text={translate("geo_feeds")} icon={icons.braille} onClick={() => { setActivePanelBridge({ panelId: 9 })}}/> 
        <Bar text={translate("driving_directions")} icon={icons.car} onClick={() => { setShowDrivingDirections(true); setAdditionalData({ entity: null }); }} />
        {(activePanel === 2 ? <AdHocManager text={translate("ad_hoc")} setPanelContents={setActivePanelBridge} onClose={() => { setActivePanelBridge(null); }} /> : '')}
        {(activePanel === 4 ? <AdHocCreationWizard
            importedFile={additionalData.importedFile}
            isImport={additionalData.isImport}
            isUpdate={additionalData.isUpdate}
            isSpatial={additionalData.isSpatial}
            availableGeocoders={additionalData.geocoders}
            onClose={() => { setActivePanelBridge({ panelId: 2 }); }}
        /> : ''
        )}
        {(activePanel === 5 ? <AdHocFlatFileImport
            text={translate("ad_hoc_import")}
            onComplete={(reponse) => { setActivePanelBridge({ panelId: 4, additionalData: {importedFile: reponse.files[0], geocoders: reponse.geocoders, isImport: true} }); }}
            onClose={() => { setActivePanelBridge({ panelId: 2 }); }}
        /> : ''
        )}
        {( activePanel === 6 ? <BulkClosestStores text={translate("bulk_closest_stores")} onClose={() => { setActivePanelBridge(null); }} /> : '' )}
        {( activePanel === 7 ? <BulkDemographics text={translate("bulk_demographics")} onClose={() => { setActivePanelBridge(null); }} /> : '' )}
        {( activePanel === 8 ? <AdHocSpatialFileImport
            text={translate("ad_hoc_import")}
            onComplete={() => { setActivePanelBridge({ panelId: 2 }); }}
            onClose={() => { setActivePanelBridge({ panelId: 2 }); }}
        /> : ''
        )}
        {( activePanel === 9 ? <GeoFeeds text={translate("geo_feed_manager")} setPanelContents={setActivePanelBridge} onClose={() => { setActivePanelBridge(null); }} /> : '' )}
        {(activePanel === 10 ? <GeoFeedCreationWizard
            text={translate("geo_feed_manager")}
            geoFeed={additionalData}
            onClose={() => { setActivePanelBridge({ panelId: 9 }); }}
        /> : ''
        )}
        
    </>
}