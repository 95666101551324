// Third party imports
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';

export function ImageDropDown({className, items, display, valueProperty, imageSrc, selected, label, disabled, height, onChange}) {

    var classes = ['app-drop-down'];

    if (className)
        classes.push(className);

    return <div className={classes.join(' ')}>
        <SelectBox dataSource={items}
            displayExpr={display}
            valueExpr={valueProperty}
            value={selected}
            label={label}
            stylingMode='outlined'
            disabled={disabled}
            height={height? height : '40px'}
            onValueChanged={(e)=>{
                if (e.value === null || e.value === e.previousValue)
                    return

                if (onChange)
                    onChange({
                        value: e.value
                    });
            }}
            fieldRender={(data)=>{  
                return (
                    <div className='app-drop-down-image'>
                        {data && data[imageSrc].startsWith("rgb") ?
                            <div className='app-drop-down-color-swatch' style={{ backgroundColor: data[imageSrc] }}></div>
                        :
                            <img src={data && data[imageSrc]} alt="" />
                        }       
                        <TextBox defaultValue={" "} readOnly={true} />
                    </div>
                )
            }}
            itemTemplate={(data)=>{  
                if (data && data[imageSrc].startsWith("rgb"))
                    return "<div class='app-drop-down-color-swatch' style='background-color: " + data[imageSrc] + ";'></div>";
                else
                    return "<div class='app-drop-down-image-src dx-dummy'><img src='" + data[imageSrc] + "' alt='' /></div>";  
            }}
        />
   </div>    
}