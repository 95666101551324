// React imports
import { useRef } from 'react'

export function AutoCloser({className, children, onClose, style}) {

    const container = useRef(null);
    
    var autoClose = (e) => {        

        try{
            if (e.target.parentElement)
            {
                // TBD: this is a hack to prevent clicks on devextreme dropdowns from closing the user profile/preferences components
                var i = Array.from(e.target.parentElement.classList).findIndex((dx) => { return dx.startsWith("dx"); }, "dx");
                if (i >= 0)
                    return;
            }
            
            if (container.current === null || (e.target === container.current || container.current.contains(e.target)))
                return;
            
            if (onClose)
                onClose();
            
            document.removeEventListener("click", autoClose, true);
            document.removeEventListener("click", autoClose, false);
        }
        catch{}       
    };
        
    document.addEventListener("click", autoClose);

	return <div ref={container} className={className} style={style}>
    {
        children
    }
    </div>         
}