// React imports
import { useState } from 'react';

// 3rd party
import Cropper from 'react-easy-crop'

// App imports
import { translate } from '../../../../utils/translation';
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import getEditedImage from './cropImage'

const _ = require("lodash");

export function EditPhoto({photo, imageURL, onSaveEditedImage, onClose}){

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [flipHorizontal, setFlipHorizontal] = useState(false)
  const [flipVertical, setFlipVertical] = useState(false)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const setDefaults = () => {
    setCrop({ x: 0, y: 0 });
    setRotation(0);
    setFlipHorizontal(false);
    setFlipVertical(false);
    setZoom(1);
    setCroppedAreaPixels(null);
  }

  const saveEditedImage = async () => {
      const croppedImage = await getEditedImage(
        imageURL,
        croppedAreaPixels,
        rotation,
        { horizontal: flipHorizontal, vertical: flipVertical },
        photo.type
      )

      onSaveEditedImage(_.cloneDeep(croppedImage));

      setDefaults();
  }

  const close = () => {
    onClose();
    setDefaults();
  }

	return (
    <div className="app-photos-edit-photo">
      <div className="app-photos-edit-photo-container">
        <Cropper
          image={imageURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={4 / 3}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          transform={`translate(${crop.x}px, ${crop.y}px) rotate(${rotation}deg) scale(${zoom}) scaleX(${flipHorizontal ? -1 : 1}) scaleY(${flipVertical ? -1 : 1})` }
        />
      </div>
      <div className='app-photos-edit-photo-controls'>
          <span className='app-photos-edit-photo-slider-label'>{translate('zoom')}</span>
          <div className='app-photos-edit-photo-slider-container'>
              <input
                className="slider"
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                onChange={(e) => {
                  setZoom(e.target.value)
                }}
              />
          </div>
        <span className='app-photos-edit-photo-slider-label'>{translate('rotate')}</span>
          <div className='app-photos-edit-photo-slider-container'>
            <input
              className="slider"
              type="range"
              value={rotation}
              min={0}
              max={360}
              step={1}
              onChange={(e) => {
                setRotation(e.target.value)
              }}
            />
          </div>
        <Button theme='simple' className='app-photos-edit-photo-button' icon={icons.arrowsLeftRight} tooltip={translate('flipHorizontal')} onClick={()=>{setFlipHorizontal(!flipHorizontal) }} />	
        <Button theme='simple' className='app-photos-edit-photo-button' icon={icons.arrowsUpDown} tooltip={translate('flipVertical')} onClick={()=>{setFlipVertical(!flipVertical) }} />	
        <Button theme='simple' className='app-photos-edit-photo-button' icon={icons.arrowsRotate} tooltip={translate('reset')} onClick={()=>{setDefaults() }} />	 
      </div>
      <Button theme='map' size='small' className='app-photos-edit-photo-close-button' icon={icons.x} tooltip={translate('close')} onClick={() =>{ close(); }}/>       
      <Button theme='primary' size='small' className='app-photos-edit-photo-save-button' width='47px' icon={icons.save} tooltip={translate('save')} onClick={() =>{ saveEditedImage(); }}/>
    </div>
  );
}