import React, { Fragment, useRef } from 'react';

// Third party imports
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

// App imports
import { Bar } from '../../../base/bar/bar';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { constants } from '../../../../utils/constants';
import { helpers } from '../../../../utils/helpers';
import { mapControl } from '../../map/mapControl/mapControl';

var _ = require("lodash");

export const layerActions = {	
	active: 0,
	visible: 1,
	zoom: 2,
	label: 3,
	clear: 4,
	delete: 5
};

export const Layer = React.forwardRef(({layer, icon, draggable}, ref) => {	

	const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({id: layer.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? "100" : "auto",
        opacity: isDragging ? 0.3 : 1
    };

	const collapsableLayers = useRef([]);
	
  	React.useImperativeHandle(ref, () => ({ collapse: () =>{
		collapsableLayers.current.forEach(x => x?.collapse());
	}}));
	
	var getActions = (o) =>{

		if (!o.actions)
			return [];

		if (helpers.isViewer())
		{
			o.actions = o.actions.filter(action => 
				action.id !== layerActions.delete &&
				action.id !== layerActions.clear
			);

			if (mapControl.isCurrentMapFixed())
				o.actions = o.actions.filter(action => 
					action.id !== layerActions.zoom
				);
		}
			

		return _.sortBy(o.actions, 'id').map(action =>{	

			var active = action.getActive && action.getActive();
			var halfActive = action.getHalfActive && action.getHalfActive();

			switch (action.id){
				case layerActions.active:
					action.icon = active ? icons.toggleOn : icons.toggleOff;
					action.active = active;
					break;
				case layerActions.visible:
					action.icon = active ? icons.eye : icons.eyeSlash;
					action.active = active || halfActive;
					action.className = halfActive ? 'app-legend-layer-action-half-active' : '';
					break;
				case layerActions.label:
					action.icon = icons.tag;
					action.active = active || halfActive;
					action.className = halfActive ? 'app-legend-layer-action-half-active' : '';
					break;
				case layerActions.zoom:
					action.icon = icons.magnifyingGlass;
					break;
				case layerActions.clear:
					action.icon = icons.eraser;
					break;
				case layerActions.delete:
					action.icon = icons.trash;
					break;
			}
						
			return {
				icon: action.icon,
				active: action.active,
				className: action.className,
				disabled: layer.loading,
				onClick: (e) =>{

					e.stopPropagation();

					if (action.onClick)
						action.onClick({ event: e});
				}
			}
		});
	};

	var layerClasses = ['app-legend-layer'];
	var subText = layer.subText;
	var barText = layer.subText;

	if (!layer.active){
		layerClasses.push('app-legend-layer-empty');
		subText = translate('layer_is_off');
		barText = subText;
	}
	else if (layer.outOfRange){
		layerClasses.push('app-legend-layer-zoom');
		subText = translate('out_of_range');
		barText = subText;
	}
	else if (layer.legend?.length === 0) {
		switch(layer.type){
			default:
				layerClasses.push('app-legend-layer-empty');
				subText = translate('no_data_in_view');
				barText = subText;
				break;
			case constants.layers.types.cosmetic:
			case constants.layers.types.selection:
				layerClasses.push('app-legend-layer-empty');
				subText = null;
				barText = translate('no_data_in_view');
				break;
		}		
	}		

	if (draggable)
		layerClasses.push('app-legend-layer-draggable');

	collapsableLayers.current = [];

	return <div ref={setNodeRef} key={layer.id} style={style}>
		<Bar ref={(element) => { collapsableLayers.current.push(element);}} className={layerClasses.join(' ')} key={layer.id} id={layer.id} text={layer.text} subText={layer.subText ? subText : null} icon={icon} loading={layer.loading} actions={getActions({ actions: layer.actions })} draggable={draggable} attributes={attributes} listeners={listeners}>
			{(
				layer.toolbars?.length > 0 && helpers.isViewer() === false ?
				<div className='app-legend-layer-body-toolbar'>
					<table className='app-legend-layer-table'>
						<tbody>
						{
							layer.toolbars.map((toolbar, i) =>{
								return <tr key={i}>
									<td>
									{
										toolbar.actions.map((action, j) =>{
											return <Fragment key={j}>{action}</Fragment>
										})
									}
									</td> 
								</tr>
							})
						}										
						</tbody>
					</table>
				</div>
				: ''
			)}
			{
				layer.legend?.length === 0 ? <Bar ref={(element) => { collapsableLayers.current.push(element);}} className='app-legend-layer-chain' text={barText} icon={icons.warning} /> :
				layer.legend?.map((chain, i) =>{
					return(
						<Bar key={i} ref={(element) => { collapsableLayers.current.push(element);}} className='app-legend-layer-chain' text={chain.text} icon={chain.icon} color={chain.color} iconWidth={chain.iconWidth} iconClass={chain.iconClass} actions={getActions({ actions: chain.actions })}>
						{
							chain.items?.map((item,i) =>{
								return <Bar key={i} className='app-legend-layer-point' text={item.text} expandable='false' onClick={item.onClick} actions={getActions({ actions: item.actions })}/>
							})
						}
						</Bar>
					)
				})
			}
		</Bar>
	</div>
})