// React imports
import { useEffect } from 'react';

//App imports
import { translate } from '../../../../../utils/translation';
import { icons } from '../../../../base/icon/icon';
import { Icon } from '../../../../base/icon/icon';
import { Bar } from '../../../../base/bar/bar';
import { geoFeeds as geoFeedsModule } from '../../../../../modules/geoFeeds';

const _ = require('lodash');

export function GeoFeedCreationWizardStep3({geoFeed,updateGeoFeed,onReadyNext}) {
    
    useEffect(() => {
        if (onReadyNext)
                onReadyNext(true);
    }, [onReadyNext]) 

    return <div className='app-geo-feeds-creation-wizard-container'>
                <div className='app-geo-feeds-creation-wizard-rows'>
                    {
                        [
                            { text: translate('geo_feed_name'), subText: geoFeed.name },
                            { text: translate('geo_feed_type'), subText: geoFeedsModule.getType(geoFeed.type) },
                            { text: translate('sources'), subText: geoFeed.sources.length  }
                        ].map((source, i) => {
                            return (
                                <div key={i} className='app-option-selector'>
                                    <div className='app-option-selector-check'>
                                        <Icon icon={icons.edit} />
                                    </div> 
                                    <Bar className={'app-geo-feeds-bar-buttons app-geo-feeds-bar-long'} 
                                        text={source.text}      
                                        subText={source.subText}                               
                                        onClick={() => { 
                                            
                                        }} 
                                        disabled={false} 
                                    /> 
                                </div>
                            );
                        })
                    }
                </div>          
    </div>
}