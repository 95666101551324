// React imports
import { useEffect } from 'react';

//App imports
import { SymbolManager } from "../../../../base/symbolManager/symbolManager";
import { constants } from '../../../../../utils/constants';
import { ShapeEdit } from '../../../shapeEdit/shapeEdit';

const _ = require('lodash');

export function AdHocCreationWizardStep2({dataSource, updateDataSource, onReadyNext, isSpatial}){
    
    useEffect(() => {
        if (!isSpatial && dataSource.symbolType !== constants.symbols.types.stock && dataSource.symbolType !== constants.symbols.types.custom)
        {
            dataSource.symbolType = constants.symbols.types.stock;
            dataSource.symbolUrl = "images/symbols/20x20/cir_l-grn_20x20.png";
            updateDataSource(_.cloneDeep(dataSource));
        }
        else if (isSpatial && dataSource.symbolType !== constants.symbols.types.shapeStyle)
        {
            dataSource.symbolType = constants.symbols.types.shapeStyle;
            dataSource.symbolStrokeWidth = 2;
            dataSource.symbolStrokeColor = {r: 0, g: 0, b: 0, a: 1};
            dataSource.symbolFillColor = {r: 0, g: 0, b: 0, a: 0.3};
            updateDataSource(_.cloneDeep(dataSource));
        }

        if (onReadyNext && !isSpatial)
            onReadyNext(_.isString(dataSource.symbolUrl) && !_.isNull(dataSource.symbolType));
        else if (onReadyNext && isSpatial)
            onReadyNext(dataSource.symbolStrokeWidth > 0 && !_.isEmpty(dataSource.symbolStrokeColor) && !_.isEmpty(dataSource.symbolFillColor) && !_.isNull(dataSource.symbolType))
    }, [dataSource, updateDataSource, onReadyNext, isSpatial])
    
    return <div className='app-ad-hoc-creation-step-container'>
        {
            isSpatial ? 
                <ShapeEdit showName={false} entity={{strokeWidth: dataSource.symbolStrokeWidth, strokeColor: dataSource.symbolStrokeColor, fillColor: dataSource.symbolFillColor, onEdit: (o) => {
                    dataSource.symbolFillColor = o.fillColor;
                    dataSource.symbolStrokeWidth = o.strokeWidth;
                    dataSource.symbolStrokeColor = o.strokeColor;
                    updateDataSource(_.cloneDeep(dataSource));
                } }} /> : 
                <SymbolManager 
                symbol={dataSource.symbolUrl} 
                onChange={(symbolUrl) => { 
                    dataSource.symbolUrl = symbolUrl;
                    dataSource.symbolType = constants.symbols.types.stock;
                    updateDataSource(_.cloneDeep(dataSource));
                }} 
            />
        }
    </div>
}