import Notify from 'devextreme/ui/notify';

export const errorToast = (message, delay) =>{
    toast({ message: message, delay: delay, type: 'error' });
};

export const successToast = (message, delay) =>{
    toast({ message: message, delay: delay, type: 'success' });
};

export const infoToast = (message, delay) =>{
  toast({ message: message, delay: delay, type: 'info' });
};

export const warningToast = (message, delay) =>{
  toast({ message: message, delay: delay, type: 'warning' });
};

export const toast = ({ message, type, delay = 2000 }) =>{
    Notify({
        message: message,
        width: 'auto',
        minWidth: '250px',
        type: type,
        displayTime: delay
      }, 
      {
        position: 'bottom right',
        direction: 'up-push'
      }
    );
};