// React imports
import { useEffect, useState, useCallback, useRef} from 'react';

// App imports
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { Button } from '../../base/button/button';
import { Loader } from '../../base/loader/loader';
import { DataGrid } from '../../base/dataGrid/dataGrid';
import { DropDown } from '../../base/dropDown/dropDown';
import { projects as projectsModule } from '../../../modules/projects';
import { successToast, errorToast } from '../../base/toast/toast';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { helpers } from '../../../utils/helpers';
import { constants } from '../../../utils/constants';

const _ = require('lodash');

export function SharedUsers({objectId,sharingObjectType,onClose,gridHeight="520px",showCancelButton=true}) {
    const [listData, setListData] = useState([]);
    const [sharedUsers, setSharedUsers] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [sharingPeerType, setSharingPeerType] = useState(constants.sharingPeerType.all);
    const grid = useRef(null);

    const sharingPeerTypes =
    [
        { name: translate('user_roles'), id: constants.sharingPeerType.all},
        { name: translate('only_users'), id: constants.sharingPeerType.user},
        { name: translate('only_roles'), id: constants.sharingPeerType.shared}
    ];

    const getUsers = useCallback(async () => {
        if(objectId == null) return;

        setLoaded(false);

        var userData = await projectsModule.getFilteredShareableObjectsForUser({objectId: objectId, objectType: sharingObjectType, sharingPeerType: sharingPeerType});
        setListData(userData);

        setLoaded(true);

    }, [sharingPeerType, objectId, sharingObjectType]); 

    useEffect(() =>{
        getUsers();
    }, [getUsers]);

    useEffect(() =>{
        if(listData?.peers == null) return;

        //todo need to keep selected shared users on type dropdown change. Needs work since DataGrid onSelectionChanged() will lose selected users on role filter.
        const initSharedUsers = listData.peers.filter(x => x.shared);
        setSharedUsers(initSharedUsers); 

    }, [listData]);    
    const share = () =>{
        const o = {
            Id: objectId,
            Type: sharingObjectType, 
            Behavior: 1,
            GlobalPermissions: [],
            SharingPeers: sharedUsers.map(x => ({ 
                id: x.id, 
                type: x.type, 
                permissions: [] })),
        }

        const success = projectsModule.shareObject(o);

        if (success)
            successToast(translate('success'));
        else
            errorToast(translate('an_error_has_occurred'));

        close();        
    };

    const close =() =>{ 
        setSharedUsers([]); 
        setSharingPeerType(constants.sharingPeerType.all);  
        grid.current.instance.state({});  //todo need a way to 'reset' the grid for things like search filters. This is likely a bad way since it exposes the devexpress grid publicly 
        if(_.isFunction(onClose))
            onClose();  
    };

    const getColumns = () => {
        return [                                                                                                                                               
            {
                name: 'name',
                caption: translate('name'),
                allowFiltering: true,
                onRender: (o) => 
                {
                    var userName = o.name;                    
                    var userPhotoUrl = legacyEndpoints.handlers.getPhotoUrl({ userId: o.id, isUserPhoto: true, width: 40, height: 40 });

                    if (o.id === helpers.emptyGuid())
                    {
                        userName = translate('system');
                        userPhotoUrl = "https://cdn.tradeareasystems.net/Images/Rebrand/KalibrateHeader.png";
                    }

                    return (<>
                        <img className='app-shared-users-user-image' src={userPhotoUrl} alt={translate('user_image')} />
                        <span className='app-shared-users-username'>
                            {`${userName} (${o.filterName})`}
                        </span>
                    </>);
                }
            }            
        ];
    }

    return  <>
        <Loader loaded={loaded} />  
        <div className='app-shared-users-header'>
            <div className='app-shared-users-title'>
                {translate("shared_users")}
            </div> 
            <div className='app-shared-users-actions'>
                {showCancelButton ? <Button theme='secondary' size='small' icon={icons.x} tooltip={translate('close')} onClick={() =>{ close(); }}/> : ''}  
                <Button theme='primary' size='small' icon={icons.check} tooltip={translate('share')} onClick={() =>{ share(); }}/> 
            </div>    
        </div>
        <div className='app-shared-users-container'>
            <div className='app-shared-users-rows'>
                <DropDown className='app-shared-peer-types-dropdown' 
                    height='47px' 
                    items={sharingPeerTypes} 
                    selected={sharingPeerType} 
                    label={translate('type')} 
                    display='name' 
                    valueProperty='id'
                    onChange={(o) => { setSharingPeerType(o.value)} }/>  
                <span className='app-shared-users-selected-label'>{`${translate('selected')}:`}</span>
                <span className='app-shared-users-selected-count'>{`${sharedUsers.length} `}</span>
            </div>   
            {
                !loaded ? '' :
                    <DataGrid 
                        ref={grid}
                        height={gridHeight}
                        keyExpr={'id'}
                        showFilterRow={true}
                        remoteOperations={'false'}
                        showScrollbar='onHover'
                        defaultPageSize={8}
                        selectionMode='multiple'  
                        selectedItems={sharedUsers.map(x => x.id)}   
                        onSelectionChanged={(o) => {
                            setSharedUsers(o.selectedRowsData);
                        }}           
                        columns={getColumns()}
                        items={listData}
                        onLoad={async (o)=>
                        {
                            return {
                                data: listData?.peers,
                                totalCount: listData?.TotalRecords
                            };
                        }}
                    />         
            }
        </div>
    </>
}