// React imports  
import  React, { useEffect, useCallback, useState, useRef } from 'react';

// App imports
import { DataList } from '../../../base/dataList/dataList';
import { projects as projectsModule } from '../../../../modules/projects';
import { helpers } from '../../../../utils/helpers';
import { icons, Icon } from '../../../base/icon/icon';
import { Loader } from '../../../base/loader/loader';
import { translate } from '../../../../utils/translation';
import { Button } from '../../../base/button/button';
import { KLIDataSource } from '../../../base/dataSource/dataSource';

const _ = require("lodash");

export var mapControl;

export function MapSearch({onClose}) {
	
    const [loaded, setLoaded] = useState(false);
    const [availableMaps, setAvailableMaps] = useState(null);
    const [frequentlyUsedMaps, setFrequentlyUsedMaps] = useState(null);
    const [mapList, setMapList] = useState([]);
    const [groupName, setGroupName] = useState(null);
    const showingFrequentlyUsed = useRef(true);

    var getUsersMaps = useCallback(
        async () => {
            setLoaded(false);

            const mapElements = [];
            const promises = [];

            promises.push(
                new Promise(async (resolve) => {
                    const maps = await projectsModule.getMaps();

                    const frequentMaps = getFrequentlyUsedMaps(maps, 5);
                    setFrequentlyUsedMaps(frequentMaps);
                    
                    if(showingFrequentlyUsed.current)
                        setMapList(frequentMaps);

                    mapElements.push(...maps);
                    resolve();
            }));

            promises.push(
                new Promise(async (resolve) => {
                    const sharedMaps = await projectsModule.getSharedMaps();
                    const updatedSharedMaps = sharedMaps.map(map => ({
                        ...map,
                        isShared: true
                    }));

                    mapElements.push(...updatedSharedMaps);
                    resolve();
            }));
            
            await Promise.all(promises);

            const mapData = await getStructuredMapDataForDisplay(mapElements);

            const flatMapData = transformGroupDataToPlain(mapData);
            setAvailableMaps(flatMapData);

            if(!showingFrequentlyUsed.current)
                setMapList(flatMapData);

            setLoaded(true);
        },
        []
    );    

    const getFrequentlyUsedMaps = (folders, count) => {
        const items = folders
            .flatMap(folder => folder.Maps)
            .sort((a, b) => {
                const lastAccessDateA = a.LastAccessDate ? new Date(parseInt(a.LastAccessDate.replace('/Date(', '').replace(')/', ''), 10)) : new Date(0);
                const lastAccessDateB = b.LastAccessDate ? new Date(parseInt(b.LastAccessDate.replace('/Date(', '').replace(')/', ''), 10)) : new Date(0);
                return lastAccessDateB - lastAccessDateA;
            })
            .slice(0, count);

            return [{key: translate('frequently_used'), items: items}];
    };    

    var store = new KLIDataSource({		
        store: mapList,
        group: groupName
	});

    useEffect(() =>{
        getUsersMaps();
    },[getUsersMaps]);

    var getStructuredMapDataForDisplay = async (data) => {
        var sources = [];
        data.forEach((folder) => {
            var group = {
                key: folder.Name,
                items: []
            };
            
            folder.Maps.forEach(map => {
                var child = {
                    Id: map.Id,
                    Name: map.Name,
                    searchText: map.Name,
                    Description: map.Description,
                    LastModified: map.LastModified,
                    isShared: folder.isShared,
                };
                group.items.push(child);
            });

            sources.push(group);
        });
        return sources;
    }

    const loadMap = (id) => {
        if (id) {
            projectsModule.loadMap({ mapId: id });
            onClose();
        }
    };

    const reload = () => {
        getUsersMaps();
    };

    const transformGroupDataToPlain = (groupData) => {
        let plainData = [];
        groupData.forEach((g) => {
          g.items.forEach((n) => {
            n.groupName = g.key;
          });
          plainData = [...plainData, ...g.items];
        });
        
        return plainData;
    };

    return <>
        <div className="app-map-map-control-search-container">
            <div className="app-map-map-control-search-button-container">
                <Button
                    theme="action"
                    icon={icons.repeatArrow}
                    tooltip={translate('reload')}
                    onClick={reload}
                />
            </div>       
            <div>
                <Loader theme='generate' className='app-card-body-loader app-map-map-control-search-loader-container' loaded={loaded} />
                <div className="app-map-map-control-search-datalist-container">
            </div>
                <DataList
                    keyExpr={'Id'}
                    grouped={true}
                    selectionMode={'single'}
                    showSelectionControls={false}
                    dataSource={store}
                    searchExpr='Name'
                    height={'auto'}
                    itemRender={(item) =>{
                        return <>
                            <Icon className='app-map-map-control-search-icon' icon={icons.map} />            
                            <span className='app-map-map-control-search-text'>{item.Name}</span> 
                            <span className='app-map-map-control-search-text-description'>{(item.Description ? `${item.Description}` : '')}</span>
                            <span className='app-map-map-control-search-text-date'>{helpers.formatDate({ date: item.LastModified })}</span>
                        </>
                    }}
                    groupRender={(item) =>{
                        const hasSharedItems = item.items.some(map => map.isShared);
                        return <>
                            <span className='app-map-map-control-search-folder-text'>{item.key}</span> 
                            <span className='app-map-map-control-search-folder-shared-text'>{hasSharedItems ? translate('shared_maps') : ''}</span>
                        </>
                    }}                
                    selectedItemsChange={(o)=> {
                        if(o && o.length > 0)   
                            loadMap(o[0]);
                    }}
                    searchValueChange={(o) => {
                        if (o.length > 0 && showingFrequentlyUsed.current === true) {  
                            showingFrequentlyUsed.current = false;
                            setGroupName('groupName');
                            setMapList(availableMaps);
                        }
                        else if (o.length === 0 && showingFrequentlyUsed.current === false) {
                            showingFrequentlyUsed.current = true;
                            setGroupName(null);
                            setMapList(frequentlyUsedMaps);
                        }                        
                    }}
                    selectByClick={true}
                />               
            </div>
        </div>
	</>
}