import { BulkInfo } from '../components/app/bulkInfo/bulkInfo';
import { BulkEdit } from '../components/app/bulkInfo/bulkEdit';
import { workBench } from '../components/base/workBench/workBench';
import { constants } from '../utils/constants';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { Loader } from '../components/base/loader/loader';

const _ = require("lodash");

export const bulkInfo = {
    getInfoFormColumns: (o) =>{

        var columns = [];

        if (_.isArray(o.data))
            o.data.forEach(field =>{

                var tab = columns.find((item) => item.id === field.tabId);
                if (!tab) {
                    tab = {
                        id: field.tabId,
                        caption: field.tabTitle,
                        allowFiltering: false,
                        allowSorting: false,
                        allowEditing: false,
                        columns: []
                    };
                    columns.push(tab);
                }

                var section = tab.columns.find((item) => item.id === field.sectionId);
                if (!section) {
                    section = {
                        id: field.sectionId,
                        caption: field.sectionTitle,
                        allowFiltering: false,
                        allowSorting: false,
                        allowEditing: false,
                        columns: []
                    };
                    tab.columns.push(section);
                }

                var dataType = "";

                switch(field.type) {
                    case constants.forms.components.date:
                        dataType = "date";
                        break;
                    case constants.forms.components.numeric:
                        dataType = "number";
                        break;
                    case constants.forms.components.checkbox:
                        dataType = "boolean";
                        break;
                    default:
                        dataType = "string";
                        break;
                }   
                
                var column = {
                    name: field.id,
                    caption: field.label,
                    dataType: dataType,
                    allowFiltering: true,
                    allowSorting: true,
                    allowEditing: false
                }

                section.columns.push(column);

            });

        return columns;
    },
    generateBulkInfo: async (o) =>{

        const result = workBench.setContent({ content: <Loader />, height: '500px', maxHeight: '800px', dragEnabled: false });

        if (!result)
            return;

        var data = await legacyEndpoints.service({
            name: 'GetFilteredBulkInfoForm',
            parameters: {
                customQueryId: o.customQueryId
            }
        });

        var columns = bulkInfo.getInfoFormColumns({ data: data });

        workBench.setContent({ 
            title: o.title,
            content: <BulkInfo customQueryId={o.customQueryId} columns={columns} />,
            height: '500px',
            maxHeight: '800px',
            dragEnabled: false
        });
    },
    getEditFormColumns: (o) =>{

        var columns = [];

        if (_.isArray(o.data))
            o.data.forEach(field =>{

                var tab = columns.find((item) => item.id === field.tabId);
                if (!tab) {
                    tab = {
                        id: field.tabId,
                        caption: field.tabTitle,
                        allowFiltering: false,
                        allowSorting: false,
                        allowEditing: false,
                        columns: []
                    };
                    columns.push(tab);
                }

                var section = tab.columns.find((item) => item.id === field.sectionId);
                if (!section) {
                    section = {
                        id: field.sectionId,
                        caption: field.sectionTitle,
                        allowFiltering: false,
                        allowSorting: false,
                        allowEditing: false,
                        columns: []
                    };
                    tab.columns.push(section);
                }

                var dataType = "";
                var lookup = null;
                var allowEditing = true;

                switch(field.type) {
                    case constants.forms.components.text:
                        dataType = "string";
                        break;
                    case constants.forms.components.label:
                        dataType = "string";
                        allowEditing = false;
                        break;
                    case constants.forms.components.date:
                        dataType = "date";
                        break;
                    case constants.forms.components.numeric:
                        dataType = "number";
                        break;
                    case constants.forms.components.currency:
                        dataType = "number";
                        break;
                    case constants.forms.components.percentage:
                        dataType = "number";
                        break;
                    case constants.forms.components.checkbox:
                        dataType = "boolean";
                        break;
                    case constants.forms.components.list:
                        dataType = "string";

                        if (field.listValues.length > 0) {
                            const ds = field.listValues.map(data =>{
                                return { Key: data.Key, Value: data.Value };
                            });

                            lookup = {
                                dataSource: ds,
                                valueExpr: "Key",
                                displayExpr: "Value",
                                allowClearing: ds.length > 0 && ds[0].Key === null
                            };
                        }

                        break;
                    default:
                        dataType = "string";
                        break;
                }   
                
                var column = {
                    name: field.id,
                    caption: field.label,
                    dataType: dataType,
                    allowFiltering: true,
                    allowSorting: true,
                    allowEditing: !field.readOnly && allowEditing,
                    lookup: lookup,
                    validationRules: field.required && !field.readOnly ? [{ type: "required" }] : []
                }

                section.columns.push(column);

            });

        return columns;
    },
    generateBulkEdit: async (o) =>{

        const result = workBench.setContent({ content: <Loader />, height: '500px', maxHeight: '800px', dragEnabled: false });

        if (!result)
            return;

        var data = await legacyEndpoints.service({
            name: 'GetFilteredBulkEditForm',
            parameters: {
                customQueryId: o.customQueryId
            }
        })

        var columns = bulkInfo.getEditFormColumns({ data: data });

        workBench.setContent({ 
            title: o.title,
            content: <BulkEdit customQueryId={o.customQueryId} form={data} columns={columns} />,
            height: '500px',
            maxHeight: '800px',
            dragEnabled: false
        });
    },
    submitBulkEditForm: async (o) =>{

        return(await legacyEndpoints.service({
            name: 'SubmitBulkEditForm',
            parameters: {
                customQueryId: o.customQueryId,
                bulkEditForms: o.bulkEditForms
            }
        }));
    }
};
