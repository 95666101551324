// App imports
import { TMXForm } from '../components/app/forms/tmxForm/tmxForm';
import { activityHub } from '../components/app/activityHub/activityHub';
import { constants } from '../utils/constants';
import { translate } from '../utils/translation';
import { legacyEndpoints } from '../services/legacyEndpoints';

const _ = require("lodash");

export const trafficMetrix = {
    tmxInformation: (o) =>{

        var highlights = [];

        activityHub.open({
            entity: { text: `${translate('loading')}...`, location: o.location },
            type: 'trafficMetrix',
            renderContent: async () =>{

                return [
                    { id: constants.activityHub.actions.information, component: <TMXForm location={o.location} onOpen={(p) => { highlights = p.highlights; }} /> }
                ];

            },
            onClose: () =>{
                if (highlights.length === 0)
                    return;

                highlights.forEach(highlight => {
                    highlight.dispose();
                });
            }        
        });

    },
    getTMXBulkInformation: async function (o) {      
    
        return(await legacyEndpoints.service({
            name: 'GetTrafficMetrixBulkInformation',
            parameters: {
                latitude: o.latitude,
                longitude: o.longitude
            }
        }));     

    },
    getTrafficPresets: async function () {      
    
        return(await legacyEndpoints.service({
            name: 'GetTrafficPresets'
        }));     

    },
    saveTrafficPreset: async function (o) {      
    
        return(await legacyEndpoints.service({
            name: 'SaveTrafficPreset',
            parameters: {
                trafficPreset: o.preset
            }
        }));     

    },
    removeTrafficPreset: async function (o) {      
    
        return(await legacyEndpoints.service({
            name: 'RemoveTrafficPreset',
            parameters: {
                trafficPresetId: o.presetId
            }
        }));     

    },
    setDefaultTrafficPreset: async function (o) {      
    
        return(await legacyEndpoints.service({
            name: 'SetDefaultTrafficPreset',
            parameters: {
                trafficPresetId: o.presetId,
                isDefault: o.isDefault
            }
        }));     

    }
}