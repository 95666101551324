// React imports
import { useEffect, useState, useRef, useCallback, useMemo } from 'react';

// App imports
import { DropDown } from '../../../base/dropDown/dropDown';
import { translate } from '../../../../utils/translation';
import { inputBoxTypes, TextBox, validatorTypes } from '../../../base/textBox/textBox';
import { constants } from '../../../../utils/constants';

const _ = require('lodash');

export function CreateRendererStep3({renderer,updateRenderer,onReadyNext}){

    const [filterTypeUnits, setFilterTypeUnits] = useState([]);

    const distanceUnits = useMemo(() => [
        { name: translate('m'), id: constants.lengthMeasurements.meters},
        { name: translate('km'), id: constants.lengthMeasurements.kilometers},
        { name: translate('mi'), id: constants.lengthMeasurements.miles},        
    ], []);   

    const driveTimeUnits = useMemo(() => [
        { name: translate('secs'), id: constants.trip2TradeDriveTimeMeasurement.seconds},
        { name: translate('mins'), id: constants.trip2TradeDriveTimeMeasurement.minutes},      
    ], []);

    const filterTypes = useMemo(() => [
        { name: translate('none'), id: constants.trip2TradeFilterLimitType.none},
        { name: translate('distance'), id: constants.trip2TradeFilterLimitType.distance},
        { name: translate('drive_time'), id: constants.trip2TradeFilterLimitType.driveTime},     
    ], []);  

    const loadFilterTypeUnits = useCallback(
        (o)=>{
            switch (o)
            {
                default:
                    setFilterTypeUnits([]);
                    break;
                case constants.trip2TradeFilterLimitType.distance:
                    setFilterTypeUnits(distanceUnits);
                    break;
                case constants.trip2TradeFilterLimitType.driveTime:
                    setFilterTypeUnits(driveTimeUnits);
                    break;        
            }
        },
        [distanceUnits, driveTimeUnits]
    );

    const filterLimitValidatorRef = useRef(null);
      useEffect(() => {
        loadFilterTypeUnits(renderer.filter.limitType);
    }, [loadFilterTypeUnits, renderer.filter.limitType]);          

    useEffect(() => {

        var isValid = true;
        if (renderer.filter.limitType !== constants.trip2TradeFilterLimitType.none)
        {
            isValid = renderer.filter.limitValue > 0;
        }

        if (onReadyNext)
            onReadyNext(isValid);

    }, [onReadyNext, renderer.filter])    

    const validatFilterLimit = (e) => {
        if (e.value > 0)
            return true;
        else
            return false;
    }

    function handleFilterTypeChange(o) {
        switch (o)
        {
            default:
            case constants.trip2TradeFilterLimitType.none:
                loadFilterTypeUnits(constants.trip2TradeFilterLimitType.none);
                break;
            case constants.trip2TradeFilterLimitType.distance:
                loadFilterTypeUnits(o);
                break;
            case constants.trip2TradeFilterLimitType.driveTime:
                loadFilterTypeUnits(o);
                break;        
        }
    };     

	return <> 
        <div className='app-trip-2-trade-body'>
            <div> 
                <DropDown className='app-trip-2-trade-renderer-wizard-filter-type' 
                            height='47px' 
                            selected={renderer.filter.limitType} 
                            items={filterTypes}
                            label={translate('filter_type')}  
                            display='name' 
                            valueProperty='id' 
                            onChange={(o) => { 
                                var limitValue = renderer.filter.limitValue;
                                var limitUnitType = renderer.filter.limitUnitType;

                                if (o.value === constants.trip2TradeFilterLimitType.none)
                                    limitValue = null;

                                switch (o.value)
                                {
                                    default:
                                    case constants.trip2TradeFilterLimitType.none:
                                        limitUnitType = null;
                                        break;
                                    case constants.trip2TradeFilterLimitType.distance:
                                        limitUnitType = constants.lengthMeasurements.meters;                        
                                        break;
                                    case constants.trip2TradeFilterLimitType.driveTime:
                                        limitUnitType = constants.trip2TradeDriveTimeMeasurement.seconds;                        
                                        break;        
                                }

                                updateRenderer({...renderer, filter: {...renderer.filter, limitUnitType: limitUnitType, limitType: o.value, limitValue: limitValue } });
                                handleFilterTypeChange(o.value) 
                            }}                                                        
                />                                                                 
            </div>              
            <div>               
                <TextBox type={inputBoxTypes.numeric} 
                            allowCommas={false} 
                            allowDecimals={false}                         
                            allowNull={true}
                            disabled={renderer.filter.limitType === constants.trip2TradeFilterLimitType.none}
                            className='app-trip-2-trade-renderer-wizard-filter-limit' 
                            height='47px' 
                            label={translate('filter_limit')} 
                            name='limitValue' 
                            value={renderer.filter.limitValue} 
                            valueChangeEvent='keyup' 
                            validator={
                                {
                                    ref: filterLimitValidatorRef,
                                    rules: [
                                        {
                                            type: validatorTypes.requiredRule,
                                            message: translate('required')
                                        },
                                        {
                                            type: validatorTypes.customRule,
                                            message: translate('invalid_filter_limit'),
                                            callback: validatFilterLimit 
                                        },
                                    ]
                                }
                            }
                            onChange={o => { 
                                if (o.userChanged)
                                    updateRenderer({...renderer, filter: {...renderer.filter, limitValue: o.value } });
                            } }
                />

                <DropDown className='app-trip-2-trade-renderer-wizard-filter-limit-units' 
                            height='47px' 
                            selected={renderer.filter.limitUnitType} 
                            disabled={renderer.filter.limitType === constants.trip2TradeFilterLimitType.none}
                            items={filterTypeUnits}
                            label={translate('units')}  
                            display='name' 
                            valueProperty='id'  
                            onChange={(o) => { 
                                if (o.userChanged)
                                    updateRenderer({...renderer, filter: {...renderer.filter, limitUnitType: o.value } });                             
                            }}                                                               
                            
                />                   
            </div>                    
        </div>
    </>
}