//App imports
import { constants } from "../../../../../utils/constants"
import { translate } from "../../../../../utils/translation"
import { legacyEndpoints } from "../../../../../services/legacyEndpoints"

export function AdHocCreationWizardStep4({dataSource, columns}){
    return <div className='app-ad-hoc-creation-step-container'>
        <table className='app-ad-hoc-creation-review-table'>
            <tbody>
                <tr>
                    <td>
                        <div className="app-ad-hoc-creation-review-block">
                            <div className='app-ad-hoc-creation-data-labels bold'>{translate("data_fields")}</div>
                            <div>{columns.length}</div>
                        </div>
                    </td>
                    <td>
                        <div className="app-ad-hoc-creation-review-block">
                            <div className='app-ad-hoc-creation-data-labels bold'>{translate("symbology")}</div>
                            <div>{dataSource.symbolType == constants.symbols.types.stock ? <img src={legacyEndpoints.handlers.getSymbolUrl({ imageUrl: dataSource.symbolUrl })} /> : translate("custom")}</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="app-ad-hoc-creation-review-block">
                            <div className='app-ad-hoc-creation-data-labels bold'>{translate("job_name")}</div>
                            <div>{dataSource.name}</div>
                        </div>
                    </td>
                    <td>
                        <div className="app-ad-hoc-creation-review-block">
                            <div className='app-ad-hoc-creation-data-labels bold'>{translate("max_zoom")}</div>
                            <div>{constants.getZoomLevelNameFromEnum(dataSource.maxZoom)}</div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
}