// React imports
import { helpers } from '../utils/helpers';
import { constants } from '../utils/constants';
import { map } from '../components/app/map/map';
import { translate } from '../utils/translation';

const _ = require("lodash");

export class Drawing {

    constructor(parent) {

        this._private = {
            _active: false,
            _type: constants.entities.polyline,
            _helperVertices: [],
            _helperLine: null,
            _helperShape: null,
            _helperOutline: null,
            _clickListenerId: null,
            _rightClickListenerId: null,
            _mouseMoveListenerId: null,
            _draw: (o) =>{

                this._private._dispose();
                this._private._active = true;

                parent.setCursor({ cursor: 'crosshair' });
                
                if (o.allowEntityClicking !== true)
                    parent.disableEntityClicking();

                var locations = [];
                var mouseLocation = {};
                var mouseVertex = null;
                var shapeVertices = [];

                var updateLocations = (c) => {

                    // If there are no locations then return
                    if (locations.length === 0)
                        return;

                    var tempLocations = _.clone(locations); // Save off the locations in order to add temporary locations
                    var drawVertex = c?.action === constants.listeners.click; // If the calling function is a click the draw its vertex

                    switch(o.type){
                        default:
                            tempLocations.push(mouseLocation.location); // Add mouse location
                            break;
                        case constants.entities.rectangle:
                            drawVertex = drawVertex && tempLocations.length <= 1; // Only draw vertex if it is the first vertex in the list
                            this._private._drawHelperLine([tempLocations[0], mouseLocation.location]); // Draw helper showing the hypotenuse
                            tempLocations = helpers.createRectangle({topLeft: tempLocations[0], bottomRight: mouseLocation.location }); // Create a rectangle
                            break;
                        case constants.entities.circle:
                            drawVertex = drawVertex && tempLocations.length <= 1;  // Only draw vertex if it is the first vertex in the list
                            this._private._drawHelperLine([tempLocations[0], mouseLocation.location]); // Draw helper showing the radius
                            tempLocations = helpers.createCircle({ location: tempLocations[0], radius: parent.computeLength({ locations: [tempLocations[0], mouseLocation.location] }) }); // Create a circle
                            break;
                    }

                    // If the boolean is true then draw a permanent vertex
                    if (drawVertex)
                        shapeVertices.push(this._private._drawHelperVertex({
                            id: helpers.newGuid(),
                            size: 20,
                            location: mouseLocation.location,
                            fillColor: { r: 255, g: 255, b: 255},
                            strokeColor: constants.polygons.temporary.strokeColor
                        }));

                    // Update the shape
                    this._private._drawHelperShape({ type: o.type, locations: tempLocations });

                    // Update the tooltip
                    this._private._renderTooltip({
                        pixel: mouseLocation.pixel,
                        locations: tempLocations,
                        onTooltipRender: o.onTooltipRender,
                        sections: [<>
                            <div>{translate('left_click_to_add_a_point')}</div>
                            <div>{translate('esc_to_remove_last_point')}</div>
                            <div>{translate('right_click_to_finish')}</div>
                            <div>{translate('esc_with_no_points_will_cancel')}</div>
                        </>]
                    });

                    return tempLocations;
                };

                this._private._escapeListener = (e) =>{
                    // If another key other than the escape key is hit then return
                    if (e.key === 'Escape')
                        if (locations.length === 0)
                            this._private._dispose(); // If there is nothing left in the locations and esc is hit again stop drawing
                        else {
                            // Remove the last location and last drawn vertex
                            locations.pop();
                            shapeVertices.pop().dispose();

                            if (locations.length > 0)
                                updateLocations(); // If there are locations left update the shape
                            else {
                                // If there are not locations left remove the helper shapes
                                this._private._helperLine?.dispose();
                                this._private._helperShape?.dispose();
                                this._private._helperOutline?.dispose();
                            }
                        }
                };
                // Bind the escape listener
                document.addEventListener("keyup", this._private._escapeListener);

                this._private._clickListenerId = parent.addListener({
                    type: constants.listeners.click,
                    action: (e) => {                        
                        mouseLocation = e; // Track the last moust event
                        locations.push(mouseLocation.location); // Add the vertex to the shape
                        updateLocations({ action: constants.listeners.click }); // Update the shape
                    }
                });

                this._private._mouseMoveListenerId = parent.addListener({
                    type: constants.listeners.mouseMove,
                    action: (e) => {

                        // Track the last moust event
                        mouseLocation = e;

                        /*
                        // If there is no mouse vertex, create one
                        if (mouseVertex === null)
                            mouseVertex = this._private._drawHelperVertex({
                                id: helpers.newGuid(),
                                size: 20,
                                location: mouseLocation.location,
                                fillColor: { r: 255, g: 255, b: 255 },
                                strokeColor: constants.polygons.temporary.strokeColor
                            });

                        mouseVertex.location = mouseLocation.location; // Set the mouse vertex to the last location
                        */
                        updateLocations(); // Update the shape
                    }
                });

                this._private._rightClickListenerId = parent.addListener({
                    type: constants.listeners.rightClick,
                    executeOnce: true,
                    isDrawing: true,
                    action: (e) => {
                        // Track the last moust event
                        mouseLocation = e;

                        var locations = updateLocations();

                        // Dispose of all resources
                        this._private._dispose({
                            clearLayer: (_.isBoolean(o.clearLayerOnFinish) && o.clearLayerOnFinish) || !_.isBoolean(o.clearLayerOnFinish),
                            clearTooltip: (_.isBoolean(o.clearTooltipOnFinish) && o.clearTooltipOnFinish) || !_.isBoolean(o.clearTooltipOnFinish)
                        });

                        // Send the final list of locations back
                        if (_.isFunction(o.onFinish))
                            o.onFinish({ locations: locations });                        
                    }
                });
            },
            _reshape: (o) => {
                this._private._dispose();
                this._private._active = true;
                o.entity.visible = false;
                
                var vertexCollection = [];
                var midCollection = [];

                var type = o.entity.type;
                var locations = _.clone(o.entity.paths);
                var vertexSize = 20;
                var vertexFillColor = { r: 223, g: 51, b: 57};
                var vertexStrokeColor = { r: 203, g: 31, b: 37};
                var midpointFillColor = { r: 123, g: 213, b: 20 };
                var midpointStrokeColor = { r: 103, g: 193, b: 0 };
                var dragVertexFillColor = { r: 43, g: 50, b: 140 };
                var dragVertexStrokeColor = { r: 43, g: 60, b: 140 };

                _.forEach(locations, (location) => {location.id = helpers.newGuid();});

                this._private._rightClickListenerId = parent.addListener({
                    type: constants.listeners.rightClick,
                    executeOnce: true,
                    isDrawing: true,
                    action: () => {
                        o.entity.paths = locations;
                        o.entity.visible = true;

                        // Dispose of all resources
                        this._private._dispose({
                            clearLayer: (_.isBoolean(o.clearLayerOnFinish) && o.clearLayerOnFinish) || !_.isBoolean(o.clearLayerOnFinish),
                            clearTooltip: (_.isBoolean(o.clearTooltipOnFinish) && o.clearTooltipOnFinish) || !_.isBoolean(o.clearTooltipOnFinish)
                        });

                        // Send the final list of locations back
                        if (_.isFunction(o.onFinish))
                            
                            switch (type)
                            {       
                                default:
                                    o.onFinish({ locations: locations });
                                    break;                 
                                case constants.entities.circle:
                                    o.onFinish({ locations: locations, radius: parent.computeLength({ locations: [o.entity.location, locations[0]] }) });
                                    break;   
                            }                        
                    }
                });

                this._private._escapeListener = (e) =>{
                    // If another key other than the escape key is hit then return
                    if (e.key !== 'Escape')
                        return;

                    o.entity.visible = true;

                    this._private._dispose();                        
                };
                // Bind the escape listener
                document.addEventListener("keyup", this._private._escapeListener);

                this._private._mouseMoveListenerId = parent.addListener({
                    type: constants.listeners.mouseMove,
                    action: (e) => {                        
                        // Update the tooltip
                        this._private._renderTooltip({
                            pixel: e.pixel,
                            locations: locations,
                            onTooltipRender: o.onTooltipRender,
                            sections: [<>
                                <div>{translate('right_click_to_finish')}</div>
                                <div>{translate('esc_with_no_points_will_cancel')}</div>
                            </>]
                        });
                    }
                });

                var createVertex = (o) => {
                    return this._private._drawHelperVertex({
                        id: o.location.id,
                        size: vertexSize,
                        fillColor: o.vertexType === 0 ? vertexFillColor : midpointFillColor,
                        strokeColor: o.vertexType === 0 ? vertexStrokeColor: midpointStrokeColor,
                        location: o.location,
                        draggable: true,
                        metaData: { vertexType: o.vertexType, nextLocation: o.nextLocation },
                        listeners: [{
                            type: constants.listeners.click,
                            action: (e) =>{
                                switch (e.entity.metaData.vertexType)
                                {
                                    case 0:
                                        switch (type)
                                        {
                                            default:
                                                return;
                                            case constants.entities.polyline:
                                                if (locations.length === 2)
                                                    return;
                                                break;
                                            case constants.entities.polygon:
                                                if (locations.length === 3)
                                                    return;
                                                break;
                                        }

                                        var index = _.findIndex(locations, (location) => {return location.lat === e.entity.location.lat && location.lon === e.entity.location.lon});
                                        locations.splice(index, 1);
                                        vertexCollection.splice(index, 1);

                                        this._private._drawHelperShape({
                                            type: type,
                                            locations: locations
                                        });

                                        e.entity.dispose();

                                        drawMidPoints();
                                        break;
                                    case 1:
                                        var newLocation = {lat: e.location.lat, lon: e.location.lon, id: helpers.newGuid()};
                                        locations.splice(e.entity.metaData.nextLocation, 0, newLocation);
                                        drawVertices();
                                        break;
                                }
                            }
                        },
                        {
                            type: constants.listeners.drag,
                            action: (e) =>{
                                switch (e.entity.metaData.vertexType)
                                {
                                    case 0:
                                        var index = _.findIndex(locations, (location) => {return location.id === e.entity.id});
                                        if(index != -1) {
                                            vertexCollection[index].location = e.location;
                                            locations[index] = {lat: e.location.lat, lon: e.location.lon, id: e.entity.id};
                                        }
                                        
                                        drawMidPoints();

                                        this._private._drawHelperShape({ type: type, locations: locations });
                                        break;
                                    case 1:
                                        e.entity.image = this._private._getHelperVertexIcon({
                                            size: vertexSize,
                                            fillColor: vertexFillColor,
                                            strokeColor: vertexStrokeColor
                                        })

                                        var newLocation = {lat: e.location.lat, lon: e.location.lon, id: e.entity.id};
                                        e.entity.metaData.vertexType = 0;
                                        locations.splice(e.entity.metaData.nextLocation, 0, newLocation);
                                        vertexCollection.splice(e.entity.metaData.nextLocation, 0, e.entity);
                                        midCollection = midCollection.filter(point => point !== e.entity);
                                        break;
                                }
                            }
                        }]
                    });
                };

                var drawMidPoints = () => {
                    midCollection.forEach(point => point?.dispose());
                    midCollection = locations.map((location, i) =>{

                        if (o.entity.type === constants.entities.polyline && i === locations.length - 1)
                            return;

                        var nextLocation = i === locations.length - 1 ? 0 : i+1;
                        return createVertex({ location: helpers.getMidpoint(location, locations[nextLocation]), vertexType: 1, nextLocation: nextLocation});
                    });
                };

                var drawVertices = () => {
                    vertexCollection.forEach(point => point.dispose());
                    vertexCollection = locations.map((location) => { return createVertex({ location: location, vertexType: 0}); });
                    drawMidPoints();
                    this._private._drawHelperShape({ type: o.entity.type, locations: locations });
                };

                var drawDragShape = (o) => {
                    var draw = (dragLocation) => {
                        
                        switch (type)
                        {                        
                            case constants.entities.circle:
                                locations = helpers.createCircle({ location: o.anchorLocation, radius: parent.computeLength({ locations: [o.anchorLocation, dragLocation] }) }); // Create a circle
                                break;
                            case constants.entities.rectangle:
                                locations = helpers.createRectangle({topLeft: o.anchorLocation, bottomRight: dragLocation }); // Create a rectangle
                                break;
                        }

                        this._private._drawHelperLine([o.anchorLocation, dragLocation]); // Draw Helper line
                        this._private._drawHelperShape({ type: type, locations: locations }); // Draw the shape
                    };

                    // Draw the shape
                    draw(o.dragLocation);

                    // Draw the anchor vertex
                    this._private._drawHelperVertex({
                        id: helpers.newGuid(),
                        size: vertexSize,
                        fillColor: { r: 255, g: 255, b: 255 },
                        strokeColor: constants.polygons.temporary.strokeColor,
                        location: o.anchorLocation
                    });
                   
                    // Draw the drag vertex
                    this._private._drawHelperVertex({
                        id: helpers.newGuid(),
                        size: vertexSize,
                        fillColor: dragVertexFillColor,
                        strokeColor: dragVertexStrokeColor,
                        location: o.dragLocation,
                        draggable: true,
                        listeners: [{
                            type: constants.listeners.drag,
                            action: (e) =>{
                                draw(e.location);
                            }
                        }]
                    });
                };

                switch (type)
                {
                    case constants.entities.polygon:
                    case constants.entities.polyline:
                        drawVertices();
                        break;
                    case constants.entities.circle:
                        drawDragShape({
                            anchorLocation: o.entity.location,
                            dragLocation: locations[Math.ceil(locations.length/4)]
                        });                        
                        break;
                    case constants.entities.rectangle:
                        drawDragShape({
                            anchorLocation: locations[0],
                            dragLocation: locations[2]
                        });
                        break;
                }

            },
            _movePoint: (o) => {

                this._private._dispose();
                this._private._active = true;
                o.entity.visible = false;

                this._private._drawHelperOutline = parent._private._temporaryLayer.addEntity({
                    type: constants.entities.activePoint,
                    location: o.entity.location,
                    anchor: o.entity.anchor,
                    width: o.entity.width,
                    height: o.entity.height
                });

                this._private._drawHelperShape = parent._private._temporaryLayer.addEntity({
                    type: constants.entities.point,
                    image: o.entity.image,
                    location: o.entity.location,
                    anchor: o.entity.anchor,
                    width: o.entity.width,
                    height: o.entity.height,
                    draggable: true
                });

                this._private._mouseMoveListenerId = parent.addListener({
                    type: constants.listeners.mouseMove,
                    action: (e) => {                        
                        // Update the tooltip
                        this._private._renderTooltip({
                            pixel: e.pixel,
                            onTooltipRender: o.onTooltipRender,
                            sections: [<>
                                <div>{translate('left_click_and_drag_to_move')}</div>
                                <div>{translate('right_click_to_finish')}</div>
                                <div>{translate('esc_to_cancel')}</div>
                            </>]
                        });
                    }
                });

                this._private._rightClickListenerId = parent.addListener({
                    type: constants.listeners.rightClick,
                    executeOnce: true,
                    action: () => {
                        
                        o.entity.location = this._private._drawHelperShape.location;       
                        o.entity.updatePushpinMetrics();                
                        o.entity.visible = true;

                        // Dispose of all resources
                        this._private._dispose();

                        o.onFinish(o.entity);                        
                    }
                });

                this._private._escapeListener = (e) =>{
                    // If another key other than the escape key is hit then return
                    if (e.key !== 'Escape')
                        return;
                    o.entity.visible = true;
                    this._private._dispose();                        
                };
                
                // Bind the escape listener
                document.addEventListener("keyup", this._private._escapeListener);
            },
            _move: (o) => {
                this._private._dispose();
                this._private._active = true;
                o.entity.visible = false;
                var locations = _.clone(o.entity.paths);
                this._private._drawHelperOutline({ type: o.entity.type, locations: locations });
                this._private._drawHelperShape({ type: o.entity.type, locations: locations });
                this._private._helperShape.draggable = true;
                this._private._helperShape.clickable = true;

                this._private._mouseMoveListenerId = parent.addListener({
                    type: constants.listeners.mouseMove,
                    action: (e) => {                        
                        // Update the tooltip
                        this._private._renderTooltip({
                            pixel: e.pixel,
                            locations: locations,
                            onTooltipRender: o.onTooltipRender,
                            sections: [<>
                                <div>{translate('left_click_and_drag_to_move')}</div>
                                <div>{translate('right_click_to_finish')}</div>
                                <div>{translate('esc_to_cancel')}</div>
                            </>]
                        });
                    }
                });

                this._private._rightClickListenerId = parent.addListener({
                    type: constants.listeners.rightClick,
                    executeOnce: true,
                    action: () => {
                        o.entity.paths = this._private._helperShape.paths;
                        o.entity.updateShapeMetrics();
                        o.entity.visible = true;
                        
                        // Dispose of all resources
                        this._private._dispose();

                        o.onFinish(o.entity);                        
                    }
                });

                this._private._escapeListener = (e) =>{
                    // If another key other than the escape key is hit then return
                    if (e.key !== 'Escape')
                        return;
                    o.entity.visible = true;
                    this._private._dispose();                        
                };
                // Bind the escape listener
                document.addEventListener("keyup", this._private._escapeListener);
            },
            _renderTooltip: (o)=>{

                var baseTooltip = o.sections;

                if (_.isFunction(o.onTooltipRender))
                {
                    var tooltip = o.onTooltipRender({ locations: o.locations });

                    if (_.isArray(tooltip.sections) && tooltip.sections.length > 0)
                        baseTooltip = tooltip.sections.concat(baseTooltip);
                }

                map.showTooltip({
                    title: translate('information'),
                    pixel: { x: o.pixel.x + 50, y: o.pixel.y + 50 },
                    sections: baseTooltip
                });
            },
            _drawHelperShape: (o) => {                
                this._private._helperShape?.dispose();
                this._private._helperShape = parent._private._temporaryLayer.addEntity({
                    type: o.type,
                    paths: o.locations,
                    fillColor: constants.polygons.temporary.fillColor,
                    strokeColor: constants.polygons.temporary.strokeColor,
                    strokeWidth: constants.polygons.temporary.strokeWidth
                });
            },
            _drawHelperOutline: (o) => {                
                this._private._helperOutline?.dispose();
                this._private._helperOutline = parent._private._temporaryLayer.addEntity({
                    type: o.type,
                    paths: o.locations,
                    fillColor: constants.polygons.outline.fillColor,
                    strokeColor: constants.polygons.outline.strokeColor,
                    strokeWidth: constants.polygons.outline.strokeWidth
                });
            },
            _drawHelperLine: (locations) =>{
                this._private._helperLine?.dispose();
                this._private._helperLine = parent._private._temporaryLayer.addEntity({
                    type: constants.entities.polyline,
                    paths: locations,
                    strokeColor: constants.polygons.temporary.strokeColor,
                    strokeWidth: constants.polygons.temporary.strokeWidth
                });
            },
            _getHelperVertexIcon: (o) =>{
                return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="${o.size}" width="${o.size}"> \
                    <circle cx="${o.size/2}" cy="${o.size/2}" r="${o.size/4}" stroke="rgb(${o.strokeColor.r},${o.strokeColor.g},${o.strokeColor.b})" stroke-width="2"  fill="rgb(${o.fillColor.r},${o.fillColor.g},${o.fillColor.b})"/> \
                </svg>`;
            },
            _drawHelperVertex: (o) =>{
                return parent._private._temporaryLayer.addEntity({
                    id: o.id,
                    type: constants.entities.genericPoint,
                    location: o.location,
                    image: this._private._getHelperVertexIcon(o),
                    anchor: { x: o.size / 2, y: o.size / 2 },
                    draggable: _.isBoolean(o.draggable) && o.draggable,
                    listeners: _.isArray(o.listeners) ? o.listeners : [],
                    metaData: o.metaData
                })
            },
            _dispose: (o) =>{
                o = _.isObject(o) ? o : { clearLayer: true };

                o.clearTooltip = !_.isBoolean(o.clearTooltip) || o.clearTooltip;
            
                this._private._active = false;
                parent.enableEntityClicking();
                parent.setCursor({ cursor: '' });

                this._private._helperVertices = [];
                this._private._helperLine = null;
                this._private._helperShape = null;
                this._private._helperOutline = null;

                parent.removeListener(this._private._clickListenerId);
                this._private._clickListenerId = null;

                parent.removeListener(this._private._rightClickListenerId);
                this._private._rightClickListenerId = null;

                parent.removeListener(this._private._mouseMoveListenerId);
                this._private._mouseMoveListenerId = null;

                document.removeEventListener("keyup", this._private._escapeListener, true);
                document.removeEventListener("keyup", this._private._escapeListener, false);

                if (!o.clearLayer)
                    return;

                parent._private._temporaryLayer.clear();

                if (!o.clearTooltip)
                    return;

                map.hideTooltip();                
            }
        };

        return this;
    };
}