// React imports
import { useState } from 'react';

// App imports
import { SymbolManager } from '../../base/symbolManager/symbolManager';
import { Bar } from '../../base/bar/bar';
import { DropDown } from '../../base/dropDown/dropDown';
import { TextBox } from '../../base/textBox/textBox';
import { Button }  from '../../base/button/button';
import { Description }  from '../../base/description/description';
import { icons } from '../../base/icon/icon';
import { translate } from "../../../utils/translation";
import { userPreferencesHelpers } from './userPreferences';
import { successToast, errorToast } from '../../base/toast/toast';

export function UserPreferencesSection({inputType, preferenceName, defaultValue, listOfValues, listDisplay, listValue, allowSave, label, description}) {

    const textBoxHeight = '55px';

    const [preference, setPreference] = useState(defaultValue);		

    return <>
        { inputType === 'symbolpicker' ?
            <SymbolManager 
                key={0}
                symbol={preference} 
                disabled={!allowSave}
                onChange={(path) => { setPreference(path); }} 
            />
        : inputType === 'checkbox' ?
            <Bar className={'app-user-preferences-inputs'} 
                key={1}
                text={label} 
                active={preference === true} 
                onClick={() => { setPreference(!preference); }} 
                disabled={!allowSave} 
            /> 
        : inputType === 'dropdown' ?
            <DropDown className='app-user-preferences-inputs'
                key={2}
                items={listOfValues} 
                label={label} 
                height={textBoxHeight} 
                display={listDisplay} 
                valueProperty={listValue} 
                selected={preference} 
                disabled={!allowSave} 
                onChange={(o) => { setPreference(o.value); }} 
            />
        : inputType === 'textbox' ?
            <TextBox
                key={3}
                label={label} 
                value={preference} 
                height={textBoxHeight} 
                onChange={(o) => { setPreference(o.value); }} 
            />
        : '' }
        <div className='app-user-preferences-save'>
            <Button key={4} theme='tertiary' size='medium' icon={icons.check} tooltip={translate('save')} disabled={!allowSave} onClick={()=>{ 
                userPreferencesHelpers.saveUserPreferences({ 
                    updatePreferences: true, 
                    columns: [preferenceName], 
                    values: [preference], 
                    callback: (o) => { 
                        if (o.success)
                            successToast(translate('success')); 
                        else
                            errorToast(o.error); 
                    }
                });
            }} />
        </div>
        { description ? <Description key={5} description={description} /> : '' }
    </>
}