// React imports
import { useState, useEffect } from 'react';

// App imports
import { Bar } from '../../base/bar/bar';
import { DemographicReports } from './demographicReports/demographicReports';
import { PointDetail } from './pointDetail/pointDetail';
import { icons } from '../../../components/base/icon/icon';
import { constants } from '../../../utils/constants';
import { translate } from "../../../utils/translation";
import { userPreferences } from '../app';
import { ManagementReports } from './managementReports/managementReports';
import { ReportManager } from './reportManager/reportManager';
import { navigation } from '../navigation/navigation';
import {MapBooksManager} from '../tools/mapBooks/mapBooksManager';
import {MapBookBuilder} from '../tools/mapBooks/mapBookBuilder';
import {MapBookJob} from '../mapBookJob/mapBookJob';

const _ = require('lodash');

export var reports;

export function Reports() {
    
    const [activePanel, setActivePanel] = useState(null);
    const [additionalData, setAdditionalData] = useState(null);
    const [showToolsMapBookJob, setShowToolsMapBookJob] = useState(false);

    useEffect(()=>{
        reports = {
            openReportManager: () => {
                navigation.forceExpand({                     
                    section: constants.navigation.sections.reports 
                });
                setActivePanel(7);
            },
            showMapbook: (o) =>{
                setAdditionalData(o);
                navigation.forceExpand({ section: constants.navigation.sections.reports });
                setActivePanel(null);
                setShowToolsMapBookJob(true);                
            },
        };
    }, []);

    return  <>
        {showToolsMapBookJob ? <MapBookJob entities={additionalData} close={()=>{setShowToolsMapBookJob(false); navigation.forceExpand({ section: constants.navigation.sections.layers }); }} /> : ''}
        {
            [
                { text: translate("demographic_summary"), icon: icons.fileLines, category: constants.reportCategories.demographicSummary },                
                { text: translate("demographic_quadrant"), icon: icons.pieChart, category: constants.reportCategories.demographicQuadrant },
            ].map((section, i) =>{
                return <div key={i}>
                    <Bar text={section.text} icon={section.icon} iconClass={section.iconClass} onClick={() => { setActivePanel(i); }} disabled={section.disabled} notLicensed={!userPreferences.AllowDemoReports} />
                    {( activePanel === i ? <DemographicReports text={section.text} onClose={() => { setActivePanel(null); }} reportCategory={section.category}/> : '' )} 
                </div>
            })
        }        

        <Bar text={translate("point_detail")} icon={icons.braille} onClick={() => { setActivePanel(3); }} />
        {( activePanel === 3 ? <PointDetail onClose={() => { setActivePanel(null); }} /> : '' )} 

        <Bar text={translate("management_reports")} icon={icons.people} notLicensed={!userPreferences.AllowManagementReports} onClick={() => { setActivePanel(4); }} />
        {( activePanel === 4 ? <ManagementReports reportCategory={constants.reportCategories.management} onClose={() => { setActivePanel(null); }} /> : '' )}        

        
        <Bar text={translate("map_books")} icon={icons.bookBookmark} onClick={() => { setActivePanel(5); }} />
        {(activePanel === 5 ? <MapBooksManager text={translate("map_books")} setPanelContents={(o) =>{ setActivePanel(6); setAdditionalData(o.additionalData); }} onClose={() => { setActivePanel(null); }}/> : '')}
        {(activePanel === 6 ? <MapBookBuilder 
            mapBookId={additionalData.id}
            text={translate("map_book_builder")} 
            onClose={() => { setActivePanel(5);}}
        /> : ''
        )}

        <Bar text={translate("report_manager")} icon={icons.fileBarChart} onClick={() => { setActivePanel(7); }} />
        {( activePanel === 7 ? <ReportManager onClose={() => { setActivePanel(null); }} /> : '' )}
    </>
}