import { legacyEndpoints } from '../services/legacyEndpoints';

const _ = require("lodash");

export const filtersModule = {
    getFilterSets: async(o) => {
        var result = await legacyEndpoints.service({
            name: 'GetDataSourceFilterSetList',
            parameters: { dataSourceId: o.dataSourceId }
        });
        var filters = [];

        _.each(result, function(filterSet)
        {
            var filter = {
                name: filterSet.Name,
                value: filterSet.Id,
                fields: [],
                selected: filterSet.IsDefault,
                keyColumn: filterSet.KeyColumn
            };

            _.each(filterSet.Columns, function(column)
            {
                var dataTypeAndFormat = filtersModule.getTypeAndFormat(column);

                /*const ds = field.listValues.map(data =>{
                    return { Key: data.Key, Value: data.Value };
                });

                lookup = {
                    dataSource: ds,
                    valueExpr: "Key",
                    displayExpr: "Value",
                    allowClearing: ds.length > 0 && ds[0].Key === null
                };*/

                filter.fields.push({
                    id: column.Id,
                    name: column.Alias.length > 0 ? column.Alias : column.Name,
                    caption: column.Caption,
                    dataField: column.Alias.length > 0 ? column.Alias : column.Name,
                    dataType: dataTypeAndFormat.type,
                    format: dataTypeAndFormat.format,
                    trueText: 'True',
                    falseText: 'False'
                  });
            });

            filters.push(filter);
        });

        if (!_.isObject(_.find(filters, function(f) { return f.selected === true; })))
            _.first(filters).selected = true;

        return filters;
    },
    getFilterSetData: async(o) => {
        return await legacyEndpoints.service({
            name: 'GetDataSourceFilterSetData',
            parameters: {
                dataSourceId: o.dataSourceId,
                customQueryId: o.customQueryId,
                filterSetId: o.filterSetId,
                skip: o.skip,
                take: o.take,
                filters: o.filters,
                sorts: o.sorts
            }
        });
    },
    getPointDataSourceFilters: async(o) => {
        return await legacyEndpoints.service({
            name: 'GetPointDataSourceFilters',
            parameters: {
                dataSourceId: o.dataSourceId,
                customQueryId: o.customQueryId,
                skip: o.skip,
                take: o.take,
                filter: o.filter,
                sortColumn: o.sortColumn,
                sortDirection: o.sortDirection
            }
        });
    },
    getPointDataSourceFiltersForCustomQuery: async(o) => {
        return await legacyEndpoints.service({
            name: 'GetPointDataSourceFiltersForCustomQuery',
            parameters: {
                customQueryId: o.customQueryId
            }
        });
    },
    getPinnedPointDataSourceFilters: async(o) => {
        return await legacyEndpoints.service({
            name: 'GetPinnedPointDataSourceFilters',
            parameters: {
                dataSourceId: o.dataSourceId
            }
        });
    },
    createPointDataSourceFilter: async(o) => {
        return await legacyEndpoints.service({
            name: 'CreatePointDataSourceFilter',
            parameters: {
                filter: {
                    dataSourceId: o.dataSourceId,
                    customQueryId: o.customQueryId,
                    filterSetList: [
                        {
                            filterSet: {
                                id: o.filterSet
                            },
                            filterBuilderList: o.filters
                        }
                    ],
                    name: o.name,
                    description: o.description
                }
            }
        });
    },
    updatePointDataSourceFilter: async(o) => {
        return await legacyEndpoints.service({
            name: 'UpdatePointDataSourceFilter',
            parameters: {
                filter: {
                    dataSourceId: o.dataSourceId,
                    customQueryId: o.customQueryId,
                    filterSetList: [
                        {
                            filterSet: {
                                id: o.filterSet
                            },
                            filterBuilderList: o.filters
                        }
                    ],
                    name: o.name,
                    description: o.description
                }
            }
        });
    },
    deletePointDataSourceFilter: async(o) => {
        return await legacyEndpoints.service({
            name: 'DeletePointDataSourceFilters',
            parameters: {
                idList: o.idList
            }
        });
    },
    getTypeAndFormat: (column) => {
        var result = {
            type: 'string',
            format: null
        }

        switch(column.DefaultFormat)
        {
            case 'T':
            default:
                result.type = 'string';
                break;
            case 'G':
                result.type = 'datetime';
                result.format = {
                    type: 'shortDateShortTime'
                };
                break;
            case 'N':
                result.type = 'number';
                result.format = {
                    type: 'decimal',
                    precision: 2
                }
                break;
            case 'C':
                result.type = 'boolean';
                break;
            case 'M':
                result.type = 'number';
                result.format = {
                    type:'currency',
                    precision: 2
                }
                break;
            case 'I':
                result.type = 'object';
                break;
        }

        return result;
    }
}