// React imports
import { useState } from 'react';

// App imports
import { LargePanel } from "../../../../base/largePanel/largePanel";
import { Uploader } from '../../../../base/uploader/uploader';
import { adHocManagement as adHocModule } from '../../../../../modules/adHocManagement';

const _ = require("lodash");

export function AdHocFlatFileImport({onClose, onComplete, text}) {

    const [isLoaded, setIsLoaded] = useState(true);
    const allowedExtensions = ["txt", "csv", "xls", "xlsx"];

    const onQueuePublish = async (queueReponse) => {
        setIsLoaded(false);

        var fileIdList = [];
        _.each(queueReponse.items, function(item){
            fileIdList.push(item.resultId);
        })

        var response = await adHocModule.getAdHocMetadata({
            fileIdList: fileIdList
        });
       
        setIsLoaded(true);
        onComplete(response);
    }
    
    return <LargePanel
        children={
            <div className='app-ad-hoc-import-container'>
                <Uploader 
                    allowedExtensions = {allowedExtensions} 
                    onQueuePublish={(o) => onQueuePublish(o)}
                /> 
            </div>
        } 
        text={text}
        loaded={isLoaded} 
        generating={false} 
        onClose={() =>{ onClose(); }}>
    </LargePanel>
}
