// React imports
import { useEffect, useState } from 'react';

// App imports
import { Form } from '../forms';
import { inputBoxTypes, TextBox } from '../../../base/textBox/textBox';
import { DropDown } from '../../../base/dropDown/dropDown';
import { DateBox } from '../../../base/dateBox/dateBox';
import { constants } from '../../../../utils/constants';
import { Icon, icons } from '../../../base/icon/icon';
import { Popover } from '../../../base/popover/popover';
import { translate } from '../../../../utils/translation';
import { forms as formsModule} from '../../../../modules/forms';
import { DataList } from '../../../base/dataList/dataList';

const _ = require("lodash");

const geoCodeForm = (form, locationResult) =>{
    form.Tabs.forEach(tab =>{
        tab.Pages.forEach(page =>{
            page.Sections.forEach(section =>{
                section.Rows.forEach(row =>{
                    row.Fields.forEach(field =>{
                        var maxLength = field.Component.ComponentMaxLength > 0 ? field.Component.ComponentMaxLength : 4000;
                        switch (form.Id){
                            default:
                                switch (field.Component.ComponentAutoField) {
                                    case constants.forms.autoFields.address:
                                        field.Component.ComponentValue = locationResult.address.street.substring(0, maxLength);
                                        break;
                                    case constants.forms.autoFields.city:
                                        field.Component.ComponentValue = locationResult.address.city.substring(0, maxLength);
                                        break;
                                    case constants.forms.autoFields.state:
                                        field.Component.ComponentValue = locationResult.address.state.substring(0, maxLength);
                                        break;
                                    case constants.forms.autoFields.zip:
                                        field.Component.ComponentValue = locationResult.address.zip.substring(0, maxLength );
                                        break;
                                    case constants.forms.autoFields.latitude:
                                        field.Component.ComponentValue = parseFloat(locationResult.location.lat.toFixed(6)).toString().substring(0, maxLength);
                                        break;
                                    case constants.forms.autoFields.longitude:
                                        field.Component.ComponentValue = parseFloat(locationResult.location.lon.toFixed(6)).toString().substring(0, maxLength);
                                        break;
                                }
                                break;
                        }
                    });
                });
            });
        });
    });

    return form;
};

export function InputForm({ form=null, collapsedForm=null, flatten=false, forceChange=false, onUpdate, locationResult }){
    const [_form, _setForm] = useState(form);
    const [_collapsedForm, _setCollapsedForm] = useState(collapsedForm);
    const [updateForm, setUpdateForm] = useState({
        Id: '',
        UpdateSections: []
    });

    useEffect(() =>{
        if (form === null)
            return;

        if (_.isObject(locationResult))
            form = geoCodeForm(form, locationResult);

        let changedUpdateForm = updateForm;

        if (forceChange) {           
            changedUpdateForm.Id = form.Id;
    
            form.Tabs.forEach(tab =>{
                tab.Pages.forEach(page =>{
                    page.Sections.forEach(section =>{
                        section.Rows.forEach(row =>{
                            row.Fields.forEach(field =>{
    
                                var deltaSection = changedUpdateForm.UpdateSections.find(x => x.Id === section.Id );
    
                                if (!_.isObject(deltaSection))            
                                    changedUpdateForm.UpdateSections.push({
                                        Id: section.Id,
                                        UpdateFields: [{
                                            Id: field.Component.Id,
                                            FieldId: field.Id,
                                            Column: field.Component.ComponentColumn,
                                            Value: field.Component.ComponentValue,
                                            Source: field.Component.ComponentSource
                                        }]
                                    });
                                else {
                                    var deltaField = deltaSection.UpdateFields.find(x => x.Id === field.Component.Id);
                        
                                    if (!_.isObject(deltaField))
                                        deltaSection.UpdateFields.push({
                                            Id: field.Component.Id,
                                            FieldId: field.Id,
                                            Column: field.Component.ComponentColumn,
                                            Value: field.Component.ComponentValue,
                                            Source: field.Component.ComponentSource
                                        });
                                }
                            });
                        });
                    });
                });
            });    
        }

        var validatedForm = formsModule.validateInputForm({form: form, collapsedForm: collapsedForm, updateForm: updateForm});
        _setForm(validatedForm.form);
        _setCollapsedForm(validatedForm.collapsedForm);
        setUpdateForm(_.cloneDeep(changedUpdateForm));
        
        if (_.isFunction(onUpdate))
            onUpdate({
                updateForm: changedUpdateForm,
                activeError: validatedForm.errors,
                activeWarning: validatedForm.warnings
            });
    }, [form, collapsedForm]);

    const setValue = (o) => {  
        let changedForm = _form;
        let changedUpdateForm = updateForm;
        let changedCollapsedForm = _collapsedForm;
        changedUpdateForm.Id = changedForm.Id;

        changedForm.Tabs.forEach(tab =>{
            tab.Pages.forEach(page =>{
                page.Sections.forEach(section =>{
                    section.Rows.forEach(row =>{
                        row.Fields.forEach(field =>{
                            field.showError = false;
                            field.showWarning = false;
                            if (field.Id !== o.field.Id)
                                return;
                            
                            field.Component.ComponentValue = o.value;

                            var deltaSection = changedUpdateForm.UpdateSections.find(x => x.Id === section.Id );

                            if (!_.isObject(deltaSection))            
                                changedUpdateForm.UpdateSections.push({
                                    Id: section.Id,
                                    UpdateFields: [{
                                        Id: field.Component.Id,
                                        FieldId: field.Id,
                                        Column: field.Component.ComponentColumn,
                                        Value: o.value,
                                        Source: field.Component.ComponentSource
                                    }]
                                });
                            else {
                                var deltaField = deltaSection.UpdateFields.find(x => x.Id === field.Component.Id);
                    
                                if (!_.isObject(deltaField))
                                    deltaSection.UpdateFields.push({
                                        Id: field.Component.Id,
                                        FieldId: field.Id,
                                        Column: field.Component.ComponentColumn,
                                        Value: o.value,
                                        Source: field.Component.ComponentSource
                                    });
                                else
                                    deltaField.Value = o.value;
                            }
                        });
                    });
                });
            });
        });
        var validatedForm = formsModule.validateInputForm({form: changedForm, collapsedForm: changedCollapsedForm, updateForm: changedUpdateForm});
        _setForm(validatedForm.form);
        _setCollapsedForm(validatedForm.collapsedForm);
        setUpdateForm(_.cloneDeep(changedUpdateForm));
        if (_.isFunction(onUpdate))
            onUpdate({
                updateForm: changedUpdateForm,
                activeError: validatedForm.errors,
                activeWarning: validatedForm.warnings
            });
    };

    const renderComponent = (field, section) =>{      
        switch(field.Component.ComponentType){
            default:
                return 'component';
            case constants.forms.components.list:

                var absoluteRequired = field.Component.ComponentRequired && field.Component.RequiredBehavior === constants.forms.componentRequiredBehaviors.invalid;
                var listValues = absoluteRequired ? field.Component.ComponentList.ListValues : [{Key: '', Value: 'N/A'}].concat(field.Component.ComponentList.ListValues);                
                var selectedValue = field.Component.ComponentValue === null && listValues.length > 0 ? (!absoluteRequired ? listValues[0] : undefined) : listValues.find(x => x.Key === field.Component.ComponentValue);

                return <table className='app-form-component-table'>
                    <tbody>
                        <tr>
                            <td className={field.showError || field.showWarning || field.Component.ShowTooltip ? 'app-form-prefix-column' : 'app-form-prefix-column-empty'}>
                                {
                                    field.showError ? <><div id={`error-${field.Id}`}><Icon className={'app-form-required'} icon={icons.octogonExclamation}/></div>
                                                        <Popover parentId={`#error-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.errorMessage) ? field.errorMessage : translate('required')}</Popover></> 
                                                    : field.showWarning ?  <><div id={`warning-${field.Id}`}><Icon className={'app-form-warning'} icon={icons.triangleExclamation}/></div>
                                                        <Popover parentId={`#warning-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.warningMessage) ? field.warningMessage : translate('required_warning')}</Popover></> 
                                                    : field.Component.ShowTooltip ? <><div id={`tooltip-${field.Id}`}><Icon className={'app-form-tooltip'} icon={icons.circleQuestion}/></div>
                                                        <Popover parentId={`#tooltip-${field.Id}`} height={-1} width={-1} showTitle={false}>{field.Component.Tooltip}</Popover></>
                                                    : ''
                                }
                            </td>
                            <td className='app-form-content-jd-test'>
                                {!field.Component.ComponentReadOnly ?
                                <DropDown
                                    className={field.showError ? 'app-form-error' : field.showWarning ? 'app-form-warning' : ''} 
                                    inputAttr={formsModule.convertStyleObject(_.isObject(field.styleOverride) ? field.styleOverride : field.ValueStyle)}
                                    display='Value' 
                                    items={listValues} 
                                    selected={selectedValue} 
                                    /*onSelect={(o) => {
                                        setValue({ field: field, value: o.value.Key })
                                    }}*/
                                    onChange={(o) => {
                                        setValue({ field: field, value: o.value.Key })
                                    }}
                                />
                                : <Icon icon={icons.ban}/>
                                }
                            </td>
                            <td className='app-form-required-column'>
                                {field.Component.ComponentRequired ? <Icon className={'app-form-required'} icon={icons.asterisk}/> : ''}
                            </td>
                        </tr>
                    </tbody>
                </table>
            case constants.forms.components.checkList:
                var listValues = field.Component.ComponentRequired ? field.Component.ComponentList.ListValues : [{Key: '', Value: 'N/A'}].concat(field.Component.ComponentList.ListValues);                

                return <table className='app-form-component-table'>
                    <tbody>
                        <tr>
                            <td className={field.showError || field.showWarning || field.Component.ShowTooltip ? 'app-form-prefix-column' : 'app-form-prefix-column-empty'}>
                                {
                                    field.showError ? <><div id={`error-${field.Id}`}><Icon className={'app-form-required'} icon={icons.octogonExclamation}/></div>
                                                        <Popover parentId={`#error-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.errorMessage) ? field.errorMessage : translate('required')}</Popover></> 
                                                    : field.showWarning ?  <><div id={`warning-${field.Id}`}><Icon className={'app-form-warning'} icon={icons.triangleExclamation}/></div>
                                                        <Popover parentId={`#warning-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.warningMessage) ? field.warningMessage : translate('required_warning')}</Popover></> 
                                                    : field.Component.ShowTooltip ? <><div id={`tooltip-${field.Id}`}><Icon className={'app-form-tooltip'} icon={icons.circleQuestion}/></div>
                                                        <Popover parentId={`#tooltip-${field.Id}`} height={-1} width={-1} showTitle={false}>{field.Component.Tooltip}</Popover></>
                                                    : ''
                                }
                            </td>
                            <td className='app-form-content-jd-test'>
                                {!field.Component.ComponentReadOnly ?
                                <DataList 
                                    keyExpr={'Value'}
                                    dataSource={listValues}
                                    searchEnabled={false}
                                    height={'auto'}
                                    itemRender={(item) =>{
                                        return item.Value;
                                    }}
                                    selectedItemsChange={(o)=> {
                                        if (!_.isArray(field.Component.ComponentValue) && !_.isString(field.Component.ComponentValue) )
                                            setValue({ field: field, value: o });
                                        else if (field.Component.ComponentValue.join('') !== o.join(''))
                                            setValue({ field: field, value: o });                            
                                    }}
                                    selectByClick={true}
                                    selectedItems={field.Component.ComponentValue}
                                />
                                : <Icon icon={icons.ban}/>
                                }
                            </td>
                            <td className='app-form-required-column'>
                                {field.Component.ComponentRequired ? <Icon className={'app-form-required'} icon={icons.asterisk}/> : ''}
                            </td>
                        </tr>
                    </tbody>
                </table>
            case constants.forms.components.text:
                return <table className='app-form-component-table'>
                    <tbody>
                        <tr>
                            <td className={field.showError || field.showWarning || field.Component.ShowTooltip ? 'app-form-prefix-column' : 'app-form-prefix-column-empty'}>
                                {
                                    field.showError ? <><div id={`error-${field.Id}`}><Icon className={'app-form-required'} icon={icons.octogonExclamation}/></div>
                                                        <Popover parentId={`#error-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.errorMessage) ? field.errorMessage : translate('required')}</Popover></> 
                                                    : field.showWarning ?  <><div id={`warning-${field.Id}`}><Icon className={'app-form-warning'} icon={icons.triangleExclamation}/></div>
                                                        <Popover parentId={`#warning-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.warningMessage) ? field.warningMessage : translate('required_warning')}</Popover></> 
                                                    : field.Component.ShowTooltip ? <><div id={`tooltip-${field.Id}`}><Icon className={'app-form-tooltip'} icon={icons.circleQuestion}/></div>
                                                        <Popover parentId={`#tooltip-${field.Id}`} height={-1} width={-1} showTitle={false}>{field.Component.Tooltip}</Popover></>
                                                    : ''
                                }
                            </td>
                            <td className='app-form-content-jd-test'>
                                <TextBox 
                                    className={field.showError ? 'app-form-error' : field.showWarning ? 'app-form-warning' : ''}
                                    inputAttr={formsModule.convertStyleObject(_.isObject(field.styleOverride) ? field.styleOverride : field.ValueStyle)}
                                    value={field.Component.ComponentValue} 
                                    maxLength={field.Component.ComponentMaxLength}
                                    readOnly={field.Component.ComponentReadOnly}
                                    onChange={(o) => {
                                        setValue({
                                            field: field,
                                            value: o.value
                                        });
                                    }}
                                />
                            </td>
                            <td className='app-form-required-column'>
                                {field.Component.ComponentRequired ? <div id={`required-${field.Id}`}><Icon className={'app-form-required'} icon={icons.asterisk}/></div> : ''}
                                {field.Component.ComponentRequired ? <Popover parentId={`#required-${field.Id}`} height={-1} width={-1} showTitle={false}>{translate('required')}</Popover> : ''}
                            </td>
                        </tr>
                    </tbody>
                </table>
            case constants.forms.components.numeric:
                return <table className='app-form-component-table'>
                    <tbody>
                        <tr>
                            <td className={field.showError || field.showWarning || field.Component.ShowTooltip ? 'app-form-prefix-column' : 'app-form-prefix-column-empty'}>
                                {
                                    field.showError ? <><div id={`error-${field.Id}`}><Icon className={'app-form-required'} icon={icons.octogonExclamation}/></div>
                                                        <Popover parentId={`#error-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.errorMessage) ? field.errorMessage : translate('required')}</Popover></> 
                                                    : field.showWarning ?  <><div id={`warning-${field.Id}`}><Icon className={'app-form-warning'} icon={icons.triangleExclamation}/></div>
                                                        <Popover parentId={`#warning-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.warningMessage) ? field.warningMessage : translate('required_warning')}</Popover></> 
                                                    : field.Component.ShowTooltip ? <><div id={`tooltip-${field.Id}`}><Icon className={'app-form-tooltip'} icon={icons.circleQuestion}/></div>
                                                        <Popover parentId={`#tooltip-${field.Id}`} height={-1} width={-1} showTitle={false}>{field.Component.Tooltip}</Popover></>
                                                    : ''
                                }
                            </td>
                            <td className='app-form-content-jd-test'>
                                <TextBox
                                    className={field.showError ? 'app-form-error' : field.showWarning ? 'app-form-warning' : ''}
                                    type={inputBoxTypes.numeric}
                                    inputAttr={formsModule.convertStyleObject(_.isObject(field.styleOverride) ? field.styleOverride : field.ValueStyle)}
                                    value={field.Component.ComponentValue} 
                                    maxLength={field.Component.ComponentMaxLength}
                                    readOnly={field.Component.ComponentReadOnly}
                                    allowNegative={true}
                                    allowDecimals={true}
                                    allowNull={true}    
                                    maxDigits={field.Component.ComponentMaxLength}
                                    maxDecimals={field.Component.ComponentDecimalPlaces}                
                                    onChange={(o) => {
                                        setValue({
                                            field: field,
                                            value: o.value
                                        })
                                    }}
                                />
                            </td>
                            <td className='app-form-required-column'>
                                {field.Component.ComponentRequired ? <div id={`required-${field.Id}`}><Icon className={'app-form-required'} icon={icons.asterisk}/></div> : ''}
                                {field.Component.ComponentRequired ? <Popover parentId={`#required-${field.Id}`} height={-1} width={-1} showTitle={false}>{translate('required')}</Popover> : ''}
                            </td>
                        </tr>
                    </tbody>
                </table>
            case constants.forms.components.currency:
                return  <table className='app-form-component-table'>
                <tbody>
                    <tr>
                        <td className={field.showError || field.showWarning || field.Component.ShowTooltip ? 'app-form-prefix-column' : 'app-form-prefix-column-empty'}>
                            {
                                field.showError ? <><div id={`error-${field.Id}`}><Icon className={'app-form-required'} icon={icons.octogonExclamation}/></div>
                                                    <Popover parentId={`#error-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.errorMessage) ? field.errorMessage : translate('required')}</Popover></> 
                                                : field.showWarning ?  <><div id={`warning-${field.Id}`}><Icon className={'app-form-warning'} icon={icons.triangleExclamation}/></div>
                                                    <Popover parentId={`#warning-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.warningMessage) ? field.warningMessage : translate('required_warning')}</Popover></> 
                                                : field.Component.ShowTooltip ? <><div id={`tooltip-${field.Id}`}><Icon className={'app-form-tooltip'} icon={icons.circleQuestion}/></div>
                                                    <Popover parentId={`#tooltip-${field.Id}`} height={-1} width={-1} showTitle={false}>{field.Component.Tooltip}</Popover></>
                                                : ''
                            }
                        </td>
                        <td className='app-form-content-jd-test'>
                            <TextBox
                                className={field.showError ? 'app-form-error' : field.showWarning ? 'app-form-warning' : ''}
                                type={inputBoxTypes.currency}
                                inputAttr={formsModule.convertStyleObject(_.isObject(field.styleOverride) ? field.styleOverride : field.ValueStyle)}
                                value={field.Component.ComponentValue} 
                                maxLength={field.Component.ComponentMaxLength}
                                readOnly={field.Component.ComponentReadOnly}
                                allowNegative={true}
                                allowDecimals={true}
                                allowZero={true}
                                allowCommas={true}
                                allowLeadingZeros={true}
                                allowNull={true}
                                maxDigits={field.Component.ComponentMaxLength}
                                maxDecimals={field.Component.ComponentDecimalPlaces}
                                onChange={(o) => {
                                    setValue({
                                        field: field,
                                        value: o.value
                                    });
                                }}
                            />
                        </td>
                        <td className='app-form-required-column'>
                            {field.Component.ComponentRequired ? <div id={`required-${field.Id}`}><Icon className={'app-form-required'} icon={icons.asterisk}/></div> : ''}
                            {field.Component.ComponentRequired ? <Popover parentId={`#required-${field.Id}`} height={-1} width={-1} showTitle={false}>{translate('required')}</Popover> : ''}
                        </td>
                    </tr>
                </tbody>
            </table>
            case constants.forms.components.percentage:
                return <table className='app-form-component-table'>
                <tbody>
                    <tr>
                        <td className={field.showError || field.showWarning || field.Component.ShowTooltip ? 'app-form-prefix-column' : 'app-form-prefix-column-empty'}>
                            {
                                field.showError ? <><div id={`error-${field.Id}`}><Icon className={'app-form-required'} icon={icons.octogonExclamation}/></div>
                                                    <Popover parentId={`#error-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.errorMessage) ? field.errorMessage : translate('required')}</Popover></> 
                                                : field.showWarning ?  <><div id={`warning-${field.Id}`}><Icon className={'app-form-warning'} icon={icons.triangleExclamation}/></div>
                                                    <Popover parentId={`#warning-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.warningMessage) ? field.warningMessage : translate('required_warning')}</Popover></> 
                                                : field.Component.ShowTooltip ? <><div id={`tooltip-${field.Id}`}><Icon className={'app-form-tooltip'} icon={icons.circleQuestion}/></div>
                                                    <Popover parentId={`#tooltip-${field.Id}`} height={-1} width={-1} showTitle={false}>{field.Component.Tooltip}</Popover></>
                                                : ''
                            }
                        </td>
                        <td className='app-form-content-jd-test'>
                            <TextBox
                                className={field.showError ? 'app-form-error' : field.showWarning ? 'app-form-warning' : ''} 
                                type={inputBoxTypes.percent}
                                inputAttr={formsModule.convertStyleObject(_.isObject(field.styleOverride) ? field.styleOverride : field.ValueStyle)}
                                value={field.Component.ComponentValue} 
                                maxLength={field.Component.ComponentMaxLength}
                                readOnly={field.Component.ComponentReadOnly}
                                allowNegative={true}
                                allowDecimals={true}
                                allowCommas={true}
                                allowNull={true}
                                storedAsDecimal={field.Component.ComponentDecimalPercentage}
                                maxDigits={field.Component.ComponentMaxLength}
                                maxDecimals={field.Component.ComponentDecimalPlaces}
                                onChange={(o) => {
                                    setValue({
                                        field: field,
                                        value: o.value
                                    })
                                }}
                            />
                        </td>
                        <td className='app-form-required-column'>
                            {field.Component.ComponentRequired ? <div id={`required-${field.Id}`}><Icon className={'app-form-required'} icon={icons.asterisk}/></div> : ''}
                            {field.Component.ComponentRequired ? <Popover parentId={`#required-${field.Id}`} height={-1} width={-1} showTitle={false}>{translate('required')}</Popover> : ''}
                        </td>
                    </tr>
                </tbody>
            </table>
            case constants.forms.components.date:
                return <table className='app-form-component-table'>
                <tbody>
                    <tr>
                        <td className={field.showError || field.showWarning || field.Component.ShowTooltip ? 'app-form-prefix-column' : 'app-form-prefix-column-empty'}>
                            {
                                field.showError ? <><div id={`error-${field.Id}`}><Icon className={'app-form-required'} icon={icons.octogonExclamation}/></div>
                                                    <Popover parentId={`#error-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.errorMessage) ? field.errorMessage : translate('required')}</Popover></> 
                                                : field.showWarning ?  <><div id={`warning-${field.Id}`}><Icon className={'app-form-warning'} icon={icons.triangleExclamation}/></div>
                                                    <Popover parentId={`#warning-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.warningMessage) ? field.warningMessage : translate('required_warning')}</Popover></> 
                                                : field.Component.ShowTooltip ? <><div id={`tooltip-${field.Id}`}><Icon className={'app-form-tooltip'} icon={icons.circleQuestion}/></div>
                                                    <Popover parentId={`#tooltip-${field.Id}`} height={-1} width={-1} showTitle={false}>{field.Component.Tooltip}</Popover></>
                                                : ''
                            }
                        </td>
                        <td className='app-form-content-jd-test'>
                            <DateBox 
                                className={field.showError ? 'app-form-error' : field.showWarning ? 'app-form-warning' : ''}
                                inputAttr={formsModule.convertStyleObject(_.isObject(field.styleOverride) ? field.styleOverride : field.ValueStyle)}
                                value={field.Component.ComponentValue}
                                onChange={(o) => {
                                    setValue({
                                        field: field,
                                        value: o.value
                                    });
                                }} 
                            />
                        </td>
                        <td className='app-form-required-column'>
                            {field.Component.ComponentRequired ? <div id={`required-${field.Id}`}><Icon className={'app-form-required'} icon={icons.asterisk}/></div> : ''}
                            {field.Component.ComponentRequired ? <Popover parentId={`#required-${field.Id}`} height={-1} width={-1} showTitle={false}>{translate('required')}</Popover> : ''}
                        </td>
                    </tr>
                </tbody>
            </table>
            case constants.forms.components.checkbox:
                return <table className='app-form-component-table'>
                <tbody>
                    <tr>
                        <td className={field.showError || field.showWarning || field.Component.ShowTooltip ? 'app-form-prefix-column' : 'app-form-prefix-column-empty'}>
                            {
                                field.showError ? <><div id={`error-${field.Id}`}><Icon className={'app-form-required'} icon={icons.octogonExclamation}/></div>
                                                    <Popover parentId={`#error-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.errorMessage) ? field.errorMessage : translate('required')}</Popover></> 
                                                : field.showWarning ?  <><div id={`warning-${field.Id}`}><Icon className={'app-form-warning'} icon={icons.triangleExclamation}/></div>
                                                    <Popover parentId={`#warning-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.warningMessage) ? field.warningMessage : translate('required_warning')}</Popover></> 
                                                : field.Component.ShowTooltip ? <><div id={`tooltip-${field.Id}`}><Icon className={'app-form-tooltip'} icon={icons.circleQuestion}/></div>
                                                    <Popover parentId={`#tooltip-${field.Id}`} height={-1} width={-1} showTitle={false}>{field.Component.Tooltip}</Popover></>
                                                : ''
                            }
                        </td>
                        <td className='app-form-content-jd-test'>
                            <div className='app-form-checkbox-container'>
                                <input 
                                   className={field.showError ? 'app-form-error' : field.showWarning ? 'app-form-warning' : ''}
                                   type='checkbox'
                                   onChange={(o) => {
                                       setValue({
                                           field: field,
                                           value: o.value
                                       });
                                   }}
                                />
                            </div>       
                        </td>
                        <td className='app-form-required-column'>
                            {field.Component.ComponentRequired ? <Icon className={'app-form-required'} icon={icons.asterisk}/> : ''}
                        </td>
                    </tr>
                </tbody>
            </table>
            case constants.forms.components.label:
                return <div className='app-form-value' style={formsModule.buildStyleObject(field.ValueStyle)}>{field.RenderedValue.value}</div>;
            case constants.forms.components.photo:
                return 'photo';
            case constants.forms.components.radio:
                return <table className='app-form-component-table'>
                <tbody>
                    <tr>
                        <td className={field.showError || field.showWarning || field.Component.ShowTooltip ? 'app-form-prefix-column' : 'app-form-prefix-column-empty'}>
                            {
                                field.showError ? <><div id={`error-${field.Id}`}><Icon className={'app-form-required'} icon={icons.octogonExclamation}/></div>
                                                    <Popover parentId={`#error-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.errorMessage) ? field.errorMessage : translate('required')}</Popover></> 
                                                : field.showWarning ?  <><div id={`warning-${field.Id}`}><Icon className={'app-form-warning'} icon={icons.triangleExclamation}/></div>
                                                    <Popover parentId={`#warning-${field.Id}`} height={-1} width={-1} showTitle={false}>{_.isString(field.warningMessage) ? field.warningMessage : translate('required_warning')}</Popover></> 
                                                : field.Component.ShowTooltip ? <><div id={`tooltip-${field.Id}`}><Icon className={'app-form-tooltip'} icon={icons.circleQuestion}/></div>
                                                    <Popover parentId={`#tooltip-${field.Id}`} height={-1} width={-1} showTitle={false}>{field.Component.Tooltip}</Popover></>
                                                : ''
                            }
                        </td>
                        <td className='app-form-content-jd-test'>
                            <input 
                                className={field.showError ? 'app-form-error' : field.showWarning ? 'app-form-warning' : ''}
                                type='radio' 
                                name={`radioGroup_${section.Id}`}
                            />   
                        </td>
                        <td className='app-form-required-column'>
                            {field.Component.ComponentRequired ? <div id={`required-${field.Id}`}><Icon className={'app-form-required'} icon={icons.asterisk}/></div> : ''}
                            {field.Component.ComponentRequired ? <Popover parentId={`#required-${field.Id}`} height={-1} width={-1} showTitle={false}>{translate('required')}</Popover> : ''}
                        </td>
                    </tr>
                </tbody>
            </table>         
        }
    };
    
	return <Form 
        form={_form}
        flatten={flatten}
        onFieldRender={renderComponent}
    />
}