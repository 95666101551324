// React imports
import { useMemo } from 'react';

// App imports
import { Popover } from '../../../base/popover/popover';
import { Bar } from '../../../base/bar/bar';
import { DropDown } from '../../../base/dropDown/dropDown';
import { ColorBox } from 'devextreme-react';
import { NumberBox } from '../../../base/numberBox/numberBox';
import { Icon, icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation"
import { constants } from '../../../../utils/constants';

const _ = require("lodash");

export function PrintPointSourceStorePopover({ parentId, title, active = true, pointSource, updatePointSource }) {

    const numberOfStores = useMemo(() => [
        { key: 1, text: '1' }, 
        { key: 2, text: '2' }, 
        { key: 3, text: '3' }, 
        { key: 4, text: '4' },
        { key: 5, text: '5' },
        { key: 6, text: '6' },
        { key: 7, text: '7' },
        { key: 8, text: '8' },
        { key: 9, text: '9' },
        { key: 10, text: '10' }
    ], []);

    const lineWidths = useMemo(() => [
        { key: 1, text: '1' }, 
        { key: 2, text: '2' }, 
        { key: 3, text: '3' }, 
        { key: 4, text: '4' },
        { key: 5, text: '5' },
        { key: 6, text: '6' }
    ], []);

    const measurements = useMemo(() => [
        { key: constants.lengthMeasurements.miles, text: 'mi' },
        { key: constants.lengthMeasurements.kilometers, text: 'km' }
    ], []);

    return <>
        { !active || !_.isObject(pointSource) ? '' :
        <Popover 
            parentId={`#${parentId}`}
            title={title}
            height={480}
            width={275}
        >
            <div className='app-projects-print-cols'>
                <div className='app-projects-print-rows'>
                    <div className='app-projects-print-labels'>{`${translate('show_closest_stores')}:`}</div>
                    <div className='app-option-selector'>
                        {pointSource.closestStore === true ? 
                        <div className='app-option-selector-check'>
                            <Icon icon={icons.check} />
                        </div> : ''}
                        <Bar className='app-projects-print-zoom-bar'
                            icon={icons.store}
                            tooltip={translate('show_closest_stores')}
                            active={pointSource.closestStore === true} 
                            onClick={() => { updatePointSource({ ...pointSource, closestStore: !pointSource.closestStore }); }} 
                        /> 
                    </div>
                </div>
                <div className='app-projects-print-rows'>
                    <div className='app-projects-print-labels app-projects-print-stretch'>{translate('number_of_stores')}:</div>
                    <DropDown className={'app-projects-print-input'} items={numberOfStores} height={'36px'} width={'70px'}
                        display='text' valueProperty='key' selected={pointSource.closestStoreCount} 
                        onChange={(o) => { updatePointSource({ ...pointSource, closestStoreCount: o.value }); }} />
                </div>
                <div className='app-projects-print-rows'>
                    <ColorBox 
                        label={translate('line_color')} 
                        defaultValue={ _.isObject(pointSource.closestStoreLineColor) ? `rgba(${pointSource.closestStoreLineColor.r},${pointSource.closestStoreLineColor.g},${pointSource.closestStoreLineColor.b},${pointSource.closestStoreLineColor.a})` : 'white'} 
                        value={ _.isObject(pointSource.closestStoreLineColor) ? `rgba(${pointSource.closestStoreLineColor.r},${pointSource.closestStoreLineColor.g},${pointSource.closestStoreLineColor.b},${pointSource.closestStoreLineColor.a})` : 'black'} 
                        editAlphaChannel={true}
                        onValueChange={(o) => {
                            var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                            updatePointSource({ ...pointSource, closestStoreLineColor: { r: parseFloat(colors[0]), g: parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) } });
                        }} 
                    />
                </div>
                <div className='app-projects-print-rows'>
                    <div className='app-projects-print-labels app-projects-print-stretch'>{translate('line_width')}:</div>
                    <DropDown className={'app-projects-print-input'} items={lineWidths} height={'36px'} width={'70px'}
                        display='text' valueProperty='key' selected={pointSource.closestStoreLineWidth} 
                        onChange={(o) => { updatePointSource({ ...pointSource, closestStoreLineWidth: o.value }); }} />
                </div>
                <div className='app-projects-print-rows'>
                    <ColorBox 
                        label={translate('text_color')} 
                        defaultValue={ _.isObject(pointSource.closestStoreDistanceTextColor) ? `rgba(${pointSource.closestStoreDistanceTextColor.r},${pointSource.closestStoreDistanceTextColor.g},${pointSource.closestStoreDistanceTextColor.b},${pointSource.closestStoreDistanceTextColor.a})` : 'white'} 
                        value={ _.isObject(pointSource.closestStoreDistanceTextColor) ? `rgba(${pointSource.closestStoreDistanceTextColor.r},${pointSource.closestStoreDistanceTextColor.g},${pointSource.closestStoreDistanceTextColor.b},${pointSource.closestStoreDistanceTextColor.a})` : 'black'} 
                        editAlphaChannel={true}
                        onValueChange={(o) => {
                            var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                            updatePointSource({ ...pointSource, closestStoreDistanceTextColor: { r: parseFloat(colors[0]), g: parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) } });
                        }} 
                    />
                </div>
                <div className='app-projects-print-rows'>
                    <div className='app-projects-print-labels app-projects-print-stretch'>{translate('distance_cutoff_2')}:</div>
                    <div className='app-projects-print-textbox'>
                    <NumberBox 
                        className={'app-projects-print-input'} 
                        height={'36px'} 
                        value={pointSource.closestStoreDistanceCutoff}
                        maxValue={100}
                        minValue={0}
                        format={'##0.#'}
                        onChange={(o) => { updatePointSource({ ...pointSource, closestStoreDistanceCutoff: o.value }); }} 
                    />
                    </div>
                </div>
                <div className='app-projects-print-rows'>
                    <div className='app-projects-print-labels app-projects-print-stretch'>{translate('measurement')}:</div>
                    <DropDown className={'app-projects-print-input'} items={measurements} height={'36px'} width={'70px'}
                        display='text' valueProperty='key' selected={pointSource.closestStoreDistanceMeasurement} 
                        onChange={(o) => { updatePointSource({ ...pointSource, closestStoreDistanceMeasurement: o.value }); }} />
                </div>
           </div>
        </Popover>
        }
    </>
}