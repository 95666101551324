// React imports
import { useState, useEffect, useCallback, useMemo } from 'react';

// Third party imports
import ColorBox from 'devextreme-react/color-box';

// App imports
import { DropDown } from '../../../base/dropDown/dropDown';
import { Button } from '../../../base/button/button';
import { Bar } from '../../../base/bar/bar';
import { PrintCustomerMapPopover } from './printCustomerMapPopover';
import { PrintPolygonThemtics } from './printPolygonThematics';
import { PrintPointSources } from './printPointSources';
import { translate } from "../../../../utils/translation"
import { Icon, icons } from '../../../base/icon/icon';
import { constants } from '../../../../utils/constants';
import { helpers } from '../../../../utils/helpers';
import { projects } from '../../../../modules/projects';

const _ = require("lodash");

export function PrintMapContent({ adHocMap, thematics, sources, dataMaps, onSave }) {
    
    const defaultStoreCount = 6;
    const defaultDistanceCutoff = 20;
    const defaultDistanceMeasurement = constants.lengthMeasurements.miles;

    const [reset, setReset] = useState(false);

    const [includeSelection, setIncludeSelection] = useState(true);
    const [showSelectionLabel, setShowSelectionLabel] = useState(true);
    const [selectionLabelColor, setSelectionLabelColor] = useState(true);
    const [selectionLabelFillColor, setSelectionLabelFillColor] = useState(true);
    
    const [rangedThematics, setRangedThematics] = useState([]);
    const [rangedThematic, setRangedThematic] = useState(-1);

    const [dotDensityThematics, setDotDensityThematics] = useState([]);
    const [dotDensityThematic, setDotDensityThematic] = useState(-1);

    const [availablePolygonThematics, setAvailablePolygonThematics] = useState([]);
    const [selectedPolygonThematics, setSelectedPolygonThematics] = useState([]);

    const [availablePointSources, setAvailablePointSources] = useState([]);
    const [selectedPointSources, setSelectedPointSources] = useState([]);

    const [pinQueryMaps, setPinQueryMaps] = useState([]);
    const [pinQueryMap, setPinQueryMap] = useState("-1");
    const [pinDataMaps, setPinDataMaps] = useState([]);
    const [pinDataMap, setPinDataMap] = useState("-1");
    const [pinStoreCount, setPinStoreCount] = useState(defaultStoreCount);
    const [pinMaxDistance, setPinMaxDistance] = useState(defaultDistanceCutoff);
    const [pinDistanceMeasurement, setPinDistanceMeasurement] = useState(defaultDistanceMeasurement);

    const [heatQueryMaps, setHeatQueryMaps] = useState([]);
    const [heatQueryMap, setHeatQueryMap] = useState("-1");
    const [heatDataMaps, setHeatDataMaps] = useState([]);
    const [heatDataMap, setHeatDataMap] = useState("-1");
    const [heatStoreCount, setHeatStoreCount] = useState(defaultStoreCount);
    const [heatMaxDistance, setHeatMaxDistance] = useState(defaultDistanceCutoff);
    const [heatDistanceMeasurement, setHeatDistanceMeasurement] = useState(defaultDistanceMeasurement);

    const [marketShareQueryMaps, setMarketShareQueryMaps] = useState([]);
    const [marketShareQueryMap, setMarketShareQueryMap] = useState("-1");
    const [marketShareDataMaps, setMarketShareDataMaps] = useState([]);
    const [marketShareDataMap, setMarketShareDataMap] = useState("-1");
    const [marketShareStoreCount, setMarketShareStoreCount] = useState(defaultStoreCount);
    const [marketShareMaxDistance, setMarketShareMaxDistance] = useState(defaultDistanceCutoff);
    const [marketShareDistanceMeasurement, setMarketShareDistanceMeasurement] = useState(defaultDistanceMeasurement);

    const [desireLinesQueryMaps, setDesireLinesQueryMaps] = useState([]);
    const [desireLinesQueryMap, setDesireLinesQueryMap] = useState("-1");
    const [desireLinesDataMaps, setDesireLinesDataMaps] = useState([]);
    const [desireLinesDataMap, setDesireLinesDataMap] = useState("-1");
    const [desireLinesStoreCount, setDesireLinesStoreCount] = useState(defaultStoreCount);
    const [desireLinesMaxDistance, setDesireLinesMaxDistance] = useState(defaultDistanceCutoff);
    const [desireLinesDistanceMeasurement, setDesireLinesDistanceMeasurement] = useState(defaultDistanceMeasurement);

    useEffect(() =>{

        if (!_.isObject(adHocMap))
            return;

        // Thematics
        var newItems = [];
        if (_.isArray(thematics))
            newItems = projects.getItemsByType({ items: thematics, type: constants.layers.types.thematics.subTypes.range });

        newItems.unshift({ id: -1, text: translate('none') });
        setRangedThematics([...newItems]);
        // rangeTheme is a number in available themes, but is a string in saved themes
        setRangedThematic(_.isObject(adHocMap.rangeTheme) ? Number(adHocMap.rangeTheme.id) : -1);

        newItems = [];
        if (_.isArray(thematics))
            newItems = projects.getItemsByType({ items: thematics, type: constants.layers.types.thematics.subTypes.dotDensity });

        newItems.unshift({ id: -1, text: translate('none') });
        setDotDensityThematics([...newItems]);
        // dotDensityTheme is a number in available themes, but is a string in saved themes
        setDotDensityThematic(_.isObject(adHocMap.dotDensityTheme) ? Number(adHocMap.dotDensityTheme.id) : -1);

        // Available Polygon Themes
        newItems = [];
        if (_.isArray(thematics))
            newItems = projects.getItemsByType({ items: thematics, type: constants.layers.types.thematics.subTypes.polygon });
    
        setAvailablePolygonThematics([...newItems]);

        if (_.isArray(adHocMap.polygonThemes) && adHocMap.polygonThemes.length > 0) {
            const selectedThemes = _.sortBy(adHocMap.polygonThemes, [function(o) { return o.sequence; }]).map(theme => {
                // theme.id is a number in available themes, but is a string in saved polygon themes
                return { id: helpers.newGuid(), text: theme.name, originalId: Number(theme.id) };
            });
            setSelectedPolygonThematics(selectedThemes);
        }
            
        // Available Point Sources
        newItems = [];
        if (_.isArray(sources))
            newItems = projects.getItemsByType({ items: sources, types: [0,1] });
        setAvailablePointSources([...newItems]);

        if (_.isArray(adHocMap.pointSources) && adHocMap.pointSources.length > 0) {
            const selectedSources = _.sortBy(adHocMap.pointSources, [function(o) { return o.sequence; }]).map(source => {
                return { 
                    id: helpers.newGuid(), 
                    text: source.name, 
                    originalId: source.id,
                    label: _.isBoolean(source.label) ? source.label : false,
                    labelColor: _.isObject(source.labelColor) ? source.labelColor : { r: 255, g: 255, b: 255, a: 1 },
                    labelFillColor: _.isObject(source.labelFillColor) ? source.labelFillColor : { r: 0, g: 0, b: 0, a: 1 },
                    closestStore: _.isBoolean(source.closestStore) ? source.closestStore : false,
                    closestStoreCount: _.isNumber(source.closestStoreCount) ? source.closestStoreCount : 5,
                    closestStoreDistanceCutoff: _.isNumber(source.closestStoreDistanceCutoff) ? source.closestStoreDistanceCutoff : 20,
                    closestStoreDistanceMeasurement: _.isNumber(source.closestStoreDistanceMeasurement) ? source.closestStoreDistanceMeasurement : 4,
                    closestStoreDistanceTextColor: _.isObject(source.closestStoreDistanceTextColor) ? source.closestStoreDistanceTextColor : { r: 255, g: 255, b: 255, a: 1 },
                    closestStoreLineColor: _.isObject(source.closestStoreLineColor) ? source.closestStoreLineColor : { r: 0, g: 0, b: 0, a: 1 },
                    closestStoreLineWidth: _.isNumber(source.closestStoreLineWidth) ? source.closestStoreLineWidth : 1,
                    type: source.type
                };
            });
            setSelectedPointSources(selectedSources);
        }

        // Customer Maps
        var pinQueries = [{ id: "-1", text: translate('none') }];
        var heatQueries = [{ id: "-1", text: translate('none') }];
        var marketQueries = [{ id: "-1", text: translate('none') }];
        var desireQueries = [{ id: "-1", text: translate('none') }];

        if (_.isArray(dataMaps))
            dataMaps.forEach(dataMap => {
                switch (dataMap.type) {
                    case constants.mapBooks.adHocMap.customerMaps.pin:
                        pinQueries.push({ id: dataMap.queryId, text: dataMap.queryName });
                        break;
                    case constants.mapBooks.adHocMap.customerMaps.heatMap:
                        heatQueries.push({ id: dataMap.queryId, text: dataMap.queryName });
                        break;
                    case constants.mapBooks.adHocMap.customerMaps.marketShare:
                        marketQueries.push({ id: dataMap.queryId, text: dataMap.queryName });
                        break;
                    case constants.mapBooks.adHocMap.customerMaps.desireLines:
                        desireQueries.push({ id: dataMap.queryId, text: dataMap.queryName });
                        break;
                    default:
                        break;
                }
            });
    
        setPinQueryMaps(pinQueries);
        setHeatQueryMaps(heatQueries);
        setMarketShareQueryMaps(marketQueries);
        setDesireLinesQueryMaps(desireQueries);

        var pinQuery = "-1";
        var heatQuery = "-1";
        var marketQuery = "-1";
        var desireQuery = "-1";

        if (_.isArray(adHocMap.dataMaps))
            adHocMap.dataMaps.forEach(dataMap => {
                switch (dataMap.type) {
                    case constants.mapBooks.adHocMap.customerMaps.pin:
                        pinQuery = dataMap.pointSourceId;
                        break;
                    case constants.mapBooks.adHocMap.customerMaps.heatMap:
                        heatQuery = dataMap.pointSourceId;
                        break;
                    case constants.mapBooks.adHocMap.customerMaps.marketShare:
                        marketQuery = dataMap.pointSourceId;
                        break;
                    case constants.mapBooks.adHocMap.customerMaps.desireLines:
                        desireQuery = dataMap.pointSourceId;
                        break;
                    default:
                        break;
                }
            });

        setPinQueryMap(pinQuery);
        setHeatQueryMap(heatQuery);
        setMarketShareQueryMap(marketQuery);
        setDesireLinesQueryMap(desireQuery);

        // Selections
        setIncludeSelection(adHocMap.includeSelection);
        setShowSelectionLabel(adHocMap.showSelectionLabel);
        setSelectionLabelColor(adHocMap.selectionLabelColor);
        setSelectionLabelFillColor(adHocMap.selectionLabelFillColor);

    }, [adHocMap, thematics, sources, dataMaps, reset]);

    const getDataMapList = useCallback(
        (o) => {

            if (!_.isObject(adHocMap) || !_.isArray(dataMaps) || dataMaps.length === 0)
                return;

            var query = _.find(adHocMap.dataMaps, function (item) {
                return item.pointSourceId == o.queryId && item.type === o.mapType;
            });

            if (_.isObject(query)) {
                o.id = query.id.toString();     // id saved with adhoc map is number, but is string in available data maps
                o.options = {
                    storeCount: query.storeCount, 
                    maxDistance: query.maxDistance, 
                    distanceMeasurement: query.distanceMeasurement 
                };
            }

            query = _.find(dataMaps, function (item) {
                return item.queryId == o.queryId && item.type === o.mapType;
            });

            if (_.isObject(query)) {
                query.dataMaps.forEach(dataMap => {
                    o.items.push({ id: dataMap.Key, text: dataMap.Value });
                });            
            }

            if (o.items.length === 0) {
                o.items.push({ id: "-1", text: translate('none') });
            }
            else if (o.id === "-1") {
                o.id = o.items[0].id;
            }

        }, [adHocMap, dataMaps]
    )

    useEffect(() =>{

        var props = { 
            queryId: pinQueryMap, 
            mapType: constants.mapBooks.adHocMap.customerMaps.pin,
            items: [], 
            id: "-1",
            options: { 
                storeCount: defaultStoreCount, 
                maxDistance: defaultDistanceCutoff, 
                distanceMeasurement: defaultDistanceMeasurement 
            }
        };

        getDataMapList(props);

        setPinDataMaps(props.items);
        setPinDataMap(props.id);
        setPinStoreCount(props.options.storeCount);
        setPinMaxDistance(props.options.maxDistance);
        setPinDistanceMeasurement(props.options.distanceMeasurement);

    }, [pinQueryMap, getDataMapList, defaultStoreCount, defaultDistanceCutoff, defaultDistanceMeasurement]);

    useEffect(() =>{

        var props = { 
            queryId: heatQueryMap, 
            mapType: constants.mapBooks.adHocMap.customerMaps.heatMap,
            items: [], 
            id: "-1",
            options: { 
                storeCount: defaultStoreCount, 
                maxDistance: defaultDistanceCutoff, 
                distanceMeasurement: defaultDistanceMeasurement 
            }
        };

        getDataMapList(props);

        setHeatDataMaps(props.items);
        setHeatDataMap(props.id);
        setHeatStoreCount(props.options.storeCount);
        setHeatMaxDistance(props.options.maxDistance);
        setHeatDistanceMeasurement(props.options.distanceMeasurement);

    }, [heatQueryMap, getDataMapList, defaultStoreCount, defaultDistanceCutoff, defaultDistanceMeasurement]);

    useEffect(() =>{

        var props = { 
            queryId: marketShareQueryMap, 
            mapType: constants.mapBooks.adHocMap.customerMaps.marketShare,
            items: [], 
            id: "-1",
            options: { 
                storeCount: defaultStoreCount, 
                maxDistance: defaultDistanceCutoff, 
                distanceMeasurement: defaultDistanceMeasurement 
            }
        };

        getDataMapList(props);

        setMarketShareDataMaps(props.items);
        setMarketShareDataMap(props.id);
        setMarketShareStoreCount(props.options.storeCount);
        setMarketShareMaxDistance(props.options.maxDistance);
        setMarketShareDistanceMeasurement(props.options.distanceMeasurement);

    }, [marketShareQueryMap, getDataMapList, defaultStoreCount, defaultDistanceCutoff, defaultDistanceMeasurement]);

    useEffect(() =>{

        var props = { 
            queryId: desireLinesQueryMap, 
            mapType: constants.mapBooks.adHocMap.customerMaps.desireLines,
            items: [], 
            id: "-1",
            options: { 
                storeCount: defaultStoreCount, 
                maxDistance: defaultDistanceCutoff, 
                distanceMeasurement: defaultDistanceMeasurement 
            }
        };

        getDataMapList(props);

        setDesireLinesDataMaps(props.items);
        setDesireLinesDataMap(props.id);
        setDesireLinesStoreCount(props.options.storeCount);
        setDesireLinesMaxDistance(props.options.maxDistance);
        setDesireLinesDistanceMeasurement(props.options.distanceMeasurement);

    }, [desireLinesQueryMap, getDataMapList, defaultStoreCount, defaultDistanceCutoff, defaultDistanceMeasurement]);

    const resetMap = () => {
        // toggle reset to force the initial useEffects to fire and restore to the original state
        setReset(!reset);
    }

    const getDataMap = (o) => {

        var dataMap = null;

        const query = _.find(dataMaps, function (item) {
            return item.queryId == o.queryId && item.type === o.mapType;
        });

        if (!_.isObject(query))
            return dataMap;

        const data = _.find(query.dataMaps, function (item) {
            return item.Key == o.dataId;
        });

        if (!_.isObject(data))
            return dataMap;

        return {
            pointSourceId: query.queryId,
            id: data.Key,
            name: data.Value,
            type: query.type,
            storeCount: o.mapOptions.storeCount,
            maxDistance: o.mapOptions.maxDistance,
            distanceMeasurement: o.mapOptions.distanceMeasurement
        };

    }

    const saveMap = () => {
        if (!_.isFunction(onSave))
            return;

        var newAdHocMap = _.cloneDeep(adHocMap);

        newAdHocMap.includeSelection = includeSelection;
        newAdHocMap.showSelectionLabel = showSelectionLabel;
        newAdHocMap.selectionLabelColor = selectionLabelColor;
        newAdHocMap.selectionLabelFillColor = selectionLabelFillColor;

        // Ranged Thematic
        var item = _.find(rangedThematics, function (o) {
            return o.id == rangedThematic;
        });

        if (_.isObject(item)) {
            newAdHocMap.rangeTheme = item.id === -1 ? null : { id: item.id.toString(), name: item.text };
        }

        // Dot Density Thematic
        item = _.find(dotDensityThematics, function (o) {
            return o.id == dotDensityThematic;
        });

        if (_.isObject(item)) {
            newAdHocMap.dotDensityTheme = item.id === -1 ? null : { id: item.id.toString(), name: item.text };
        }

        // Polygon Thematics
        newAdHocMap.polygonThemes = selectedPolygonThematics.map((theme, i) => {
            return { id: theme.originalId.toString(), name: theme.text, tempId: theme.id };        // TBD: KLI 1.0 does not include sequence? ( sequence: i+1 )
        });
        // Point Sources
        newAdHocMap.pointSources = selectedPointSources.map((source, i) => {
            return { 
                id: source.originalId, 
                name: source.text, 
                tempId: source.id,
                label: source.label,
                labelColor: source.labelColor,
                labelFillColor: source.labelFillColor,
                closestStore: source.closestStore,
                closestStoreCount: source.closestStoreCount,
                closestStoreDistanceCutoff: source.closestStoreDistanceCutoff,
                closestStoreDistanceMeasurement: source.closestStoreDistanceMeasurement,
                closestStoreDistanceTextColor: source.closestStoreDistanceTextColor,
                closestStoreLineColor: source.closestStoreLineColor,
                closestStoreLineWidth: source.closestStoreLineWidth,
                type: source.type
            };
        });

        // Data Maps
        newAdHocMap.dataMaps = [];

        var dataMap = getDataMap({ queryId: pinQueryMap, dataId: pinDataMap, mapType: constants.mapBooks.adHocMap.customerMaps.pin, 
            mapOptions: { storeCount: pinStoreCount, maxDistance: pinMaxDistance, distanceMeasurement: pinDistanceMeasurement }});

        if (_.isObject(dataMap))
            newAdHocMap.dataMaps.push(dataMap);

        dataMap = getDataMap({ queryId: heatQueryMap, dataId: heatDataMap, mapType: constants.mapBooks.adHocMap.customerMaps.heatMap, 
            mapOptions: { storeCount: heatStoreCount, maxDistance: heatMaxDistance, distanceMeasurement: heatDistanceMeasurement }});

        if (_.isObject(dataMap))
            newAdHocMap.dataMaps.push(dataMap);

        dataMap = getDataMap({ queryId: marketShareQueryMap, dataId: marketShareDataMap, mapType: constants.mapBooks.adHocMap.customerMaps.marketShare, 
            mapOptions: { storeCount: marketShareStoreCount, maxDistance: marketShareMaxDistance, distanceMeasurement: marketShareDistanceMeasurement }});

        if (_.isObject(dataMap))
            newAdHocMap.dataMaps.push(dataMap);

        dataMap = getDataMap({ queryId: desireLinesQueryMap, dataId: desireLinesDataMap, mapType: constants.mapBooks.adHocMap.customerMaps.desireLines, 
            mapOptions: { storeCount: desireLinesStoreCount, maxDistance: desireLinesMaxDistance, distanceMeasurement: desireLinesDistanceMeasurement }});

        if (_.isObject(dataMap))
            newAdHocMap.dataMaps.push(dataMap);
            
        onSave(newAdHocMap);
    }

    return <>
        { !_.isArray(thematics) || thematics.length === 0 ? '' :
        <div className='app-projects-print-content-rows'>
            <div className='app-projects-print-cols app-projects-print-stretch'>
                <div className='app-projects-print-rows'>
                    <Button className='app-projects-print-apply-button app-projects-print-align-right' theme='secondary' tooltip={translate('reset')} size='small' icon={icons.clockRotateLeft} onClick={()=>{ resetMap(); }} />
                    <Button className='app-projects-print-apply-button' theme='primary' tooltip={translate('apply_changes')} size='small' icon={icons.check} onClick={()=>{ saveMap(); }} />
                </div>
                <div className='app-projects-print-selection-rows'>
                    <div className='app-projects-print-labels'>{`${translate('selections')}:`}</div>
                    <div className='app-option-selector'>
                        {includeSelection === true ? 
                        <div className='app-option-selector-check'>
                            <Icon icon={icons.check} />
                        </div> : ''}
                        <Bar className='app-projects-print-zoom-bar'
                            icon={icons.lasso}
                            tooltip={translate('include_selection')}
                            active={includeSelection === true} 
                            onClick={() => { setIncludeSelection(!includeSelection); }} 
                        /> 
                    </div>
                    <div className='app-option-selector'>
                        {showSelectionLabel === true ? 
                        <div className='app-option-selector-check'>
                            <Icon icon={icons.check} />
                        </div> : ''}
                        <Bar className='app-projects-print-zoom-bar'
                            icon={icons.tag}
                            tooltip={translate('show_selection_label')}
                            active={showSelectionLabel === true} 
                            onClick={() => { setShowSelectionLabel(!showSelectionLabel); }} 
                        /> 
                    </div>
                    <div className='app-projects-print-cols app-projects-print-stretch'>
                        <ColorBox 
                            label={translate('text_color')} 
                            defaultValue={ _.isObject(selectionLabelColor) ? `rgba(${selectionLabelColor.r},${selectionLabelColor.g},${selectionLabelColor.b},${selectionLabelColor.a})` : 'white'} 
                            value={ _.isObject(selectionLabelColor) ? `rgba(${selectionLabelColor.r},${selectionLabelColor.g},${selectionLabelColor.b},${selectionLabelColor.a})` : 'white'} 
                            editAlphaChannel={true}
                            onValueChange={(o) => {
                                var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                                setSelectionLabelColor({ r: parseFloat(colors[0]), g: parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) });
                            }} 
                        />
                        <ColorBox 
                            label={translate('fill_color')} 
                            defaultValue={ _.isObject(selectionLabelFillColor) ? `rgba(${selectionLabelFillColor.r},${selectionLabelFillColor.g},${selectionLabelFillColor.b},${selectionLabelFillColor.a})` : 'white'} 
                            value={ _.isObject(selectionLabelFillColor) ? `rgba(${selectionLabelFillColor.r},${selectionLabelFillColor.g},${selectionLabelFillColor.b},${selectionLabelFillColor.a})` : 'white'} 
                            editAlphaChannel={true}
                            onValueChange={(o) => {
                                var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                                setSelectionLabelFillColor({ r: parseFloat(colors[0]), g: parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) });
                            }} 
                        />
                    </div>
                </div>
                <div className='app-projects-print-rows'>
                    <DropDown className={'app-projects-print-input app-projects-print-stretch'} items={rangedThematics} label={translate('range_theme')} height={'40px'} 
                        display='text' valueProperty='id' selected={rangedThematic} 
                        onChange={(o) => { setRangedThematic(o.value); }} />
                </div>
                <div className='app-projects-print-rows'>
                    <DropDown className={'app-projects-print-input app-projects-print-stretch'} items={dotDensityThematics} label={translate('dot_density_theme')} height={'40px'} 
                        display='text' valueProperty='id' selected={dotDensityThematic} 
                        onChange={(o) => { setDotDensityThematic(o.value); }} />
                </div>
                <div className='app-projects-print-rows'>
                    <DropDown className={'app-projects-print-input app-projects-print-stretch'} items={pinQueryMaps} label={translate('pin_map')} height={'40px'} 
                        display='text' valueProperty='id' selected={pinQueryMap} 
                        onChange={(o) => { setPinQueryMap(o.value); }} />
                    <DropDown className={'app-projects-print-input app-projects-print-stretch'} items={pinDataMaps} label={translate('sources')} height={'40px'} 
                        display='text' valueProperty='id' selected={pinDataMap} disabled={pinQueryMap === '-1'}
                        onChange={(o) => { setPinDataMap(o.value); }} />
                    <PrintCustomerMapPopover parentId={'app-projects-print-pin-options'} title={`${translate('pin_map')} ${translate('options')}`} active={pinQueryMap !== '-1'}
                        storeCount={pinStoreCount} updateStoreCount={(value) => { setPinStoreCount(value); }}
                        maxDistance={pinMaxDistance} updateMaxDistance={(value) => { setPinMaxDistance(value); }}
                        distanceMeasurement={pinDistanceMeasurement} updateDistanceMeasurement={(value) => { setPinDistanceMeasurement(value); }}
                    />
                    <Button id='app-projects-print-pin-options' className='app-projects-print-content-options' theme='secondary' tooltip={translate('options')} 
                        size='small' icon={icons.gear} disabled={pinQueryMap === '-1'} />
                </div>
                <div className='app-projects-print-rows'>
                    <DropDown className={'app-projects-print-input app-projects-print-stretch'} items={heatQueryMaps} label={translate('heat_map')} height={'40px'} 
                        display='text' valueProperty='id' selected={heatQueryMap} 
                        onChange={(o) => { setHeatQueryMap(o.value); }} />
                    <DropDown className={'app-projects-print-input app-projects-print-stretch'} items={heatDataMaps} label={translate('sources')} height={'40px'} 
                        display='text' valueProperty='id' selected={heatDataMap} disabled={heatQueryMap === '-1'}
                        onChange={(o) => { setHeatDataMap(o.value); }} />
                    <PrintCustomerMapPopover parentId={'app-projects-print-heat-options'} title={`${translate('heat_map')} ${translate('options')}`} active={heatQueryMap !== '-1'}
                        storeCount={heatStoreCount} updateStoreCount={(value) => { setHeatStoreCount(value); }}
                        maxDistance={heatMaxDistance} updateMaxDistance={(value) => { setHeatMaxDistance(value); }}
                        distanceMeasurement={heatDistanceMeasurement} updateDistanceMeasurement={(value) => { setHeatDistanceMeasurement(value); }}
                    />
                    <Button id='app-projects-print-heat-options' className='app-projects-print-content-options' theme='secondary' tooltip={translate('options')} 
                        size='small' icon={icons.gear} disabled={heatQueryMap === '-1'} />
                </div>
                <div className='app-projects-print-rows'>
                    <DropDown className={'app-projects-print-input app-projects-print-stretch'} items={marketShareQueryMaps} label={translate('marketshare')} height={'40px'} 
                        display='text' valueProperty='id' selected={marketShareQueryMap} 
                        onChange={(o) => { setMarketShareQueryMap(o.value); }} />
                    <DropDown className={'app-projects-print-input app-projects-print-stretch'} items={marketShareDataMaps} label={translate('sources')} height={'40px'} 
                        display='text' valueProperty='id' selected={marketShareDataMap} disabled={marketShareQueryMap === '-1'}
                        onChange={(o) => { setMarketShareDataMap(o.value); }} />
                    <PrintCustomerMapPopover parentId={'app-projects-print-market-options'} title={`${translate('marketshare')} ${translate('options')}`} active={marketShareQueryMap !== '-1'}
                        storeCount={marketShareStoreCount} updateStoreCount={(value) => { setMarketShareStoreCount(value); }}
                        maxDistance={marketShareMaxDistance} updateMaxDistance={(value) => { setMarketShareMaxDistance(value); }}
                        distanceMeasurement={marketShareDistanceMeasurement} updateDistanceMeasurement={(value) => { setMarketShareDistanceMeasurement(value); }}
                    />
                    <Button id='app-projects-print-market-options' className='app-projects-print-content-options' theme='secondary' tooltip={translate('options')} 
                        size='small' icon={icons.gear} disabled={marketShareQueryMap === '-1'} />
                </div>
                <div className='app-projects-print-rows'>
                    <DropDown className={'app-projects-print-input app-projects-print-stretch'} items={desireLinesQueryMaps} label={translate('desire_lines')} height={'40px'} 
                        display='text' valueProperty='id' selected={desireLinesQueryMap} 
                        onChange={(o) => { setDesireLinesQueryMap(o.value); }} />
                    <DropDown className={'app-projects-print-input app-projects-print-stretch'} items={desireLinesDataMaps} label={translate('sources')} height={'40px'} 
                        display='text' valueProperty='id' selected={desireLinesDataMap} disabled={desireLinesQueryMap === '-1'} 
                        onChange={(o) => { setDesireLinesDataMap(o.value); }} />
                    <PrintCustomerMapPopover parentId={'app-projects-print-desire-options'} title={`${translate('desire_lines')} ${translate('options')}`} active={desireLinesQueryMap !== '-1'}
                        storeCount={desireLinesStoreCount} updateStoreCount={(value) => { setDesireLinesStoreCount(value); }}
                        maxDistance={desireLinesMaxDistance} updateMaxDistance={(value) => { setDesireLinesMaxDistance(value); }}
                        distanceMeasurement={desireLinesDistanceMeasurement} updateDistanceMeasurement={(value) => { setDesireLinesDistanceMeasurement(value); }}
                    />
                    <Button id='app-projects-print-desire-options' className='app-projects-print-content-options' theme='secondary' tooltip={translate('options')} 
                        size='small' icon={icons.gear} disabled={desireLinesQueryMap === '-1'} />
                </div>
                <div className='app-projects-print-rows'>
                    <PrintPolygonThemtics 
                        className={'app-projects-print-labels'} 
                        available={availablePolygonThematics} 
                        selected={selectedPolygonThematics}
                        onSelectedChanged={(o) => { setSelectedPolygonThematics(o); }}
                    />
                </div> 
                <div className='app-projects-print-rows'>
                    <PrintPointSources 
                        className={'app-projects-print-labels'} 
                        available={availablePointSources} 
                        selected={selectedPointSources}
                        onSelectedChanged={(o) => { setSelectedPointSources(o); }}
                    />
                </div>
           </div>
        </div>
        }       
    </>	
}