import { useCallback, useEffect, useRef, useState } from "react";
import { translate } from "../../../utils/translation";
import { LargePanel } from "../../base/largePanel/largePanel";

import { Details, Column, FileManager, ItemView, Toolbar, Permissions, Item } from 'devextreme-react/file-manager';
import { legacyEndpoints } from "../../../services/legacyEndpoints";
import { constants } from "../../../utils/constants";
import { helpers } from "../../../utils/helpers";
import { projections } from "../../../modules/projections";
import { Loader } from "../../base/loader/loader";
import { workBench } from "../../base/workBench/workBench";
import { map } from "../map/map";
import { ModalCard } from "../../base/modalCard/modalCard";
import { Button } from "../../base/button/button";
import { Icon, icons } from "../../base/icon/icon";
import { TreeList, SearchPanel, Selection } from 'devextreme-react/tree-list';
import { sources } from "../../../modules/sources";
import { layers } from "../layers/layers";
import { userPreferences } from "../app";

const _ = require("lodash");

export function Scenarios({ onClose }){

    const [loaded, setLoaded] = useState(false);
    const [fileSystem, setFileSystem ] = useState([]);
    const [searchList, setSearchList ] = useState({ store: [] });
    const [searchVisible, setSearchVisible] = useState(false);
    const [currentPath, setCurrentPath] = useState([]);
    const [selectedItemKeys, setSelectedItemKeys] = useState([]);
    const fileManagerRef = useRef(null);    

    useEffect(() =>{        
        updateFileSystem();
    }, []);

    const updateFileSystem = useCallback(async () =>{

        var data = await legacyEndpoints.service({
            name: 'GetScenarioFileSystem',
            parameters: {
                type: constants.scenarios.types.projections
            }
        })

        const flatten = (data, parent, root) =>{

            var flattenedData = [];

            data.forEach(x => {
                
                x.key = x.id;
                x.projection = x.dictionary.find(x => x.Key === "AnalyticsScenarioProjectionName")?.Value;
                x.mod = x.dateModified === "/Date(-62135596800000)/" ? '' : helpers.formatDate({ date: helpers.convertJSONDate(x.dateModified), format: 'MM/DD/YYYY h:mm a' });
                x.geography = x.dictionary.find(x => x.Key === "AnalyticsScenarioGeography")?.Value;                
                
                var item = {
                    key: x.id,
                    id: x.id,
                    name: x.name,
                    description: x.description,
                    projection: x.projection,
                    mod: x.dateModified,
                    geography: x.geography,                    
                    isDirectory: x.isDirectory,
                    parentId: root === true ? 0 : x.parentId,
                    userGuid: x.userGuid,
                    path: x.isDirectory === false ? parent : parent.length === 0 ? x.name : [parent, x.name].join('/')
                };

                flattenedData.push(item);

                if (x.items.length > 0)
                    flattenedData = [...flattenedData, ...flatten(x.items, item.path)];
            });
            
            return flattenedData;
        };
        
        setSearchList({store: flatten(data, '', true) });
        setFileSystem(data);
        setLoaded(true);
    },[]);

    const onDirectoryCreating = async (options) => {
        
        options.cancel = true;            
        
        await legacyEndpoints.service({
            name: 'CreateScenarioFolder',
            parameters: {
                name: options.name,
                type: constants.scenarios.types.projections,
                parentId: _.isObject(options.parentDirectory.dataItem) ? options.parentDirectory.dataItem.id : helpers.emptyGuid()
            }            
        });
                
        updateFileSystem();
    };

    const onItemMoving = async (options) => {
        
        if (options.item.dataItem.id === helpers.emptyGuid())
            options.cancel = true;
        else
            await legacyEndpoints.service({
                name: options.item.isDirectory ? 'MoveScenarioFolder' : 'MoveScenario',
                parameters: {
                    id: options.item.dataItem.id,
                    parentId: options.destinationDirectory.dataItem.id
                }
            });
    };

    const onItemDeleting = async (options) => {
        if (options.item.dataItem.id === helpers.emptyGuid())
            options.cancel = true;
        else
            if (options.item.isDirectory)
                await legacyEndpoints.service({
                    name: 'DeleteScenarioFolder',
                    parameters: {
                        id: options.item.dataItem.id,
                        type: constants.scenarios.types.projections
                    }
                });
            else
                await legacyEndpoints.service({
                    name: 'DeleteScenario',
                    parameters: {
                        Id: options.item.dataItem.id
                    }
                });
    };

    const onItemRenaming = async (options) => {        
        if (options.item.dataItem.id === helpers.emptyGuid())
            options.cancel = true;
        else
            await legacyEndpoints.service({
                name: options.item.isDirectory ? 'RenameScenarioFolder' : 'RenameScenario',
                parameters: {
                    id: options.item.dataItem.id,
                    name: options.newName
                }
            });
    };

    const onContextMenuShowing = function (options) {
        if(_.isUndefined(options.fileSystemItem))
            fileManagerRef.current.instance.option("contextMenu.items", ["refresh"]);
        else
            fileManagerRef.current.instance.option("contextMenu.items", options.fileSystemItem.dataItem.userGuid === userPreferences.UserGUID ? ["rename", "move", "delete", "refresh"] : []);  
    };

    const onSelectionChanged = async (options) => {
            
        if (options.selectedItems.length === 1)
        {
            //setSelectedItemKeys([options.selectedItems[0].dataItem.id]);
            showItemTools(options.selectedItems[0].dataItem);
        }
        else if (options.selectedItems.length > 1)
            showMultiItemTools(options.selectedItems)
    };

    const showItemTools = function (selectedItem) {
        var fileSelectionItems = ["refresh", "clearSelection"];
        
        fileManagerRef.current.instance.option("toolbar.fileSelectionItems", fileSelectionItems);
      }
    
      //Show toolbar buttons for multiple selected item
    const showMultiItemTools = function () {
        fileManagerRef.current.instance.option("toolbar.fileSelectionItems", []);
    }

    const onSelectedFileOpened = async (options) =>{

        var item = _.isObject(options.selectedRowsData) ? options.selectedRowsData : options.file.dataItem;

        onClose();

        workBench.setContent({ 
            title: `${translate('loading')}...`,
            content: <Loader />,
            height: '700px',
            maxHeight: '1000px',
            startMinimized: true,
            dragEnabled: false
        });

        var data = await legacyEndpoints.service({
            name: 'LoadAnalyticsProjectionScenario',
            parameters: {
                scenarioId: item.id
            }
        });

        var scenario = await legacyEndpoints.service({
            name: 'GetScenario',
            parameters: {
                Id: item.id
            }
        });

        projections.openInteractiveGrid({
            name: data.name,
            projectionResults: data,
            scenario: scenario
        });

        map.locate({ location: data.location });

        if (data.layer !== null)
            sources.refresh({
                layers: [{
                    id: data.layer.customQueryId,
                    text: translate('loading'),
                    subType: 0,
                    data: { isCompetitiveInsights: false }
                }],
                onRefresh: (o) =>{
                    layers.refreshDataLayers(o);
                }
            });
    };

    const getNewFileMenuOptions = () => ({
        text: translate('search'),
        icon: 'find',
        onClick: () =>{
            setSearchVisible(true);
        }
    });

    const renderName = ({ data })=>{        
        return <>
            <Icon icon={data.data.isDirectory ? icons.folderOpen : icons.file} />
            {data.data.name}
        </>
    };

    const selectSearch = ({ selectedRowsData })=>{           
        setCurrentPath(selectedRowsData[0].path.split('/'));
        setSelectedItemKeys([selectedRowsData[0].key]);
        setSearchVisible(false);
    };

    const onCurrentDirectoryChanged = useCallback((e) => {        
        setCurrentPath(e.component.option('currentPathKeys'));
      }, [setCurrentPath]);

	return <LargePanel className='app-scenarios' text={translate('scenarios')} loaded={loaded} generating={false} onClose={() =>{ onClose(); }}>
        {
            searchVisible ? 
            <ModalCard className='app-modal-card-default app-scenario-modal' open={true}>
                <div className='app-scenarios-search'>
                    <div className='app-scenarios-search-title'>
                        {translate('scenario_search')}
                        <Button className='app-scenarios-search-title-close' theme='action' icon={icons.x} onClick={()=>{setSearchVisible(false);}} />
                    </div>
                    <div className='app-scenarios-search-body'>
                        <TreeList 
                            keyExpr="id"
                            parentIdExpr="parentId"
                            dataSource={searchList}
                            showBorders={false}
                            columnAutoWidth={true}
                            wordWrapEnabled={true}                        
                            onSelectionChanged={selectSearch}                        
                            height={'90%'}
                        >
                            <SearchPanel visible={true} width={'1030px'} />
                            <Selection mode="single" />
                            <Column dataField="name" cellComponent={renderName} />
                            <Column dataField="description" />
                            <Column dataField="projection" />
                            <Column dataField="dateModified" />
                            <Column dataField="geography" />
                        </TreeList>
                    </div>                    
                </div>
            </ModalCard>
            :
            ''
        }
        <FileManager 
                ref={fileManagerRef}
                currentPathKeys={currentPath}
                onContextMenuShowing={onContextMenuShowing}
                rootFolderName={translate('scenarios')}
                selectionMode={'single'}
                fileSystemProvider={fileSystem}
                onDirectoryCreating={onDirectoryCreating}
                onItemMoving={onItemMoving}
                onItemRenaming={onItemRenaming}
                onItemDeleting={onItemDeleting} 
                onSelectionChanged={onSelectionChanged}
                onSelectedFileOpened={onSelectedFileOpened}
                onCurrentDirectoryChanged={onCurrentDirectoryChanged}
                selectedItemKeys={selectedItemKeys}
            >
                <Permissions create={true} download={false} move={true} rename={true} delete={true} />
                <ItemView showParentFolder={true}>
                    <Details>
                        <Column dataField="thumbnail" />
                        <Column dataField="name" />
                        <Column dataField="description" caption={translate('description')} />         
                        <Column dataField="geography" caption={translate('geography')} />
                        <Column dataField="mod" caption={translate('modified')} />
                    </Details>
                </ItemView>
                <Toolbar>
                    <Item name="showNavPane" visible={true} />
                    <Item name="separator" />
                    <Item name="create" />
                    <Item widget="dxButton" location="before" options={getNewFileMenuOptions()} />
                    <Item name="refresh" />
                    <Item name="separator"location="after"/>
                    <Item name="switchView" />
                </Toolbar>
            </FileManager>
        
    </LargePanel>;
}