// React imports
import { useState, useEffect } from 'react';

// App imports
import { TextArea } from '../../../base/textArea/textArea';
import { Button } from '../../../base/button/button';
import { ConfirmButton } from "../../../base/confirmButton/confirmButton";
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { helpers } from '../../../../utils/helpers';
import { userPreferences } from '../../app';

export function Comment({comment, replyActive, editActive, onReply, onEdit, onEditSave, onDelete, isSubComment, editSubCommentId, onEditSubComment, onEditSaveSubComment, onDeleteSubComment}) {

    const [userImage, setUserImage] = useState(null);
    const [commentText, setCommentText] = useState(null);
    const [subComments, setSubComments] = useState([]);
    const [processing, setProcessing] = useState(false);

    useEffect(() =>{
        setUserImage(legacyEndpoints.handlers.getPhotoUrl({ isUserPhoto: true, userId: comment.UserGUID, width: 40, height: 40 }));
        setCommentText(comment.Comment);
        setSubComments(comment.SubComments);

    }, [comment]);
    
    const saveComment = ()=>{
        setProcessing(true);
        var message = commentText.trim();
        var dateNow = '/Date(' + Date.now() + '+0000)/';

        !isSubComment ?
            legacyEndpoints.service({
                name: 'EditCommentWithAdditionalData',
                parameters: {
                    aID: comment.ID,
                    aComment: message
                },
                success: function(r) {
                    if (onEditSave) onEditSave({ comment: message, date: dateNow });
                    setProcessing(false);
                },
                error: function(e) {
                    setProcessing(false);
                }
            })
        :
            legacyEndpoints.service({
                name: 'EditSubCommentWithAdditionalData',
                parameters: {
                    Id: comment.ID,
                    Comment: message
                },
                success: function(r) {
                    if (onEditSave) onEditSave({ comment: message, date: dateNow });
                    setProcessing(false);
                },
                error: function(e) {
                    setProcessing(false);
                }
            })
    }

    var classes = ['app-comment'];
    if (userPreferences.UserGUID === comment.UserGUID)
        classes.push('app-comment-align-right');    
    if (comment.SubComments.length > 0)
        classes.push('app-comment-with-subcomments');    

    return (
        <div key={comment.ID} className={classes.join(' ')}>
            <div className='app-comment-container'>
                <div className='app-comment-image'>
                    <img className='app-comment-userImage' src={userImage} alt={comment.UserName} />
                </div>
                <div className='app-comment-details'>
                    <div className='app-comment-header'>
                        <div className='app-comment-user'>{comment.UserName}</div>
                        <div>{ (comment.isEdited ? translate('last_edited') + " " : "") + helpers.formatDate({ date: helpers.convertJSONDate(comment.Date) })}</div>
                    </div>
                    <div className='app-comment-message'>
                        { comment.isEditable && editActive ? 
                        <>
                            <TextArea className='app-comment-edit-input' label={translate('edit_comment')} value={commentText} minHeight={40} maxHeight={200} 
                                valueChangeEvent={'change keyup'}
                                onChange={(o) => { 
                                    setCommentText(o.value); 
                                }} 
                                onEnter={(o) => { 
                                    saveComment(); 
                                }}
                            /> 
                            <Button className='app-comments-edit-save-button' theme='primary' size='small' icon={icons.check} tooltip={translate('save')} disabled={processing} 
                                onClick={()=>{
                                    saveComment(); 
                                }} 
                            />
                        </>
                        : 
                            <div className='app-comment-display-text'>{comment.Comment}</div> 
                        }
                        { !isSubComment ?
                            <Button className='app-comment-reply-button' theme='simple' active={replyActive} icon={icons.reply} tooltip={translate('reply')} onClick={()=>{
                                if (onReply) onReply();
                            }} />
                        : ''
                        }   
                    </div>
                    { comment.isEditable ?
                        <div className='app-comment-edit-buttons'>
                            <Button className='app-comment-edit-button' theme='simple' active={editActive} icon={icons.edit} tooltip={translate('edit')} onClick={()=>{
                                if (onEdit) onEdit();
                            }} />
                            <ConfirmButton theme='simple' icon={icons.trash} tooltip={translate('delete')} clearText={translate('delete')} onConfirm={()=>{
                                !isSubComment ?
                                    legacyEndpoints.service({
                                        name: 'DeleteComment',
                                        parameters: {
                                            aID: comment.ID
                                        },
                                        success: function(r) {
                                            if (onDelete) onDelete();
                                        }
                                    })
                                :
                                    legacyEndpoints.service({
                                        name: 'DeleteSubComment',
                                        parameters: {
                                            Id: comment.ID
                                        },
                                        success: function(r) {
                                            if (onDelete) onDelete();
                                        }
                                    })
                                }} 
                            />
                        </div>
                    : '' }
                </div>
            </div>
                { comment.SubComments.length > 0 ?
                    <div className='app-comment-subcomments'>
                        {
                            comment.SubComments.map((comment, i) => {
                                return (
                                <Comment key={comment.ID} comment={comment} isSubComment={true} replyActive={false} editActive={editSubCommentId === comment.ID} 
                                    onEdit={()=>{ 
                                        if (onEditSubComment) onEditSubComment({ ID: comment.ID });
                                    }}
                                    onEditSave={(o)=>{
                                        const newSubComments = subComments.map(newComment => {
                                            if (newComment.ID !== comment.ID)
                                                return newComment;
                                            else
                                                return {...newComment, Comment: o.comment, Date: o.date };
                                        });

                                        setSubComments(newSubComments);

                                        if (onEditSaveSubComment) onEditSaveSubComment({ SubComments: newSubComments });
                                    }}
                                    onDelete={()=>{
                                        const newSubComments = subComments.filter(c => c.ID !== comment.ID);

                                        setSubComments(newSubComments);

                                        if (onDeleteSubComment) onDeleteSubComment({ SubComments: newSubComments });
                                    }}
                                />
                                )
                            })
                        }
                    </div>
                : ''
            }
        </div>
    );
}