import { useEffect, useState } from 'react';


// Third party imports
import ColorBox, { ColorBoxTypes } from 'devextreme-react/color-box';

// App imports
import { DropDown } from '../../base/dropDown/dropDown';
import { TextBox } from '../../base/textBox/textBox';
import { Button } from '../../base/button/button';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { constants } from '../../../utils/constants';

const _ = require("lodash");

export function ShapeEdit({entity, showName = true}) {
    const [name, setName] = useState(entity.text);    
    const [fillColor, setFillColor] = useState(entity.type === constants.entities.polyline ? null : entity.fillColor);
    const [strokeColor, setStrokeColor] = useState(entity.strokeColor);
    const [strokeWidth, setStrokeWidth] = useState(entity.strokeWidth);    
        
    return <div className='app-shape-edit-container'>
        <div className='app-shape-edit-button-container'>                    
            <Button theme='secondary' size='small' icon={icons.x} tooltip={translate('cancel')} onClick={() =>{
                if (_.isFunction(entity.onCancel))
                    entity.onCancel();
            }} />
            <Button theme='primary' size='small' icon={icons.check} tooltip={translate('save')} onClick={() =>{
                entity.text = name;
                
                if (entity.type !== constants.entities.polyline)
                    entity.fillColor = fillColor;
                
                entity.strokeColor = strokeColor;
                entity.strokeWidth = strokeWidth;
                if (_.isFunction(entity.onEdit))
                    entity.onEdit({
                        text: name,
                        fillColor: fillColor,
                        strokeColor: strokeColor,
                        strokeWidth: strokeWidth,
                        strokeStyle: 'solid'
                    });
            }}/>
        </div>
        {
            showName ? <TextBox className='app-trade-areas-name' label={translate('name')} value={name} onChange={(o) => {setName(o.value);}}/> : ''
        }
        <DropDown 
            label={translate('line_width')} 
            selected={strokeWidth} 
            fieldRender={(data) => {
                return <>
                        <img alt={translate('line_width')} className='app-shape-edit-line-width-image'/*style={}*/ src={data ? data.image : ''}/>
                        <TextBox readOnly={true} visible={false}/>
                    </>
             }}
            itemRender={(data) => { return <img alt={data.name} src={data.image} />}} 
            valueProperty='value'
            onChange={(o) => { setStrokeWidth(o.value) }}
            items={[1, 2, 3, 4, 5, 6].map((width) => { 
                return {
                    name: `LineWidth_${width}`,
                    value: width,
                    image: `https://tasonline.com/Content/Media/LineStyle/LineWidth_${width}.gif`
                }
            })}
        />
        {entity.type !== constants.entities.polyline ? <ColorBox 
            label={translate('fill_color')} 
            defaultValue={`rgba(${fillColor.r},${fillColor.g},${fillColor.b},${fillColor.a})`} 
            value={`rgba(${fillColor.r},${fillColor.g},${fillColor.b},${fillColor.a})`} 
            editAlphaChannel={true}
            onValueChange={(o) => {
                var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(',');                
                setFillColor({ r: parseFloat(colors[0]), g:parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) });
            }}
        /> : ''}        
        <ColorBox 
            label={translate('line_color')} 
            defaultValue={`rgba(${strokeColor.r},${strokeColor.g},${strokeColor.b},${strokeColor.a})`} 
            value={`rgba(${strokeColor.r},${strokeColor.g},${strokeColor.b},${strokeColor.a})`} 
            editAlphaChannel={true}
            onValueChange={(o) => {
                var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                setStrokeColor({ r: parseFloat(colors[0]), g:parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) });
            }} 
        />
    </div>
}