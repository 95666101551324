// React imports
import { useEffect } from 'react';

// App imports
import { Bar } from '../../../base/bar/bar';
import { Icon, icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { userPreferences } from '../../app';
import { constants } from '../../../../utils/constants';
import { DropDown } from '../../../base/dropDown/dropDown';
import { DateRangeBox } from '../../../base/dateRangeBox/dateRangeBox';
import { TextBox } from '../../../base/textBox/textBox';
import { ListFromService } from './listFromService';
import { helpers } from '../../../../utils/helpers';

const _ = require('lodash');

export function ManagementReportsStep2({ outputFormat, updateOutputFormat, selectedReport, reportParams, updateReportParams, initReportParams, onReadyNext }) {

    useEffect(() => {

        if (onReadyNext)
            onReadyNext(reportParams != null);

    }, [onReadyNext, reportParams])

    useEffect(() => {
        const fetchData = async () => {
            await populateDefaultReportParams();
        };

        fetchData();
    }, []);

    const getDefaultDateRange = (filterParams) => {
        const endDate = new Date();
        var startDate;

        var startFilterParam = filterParams.find((p) => p.Type === 0);
        var endFilterParam = filterParams.find((p) => p.Type === 1);

        if (endDate.getMonth() === 0)
            startDate = new Date(endDate.getFullYear() - 1, 11, endDate.getDate());
        else
            startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate());

        return {
            startDate: helpers.formatDate({ date: startDate, format: 'MM/DD/YYYY' }),
            startReportParam: startFilterParam.ReportParam,
            endDate: helpers.formatDate({ date: endDate, format: 'MM/DD/YYYY' }),
            endReportParam: endFilterParam.ReportParam
        };
    };

    var populateDefaultReportParams = async () => {
        var params = [];

        selectedReport.FilterItems.forEach((filterItem) => {
            if (filterItem.Type === constants.reports.filters.type.date) {
                const defaultDateRange = getDefaultDateRange(filterItem.FilterParams);

                params.push({
                    GUID: defaultDateRange.GUID,
                    Key: defaultDateRange.startReportParam,
                    Value: defaultDateRange.startDate
                });

                params.push({
                    GUID: defaultDateRange.GUID,
                    Key: defaultDateRange.endReportParam,
                    Value: defaultDateRange.endDate
                });
            } else if (filterItem.Type === constants.reports.filters.type.custom && filterItem.FilterParams[0].dataType === constants.reports.filters.dataType.text) {
                var filterParam = filterItem.FilterParams[0];
                params.push({
                    GUID: filterParam.GUID,
                    Key: filterParam.startReportParam,
                    Value: filterParam.ValueList?.length == 1 ? filterParam.ValueList[0].Value : filterParam.DefaultValue
                });

            }
            else {
                filterItem.FilterParams.forEach((filterParam) => {
                    params.push({
                        GUID: filterParam.GUID,
                        Key: filterParam.ReportParam,
                        Value: filterParam.DefaultValue
                    });
                });
            }
        });

        initReportParams(params);
    }

    const getReportControls = () => {
        var controls = [];

        selectedReport.FilterItems.forEach((filterItem) => {
            if (!filterItem.IsVisible) return;

            switch (filterItem.Type) {
                default:
                    break;
                case constants.reports.filters.type.date:

                    const defaultDateRange = getDefaultDateRange(filterItem.FilterParams);

                    controls.push(<DateRangeBox key={filterItem.GUID} 
                                                className="management-reports-date-range" 
                                                startDate={reportParams.find((p) => p.Key === defaultDateRange.startReportParam)?.Value}
                                                endDate={reportParams.find((p) => p.Key === defaultDateRange.endReportParam)?.Value}
                                                height={47} 
                                                label={translate("date_range")}
                                                onChange={(o) => {
                                                    updateReportParams({ key: defaultDateRange.startReportParam, newValue: helpers.formatDate({ date: o.startDate, format: 'MM/DD/YYYY' }) })
                                                    updateReportParams({ key: defaultDateRange.endReportParam, newValue: helpers.formatDate({ date: o.endDate, format: 'MM/DD/YYYY' }) })
                                                }} />);

                    break;
                case constants.reports.filters.type.user:
                    controls.push(<ListFromService key={filterItem.GUID} className={'app-reports-management-reports-criteria-item'} filter={filterItem} reportId={selectedReport.ID}
                        updateReportParams={updateReportParams}
                    />);

                    break;
                case constants.reports.filters.type.standardGeography:
                    // not coded in 1.0
                    break;
                case constants.reports.filters.type.geoSecurity:
                    // Not implemented : This was a one off report for CVS when they had completely custom geoSecurity
                    break;
                case constants.reports.filters.type.custom:

                    if (filterItem.FilterParams?.length == 1) {
                        var filterParam = filterItem.FilterParams[0];

                        if (filterParam.DataType == constants.reports.filters.dataType.listFromDB || filterParam.Type == constants.reports.filters.dataType.range) {
                            controls.push(<DropDown
                                key={filterItem.GUID}
                                className={'app-reports-management-reports-criteria-item'}
                                id={filterParam.GUID}
                                height='47px'
                                items={filterParam.ValueList}
                                display='Key'
                                valueProperty='Value'
                                selected={reportParams.length === 0 ? filterParam.DefaultValue : reportParams.find((p) => p.Key === filterItem.FilterParams[0].ReportParam)?.Value}
                                label={filterParam.Label}
                                onChange={(o) => {
                                    updateReportParams({ key: filterItem.FilterParams[0].ReportParam, newValue: o.value })
                                }}
                            />);

                        }
                        else if (filterParam.DataType == constants.reports.filters.dataType.listFromService) {
                            controls.push(<ListFromService key={filterItem.GUID} className={'app-reports-management-reports-criteria-item'} filter={filterItem} reportId={selectedReport.ID} updateReportParams={updateReportParams} />);
                        } else if (filterParam.DataType == constants.reports.filters.dataType.text) {   
                            controls.push(<TextBox 
                                label={filterParam.Label}
                                value={reportParams.find((p) => p.Key === filterItem.FilterParams[0].ReportParam)?.Value} 
                                height={'36px'}
                                onChange={(o) => { updateReportParams({ key: filterItem.FilterParams[0].ReportParam, newValue: o.value }); }} 
                            />);
                        }

                    } else if (filterItem.FilterParams != null && filterItem.FilterParams.length == 2) {
                        // FilterParams array more than 1 not coded in 1.0 
                    }

                    break;
            }

        });
        return controls;
    };

    return <>
        <div className='app-management-report-step-1-container'>

            <div className='app-reports-management-reports-criteria-container-panel-container'>
                {
                    getReportControls()
                }
            </div>
            <div className='app-management-report-rows'>
                {
                    [
                        { icon: icons.excel, format: 'Excel', text: translate('excel_document'), class: 'app-mobility-data-bar-excel' },
                        { icon: icons.pdf, format: 'PDF', text: translate('pdf_document'), class: 'app-mobility-data-bar-pdf' },
                    ].map((output, i) => {
                        var classes = ['app-mobility-data-bar-buttons', 'app-mobility-data-bar-short'];
                        classes.push(output.class);

                        return (
                            <div key={i} className='app-option-selector'>
                                {outputFormat === output.format ?
                                    <div className='app-option-selector-check'>
                                        <Icon icon={icons.check} />
                                    </div> : ''}
                                <Bar className={classes.join(' ')}
                                    icon={output.icon}
                                    text={output.text}
                                    active={outputFormat === output.format}
                                    onClick={() => { updateOutputFormat(output.format) }}
                                    disabled={!userPreferences.AllowSetReportOutput}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </>
}