import { useState, useEffect, useMemo } from 'react';

//app imports
import { GridDropDown } from '../../../base/dropDown/gridDropDown';
import { TreeDropDown } from '../../../base/dropDown/treeDropDown';
import { reports as reportsModule } from '../../../../modules/reports';
import { constants } from '../../../../utils/constants';


const _ = require("lodash");

export function ListFromService({ className, filter, reportId, updateReportParams }) {
 
    const displayType = useMemo(() => ({
        grid: 0,
        tree: 1
    }), []);

    const [listData, setListData] = useState([]);
    const [filterDisplayType, setFilterDisplayType] = useState(0);

    var classes = [];

    if (className)
        classes.push(className);

    useEffect(() => {
        const fetchData = async () => {
            var filterParam = filter.FilterParams[0];

            switch (filter.Type) {
                default:
                    break;
                case constants.reports.filters.type.user:
                    var userParameters = {
                        aFilterType: 'Users',
                        aRptID: reportId,
                        SelectedOrgFilters: '',
                        SelectedGeoSecFilters: '',
                        SelectedStandGeoFilters: ''
                    }
                    var userData = await reportsModule.getFilterListForReport(userParameters);
                    setListData(userData);
                    break;

                case constants.reports.filters.type.geoSecurity:
                    // Not implemented : One off report for CVS when they had completely custom geoSecurity
                    break;

                case constants.reports.filters.type.organizationHierarchy:
                    // Not implemented : One off report for Family Dollar that is no longer a client
                    // var userHierarchyData = await reportsModule.getReportFilterUserHierarchy();
                    // setListData(userHierarchyData.Users);
                    // setFilterDisplayType(displayType.tree);
                    break;

                case constants.reports.filters.type.custom:
                    var mapsParameters = {
                        aReportFilterParamGUID: filterParam.GUID,
                    }
                    var listData = await reportsModule.getFilterListForReportTemplateParam(mapsParameters);
                    setListData(listData);
                    break;
            };
        };

        fetchData();
    }, [filter, reportId, displayType]);


    return <div className={classes.join(' ')}>
        {
            filterDisplayType === displayType.grid ?
                <GridDropDown 
                    dropDownHeight={47}
                    items={listData}
                    columns={['Name', 'Description']}
                    keyProperty="ID"
                    valueProperty="Name"
                    placeholder={filter.FilterParams[0].Label}
                    onSelect={(o) => {
                        updateReportParams({ key: filter.FilterParams[0].ReportParam, newValue: o.value.join(',') })
                    }}
                />
                :
                <TreeDropDown
                    items={listData}
                    itemsExpr="Oversees"
                    keyProperty="Id"
                    valueProperty="Name"
                    dataStructure="tree"
                    parentProperty="Id"
                    onSelect={(o) => {
                        updateReportParams({ key: filter.FilterParams[0].ReportParam, newValue: o.value.join(',') })
                    }}                    
                />
        }
    </div>
}
