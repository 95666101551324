// App imports
import { constants } from '../../utils/constants';
import { zIndexes } from '../../utils/zIndex';

export class Layer {

    constructor(o) {
        this._layer = null;
        this.parent = o.parent;
        this._onChange = o.onChange;

        switch(o.type) {
            default:
                break;
            case constants.layers.other:
                break;
            case constants.layers.temporary:
                break;
            case constants.layers.selection:
                break;
            case constants.layers.cosmetic:
                break;
            case constants.layers.point:
                this._layer = new window.Microsoft.Maps.Layer();
                break;
            case constants.layers.thematic:
                var zIndex = zIndexes.rangeThematic;

                switch (o.subType)
                {
                    default:
                        break;
                    case constants.layers.thematics.subTypes.polygon:
                    case constants.layers.thematics.subTypes.dotDensity:
                        zIndex = zIndexes.otherThematic++;
                        break;
                }

                this._layer = new window.Microsoft.Maps.TileLayer({
                    mercator: new window.Microsoft.Maps.TileSource({
                        uriConstructor: function (tile) {
                            return o.tileUrl({quadkey: tile.quadKey});
                        }
                    }),
                    zIndex: zIndex,
                    downloadTimeout: 10000,
                    animationDisplay: "hide",
                    enableCors: true,
                    opacity: 1
                });

                break;
            case constants.layers.tradeArea:
                break;
            case constants.layers.modelTradeArea:
                break;
            case constants.layers.competitiveInsightsTradeArea:
                break;
            case constants.layers.pinMap:
                break;
            case constants.layers.heatMap:
                break;
            case constants.layers.stringMap:
                break;
            case constants.layers.geoFence:
                break;
            case constants.layers.geoFeeds:
                break;
            case constants.layers.trip2Trade:
                break;
            case constants.layers.directions:
                break;
        }

        o.map.layers.insert(this._layer);
    };

    getVisible(){
        return this._layer.getVisible();
    };

    setVisible(o){
        this._layer.setVisible(o.visible);
    };

    getZIndex(){
        return this._layer.getZIndex();
    };

    setZIndex(o){
        this._layer.setZIndex(o.zIndex);
    };

    clear(){
        this._layer.clear();
    };

    dispose(){
        this._layer.dispose();
        this.parent._layers = this.parent._layers.filter(layer => layer !== this);

        if (this._onChange)
            this._onChange();
    };

    entities = {
        get: () =>{
            return this._entities;
        },
        add: (o) =>{
            var entity = new window.Microsoft.Maps.Pushpin(
                new window.Microsoft.Maps.Location(o.location.lat, o.location.lon),
                {
                    icon: o.image
                }
            );

            entity.props = {};

            if (o.onClick)
                new window.Microsoft.Maps.Events.addHandler(entity, 'click', (e)=>{
                    o.onClick(e);
                });

            this._layer.add(entity);
            return entity;
        },
        remove: (o) =>{
            o.entity.setMap(null);
            this.e = this.e.filter(entity => {
                return entity !== o.entity;
            });
        }
    };
};