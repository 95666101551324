// React imports
import React, { Fragment, useEffect, useState } from 'react';

import _ from 'lodash';
import { translate } from '../../../../utils/translation';
import { icons } from '../../../base/icon/icon';
import { Button } from '../../../base/button/button';
import { map } from '../map';

export const MapModeBanner = ({ state }) => {

    return <div className='app-map-mode-banner'>
        <div className='app-map-mode-banner-content'>
            <div className='app-map-mode-banner-title'>
                {state?.title}
                <Button className='app-map-tooltip-close' theme='action' icon={icons.x} tooltip={translate('close')} onClick={() =>{
                    if (_.isFunction(state?.onClose))
                        state?.onClose();
                    map.hideBanner();
                }} />
            </div>
            <div className='app-map-mode-banner-body'>
                {state?.content}
            </div>
        </div>   
    </div>        
}