// React imports
import { useState, useEffect } from 'react';

// App imports
import { Wizard } from '../../../../base/wizard/wizard';
import { GeoFeedCreationWizardStep1 } from './geoFeedCreationWizardStep1';
import { GeoFeedCreationWizardStep2 } from './geoFeedCreationWizardStep2';
import { GeoFeedCreationWizardStep3 } from './geoFeedCreationWizardStep3';
import { translate } from "../../../../../utils/translation";
import { geoFeeds as geoFeedsModule } from '../../../../../modules/geoFeeds';
import { constants } from '../../../../../utils/constants';
import { helpers } from '../../../../../utils/helpers';

const _ = require('lodash');

export function GeoFeedCreationWizard({text,geoFeed,onClose}) {

    const [loaded, setLoaded] = useState(true);
    const [continueDisabled, setContinueDisabled] = useState(true);
    const [generating, setGenerating] = useState(false);
    const [editGeoFeed, setEditGeoFeed] = useState(geoFeedsModule.defaultGeoFeed());

    useEffect(() => {
        if (_.isObject(geoFeed))
        setEditGeoFeed(geoFeed);
    }, [geoFeed])    


    const previousStep = (o) => {
    };

    const nextStep = async (o) => {
        if (o.step == 3){
            setGenerating(true);
            
            if (editGeoFeed.id === helpers.emptyGuid())
                geoFeedsModule.createGeoFeed(editGeoFeed);
            else
                geoFeedsModule.updateGeoFeed(editGeoFeed);

            setGenerating(false);

            onClose();
        }
    };

    const updateGeoFeed = async (o) =>{
        setEditGeoFeed(o);
    };

    var steps = [
        {
            title: text, text: translate("geo_feeds_creation_wizard_step_1"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children:
                <GeoFeedCreationWizardStep1
                    geoFeed={editGeoFeed} 
                    updateGeoFeed={(value) =>{ updateGeoFeed(value); }}
                    onReadyNext={(o) => { setContinueDisabled(!o); }}
                />
        },
        {
            title: text, text: translate("geo_feeds_creation_wizard_step_2"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children:
                <GeoFeedCreationWizardStep2
                    geoFeed={editGeoFeed} 
                    updateGeoFeed={(value) =>{ updateGeoFeed(value); }}
                    onReadyNext={(o) => { setContinueDisabled(!o); }}
                />
        },
        {
            title: text, text: translate("geo_feeds_creation_wizard_step_3"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children:
                <GeoFeedCreationWizardStep3
                    geoFeed={editGeoFeed} 
                    updateGeoFeed={(value) =>{ updateGeoFeed(value); }}
                    onReadyNext={(o) => { setContinueDisabled(!o); }}
                />
        }
    ];

    return <>
        <Wizard steps={steps} onClose={() => { onClose(); }} onNextStep={(o) => { nextStep(o); }} onPreviousStep={(o) => { previousStep(o) }} />
    </>
}