// React imports
import { useEffect, useState } from 'react';

// App imports
import { Button } from '../../../base/button/button';
import { DropDown } from '../../../base/dropDown/dropDown';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { inputBoxTypes, TextBox } from '../../../base/textBox/textBox';
import { TextArea } from '../../../base/textArea/textArea';
import { trip2Trade as trip2TradeModule } from '../../../../modules/trip2Trade';

const _ = require('lodash');

export function Trip2TradeJob({entity,trip2TradeJob,onSave,onClose}){

    const [formData, setFormData] = useState(trip2TradeModule.getDefaultJob(entity));
    const [isEdit, setIsEdit] = useState(false);
    
    useEffect(() => {
        if (trip2TradeJob) {
            setFormData(trip2TradeJob);
            setIsEdit(true);
        }

    }, [trip2TradeJob])       

    function handleChange(e) {
        setFormData((formData) => ({ ...formData, [e.name]: e.value }));
    };

    var saveJob = () =>{ 
        onSave(formData);
    };       
    
	return <>
        <div className='app-trip-2-trade-job-header'>      
            <div className='app-trip-2-trade-job-actions'>
                <Button theme='secondary' size='small' className={'fa-flip-vertical'} icon={icons.arrowTurnDownLeft} tooltip={translate('go_back')} onClick={() =>{ onClose()  }}/>
                <Button theme='primary' size='small' icon={icons.check} disabled={formData.name.length <= 0} tooltip={translate('save')} onClick={saveJob}/>
            </div>  
        </div>  
        <div className='app-trip-2-trade-body'>
            <div>
                <TextBox className='app-trip-2-trade-name' height='47px' label={translate('name')} name='name' value={formData.name} valueChangeEvent='keyup' onChange={ handleChange }  />
                <TextArea className='app-trip-2-trade-description' minHeight='200px' maxHeight='200px' label={translate('description')} name='description' value={formData.description} valueChangeEvent='keyup' onChange={ handleChange } />                                             
            </div> 
            {isEdit ? '' : <div>               
                <TextBox type={inputBoxTypes.numeric} className='app-trip-2-trade-radius-number' height='47px' label={translate('radius')} name='radius' value={formData.radius} onChange={ handleChange }  />  

                <DropDown className='app-trip-2-trade-radius-radius-units' 
                            height='47px' 
                            selected={formData.radiusUnitType} 
                            items={trip2TradeModule.getRadiusUnits()}
                            label={translate('radius')}  
                            display='name' 
                            valueProperty='id' 
                            onChange={(o) => { setFormData((formData) => ({ ...formData, 'radiusUnitType': o.value })); }}/>                    

                <DropDown className='app-trip-2-trade-trip-type' 
                            height='47px' 
                            items={trip2TradeModule.getTripTypes()} 
                            selected={formData.tripType} 
                            label={translate('trip_type')} 
                            display='name' 
                            valueProperty='id'
                            onChange={(o) => { setFormData((formData) => ({ ...formData, 'tripType': o.value })); }}/>                                               
            </div>}  
        </div>
    </>
}