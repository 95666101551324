import { useEffect, useState } from 'react';


// Third party imports
import ColorBox, { ColorBoxTypes } from 'devextreme-react/color-box';

// App imports
import { DropDown } from '../../base/dropDown/dropDown';
import { TextBox } from '../../base/textBox/textBox';
import { Button } from '../../base/button/button';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { constants } from '../../../utils/constants';
import { Window } from '../../base/window/window';
import { UserSecurity } from './userSecurity/userSecurity';
import { UserPreferences } from './userPreferences';

const _ = require("lodash");

export function ChangePasswordPrompt({title, visible, setVisible })
{
    return <Window 
        title={title}
        visible={visible}
        setVisible={setVisible} 
        width={"595px"} 
        height={"575px"} 
        resizable={false}
        allowMinimize={false}
        startMinimized={false}
        modal={true}
        showCloseButton={false}
    >
        <div className='app-user-preferences-only-password' style={{ position: 'inherit', width: 'inherit', overflowY: 'scroll' }}>
            <div className='app-tabbed-content'>
                <div className='app-tabbed-content-cards'>
                    <UserSecurity onSuccessfulPasswordChanged={() => {
                        setVisible(false);
                    }} />
                </div>
            </div>
        </div>
    </Window>
}