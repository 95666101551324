// React imports
import { useState, useEffect, useCallback } from 'react';

// App imports
import { reports as reportsModule } from '../../../../modules/reports';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { translate } from "../../../../utils/translation";
import { helpers } from "../../../../utils/helpers";
import { constants } from '../../../../utils/constants';
import { LargePanel } from '../../../base/largePanel/largePanel';
import { DropDown } from '../../../base/dropDown/dropDown';
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { Bar } from '../../../base/bar/bar';
import { pointDetail as pointDetailModule } from '../../../../modules/pointDetail';
import { inputBoxTypes, TextBox } from '../../../base/textBox/textBox';
import { map } from '../../map/map';
import { NumberBox } from '../../../base/numberBox/numberBox';
import { successToast } from '../../../base/toast/toast';
import { navigation } from '../../navigation/navigation';

const _ = require('lodash');

export function PointDetail({reportCategory, onClose}) {

    const [loaded, setLoaded] = useState(false);
    const [voidSubTitle, setVoidSubTitle] = useState('');
    const [tradeAreasLibraries, setTradeAreasLibraries] = useState([]);
    const [tradeAreaLibrary, setTradeAreaLibrary] = useState([]);
    const [tradeArea, setTradeArea] = useState([]);
    const [outputTypes, setOutputTypes] = useState([]);
    const [outputType, setOutputType] = useState([]); 
    const [jobName, setJobName] = useState(`${translate('point_report_package')} ${helpers.getDate('M/D/YYYY h:mm a')}`); 
    const [generating, setGenerating] = useState(false);
    const [pointDetailData, setPointDetailData] = useState(null);
    const [pointDetailReportsPackage, setpointDetailReportsPackage] = useState([]);

    var getReportList = useCallback(
        async () =>{ 
            setLoaded(false);  

            var data = await reportsModule.getAvailablePointDetailReports();

            if (data) {
                data.geoLevels = data.geographyVintages.map(vintage => { 
                    return vintage.GeoLevels.map(geoLevel => { 
                        geoLevel.group = vintage.Name; 
                        geoLevel.compositeKey = `${vintage.Id}_${geoLevel.Id}`;
                        return geoLevel; 
                    });
                }).flat();

                data.customQueryGroupings.forEach(customQueryGrouping => {
                    customQueryGrouping.customQueries.forEach(customQuery => {
                        customQuery.reports.forEach(report => {                            
                            report.forceJob = report.isJob || report.type === 9;
                        });
                    });
                });

                await map.geocode({
                    query: `${map.center.lat} ${map.center.lon}`,
                    callback: (result) => {															
                        setVoidSubTitle(`${result.address.city} - ${result.address.state}`);
                    }
                });

                setPointDetailData(data);
                setpointDetailReportsPackage([pointDetailModule.getDefaultReport()]);
            }

            var libraries = pointDetailModule.getTradeAreaLibraries();    
            setTradeAreaLibrary(libraries[0]);
            setTradeAreasLibraries([...libraries]);   
            setTradeArea(libraries[0].tradeAreas[0])

            var outputTypesList= reportsModule.getOutputTypes();
            setOutputType(outputTypesList[1]);
            setOutputTypes([...outputTypesList]);

            setLoaded(true);
        },
        []
    );

    useEffect(()=>{           
        getReportList();
    },[getReportList]);  

    const close = () =>{
        onClose();
    };

    var submit = async  (o) => {
        setGenerating(true);
        
        var items = _.cloneDeep(pointDetailReportsPackage)

        items.forEach((item, i)=>{        
            item.id = helpers.newGuid();
            item.outputType = item.outputType === null ? outputType.id :  item.outputType.id;
            item.customQueryId = item.customQueryValue; //todo temp was '-'
            item.tradeArea = item.tradeArea ?? tradeArea;
        });        

        var result = await reportsModule.submitPointDetailReportPackage({ 'pointDetailReportPackage': { name: jobName, items: items} });
        
        result.forEach((r)=>{        
            helpers.navigateToUrl(legacyEndpoints.handlers.getReportUrl({
                id: r.id,
                type: r.handlerType,
                output: r.outputType === constants.reports.output.pdf ? "PDF" : "Excel"
            }));
        });  
        
        navigation.updateJobs();
        successToast(translate('success'));
        setGenerating(false);
    }; 
    
    const getCustomQueryComponent = (reportPackage) =>{

        if (pointDetailData === null) return;

        var customQueryGroupings = pointDetailModule.getCustomQueryGroupings(pointDetailData); 

        return <>
            <DropDown
                height='47px' 
                className='app-reports-point-detail-query'
                items={customQueryGroupings}
                grouped={true}
                selected={reportPackage.customQueryId ?? customQueryGroupings[0].key}
                label={translate('custom_query')} 
                display='name' 
                valueProperty='key'
                onSelect={(o) =>{
                    reportPackage.customQueryId = o.value.key;
                    reportPackage.customQueryValue = o.value.value;

                    const reports = getReportsForCustomQuery(reportPackage);
                    setReportFields(reportPackage,reports[0]);

                    if (!reportPackage.dirtyName)
                        reportPackage.outputName = getUniqueReportOuputName(reportPackage.id, reports[0].name);
                            
                    setpointDetailReportsPackage((pointDetailReportsPackage) => ([..._.cloneDeep(pointDetailReportsPackage)]));
                }}                        
            />  
        </>;
    };   

    const setReportFields = (item,o) =>{
        item.isGoldOrg = o.isGoldOrg;
        item.type = o.type;
        item.forceJob = o.forceJob;
        item.isJob = o.isJob;
        item.pointDetailReportId = o.id;
        item.generationType = o.generationType;
        item.reportName = o.name; 
        item.aggregateFields = [{ Name: '', FriendlyName: translate('none') }].concat(o.aggregateFields);
    };
    
    const getReportComponent = (item) =>{

        var data = getReportsForCustomQuery(item);

        return <>
            <DropDown
                height='47px' 
                className='app-reports-point-detail-report'
                items={data}
                selected={item.pointDetailReportId ?? data[0].id}
                label={translate('report')} 
                display='name' 
                valueProperty='id'
                onSelect={(o) =>{
                    setReportFields(item, o.value);

                    if (!item.dirtyName)
                        item.outputName = getUniqueReportOuputName(item.id, o.value.name);

                    setpointDetailReportsPackage((pointDetailReportsPackage) => ([..._.cloneDeep(pointDetailReportsPackage)]));
                }}                        
            />
        </>;
    };      

    const getReportsForCustomQuery = (reportPackage) =>{
        if (reportPackage.customQueryValue === null || reportPackage.customQueryValue === '') {
            return pointDetailData.customQueryGroupings[0].customQueries[0].reports;
        } else {
            const value = pointDetailData.customQueryGroupings.flatMap(x => x.customQueries).find(i => i.id === reportPackage.customQueryValue);
            return value.reports
        }
    };      

    const getJobComponent = (reportPackage) =>{
        return <Button className='app-reports-point-detail-job-select' theme='simple' 
            icon={reportPackage.isJob || reportPackage.forceJob ? icons.toggleOn : icons.toggleOff}
            disabled={reportPackage.forceJob} 
            onClick={(o) => { 
                reportPackage.isJob = !reportPackage.isJob;
                setpointDetailReportsPackage([..._.cloneDeep(pointDetailReportsPackage)]);
            }}
        />;
    };        
    
    const getUniqueReportOuputName = (id,report) =>{
        var i = 1;
        var proposedName = `${report} - ${tradeArea.name}`;

        var reportRow = pointDetailReportsPackage.find(x => x.id === id && x.outputName === proposedName);
        
        if (_.isObject(reportRow)) return proposedName;

        var testName = proposedName;
        var takenNames = pointDetailReportsPackage.map(x => { return x.outputName })

        while (takenNames.includes(testName))
            testName = `${proposedName} (${i++})`

        return testName;
    }   

    const reportRow = (item) =>{        
        return <>
            <div>
                {getCustomQueryComponent(item)}
                {getReportComponent(item)}
                <TextBox height='47px' className='app-reports-point-detail-report-name' label={translate('name')} value={item.outputName} 
                        onChange={(o) => {
                            if (o.userChanged)
                                item.dirtyName = true;

                            item.outputName = o.value;
                            setpointDetailReportsPackage((pointDetailReportsPackage) => ([..._.cloneDeep(pointDetailReportsPackage)]));
                        }}               
                />     
                <div className='app-reports-point-detail-job'>
                    <div className='app-reports-point-detail-job-label'>{translate('job')}</div>
                    {getJobComponent(item)}
                </div>
                <div className='app-reports-point-detail-row-delete'>
                    <Button className='app-reports-point-actions-button' theme='simple' icon={icons.trash} tooltip={translate('delete')} onClick={()=>{ setpointDetailReportsPackage(pointDetailReportsPackage.filter(x => x !== item)); }} />
                </div>
            </div>
            <table>
                <tbody>
                    <tr>
                        <td valign='top'>
                            <Bar className='app-reports-point-detail-row-advanced' icon={icons.gear} text={translate('extra_settings')}>                
                                {advancedOptionsComponent(item)}
                            </Bar>
                        </td>
                        {(
                            item.type !== 9 ? '' : <td valign='top'>
                                <Bar className='app-reports-point-detail-row-void' icon={icons.gear} text={translate('void_settings')}>                
                                    {voidOptionsComponenet(item)}
                                </Bar>
                            </td>
                        )}                        
                    </tr>
                </tbody>
            </table>
        </>
    }

    const voidOptionsComponenet = (item) =>{

        var subTitle = item.extendedParameters.find(x => x.Key === 'Subtitle').Value;
        if (subTitle.toString().length === 0)
        {
            subTitle = voidSubTitle;
            item.extendedParameters.find(x => x.Key === 'Subtitle').Value = subTitle;
        }
        
        var firstAggregateField = item.extendedParameters.find(x => x.Key === 'AggregateField1').Value;
        if (firstAggregateField.toString().length === 0 && item.aggregateFields.length > 0)
            firstAggregateField = item.aggregateFields[0].Name;

        var firstAggregateType = item.extendedParameters.find(x => x.Key === 'AggregateFieldType1').Value;
        if (firstAggregateType.toString().length === 0 && pointDetailData.aggregates.length > 0)
            firstAggregateType = pointDetailData.aggregates[0].key;

        var secondAggregateField = item.extendedParameters.find(x => x.Key === 'AggregateField2').Value;
        if (secondAggregateField.toString().length === 0 && item.aggregateFields.length > 0)
            secondAggregateField = item.aggregateFields[0].Name;

        var secondAggregateType = item.extendedParameters.find(x => x.Key === 'AggregateFieldType2').Value;
        if (secondAggregateType.toString().length === 0 && pointDetailData.aggregates.length > 0)
            secondAggregateType = pointDetailData.aggregates[0].key;
        
        var baseGeography = item.extendedParameters.find(x => x.Key === 'BaseGeog').Value;
        if (baseGeography.toString().length === 0 && pointDetailData.geoLevels.length > 0)
            baseGeography = pointDetailData.geoLevels[0].GeographyVintageId;

        var geoLevel = item.extendedParameters.find(x => x.Key === 'GeogLevel').Value;
        if (geoLevel.toString().length === 0 && pointDetailData.geoLevels.length > 0)
            geoLevel = pointDetailData.geoLevels[0].Id;
        
        var minRadius = item.extendedParameters.find(x => x.Key === 'MinRadius').Value;
        if (minRadius.toString().length === 0)
        {
            minRadius = 1;
            item.extendedParameters.find(x => x.Key === 'MinRadius').Value = minRadius;
        }

        var lengthMeasurement = item.extendedParameters.find(x => x.Key === 'LengthMeasurement').Value;
        if (lengthMeasurement.toString().length === 0)
            lengthMeasurement = 4;

        return <table>
            <tbody>
                <tr>
                    <td colSpan='2'>
                        <TextBox height='47px' className='app-reports-point-detail-void-name' label={translate('subtitle')} value={subTitle}/>   
                    </td>
                </tr>
                <tr>
                    <td>
                        <DropDown 
                            className='app-reports-point-detail-void-aggregate-field'
                            height='47px'
                            selected={firstAggregateField}
                            items={item.aggregateFields}
                            display='FriendlyName' 
                            valueProperty='Name'
                            label={translate('first_aggregate')}
                            onSelect={(o) =>{
                                item.extendedParameters.find(x => x.Key === 'AggregateField1').Value = o.value.Name;
                                setpointDetailReportsPackage((pointDetailReportsPackage) => ([..._.cloneDeep(pointDetailReportsPackage)]));  
                            }}
                        />                    
                    </td>
                    <td>
                        <DropDown 
                            className='app-reports-point-detail-void-aggregate'
                            label={translate('type')}
                            height='47px'
                            selected={firstAggregateType}
                            items={pointDetailData.aggregates}
                            display='abbreviation'
                            valueProperty='key'
                            onSelect={(o) =>{
                                item.extendedParameters.find(x => x.Key === 'AggregateFieldType1').Value = o.value.key;
                                setpointDetailReportsPackage((pointDetailReportsPackage) => ([..._.cloneDeep(pointDetailReportsPackage)]));  
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <DropDown 
                            className='app-reports-point-detail-void-aggregate-field'
                            height='47px'
                            selected={secondAggregateField}
                            items={item.aggregateFields}
                            display='FriendlyName'
                            valueProperty='Name'
                            label={translate('second_aggregate')}
                            onSelect={(o) =>{
                                item.extendedParameters.find(x => x.Key === 'AggregateField2').Value = o.value.Name;
                                setpointDetailReportsPackage((pointDetailReportsPackage) => ([..._.cloneDeep(pointDetailReportsPackage)]));  
                            }}
                        />
                    </td>
                    <td>
                        <DropDown 
                            className='app-reports-point-detail-void-aggregate'
                            label={translate('type')}
                            height='47px'
                            selected={secondAggregateType}
                            items={pointDetailData.aggregates}
                            display='abbreviation'
                            valueProperty='key'
                            onSelect={(o) =>{
                                item.extendedParameters.find(x => x.Key === 'AggregateFieldType2').Value = o.value.key;
                                setpointDetailReportsPackage((pointDetailReportsPackage) => ([..._.cloneDeep(pointDetailReportsPackage)]));  
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan='2'>
                        <DropDown 
                            className='app-reports-point-detail-void-vintage'
                            grouped={true}
                            selected={`${baseGeography}_${geoLevel}`}
                            height='47px'
                            items={pointDetailData.geoLevels}
                            display='Name'
                            valueProperty='compositeKey'
                            label={translate('geography')}
                            onSelect={(o) =>{
                                item.extendedParameters.find(x => x.Key === 'BaseGeog').Value = o.value.GeographyVintageId;
                                item.extendedParameters.find(x => x.Key === 'GeogLevel').Value = o.value.Id;
                                setpointDetailReportsPackage((pointDetailReportsPackage) => ([..._.cloneDeep(pointDetailReportsPackage)]));  
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <td>                    
                        <NumberBox
                            className='app-reports-point-detail-void-limit' 
                            label={translate('min_radius')}
                            value={minRadius}
                            height='47px'
                            maxValue={100}
                            minValue={.0000001}
                            onChange={(o) => {

                                if (!o.userChanged)
                                    return;

                                item.extendedParameters.find(x => x.Key === 'MinRadius').Value = o.value;
                                setpointDetailReportsPackage((pointDetailReportsPackage) => ([..._.cloneDeep(pointDetailReportsPackage)]));  
                            }}
                        />
                    </td>
                    <td>
                        <DropDown 
                            className='app-reports-point-detail-void-measurement'
                            label={translate('measure')}
                            selected={lengthMeasurement}
                            height='47px'
                            items={pointDetailData.lengthMeasurements}
                            display='abbreviation'
                            valueProperty='key'
                            onSelect={(o) =>{
                                item.extendedParameters.find(x => x.Key === 'LengthMeasurement').Value = o.value.key;
                                setpointDetailReportsPackage((pointDetailReportsPackage) => ([..._.cloneDeep(pointDetailReportsPackage)]));  
                            }}
                        />
                    </td>
                </tr>
            </tbody>            
        </table>
    };
  
    const advancedOptionsComponent = (item) => {
        var libs = _.cloneDeep(tradeAreasLibraries)
        return <>
            <DropDown className='app-reports-point-detail-trade-area'
                    height='47px' 
                    items={libs}
                    selected={item.tradeAreaLibrary?.id}
                    label={translate('trade_area')} 
                    display='name' 
                    valueProperty='id'
                    onSelect={(o) =>{
                        item.tradeAreaLibrary = o.value;
                        setpointDetailReportsPackage((pointDetailReportsPackage) => ([..._.cloneDeep(pointDetailReportsPackage)]));  
                    }}
            />  
            <DropDown className='app-reports-point-detail-trade-area'
                    height='47px' 
                    items={item.tradeAreaLibrary?.tradeAreas}
                    selected={item.tradeArea?.id}
                    label={translate('trade_area')} 
                    display='name'
                    valueProperty='id'
                    onSelect={(o) =>{
                        item.tradeArea = o.value;
                        setpointDetailReportsPackage((pointDetailReportsPackage) => ([..._.cloneDeep(pointDetailReportsPackage)]));  
                    }}                        
            />    
            <DropDown className='app-reports-point-detail-output'
                height='47px' 
                items={outputTypes}
                selected={item.outputType?.id}
                label={translate('output_format')} 
                display='name' 
                valueProperty='id'
                onSelect={(o) => {
                    item.outputType = o.value;                  
                    setpointDetailReportsPackage([..._.cloneDeep(pointDetailReportsPackage)]);    
                }}                       
            />                
        </>
     };        

    return  <LargePanel text={translate('point_detail')} loaded={loaded} generating={generating} onClose={() =>{ onClose(); }}>
        <div className='app-reports-point-detail-trade-area-header'>
            <DropDown className='app-reports-point-detail-trade-area'
                    height='47px' 
                    items={tradeAreasLibraries}
                    selected={tradeAreaLibrary}
                    label={translate('default_trade_area')} 
                    display='name' 
                    onChange={(o) =>{
                        setTradeAreaLibrary(o.value);
                        setTradeArea(o.value.tradeAreas[0]);
                    }}                        
            />  
            <DropDown className='app-reports-point-detail-trade-area'
                    height='47px' 
                    items={tradeAreaLibrary.tradeAreas}
                    selected={tradeArea}
                    label=''//todo combine both trade area dropdowns within one container 
                    display='name' 
                    onChange={(o) =>{
                        setTradeArea(o.value);
                    }}                        
            />    
            <DropDown className='app-reports-point-detail-output'
                height='47px' 
                items={outputTypes}
                selected={outputType}
                label={translate('default_output_format')} 
                display='name' 
                onSelect={(o) => {
                    setOutputType(o.value);
                }}                       
            />     
            <TextBox className='app-reports-point-detail-name' height='47px' label={translate('print_report_job_name')} value={jobName} valueChangeEvent='keyup' 
                    onChange={(o) => {
                        setJobName(o.value);
                    }}               
            /> 
            <Button className='app-reports-point-detail-actions' theme='secondary' size='small' icon={icons.x} tooltip={translate('close')} onClick={() =>{ close() }}/>
            <Button className='app-reports-point-detail-actions-primary' theme='primary' size='small' icon={icons.check} tooltip={translate('submit')} disabled={pointDetailReportsPackage.length === 0} onClick={() =>{ submit() }}/>                             
        </div>   
        <div>
            <div className='app-reports-point-add'>
                <Button theme='secondary' size='small' icon={icons.plus} tooltip={translate('add')} disabled={pointDetailReportsPackage.length===10} onClick={() =>{
                    var newPointDetailReportsPackage = _.cloneDeep(pointDetailModule.getDefaultReport());
                    pointDetailReportsPackage.push(newPointDetailReportsPackage);
                    setpointDetailReportsPackage([...pointDetailReportsPackage]);
                }} />
            </div>
        </div>
        <div className='app-reports-point-detail-body'>
        {
            pointDetailReportsPackage.length === 0 ? <div className='app-reports-point-detail-empty'>{translate('add_report')}</div> :
            pointDetailReportsPackage.map((item) => {                                                               
                return <div key={item.id} className={'app-reports-point-detail-row'}>{reportRow(item)}</div>
            })
        }
        </div>
    </LargePanel>
}