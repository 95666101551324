//App Imports
import { legacyEndpoints } from '../services/legacyEndpoints';

export const upload = {         
    updateQueueFromTempDirectory: async function(o) {
		var result = legacyEndpoints.service({
			name: 'UpdateQueueFromTempDirectory', 
            parameters: {
                uploadQueueId: o.uploadQueueId,
                fileId: o.fileId,
                tempFileId: o.tempFileId,
                contentType: o.contentType
            }});              
        return result;
    },      
    publish: async function(o) {
		var result = legacyEndpoints.service({
			name: 'PublishQueue', 
            parameters: {
                uploadQueueId: o.uploadQueueId,
                data: o.data,
                dataSourceId: null,
                pointId: null,
                tempPointId: null                           
            }});    

        return result;
    },
    validateFromFile: async function(o) {
        var result = legacyEndpoints.service({
			name: 'ValidateFromFile', 
            parameters: {
                guid: o.taskGUID,
                type: "2",
                fileId: o.fileId,
                tableName: "TAS",
                fileName: o.fileName
            }
        });    
        
        return result;
    },   
    deleteFile: async function(o) {
        var result = legacyEndpoints.service({
			name: 'DeleteFile', 
            parameters: {
                Id: o.fileId
            }
        });    
        
        return result;
    },   
    addFileToQueueFromTempDirectory: async function(o) {
        var result = legacyEndpoints.service({
            name: 'AddFileToQueueFromTempDirectory',
            parameters: {
                uploadQueueId: o.uploadQueueId,
                fileId: o.fileId,
                fileName: o.fileName,
                contentType: o.contentType
            }
        });

        return result
    },
    deleteFileFromQueue: async function(o) {
        var result = legacyEndpoints.service({
			name: 'DeleteFileFromQueue', 
            parameters: {
                queueFileId: o.queueFileId,
                tempFileId: o.tempFileId,
                uploadQueueId: o.uploadQueueId                          
            }
        });    
        
        return result;
    },   
    clearUploadQueue: async function(o) {
		var result = legacyEndpoints.service({
			name: 'ClearUploadQueue', 
            parameters: {
                uploadQueueId: o.uploadQueueId                          
            }
        });    

        return result;
    },  
    uploadFileChunk: async function(file, o) {
        var result = await legacyEndpoints.uploadFileChunk(
            file,
            {
            parameters: {
              name: 'UploadFileChunk',
              uploadQueueId: o.uploadQueueId,
              fileId: o.fileId, 
              'Content-Type': "text/plain"
            }});  
        return result;
    }
};