
export function SimpleTable({ headers, data }) {
    return <table className='app-simple-table'>
        {
            headers ? <thead>
                <tr>{
                    headers.map((header,i) =>{
                        return <th key={i}>{header}</th>
                    })
                }
                </tr>
            </thead> : ''
        }
        <tbody>
        {
            data.map((row, i)=>{
                return <tr key={i}>
                {
                    row.map((col, i) =>{
                        return <td key={i}>{col}</td>
                    })
                }
                </tr>
            })
        }
        </tbody>
    </table>
}