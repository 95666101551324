import { MenuButton } from '../menuButton/menuButton';
import { translate } from '../../../utils/translation';
import { icons } from '../../base/icon/icon';

export function ConfirmButton({className, tooltip, disabled, size, theme, align, icon, text, clearText, cancelText, onConfirm, onCancel, headerText = translate('confirm')}){
    return <MenuButton {...{className, tooltip, disabled, size, theme, align, icon, text }} showDropDown={false} items={[
        { header: true, text: headerText },
        { icon: icons.check, text: clearText ? clearText : translate('clear'), onClick: () => {
            if (onConfirm)
                onConfirm(); 
        }},
        { icon: icons.x, text:  cancelText ? cancelText : translate('cancel'), onClick: () => {
            if (onCancel)
                onCancel(); 
        }}
    ]} />
} 