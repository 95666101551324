// React imports
import { useState, useEffect } from 'react';

// App imports
import { Card } from "../../../base/card/card";
import { TextBox } from "../../../base/textBox/textBox"
import { Button } from '../../../base/button/button';
import { translate } from "../../../../utils/translation"
import { icons } from '../../../base/icon/icon';

const _ = require("lodash");

export function TMXSavePreset({ active, presetId, presets, saveDisabled, onClose, onSaveAs, onSave }) {

    const [presetName, setPresetName] = useState('');
    const [originalName, setOriginalName] = useState('');
    const [saveAsDisabled, setSaveAsDisabled] = useState(true);

    useEffect(() =>{

        if (saveDisabled)
            return;

        const selPreset = _.find(presets, function (preset) {
            return preset.id == presetId;
        });

        if (_.isObject(selPreset)) {
            setPresetName(selPreset.name);
            setOriginalName(selPreset.name);

            if (selPreset.name.length > 0)
                setSaveAsDisabled(false);
        }

    }, [presetId, presets, saveDisabled]);

    return <Card open={active}>
        <div className='app-tmx-save-container'>
            <div className='app-tmx-save-row'>
                <div className='app-tmx-labels'>{translate('name')}</div>
                <TextBox className={'app-tmx-save-stretch'} value={presetName} onChange={(o) => { 
                    setPresetName(o.value.trim()); 
                    setSaveAsDisabled(o.value.trim().length === 0);
                }} />
            </div>
            <div className='app-tmx-save-actions-rows'>
                <Button className='app-tmx-save-button' theme='secondary' tooltip={translate('cancel')} size='small' icon={icons.x} onClick={()=>{ onClose(); }} />	                    
                <Button className='app-tmx-save-button' theme='secondary' tooltip={translate('save_as')} size='small' icon={icons.saveAs} 
                    disabled={saveAsDisabled || originalName.toLowerCase() === presetName.toLowerCase()} 
                    onClick={()=>{ onSaveAs({ name: presetName }); }} 
                />	
                <Button className='app-tmx-save-button' theme='primary' tooltip={translate('save')} size='small' icon={icons.save} disabled={saveDisabled || saveAsDisabled} 
                    onClick={()=>{ onSave({ name: presetName }); }} 
                />	
            </div>
        </div>
    </Card>	
}