import React from "react";

export function RadioButtonSet({children, className, vertical}){

    var classes = ['app-radio-button-set'];

    if (vertical)
        classes.push('app-radio-button-set-vertical');
    else
        classes.push('app-radio-button-set-horizontal');

    if (className)
        classes.push(className);

    return <div className={classes.join(' ')}>        
    {
        React.Children.map(children.filter(x => x !== ''), child => <span className='app-radio-button'>{child}</span>)
    }
    </div>
} 