// Third party imports
import {DateRangeBox as DxDateRangeBox} from 'devextreme-react/date-range-box';

import React, { useCallback, useRef } from 'react';

export function DateRangeBox({ className, startDate, endDate, min, max, height, onChange}) {
    
    const dateRangeBoxRef = useRef(null);

    var classes = ['app-date-box'];

    if (className)
        classes.push(className);

    const onCurrentValueChange = useCallback(
        ({ value: [startDate, endDate] }) => {
            if (startDate !== null && endDate !== null) 
                onChange({startDate: startDate, endDate: endDate});       
        },
        [onChange],
      );    

    return <div className={classes.join(' ')}>
        <DxDateRangeBox
            ref={dateRangeBoxRef}
            startDate={startDate}
            endDate={endDate}
            onValueChanged={onCurrentValueChange}
            height={height? height : '30px'}
          />
    </div>
}