import { constants } from "../../utils/constants";

const _ = require("lodash");

export class GoogleKmlLayer {

    constructor(o) {

        o.map._private._dataLayers.push(this);

        this._map = o.map;
        this._googleMap = o.googleMap;
        this._overlay = null;
        this._visible = o.visible;
        this._zIndex = o.zIndex;
        this._entities = [];
        this._clickEntities = [];
        this._mouseoutEntities = [];
        this._mouseoverEntities = [];
        this._imageUrl = o.imageUrl;
        this._data = o.data

        if (this._data.type === constants.geoFeed.geoJson) {
            this._overlay = new window.google.maps.Data();
            this._overlay.loadGeoJson(
                this._imageUrl()           
              );             
        } else {            
            this._overlay = new window.google.maps.KmlLayer({
                url: this._imageUrl(),
                preserveViewport: this._data.preserveViewport
              });          
        }          

        this.setVisible(o.visible);

        this.refresh = async () => {};
         
        this._map._private._dataLayers = _.sortBy(this._map._private._dataLayers, '_zIndex');

        o.layer.addListener({
            type: constants.listeners.click,
            action: (e) =>{
                var entity = this._clickEntities.find(x => x.entity.id === e.entity.id);

                if (_.isObject(entity))
                    entity.event(e);
            }
        });

        o.layer.addListener({
            type: constants.listeners.mouseIn,
            action: (e) =>{

                var dataEntity = this._entities.find(x => x.getProperty('_googleEntity').id === e.entity.id);

                this._overlay.overrideStyle(
                    dataEntity, 
                    {
                        fillColor: `rgb(${constants.polygons.hover.fillColor.r},${constants.polygons.hover.fillColor.g},${constants.polygons.hover.fillColor.b})`,
                        fillOpacity: constants.polygons.hover.fillColor.a,
                        strokeColor: `rgb(${constants.polygons.hover.strokeColor.r},${constants.polygons.hover.strokeColor.g},${constants.polygons.hover.strokeColor.b})`,
                        strokeOpacity: constants.polygons.hover.strokeColor.a
                    }
                );

                o.layer.addListener({
                    type: constants.listeners.mouseOut,
                    executeOnce: true,
                    action: (e) =>{
                        this._overlay.revertStyle(dataEntity);
                    }
                });
             }
        });
    }

    dispose() {

        if (this._overlay === null)
            return;

        this._overlay.setMap(null);
        this._overlay = null;

        this._map._private._dataLayers = this._map._private._dataLayers.filter(overlay => overlay != this);
    }

    addEntity(entity){
        
    }

    removeEntity(entity){
        var dataEntity = this._entities.find(x => x.getId() === entity.id);

        this._overlay.remove(dataEntity);
        this._entities = this._entities.filter(x => x != dataEntity);
    }

    setEntityVisibility(entity, visible) {
        var dataEntity = this._entities.find(x => x.getId() === entity.id);

        if (visible)
            this._overlay.add(dataEntity);
        else
            this._overlay.remove(dataEntity);
    }

    setEntityFill(entity, color) {  
        this.setEntityOptions(entity, { fillColor: color});            
    }

    setEntityStroke(entity, color) {
        this.setEntityOptions(entity, { strokeColor: color });
    }

    addListenerToEntity(entity, type, event){
        
        this.setEntityOptions(entity, { clickable: true });

        switch(type)
        {
            case "click":
                this._clickEntities.push({ entity: entity, event: event });
                break;
            case "mouseout":
                this._mouseoutEntities.push({ entity: entity, event: event });
                break;     
            case "mouseover":
                this._mouseoverEntities.push({ entity: entity, event: event });
                break;                   
        }        
    }

    setVisible(value){
        this._visible = value;   
        if (value)
            this._overlay.setMap(this._googleMap);        
        else
            this._overlay.setMap(null);   
    }

    setZIndex(value){

        if (this._zIndex === value)
            return;

        this._zIndex = value;

        this._map._private._dataLayers = _.sortBy(this._map._private._dataLayers, '_zIndex');        
        this._map._private._dataLayers.forEach(layer => layer.refresh());
    }
}