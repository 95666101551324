// React imports
import { useState, useEffect, useCallback } from 'react';

// App imports
import { Wizard } from '../../../base/wizard/wizard';
import { BulkDemographcsStep1 } from './bulkDemographicsStep1'
import { BulkDemographcsStep2 } from './bulkDemographicsStep2'
import { BulkDemographcsStep3 } from './bulkDemographicsStep3'
import { translate } from "../../../../utils/translation";
import { helpers } from "../../../../utils/helpers";
import { constants } from '../../../../utils/constants';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { upload as uploadModule } from '../../../../modules/upload';
import { reports as reportsModule } from '../../../../modules/reports';

export function BulkDemographics({text, onClose}) {

    const [loaded, setLoaded] = useState(true);
    const [generating, setGenerating] = useState(false);
    const [excelFile, setExcelFile] = useState(null);
    const [demoReport, setDemoReport] = useState(null);
    const [demoReports, setDemoReports] = useState([]);
    const [jobName, setJobName] = useState(`${translate('bulk_demographics')} ${helpers.formatDate({ date: new Date() })}`);
    const [outputFormat, setOutputFormat] = useState(constants.bulkReports.excelOutputFormat.single);
    const [tradeAreas, setTradeAreas] = useState([{ id: helpers.newGuid(), key: constants.tradeAreas.types.ring, type: translate('ring'), distance: '1', fillColor: '', lineColor: '' }]);
    const [continueDisabled, setContinueDisabled] = useState(false);
    const [goToStep, setGoToStep] = useState(-1);

    var getReportList = useCallback(
        async () =>{
            setLoaded(false);      

            var data = await reportsModule.getReportListForCategory({ reportCategory: constants.reportCategories.demographicSummary });

            var items = [];

            data.forEach((item) => {
                item.DemographicSource = translate("reports");

                var parent = items.find((val) => val.text.toLowerCase() === item.DemographicSource.toLowerCase());
                var child = {
                    id: item.ID,
                    text: item.Name,
                    type: item.Type
                };

                if (parent)
                    parent.items.push(child);
                else
                    items.push({
                        text: item.DemographicSource,
                        items: [child]
                    });
            });

            setDemoReports([...items]);
            setLoaded(true);
        },
        []
    );

    useEffect(() =>{
        getReportList();
    }, [getReportList]);

    var steps = [
        { title: text, text: translate("bulk_demographics_step_1"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children: 
            <BulkDemographcsStep1 excelFile={excelFile} updateExcelFile={(value) =>{ setExcelFile(value); }}
                demoReports={demoReports} updateDemoReports={(value) =>{ setDemoReports(value); }} 
                demoReport={demoReport} updateDemoReport={(value) =>{ setDemoReport(value); }}
                onReadyNext={(o) => { setContinueDisabled(!o); }} 
            />
        },
        { title: text, text: translate("bulk_demographics_step_2"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children: 
            <BulkDemographcsStep2 jobName={jobName} updateJobName={(value) =>{ setJobName(value); }}
                outputFormat={outputFormat} updateOutputFormat={(value) =>{ setOutputFormat(value); }}
                tradeAreas={tradeAreas} updateTradeAreas={(value) =>{ setTradeAreas([...value]); }}
                onReadyNext={(o) => { setContinueDisabled(!o); }} 
            />
        },
        { title: text, text: translate("bulk_demographics_step_3"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children: 
            <BulkDemographcsStep3 excelFile={excelFile} demoReports={demoReports} demoReport={demoReport} jobName={jobName} outputFormat={outputFormat} 
                tradeAreas={tradeAreas} onEditStep={(o) => { setGoToStep(o.step); }} 
            />
        }
    ]

    const generateReport = async () =>{

        setGenerating(true);

        const myTradeAreas = tradeAreas.map((tradeArea) => { 
            return { Distance: tradeArea.distance, FillColor: tradeArea.fillColor, LineColor: tradeArea.lineColor, Type: tradeArea.type }; 
        });

        var requestId = await legacyEndpoints.service({
            name: 'AddDemographicBulkProcessingJob',
            parameters: {
                Name: jobName.trim(),
                FileId: excelFile.resultId,
                ReportId: demoReport,
                TradeAreaList: myTradeAreas,
                OutputFormat: outputFormat
            }
        });

        //console.log(requestId);

        setGenerating(false);

        onClose();

        // TBD: open the job managment component
    }

    const cleanupFiles = () =>{
        
        if (excelFile != null && excelFile.resultId.length > 0) {

            uploadModule.deleteFile({
                fileId: excelFile.resultId
            });

            setExcelFile(null);
        }
    }

    const cancel = () =>{
        cleanupFiles();
        onClose();
    };

    const nextStep = (o) =>{
        if (o.step == 3)
            generateReport();
    };

    return  <>
        <Wizard steps={steps} goToStep={goToStep} completeText={translate('generate')} onClose={() =>{ cancel(); }} onNextStep={(o) =>{ nextStep(o); }}/>
    </>
}