// Third party imports
import moment from 'moment';

// React imports
import { useState, useRef, useEffect } from 'react';

// App imports
import { DataGrid } from '../../base/dataGrid/dataGrid'
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { translate } from '../../../utils/translation';
import { constants } from '../../../utils/constants';
import { helpers } from '../../../utils/helpers';
import { tasks as tasksModule } from '../../../modules/tasks';
import { Button } from '../../base/button/button';
import { icons } from '../../base/icon/icon';
import { RadioButtonSet } from '../../base/radioButtonSet/radioButtonSet';

const _ = require('lodash');

export function Tasks() {
    const [dueFilter, setDueFilter] = useState({id: null});
    const [assignmentFilter, setAssignmentFilter] = useState({id: null});
    const grid = useRef(null);

    const dueFilters = {
        openTasks: { id:1, filters: [{ expression: constants.expressions.and,  field: 'status', operator: constants.operators.notEquals, value: constants.tasks.statuses.completed, filterBuilders: [] }]},
        dueToday: { id:2, filters: [
            { expression: constants.expressions.and, field: 'dueDate', operator: constants.operators.greaterThanEquals, value: moment().format('YYYY-MM-DD'), filterBuilders: [] },
            { expression: constants.expressions.and, field: 'dueDate', operator: constants.operators.lessThan, value: moment().add('1', 'days').format('YYYY-MM-DD'), filterBuilders: [] }
        ]},
        dueThisWeek: { id: 3, filters: [
            { expression: constants.expressions.and, field: 'dueDate', operator: constants.operators.greaterThanEquals, value: moment().startOf('week').format('YYYY-MM-DD'), filterBuilders: [] },
            { expression: constants.expressions.and, field: 'dueDate', operator: constants.operators.lessThan, value: moment().endOf('week'), filterBuilders: [] }
        ]},
        overdue: { id: 4, filters: [{ expression: constants.expressions.and, field: 'dueDate', operator: constants.operators.lessThan, value: moment().format('YYYY-MM-DD'), filterBuilders: [] }]},
        completed: { id: 5, filters: [{ expression: constants.expressions.and, field: 'status', operator: constants.operators.equals, value: constants.tasks.statuses.completed, filterBuilders: [] }]}
    }

    const assignmentFilters = {
        allTasks: { id: 1, filters: []},
        createdByMe: { id: 2, filters: [{ expression: constants.expressions.and, field: 'createdBy', operator: constants.operators.equals, value: legacyEndpoints.authenticationToken.TokenInformation.UserGUID, filterBuilders: [] }]},
        assignedToMe: { id: 3, filters: [{ expression: constants.expressions.and, field: 'assignedTo', operator: constants.operators.equals, value: legacyEndpoints.authenticationToken.TokenInformation.UserGUID, filterBuilders: [] }]},
        sharedToMe: { id: 4, filters: [{ expression: constants.expressions.and, field: 'sharedTo', operator: constants.operators.equals, value: legacyEndpoints.authenticationToken.TokenInformation.UserGUID, filterBuilders: [] }] }
    }

    useEffect(() => {
        setDueFilter(dueFilters.openTasks);
        setAssignmentFilter(assignmentFilters.allTasks);
    }, []);

    const createHeaderFilter = (options)=>{
        options.dataSource.useDefaultSearch = false;
        options.dataSource.load = async (o) => {
            var filters = helpers.getFilterBuilder({ filter: options.dataSource.filter });

            var result = await tasksModule.getColumnFilter({
                field: options.fieldName,
                skip: _.isNumber(o.skip) ? o.skip : 0,
                take: _.isNumber(o.take) ? o.take : 20,
                searchOperation: helpers.getFilterOperator(o.searchOperation),
                searchValue: o.searchValue,
                filters: filters
            });

            if (options.fieldName === 'dueDate') {
                result.data = _.map(result.data, (o) => {
                    return { ...o, text: moment(o.text).format('MM/DD/YYYY') };
                });
            }
           
            return {
                data: result.data,
                totalCount: result.total
            };
        }
    }

    const renderStatus = (status)=>{
        switch(status){
            default:
                return status;
            case constants.tasks.statuses.completed:
                return translate('completed');
            case constants.tasks.statuses.deferred:
                return translate('deferred');
            case constants.tasks.statuses.inProgress:
                return translate('in_progress');
            case constants.tasks.statuses.notStarted:
                return translate('not_started');
            case constants.tasks.statuses.waiting:
                return translate('waiting');
        }
    };

    const renderType = (type)=>{
        switch(type){
            default:
                return type;
            case constants.tasks.types.system:
                return translate('system');
            case constants.tasks.types.user:
                return translate('user');
        }
    };

    const renderPriority = (priority)=>{
        switch(priority){
            default:
                return priority;
            case constants.tasks.priorities.low:
                return translate('low');
            case constants.tasks.priorities.medium:
                return translate('normal');
            case constants.tasks.priorities.high:
                return translate('high');
            case constants.tasks.priorities.critical:
                return translate('critical');
        }
    };

    const renderUser = ({userGuid, userName}) =>{ return <div><img className='app-tasks-user-image' src={legacyEndpoints.handlers.getPhotoUrl({ userId: userGuid, isUserPhoto: true, width: 150, height: 150 })} alt={translate('user_image')} /> {userName}</div>};

    const renderActions = (o) =>{
        var actions = [];

        // History
        const canEdit = true;
        if (canEdit)
            actions.push(<Button theme='simple' icon={icons.edit} 
                tooltip={translate('edit')} 
                onClick={() => { 
                    console.log('Edit');
                }} 
            />);
        else
            actions.push(<div style={{width: '20px'}}></div>);

        // History
        const canHistory = true;
        if (canHistory)
            actions.push(<Button theme='simple' icon={icons.clock} 
                tooltip={translate('history')} 
                onClick={() => { 
                    console.log('History');
                }} 
            />);
        else
            actions.push(<div style={{width: '20px'}}></div>);

        // Share
        const canShare = true;
        if (canShare)
            actions.push(<Button theme='simple' icon={icons.share} 
                tooltip={translate('share')} 
                onClick={() => { 
                    console.log('Share');
                }} 
            />);
        else
            actions.push(<div style={{width: '20px'}}></div>);

        // Delete
        const canDelete= true;
        if (canDelete)
            actions.push(<Button theme='simple' icon={icons.trash} 
                tooltip={translate('delete')} 
                onClick={() => { 
                    console.log('Delete');
                }} 
            />);
        else
            actions.push(<div style={{width: '20px'}}></div>);

        return <div className='app-task-list-action-container'>
            {
                actions.map((action ,i) => { 
                    return <div key={i} className='app-task-list-action'>
                        {action}
                    </div>
                })
            }
        </div>
        
    };

    return <div className='app-tasks'>
        <div className='app-task-list-section'>
            <div className='app-task-list-header'>
                <div className='app-task-list-filters'>
                    <RadioButtonSet>
                        <Button theme='map' text={translate('open_tasks')} active={dueFilter.id == dueFilters.openTasks.id} onClick={()=>{setDueFilter(dueFilters.openTasks)}}></Button>
                        <Button theme='map' text={translate('due_today')} active={dueFilter.id == dueFilters.dueToday.id} onClick={()=>{setDueFilter(dueFilters.dueToday)}}></Button>
                        <Button theme='map' text={translate('due_this_week')} active={dueFilter.id == dueFilters.dueThisWeek.id} onClick={()=>{setDueFilter(dueFilters.dueThisWeek)}}></Button>
                        <Button theme='map' text={translate('overdue')} active={dueFilter.id == dueFilters.overdue.id} onClick={()=>{setDueFilter(dueFilters.overdue)}}></Button>
                        <Button theme='map' text={translate('completed')} active={dueFilter.id == dueFilters.completed.id} onClick={()=>{setDueFilter(dueFilters.completed)}}></Button>
                    </RadioButtonSet>

                    <RadioButtonSet>
                        <Button theme='map' text={translate('all_tasks')} active={assignmentFilter.id == assignmentFilters.allTasks.id} onClick={()=>{setAssignmentFilter(assignmentFilters.allTasks)}}></Button>
                        <Button theme='map' text={translate('created_by_me')} active={assignmentFilter.id == assignmentFilters.createdByMe.id} onClick={()=>{setAssignmentFilter(assignmentFilters.createdByMe)}}></Button>
                        <Button theme='map' text={translate('assigned_to_me')} active={assignmentFilter.id == assignmentFilters.assignedToMe.id} onClick={()=>{setAssignmentFilter(assignmentFilters.assignedToMe)}}></Button>
                        <Button theme='map' text={translate('shared_to_me')} active={assignmentFilter.id == assignmentFilters.sharedToMe.id} onClick={()=>{setAssignmentFilter(assignmentFilters.sharedToMe)}}></Button>
                    </RadioButtonSet>
                </div>
                <div className='app-task-list-button-container'>
                    <Button theme='primary' size='small' icon={icons.plus} tooltip={translate('add')} onClick={()=>{}}></Button>
                    <Button theme='primary' size='small' icon={icons.refresh} tooltip={translate('refresh')} onClick={()=>{}}></Button>
                    <Button theme='primary' size='small' icon={icons.download} tooltip={translate('export')} onClick={()=>{}}></Button>
                </div>
            </div>
            <DataGrid 
                ref={grid}
                className='app-task-list'
                defaultPageSize={10}
                remoteOperations={ {groupPaging: true, remoteOperations: true} }
                showHeaderFilter={true}
                showFilterRow={true}
                activeRefreshOnly={true}
                key='Id'
                columns={[
                    { name: 'status', caption: translate('status'), onRender: (o) => { return renderStatus(o.status); }, headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'status' }); }},
                    { name: 'title', caption: translate('title'), headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'title' }); } },
                    { name: 'type', caption: translate('type'), onRender: (o) => { return renderType(o.type); }, headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'type' }); } },
                    { name: 'priorityname', caption: translate('priority'), onRender: (o) => { return renderPriority(o.priority); }, headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'priorityname' }); } },
                    { name: 'sourceInformation', caption: translate('associated_with'), allowFiltering: false, allowSorting: false, onRender: (o) => { return o.sourceInformation?.label; } },
                    { name: 'dueDate', dataType: 'date', caption: translate('due_date'), onRender: (o) => { return helpers.formatDate({ date: o.dueDate }); }, allowHeaderFiltering: false },
                    { name: 'assignedtoname', caption: translate('assigned_to'), onRender: (o) => { return renderUser({ userGuid: o.assignedTo.GUID, userName: o.assignedTo?.Name }) }, headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'assignedTo' }); }},
                    { name: 'createdbyname', caption: translate('created_by'), onRender: (o) => { return renderUser({ userGuid: o.createdBy.GUID, userName: o.createdBy?.Name }) }, headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'createdBy' }); }},
                    { name: 'actions', alignment:'center', caption: translate('actions'), allowFiltering: false, allowSorting: false, onRender: (o) => { return renderActions(o); }}
                ]}
                onLoad={async (o)=>{
                    var filters = helpers.getFilterBuilder({ filter: o.filter });
                    filters = filters.concat({expression: 1, filters: dueFilter.filters});
                    
                    if (assignmentFilter.id !== assignmentFilters.allTasks.id)
                        filters = filters.concat({expression: 1, filters: assignmentFilter.filters});

                    var sorts = [];
                    if (_.isArray(o.sort))
                        o.sort.forEach(sort => { 
                            sorts.push({ fieldId: o.sort[0].selector, sortOrder: sort.desc ? 1 : 0 });
                        });

                    const data = await tasksModule.getFilteredTasks({ skip: o.skip, take: o.take, filters: filters, sorts: sorts });

                    return {
                        data: data.results,
                        totalCount: data.total
                    };
                }}
            />
        </div>
        <div className='app-task-support-section'>
            <div className='app-task-detail-section'>TODO: Task Detail Section</div>
            <div className='app-task-action-section'>TODO: Action Section</div>
        </div>
        
    </div>
}