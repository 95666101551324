// React imports
import { useState, useEffect } from 'react';

// App imports
import { Button } from '../button/button';

export function ButtonGroup({buttons,theme,onClick,activeOnClick=false}) {

	const [clickedId, setClickedId] = useState(-1);

	useEffect(() =>{
        const defaultButtonIndex = buttons.findIndex(b => b.isDefault === true);

		if (defaultButtonIndex > -1)
			setClickedId(defaultButtonIndex);
    }, [buttons]);

	return (
		<>
			{buttons.map((button, i) => (
					<Button key={i} 
							active={i === clickedId} 
							activeOnClick={activeOnClick} 
							tooltip={button.name} 
							className={'app-legend-layer-toolbar-action ' + (i === clickedId ? 'app-clickable-active' : 'app-clickable')}
							theme={theme}
							icon={button.icon} 
							onClick={(e)=>{
								setClickedId(i);

								if (onClick)
									onClick(button);
					}} />

	
			))}		
		</>
	);
}