// Third party imports
import { PieChart as DevExPieChart,Size,Legend,Animation,Title,CommonSeriesSettings,SmallValuesGrouping } from 'devextreme-react/pie-chart';

// App imports
import { translate } from '../../../utils/translation';

export function PieChart({ data, width, series, exportOptions, title, subtitle, legendVisible=true, resolveLabelOverlapping='none', chartRef, onFileSaving, onDrawn }) {

    const defaultSeries = {
        argumentField: 'field',
        valueField: 'value',
        label: {
            visible: true,
            position: 'inside',
            backgroundColor: 'transparent',
            connector: {
                visible: false,
                width: 1
            },
            font: {
                size: 16
            }
        }
    };

    return <DevExPieChart
            dataSource={data}
            palette={['#902153', '#2B328C', '#5AB88D', '#C1D42F', '#F59C28', '#EC5D20', '#00A5D3']}
            paletteExtensionMode='extrapolate'
            series={ series ? series : defaultSeries }
            resolveLabelOverlapping={resolveLabelOverlapping}
            export={exportOptions}
            ref={chartRef}
            onFileSaving={onFileSaving}
            onDrawn={onDrawn}
        >
            <Animation easing="linear"duration={150}/>
            <CommonSeriesSettings>
                <SmallValuesGrouping mode={'topN'} topCount={10} groupName={translate('other')} />
            </CommonSeriesSettings>
            <Size width={width} />
            <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="right"
                rowCount={2}
                visible={legendVisible}
            />
            { title ? <Title text={title} subtitle={subtitle} /> : '' }
    </DevExPieChart>
}