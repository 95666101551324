import { helpers } from "../../utils/helpers";

const _ = require("lodash");

export class GoogleTileOverlay {

    constructor(o) {
        this._googleMap = o.googleMap;

        this._overlay = new window.google.maps.ImageMapType({
            getTileUrl: (loc, z) => o.tileUrl({ 
                quadkey: helpers.xyzToQuadKey({ x: loc.x, y: loc.y, z: z}),
                layer: o.layer
            }),
            tileSize: new window.google.maps.Size(256, 256)
        });  
        this._overlay.__id = helpers.newGuid();      
        
        this.setVisible(o.visible);

        this._overlay._zIndex = _.isInteger(o.zIndex) ? o.zIndex : 0;
        this._overlay._visible = _.isBoolean(o.visible) ? o.visible : true;
    }

    dispose() {
        var array = _.sortBy(this._googleMap.overlayMapTypes.getArray().filter(layer => layer.__id !== this._overlay.__id), '_zIndex');
        this._googleMap.overlayMapTypes.clear();
        array.forEach(item => this._googleMap.overlayMapTypes.push(item));
    }

    refresh(){
        
        var array = _.cloneDeep(this._googleMap.overlayMapTypes.getArray());
        this._googleMap.overlayMapTypes.clear();
        array.forEach(item => this._googleMap.overlayMapTypes.push(item));
    }

    setVisible(value){

        if (this._overlay._visible === value)
            return;

        this._overlay._visible = value;

        var array = value ? _.sortBy(this._googleMap.overlayMapTypes.getArray().concat([this._overlay]), '_zIndex') :
            _.sortBy(this._googleMap.overlayMapTypes.getArray().filter(layer => layer.__id !== this._overlay.__id), '_zIndex');
        this._googleMap.overlayMapTypes.clear();
        array.forEach(item => this._googleMap.overlayMapTypes.push(item));
    }

    setZIndex(value){

        if (this._overlay._zIndex === value)
            return;

        this._overlay._zIndex = value;

        var array = _.sortBy(this._googleMap.overlayMapTypes.getArray(), '_zIndex');
        this._googleMap.overlayMapTypes.clear();
        array.forEach(item => this._googleMap.overlayMapTypes.push(item));
    }
};