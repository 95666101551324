// React imports
import { useState } from 'react'

//App imports
import { Button } from "../button/button";
import { MenuButton } from "../menuButton/menuButton";
import { AutoCloser } from "../autoCloser/autoCloser";

export function SplitButton({className, tooltip, align, theme, size, icon, items, active, disabled, onClick}) {
    
    const [visible, setVisible] = useState(false);
    
    var classes = ['app-button-split-container'];

    if (align === 'right')
        classes.push('app-button-menu-right');

    if (className)
        classes.push(className);

    var buttonClasses = [];

    if (visible)
        buttonClasses.push('app-button-menu-open');

	return <AutoCloser className={classes.join(' ')} open={visible} onClose={() => { setVisible(false); }}>
        <Button {...{disabled, active, theme, size, icon, tooltip, onClick}} className={'app-button-split-left'} onClick={onClick} />
        <MenuButton {...{disabled, active, theme, size, tooltip, items}} className={'app-button-split-right'} showIcon={false} showDropDown={true} onClick={() => { setVisible(!visible); }} />        
    </AutoCloser>         
}