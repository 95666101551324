// React imports
import { useEffect, useState } from 'react';

// App imports
import { translate } from '../../../../utils/translation';
import { Wizard } from '../../../base/wizard/wizard'
import { CreateRendererStep1 } from './createRendererStep1';
import { CreateRendererStep2 } from './createRendererStep2';
import { CreateRendererStep3 } from './createRendererStep3';
import { CreateRendererStep4 } from './createRendererStep4';

export function RendererWizard({trip2TradeJob,renderer,onClose,onUpdateRenderer,onSave}){

    const wizardTitle = translate("create_trip_2_trade_renderer");

    const [loaded, setLoaded] = useState(true);
    const [continueDisabled, setContinueDisabled] = useState(true);
    const [goToStep, setGoToStep] = useState(-1)
    
    const nextStep = (o) =>{
        if(o.step == 4)        
            saveRenderer(o);
    };    

    const saveRenderer = (o) =>{
        onSave();
    };    

    var steps = [ 
        
        { title: wizardTitle, text: translate("create_renderer_step_1"), loaded: loaded, continueDisabled: continueDisabled, children: 
            <CreateRendererStep1 trip2TradeJob={trip2TradeJob} renderer={renderer} updateRenderer={(value) =>{ onUpdateRenderer(value); } } onReadyNext={(o) => { setContinueDisabled(!o); }} />
        },
        { title: wizardTitle, text: translate("create_renderer_step_2"), loaded: loaded, continueDisabled: continueDisabled, children: 
            <CreateRendererStep2 renderer={renderer} updateRenderer={(value) =>{ onUpdateRenderer(value); } } onReadyNext={(o) => { setContinueDisabled(!o); }} />   
        },    
        { title: wizardTitle, text: translate("create_renderer_step_3"), loaded: loaded, continueDisabled: continueDisabled, children: 
            <CreateRendererStep3 renderer={renderer} updateRenderer={(value) =>{ onUpdateRenderer(value); } } onReadyNext={(o) => { setContinueDisabled(!o); }} />
        },   
        { title: wizardTitle, text: translate("create_renderer_step_4"), loaded: loaded, continueDisabled: continueDisabled, children: 
            <CreateRendererStep4 renderer={renderer} updateRenderer={(value) =>{ onUpdateRenderer(value); } } onReadyNext={(o) => { setContinueDisabled(!o); }} />
        },                      

	];

    return  <div className='app-trip-2-trade'>
        <Wizard steps={steps} goToStep={goToStep} completeText={translate('generate')} onClose={() =>{ onClose(); }} onNextStep={(o) =>{ nextStep(o); }}/>
    </div>
}