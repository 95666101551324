// React imports
import { useState } from 'react';

// App imports
import { CollapsibleSection } from '../../../base/collapsibleSection/collapsibleSection';
import { UserPreferencesSection } from '../userPreferencesSection';
import { constants } from '../../../../utils/constants'
import { translate } from "../../../../utils/translation";
import { userPreferences } from '../../app';

export function UserMap() {

    const [activeSection, setActiveSection] = useState(0);    

    var mapTools = [
        { key: translate('measure_distance'), value: constants.mapTools.measureDistance },
        { key: translate('measure_area'), value: constants.mapTools.measureArea },
        { key: translate('get_coordinates'), value: constants.mapTools.getCoordinates },
        { key: translate('reverse_geocode'), value: constants.mapTools.reverseGeocode },
        { key: translate('add_text'), value: constants.mapTools.addText },
        { key: translate('add_pushpin'), value: constants.mapTools.addPushpin },
        { key: translate('line_tool'), value: constants.mapTools.lineTool },
        { key: translate('draw_polygon'), value: constants.mapTools.drawPolygon },
        { key: translate('draw_circle'), value: constants.mapTools.drawCircle },
        { key: translate('draw_rectangle'), value: constants.mapTools.drawRectangle },
        { key: translate('auto_label'), value: constants.mapTools.autoLabel }
    ]

    var zoomOffsets = [
        { key: 4, value: 4 },
        { key: 5, value: 5 },
        { key: 6, value: 6 },
        { key: 7, value: 7 },
        { key: 8, value: 8 }
    ]

    var items = [
		{ text: translate('default_pushpin_symbol'), children: <UserPreferencesSection
            key={0}
            inputType={'symbolpicker'}
            preferenceName={'DefaultPushpinSymbol'} 
            defaultValue={userPreferences.DefaultPushpinSymbol} 
            allowSave={userPreferences.AllowSetDefaultPushpinSymbol} /> 
        },
		{ text: translate('show_pushpin_info_driving'), children: <UserPreferencesSection
            key={1}
            inputType={'checkbox'}
            preferenceName={'ShowPushpinInfo'} 
            defaultValue={userPreferences.ShowPushpinInfo}
            allowSave={userPreferences.AllowSetShowPushpinInfo}
            label={translate('show_edit_infobox')}
            description={translate('infobox_pushpin')} /> 
        },
		{ text: translate('default_geocode_symbol'), children: <UserPreferencesSection
            key={2}
            inputType={'symbolpicker'}
            preferenceName={'DefaultGeocodeSymbol'} 
            defaultValue={userPreferences.DefaultGeocodeSymbol} 
            allowSave={userPreferences.AllowSetDefaultGeocodeSymbol} /> 
        },
		{ text: translate('default_map_tool'), children: <UserPreferencesSection
            key={3}
            inputType={'dropdown'}
            preferenceName={'DefaultMapTool'} 
            defaultValue={userPreferences.DefaultMapTool}
            listOfValues={mapTools}
            listDisplay={'key'}
            listValue={'value'}
            allowSave={userPreferences.AllowSetDefaultMapTool}
            label={translate('default_map_tool')}
            description={translate('default_map_tool_settings')} /> 
        },
		{ text: translate('gps_refresh'), children: <UserPreferencesSection
            key={4}
            inputType={'textbox'}
            preferenceName={'GPSRefresh'} 
            defaultValue={userPreferences.GPSRefresh.toString()}
            allowSave={userPreferences.AllowSetGPSRefresh}
            label={translate('interval')}
            description={translate('gps_interval')} /> 
        },
		{ text: translate('mini_map'), children: <UserPreferencesSection
            key={5}
            inputType={'dropdown'}
            preferenceName={'MiniMapZoomOffset'} 
            defaultValue={userPreferences.MiniMapZoomOffset}
            listOfValues={zoomOffsets}
            listDisplay={'key'}
            listValue={'value'}
            allowSave={userPreferences.AllowSetMiniMapZoomOffset}
            label={translate('mini_map_zoom_offset')}
            description={translate('mini_map_settings')} /> 
        }
	];

    return <div key={0} className='app-user-preferences-sections'>
        {
            items.map((item, i) => {
                return <div key={i} className='app-user-preferences-section'>
                    <CollapsibleSection text={item.text} expanded={activeSection === i} onClick={() => { setActiveSection(i); }} >
                        {item.children}
                    </CollapsibleSection>
                </div>
            })
        }
    </div>
}