// React imports
import { Layer } from './layer';
import { Drawing } from './drawing';
import { constants } from '../utils/constants';
import { ActionListener } from './actionListener';

const _ = require("lodash");

export class Map extends ActionListener {

    constructor(o) {
        super(o);
        
        o = _.isObject(o) ? o : {};

        this._private = {
            _addressSearch: null,
            _bounds: { },
            _center: _.isObject(o.center) ? o.center : {},
            _position: {},
            _drawing: new Drawing(this),            
            _geocoder: null,
            _heading: _.isInteger(o.heading) ? o.heading : 0,
            _id: _.isNull(o.id) || _.isUndefined(o.id) ? crypto.randomUUID() : o.id,
            _isDrawing: false,
            _layers: [],
            _imageOverlays: [],
            _heatMaps: [],
            _dataLayers: [],
            _map: {},
            _pois: {},
            _rotatable: false,
            _temporaryLayer: null,
            _tilt: _.isInteger(o.tilt) ? o.tilt : 0,
            _tiltable: false,
            _type: '',
            _zoom: _.isInteger(o.zoom) ? o.zoom : 0,
            _maxZoom: 20,
            _minZoom: 2,
            _trafficLayer: { layer: null, visible: false },
            _bikeLayer: { layer: null, visible: false },
            _transitLayer: { layer: null, visible: false },
            _streetViewLayer: { layer: null, visible: false },
            _streetViewPanorama: null,
            _showAerialLabels: _.isBoolean(o.showAerialLabels) ? o.showAerialLabels : true,
            _errors: {
                _readOnly: (o) =>{
                    throw new Error('Map "' + o.oproperty + '" property is read only and cannot be set to "' + o.value + '".');
                }
            }           
        };

        this._private._temporaryLayer = this.addLayer({
			group: constants.layers.groups.temporary, 
			type: constants.layers.types.temporary
		});

        // Custom layers refresh
        this.addListener({
            type: constants.listeners.viewChange,
            action: () => {
                this._private._heatMaps.forEach(heatMap => heatMap.refresh());
                this._private._imageOverlays.forEach(overlay => overlay.refresh());
                this._private._dataLayers.forEach(dataLayer => dataLayer.refresh());
            }
        });

        return this;
    };

    get bounds() { return this._private._bounds; }
    set bounds(value) { this._private._bounds = value; }

    get pois() { return this._private._pois; }
    set pois(value) { this._private._pois = value; }
    
    get center() { return this._private._center; }
    set center(value) { this._private._center = value; }
    
    get position() { return this._private._position; }
    set position(value) { this._private._position = value; }

    get heading() { return this._private._heading; }
    set heading(value) { this._private._heading = value; }

    get id() { return this._private._id; }
    //set id(value) { this._private._errors._readOnly({ property: 'id', value: value }); }

    get isDrawing() { return this._private._isDrawing; }
    set isDrawing(value) { this._private._errors._readOnly({ property: 'id', value: value }); }

    get layers() { return this._private._layers; }
    //set layers(value) { this._private._errors._readOnly({ property: 'layers', value: value }); }    

    get rotatable() { return this._private._rotatable; }
    set rotatable(value) { this._private._rotatable = value; }

    get tilt() { return this._private._tilt; }
    set tilt(value) { this._private._tilt = value; }
    
    get tiltable() { return this._private._tiltable; }
    set tiltable(value) { this._private._tiltable = value; }

    get trafficVisible(){ return this._private._trafficLayer.visible; }
    set trafficVisible(value){this._private._trafficLayer.visible = !value;}

    get transitVisible(){ return this._private._transitLayer.visible; }
    set transitVisible(value){this._private._transitLayer.visible = !value;}

    get type() { return this._private._type; }
    set type(value) { this._private._type = value; }

    get zoom() { return Math.round(this._private._zoom); }
    set zoom(value) {

        if (!this.validZoom(value))
            return;

        this._private._zoom = value;
    }

    get showAerialLabels() { return this._private._showAerialLabels; }
    set showAerialLabels(value) { this._private._showAerialLabels = value; }

    enableZooming(){

    }

    disableZooming(){
        
    }

    enablePanning(){

    }

    disablePanning(){
        
    }

    isReady(){
        return _.isObject(this.bounds);
    }

    disableEntityClicking() {
        this._private._layers.forEach(layer =>{
            layer._private._entities.forEach(entity => {
                entity._private._tempClickable = entity.clickable;
                entity.clickable = false;                
            });
        });
    }

    enableEntityClicking() {
        this._private._layers.forEach(layer =>{
            layer._private._entities.forEach(entity => {
                entity.clickable = entity._private._tempClickable;
                delete entity._private._tempClickable;
            });
        });
    }

    validZoom(value){
        return value < 3 || value > 20 ? false : true;
    }

    xyToLatLon(o){
        
    };

    latLonToXY(o){
        
    };

    streetView(){
        
    }

    getTypes(){
        
    };

    addressSearch(o){

    };

    geocode(o){
        this._private._geocoder.geocode({ query: o.query}, (o) =>{
            console.log(o);
        })
    };
    
    addLayer(o) {
        
        var layer = _.isObject(o.layer) ? o.layer : new Layer(this, o);
        
        this._executeListeners({ type: constants.listeners.layerAdd, properties: { layer: layer } });
        
        return layer;        
    };

    clear(){

        this._private._layers.forEach(layer =>{

            if (layer.type === constants.layers.types.poi ||layer.type === constants.layers.types.cosmetic || layer.type === constants.layers.types.selection || layer.type === constants.layers.types.temporary || layer.type === constants.layers.types.activeEntity)
                return;

            layer.removeAllListeners();
            layer.dispose();
        });

        this._private._layers.find(layer => layer.type === constants.layers.types.cosmetic).clear();  
        this._private._layers.find(layer => layer.type === constants.layers.types.selection).clear();
        this._private._layers.find(layer => layer.type === constants.layers.types.temporary).clear();
        this._private._layers.find(layer => layer.type === constants.layers.types.activeEntity).clear();
    };

    // TODO: Remove this and have driving directions use the generic
    clearByType(type){
        this._private._layers.filter(x => x.type === type).forEach(x => x.dispose());
    };

    async getGPSLocation(o){

        var getLocation = () => {
            return new Promise((success, error) => {
                navigator.geolocation.getCurrentPosition(
                    success, 
                    error,
                    { 
                        timeout: 5000,
                        enableHighAccuracy: false 
                    }
                );
            });
        };

        var position = await getLocation();  // wait for getPosition to complete
        return position;
    }

    setCursor(){

    }

    startDrawing(o){
        this._private._isDrawing = true;
        
        var clonedObject = _.cloneDeep(o);        
        if (!_.isFunction(o.onFinish))
            o.onFinish = () =>{}

        o.onFinish = (results) => {            
            clonedObject.onFinish(results);
            this._private._isDrawing = false;  
        };

        this._private._drawing._private._draw(o);
    }

    stopDrawing(o){
        this._private._isDrawing = false;
        this._private._drawing._private._dispose(o);
    }

    mergeShapes({ entities }){

        // logic to merge shapes

        return entities;
    }

    reset(){
        this.clear();
    }
};