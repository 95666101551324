// React imports
import { useState } from 'react';

// App imports
import { Panel } from '../../../base/panel/panel';
import { SaveMap } from '../../saveMap/saveMap';

const _ = require('lodash');

export function SaveProjectMap({text,open,onClose}) {

    const [_open, setOpen] = useState(open);

    const [loaded, setLoaded] = useState(true);
    const [generating, setGenerating] = useState(false);

    const close = () =>{
        setOpen(false);
        onClose();
    };

    return <Panel text={text} open={_open} onClose={() => { close(); }}>
            <SaveMap onClose={()=>{close()}}  />
        </Panel>


			

}