// App imports
import { Icon, icons } from '../icon/icon';
import { Clickable } from '../clickable/clickable';
import { Bar } from '../bar/bar';

const _ = require("lodash");

export function OptionSelector({ text, icon, active, onClick, onDoubleClick, theme, disabled, content}) {
    return theme === 'small' ? 
    <div className='app-option-selector-small'>
        {active ? 
        <div className='app-option-selector-check'>
            <Icon icon={icons.check} />
        </div> : ''}
        <Bar 
            icon={icon}
            text={text} 
            active={active} 
            onClick={() => { onClick(); }} 
            disabled={disabled} 
        /> 
    </div>
    : 
    <div className='app-option-selector'>
        {active ? 
            <div className='app-option-selector-check'>
                <Icon icon={icons.check} />
            </div> : ''
        }
        <Clickable className={icon ? 'app-option-selector-icon' : ''} active={active} 
            onClick={() => { 
                if (_.isFunction(onClick)) 
                    onClick(); }
                }             
            onDoubleClick={() => { 
                if (_.isFunction(onDoubleClick)) 
                    onDoubleClick(); }
                } 
        >
            { icon ? <Icon icon={icon} /> : '' }
            {content} 
        </Clickable>
        <div className='app-option-selector-text'>
            {text}
        </div>
    </div>
}