// React imports
import { useEffect, useState } from 'react';

// App imports
import { DropDown } from '../../base/dropDown/dropDown';
import { translate } from '../../../utils/translation';
import { TextArea } from '../../base/textArea/textArea';
import { competitiveInsightsReportObject as competitiveInsightsReportObjectModule } from '../../../modules/competitiveInsightsReportObject';
import { Description } from '../../base/description/description';
import { Button } from '../../base/button/button';
import { icons } from '../../base/icon/icon';
import { constants } from '../../../utils/constants';
import { successToast } from '../../base/toast/toast';
import { Loader } from '../../base/loader/loader';
const _ = require('lodash');

export function CompetitiveInsightsReportObject({type,entity,onClose}){

    const [reason, setReason] = useState(null)
    const [description, setDescription] = useState(null)
    const [generating, setGenerating] = useState(false);
    
    var save = async () =>{
        var result;
        setGenerating(true);
        if (type === constants.competitiveInsights.reportedObjectTypes.geoFence) {
            var geoFenceData = 
            {
                    pointId: entity.metaData.pointTitle.id,
                    latitude: entity.location.lat,
                    longitude: entity.location.lon,
                    geoFenceId: entity.id,
                    reason: reason,
                    description: description
            }
            result = await competitiveInsightsReportObjectModule.reportCompetitiveInsightGeoFence(geoFenceData);
        } else {
            var pointData = 
            {
                    pointId: entity.id,
                    latitude: entity.location.lat,
                    longitude: entity.location.lon,
                    reason: reason,
                    description: description
            }
            result = await competitiveInsightsReportObjectModule.reportCompetitiveInsightPoint(pointData);
        }
        setGenerating(false);
        if (result) {
            successToast(translate('success')); 
            onClose();
        }
    };
    
    var cancel = async () =>{
        onClose();
    };

	return <>
        { generating ? <Loader theme='generate' showLogo={true}/> : ''}
        <div className='app-competitive-insights-report-object'>
            <div>
                <Description className='app-competitive-insights-report-object-instructions' description={type === constants.competitiveInsights.reportedObjectTypes.point ? translate('report_incorrect_point_criteria') : translate('report_incorrect_geofence_criteria')} />     
                <div className='app-competitive-insights-report-object-actions'>
                    <Button className='app-competitive-insights-report-object-button' theme='secondary' size='small' icon={icons.x} tooltip={translate('cancel')} onClick={() =>{ cancel() }}/>
                    <Button className='app-competitive-insights-report-object-button' theme='primary' size='small' icon={icons.save} tooltip={translate('save')} onClick={() =>{ save() }}/>                            
                </div>  
            </div>  
            <DropDown className='app-competitive-insights-report-object-reason' 
                            height='47px' 
                            selected={reason} 
                            items={competitiveInsightsReportObjectModule.getReasons(type)}
                            label={translate('reason')}  
                            display='name' 
                            valueProperty='id' 
                            onChange={(o) => { 
                                setReason(o.value);                       
                            }}/>  
            <TextArea className='app-competitive-insights-report-object-description' minHeight='200px' maxHeight='165px' label={translate('description')} 
                value={description} 
                onChange={(e) => { 
                    setDescription(e.value);
                } } 
            />                                  
        </div>
    </> 
}