// React imports
import { useEffect, useState } from 'react';

// Third party imports
import ScheduleSelector from 'react-schedule-selector';
import moment from 'moment'; //todo move functions to helper

const _ = require('lodash');

export function CreateRendererStep4({renderer,updateRenderer,onReadyNext}){

    const [sched, setSchedule] = useState([]);

    useEffect(() => {
        translateSchedule();
    }, []) 

    useEffect(() => {
        if (onReadyNext)
            onReadyNext(true); //todo validation

    }, [onReadyNext])    

    const handleChange = (newSchedule) => {
        setSchedule(newSchedule);
    
        var schedule = newSchedule.map(s => ({day: moment(s).day(), hour: moment(s).hour()}));
        renderer.filter.dayParts = schedule;        

        updateRenderer({...renderer, filter: {...renderer.filter, dayParts: schedule } });  
    }      

    const translateSchedule = () => {
        var longDateFormat = renderer.filter.dayParts?.map(o => moment("2024-01-21", "YYYY-MM-DDTHH:mm:ss.SSSSZ").add(o.hour, 'hours').add(o.day, 'days').toString());
        setSchedule(longDateFormat);
    }        

	return <> 
        <div className='app-trip-2-trade-renderer-day-parts-body'>
            <div className='app-trip-2-trade-renderer-day-parts-scheduler'>   
                <ScheduleSelector
                        selection={sched}
                        numDays={7}
                        minTime={0}
                        maxTime={24}
                        hourlyChunks={1}
                        dateFormat='ddd'
                        startDate={'1/21/2024'}
                        onChange={handleChange}
                    />                                                                                                                    
            </div>                 
        </div>
    </>
}