// React imports
import { useState, useEffect, Fragment } from 'react';

// App imports
import { Description } from '../../../base/description/description';
import { DataList } from '../../../base/dataList/dataList';
import { translate } from '../../../../utils/translation';

const _ = require("lodash");

export function FilterDetailsLD({ isLocked, distribution, variable, updateVariable }) {

    const [description, setDescription] = useState('');
    const [origValueList, setOrigValueList] = useState([]);

    useEffect(() => {
        setOrigValueList(_.cloneDeep(variable.ValueList));
    }, [variable]);

    useEffect(() => {
            
        if (!_.isArray(distribution) || distribution.length === 0)
            return;

        const dist = distribution.find(o => o.Id === variable.ID);

        if (_.isObject(dist))
            setDescription(`${translate('stores_within_range')}: ${dist.Raw}\n${translate('available_analogs')}: ${dist.Weighted}`);
        else
            setDescription(`${translate('stores_within_range')}: ${translate('n_a')}\n${translate('available_analogs')}: ${translate('n_a')}`);

    }, [variable, distribution]);

    return <>
        { !_.isArray(variable.ValueList) || variable.ValueList.length === 0 ?
            <div>{translate('no_data')}</div>
            : 
            <Fragment key={variable.ID}>
                <Description description={translate('view_alter_value_variable')} />
                <div className={'app-analogs-filter-details-datalist'}>
                    <DataList
                        keyExpr={'Value'}
                        displayExpr={'Label'}
                        dataSource={variable.ValueList}
                        disabled={(!variable.IsModifiable && variable.IsReadOnly) || isLocked} 
                        searchExpr={'Label'}
                        height={'auto'}
                        selectByClick={true}
                        selectedItems={variable.ValueList.filter(value => value.IsChecked === true).map(value => value.Value)}
                        selectedItemsChange={(o)=> {
                            var items = variable.ValueList;
                            items.forEach(value =>{
                                value.IsChecked = o.indexOf(value.Value) > -1;
                            });

                            if (_.isEqual(origValueList, items))
                                return;

                            updateVariable(items);
                        }}
                    />
                </div>
                { description.length === 0 ? '' :
                    <Description description={description} />
                }
            </Fragment>
        }
    </>
}