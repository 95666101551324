// React imports
import { useState, useEffect, useRef } from 'react';

// App imports
import { TextArea } from '../../../../base/textArea/textArea';
import { Button } from '../../../../base/button/button';
import { ConfirmButton } from "../../../../base/confirmButton/confirmButton";
import { icons } from '../../../../base/icon/icon';
import { translate } from '../../../../../utils/translation';
import { legacyEndpoints } from '../../../../../services/legacyEndpoints';
import { helpers } from '../../../../../utils/helpers';
import { analogs } from '../../../../../modules/analogs';

const _ = require("lodash");

export function AnalogNote({id, date, note, userGUID, userName, isEditable, onUpdate, onDelete}) {

    const [userImage, setUserImage] = useState(null);
    const [noteText, setNoteText] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [editActive, setEditActive] = useState(false);
    const oldText = useRef(note);

    useEffect(() =>{
        setUserImage(legacyEndpoints.handlers.getPhotoUrl({ isUserPhoto: true, userId: userGUID, width: 20, height: 20 }));
        setNoteText(note);
    }, []);
    
    const updateNote = async ()=>{
        oldText.current = noteText;
        setEditActive(false);
        if(_.isFunction(onUpdate))
            onUpdate({id: id, note: noteText});
    }

    const deleteNote = async ()=>{
        await analogs.deleteAnalogNote({noteId: id});

        if(_.isFunction(onDelete))
            onDelete({id});
    }

    return (
        <div key={id} className={'app-analog-note'}>
            <div className='app-analog-note-details'>
                <div className='app-analog-note-display-text'>
                    { isEditable && editActive ? 
                    <>
                        <TextArea className='app-analog-note-edit-input' value={noteText} minHeight={40} maxHeight={200} 
                            valueChangeEvent={'change keyup'}
                            onChange={(o) => { 
                                setNoteText(o.value); 
                            }} 
                            onEnter={(o) => { 
                                updateNote(); 
                            }}
                        /> 
                        <Button className='app-analog-note-edit-save-button' theme='primary' size='small' icon={icons.check} tooltip={translate('save')} disabled={processing} 
                            onClick={()=>{
                                updateNote(); 
                            }} 
                        />
                    </>
                    : 
                        <div className='app-analog-note-display-text'>{noteText}</div> 
                    } 
                </div>
                
                <div className='app-analog-note-footer'>
                    <span className='app-analog-note-footer-left'>
                        <div className='app-analog-note-user-container'>
                            <img className='app-analog-note-userImage' src={userImage} alt={userName} />
                            <span className='app-analog-note-user'>{userName}</span>
                        </div>
                        <div className='app-analog-note-date'>{ (helpers.formatDate({ date: helpers.convertJSONDate(date) }))}</div>
                    </span>
                    <span className='app-analog-note-footer-right'>
                        { isEditable ?
                            <span className='app-analog-note-edit-buttons'>
                                <Button className='app-analog-note-edit-button' theme='simple' active={editActive} icon={icons.edit} tooltip={translate('edit')} 
                                onClick={()=>{
                                    if (editActive)
                                        setNoteText(oldText.current);
                                    setEditActive(!editActive)
                                }} />
                                <ConfirmButton theme='simple' icon={icons.trash} tooltip={translate('delete')} clearText={translate('delete')} onConfirm={()=>{deleteNote()}} />
                            </span>
                            : '' 
                        }
                    </span>                        
                </div>  
            </div>
        </div>
    );
}