import { useEffect, useState } from 'react';

import { InputForm } from '../forms/inputForm/inputForm';
import { Loader } from '../../base/loader/loader';
import { map } from '../map/map';
import { Button } from '../../base/button/button';
import { icons } from '../../base/icon/icon';
import { successToast } from '../../base/toast/toast';
import { translate } from '../../../utils/translation';
import { ConfirmButton } from '../../base/confirmButton/confirmButton';
import { forms  as formModule } from '../../../modules/forms';

const _ = require("lodash");

export function ProjectionTemporaryAddForm({ form, entity, onGenerate }){
    
    const [temporaryForm, setTemporaryForm] = useState(null);
    const [collapsedForm, setCollapsedForm] = useState(null);
    const [generating, setGenerating] = useState(false);
    const [locationResult, setLocationResult] = useState(null);
    const [updateForm, setUpdateForm] = useState(null);
    const [activeWarning, setActiveWarning] = useState(false);
    const [activeError, setActiveError] = useState(false);

    useEffect(() =>{
		(async ()=>{

            map.geocode({
                query: `${entity.location.lat} ${entity.location.lon}`,
                callback: (result) => {
                    setLocationResult(result);
                    setTemporaryForm(form);
                    setCollapsedForm(formModule.collapseForm({form: form}));
                }
            });

            setGenerating(false);

		})();
        
    }, [form, entity]);

    const generate = async () =>{        
        
        onGenerate({
            updateForm: updateForm
        });

        successToast(translate('successfully_added'));        
    };

	return <>
        <div className='app-proposed-site-header'>            
            {
                (activeError === true) ? <Button theme='primary' size='medium' icon={icons.check} disabled={true} /> 
                : (activeWarning === true) ? <ConfirmButton className='app-button-warning' theme='primary' size='medium' align='right' icon={icons.check} clearText={translate('save')} headerText={translate('save_with_warnings')} onConfirm={()=>{generate()}}/> 
                : <Button theme='primary' size='medium' icon={icons.check} onClick={()=>{generate()}}/> 
            }
        </div>
        <Loader loaded={form !== null} />
        <Loader theme='generate' loaded={!generating} />
        <InputForm form={temporaryForm} collapsedForm={collapsedForm} forceChange={true} locationResult={locationResult} onUpdate={(o) => { 
                setUpdateForm(o.updateForm); 
                setActiveError(o.activeError); 
                setActiveWarning(o.activeWarning) }
            } 
        />    
    </>;
}