// React imports
import { useEffect } from 'react';
import { Loader } from '../../components/base/loader/loader';

// App imports
import { constants } from '../../utils/constants';
import { translate } from '../../utils/translation';

export function SignIn({onSignIn}) {
    useEffect(() => {
        onSignIn({
            email: null,
            version: "v" + process.env.REACT_APP_VERSION,
            branch: process.env.REACT_APP_VERSION_BRANCH
        });
    }, []);

    return (
        <>
            <div className='app-sign-in-container'>
                <div className='app-sign-in'>
                    <img className='app-sign-in-logo' src="https://cdn.tradeareasystems.net/Images/Rebrand/Kalibrate_RGB_Color2.png" alt={constants.company} />
                    <div className='app-sign-in-form'>
                        <Loader theme='generate' size='large'/>
                    </div>
                    <div className='app-sign-in-footer'>
                        <div className='app-sign-in-footer-left'>© {constants.company} {new Date().getFullYear()}</div>
                        <div className='app-sign-in-footer-right'>
                            <a target="_blank" rel='noreferrer' href="https://kalibrate.com/terms-of-use/">{translate("terms_of_use")}</a>
                            <a target="_blank" rel='noreferrer' href="https://kalibrate.com/privacy-policy/">{translate("privacy_policy")}</a>
                        </div>
                        <div className='app-sign-in-footer-version' title={process.env.REACT_APP_VERSION_BRANCH}>v{process.env.REACT_APP_VERSION}</div>
                    </div>
                </div>
                <div className='app-sign-in-splash' />
            </div>
        </>

        /*<Loader showLogo='true' theme='primary' size='large'/>*/
    );
}