
// React imports
import { useState } from 'react';

// App imports
import { Tag } from '../tag/tag';
import { Button } from '../button/button';
import { icons } from '../icon/icon';
import { translate } from '../../../utils/translation';

const _ = require("lodash");

export function SelectableItems({ items, selectedItems, maxTagWidth, onClose, onSave, onRender, onSelect, onRenderFooter, hideFooter, showTags=true, toolbar }) {
	
	const [disabled, setDisabled] = useState(false);

	const save = ()=>{
		let selectedItems = [];

		items.forEach(item => _.isArray(item.items) ? item.items.filter(x => x.selected).forEach(item => {selectedItems.push(item);}) : selectedItems.push(item));

		onSave({items: selectedItems});
	};

    const render = (o) => {
        return onRender({
            items: items,
            select: (o)=>{
                o.item.selected = true;
                onSelect({ items: items });
               
                setDisabled(false);
            }
        })
    };

	return  <>
        {
            hideFooter ? '' :
            <div className='app-selectable-items-footer'>
                <div className='app-selectable-items-confim'>
                    {
                        onRenderFooter ? onRenderFooter() : ''
                    }
                    <Button className='app-selectable-items-close-button' theme='secondary' size='medium' icon={icons.x} tooltip={translate('cancel')} onClick={() => { onClose()}} />
                    <Button theme='primary' size='medium' disabled={disabled} icon={icons.check} tooltip={translate('save')} onClick={() => {save()}} />
                </div>
            </div>
        }
        {            
            selectedItems.length === 0 || showTags === false ? '' :
            <div className='app-selectable-items-selected'>
            {
                selectedItems.map((item,i ) => {
                    return <Tag key={i} className='app-selectable-items-tag' id={item.id} text={item.text} maxTextWidth={maxTagWidth ? maxTagWidth + 'px' : ''} onRemove={() => {
                        item.selected = false;
                        onSelect({ items: items });
                    }}/>
                })
            }
            </div>
        }
        {
            toolbar ? toolbar : ''
        }
		<div className= {hideFooter ? 'app-selectable-items-body-no-footer' : 'app-selectable-items-body'}>
            <div className='app-selectable-items-body-scroll'>
            {
                render({items: items})
            }
            </div>        
        </div>
        
	</>
}