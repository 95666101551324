// React imports
import { useEffect } from 'react';

// App imports
import { ExcelUploader } from '../excelUploader'
import { Button } from '../../../base/button/button';
import { SelectableItems } from '../../../base/selectableItems/selectableItems';
import { CollapsibleSection } from '../../../base/collapsibleSection/collapsibleSection';
import { OptionSelector } from '../../../base/optionSelector/optionSelector';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { helpers } from "../../../../utils/helpers";
import { errorToast } from '../../../base/toast/toast';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';

export function BulkDemographcsStep1({excelFile, updateExcelFile, demoReports, updateDemoReports, demoReport, updateDemoReport, onReadyNext}) {

    useEffect(() => {

        if (onReadyNext)
            onReadyNext(excelFile != null && excelFile.resultId.length > 0 && demoReport != null && demoReport.length > 0);

    }, [onReadyNext, excelFile, demoReport])

    const selectDemoReport = (o) =>{
        updateDemoReport(o.selected ? o.item.id : null);

        const newDemoReports = demoReports.map(newReport => ({
            ...newReport,
            items: newReport.items.map(src => {
                    if (src.id !== o.item.id)
                        return {...src, selected: false };
                    else
                        return {...src, selected: o.selected };
                })
            }
        ));

        updateDemoReports(newDemoReports);        
    };
    
    return <>
        <div className='app-tools-bulk-divided-container app-tools-bulk-divided-container-wide'>
            <div className='app-tools-bulk-divided-column'>
                <div className='app-tools-bulk-container-top'>
                    <Button className='app-tools-bulk-download_button' theme='secondary' size='large' icon={icons.download} tooltip={translate('download_template')} 
                        onClick={()=>{ helpers.navigateToUrl('https://tasonline.com/Files/BulkProcessing_Demographics_Template.xls'); }}>
                        {translate('download_template')} 
                    </Button> 
                </div>                                                        
                <div className='app-tools-bulk-rows'>
                    <ExcelUploader excelFile={excelFile} updateExcelFile={async (value) =>{ 
                        
                        if (value === null)
                            updateExcelFile(null);                        
                        else
                        {
                            var result = await legacyEndpoints.service({
                                name: 'ValidateFromFile',
                                suppressError: true,
                                parameters: {
                                    guid: helpers.newGuid(),
                                    type: "2",
                                    fileId: value.resultId,
                                    tableName: "TAS",
                                    fileName: value.name
                                }
                            });
    
                            if (result === null)
                                updateExcelFile(value); 
                            else
                                errorToast(translate('invalid_template_file'));
                        }
                    }} />
                </div>
            </div>
            <div className='app-tools-bulk-vertical-line'></div>
            <div className='app-tools-bulk-divided-column app-tools-bulk-divided-column-wide'>
                <div className='app-tools-bulk-labels'>{translate('select_demographic_report')}</div>
                <div className='app-tools-bulk-columns'>
                    <SelectableItems
                        items={demoReports}
                        selectedItems={demoReports.map((item) => { return item.items.filter(x => x.selected) }).filter(item => item.length > 0).flat()}
                        onSelect={(o) => { updateDemoReports([...o.items]); }}
                        onRender={(o) => {
                            return <div className='app-tools-bulk-selectable-items'>
                            {
                                o.items.map((group, i) => {
                                    return <CollapsibleSection key={i} text={group.text} count={group.items.length} expanded={true} selectedCount={group.items.filter(x => x.selected).length}>
                                    {
                                        group.items.map((item, j) =>{
                                            return <OptionSelector key={j} text={item.text} icon={icons.people} active={item.selected} onClick={() =>{ 
                                                selectDemoReport({ item: item, selected: !item.selected }); 
                                            }}/>
                                        })
                                    }
                                    </CollapsibleSection>
                                })
                            }
                            </div>
                        }}
                        hideFooter={true}
                    />
                </div>
            </div>
        </div>
    </>
}