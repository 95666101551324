// React imports
import { useState, useRef } from 'react'

// App imports
import { Popover } from '../../base/popover/popover';
import { Button } from '../../base/button/button';
import { TextArea } from '../../base/textArea/textArea';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';

const _ = require("lodash");

export function RemoveStore({ parentId, onStoreRemoved }) {

    const ref = useRef(null);

    const [reasonText, setReasonText] = useState('');
	
    return <>
        <Popover 
            ref={ref}
            className='app-analogs-popup-wrapper'
            parentId={`#${parentId}`}
            title={translate('remove_store')}
            height={435}
            width={320}
        >
            <>
                <div className='app-analogs-popup-actions'>
                    <Button 
                        className={'app-analogs-popup-buttons'} 
                        theme='secondary' 
                        size='tiny' 
                        icon={icons.x} 
                        tooltip={translate('cancel')} 
                        onClick={() => { 
                            setReasonText('');
                            ref.current.instance.hide(); 
                        }} 
                    />
                    <Button 
                        className={'app-analogs-popup-buttons'} 
                        theme='primary' 
                        size='tiny' 
                        icon={icons.check} 
                        tooltip={translate('remove')} 
                        disabled={reasonText.length === 0} 
                        onClick={() => { 
                            if (onStoreRemoved)
                                onStoreRemoved({ reason: reasonText });

                            setReasonText('');
                            ref.current.instance.hide();
                        }} 
                    />
                </div>
                <div className='app-analogs-popup-content'>
                    <TextArea 
                        className='app-analogs-popup-reason' 
                        label={translate('reason')} 
                        value={reasonText} 
                        minHeight={290} 
                        maxHeight={290} 
                        valueChangeEvent={'change keyup'}
                        onChange={(o) => { 
                            setReasonText(o.value); 
                        }} 
                    /> 
                </div>							
            </>
        </Popover>
    </>
}