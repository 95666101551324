// React imports
import { useState, useEffect, useCallback } from 'react';

// App imports
import { SelectableItems } from '../../../base/selectableItems/selectableItems';
import { CollapsibleSection } from '../../../base/collapsibleSection/collapsibleSection';
import { OptionSelector } from '../../../base/optionSelector/optionSelector';
import { TextBox } from '../../../base/textBox/textBox';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { constants } from '../../../../utils/constants';
import { reports as reportsModule } from '../../../../modules/reports';
import { Button } from '../../../base/button/button';
import { Loader } from '../../../base/loader/loader';

const _ = require('lodash');

export function SmallDemographicReports({text,reportCategory,tradeAreaLibrary,scrolling}) {

    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [allowSave, setAllowSave] = useState(false);
    const [tradeAreas, setTradeAreas] = useState([]);
    const [title, setTitle] = useState(text);

    var getReportList = useCallback(
        async () =>{
            setLoaded(false);    
            
            var data = await reportsModule.getReportListForCategory({ reportCategory: reportCategory });

            var items = [];

            data.forEach((item) => {
                item.DemographicSource = translate("reports");

                var parent = items.find((val) => val.text.toLowerCase() === item.DemographicSource.toLowerCase());
                var child = {
                    id: item.ID,
                    text: item.Name,
                    type: item.Type
                };

                if (parent)
                    parent.items.push(child);
                else
                    items.push({
                        text: item.DemographicSource,
                        items: [child]
                    });
            });

            setItems([...items]);
            setLoaded(true);      
            
            if (reportCategory === constants.reportCategories.demographicComparison)                               
                setTitle(translate('demographic_comparison_report'));
        },
        [reportCategory]
    );

    useEffect(() =>{
        getReportList();
        setTradeAreas(tradeAreaLibrary);
    }, [tradeAreaLibrary, reportCategory, getReportList]);

    useEffect(() =>{
        var count = 0;
        _.forEach(items, function(group){
            _.forEach(group.items, function(item){
                if (item.selected)
                    count+=1;
            })
        });

        count === 0 ? setAllowSave(false) : setAllowSave(true);
    }, [items]);

    const onSelect = (o) =>{
        o.item.selected = o.selected;
        setItems([...items]);

        if (o.selected)
        {
            selectedItems.push(o.item)
            setSelectedItems([...selectedItems]);
        }
        else
            setSelectedItems([..._.without(selectedItems, o.item)]);
    };

    const onSave = () =>{
        setGenerating(true);

        reportsModule.generateDemoReports({items: selectedItems, title: title, tradeAreas: tradeAreas, callback: () => {setGenerating(false);}});  
    };

    return  <>
        { generating ? <Loader theme='generate' showLogo={true}/> : ''}
        { loaded ? '' : <Loader /> }
        <div className ='app-small-demographic-report-header'>
            <TextBox className='app-small-demographic-report-title' height='47px' label={translate('title')} value={title} onChange={(o) => {setTitle(o.value);}}/>
            <div className='app-small-demographic-report-save'>
                <Button theme='primary' size='medium' disabled={!allowSave} icon={icons.check} tooltip={translate('save')} onClick={() => {onSave()}} />
            </div>
        </div>
        <div className= {scrolling ? 'app-small-demographic-report-scrolling-container' : ''}>
            <SelectableItems
                items={items}
                selectedItems={items.map((item) => { return item.items.filter(x => x.selected) }).filter(item => item.length > 0).flat()}
                onSelect={(o) => { setItems([...o.items]); }}
                hideFooter={true}
                onRender={(o) => {
                    return <div className='app-small-demographic-report-container'>
                    {
                        o.items.map((group, i) => {
                            return <CollapsibleSection key={i} text={group.text} count={group.items.length} selectedCount={group.items.filter(x => x.selected).length}>
                            {
                                group.items.map((item, i) =>{
                                    return <OptionSelector key={i} text={item.text} icon={icons.people} active={item.selected} onClick={() =>{ onSelect({ item: item, selected: !item.selected }); }}/>
                                })
                            }
                            </CollapsibleSection>
                        })
                    }
                    </div>
                }}
                onSave={(o) =>{
                    onSave(o);
                }}
            />
        </div>
        
    </>
}