import { useEffect, useRef, useState, useCallback } from "react";
import { Popover } from "../../../base/popover/popover";
import { TextArea } from "../../../base/textArea/textArea";
import { translate } from "../../../../utils/translation";
import { Button } from "../../../base/button/button";
import { analogs as analogsModule } from "../../../../modules/analogs";
import { AnalogNote } from "./analogNote/analogNote";
import { icons } from "../../../base/icon/icon";
const _ = require("lodash");

export const AddNote = ({ parentId, analogSettings, pointId }) => {

    const ref = useRef(null);

    const [notes, setNotes] = useState([]);
    const [textBoxInput, setTextBoxInput] = useState('');

    const getNotes = useCallback(
        async () => {
            setNotes(await analogsModule.getAnalogNotes({savedAnalogId: analogSettings.id, pointId: pointId}));           
        }, 
        [analogSettings.id, pointId]
    );

    useEffect(() => {
        getNotes();
    }, [getNotes]);

    const addNote = async ({note}) => {
        await analogsModule.addAnalogNote({savedAnalogId: analogSettings.id, note: note, pointId: pointId});  
        getNotes();
    };

    return <>
        <Popover 
            ref={ref}
            className='app-add-note-wrapper'
            parentId={`#${parentId}`}
            showTitle={false}
            height={500}
            width={700}
        >
            <div className="add-notes-container">
            <div className="app-new-note-container">
                    <TextArea 
                        className='app-new-note-input'
                        value={textBoxInput}
                        placeholder={translate('add_note')} 
                        valueChangeEvent={'change keyup'}
                        onEnter={() => { 
                            addNote({note: textBoxInput});
                            setTextBoxInput(''); 
                        }}
                        onChange={(o) => { 
                            setTextBoxInput(o.value);
                        }}
                    />
                    <Button className='app-new-note-save-button' theme='primary' size='small' icon={icons.check} tooltip={translate('save')}
                        onClick={()=>{
                            addNote({note: textBoxInput});
                            setTextBoxInput('');  
                        }} 
                    />
                </div>
                <div className="app-notes-container">
                { notes.length > 0 ?
                    notes.map((note) => {
                        return <AnalogNote key={note.Id} id={note.Id} date={note.Date} note={note.Note} userGUID={note.UserGUID} userName={note.UserName} isEditable={note.isEditable} 
                        onUpdate={({id, note}) => {
                            analogsModule.updateAnalogNote({noteId: id, note: note, pointId: pointId});
                        }}
                        onDelete={async ({id}) => {
                            await analogsModule.deleteAnalogNote({noteId: id, pointId: pointId});
                            getNotes();
                        }}
                        />
                    })
                    : <div className="app-no-notes">{translate('no_notes')}</div>
                }
                </div>    
            </div>         
        </Popover>
    </>;
}