// React imports
import { useState, useEffect, useRef, useCallback } from 'react';

// App imports
import { Loader } from '../../../base/loader/loader';
import { CollapsibleSection } from '../../../base/collapsibleSection/collapsibleSection';
import { TextBox, validatorTypes } from '../../../base/textBox/textBox';
import { DropDown } from '../../../base/dropDown/dropDown';
import { Button }  from '../../../base/button/button';
import { Description }  from '../../../base/description/description';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { successToast, errorToast } from '../../../base/toast/toast';
import { ValidationSummary } from 'devextreme-react/validation-summary';

const _ = require("lodash");
const s = require("underscore.string");

export var userSecurityHelpers;

export function UserSecurity({ onSuccessfulPasswordChanged })
{
    userSecurityHelpers = {
        changePassword: async ({ password, newPassword }) => {
            return await legacyEndpoints.service({
                name: 'ChangePassword',
                parameters: {
                    password: legacyEndpoints.encrypt(password),
                    newPassword: legacyEndpoints.encrypt(newPassword)
                }});
        },
        validatePassword: async ({ password }) => {
            return await legacyEndpoints.service({
                name: 'ValidatePassword',
                parameters: {
                    password: legacyEndpoints.encrypt(password)
                }});
        },
    };

    const textBoxHeight = '55px';
    const [activeSection, setActiveSection] = useState(0);
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [securityQuestionsLoaded, setSecurityQuestionsLoaded] = useState(false);
    const [securityQuestions, setSecurityQuestions] = useState([]);
    const [securityQuestion, setSecurityQuestion] = useState(null);
    const [passwordMode, setPasswordMode] = useState('password');
    const [newPasswordMode, setNewPasswordMode] = useState('password');
    const [confirmNewPasswordMode, setConfirmNewPasswordMode] = useState('password');
    const passwordValidatorRef = useRef(null);
    const newPasswordValidatorRef = useRef(null);
    const confirmNewpasswordValidatorRef = useRef(null);
    const passwordComparison = useCallback(() => password, [password]);
    const newPasswordComparison = useCallback(() => newPassword, [newPassword]);
    const onPasswordChanged = useCallback((e) => {
        setPassword(e.value);
    }, [setPassword]);
    const onNewPasswordChanged = useCallback((e) => {
        setNewPassword(e.value);
    }, [setNewPassword]);
    const onConfirmNewPasswordChanged = useCallback((e) => {
        setConfirmNewPassword(e.value);
    }, [setConfirmNewPassword]);
    const validatePassword = useCallback(async (e) => {
        var result = await userSecurityHelpers.validatePassword({
            password: e.value
        });

        return new Promise((resolve, reject) => {
            if (result.IsValid)
                resolve(result.IsValid);
            else
                reject(s.stripTags(translate(result.ErrorCode)));
        });
    }, []);

    useEffect(() =>
    {
        if (securityQuestions.length > 0)
            return;

        legacyEndpoints.service({
            name: 'GetSecurityQuestions',
            success: function(r) {
                setSecurityQuestions(r);
                setSecurityQuestionsLoaded(true);
            }
        });
    
    }, [securityQuestions.length]);

    return <>
        <Loader className='app-card-body-loader' loaded={securityQuestionsLoaded} />
        {!securityQuestionsLoaded ? '' : 
            <div className='app-user-preferences-sections'>
                <CollapsibleSection key={0} text={translate('password')} expanded={activeSection === 0} onClick={() => { setActiveSection(0); }} >
                    <TextBox label={translate('password')} height={textBoxHeight} mode={passwordMode} value={password} onChange={onPasswordChanged}
                        validator={
                            {
                                ref: passwordValidatorRef,
                                rules: [
                                    {
                                        type: validatorTypes.requiredRule,
                                        message: 'Password is required'
                                    }
                                ]
                            }
                        } actions={[
                            {
                                icon: icons.eye,
                                stylingMode: 'text',
                                onClick: (e) => {
                                    setPasswordMode(passwordMode === 'text' ? 'password' : 'text');
                                }
                            }
                        ]}
                    />
                    <TextBox label={translate('new_password')} height={textBoxHeight} mode={newPasswordMode} value={newPassword} onChange={onNewPasswordChanged}
                        validator={
                            {
                                ref: newPasswordValidatorRef,
                                rules: [
                                    {
                                        type: validatorTypes.requiredRule,
                                        message: 'New Password is required'
                                    },
                                    {
                                        type: validatorTypes.asyncRule,
                                        message: 'New Password does not meet the required guidelines',
                                        callback: validatePassword
                                    },
                                    {
                                        type: validatorTypes.compareRule,
                                        message: 'New Password cannot be the same as the current password',
                                        comparisonTarget: passwordComparison,
                                        comparisonType: '!='
                                    }
                                ]
                            }
                        } actions={[
                            {
                                icon: icons.eye, 
                                stylingMode: 'text',
                                onClick: (e) => {
                                    setNewPasswordMode(newPasswordMode === 'text' ? 'password' : 'text');
                                }
                            }
                        ]}
                     />
                    <TextBox label={translate('confirm_password')} height={textBoxHeight} mode={confirmNewPasswordMode} value={confirmNewPassword} onChange={onConfirmNewPasswordChanged}
                        validator={
                            {
                                ref: confirmNewpasswordValidatorRef,
                                rules: [
                                    {
                                        type: validatorTypes.requiredRule,
                                        message: 'Confirm Password is required'
                                    },
                                    {
                                        type: validatorTypes.compareRule,
                                        message: 'New Password and Confirm Password do not match',
                                        comparisonTarget: newPasswordComparison,
                                        comparisonType: '=='
                                    }
                                ]
                            }
                        } actions={[
                            {
                                icon: icons.eye, 
                                stylingMode: 'text',
                                onClick: (e) => {
                                    setConfirmNewPasswordMode(confirmNewPasswordMode === 'text' ? 'password' : 'text');
                                }
                            }
                        ]}
                    />
                    <ValidationSummary id="summary" />
                    <div className='app-user-preferences-save'>
                        <Button theme='tertiary' size='medium' icon={icons.check} tooltip={translate('save')} disabled={false} onClick={async()=>
                        {
                            if (passwordValidatorRef.current.instance.validate().isValid &&
                                newPasswordValidatorRef.current.instance.validate().isValid &&
                                confirmNewpasswordValidatorRef.current.instance.validate().isValid)
                                {
                                    var result = await userSecurityHelpers.changePassword({
                                        password: password,
                                        newPassword: newPassword
                                    });
                                    
                                    if (!result.IsValid)
                                        errorToast(translate(result.ErrorCode) ?? result.ErrorMessage);
                                    else
                                    {
                                        successToast(translate('success_change_password')); 
                                        setPassword('');
                                        setNewPassword('');
                                        setConfirmNewPassword('');

                                        if (_.isFunction(onSuccessfulPasswordChanged))
                                            onSuccessfulPasswordChanged();
                                    }
                                }
                        }}  />
                    </div>
                    <Description description={translate('change_password1')} bullets={[ 
                            {id: 0, text: translate('change_password3')}, 
                            {id: 1, text: translate('change_password4')}, 
                            {id: 100, bullets: [ 
                                {id: 2, text: translate('change_password5')}, 
                                {id: 3, text: translate('change_password6')}, 
                                {id: 4, text: translate('change_password7')},
                                {id: 5, text: translate('change_password8')},
                                    {id: 101, bullets: [
                                        {id: 6, text: '! @ # $ % ^ & * - _ + = [ ] { } | \\ : \' , . ? / ` ~ " < > ( ) ;'}
                                    ]},
                            ]},
                            {id: 7, text: translate('change_password9')}, 
                            {id: 102, bullets: [ 
                                {id: 8, text: translate('change_password10')}, 
                                {id: 9, text: translate('change_password11')}, 
                                {id: 10, text: translate('change_password12')} 
                            ]} 
                        ]} 
                    />
                </CollapsibleSection>
                {
                    /*
                        <CollapsibleSection key={1} text={translate('security_question')} expanded={activeSection === 1} onClick={() => { setActiveSection(1); }} >
                    <TextBox label={translate('password')} height={textBoxHeight} />
                    <DropDown className='app-user-preferences-inputs' items={securityQuestions} label={translate('security_question')} height={textBoxHeight} display='Question' valueProperty='Id' 
                        selected={securityQuestion} onChange={(o) => { setSecurityQuestion(o.value); }} />
                    <TextBox label={translate('answer')} height={textBoxHeight} />
                    <div className='app-user-preferences-save'>
                        <Button theme='tertiary' size='medium' icon={icons.check} tooltip={translate('save')} disabled={true} />
                    </div>
                    <Description description={translate('security_question_form')} />
                        </CollapsibleSection>
                    */
                }
            </div>
        }
    </>
}