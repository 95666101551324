import { useEffect, useState } from 'react';

import { InputForm } from '../forms/inputForm/inputForm';
import { Loader } from '../../base/loader/loader';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { map } from '../map/map';
import { Button } from '../../base/button/button';
import { icons } from '../../base/icon/icon';
import { constants } from '../../../utils/constants';
import { projections } from '../../../modules/projections';
import { successToast } from '../../base/toast/toast';
import { translate } from '../../../utils/translation';
import { sources } from '../../../modules/sources';
import { layers } from '../layers/layers';
import { ConfirmButton } from '../../base/confirmButton/confirmButton';
import { forms  as formModule } from '../../../modules/forms';

const _ = require("lodash");

export function ProjectionAddForm({ projection, entity }){

    const [form, setForm] = useState(null);
    const [collapsedForm, setCollapsedForm] = useState(null);
    const [generating, setGenerating] = useState(false);
    const [locationResult, setLocationResult] = useState(null);
    const [updateForm, setUpdateForm] = useState(null);
    const [activeWarning, setActiveWarning] = useState(false);
    const [activeError, setActiveError] = useState(true);

    useEffect(() =>{
		(async ()=>{

            map.geocode({
                query: `${entity.location.lat} ${entity.location.lon}`,
                callback: (result) => {
                    setLocationResult(result);
                }
            });
            var formData = await legacyEndpoints.service({
                name: 'GetAnalyticsProjectionProposedSiteForm',
                parameters: {
                    projectionId: projection.id,
                    lat: entity.location.lat, 
                    lon: entity.location.lon
                }
            })
			setForm(formData);
            setCollapsedForm(formModule.collapseForm({form: formData}));

            setGenerating(false);

		})();
    }, [projection, entity]);

    const generate = async () =>{

        setGenerating(true);
        switch (projection.providerType)
        {
            case constants.projections.providers.intalytics:
                var result = await projections.generateProposedLocation({
                    id: projection.id,
                    name: projection.name,
                    type: projection.type,
                    location: entity.location,
                    updateForm: updateForm
                });

                sources.refresh({
                    layers: [{
                        id: result.pointResult.Key,
                        text: translate('loading'),
                        subType: 0,
                        data: { isCompetitiveInsights: false }
                    }],
                    onRefresh: (o) =>{
                        layers.refreshDataLayers(o);
                    }
                });

                entity?.dispose();

                break;
            case constants.projections.providers.eSite:
                await projections.generateAdhoc({
                    id: projection.id,
                    location: entity.location,
                    updateForm: updateForm
                });
                break;
        }
        
        setGenerating(false);
        successToast(translate('successfully_submitted'));        
    };

	return <>
        <div className='app-proposed-site-header'>            
            {
                (activeError === true) ? <Button theme='primary' size='medium' icon={icons.check} disabled={true} /> 
                : (activeWarning === true) ? <ConfirmButton className='app-button-warning' theme='primary' size='medium' align='right' icon={icons.check} clearText={translate('save')} headerText={translate('save_with_warnings')} onConfirm={()=>{generate()}}/> 
                : <Button theme='primary' size='medium' icon={icons.check} onClick={()=>{generate()}}/> 
            }
        </div>
        <Loader loaded={form !== null} />
        <Loader theme='generate' loaded={!generating} />
        <InputForm form={form} collapsedForm={collapsedForm} forceChange={true} locationResult={locationResult} onUpdate={(o) => { setUpdateForm(o.updateForm); 
                            setActiveError(o.activeError); 
                            setActiveWarning(o.activeWarning) }
                        } 
                    />    
    </>;
}