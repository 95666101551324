// App imports
import { Icon, icons } from '../icon/icon';
import { Loader } from '../loader/loader';
import { translate } from "../../../utils/translation";
import { Hideable } from '../hideable/hideable';
import { Button } from '../button/button';
import { useState } from 'react';

import _ from 'lodash';

export function DualPanel({leftPanelClassName, leftPanelChildren, leftPanelText, leftPanelOnClose, rightPanelClassName, rightPanelWidth = 620, rightPanelChildren, rightPanelText, rightPanelOnClose, showRightPanelClose = true, showRightPanel = false, tools, loaded, generating}) {   
    const closeLeft = () =>{
        if(_.isFunction(leftPanelOnClose))
            leftPanelOnClose();
    };

    const closeRight = () =>{
        showRightPanel = false; 
        if(_.isFunction(rightPanelOnClose))
            rightPanelOnClose();
    }

    var leftPanelClasses = ['app-dual-panel left-panel'];
    if (leftPanelClassName)
        leftPanelClasses.push(leftPanelClassName);

    var rightPanelClasses = ['app-dual-panel right-panel'];
    if (rightPanelClassName)
        rightPanelClasses.push(rightPanelClassName);

    return  <>
        <div className={leftPanelClasses.join(' ')}>
            <div className='app-dual-panel-title'>
                <div className='app-dual-panel-back'>
                    <Icon className='app-dual-panel-back-icon' icon={icons.caret}/>
                    <span className='app-dual-panel-back-link' onClick={() => {closeLeft();}}>{translate('go_back')}</span>
                </div>
                {leftPanelText}
            </div>
            <div className='app-dual-panel-container'>
                {
                    tools ? 
                        <div className='app-dual-panel-tool-bar'> 
                        { loaded ? tools : <Loader /> }
                        </div>
                    : ''
                }            
                <div className='app-dual-panel-body'> 
                    { generating ? <Loader theme='generate' showLogo={true}/> : ''}           
                    { loaded ? leftPanelChildren : <Loader /> }
                </div>
            </div>            
        </div>
        <Hideable style={{'maxWidth': rightPanelWidth + 'px'}} className={rightPanelClasses.join(' ')} hide={!showRightPanel}>
            <div className='app-dual-panel-title'>
                { rightPanelText }
                { showRightPanelClose ? <Button className='app-panel-close' theme='action' icon={icons.x} tooltip={translate('close')} onClick={() => { closeRight(); }}/> : ''}
            </div>
            <div className='app-dual-panel-container'>           
                <div className='app-dual-panel-body'> 
                    { generating ? <Loader theme='generate' showLogo={true}/> : ''}           
                    { loaded ? rightPanelChildren : <Loader /> }
                </div>
            </div> 
        </Hideable>
    </>
}