// React imports
import { Fragment, useCallback, useEffect, useState } from 'react';

// App imports
import { Button}  from "../../base/button/button";
import { icons } from "../../base/icon/icon";
import { TextArea } from '../../base/textArea/textArea';
import { Window } from '../../base/window/window';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { translate } from '../../../utils/translation';

const _ = require("lodash");

export function ChatBot() {
    
    const [typingMessage, setTypingMessage] = useState(null);
    const [typingIntervalId, setTypingIntervalId] = useState(null);
    const [messageLog, setMessageLog] = useState([]);
    const [userMessage, setUserMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [threadId, setThreadId] = useState(null);

    const sendUserMessage = useCallback(async (message) => {

        message = message || userMessage;

        if (message.length === 0)
            return;

        setMessageLog([...messageLog, {
            type: 'user',
            text: message
        }]);
        
        setLoading(true);
        
        var data = await legacyEndpoints.service({
            name: 'ChatBotMessage',
            parameters: { 
                threadId: threadId,
                message: message
            }
        });

        setLoading(false);

        if (!_.isArray(data))
            return;

        typeBotMessage(data[0], message);
        
    }, [userMessage, messageLog, threadId]);

    const typeBotMessage = (message, lastUserMessage, reset = false) =>{

        let i = 0;        
        const intervalId = setInterval(() => {

            setTypingMessage({ text: message.slice(0, i), type: 'bot' });
                    
            i+= Math.floor(Math.random() * (10 - 1 + 1) + 1);
        
            if (i < message.length)
                return;
            
            var messages = reset ? [] : [...messageLog];

            if (_.isString(lastUserMessage))
                messages.push({type: 'user', text: lastUserMessage });

            messages.push({type: 'bot', text: message });

            clearInterval(intervalId);
            setTypingMessage(null);
            setMessageLog([...messages]);

        }, 100);

        setTypingIntervalId(intervalId);
    };

    const formatMessage = (message) => {

        const createTitle = (text) => {
            return text.split("###").map((part, i) => {
                return i % 2 === 0 ? <Fragment key={i}>{createSubTitle(part)}</Fragment> : <div key={i} className='app-chat-bot-message-title' >{part}</div>;
            })
        };

        const createSubTitle = (text) =>{            
            return text.split("**-").map((part, i) => {
                return i % 2 === 0 ? <Fragment key={i}>{createBoldText(part)}</Fragment> : <div key={i} className='app-chat-bot-message-sub-title'>{part}</div>;
            })
        };

        const createBoldText = (text) =>{            
            return text.split("**").map((part, i) => {
                return i % 2 === 0 ? <Fragment key={i}>{part}</Fragment> : <b key={i}>{part}</b>;
            })
        };

        const createNewLine = (text) => {
        
            return text.split('\n').map((part, i) => {
                    
                if (text.length === 0)
                    return <br key={i} />;

                return <div key={i} className='app-chat-bot-message-line'>{createTitle(part)}</div>;
            })
        };

        switch (message.type) {
            case 'user':
                return message.text;
            case 'bot':
                return createNewLine(message.text);
            default:
                return null;
        }
    };

    const beginThread = async () => {
        var thread = await legacyEndpoints.service({ name: 'StartChatBotThread' });

        setThreadId(thread);
    };

    const reset = async () => {
        var thread = await legacyEndpoints.service({ name: 'StartChatBotThread' });
        setThreadId(thread);
        setMessageLog([]);
        typeBotMessage(translate('chatbot_welcome'), null, true);
        clearInterval(typingIntervalId);
    };

    useEffect(() => {
        beginThread();
    },[]);

    useEffect(() => {
        
        if (visible && messageLog.length === 0)
            typeBotMessage(translate('chatbot_welcome'));

    }, [visible]);

    return <>
        <div className='app-chat-bot-container'>
            { visible ? <Window 
                title='k.ai' 
                width={"595px"} 
                height={"575px"} 
                position='bottom right'
                container={'.app-chat-bot-container'} 
                containerPosition='bottom right'
                hideOnOutsideClick={true} 
                setVisible={(flag)=>{setVisible(flag)}} 
                resizable={true} 
                visible={visible} 
                dragEnabled={false} 
                className='app-chat-bot'
            >
                <div className='app-chat-bot-content'>
                    <div className='app-chat-bot-body'>
                        {
                            messageLog.map((message, i) => {
                                return <div key={i}>
                                    <div className={`app-chat-bot-message app-chat-bot-message-${message.type}`}>
                                        {formatMessage(message)}
                                    </div>
                                </div>
                            })
                        }
                        {
                            typingMessage ? <div>
                                <div className='app-chat-bot-message app-chat-bot-message-bot'>
                                    {formatMessage(typingMessage)}
                                </div>
                            </div> : null
                        }
                        {loading ?
                            <div>
                                <div className='app-chat-bot-message app-chat-bot-message-bot'>
                                {
                                    [1,3,5].map((dot, i) => {return <div key={i} className={'app-chat-bot-loader-dot app-loader-dot app-loader-dot' + dot} />}) 
                                }
                                </div>
                            </div>                        
                        : null}
                        
                    </div>
                    <div className='app-chat-bot-message-input'>
                        <TextArea 
                            className='app-chat-bot-send-input' 
                            placeholder={translate('chatbot_placeholder')} 
                            minHeight={'50px'} 
                            value={userMessage} 
                            onChange={(o) => {

                                if (o.userChanged !== true)
                                    return;
                                
                                setUserMessage();
                            }} 
                            onEnter={(o) => {        
                                if (o.userChanged !== true)
                                    return;
                        
                                sendUserMessage(o.event.component.option('text'));
                            }}
                        />
                        <Button className='app-chat-bot-send-button' theme='primary' size='tiny' icon={loading ? icons.spinner : icons.send} disabled={loading} onClick={()=>{sendUserMessage()}} />                                    
                        <Button className='app-chat-bot-reset-button' theme='secondary' size='tiny' icon={loading ? icons.spinner : icons.eraser} disabled={loading} onClick={()=>{reset()}} />
                    </div>
                </div>
            </Window> : null}        
        </div>
        <Button className='app-chat-bot-button' theme='chat' icon={icons.comments} onClick={()=>{setVisible(!visible)}} />
    </>
}