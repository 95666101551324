import { Fragment, useMemo, useRef, useState } from 'react';

// App imports
import { DataGrid } from '../../base/dataGrid/dataGrid'
import { Button } from '../../base/button/button'
import { ConfirmButton } from '../../base/confirmButton/confirmButton';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { translate } from '../../../utils/translation';
import { helpers } from '../../../utils/helpers';
import { icons } from '../../base/icon/icon';
import { Loader } from '../../base/loader/loader';
import { ModalCard } from '../../base/modalCard/modalCard';
import { TextBox } from '../../base/textBox/textBox';
import { TextArea } from '../../base/textArea/textArea';

const _ = require('lodash');

export function PointScenarios({ projection, onLoad, close }) {
    
    const [generating, setGenerating] = useState(false);
    const [createActive, setCreateActive] = useState(false);
    const [createName, setCreateName] = useState('');
    const [createDescription, setCreateDescription] = useState('');
    const [createTitle, setCreateTitle] = useState('');
    const [createAction, setCreateAction] = useState('');
    const [createId, setCreateId] = useState('');

    const renderUser = (item) =>{
        
        return <div>            
            <div style={{ display: 'inline-block', marginRight: '10px' }}>{<img className='app-multimedia-user-image' src={legacyEndpoints.handlers.getPhotoUrl({ userId: item.UserId, isUserPhoto: true, width: 50, height: 50 })} alt={translate('user_image')} />}</div>
            <div style={{ display: 'inline-block' }}>{item.UserId}</div>
        </div>
    };

    const renderActions = (o)=>{

        var actions = [];

        actions.push(<Button theme='simple' icon={icons.download} tooltip={translate('load')} onClick={async () => { 
                
            setGenerating(true);

            var data = await legacyEndpoints.service({
                name: 'LoadAnalyticsProjectionScenario',
                parameters: {
                    scenarioId: o.Id
                }
            });

            var scenario = await legacyEndpoints.service({
                name: 'GetScenario',
                parameters: {
                    Id: o.Id
                }
            });
            
            onLoad({
                scenario: scenario,
                data: data
            });

            setGenerating(false);
            
        }} />);

        if (!o.IsReadOnly)
            actions.push(<Button theme='simple' icon={icons.edit} tooltip={translate('edit')} onClick={async () => {
                setCreateActive(true);
                setCreateName(o.Name);
                setCreateDescription(o.Description);
                setCreateTitle(translate('edit_scenario'));
                setCreateAction(1);
                setCreateId(o.Id);
            }}/>);

        actions.push(<Button theme='simple' icon={icons.files} tooltip={translate('copy')} onClick={async () => {
            setCreateActive(true);
            setCreateName(`${o.Name} ${translate('copy')}`);
            setCreateDescription(`${o.Description} ${translate('copy')}`);
            setCreateTitle(translate('copy_scenario'));
            setCreateAction(2);
            setCreateId(o.Id);
        }} />);

        if (!o.IsReadOnly)
            actions.push(<ConfirmButton theme='simple' icon={icons.trash} 
                tooltip={translate('delete')} 
                clearText={translate('delete')}
                onConfirm={async () => {
                    setGenerating(true);

                    await legacyEndpoints.service({
                        name: 'DeleteScenario',
                        parameters: {
                            Id: o.Id
                        }
                    });

                    setGenerating(false);
                }} 
            />);

        return <table className='app-job-management-action-table'>
            <tbody>
                <tr>
                {
                    actions.map((action ,i) => { 
                        return <td key={i}>
                            {action}
                        </td>
                    })
                }
                </tr>
            </tbody>
        </table>
    }

    return  <>
        {
            createActive ?
            <ModalCard className='app-modal-card-default app-point-scenarios-create' open={true}>
                <div className='app-point-scenarios-create-title'>
                    {createTitle}                    
                    <Button theme='primary' size='small' icon={createAction === 0 ? icons.plus : icons.check} disabled={createName.length === 0 || createDescription.length === 0} onClick={async ()=>{

                        setGenerating(true);

                        switch (createAction)
                        {
                            case 0:
                                var scenarioId = await legacyEndpoints.service({
                                    name: 'CreateScenario',
                                    parameters: {
                                        SourceId: projection.pointId,
                                        TypeId: '37AFCA8B-8FA1-4441-814C-C4519210E5CE',
                                        Name: createName,
                                        Description: createDescription
                                    }
                                });
        
                                await legacyEndpoints.service({
                                    name: 'SaveAnalyticsProjectionScenario',
                                    parameters: {
                                        scenarioId: scenarioId,
                                        projectionResults: projection
                                    }
                                });

                                break;
                            case 1:
                                await legacyEndpoints.service({
                                    name: 'UpdateScenario',
                                    parameters: {
                                        Id: createId,
                                        Name: createName,
                                        Description: createDescription,
                                    }
                                });
                                break;
                            case 2:
                                await legacyEndpoints.service({
                                    name: 'CopyScenario',
                                    parameters: {
                                        Id: createId,
                                        Name: createName,
                                        Description: createDescription,
                                    }
                                });
                                break;
                        }                        

                        setGenerating(false);
                        setCreateActive(false);

                    }} />
                    <Button theme='secondary' size='small' icon={icons.x} onClick={()=>{setCreateActive(false);}} />
                </div>
                <div className='app-point-scenarios-create-body'>
                    <TextBox 
                        label={translate('name')}
                        value={createName}
                        onChange={(o) =>{
                            setCreateName(o.value);
                        }}
                    />
                    <TextArea 
                        label={translate('description')} 
                        minHeight='175px' 
                        value={createDescription}
                        onChange={(o) =>{
                            setCreateDescription(o.value);
                        }}
                    />
                </div>
            </ModalCard>
            : ''
        }        
        <Loader loaded={!generating} />
        <div className='app-point-scenarios-title'>
            {`${projection.name} ${translate('scenarios')}`}
            <Button className='app-point-scenarios-close' theme='action' icon={icons.x} onClick={() => { close() }}/>
        </div>
        <div>
            <Button className='app-point-scenarios-create-button' theme='secondary' size='small' icon={icons.plus} onClick={()=>{
                setCreateActive(true);
                setCreateName(translate('new_scenario'));
                setCreateDescription('');
                setCreateTitle(translate('create_scenario'));
                setCreateAction(0);
            }} />
        </div>
        <DataGrid
            className='app-job-management'
            key='Id'
            columns={[
                { name: 'Name', alignment:'center', caption: translate('name'), onRender: (o) => { return o.Name; }},
                { name: 'Projection', alignment:'center', caption: translate('projection'), onRender: (o) => { return o.DictionaryInformation.find(x => x.Key === 'AnalyticsScenarioProjectionName')?.Value; }},
                { name: 'CreateBy', alignment:'center', caption: translate('created_by'), onRender: (o) => { return renderUser(o) }},
                { name: 'Created', alignment:'center', caption: translate('create_date'), width: '200px', onRender: (o) => { return helpers.formatDate({ date: o.DateCreated }); }},
                { name: 'Last Modified', alignment:'center', caption: translate('last_modified_date'), width: '200px', onRender: (o) => { return helpers.formatDate({ date: o.DateModified }); }},
                { name: 'Actions', alignment:'center', caption: translate('actions'), width: '100px', onRender: (o) => { return renderActions(o); }}
            ]}
            onLoad={async ()=>{
                var data = await legacyEndpoints.service({
                    name: 'GetFilteredScenarios',
                    parameters: {
                        SelectedScenarioId: '00000000-0000-0000-0000-000000000000',
                        SourceId: projection.pointId,
                        TypeId: '37AFCA8B-8FA1-4441-814C-C4519210E5CE',
                        Columns: 'OriginalTypeEnumValue,Name,DateModified,UserName,AnalyticsScenarioProjectionName',
                        Page: 0,
                        ResultCount: 15,
                        Filter: '',
                        SortColumn: 2,
                        SortDirection: 'asc',
                        DictionaryKeys: "AnalyticsScenarioProjectionName",
                        SearchableDictionaryKeys: "AnalyticsScenarioProjectionName"
                    }
                });
                
                return {
                    data: data.Scenarios,
                    totalCount: data.TotalRecords
                };
            }}
        />       
    </>
}