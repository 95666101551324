// React imports
import { useEffect } from 'react';

// App imports
import { inputBoxTypes, TextBox } from '../../../base/textBox/textBox';
import { Bar } from '../../../base/bar/bar';
import { Icon, icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { constants } from '../../../../utils/constants';
import { userPreferences } from '../../app';

const _ = require("lodash");

export function BulkClosestStoresStep2({jobName, updateJobName, calculationMethod, updateCalculationMethod, initialRadius, updateInitialRadius, maxRadius, updateMaxRadius, 
    destinationReturn, updateDestinationReturn, excludeDestinations, updateExcludeDestinations, includeOriginData, updateIncludeOriginData, onReadyNext}) {

    const calculationMethods = [
        { icon: icons.rulerCombined, method: constants.bulkReports.calculationMethods.euclidian, text: translate('euclidean_distance') }
    ];

    if (userPreferences.AllowDriveTimes && _.isNumber(userPreferences.DriveTimeEngineID) && _.isNumber(userPreferences.DriveTimeSettingID)) {
        calculationMethods.push({ icon: icons.carSide, method: constants.bulkReports.calculationMethods.driveTime, text: translate('drive_time') });
        calculationMethods.push({ icon: icons.car, method: constants.bulkReports.calculationMethods.driveDistance, text: translate('drive_distance') });
    }

    useEffect(() => {

        if (onReadyNext)
            onReadyNext(jobName.length > 0 && calculationMethod != null && initialRadius > 0 && maxRadius > 0 && destinationReturn > 0);

    }, [onReadyNext, jobName, calculationMethod, initialRadius, maxRadius, destinationReturn])
    
    return <>
        <div className='app-tools-bulk-container'>
            <div className='app-tools-bulk-labels'>{translate('name_your_job')}</div>
            <TextBox className={'app-tools-bulk-text-input'} label={translate('job_name')} value={jobName} height={'36px'} onChange={(o) => { updateJobName(o.value); }} />
            <div className='app-tools-bulk-labels'>{translate('closest_calculation_method')}</div>
            <div className='app-tools-bulk-rows'>
            {
                calculationMethods.map((method, i) => {
                    return (
                        <div key={i} className='app-option-selector'>
                            {calculationMethod === method.method ? 
                            <div className='app-option-selector-check'>
                                <Icon icon={icons.check} />
                            </div> : ''}
                            <Bar className='app-tools-bulk-bar-buttons app-tools-bulk-bar-medium'
                                icon={method.icon}
                                text={method.text} 
                                active={calculationMethod === method.method} 
                                onClick={() => { updateCalculationMethod(method.method); }} 
                            /> 
                        </div>
                    )
                })
            }
            </div>        
            <div className='app-tools-bulk-labels'>{translate('closest_calculation_parameters')}</div>
            <div className='app-tools-bulk-rows'>
                <div className='app-tools-bulk-columns'>
                    <div className='app-tools-bulk-labels'>{translate('initial_radius')}</div>
                    <TextBox 
                        className={'app-tools-bulk-text-input'} 
                        height={'36px'} 
                        value={initialRadius}
                        type={inputBoxTypes.numeric}
                        allowNegative={false}
                        allowDecimals={true}
                        allowZero={false}
                        allowLeadingZeros={false}
                        onChange={(o) => { updateInitialRadius(o.value); }} 
                    />
                </div>        
                <div className='app-tools-bulk-columns'>
                    <div className='app-tools-bulk-labels'>{translate('max_radius')}</div>
                    <TextBox 
                        className={'app-tools-bulk-text-input'} 
                        height={'36px'} 
                        value={maxRadius}
                        type={inputBoxTypes.numeric}
                        allowNegative={false}
                        allowDecimals={true}
                        allowZero={false}
                        allowLeadingZeros={false}
                        onChange={(o) => { updateMaxRadius(o.value); }} 
                    />
                </div>        
                <div className='app-tools-bulk-columns'>
                    <div className='app-tools-bulk-labels'>{translate('return_the')}</div>
                    <TextBox 
                        className={'app-tools-bulk-text-input'} 
                        height={'36px'} 
                        value={destinationReturn}
                        type={inputBoxTypes.numeric}
                        allowNegative={false}
                        allowDecimals={false}
                        allowZero={false}
                        allowLeadingZeros={false}
                        onChange={(o) => { updateDestinationReturn(o.value); }} 
                    />
                    <div className='app-tools-bulk-sub-labels'>{translate('closest_destination_from_origin')}</div>
                </div>        
            </div>        
            <div className='app-tools-bulk-rows app-tools-bulk-container-top'>
                <div className='app-option-selector'>
                    {excludeDestinations === true ? 
                    <div className='app-option-selector-check'>
                        <Icon icon={icons.check} />
                    </div> : ''}
                    <Bar className='app-tools-bulk-bar-buttons app-tools-bulk-bar-medium'
                        icon={icons.fileLines}
                        text={translate('exclude_destination')} 
                        active={excludeDestinations === true} 
                        onClick={() => { updateExcludeDestinations(!excludeDestinations); }} 
                    /> 
                </div>
                <div className='app-option-selector'>
                    {includeOriginData === true ? 
                    <div className='app-option-selector-check'>
                        <Icon icon={icons.check} />
                    </div> : ''}
                    <Bar className='app-tools-bulk-bar-buttons app-tools-bulk-bar-medium'
                        icon={icons.crosshair}
                        text={translate('include_origin_data_results')} 
                        active={includeOriginData === true} 
                        onClick={() => { updateIncludeOriginData(!includeOriginData); }} 
                    /> 
                </div>
           </div>        
        </div>
    </>
}