// React imports
import { useState, useEffect, useCallback } from 'react';

// Third party imports
import ScheduleSelector from 'react-schedule-selector';
import moment from 'moment';
import validator from 'validator';

// App imports
import { GeofenceJobCreateConfirm } from './geofenceJobCreateConfirm';
import { TextBox } from '../../../base/textBox/textBox';
import { TextArea } from '../../../base/textArea/textArea';
import { DateBox } from '../../../base/dateBox/dateBox';
import { Button } from '../../../base/button/button';
import { ModalCard } from '../../../base/modalCard/modalCard';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { successToast, errorToast } from '../../../base/toast/toast';

const _ = require("lodash");

export function GeofenceJobCreate({geofenceId, onClose}) {

    const d = new Date();
    const maxDate = `${d.getMonth()+1}-${d.getDate()}-${d.getFullYear()}`;

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [distanceCutoff, setDistanceCutoff] = useState('50');
    const [schedule, setSchedule] = useState([]);
    const [dayParts, setDayParts] = useState([]);
    const [validationWarning, setValidationWarning] = useState('');
    const [disableCreate, setDisableCreate] = useState(true);
    const [firstPass, setFirstPass] = useState(true);
    const [notifyRestrictedDownload, setNotifyRestrictedDownload] = useState(false);
    const [creating, setCreating] = useState(false);

    const validateInputs = useCallback(
        ()=>{

            var warning = '';

            var cutoff = 0;
            if (validator.isNumeric(distanceCutoff, { no_symbols: true })) {
                cutoff = parseInt(distanceCutoff);
                if (cutoff < 10)
                    warning = translate('minimum_distance_cutoff_job');
            }
            else
                warning = translate('distance_cutoff_required_job');

            if (startDate > endDate)
                warning = translate('start_older_end_date_job');

            if (endDate == null)
                warning = translate('end_date_required_job');

            if (startDate == null)
                warning = translate('start_date_required_job');

            if (description.trim().length == 0)
                warning = translate('description_required_job');

            if (name.trim().length == 0)
                warning = translate('name_required_job');
    
            // do not display warning when component is first displayed
            if (!firstPass)                
                setValidationWarning(warning);

            setFirstPass(false);

            return warning == '';

        // disable missing dependencies warning - do not want to include firstPass and cause this fct to be called when firstPass is set false above
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [name, description, startDate, endDate, distanceCutoff]
    );

    useEffect(() => {
        setDisableCreate(!validateInputs());
    }, [validateInputs])

	const checkForRestrictedDownload = ()=>{

        setCreating(true);

        legacyEndpoints.service({
            name: 'CheckUberRetailJobForRestrictedDownload',
            parameters: {
                GeoFenceId: geofenceId,
                DistanceCutoff: distanceCutoff
            },
            success: function(results) {
                if (results)
                    setNotifyRestrictedDownload(true);
                else
                    createJob({ restrictDownload: false });
            },
            error: function(e) {
                errorToast(e);
                onClose({ refresh: false });
            }
        });
    };		

    const createJob = (o)=>{

        legacyEndpoints.service({
            name: 'CreateUberRetailJob',
            parameters: {
                GeoFenceId: geofenceId,
                RegisteredGeographyId: null, // Explicit Override: Currently only the default grids
                RegisteredGeographyType: 0, // Explicit Override: Currently only the default grids
                PIPReportTypeId: "67D5E012-15F2-4632-8813-23F8DB95A9EA", // Explicit Override: Currently only supporting the Zero Point Report
                DIDReportTypeId: "5FD4A877-33F5-4DEF-BA0D-DB012B5441A6", // Explicit Override: Currently only supporting the Common Evening / Common Daytime Locations Report
                P2PReportTypeId: null,
                Name: name.trim(),
                Description: description.trim(),
                StartDate: `/Date(${startDate.getTime()}-0000)/`,
                StartTimeHour: 0,
                StartTimeMinutes: 0,
                EndDate: `/Date(${endDate.getTime()}-0000)/`,
                EndTimeHour: 23,
                EndTimeMinute: 59,
                DayParts: dayParts,
                DistanceCutoff: distanceCutoff,
                RestrictDownload: o.restrictDownload
            },
            success: function(results) {
                if (results.IsError)
                    errorToast(results.Message);
                else
                    successToast(translate('success'));

                setCreating(false);
                onClose({ refresh: true });
            },
            error: function(e) {
                errorToast(e);
                setCreating(false);
                onClose({ refresh: false });
            }
        });
    };		

    useEffect(() => {
        const myDayParts = _.sortBy(schedule.map(s => ({day: moment(s).day(), hour: moment(s).hour()})), ['day', 'hour']);
        setDayParts(myDayParts);
    }, [schedule])

    const updateDayParts = (newSchedule) => {
        setSchedule(newSchedule);
    }      

    return <>
        <div className='app-geofence-job'>
            <div className='app-geofence-job-header'>      
                <div className='app-geofence-job-create-warning'>{validationWarning.length === 0 ? '' : validationWarning}</div>
                <div className='app-geofence-job-actions'>
                    <Button theme='secondary' size='small' icon={icons.x} tooltip={translate('cancel')} disabled={creating} onClick={() => { onClose({ refresh: false }); }} />
                    <Button theme='primary' size='small' icon={icons.check} tooltip={translate('create')} disabled={disableCreate || creating} onClick={()=>{ checkForRestrictedDownload(); }} />
                </div>
            </div>
            <div className='app-geofence-job-create-body'>
                <div className='app-geofence-job-create-row'>      
                    <TextBox label={translate('name')} value={name} height={'36px'} onChange={(o) => { setName(o.value); }} />
                </div>
                <div className='app-geofence-job-create-row'>      
                    <TextArea label={translate('description')} value={description} minHeight={40} maxHeight={200} valueChangeEvent={'change keyup'} onChange={(o) => { setDescription(o.value); }} /> 
                </div>
                <div className='app-geofence-job-create-row'>      
                    <div className='app-geofence-job-create-dates'>      
                        <div className='app-geofence-job-create-label'>{translate('start_date')}:</div>
                        <DateBox value={startDate} min={'01/01/2014'} max={maxDate} height={'36px'} onChange={(o) => { setStartDate(o.value); }} />
                        <div className='app-geofence-job-create-label'>{translate('end_date')}:</div>
                        <DateBox value={endDate} min={'01/01/2014'} max={maxDate} height={'36px'} onChange={(o) => { setEndDate(o.value); }} />
                    </div>
                </div>
                <div className='app-geofence-job-create-row'>      
                    <TextBox label={translate('distance_cutoff')} value={distanceCutoff} height={'36px'} onChange={(o) => { setDistanceCutoff(o.value); }} />
                </div>
                <div className='app-geofence-job-create-row'>      
                    <div className='app-geofence-job-create-label'>{translate('day_parts')}:</div>
                    <ScheduleSelector
                        selection={schedule}
                        numDays={7}
                        minTime={0}
                        maxTime={24}
                        hourlyChunks={1}
                        dateFormat='ddd'
                        startDate={'1/21/2024'}
                        onChange={updateDayParts}
                    />                                                                                                    
                </div>                 
            </div>
            <ModalCard loaded={true} open={notifyRestrictedDownload == true}>
                { notifyRestrictedDownload === false ? '' :
                    <GeofenceJobCreateConfirm onConfirm={(o)=>{ 
                            setNotifyRestrictedDownload(false);
                            if (o.continue) {
                                setCreating(true);
                                createJob({ restrictDownload: true });
                            }
                        }} 
                    />
                }
            </ModalCard>	
        </div>
    </>
}
