const _ = require("lodash");

export class GoogleDirectionsRenderer {

    constructor(o) {
        this._directionsRenderer = new window.google.maps.DirectionsRenderer();
    }
    get renderer(){ 
        return this._directionsRenderer; 
    }

    dispose() {
        this._directionsRenderer.setMap(null);
        this._directionsRenderer.setPanel(null); 
    }

};