// React imports
import { useEffect, useState, useCallback } from 'react';

// App imports
import { activityHub } from '../../activityHub/activityHub';
import { Loader } from '../../../base/loader/loader';
import { Form } from '../forms';
import { constants } from '../../../../utils/constants';
import { parcel } from '../../../../modules/parcel';
import { map } from '../../map/map';

const _ = require("lodash");

export function ParcelForm({ location, onOpen }){

    const [loaded, setLoaded] = useState(false);
    const [_form, _setForm] = useState(null);

    const highlightParcel = useCallback( 
        (o) => {

            const temporaryLayer = map.layers.find(layer => layer.type === constants.layers.types.temporary);
            temporaryLayer.clear();

            var highlights = [];

            _.each(o.parcels, function (parcel) {

                var locations = [];

                _.each(parcel, function (location) {
                    _.each(location, function (geoPoint) {
                        locations.push({ lat: geoPoint.lat, lon: geoPoint.lon });
                    });
                });

                highlights.push(temporaryLayer.addEntity({
                    text: "",
                    description: "",
                    type: constants.entities.polygon,
                    paths: locations,
                    fillColor: { r: 255, g: 112, b: 1, a: 0.3 },
                    strokeColor: { r: 255, g: 112, b: 1, a: 0.6 },
                    strokeWidth: 2
                }));

            });

            return highlights;

    }, []);

    useEffect(() =>{
        (async () => {
    
            if (!_.isObject(location))
                return;

            setLoaded(false);

            const results = await parcel.getParcelInformation({ latitude: location.lat, longitude: location.lon });

            activityHub.title('');

            const highlights = highlightParcel({ parcels: results.parcels });

            _setForm(results.information);

            setLoaded(true);

            if (_.isFunction(onOpen))
                onOpen({ highlights: highlights });

        })();
    }, [location, highlightParcel, onOpen]);

    return <>
        <Loader className='app-form-loader' loaded={loaded} />
	    <Form 
            form={_form}
        />
    </>
}  