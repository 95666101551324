// App imports
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';

export function GeofenceJobCreateConfirm({onConfirm}) {

    return <>
        <div className='app-geofence-job'>
            <div className='app-geofence-job-header app-geofence-job-create-confirm-header'>      
                <div className='app-geofence-job-actions'>
                    <Button theme='secondary' size='small' icon={icons.x} tooltip={translate('cancel')} onClick={()=>{ onConfirm({ continue: false }); }} />
                    <Button theme='primary' size='small' icon={icons.check} tooltip={translate('continue')} onClick={()=>{ onConfirm({ continue: true }); }} />
                </div>
            </div>
            <div className='app-geofence-job-create-body'>
                <div className='app-geofence-job-create-row'>      
                    <div className='app-geofence-job-create-confirm-warning'>{translate('restricted_data_job')}</div>
                </div>
                <div className='app-geofence-job-create-row'>      
                    <div className='app-geofence-job-create-confirm-warning'>{translate('do_continue')}</div>
                </div>
            </div>
        </div>
    </>
}
