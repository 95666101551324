// App imports
import { helpers } from '../utils/helpers';
import { translate } from '../utils/translation';
import { map } from '../components/app/map/map';
import { constants } from '../utils/constants';

const _ = require("lodash");

export const pointDetail = { 

  getDefaultReport: function() {
      return  {
        id: helpers.newGuid(),
        pointDetailReportId: null,//item.pointDetailReportId,
        customQueryId: null,// customQueryGroup.customQueries[0].id,
        customQueryValue:null, //todo holds composite key
        type: null,
        generationType: null,
        polygon: null,
        outputName: null,
        outputType: null,
        isJob: false,
        isGoldOrg: false,
        extendedParameters: [
            { Key: "AggregateField1", Value: "" },
            { Key: "AggregateFieldType1", Value: "" },
            { Key: "AggregateField2", Value: "" },
            { Key: "AggregateFieldType2", Value: "" },
            { Key: "MinRadius", Value: "" },
            { Key: "BaseGeog", Value: "" },
            { Key: "GeogLevel", Value: "" },
            { Key: "Subtitle", Value: "" },
            { Key: "LengthMeasurement", Value: "" }
        ]
      };
    },

    getCustomQueryGroupings: function(pointDetailData) {
      return  pointDetailData.customQueryGroupings.map(customQueryGrouping => { 
          return customQueryGrouping.customQueries
          .map(customQuery => { 
              return { key: `${customQueryGrouping.id}_${customQuery.id}`, 
                          value: customQuery.id, 
                          name: customQuery.name, 
                          group: customQueryGrouping.name, 
                          isSelected: customQuery.isSelected
                      }}) })
          .flat();   
    },
    
    getTradeAreaLibraries: function() {
        return [{ 
          name: translate('map_extent'), 
          id: helpers.newGuid(),
          tradeAreas: [{
              name: translate('current_view'), 
              id: helpers.newGuid(),
              type: constants.tradeAreas.types.userDrawn,
              polygon: {
                  polygonString: helpers.encodedLocations(helpers.createRectangle({ topLeft: map.bounds.northEast, bottomRight: map.bounds.southWest})),
                  polygonType: constants.encodedString.google,
                  centroid: map.center
              }
          }]
      }, ...map.layers.filter(layer => layer.type === constants.layers.types.tradeArea).map(layer =>{
          return { 
              name: layer.text, 
              id: layer.id,
              tradeAreas: layer.entities.map((entity) =>{
                  return {
                      name: entity.text, 
                      id: entity.id,
                      type: entity.type,
                      polygon: {
                          polygonString: helpers.encodedLocations(entity.paths.flat()),
                          polygonType: constants.encodedString.google,                          
                          centroid:{
                              "lat": entity.location.lat,
                              "lon": entity.location.lon
                            }
                      }                            
                  }
              })
          };
      })];   
    },    

            
};
