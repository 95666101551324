// React imports
import { useState, useEffect } from 'react';

// App imports
import { Wizard } from '../../../base/wizard/wizard';
import { BulkClosestStoresStep1 } from './bulkClosestStoresStep1'
import { BulkClosestStoresStep2 } from './bulkClosestStoresStep2'
import { BulkClosestStoresStep3 } from './bulkClosestStoresStep3'
import { translate } from "../../../../utils/translation";
import { helpers } from "../../../../utils/helpers";
import { constants } from '../../../../utils/constants';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { upload as uploadModule } from '../../../../modules/upload';

const _ = require('lodash');

export function BulkClosestStores({text, onClose}) {

    const [loaded, setLoaded] = useState(true);
    const [generating, setGenerating] = useState(false);
    const [originExcelFile, setOriginExcelFile] = useState(null);
    const [originDataSource, setOriginDataSource] = useState(null);
    const [originDataSources, setOriginDataSources] = useState([]);
    const [destinationExcelFile, setDestinationExcelFile] = useState(null);
    const [destinationDataSource, setDestinationDataSource] = useState(null);
    const [destinationDataSources, setDestinationDataSources] = useState([]);
    const [originFormat, setOriginFormat] = useState(constants.bulkReports.dataSources.excel);		
    const [destinationFormat, setDestinationFormat] = useState(constants.bulkReports.dataSources.excel);		
    const [jobName, setJobName] = useState(`${translate('bulk_closest_stores')} ${helpers.formatDate({ date: new Date() })}`);
    const [calculationMethod, setCalculateMethod] = useState(constants.bulkReports.calculationMethods.euclidian);		
    const [initialRadius, setInitialRadius] = useState(1.00);
    const [maxRadius, setMaxRadius] = useState(1000.00);
    const [destinationReturn, setDestinationReturn] = useState(1);
    const [excludeDestinations, setExcludeDestinations] = useState(true);
    const [includeOriginData, setIncludeOriginData] = useState(true);
    const [continueDisabled, setContinueDisabled] = useState(false);
    const [goToStep, setGoToStep] = useState(-1);

    useEffect(() =>{

        setLoaded(false);      

        legacyEndpoints.service({
            name: 'GetPointServices',
            success: function(results) {
                var sources = [];

                results.forEach((source) => {
                    var groupTitle = translate('others');
                    var groupId = '0';
    
                    if (source.ApplicationSymbology) {
                        groupId = source.ApplicationSymbology.Id;
                        groupTitle = source.ApplicationSymbology.Title;
                    }
    
                    var parent = sources.find((val) => val.id.toLowerCase() === groupId.toLowerCase());
    
                    var child = {
                        id: source.Id,
                        text: source.Name,
                        type: source.Type
                    };
    
                    if (parent)
                        parent.sources.push(child);
                    else
                        sources.push({
                            id: groupId,
                            text: groupTitle,
                            sources: [child]
                        });
                });

                setOriginDataSources([...sources]);
                setDestinationDataSources(_.cloneDeep(sources));

                setLoaded(true);
            }
        });

    }, []);

    var steps = [
        { title: text, text: translate("bulk_closest_stores_step_1"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children: 
            <BulkClosestStoresStep1 
                originExcelFile={originExcelFile} updateOriginExcelFile={(value) =>{ setOriginExcelFile(value); }} 
                originDataSources={originDataSources} updateOriginDataSources={(sources) =>{ setOriginDataSources([...sources]); }}
                originDataSource={originDataSource} updateOriginDataSource={(value) =>{ setOriginDataSource(value); }} 
                destinationExcelFile={destinationExcelFile} updateDestinationExcelFile={(value) =>{ setDestinationExcelFile(value); }} 
                destinationDataSources={destinationDataSources} updateDestinationDataSources={(sources) =>{ setDestinationDataSources([...sources]); }}
                destinationDataSource={destinationDataSource} updateDestinationDataSource={(value) =>{ setDestinationDataSource(value); }} 
                originFormat={originFormat} updateOriginFormat={(value) =>{ setOriginFormat(value); }}
                destinationFormat={destinationFormat} updateDestinationFormat={(value) =>{ setDestinationFormat(value); }}
                onReadyNext={(o) => { setContinueDisabled(!o); }} 
            />
        },
        { title: text, text: translate("bulk_closest_stores_step_2"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children: 
            <BulkClosestStoresStep2 
                jobName={jobName} updateJobName={(value) =>{ setJobName(value); }} 
                calculationMethod={calculationMethod} updateCalculationMethod={(value) =>{ setCalculateMethod(value); }} 
                initialRadius={initialRadius} updateInitialRadius={(value) =>{ setInitialRadius(value); }} 
                maxRadius={maxRadius} updateMaxRadius={(value) =>{ setMaxRadius(value); }} 
                destinationReturn={destinationReturn} updateDestinationReturn={(value) =>{ setDestinationReturn(value); }} 
                excludeDestinations={excludeDestinations} updateExcludeDestinations={(value) =>{ setExcludeDestinations(value); }} 
                includeOriginData={includeOriginData} updateIncludeOriginData={(value) =>{ setIncludeOriginData(value); }} 
                onReadyNext={(o) => { setContinueDisabled(!o); }} 
            />
        },
        { title: text, text: translate("bulk_closest_stores_step_3"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children: 
            <BulkClosestStoresStep3 
                originFormat={originFormat} destinationFormat={destinationFormat} originExcelFile={originExcelFile} destinationExcelFile={destinationExcelFile}
                originDataSources={originDataSources} originDataSource={originDataSource} destinationDataSources={destinationDataSources} destinationDataSource={destinationDataSource}
                jobName={jobName} calculationMethod={calculationMethod} initialRadius={initialRadius} maxRadius={maxRadius} destinationReturn={destinationReturn}
                excludeDestinations={excludeDestinations} includeOriginData={includeOriginData}
                onEditStep={(o) => { setGoToStep(o.step); }} 
            />
        }
	];

    const generateReport = async () =>{

        setGenerating(true);

        var originId = '';
        var destinationId = '';
        var inputType = 0;

        if (originFormat === constants.bulkReports.dataSources.excel)
            originId = originExcelFile.resultId;
        else {
            originId = originDataSource;
            inputType = 2;
        }

        if (destinationFormat === constants.bulkReports.dataSources.excel)
            destinationId = destinationExcelFile.resultId;
        else {
            destinationId = destinationDataSource;
            inputType++;
        }

        cleanupFiles({cancel: false});
    
        var requestId = await legacyEndpoints.service({
            name: 'AddClosestStoreBulkProcessingJob',
            parameters: {
                Name: jobName.trim(),
                OriginId: originId,
                DestinationId: destinationId,
                InputType: inputType,
                CalculationMethod: calculationMethod,
                InitialRadius: initialRadius,
                MaxRadius: maxRadius,
                DestinationReturn: destinationReturn,
                ExcludeDestinationsWithSameId: excludeDestinations,
                IncludeOriginData: includeOriginData,
                OriginOutputReportId: null,
                DestinationOutputReportId: null
            }
        });

        //console.log(requestId);

        setGenerating(false);

        onClose();

        // TBD: open the job managment component
    }

    const cleanupFiles = (o) =>{
        
        if ((o.cancel || originFormat !== constants.bulkReports.dataSources.excel) && originExcelFile != null && originExcelFile.resultId.length > 0) {

            uploadModule.deleteFile({
                fileId: originExcelFile.resultId
            });
    
            setOriginExcelFile(null);
        }
        
        if ((o.cancel || destinationFormat !== constants.bulkReports.dataSources.excel) && destinationExcelFile != null && destinationExcelFile.resultId.length > 0) {

            uploadModule.deleteFile({
                fileId: destinationExcelFile.resultId
            });
    
            setDestinationExcelFile(null);
        }

    }

    const cancel = () =>{
        cleanupFiles({cancel: true});
        onClose();
    };

    const nextStep = (o) =>{
        if (o.step == 3)
            generateReport();
    };

    return  <>
        <Wizard steps={steps} goToStep={goToStep} completeText={translate('generate')} onClose={() =>{ cancel(); }} onNextStep={(o) =>{ nextStep(o); }}/>
    </>
}