// React imports
import { useState, useEffect, useCallback, useRef } from 'react';

// App imports
import { translate } from '../../../../../utils/translation';
import { legacyEndpoints } from "../../../../../services/legacyEndpoints";
import { LargePanel } from '../../../../base/largePanel/largePanel';
import { Description } from '../../../../base/description/description';
import { Button } from '../../../../base/button/button';
import { DataGrid } from '../../../../base/dataGrid/dataGrid';
import { icons, Icon } from '../../../../base/icon/icon';
import { helpers } from '../../../../../utils/helpers';
import { constants } from '../../../../../utils/constants';
import { ConfirmButton } from '../../../../base/confirmButton/confirmButton';
import { successToast } from '../../../../base/toast/toast';

const _ = require('lodash');

export function CustomChannels({text,setPanelContents,onClose}) {
    
    const [loaded, setLoaded] = useState(true);
    const [generating, setGenerating] = useState(false);

    const grid = useRef(null);

    const renderStatus = (status)=>{ //todo: move to a common code
        switch(status){
            default:
                return null;
            case constants.jobs.statuses.pending:
                return <span className='app-job-management-icon-wrapper' title={translate('pending')}>
                    <Icon className='app-job-management-pending app-global-icon-body' icon={icons.hourglass} />
                </span>
            case constants.jobs.statuses.processing:
                return <span className='app-job-management-icon-wrapper' title={translate('processing')}>
                    <Icon className='app-job-management-processing app-global-icon-body' icon={icons.rotate} />
                </span>
            case constants.jobs.statuses.completed:
                return <span className='app-job-management-icon-wrapper' title={translate('completed')}>
                    <Icon className='app-job-management-completed app-global-icon-body' icon={icons.circleCheck} />
                </span>
            case constants.jobs.statuses.completedWithErrors:
                return <span className='app-job-management-icon-wrapper' title={translate('completed_with_errors')}>
                    <Icon className='app-job-management-completed_with_errors app-global-icon-body' icon={icons.warning} />
                </span>
            case constants.jobs.statuses.failed:
                return <span className='app-job-management-icon-wrapper' title={translate('failed')}>
                    <Icon className='app-job-management-failed app-global-icon-body' icon={icons.circleX} />
                </span>
        }
    };      

    const renderActions = (o)=>{
        var actions = [];

        if (o.Status !== constants.jobs.statuses.pending && o.Status !== constants.jobs.statuses.processing) 
            actions.push(<Button theme='simple' icon={icons.edit} 
                tooltip={translate('edit')} 
                clearText={translate('edit')}
                onClick={() =>{setPanelContents({panelId:2, additionalData: {id: o.id}})}}
            />);            

        if (o.Status !== constants.jobs.statuses.pending && o.Status !== constants.jobs.statuses.processing) 
            actions.push(<ConfirmButton theme='simple' icon={icons.trash} 
                tooltip={translate('delete')} 
                clearText={translate('delete')}
                onConfirm={() => { deleteCustomChannel(o); }} 
            />);

        if (actions.length === 0)
            actions.push(<Icon icon={icons.ban} />)

        return <table className='app-tools-custom-channels-action-table'>
            <tbody>
                <tr>
                {
                    actions.map((action ,i) => { 
                        return <td key={i}>
                            {action}
                        </td>
                    })
                }
                </tr>
            </tbody>
        </table>
    }
    
    const renderName = (o) =>{
        return <div className='app-job-management-name-wrapper'>{o.name}</div>
    };    

    const deleteCustomChannel = async (o) =>{
        setGenerating(true);

        await legacyEndpoints.service({
            name: 'DeleteCompetitiveInsightsCustomChannel',
            parameters: {
                id: o.id
            },
            success: () => {
                successToast(translate('success'));
            }
        });

        setGenerating(false);
    };        
    
    const refresh = () =>{
        grid.current.instance.refresh();
    };        

    return  <LargePanel 
            text={text} 
            loaded={loaded} 
            generating={generating} 
            onClose={() =>{ onClose(); }}
            tools={        
                    <div className='app-tools-custom-channels-header'>
                        <div className='app-tools-custom-channels-instructions'>
                                <Description description={translate('custom_channel_directions')} />
                        </div>
                        <div className='app-tools-custom-channels-actions'>
                            <Button theme='secondary' size='small' icon={icons.plus} tooltip={translate('create_custom_channel')} onClick={() =>{ setPanelContents({ panelId: 2 }); }}/>                  
                        </div>
                    </div>}
            >
            <DataGrid
                ref={grid}
                className='app-tools-custom-channels'
                key='Id'
                columns={[
                    { name: 'jobStatus', className: 'app-tools-custom-channels-column-text', alignment:'center', caption: translate('status'), width: '80px', onRender: (o) => { return renderStatus(o.jobStatus); }},
                    { name: 'name', caption: translate('name'), onRender: (o) => { return renderName(o); } },
                    { name: 'lastUpdateDate', caption: translate('date'), width: '220px', onRender: (o) => { return helpers.formatDate({ date: o.lastUpdateDate }); }},
                    { name: 'Actions', alignment:'center', caption: translate('actions'), width: '100px', onRender: (o) => { return renderActions(o); }}
                ]}
                onLoad={async ()=>{                
                    var data = await legacyEndpoints.service({
                        name: 'GetCompetitiveInsightsUserCustomChannels',
                        parameters: {
                            Columns: 'jobStatusColumn,name,lastUpdateDate',
                            Page: 0,
                            ResultCount: 15,
                            Filter: '',
                            SortColumn: 1,
                            SortDirection: 'asc'
                        }
                    });
                    
                    return {
                        data: data.results,
                        totalCount: data.total
                    };
                }}
            />            
        </LargePanel>
}