// React imports
import { useEffect } from 'react';

// App imports
import { BulkDemographicsTradeAreas } from './bulkDemographicsTradeAreas';
import { TextBox } from '../../../base/textBox/textBox';
import { Bar } from '../../../base/bar/bar';
import { Description }  from '../../../base/description/description';
import { Icon, icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { constants } from '../../../../utils/constants';

export function BulkDemographcsStep2({jobName, updateJobName, outputFormat, updateOutputFormat, tradeAreas, updateTradeAreas, onReadyNext}) {

    useEffect(() => {

        if (onReadyNext)
            onReadyNext(jobName.length > 0 && outputFormat.length > 0 && tradeAreas.length > 0 && tradeAreas[0].distance > 0);

    }, [onReadyNext, jobName, outputFormat, tradeAreas])
    
    return <>
        <div className='app-tools-bulk-container'>
            <div className='app-tools-bulk-labels'>{translate('name_your_job')}</div>
            <TextBox className={'app-tools-bulk-text-input'} label={translate('job_name')} value={jobName} height={'36px'} onChange={(o) => { updateJobName(o.value); }} />
            <div className='app-tools-bulk-labels'>{translate('output_format')}</div>
            <div className='app-tools-bulk-rows'>
            {
                [
                    { icon: icons.file, text: translate('all_on_one_sheet'), format: constants.bulkReports.excelOutputFormat.single },
                    { icon: icons.files, text: translate('one_sheet_per_trade_area'), format: constants.bulkReports.excelOutputFormat.multiple },
                ].map((format, i) => {
                    return (
                        <div key={i} className='app-option-selector'>
                            {outputFormat === format.format ? 
                            <div className='app-option-selector-check'>
                                <Icon icon={icons.check} />
                            </div> : ''}
                            <Bar className={'app-tools-bulk-bar-buttons app-tools-bulk-bar-medium'} 
                                icon={format.icon}
                                text={format.text} 
                                active={outputFormat === format.format} 
                                onClick={() => { updateOutputFormat(format.format); }} 
                                disabled={false} 
                            /> 
                        </div>
                    )
                })
            }
            </div>
            <div className='app-tools-bulk-labels'>{translate('trade_areas')}</div>
            <div className='app-tools-bulk-rows'>
                <BulkDemographicsTradeAreas tradeAreas={tradeAreas} updateTradeAreas={updateTradeAreas} />
            </div>
            <div className='app-tools-bulk-rows'>
                <Description description={translate('bulk_demo_drivetime_desc')} />
            </div>
        </div>
    </>
}