// App imports
import { useEffect, useState } from 'react';

import { CIDashboard } from "./ciDashboard";
import { competitiveInsights } from '../../../../../modules/competitiveInsights';
import { constants } from '../../../../../utils/constants';
import { Loader } from '../../../../base/loader/loader';

const _ = require("lodash");

export function CIDashboardLazyLoader({entity}) {

    const [loaded, setLoaded] = useState(false);
    const [siteDetailsData, setSiteDetailsData] = useState(null);

    useEffect(() => {
        (async () => {

            const defaultSettings = await competitiveInsights.getDashboardDefaultSettings({ siteId: entity.id, channelId: entity.metaData.competitiveInsights.channelId, channel: entity.metaData.competitiveInsights.channel });
            const siteDetailsData = await competitiveInsights.getDashboard({ dashboardId: constants.competitiveInsights.dashboards.siteDetail, siteId: entity.id, settings: defaultSettings });

            setSiteDetailsData(siteDetailsData);
            setLoaded(true);

        })();
    }, []);

	return <>
        <Loader loaded={loaded} />
        <CIDashboard data={siteDetailsData} siteId={entity.id} loaded={true} visible={true} /> 
    </>
}