const _ = require("lodash");

export class GoogleHeatMap {

    constructor(o) {

        o.map._private._heatMaps.push(this);

        this._map = o.map;
        this._googleMap = o.googleMap;
        this._imageUrl = o.imageUrl;
        this._layer = o.layer;
        this._visible = o.visible;
        this._zIndex = o.zIndex;
        this._overlay = new window.google.maps.visualization.HeatmapLayer({ map: this._googleMap, dissipating: true });
        this._opacity = .6;
        
        this.refresh();        
        this.setVisible(o.visible);
    }

    dispose() {
        
        if (this._overlay === null)
            return;

        this._overlay.setMap(null);
        this._overlay = null;
        this._map._private._heatMaps = this._map._private._heatMaps.filter(overlay => overlay != this);
    }

    async refresh (){
        var data = await this._imageUrl({
            bounds: this._map.bounds,
            zoom: this._map.zoom,
            layer: this._layer
        });

        if (data == null || data == '')
            return;

        var location = { lat: null, lon: null, weight: 0 };
        var locations = [];        

        _.orderBy(data.locations, ['lat', 'lon']).forEach((element, i) => {            
            if (location.lat === element.lat && location.lon === element.lon)
                location.weight+=_.isNumber(element.weight) ? element.weight : 1;
            else
            {
                location = { lat: element.lat, lon: element.lon, weight: _.isNumber(element.weight) ? element.weight : 1 };
                locations.push(location);
            }
        });

        this._overlay.setOptions({ 
            data: locations.map(location => {return { location: new window.google.maps.LatLng(location.lat, location.lon), weight: location.weight }}),
            gradient: ['rgba(0, 0, 0, 0)'].concat(data.gradient.map(color => { return `rgba(${color.r},${color.g},${color.b},${color.a})` })),
            radius: data.radius,
            maxIntensity: data.intensity
        });
        
        if (this._opacity !== data.opacity)
            this._overlay.setOptions({ opacity: data.opacity });

        this._opacity = data.opacity;
    }

    setVisible(value){
        this._visible = value;        
        this._overlay.setOptions({ opacity: this._visible === false ? 0 : this._opacity });
    }

    setZIndex(){
        // Heat maps are all on the same zIndex
        return;
    }
};