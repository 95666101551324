// React imports
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DropDown } from '../../base/dropDown/dropDown';
import { Button } from '../../base/button/button';
import { constants } from '../../../utils/constants';
import { translate } from '../../../utils/translation';
import { icons } from '../../base/icon/icon';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { helpers } from '../../../utils/helpers';
import { map } from '../map/map';

const _ = require("lodash");

export function ProjectionPolygon({ projectionResults, onSave, onClose }) {

    const [actions, setActions] = useState([{ id: helpers.emptyGuid(), name: translate('draw_polygon'), group: translate('draw') }]);
    const [tradeAreas, setTradeAreas] = useState([]);

    useEffect(() =>{
        (async () => {

            var result = await legacyEndpoints.service({
                name: 'GetTradeAreaModule',
                parameters: {
                    dataSourceId: projectionResults.layer.dataSourceId,
                    customQueryId: projectionResults.layer.customQueryId,
                    pointId: projectionResults.pointId,
                    latitude: projectionResults.location.lat,
                    longitude: projectionResults.location.lon,
                    filteredTypes: [constants.tradeAreas.types.savedShape]
                }
            });
    
            setActions([...actions, ...result.savedTradeAreas.map(tradeArea => {return { id: tradeArea.id, name: tradeArea.name, group: translate('saved_shapes'), tradeArea: tradeArea } })]);
            setTradeAreas(projectionResults.tradeAreas.filter(x => x.isDeliveryArea).map(x => { return { id: x.tradeArea.id, name: x.tradeArea.name, action: helpers.emptyGuid() } }));
        })();
    }, [projectionResults]);

    const generate = useCallback(async () => {
        
        onClose();
        
        var tradeAreasToDraw = tradeAreas.filter(x => x.action === helpers.emptyGuid());
        var tradeAreasToGenerate = tradeAreas.filter(x => x.action !== helpers.emptyGuid());

        const drawingCallback = () =>{
            tradeAreasToDraw.forEach(tradeArea => {
                map.startDrawing({
                    type: constants.entities.polygon,			
                    onTooltipRender: (result) =>{
        
                        if (result.locations.length < 3)
                            return {
                                sections: [<div>{translate('add_at_least_3_vertices')}</div>]
                            };
        
                        return { sections: [] };
                    },
                    onFinish: async (shape) =>{
                        projectionResults.tradeAreas.find(x => x.tradeArea.id === tradeArea.id).tradeArea.polygon.polygonString = helpers.encodedLocations(shape.locations);
                        onSave({ tradeAreas: projectionResults.tradeAreas });
                    }
                });
            });
        };

        if (tradeAreasToGenerate.length === 0)
            drawingCallback();
        else
        {
            var result = await legacyEndpoints.service({
                name: 'GenerateTradeAreas',
                parameters: {
                    latitude: 0,
                    longitude: 0,
                    tradeAreas: tradeAreasToGenerate.map(tradeArea => { 

                        var shapeToGenerate = _.cloneDeep(actions.find(action => action.id === tradeArea.action).tradeArea);
                        shapeToGenerate.type = constants.tradeAreas.types.savedShape;
                        shapeToGenerate.polygon.id = shapeToGenerate.id;

                        return shapeToGenerate; 
                    }),
                }
            });

            result.forEach(generated => {
                var tradeArea = tradeAreas.find(x => x.action === generated.id);
                projectionResults.tradeAreas.find(x => x.tradeArea.id === tradeArea.id).tradeArea.polygon.polygonString = generated.polygon.polygonString;
            });

            if (tradeAreasToDraw.length === 0)
                onSave({ tradeAreas: projectionResults.tradeAreas });
            else
                drawingCallback();
        }

    }, [tradeAreas]);

    return <div className='app-projections-polygon'>
        <div className='app-proposed-site-header'>
            <Button theme='primary' size='tiny' icon={icons.check} onClick={()=>{generate()}}/> 
        </div>
        <div className='app-projections-polygon-content'>
            <table className='app-simple-table'>
                <thead>
                    <tr>
                        <th>{translate('name')}</th>
                        <th>{translate('polygon')}</th>
                    </tr>
                </thead>
                <tbody>
                {
                    tradeAreas.map((tradeArea, i) => {                        
                        return <tr key={i}>
                            <td>{tradeArea.name}</td>
                            <td>
                                <DropDown                                      
                                    display='name'
                                    valueProperty='id'
                                    selected={actions.find(x => x.id === tradeArea.action).id}
                                    grouped={true} 
                                    label={translate('action')}
                                    items={actions}
                                    onChange={({ value }) => {
                                        tradeAreas[i].action = value;
                                        setTradeAreas([...tradeAreas]);                                        
                                    }}
                                />
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    </div>;    
};