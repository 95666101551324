// App imports
import { helpers } from '../utils/helpers';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { translate } from '../utils/translation';
import { map } from '../components/app/map/map';
import { constants } from '../utils/constants';
import { layerActions } from '../components/app/layers/layer/layer';
import { layers } from '../components/app/layers/layers';

const _ = require("lodash");

export const trip2Trade = {    
    data: {
        autoLoadJobs: []
    },    
    getLayers: function() {
      return map.layers.filter(layer => layer.group === constants.layers.groups.trip2Trade)
    },
    getDefaultJob: function(entity) {
        return {
            id: helpers.emptyGuid(),
            pointId: entity.id,
            dataSourceId: helpers.emptyGuid(),
            sourceId: helpers.emptyGuid(),
            tag: {
                id: helpers.emptyGuid()
            },
            name: '',
            description: '',
            radius: 528,
            radiusUnitType: 2,
            tripType: 3,
            geoFilterType: 1,
            geoFenceId: null,
            points: [],
            renderers: [],
            schedulerJobId: null,
            sourceLabel: null
        };  
    },
    getRadiusUnits: function() {
        return [
            { name: translate('ft'), id: constants.lengthMeasurements.feet},
            { name: translate('m'), id: constants.lengthMeasurements.meters},
            { name: translate('km'), id: constants.lengthMeasurements.kilometers},
            { name: translate('mi'), id: constants.lengthMeasurements.miles}
        ];
    },
    getTripTypes: function() {
        return [
          { name: translate('origin'), id: constants.inrixTripType.origin},
          { name: translate('destination'), id: constants.inrixTripType.destination},
          { name: translate('either'), id: constants.inrixTripType.either}
          ];
    },    
    getRendererGridSizeData: function() {
        return [{
                id: '3D6557A1-5244-4C13-A676-A11F6A419316',
                name: '1 acre',
                avgAreaSqMi: 0.00105
            }, {
                id: '00DA69B9-B4AF-4F62-8E8F-966769127186',
                name: '1 typical city block',
                avgAreaSqMi: 0.00555
            }, {
                id: '69316702-9553-46DC-83F6-419E62D065E6',
                name: '1 typical neighborhood',
                avgAreaSqMi: 0.06270000000000001
            }, {
                id: '5FB512C6-20F7-4036-A137-23D12B6DE144',
                name: '1 typical block group',
                avgAreaSqMi: 0.3118
            }, {
                id: '7FEE001C-35A7-4723-BEAC-02016D80EEFF',
                name: '1 typical census tract',
                avgAreaSqMi: 1.6742
            }, {
                id: '23C51467-5B49-4EC6-A125-37A932FE5912',
                name: '1 typical town',
                avgAreaSqMi: 17.8192
            }, {
                id: '06CE25C7-96E5-47D2-A33A-4AFDF0F994A3',
                name: '1 typical city',
                avgAreaSqMi: 92.20105
            }, {
                id: '64C7410A-9491-48A3-B68B-6E2DE9871FB9',
                name: '1 typical Metro CBSA',
                avgAreaSqMi: 515.27415
            }, {
                id: '8E142929-26E5-4019-9F1F-9EE531EB1A2A',
                name: '1 typical DMA',
                avgAreaSqMi: 5168.52035
            }, {
                id: 'AFA90FEE-CC59-4A04-9324-2E2524C00929',
                name: '1 typical state',
                avgAreaSqMi: 27851.10175
            }, {
                id: 'F3F8DA01-D1BB-4D27-BD5F-49994A5341CC',
                name: '1 Census Region',
                avgAreaSqMi: 294900.20135
            }, {
                id: 'E4FEDD31-71D5-4E0C-9047-E27AC170534F',
                name: 'Half of US scope',
                avgAreaSqMi: 1531274.801
            }]   
    } ,
    getDefaultRenderer: function() {
        return {
            id: helpers.emptyGuid(),
            jobId: helpers.emptyGuid(),
            tag: {
                id: helpers.emptyGuid()
            },
            tempId: helpers.newGuid,
            name: translate('default_renderer'),
            description: translate('default_renderer_description'),
            autoLoad: true,
            gridType: 0,
            gridSizeId: '5FB512C6-20F7-4036-A137-23D12B6DE144',
            providerType: 1,
            visualizationType: 1,
            classBreaks: [{
                jobRendererId: helpers.emptyGuid(),
                percentile: 0.5,
                color: {
                    r: 222,
                    g: 45,
                    b: 38,
                    a: 0.75
                }
            }, {
                jobRendererId: helpers.emptyGuid(),
                percentile: 0.7,
                color: {
                    r: 252,
                    g: 146,
                    b: 114,
                    a: 0.75
                }
            }, {
                jobRendererId: helpers.emptyGuid(),
                percentile: 0.9,
                color: {
                    r: 254,
                    g: 224,
                    b: 210,
                    a: 0.75
                }
            }],
            filter: {
                id: helpers.emptyGuid(),
                jobRendererId: helpers.emptyGuid(),
                limitType: 0,
                limitValue: null,
                limitUnitType: constants.lengthMeasurements.meters, 
                startDate: null,
                endDate: null,
                dayParts: trip2Trade.getDayParts()
            },            
            autoLoadJobs: []
        };
    },
    getDefaultClassBreaks: function() {
        return [{
                id: crypto.randomUUID(),
                jobRendererId: helpers.emptyGuid(),
                percentile: 0.5,
                color: {
                    r: 222,
                    g: 45,
                    b: 38,
                    a: 0.75
                }
            }, {
                id: crypto.randomUUID(),
                jobRendererId: helpers.emptyGuid(),
                percentile: 0.7,
                color: {
                    r: 252,
                    g: 146,
                    b: 114,
                    a: 0.75
                }
            }, {
                id: crypto.randomUUID(),
                jobRendererId: helpers.emptyGuid(),
                percentile: 0.9,
                color: {
                    r: 254,
                    g: 224,
                    b: 210,
                    a: 0.75
                }
            }]
    },    
    loadNotification: async function (trip2TradeNotifications) {

        const autoLoadObject = this.data.autoLoadJobs.find(item1 => trip2TradeNotifications.some(item2 => item1.id.toUpperCase() === item2.Id.toUpperCase()));   

        if (_.isObject(autoLoadObject)) {

            this.data.autoLoadJobs = this.data.autoLoadJobs.filter(item => item.id !== autoLoadObject.id);

            var renderers = await legacyEndpoints.service({
                name: 'GetTrip2TradeRenderersFromJob',
                parameters: {
                    id: autoLoadObject.id
                }});
                
            const autoLoadRenderer = renderers.find(x => x.name === autoLoadObject.renderers[0]);

            if (_.isObject(autoLoadRenderer)) 
                await this.showLayers([autoLoadRenderer.id]);
        }   
    },        
    showLayers: async function(selectedRenderers, options) {

        options = options || [];

        var getEntityVisibility = (layer, groupId) =>{
          return layer.oppositeVisibilityEntities.indexOf(groupId) > -1 ? !layer.visible : layer.visible;
        };

        _.each(await this.getTrip2TradeRendererGeoJson(selectedRenderers), (data) =>{
            var existingLayer = this.getLayers().find(layer => { return layer.id.toLowerCase() === data.id });
            if (_.isObject(existingLayer))
                return;

            var active = true;
            var visible = true;
            var oppositeEntities = [];

            const opt = options.find(x => x.id === data.id.toUpperCase());
            if (_.isObject(opt)) {
              active = _.isUndefined(opt.active) ? true : opt.active;
              visible = _.isUndefined(opt.visible) ? true : opt.visible;

              const hiddenGroups = _.isArray(opt.oppositeVisibilityEntities) ? opt.oppositeVisibilityEntities : [];
              hiddenGroups.forEach(groupId => {
                const index = parseInt(groupId);
                if (index < data.legendItems.length) {
                  oppositeEntities.push(data.legendItems[index].groupId);
                }
              });
            }

            var layer = map.addLayer({
                id: data.id,
                group: constants.layers.groups.trip2Trade,
                type: constants.layers.types.data,
                text: data.text,
                subText: data.subText,
                active: active,
                visible: visible,
                oppositeVisibilityEntities: oppositeEntities,
                actions: [{
                    id: layerActions.visible,
                    getActive: () => { return layer.visible; },
                    getHalfActive: () => { return layer.oppositeVisibilityEntities.length > 0; },
                    onClick: () =>{                        
                        layer.visible = !layer.visible;
                    }
                },
                {
                    id: layerActions.delete,
                    onClick: () =>{
                        layer.dispose();
                    }
                }],
                onChange: (c)=>{
                    layers.update();
                }
            });

            var indexVisibilities = data.legendItems.map((legend, index) => { 

              var color = legend.color;
              var visible = getEntityVisibility(layer, legend.groupId);

              for (var i = index; i < data.legendItems.length; i++)
              {
                  var indexVisibility = getEntityVisibility(layer, data.legendItems[i].groupId);                                        
                  if (visible === false && indexVisibility)
                  {
                      color = data.legendItems[i].color;
                      visible = indexVisibility;
                  }
              }

              return { groupId: legend.groupId, visible: visible, color: color }; 
            });

            JSON.parse(data.json).features.forEach(feature =>{

                var fillColor = helpers.stringToColor(feature.properties.fill, parseFloat(feature.properties["fill-opacity"]));
                var strokeColor = helpers.stringToColor(feature.properties.stroke, parseFloat(feature.properties["stroke-opacity"]));
                var visible = true;

                const group = indexVisibilities.find(index => index.groupId === feature.properties.groupId);

                if (_.isObject(group)) {
                    fillColor = { r: group.color.r, g: group.color.g, b: group.color.b, a: fillColor.a };
                    strokeColor = { r: group.color.r, g: group.color.g, b: group.color.b, a: strokeColor.a };
                    visible = group.visible;
                }

                layer.addEntity({
                    id: feature.id,
                    type: constants.entities.geoJson,
                    data: feature,
                    groupId: feature.properties.groupId,
                    fillColor: fillColor,
                    strokeColor: strokeColor,
                    strokeWidth: parseInt(feature.properties["stroke-width"]),
                    visible: visible,
                    listeners: [{
                        type: constants.listeners.click,
                        action: (e) =>{
                            var renderShape = translate('hex');
                            if (data.legendItems.length > 0 && data.legendItems[0].gridType === 1)
                                renderShape = translate('grid');

                            map.showTooltip({
                                title: translate('results'),
                                pixel: e.pixel,
                                sections: feature.properties.jobTotal ?
                                  [<>
                                    <div>{`${renderShape} ${translate('value')}: ${helpers.formatNumber(feature.properties.value)}`}</div>
                                    <div>{`${translate('band_total')}: ${helpers.formatNumber(feature.properties.total)}`}</div>
                                    <div>{`${translate('trade_area_total')}: ${helpers.formatNumber(feature.properties.jobTotal)}`}</div>
                                  </>]
                                  : [<>
                                    <div>{`${feature.properties.name}`}</div>
                                  </>]
                            });
                        }
                    }]
                });
            });

            layer.legend = data.legendItems.map(legend =>{
                return {
                    groupId: legend.groupId,
                    text: legend.text,
                    subText: legend.subText,
                    color: legend.color,
                    actions: [{
                        id: layerActions.visible,
                        getActive: () => { return getEntityVisibility(layer, legend.groupId); },
                        onClick: () =>{
                          if (layer.oppositeVisibilityEntities.indexOf(legend.groupId) > -1)
                              layer.oppositeVisibilityEntities = layer.oppositeVisibilityEntities.filter(x => x !== legend.groupId);
                          else
                              layer.oppositeVisibilityEntities.push(legend.groupId);
                              
                          var indexVisibilities = data.legendItems.map((legend, index) => { 

                              var color = legend.color;
                              var visible = getEntityVisibility(layer, legend.groupId);

                              for (var i = index; i < data.legendItems.length; i++)
                              {
                                  var indexVisibility = getEntityVisibility(layer, data.legendItems[i].groupId);                                        
                                  if (visible === false && indexVisibility)
                                  {
                                      color = data.legendItems[i].color;
                                      visible = indexVisibility;
                                  }
                              }

                              return { groupId: legend.groupId, visible: visible, color: color }; 
                          });

                          layer.entities.filter(x => _.isString(x.groupId)).forEach(x => {
                              var group = indexVisibilities.find(index => index.groupId === x.groupId);                                
                              x.fillColor = { r: group.color.r, g: group.color.g, b: group.color.b, a: x.fillColor.a };
                              x.strokeColor = { r: group.color.r, g: group.color.g, b: group.color.b, a: x.strokeColor.a };
                              x.visible = group.visible;
                          });
                        } 
                    }]
                };
            })
        }); 
        
        layers.update();
    },
    get: async function(o) {
		return await legacyEndpoints.service({
			name: 'GetFilteredTrip2TradeJobs',
            parameters: {
                DataSourceId: o.aServiceID,
                SourceId:  o.sPointID,
                Columns: 'status,name,userName,startDate,endDate,trips,modified',
                Page: 0,
                ResultCount: 15,
                Filter: '',
                SortColumn: 1,
                SortDirection: 'asc'
            }});
  	         
    },
    getTrip2TradeJobs: async function(id) {
      return await legacyEndpoints.service({
        name: 'GetTrip2TradeJobs',
              parameters: {
                idList: [id]
              }});
               
    },
    getTrip2TradeRendererGeoJson: async function(o) {
      return await legacyEndpoints.service({
        name: 'GetTrip2TradeRendererGeoJson',
              parameters: {
                idList: o
              }});
               
    },      
    createTrip2TradeJob: async function(o) {
		return await legacyEndpoints.service({
			name: 'CreateTrip2TradeJob',
            parameters: {
                job: o     
            }});
  	         
    },
    updateTrip2TradeJob: async function(o) {
      return await legacyEndpoints.service({
        name: 'UpdateTrip2TradeJob',
              parameters: {
                  job: o     
              }});
               
      },   
    deleteTrip2TradeJob: async function(id) {
      return await legacyEndpoints.service({
        name: 'DeleteTrip2TradeJobs',
              parameters: {
                idList: [id]   
              }});
               
      }, 
    getDayParts: function() { //todo temp till component in place
        return [
          {
            "day": 0,
            "hour": 0
          },
          {
            "day": 0,
            "hour": 1
          },
          {
            "day": 0,
            "hour": 2
          },
          {
            "day": 0,
            "hour": 3
          },
          {
            "day": 0,
            "hour": 4
          },
          {
            "day": 0,
            "hour": 5
          },
          {
            "day": 0,
            "hour": 6
          },
          {
            "day": 0,
            "hour": 7
          },
          {
            "day": 0,
            "hour": 8
          },
          {
            "day": 0,
            "hour": 9
          },
          {
            "day": 0,
            "hour": 10
          },
          {
            "day": 0,
            "hour": 11
          },
          {
            "day": 0,
            "hour": 12
          },
          {
            "day": 0,
            "hour": 13
          },
          {
            "day": 0,
            "hour": 14
          },
          {
            "day": 0,
            "hour": 15
          },
          {
            "day": 0,
            "hour": 16
          },
          {
            "day": 0,
            "hour": 17
          },
          {
            "day": 0,
            "hour": 18
          },
          {
            "day": 0,
            "hour": 19
          },
          {
            "day": 0,
            "hour": 20
          },
          {
            "day": 0,
            "hour": 21
          },
          {
            "day": 0,
            "hour": 22
          },
          {
            "day": 0,
            "hour": 23
          },
          {
            "day": 1,
            "hour": 0
          },
          {
            "day": 1,
            "hour": 1
          },
          {
            "day": 1,
            "hour": 2
          },
          {
            "day": 1,
            "hour": 3
          },
          {
            "day": 1,
            "hour": 4
          },
          {
            "day": 1,
            "hour": 5
          },
          {
            "day": 1,
            "hour": 6
          },
          {
            "day": 1,
            "hour": 7
          },
          {
            "day": 1,
            "hour": 8
          },
          {
            "day": 1,
            "hour": 9
          },
          {
            "day": 1,
            "hour": 10
          },
          {
            "day": 1,
            "hour": 11
          },
          {
            "day": 1,
            "hour": 12
          },
          {
            "day": 1,
            "hour": 13
          },
          {
            "day": 1,
            "hour": 14
          },
          {
            "day": 1,
            "hour": 15
          },
          {
            "day": 1,
            "hour": 16
          },
          {
            "day": 1,
            "hour": 17
          },
          {
            "day": 1,
            "hour": 18
          },
          {
            "day": 1,
            "hour": 19
          },
          {
            "day": 1,
            "hour": 20
          },
          {
            "day": 1,
            "hour": 21
          },
          {
            "day": 1,
            "hour": 22
          },
          {
            "day": 1,
            "hour": 23
          },
          {
            "day": 2,
            "hour": 0
          },
          {
            "day": 2,
            "hour": 1
          },
          {
            "day": 2,
            "hour": 2
          },
          {
            "day": 2,
            "hour": 3
          },
          {
            "day": 2,
            "hour": 4
          },
          {
            "day": 2,
            "hour": 5
          },
          {
            "day": 2,
            "hour": 6
          },
          {
            "day": 2,
            "hour": 7
          },
          {
            "day": 2,
            "hour": 8
          },
          {
            "day": 2,
            "hour": 9
          },
          {
            "day": 2,
            "hour": 10
          },
          {
            "day": 2,
            "hour": 11
          },
          {
            "day": 2,
            "hour": 12
          },
          {
            "day": 2,
            "hour": 13
          },
          {
            "day": 2,
            "hour": 14
          },
          {
            "day": 2,
            "hour": 15
          },
          {
            "day": 2,
            "hour": 16
          },
          {
            "day": 2,
            "hour": 17
          },
          {
            "day": 2,
            "hour": 18
          },
          {
            "day": 2,
            "hour": 19
          },
          {
            "day": 2,
            "hour": 20
          },
          {
            "day": 2,
            "hour": 21
          },
          {
            "day": 2,
            "hour": 22
          },
          {
            "day": 2,
            "hour": 23
          },
          {
            "day": 3,
            "hour": 0
          },
          {
            "day": 3,
            "hour": 1
          },
          {
            "day": 3,
            "hour": 2
          },
          {
            "day": 3,
            "hour": 3
          },
          {
            "day": 3,
            "hour": 4
          },
          {
            "day": 3,
            "hour": 5
          },
          {
            "day": 3,
            "hour": 6
          },
          {
            "day": 3,
            "hour": 7
          },
          {
            "day": 3,
            "hour": 8
          },
          {
            "day": 3,
            "hour": 9
          },
          {
            "day": 3,
            "hour": 10
          },
          {
            "day": 3,
            "hour": 11
          },
          {
            "day": 3,
            "hour": 12
          },
          {
            "day": 3,
            "hour": 13
          },
          {
            "day": 3,
            "hour": 14
          },
          {
            "day": 3,
            "hour": 15
          },
          {
            "day": 3,
            "hour": 16
          },
          {
            "day": 3,
            "hour": 17
          },
          {
            "day": 3,
            "hour": 18
          },
          {
            "day": 3,
            "hour": 19
          },
          {
            "day": 3,
            "hour": 20
          },
          {
            "day": 3,
            "hour": 21
          },
          {
            "day": 3,
            "hour": 22
          },
          {
            "day": 3,
            "hour": 23
          },
          {
            "day": 4,
            "hour": 0
          },
          {
            "day": 4,
            "hour": 1
          },
          {
            "day": 4,
            "hour": 2
          },
          {
            "day": 4,
            "hour": 3
          },
          {
            "day": 4,
            "hour": 4
          },
          {
            "day": 4,
            "hour": 5
          },
          {
            "day": 4,
            "hour": 6
          },
          {
            "day": 4,
            "hour": 7
          },
          {
            "day": 4,
            "hour": 8
          },
          {
            "day": 4,
            "hour": 9
          },
          {
            "day": 4,
            "hour": 10
          },
          {
            "day": 4,
            "hour": 11
          },
          {
            "day": 4,
            "hour": 12
          },
          {
            "day": 4,
            "hour": 13
          },
          {
            "day": 4,
            "hour": 14
          },
          {
            "day": 4,
            "hour": 15
          },
          {
            "day": 4,
            "hour": 16
          },
          {
            "day": 4,
            "hour": 17
          },
          {
            "day": 4,
            "hour": 18
          },
          {
            "day": 4,
            "hour": 19
          },
          {
            "day": 4,
            "hour": 20
          },
          {
            "day": 4,
            "hour": 21
          },
          {
            "day": 4,
            "hour": 22
          },
          {
            "day": 4,
            "hour": 23
          },
          {
            "day": 5,
            "hour": 0
          },
          {
            "day": 5,
            "hour": 1
          },
          {
            "day": 5,
            "hour": 2
          },
          {
            "day": 5,
            "hour": 3
          },
          {
            "day": 5,
            "hour": 4
          },
          {
            "day": 5,
            "hour": 5
          },
          {
            "day": 5,
            "hour": 6
          },
          {
            "day": 5,
            "hour": 7
          },
          {
            "day": 5,
            "hour": 8
          },
          {
            "day": 5,
            "hour": 9
          },
          {
            "day": 5,
            "hour": 10
          },
          {
            "day": 5,
            "hour": 11
          },
          {
            "day": 5,
            "hour": 12
          },
          {
            "day": 5,
            "hour": 13
          },
          {
            "day": 5,
            "hour": 14
          },
          {
            "day": 5,
            "hour": 15
          },
          {
            "day": 5,
            "hour": 16
          },
          {
            "day": 5,
            "hour": 17
          },
          {
            "day": 5,
            "hour": 18
          },
          {
            "day": 5,
            "hour": 19
          },
          {
            "day": 5,
            "hour": 20
          },
          {
            "day": 5,
            "hour": 21
          },
          {
            "day": 5,
            "hour": 22
          },
          {
            "day": 5,
            "hour": 23
          },
          {
            "day": 6,
            "hour": 0
          },
          {
            "day": 6,
            "hour": 1
          },
          {
            "day": 6,
            "hour": 2
          },
          {
            "day": 6,
            "hour": 3
          },
          {
            "day": 6,
            "hour": 4
          },
          {
            "day": 6,
            "hour": 5
          },
          {
            "day": 6,
            "hour": 6
          },
          {
            "day": 6,
            "hour": 7
          },
          {
            "day": 6,
            "hour": 8
          },
          {
            "day": 6,
            "hour": 9
          },
          {
            "day": 6,
            "hour": 10
          },
          {
            "day": 6,
            "hour": 11
          },
          {
            "day": 6,
            "hour": 12
          },
          {
            "day": 6,
            "hour": 13
          },
          {
            "day": 6,
            "hour": 14
          },
          {
            "day": 6,
            "hour": 15
          },
          {
            "day": 6,
            "hour": 16
          },
          {
            "day": 6,
            "hour": 17
          },
          {
            "day": 6,
            "hour": 18
          },
          {
            "day": 6,
            "hour": 19
          },
          {
            "day": 6,
            "hour": 20
          },
          {
            "day": 6,
            "hour": 21
          },
          {
            "day": 6,
            "hour": 22
          },
          {
            "day": 6,
            "hour": 23
          }
        ]
    },                
};
