// Third party imports
import { useDraggable } from '@dnd-kit/core';

// App imports
import { Button } from '../button/button';
import { Icon, icons } from '../icon/icon';
import { translate } from '../../../utils/translation';

export function DraggableItemBasic({ id }) {

    const {
        attributes,
        listeners,
        setNodeRef,
    } = useDraggable({ id: id });

    return <span ref={setNodeRef} {...attributes} {...listeners}>
        <button {...attributes} {...listeners} className='app-drag-and-drop-basic-move-button' ><Icon icon={icons.ellipses}/></button>
    </span>

}