// React imports
import { useEffect, useState, useCallback } from 'react';

// App imports
import { Loader } from '../../../base/loader/loader';
import { Button } from '../../../base/button/button';
import { DataGrid } from '../../../base/dataGrid/dataGrid'
import { GeofenceJobDetail } from "./geofenceJobDetail";
import { GeofenceJobCreate } from "./geofenceJobCreate";
import { ModalCard } from '../../../base/modalCard/modalCard';
import { Description } from '../../../base/description/description';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { helpers } from '../../../../utils/helpers';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";

export var geofenceJobsHelpers;

export function GeofenceJobs({entity, geofence, onClose}) {

    const [jobsLoaded, setJobsLoaded] = useState(false);
    const [dataGridLoaded, setDataGridLoaded] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [isMaintenance, setIsMaintenance] = useState(false);
    const [maintenanceMessage, setMaintenanceMessage] = useState('');
    const [createJob, setCreateJob] = useState(false);

    geofenceJobsHelpers = {
        refreshGeofenceJobs: (o) => {
            getGeofenceJobs();
        }
    };

    var getGeofenceJobs = useCallback(
        () =>{ 
            if (geofence === null)
                return;

            setJobsLoaded(false);
            setDataGridLoaded(false);

            legacyEndpoints.service({
                name: 'GetGeoFences',
                parameters: {
                    idList: [geofence.id]
                },
                success: function(geofences) {
                    if (!geofences || geofences.length === 0)
                        return;
        
                    legacyEndpoints.service({
                        name: 'GetFilteredUberRetailJobs',
                        parameters: {
                            GeoFenceId: geofences[0].id,
                            Columns: 'Status,Name,Date',
                            Page: 0,
                            ResultCount: 15,
                            Filter: '',
                            SortColumn: 2,
                            SortDirection: 'asc'
                        },
                        success: function(results) {
                            if (results && results.UberRetailJobs && results.UberRetailJobs.length > 0) {
            
                                const newJobs = results.UberRetailJobs.map(newJob => {
                                    return {...newJob, 
                                        AllowDataDownload: results.AllowDataDownload, 
                                        AllowDataRender: results.AllowDataRender,
                                        EntityLayerId: entity.layer.id
                                    };
                                });

                                setJobs([...newJobs]);
                                setIsMaintenance(results.IsMaintenance);
                                setMaintenanceMessage(results.MaintenanceMessage);
                            }
                            else {
                                setJobs([]);
                                setIsMaintenance(false);
                                setMaintenanceMessage('');
                            }

                            setJobsLoaded(true);
                        }
                    });
                }
            });
        },
        [geofence, entity.layer.id]
    );

    useEffect(()=>{        
        getGeofenceJobs();
    },[getGeofenceJobs]);  

    return <>
        <Loader className='app-card-body-loader' loaded={dataGridLoaded} />
        {!jobsLoaded ? '' : 
            <div className='app-geofence-job'>      
                <div className='app-geofence-job-header'>      
                    <div className='app-geofence-job-title'>{geofence === null ? '' : geofence.name}</div>
                    <div className='app-geofence-job-actions'>
                        <Button theme='secondary' size='small' className={'fa-flip-vertical'} icon={icons.arrowTurnDownLeft} tooltip={translate('go_back')} onClick={() =>{ onClose(); }}/>
                        <Button theme='primary' size='small' disabled={isMaintenance} icon={icons.plus} tooltip={translate('create_geofence_job')} onClick={() =>{ setCreateJob(true); }}/>
                    </div>  
                </div>          
                { !isMaintenance ? '' :
                    <div className='app-geofence-job-maintenance'>
                        <Description description={maintenanceMessage} /> 
                    </div>
                }
                <div className='app-geofence-job-datagrid'>
                    { geofence === null ? '' :
                        <DataGrid 
                            key='Id'
                            height='100%'
                            showFilterRow={false}
                            noDataText={translate('no_jobs_geofence')}
                            columns={[
                                { name: 'status', caption: translate('status'), width: '80px', onRender: (o) => { 
                                    switch (o.Status)
                                    {
                                        default:
                                        case 0:
                                            return <div className="app-geofence-job-status pending" title={translate('pending')}></div>
                                        case 1:
                                            return <div className="app-geofence-job-status processing" title={translate('processing')}></div>
                                        case 2:
                                            return <div className="app-geofence-job-status completed" title={translate('completed')}></div>
                                        case 3:
                                            return <div className="app-geofence-job-status completed-with-errors" title={translate('completed_with_errors')}></div>
                                        case 4:
                                            return <div className="app-geofence-job-status failed" title={translate('failed')}></div>
                                    }                                
                                }},
                                { name: 'name', caption: translate('name'), onRender: (o) => { return o.Name; }},
                                { name: 'date', caption: translate('date'), width: '180px', onRender: (o) => { return helpers.formatDate({ date: helpers.convertJSONDate(o.Date), format: 'MM/DD/YYYY h:mm a'}) }}
                            ]}
                            enableMasterDetail={true}
                            detailTemplate={GeofenceJobDetail}
                            expandOneRowOnly={true}
                            disableLoadPanel={true}
                            onLoad={async (o)=>{
                                setDataGridLoaded(true);
                                return {
                                    data: jobs,
                                    totalCount: jobs.length
                                };
                            }}
                        />
                    }
                </div>          
                <ModalCard loaded={true} open={createJob == true}>
                    { createJob === false ? '' :
                        <GeofenceJobCreate geofenceId={geofence.id} onClose={(o)=>{ setCreateJob(false); if (o.refresh) getGeofenceJobs(); }} />
                    }
                </ModalCard>	
            </div>          
        }
    </>								
}