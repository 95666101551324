import { useEffect, useState } from 'react';
import { DataList } from '../../base/dataList/dataList';
import { constants } from '../../../utils/constants';

const _ = require("lodash");

export function ProjectionLinkSitesForm({ projectionResults, projectionElements, onSelect }){
    
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(()=>{
        
        var items = [];

        projectionResults.modelResults.map(modelResult =>{

            projectionElements.find(x => x.id === modelResult.model.id).views.filter(view => view.type === constants.projections.views.disaggregateForecastPoint || view.type === constants.projections.views.disaggregateCannibalizationPoint).forEach(view =>{
                items.push({
                    id: view.id,
                    linked: view.linked,
                    name: `${view.name} (${modelResult.model.name})`
                });
            });
        });

        setItems(items);
        setSelectedItems(items.filter(view => view.linked).map(x => x.id));

    }, [projectionResults]);

	return <>
        <DataList
            keyExpr={'id'}
            grouped={false}
            searchEnabled={false}
            dataSource={items}            
            height={'auto'}
            itemRender={(item) =>{
                return <>{item.name}</>;
            }}
            selectedItemsChange={(o)=> {
                setSelectedItems(o);
                onSelect(o);
            }}
            selectByClick={true}
            selectedItems={selectedItems}
        />
    </>;
}