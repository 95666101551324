// React imports
import { useState } from 'react';

import { Window } from '../window/window';
import { map } from '../../app/map/map';
import { constants } from '../../../utils/constants';

export var rightPanel;

export function RightPanel() {

    var [content, setContent] = useState(null);
    var [visible, setVisible ] = useState(false);
    var [title, setTitle] = useState('');
    var [width, setWidth] = useState('30%');
    var [height, setHeight] = useState('85%');
    var [maxWidth, setMaxWidth] = useState(null);
    var [maxHeight, setMaxHeight] = useState(null);
    var [className, setClassName] = useState('');
    var [onClose, setOnClose] = useState({});

    rightPanel = {
        setContent: (o) => {
            setVisible(true);
            setContent(o.content); 
            setTitle(o.title ? o.title : '');
            setWidth(o.width ? o.width : '30%');
            setHeight(o.height ? o.height : '85%');
            setMaxWidth(o.maxWidth ? o.maxWidth : null);
            setMaxHeight(o.maxHeight ? o.maxHeight : null);
            setClassName(o.className ? o.className : '');
            setOnClose(o.onClose ? { action: o.onClose }  : { });
        },
        getContent: () => {
            return content; //todo expose state or copy?
        }        
    };

    return <Window 
        position='right' 
        className={className}
        visible={visible} 
        setVisible={setVisible} 
        title={title} 
        width={width} 
        height={height} 
        maxWidth={maxWidth} 
        maxHeight={maxHeight} 
        dragEnabled={false}
        onClose={onClose.action}
    >
        {content}
    </Window>
}