// App imports
import { Hideable } from '../hideable/hideable';
import { Loader } from '../loader/loader';

export function Card({className, open, loaded, children}) {

    var classes = ['app-card'];

    if (className)
        classes.push(className);

    return <Hideable className={classes.join(' ')} hide={!open}>
        { loaded===false ? <Loader className='app-card-body-loader' /> :
            <div className='app-card-body'>
                {children}
            </div>
        }
    </Hideable>
}