// React imports
import { useState } from 'react';

// App imports
import { Description } from '../../../base/description/description';
import { TextBox } from '../../../base/textBox/textBox';
import { ConfirmButton } from '../../../base/confirmButton/confirmButton';
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { successToast, errorToast } from '../../../base/toast/toast';

export function GeofenceJobDelete({jobId, onClose}) {

    const [reason, setReason] = useState('');

	const deleteJob = ()=>{

        legacyEndpoints.service({
            name: 'DeleteUberRetailJob',
            parameters: {
                Id: jobId,
                Reason: reason
            },
            success: function(results) {
                successToast(translate('success'));
                onClose({ refresh: true });
            },
            error: function(e) {
                errorToast(e);
                onClose({ refresh: false });
            }
        });

    };		

    return <>
        <div className='app-geofence-job-details-action-container'>
            <div className='app-geofence-job-details-row'>      
                <div className='app-geofence-job-details-action-row'>      
                    <TextBox label={translate('reason')} value={reason} height={'36px'} onChange={(o) => { setReason(o.value); }} />
                </div>
            </div>
            <div className='app-geofence-job-details-btn-row'>      
                <Button className='app-selectable-items-close-button' theme='secondary' size='small' icon={icons.x} tooltip={translate('cancel')} onClick={() => { onClose({ refresh: false }); }} />
                <ConfirmButton theme='primary' size='small' icon={icons.trash} tooltip={translate('delete')} clearText={translate('delete')}
                    disabled={reason.trim().length === 0} onConfirm={()=>{ deleteJob(); }} 
                />
            </div>
            <div className='app-geofence-job-details-row'>      
                <div className='app-geofence-job-details-action-row app-geofence-job-details-bottom-row'>      
                    <Description description={translate('uber_retail_delete_warning')} /> 
                </div>
            </div>
        </div>
    </>
}
