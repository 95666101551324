// React imports
import { React, useState, useEffect, useCallback } from 'react';

// Third party imports
import { TreeList, Scrolling, Paging } from 'devextreme-react/tree-list';

const _ = require("lodash");

export function TreeViewList({dataSource,keyExpr,itemsExpr,dataStructure,height,displayExpr,selectedItemsChange,selectedItems,searchEnabled=true,showParentCheckboxOnly,virtualModeEnabled=false,rootValue=0,parentIdExpr="parentId",scrollingMode="virtual"}) {
	const [selectedItemKeys, setSelectedItemKeys] = useState([]);

	useEffect(()=>{
		setSelectedItemKeys(selectedItems);
    },[selectedItems]);

	const onSelectionChanged = useCallback(
		(e) => {
		  	setSelectedItemKeys(e.selectedRowKeys);
			selectedItemsChange(e.selectedRowKeys);

			// Workaround for a bug in DevExtreme TreeList where the scroll position is not updated when the selected row is not visible
			/*_.delay(function(component) {
				component.getScrollable().scrollBy({ left: 1, top: 1 });
			}, 500, e.component);*/
		},
		[selectedItemsChange],
	);	  

	const onCellPrepared = (e) => {
		if (showParentCheckboxOnly && e.rowType === 'data' && e.data.parentId !== 0 && e.row.node.hasChildren === false )
			e.cellElement.classList.add("hide-check-box");
	};

	return <TreeList 
			keyExpr={keyExpr}
			itemsExpr={itemsExpr}
			dataStructure={dataStructure}
			dataSource={dataSource}
			showBorders={false}
			columnAutoWidth={true}
			wordWrapEnabled={true}       
			showColumnHeaders={false}                 
			onSelectionChanged={onSelectionChanged}   
			selectedRowKeys={selectedItemKeys}
			onCellPrepared={onCellPrepared}
			height={height}
			columns={[{ dataField: displayExpr }]} 
			searchPanel={{visible: searchEnabled, width: 430 }}
			selection={{ mode: "multiple" }}
			rootValue={rootValue}
			virtualModeEnabled={virtualModeEnabled}
			parentIdExpr={parentIdExpr}
		>
    		<Scrolling mode={scrollingMode} />
			<Paging enabled={false} />
		</TreeList>;
}