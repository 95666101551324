// React imports
import { useState, useEffect, useCallback } from 'react';

// Third party imports
import List from 'devextreme-react/list';

const _ = require("lodash");

export function DataList({dataSource,keyExpr,displayExpr='name',disabled=false,height,itemRender,groupRender,selectedItemsChange,selectedItems,grouped=false,selectByClick=true,focusStateEnabled=true,activeStateEnabled=true,searchEnabled=true,selectionMode='all',searchExpr='name',searchValueChange,showSelectionControls=true}) {
	const [selectedItemKeys, setSelectedItemKeys] = useState([]);

	useEffect(()=>{
		setSelectedItemKeys(selectedItems);
    },[selectedItems]);

	const onSelectedItemKeysChange = useCallback(
		(o) => {		
            if (o.name === 'searchValue') {
                if(searchValueChange)
                    searchValueChange(o.value);
            }

			if (o.name !== 'selectedItemKeys')
				return;

			if (_.isArray(o.value) && _.isArray(o.previousValue) && JSON.stringify(_.sortBy(o.value.map(x => { return { id: x }}), 'id')) === JSON.stringify(_.sortBy(o.previousValue.map(x => { return { id: x }}), 'id'))) 
				return;

			if (selectionMode !== 'none' || selectedItemKeys.length !== 0) {
				
				setSelectedItemKeys(o.value);
				selectedItemsChange(o.value);
			}
		},
		[selectionMode, selectedItemKeys, setSelectedItemKeys, selectedItemsChange, searchValueChange],
	);  

	return <List
		keyExpr={keyExpr}
		dataSource={dataSource}
		height={height}
		grouped={grouped}
		collapsibleGroups={true}
		showSelectionControls={showSelectionControls}
		selectionMode={selectionMode}
		selectAllMode={'allPages'}
		displayExpr={displayExpr}
		searchEnabled={searchEnabled}
		searchExpr={searchExpr}
		searchMode={'contains'}
		itemRender={itemRender}
        groupRender={groupRender? groupRender : null}
		onOptionChanged={onSelectedItemKeysChange}
		selectedItemKeys={selectedItemKeys}
		selectByClick={selectByClick} 
		pageLoadMode={"scrollBottom"}
		focusStateEnabled={focusStateEnabled}
		activeStateEnabled={activeStateEnabled}
		disabled={disabled}
	/>
}