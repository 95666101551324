// React imports
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { helpers } from '../../../utils/helpers';
import { Loader } from '../../base/loader/loader';
import { Button } from '../../base/button/button';
import { DropDown } from '../../base/dropDown/dropDown';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { ModalCard } from '../../base/modalCard/modalCard';
import { FilterBuilder } from 'devextreme-react/filter-builder';
import { DataGrid } from '../../base/dataGrid/dataGrid';
import { filtersModule } from '../../../modules/filters';
import { TextBox, validatorTypes } from '../../base/textBox/textBox';
import { TextArea } from '../../base/textArea/textArea';
import { Tabs } from '../../base/tabs/tabs';
import { Description } from '../../base/description/description';
import { successToast, errorToast } from '../../base/toast/toast';

const _ = require("lodash");
const s = require("underscore.string");

export var filterCreate;

export function FilterCreate()
{
    var [visible, setVisible ] = useState(false);
    var [onSave, setOnSave] = useState({});

    var [dataSourceId, setDataSourceId] = useState(null);
    var [customQueryId, setCustomQueryId] = useState(helpers.emptyGuid());
    var [filterSetList, setFilterSetList] = useState([]);
    var [filterSet, setFilterSet] = useState(null);
    var [keyColumn, setKeyColumn] = useState(null);
    var [fields, setFields] = useState([]);
    var [filter, setFilter] = useState([]);
    var [currentTab, setCurrentTab] = useState(null);

    var [name, setName] = useState('');
    var [description, setDescription] = useState('');

    const nameValidatorRef = useRef(null);

    filterCreate = {
        setContent: (o) => {         
            setVisible(true);   
            setDataSourceId(o.dataSourceId ? o.dataSourceId : null);
            setCustomQueryId(o.customQueryId ? o.customQueryId : helpers.emptyGuid());
            setFilterSetList(o.filterSetList ? o.filterSetList: []);
            setOnSave(o.onSave ? { action: o.onSave } : {});
            setName(o.name ? o.name : '');
            setDescription(o.description ? o.description : '');
            setCurrentTab('filterBuilder');

            if (o.filterSetList.length > 0)
                setFilterSet(_.find(o.filterSetList, function(f) { return f.selected === true; }).value);
        },
        close: async ()=> {
            setVisible(false);
            setFilter([]);
            setFields([]);
            setKeyColumn(null);
            setFilterSet(null);
            setFilterSetList([]);
            setDataSourceId(null);
            setCustomQueryId(helpers.emptyGuid());
            setName('');
            setDescription('');
            setCurrentTab('filterBuilder');
        }
    };

    const onValueChanged = useCallback((e) =>
    {
        setFilter(e);
    }, [setFilter]);

    useEffect(()=>
    {
        if (filterSet === null)
            return;

        var obj = _.find(filterSetList, function(f) { return f.value === filterSet; });

        setKeyColumn(obj.keyColumn);
        setFields(obj.fields);
        setFilter([]);//todo keep track of premade filters
    }, [filterSet, filterSetList]);

    const tabs = [
        {
            id: 'filterBuilder',
            text: 'Filter Builder',
            icon: `<svg data-prefix="fas" class="svg-inline--fa" viewBox="0 0 ${icons.filter.icon[0]} ${icons.filter.icon[1]}"><path fill="currentColor" d="${icons.filter.icon[4]}"></svg>`,
            component: <div className='app-filters-create-filter-builder-container'>
                            <FilterBuilder
                                fields={fields}
                                value={filter}
                                onValueChange={onValueChanged}>
                            </FilterBuilder>
                        </div>
        },
        {
            id: 'filterResults',
            text: 'Filter Results',
            icon: `<svg data-prefix="fas" class="svg-inline--fa" viewBox="0 0 ${icons.database.icon[0]} ${icons.database.icon[1]}"><path fill="currentColor" d="${icons.database.icon[4]}"></svg>`,
            component: <div className='app-filters-create-grid-container'>
                            <DataGrid 
                                key={keyColumn}
                                columns={fields}
                                remoteOperations={ {groupPaging: true} }
                                showHeaderFilter={false}
                                showScrollbar='onHover'
                                showFilterRow={false}
                                defaultPageSize={8}
                                onLoad={async (o)=>
                                {
                                    if (dataSourceId === null || filterSet === null || keyColumn === null || fields.length === 0)
                                        return {
                                            data: [],
                                            totalCount: 0
                                        };
                                        
                                    var filters = [];
                                    var sorts = [];

                                    if (_.isArray(filter) && filter.length > 0)
                                        filters = helpers.getFilterBuilder({ filter: filter });

                                    if (_.isArray(o.sort))
                                        o.sort.forEach(sort => { 
                                            var field = _.find(fields, function(f) { return f.name.toLowerCase() === o.sort[0].selector.toLowerCase(); });

                                            sorts.push({ fieldId: field.id, sortOrder: sort.desc ? 1 : 0 });
                                        });
                                    
                                    var data = await filtersModule.getFilterSetData({
                                        dataSourceId: dataSourceId,
                                        customQueryId: customQueryId,
                                        filterSetId: filterSet,
                                        skip: o.skip,
                                        take: o.take,
                                        filters: filters,
                                        sorts: sorts
                                    });

                                    var dataSource = {
                                        results: []
                                    };
                            
                                    data.results.forEach(row => {
                                        var cols = {};
                                        row.data.forEach(col => {
                                            var field = _.find(fields, function(f) { return f.id.toLowerCase() === col.columnId.toLowerCase(); });
                                            cols[field.name] = col.value;
                                        });

                                        dataSource.results.push(cols);
                                    });

                                    return {
                                        data: dataSource.results,
                                        totalCount: data.total
                                    };
                                }}
                            />
                        </div>
        },
    ];

    const onTabClick = async (id) => {
        setCurrentTab(id);
    }  

    return <>
            <ModalCard className='app-modal-card-default app-filters-create-modal' open={visible}>
                <div className='app-filters-create-title'>
                    {translate('create_filter')}
                    <Button className='app-filters-create-title-close' theme='action' icon={icons.x} onClick={async()=>{await filterCreate.close();}} />
                </div>
                <div className='app-filters-create-header'>
                    <div className='app-filters-create-instructions'>
                        <Description description={translate('create_filter_description_text')}></Description>
                    </div>
                    <div className='app-filters-create-actions'>
                        <Button className='app-button-container' theme='secondary' tooltip={translate('save')} size='small' icon={icons.save} onClick={async()=>
                        {
                            if (nameValidatorRef.current.instance.validate().isValid)
                            {
                                if (_.isFunction(onSave.action))
                                {
                                    var filters = [];

                                    if (_.isArray(filter) && filter.length > 0)
                                        filters = helpers.getFilterBuilder({ filter: filter });

                                    if (filters.length > 0)
                                    {
                                        await onSave.action({ dataSourceId, customQueryId, filterSet, filters, name, description });
                                        successToast(translate('filters_saved_success'));
                                        await filterCreate.close();
                                    }
                                    else
                                        errorToast(translate('filter_required'));
                                }
                            }
                        }} />
                    </div>
                </div>
                <div className='app-filters-create-container'>
                    <div className='app-filters-create-dropdown-container'>
                        <DropDown 
                            className='app-filters-create-dropdown' 
                            items={filterSetList}
                            selected={filterSet}
                            display='name' 
                            valueProperty='value'
                            label={translate('filter_sets')}
                            height='47px'
                            onChange={(o) => {
                                setFilterSet(o.value);
                            }}
                        />
                    </div>

                    <div className='app-filters-create-row'>
                        <TextBox className='app-filters-create-row-item' height='47px' width='100%' label={translate('name')} value={name} maxLength={255} onChange={(o) => { setName(o.value); }}
                            validator={
                                {
                                    ref: nameValidatorRef,
                                    rules: [
                                        {
                                            type: validatorTypes.requiredRule,
                                            message: 'Name is required'
                                        }
                                    ]
                                }
                            } />
                    </div>

                    <div className='app-filters-create-row'>                            
                        <TextArea className='app-filters-create-row-item' minHeight={100} width='100%' label={translate('description')} value={description} onChange={(o) => { setDescription(o.value); }} />
                    </div>
                    <div className='app-filters-create-tab-container'>
                        <Tabs tabs={tabs} onTabClick={onTabClick} />
                    </div>
                </div>
            </ModalCard>
        </>;
}