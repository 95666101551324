// React imports
import { useCallback, useEffect, useRef, useMemo, useState } from 'react';

import { DropDown } from '../../base/dropDown/dropDown';
import { Button } from '../../base/button/button';
import { constants } from '../../../utils/constants';
import { translate } from '../../../utils/translation';
import { icons } from '../../base/icon/icon';
import { helpers } from '../../../utils/helpers';
import { RadioButtonSet } from '../../base/radioButtonSet/radioButtonSet';
import { map } from '../map/map';
import { Popover } from '../../base/popover/popover';
import { standardGeographies } from '../../../modules/standardGeographies';
import { workBench } from '../../base/workBench/workBench';
import { DataGrid } from '../../base/dataGrid/dataGrid';
import { TextArea } from '../../base/textArea/textArea';

const _ = require("lodash");

var overlayLayer = null;
var tradeAreaLayer = null;

export function StandardGeographies({ tradeArea, vintages, onSave, onOpen }) {

    const [geoLevel, setGeoLevel] = useState(null); 
    const [geoLevels, setGeoLevels] = useState([]);
    const [report, setReport] = useState(null);
    const [reports, setReports] = useState([]);
    const [data, setData] = useState({ columns: [], data: [] });
    const [behavior, setBehavior] = useState(constants.selections.behaviors.none);
    const [bulkInput, setBulkInput] = useState('');
    const bulkWindow = useRef(null);  

    useEffect(()=>{

        if (!_.isArray(vintages) || vintages.length === 0)
            return;

        overlayLayer = map.addLayer({ group: constants.layers.groups.other, type: constants.layers.types.other });
        tradeAreaLayer = map.addLayer({ group: constants.layers.groups.other, type: constants.layers.types.other });
        map.addListener({
            id: tradeArea.id,
            type: constants.listeners.viewChange,
            action: () => { standardGeographies.updateOverlayLayer(overlayLayer, tradeArea.geographyIds); }
        });

        var geoLevels = vintages.map(vintage => { 
            return vintage.geoLevels.map(geoLevel => { 
                geoLevel.group = vintage.name; 
                geoLevel.reports = vintage.demographicReports; 
                return geoLevel; 
            });
        }).flat();
        
        setGeoLevel(geoLevels.find(x => x.Id === tradeArea.geoLevel) ?? geoLevels[0]);
        setGeoLevels(geoLevels);

        onOpen({ dispose: () => { dispose(); } });

    }, [tradeArea]);

    useEffect(()=>{        
        
        if (!_.isObject(geoLevel))
            return;

        overlayLayer.clear();
        overlayLayer.metaData.geoLevel = geoLevel;
        overlayLayer.metaData.onRefresh = refreshData;
        overlayLayer.metaData.tradeAreaLayer = tradeAreaLayer;
        
        tradeAreaLayer.metaData.onRefresh = refreshData;

        standardGeographies.updateOverlayLayer(overlayLayer, tradeArea.geographyIds);
        
        tradeAreaLayer.clear();
        
        var reports = [{ id: -1, name: translate('no_report') }].concat(geoLevel.reports.map(x => { return { id: parseInt(x.ID), name: x.Name }}));
        
        setReport(reports.find(x => x.id === tradeArea.demographicReport) ?? reports[0]);
        setReports(reports);

    }, [geoLevel]);

    useEffect(()=>{    
        
        if (!_.isObject(overlayLayer) || !_.isObject(tradeAreaLayer))
            return;
        
        overlayLayer.metaData.behavior = behavior;
        tradeAreaLayer.metaData.behavior = behavior;

    }, [behavior]);

    useEffect(()=>{

        if (report === null)
            return;

        overlayLayer.metaData.report = report;

        refreshData();

    }, [report]);

    const select = useCallback((behavior) =>{

        switch(behavior){
            case constants.selections.behaviors.none:
                map.stopDrawing();
                break;
            case constants.selections.behaviors.contains:
            case constants.selections.behaviors.intersects:
                standardGeographies.select({
                    overlayLayer: overlayLayer,
                    tradeAreaLayer: tradeAreaLayer,
                    behavior: behavior,                     
                    onSelect: () => {
                        refreshData();
                        setBehavior(constants.selections.behaviors.none);
                    }
                });
                break;
        }

        setBehavior(behavior);
        
    }, []);

    const refreshData = useCallback(async () =>{

        setData(
            await standardGeographies.updateData({
                overlayLayer: overlayLayer,
                selectionType: constants.selections.types.append, 
                selectionBehavior: constants.selections.behaviors.single, 
                polygonSelection: { polygonString: helpers.encodedLocations([{ lat: 0, lon: 0}]), polygonType: constants.encodedString.google },
                selectedGeographies: tradeAreaLayer.entities.map(item => { return item.id }), 
                skip: 0, 
                take: 0, 
                filters: [], 
                sorts: []
            })
        );
        
    }, []);

    const dispose = useCallback(() =>{
        map.removeListener(tradeArea.id);
        map.hideTooltip();
        overlayLayer.dispose();
        tradeAreaLayer.dispose();
        workBench.close();
        map.layers.find(x => x.type === constants.layers.types.temporary).clear();
    }, []);

    const grid = useMemo(() =>{
        return <DataGrid 
            key='id'
            columns={data.columns}
            showScrollbar='onHover'
            onLoad={(o) => {
                return data.data;
            }}
        />
    }, [data]);
    
    return <div className='app-projections app-standard-geography'>
        <Popover ref={bulkWindow} parentId='#app-standard-geographies-bulk' title={translate('bulk')}height={400} width={400}>
            <div className='app-projections-execute-job' >
                <Button theme='primary' size='tiny' icon={icons.check} disabled={bulkInput.length === 0} onClick={async ()=>{                
                    
                    var result = await standardGeographies.getBulkData({
                        overlayLayer, 
                        type: constants.selections.types.new, 
                        bulkData: bulkInput.split(',').map(x => { return x.trim(); }), 
                        currentData: tradeAreaLayer.entities.map(x => { return x.id; })
                    });

                    result.shapes.forEach(geography =>{                    
                        standardGeographies.createTradeAreaEntity(
                            tradeAreaLayer,
                            {
                                id: geography.id,
                                paths:helpers.parseWkt(geography.polygon.polygonString),
                                metaData: { wkt: geography.polygon.polygonString }
                            }
                        );
                    });

                    tradeAreaLayer.metaData.onRefresh();
                    bulkWindow.current.instance.hide();
                    setBulkInput('');

                }}/>
            </div>
            <TextArea value={bulkInput} placeholder={translate('standard_geography_bulk')} minHeight={260} onChange={(o) => { 
                
                if (o.userChanged)
                    setBulkInput(o.value);

            }} />
            
        </Popover>
        <div className='app-projections-toolbar'>
            <DropDown
                className='app-projections-dropdown'
                grouped={true}
                display='Name'
                selected={geoLevel} 
                label={translate('geography')}
                items={geoLevels}
                onChange={(o) => {
                    setGeoLevel(o.value);
                }}
            />
            <DropDown 
                className='app-projections-dropdown'
                display='name'
                selected={report} 
                label={translate('report')}
                items={reports}
                onChange={(o) => {
                    setReport(o.value);
                }}
            />
            <RadioButtonSet className='app-projections-selection-tools'>
            {
                [
                    { icon: icons.crosshair, tooltip: translate('single'), behavior: constants.selections.behaviors.single },
                    { icon: icons.dotCircle, tooltip: translate('contains'), behavior: constants.selections.behaviors.contains },
                    { icon: icons.objectUngroup, tooltip: translate('intersects'), behavior: constants.selections.behaviors.intersects },
                    { icon: icons.grab, tooltip: translate('pan'), behavior: constants.selections.behaviors.none }
                ].map(button =>{
                    return <Button key={button.behavior} theme='action' size='tiny' icon={button.icon} tooltip={button.tooltip} active={behavior===button.behavior} onClick={()=>{select(button.behavior); }} />
                })
            }
            </RadioButtonSet>
            <Button id='app-standard-geographies-bulk' className='app-projections-toolbar-action' theme='action' size='tiny' icon={icons.list} />
            <Button className='app-projections-toolbar-action' theme='primary' size='tiny' icon={icons.save} disabled={tradeAreaLayer == null || tradeAreaLayer.entities.length === 0} onClick={()=>{
                onSave({ ids: tradeAreaLayer.entities.map(x => x.id), wkt: `GEOMETRYCOLLECTION(${tradeAreaLayer.entities.map(x => x.metaData.wkt).join(',')})`, vintageId: geoLevel.GeographyVintageId, geoLevelId: geoLevel.Id, reportId: report.id });
                dispose();
            }} />
        </div>
        <div className='app-projections-content'>            
            {grid}
        </div>       
    </div>
}