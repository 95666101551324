// React imports
import { useEffect, useState } from 'react';

// Third party imports
import ColorBox, { ColorBoxTypes } from 'devextreme-react/color-box';

// App imports
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { TextBox, inputBoxTypes } from '../../../base/textBox/textBox';
import { ConfirmButton } from '../../../base/confirmButton/confirmButton';
import { helpers } from '../../../../utils/helpers';

const _ = require('lodash');

export function CreateRendererStep2({renderer,updateRenderer,onReadyNext}){

    useEffect(() => {
        if (onReadyNext)
            onReadyNext(true);//todo add validation

    }, [onReadyNext]) 

    function createClassBreak() {    
        var percentile = 0;
        
        while (renderer.classBreaks.find(x => x.percentile === percentile)) {
            percentile += .01;
        }
        
        updateRenderer({ ...renderer, classBreaks: [ ...renderer.classBreaks, { jobRendererId: renderer.id, percentile: percentile, color: helpers.randomColor() } ] });                      
    };        
    
    function deleteClassBreak (percentile){
        updateRenderer({ ...renderer, classBreaks: renderer.classBreaks.filter(x => x.percentile !== percentile) }); 
    };
	
	 function handleColorUpdate(percentile, color) {
        updateRenderer({...renderer, classBreaks: renderer.classBreaks.map(classBreak => {
          if (classBreak.percentile === percentile) {
            return { ...classBreak, color: color };
          } else {
            return classBreak;
          }
        })});
      } 

	return <>    
        <div>
            <Button theme='secondary'className='app-trip-2-trade-renderer-class-breaks-add' size='small'icon={icons.plus} tooltip={translate('create_class_break')} onClick={() =>{ createClassBreak()  }}/>
        </div>
        <div className='app-trip-2-trade-renderer-class-breaks-body'>
            <div>
                {
                    renderer.classBreaks.map((classBreak,i) => {

                        return <div key={classBreak.percentile} className='app-trip-2-trade-renderers-row'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <TextBox className='app-trip-2-trade-renderer-class-breaks-percentile' height='47px' label={translate('percentile')} 
                                                name='percentile' type={inputBoxTypes.percent} value={classBreak.percentile} valueChangeEvent='keyup' 
                                                onChange={(o) => {
                                                    updateRenderer({...renderer, classBreaks: renderer.classBreaks.map(cb => {
                                                        if (cb.percentile === classBreak.percentile) {
                                                            return { ...cb, percentile: o.value };
                                                        } else {
                                                            return cb;
                                                        }
                                                        })});           //updateRenderer({...renderer, providerType: o.value});                                                                             
                                            }} />
                                        </td>
                                        <td  className='app-trip-2-trade-renderer-class-breaks-color'>
                                            <ColorBox height='47px' 
                                                label={translate('fill_color')} 
                                                defaultValue={`rgba(${classBreak.color.r},${classBreak.color.g},${classBreak.color.b},${classBreak.color.a})`} 
                                                value={`rgba(${classBreak.color.r},${classBreak.color.g},${classBreak.color.b},${classBreak.color.a})`} 
                                                editAlphaChannel={true}
                                                onValueChange={(o) => {
                                                    var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(',');     
                                                    var newColor = { r: parseFloat(colors[0]), g:parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) }; 
                                                    handleColorUpdate(classBreak.percentile, newColor) 
                                                    // updateRenderer({...renderer, color: { r: parseFloat(colors[0]), g:parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) }});
                                                    // updateRenderer([..._.cloneDeep(renderer)]);   
                                                }}
                                            />                                            
                                        </td>                            
                                        <td>
                                            <ConfirmButton className='app-trip-2-trade-renderer-class-breaks-action' theme='simple' icon={icons.trash} tooltip={translate('delete')} 
                                                clearText={translate('delete')} onConfirm={()=>{ deleteClassBreak(classBreak.percentile) }} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    })
                }                               
            </div>
        </div>
    </>
}