import { legacyEndpoints } from '../services/legacyEndpoints';
import { translate } from '../utils/translation';
import { sources as sourcesModule} from './sources';
import { layers } from '../components/app/layers/layers'
import { constants } from '../utils/constants';
import { helpers } from '../utils/helpers';

const _ = require('lodash');

export const adHocManagement = {
    supportedTypes: [constants.sqlDataTypes.nVarChar, constants.sqlDataTypes.sqlFloat, constants.sqlDataTypes.dateTime, constants.sqlDataTypes.bit],
    foldersToDelete: [],
    sourcesToDelete: [],
    sourcesToUnload: [],
    hasPublisherAccess: false,
    hasWriteAccess: false,
    getFileManagerFileSystem: async function () {
        var allowSharing = false;

        var fileSystem = []
        var defaultFolder = {
            id: null,
            name: null,
            isDirectory: true,
            isDefault: true,
            ownedByMe: true,
            lastModified: null,
            icon: "folder",
            items: []
        }

        //Get Sharing Permission
        allowSharing = await legacyEndpoints.service({
            name: 'IsUserAllowedToShareImports'
        });

        //Build File Structure
        var adHocs = await legacyEndpoints.service({
            name: 'GetMyAdhocs'
        });
        this.publisherAccess = adHocs.HasPublisherAccess;
        this.hasWriteAccess = adHocs.HasWriteAccess;

        //get folders
        _.each(adHocs.AdHocFolders,
            function (importFolder) {
                
                var folder = {
                    id: importFolder.id,
                    name: importFolder.isShared ? translate('created_by') + " " + importFolder.name : importFolder.name,
                    ownerName: legacyEndpoints.authenticationToken.UserInformation.Name,
                    isDirectory: true,
                    isDefault: importFolder.isDefault,
                    ownedByMe: !importFolder.isShared,
                    lastModified: null,
                    icon: "folder",
                    items: []
                };

                //get adhocs
                _.each(importFolder.adHocs,
                    function (adHoc) {
                        var adHocItem = {
                            ownedByMe: !adHoc.shared,
                            id: adHoc.id,
                            name: adHoc.name,
                            parentFolderName: folder.name,
                            isDirectory: false,
                            isJob: adHoc.isJob,
                            customQueryId: adHoc.customQueryId,
                            ownerGUID: adHoc.ownerGUID,
                            ownerName: adHoc.ownerName,
                            allowBulkDemographics: adHoc.allowBulkDemographics,
                            allowClosestStores: adHoc.allowClosestStores,
                            allowEdit: adHoc.allowEdit,
                            allowExport: adHoc.allowExport,
                            allowMapBooks: adHoc.allowExport,
                            allowDelete: !adHoc.shared,
                            allowSharing: allowSharing,
                            isPersistent: adHoc.isPersistent,
                            publishable: adHoc.publishable,
                            status: adHoc.status,
                            type: adHoc.type,
                            sharingBehavior: adHoc.sharingBehavior,
                            dataSourceType: adHoc.dataSourceType,
                            lastModified: helpers.convertJSONDate(adHoc.createDate),
                            dataSourceTypeLabel: adHoc.dataSourceType == constants.dataSourceType.UserAdHocPoint ? translate("point") : translate("shape"),
                            icon: "file"
                        }
                        
                        if (!adHocItem.isJob) {
                            folder.items.push(adHocItem);

                            if (folder.lastModified === null || adHocItem.lastModified > folder.lastModified)
                                folder.lastModified = adHocItem.lastModified;
                        }
                    }
                );

                if (!folder.ownedByMe) {
                    folder.ownerName = folder.items[0].ownerName;
                    fileSystem.push(folder);
                }
                else if (importFolder.isDefault) {
                    defaultFolder.id = folder.id;
                    defaultFolder.name = folder.name;
                    defaultFolder.ownerImage = folder.ownerImage;
                    defaultFolder.ownerName = folder.ownerName;
                    defaultFolder.items = _.merge(defaultFolder.items, folder.items);
                    defaultFolder.lastModified = folder.lastModified;
                }
                else {
                    defaultFolder.items.push(folder);
                }

            }
        );

        //finish file system
        fileSystem.push(defaultFolder);

        return fileSystem;
    },
    runFileManagerCommand: async function (options) {
        switch (options.command) {
            case "MapData":
                this.mapAdHocLayer(options.object);
                break;
            case "BulkDemos":
                console.log("TODO: BulkDemos");
                break;
            case "ClosestStores":
                console.log("TODO: ClosestStores");
                break;
            case "MapBooks":
                console.log("TODO: MapBooks");
                break;
            case "Share":
                options.setShowSharedUsers({show: true, adHocId: options.object.id});
                break;
            case "Publish":
                console.log("TODO: Publish");
                break;
            case "Edit":
                var metaData = await this.getAdhocDataSourceMetadata({dataSourceId: options.object.id});
                if (_.isNull(metaData.files) || metaData.files.length !== 1) return;

                metaData.files[0].tables[0].dataSource.id = options.object.id;

                options.setPanelContents({ panelId: 4, additionalData: {
                    importedFile: metaData.files[0], 
                    isUpdate: true,
                    isSpatial: options.object.dataSourceType == constants.dataSourceType.UserAdHocShape
                }})
                break;
            case "Export":
                console.log("TODO: Export");
                break;
            case "Symbolize":
                console.log("TODO: Symbolize");
                break;
            default:
                break;
        }
    },
    renameAdhocFolder: function (options) {
        return legacyEndpoints.service({
            name: 'RenameAdhocFolder',
            parameters: {
                Id: options.id,
                Name: options.name
            }
        });
    },
    createAdHocFolder: function (options) {
        return legacyEndpoints.service({
            name: 'CreateAdhocFolder',
            parameters: {
                Name: options.name
            }
        });
    },
    moveAdHocToFolder: function (options) {    
        return legacyEndpoints.service({
            name: 'MoveAdhocToFolder',
            parameters: {
                AdHocIds: options.adHocs,
                FolderId: options.folderId
            }
        })
    },
    deleteAdHocObject: async function(options) {
        if (this.foldersToDelete.length < 1 && this.sourcesToDelete.length < 1)
        {
            //handle folders
            if (options.isDirectory) {
                this.foldersToDelete.push(options.dataItem.id);
            
                _.each(options.dataItem.items, function (item) {
                    if (!item.isDirectory)
                        this.sourcesToUnload.push(item.id);
                })
            }
            else {
                //handle sources
                this.sourcesToDelete.push(options.dataItem.id);
                this.sourcesToUnload.push(options.dataItem.id);
            }

            //wait half a second to see if more delete commands are sent
            var numSourcesToDelete = this.sourcesToDelete.length;
            var numFoldersToDelete = this.foldersToDelete.length;
            await new Promise(resolve => setTimeout(resolve, 500));
            
            while (numSourcesToDelete !== this.sourcesToDelete.length || numFoldersToDelete !== this.foldersToDelete.length)
            {
                //wait until the number of entries to delete stops changing
                numSourcesToDelete = this.sourcesToDelete.length;
                numFoldersToDelete = this.foldersToDelete.length;
                await new Promise(resolve => setTimeout(resolve, 500));
            }
            
            //send deletes to server
            legacyEndpoints.service({
                name: 'DeleteAdhocFoldersAndSources',
                parameters: {
                    FolderIds: this.foldersToDelete,
                    AdhocIds: this.sourcesToDelete,
                    Token: legacyEndpoints.authenticationToken.TokenInformation.Token
                }
            });

            //remove layers from legend
            //todo

            //reset arrays
            this.foldersToDelete = [];
            this.sourcesToDelete = [];
            this.sourcesToUnload = [];
        }
        else
        {
            //handle subsequent folders
            if (options.isDirectory) {
                this.foldersToDelete.push(options.dataItem.id);
            
                _.each(options.dataItem.items, function (item) {
                    if (!item.isDirectory)
                        this.sourcesToUnload.push(item.id);
                })
            }
            else {
                //handle subsequent sources
                this.sourcesToDelete.push(options.dataItem.id);
                this.sourcesToUnload.push(options.dataItem.id);
            }
        }
    },
    mapAdHocLayer: function(options) {
        sourcesModule.refresh({ 
            layers: [
                { 
                    id: options.customQueryId, 
                    text: options.name, 
                    type: options.dataSourceType == constants.dataSourceType.UserAdHocPoint? 0 : 1,  
                    subType: options.dataSourceType == constants.dataSourceType.UserAdHocPoint? 0 : 1, 
                    selected: true, 
                    data: { isCompetitiveInsights: false, isAdhoc: true }
                }
            ],
            onRefresh: (o)=> {
                layers.refreshDataLayers(o);
            }
		});
    },
    getDefaultAdHocTable: function() {
        return {
            name: translate("new_data_source"),
            text: translate("new_data_source"),
            dataSource: {},
            columns: [],
            initialColumns: [],
            firstRowContainsHeader: true,
            isValid: true,
            isPersistent: true,
            autoLoad: true,
            totalRows: 0,
            totalColumns: 0,
            sessionId: crypto.randomUUID(),
            errorMessage: null,
            sampleData: []
        };
    },
    getDefaultAdHocDataSource: function() {
        return {
            addressColumn: null,
            cityColumn: null,
            description: null,
            geocoder: constants.geocoders.noProduct,
            geocodingBehavior: constants.geocodingBehavior.fillIn,
            id: null,
            latitudeColumn: null,
            longitudeColumn: null,
            name: translate("new_data_source"),
            nameColumn: null,
            nullText: translate("null_na"),
            stateColumn: null,
            symbolType: constants.symbols.types.stock,
            symbolUrl: "images/symbols/20x20/cir_blk_20x20.png",
            symbolStroke: 0,
            symbolStrokeWidth: 2,
            symbolStrokeColor: {r:0, g:0, b:0, a:1},
            symbolFillColor: {r:0, g:0, b:0, a:0.3},
            zipColumn: null,
            maxZoom: 3
        }
    },
    createDefaultAdHocColumn: function() {
        var columnName = ("tas_custom_" + helpers.newGuid()).replaceAll("-", "");
        return {
            id: helpers.newGuid(),
            dataType: constants.sqlDataTypes.nVarChar,
            isVisible : true,
            isSystemColumn : false,
            isCustomColumn : true,
            isNew : true, 
            name : columnName,
            supportedDataTypes: this.supportedTypes,
            text : translate("new_column"),
            format : [
                {"Key": "type", "Value": "T"},
                {"Key": "dataType", "Value": "T"},
                {"Key": "precision", "Value": 2},
                {"Key": "symbol", "Value": "$"},
                {"Key": "showCommas", "Value": true},
                {"Key": "multiply", "Value": true},
                {"Key": "sample", "Value": "Sample Text"},
                {"Key": "dBaseValue", "Value": "G"},
                {"Key": "linkText", "Value": "Click Here!"},
                {"Key": "dateFormat", "Value": 1},
                {"Key": "timeFormat", "Value": 1},
                {"Key": "bitFormat", "Value": 2}
              ],
            headerlessName : columnName,
            headerlessText : translate("new_column"),
            headerlessDataType : constants.sqlDataTypes.nVarChar,
            headerlessSupportedDataTypes: this.supportedTypes
        };
    },
    createAdHocTypeDropDownItems: function(isSpatial) {
        var items = [];
        _.each(this.supportedTypes, function(supportedType){
            items.push({key: supportedType, name: constants.getSqlDataTypeNameFromEnum(supportedType)});
        })

        if (isSpatial)
            items.push({key: constants.sqlDataTypes.udt, name: constants.getSqlDataTypeNameFromEnum(constants.sqlDataTypes.udt) });

        return items;
    },
    getMappingsForDataType: function(type) {
        var result = [{key: constants.columnMappingTypes.none, name: constants.getColumnMappingTypeNameFromEnum(constants.columnMappingTypes.none) }];
        switch(type)
        {
            case constants.sqlDataTypes.nVarChar:
                result.push({key: constants.columnMappingTypes.address, name: constants.getColumnMappingTypeNameFromEnum(constants.columnMappingTypes.address)});
                result.push({key: constants.columnMappingTypes.city, name: constants.getColumnMappingTypeNameFromEnum(constants.columnMappingTypes.city)});
                result.push({key: constants.columnMappingTypes.state, name: constants.getColumnMappingTypeNameFromEnum(constants.columnMappingTypes.state)});
                result.push({key: constants.columnMappingTypes.zip, name: constants.getColumnMappingTypeNameFromEnum(constants.columnMappingTypes.zip)});
                return result;
            case constants.sqlDataTypes.sqlFloat:
                result.push({key: constants.columnMappingTypes.latitude, name: constants.getColumnMappingTypeNameFromEnum(constants.columnMappingTypes.latitude)});
                result.push({key: constants.columnMappingTypes.longitude, name: constants.getColumnMappingTypeNameFromEnum(constants.columnMappingTypes.longitude)});
                return result;
            case constants.sqlDataTypes.udt:
                result.push({key: constants.columnMappingTypes.spatial, name: constants.getColumnMappingTypeNameFromEnum(constants.columnMappingTypes.spatial)});
                return result;
            default:
                return result;
        }
    },
    createAdHocDataSource: function(o) {
        o.table.dataSource = o.dataSource;
        o.table.columns = o.columns;
        o.table.totalColumns = o.columns.length;

        var adHocFile = {
            id: crypto.randomUUID(),
            name: o.table.name,
            tables: [o.table],
            type: !_.isEmpty(this.importedType) ? this.importedType : {
                extension: ".csv",
                name: "CSV (Comma delmited}"
            }
        }
        
        return legacyEndpoints.service({
            name: 'CreateAdhocDataSource',
            parameters: {
                AdHocFile: adHocFile
            }
        });
    },
    importAdHocDataSource: function(o) {
        o.table.dataSource = o.dataSource;
        o.table.columns = o.columns;
        o.table.totalColumns = o.columns.length;
        o.file.tables = [o.table];

        return legacyEndpoints.service({
            name: 'ImportAdhocFiles',
            parameters: {
                AdhocFileList: [o.file]
            }
        });
    },
    importSpatialAdHocDataSource: function(o) {
        return legacyEndpoints.service({
            name: 'CreateAdhocDataSourceFromSpatialFiles',
            parameters: {
                JobName: o.name,
                Description: o.description,
                NullText: o.nullText,
                ZoomLevel: o.zoomLevel,
                FileIdList: o.fileIdList
            }
        })
    },
    updateAdHocDataSource: async function(o) {

        var canUpdateResponse = await legacyEndpoints.service({
            name: 'CanUpdateAdhocDataSource',
            parameters: {
                DataSourceId: o.dataSourceId,
                AdHocFile: o.file
            }
        });
        
        switch(canUpdateResponse.status){
            case constants.jobs.statuses.completed:
                legacyEndpoints.service({
                    name: 'UpdateAdhocDataSource',
                    parameters: {
                        DataSourceId: o.dataSourceId,
                        AdHocFile: o.file,
                        Token: legacyEndpoints.authenticationToken.TokenInformation.Token
                    }
                });

                var counter = 0;
                while (counter < 60)
                {
                    await new Promise(resolve => setTimeout(resolve, 5000));
                    counter++;
                    var statusResponse = await legacyEndpoints.service({
                        name: 'GetUpdateAdhocDataSourceStatus',
                        parameters: {
                            DataSourceId: o.dataSourceId
                        }
                    });

                    switch(statusResponse.status)
                    {
                        case constants.jobs.statuses.completed:
                            return statusResponse;
                        case constants.jobs.statuses.failed:
                            alert(statusResponse.errorMessage);
                            return statusResponse;
                        default:
                            break;
                    }
                }
                break;
            case constants.jobs.statuses.failed:
            default:
                alert(canUpdateResponse.errorMessage);
                return canUpdateResponse;
        }
    },
    getAdHocMetadata: async function(o) {
        var response = await legacyEndpoints.service({
            name: 'GetAdhocFileMetadata',
            parameters: {
                FileIdList: o.fileIdList
            }
        });

        _.each(response.files[0].tables[0].columns, function(column){
            column.id = helpers.newGuid();
            
            //change int mapping to float for "number" dropdown
            if (column.dataType === constants.sqlDataTypes.sqlInt)
                column.dataType = constants.sqlDataTypes.sqlFloat;

            //set default text format
            column.format = [
                {"Key": "type", "Value": "T"},
                {"Key": "dataType", "Value": "T"},
                {"Key": "precision", "Value": 2},
                {"Key": "symbol", "Value": "$"},
                {"Key": "showCommas", "Value": true},
                {"Key": "multiply", "Value": true},
                {"Key": "sample", "Value": "Sample Text"},
                {"Key": "dBaseValue", "Value": "G"},
                {"Key": "linkText", "Value": "Click Here!"},
                {"Key": "dateFormat", "Value": 1},
                {"Key": "timeFormat", "Value": 1},
                {"Key": "bitFormat", "Value": 2}
              ]
        });

        this.autoMapColumns({table: response.files[0].tables[0]});

        return response;
    },
    getAdhocDataSourceMetadata: async function(o) {
        var response = await legacyEndpoints.service({
            name: 'GetAdhocDataSourceMetadata',
            parameters: {
                DataSourceId: o.dataSourceId
            }
        });

        //adjust column mappings to valid types (mostly for spatial imported sources) and add id
        _.each(response.files[0].tables[0].columns, function(column){
            column.id = helpers.newGuid();
            
            if (column.dataType === constants.sqlDataTypes.sqlInt)
                column.dataType = constants.sqlDataTypes.sqlFloat;

                if (column.headerlessDataType === constants.sqlDataTypes.sqlInt)
                column.headerlessDataType = constants.sqlDataTypes.sqlFloat;

            if (column.dataType === constants.sqlDataTypes.uniqueIdentifier)
                column.dataType = constants.sqlDataTypes.nVarChar;

            if (column.headerlessDataType === constants.sqlDataTypes.uniqueIdentifier)
                column.headerlessDataType = constants.sqlDataTypes.nVarChar;
        });

        return response;
    },
    autoMapColumns: function(o){
        if (o.table.firstRowContainsHeader) {
            if (!o.table.nameColumnIsDirty) {
                o.table.dataSource.nameColumn = _.find(o.table.initialColumns, function (item) { return item.text.toLowerCase().indexOf("name") > -1 && item.dataType === constants.sqlDataTypes.nVarChar; });
                if (!_.isNull(o.table.dataSource.nameColumn) && !_.isUndefined(o.table.dataSource.nameColumn))
                    o.table.dataSource.nameColumn = o.table.dataSource.nameColumn.name;
                else
                    o.table.dataSource.nameColumn = null;
            }

            if (!o.table.dataSource.addressColumnIsDirty) {
                o.table.dataSource.addressColumn = _.find(o.table.initialColumns, function (item) { return item.text.toLowerCase().indexOf("address") > -1 && item.dataType === constants.sqlDataTypes.nVarChar; });
                if (!_.isNull(o.table.dataSource.addressColumn) && !_.isUndefined(o.table.dataSource.addressColumn))
                    o.table.dataSource.addressColumn = o.table.dataSource.addressColumn.name;
                else
                    o.table.dataSource.addressColumn = null;
            }

            if (!o.table.dataSource.cityColumnIsDirty) {
                o.table.dataSource.cityColumn = _.find(o.table.initialColumns, function (item) { return item.text.toLowerCase().indexOf("city") > -1 && item.dataType === constants.sqlDataTypes.nVarChar; });
                if (!_.isNull(o.table.dataSource.cityColumn) && !_.isUndefined(o.table.dataSource.cityColumn))
                    o.table.dataSource.cityColumn = o.table.dataSource.cityColumn.name;
                else
                    o.table.dataSource.cityColumn = null;
            }

            if (!o.table.dataSource.stateColumnIsDirty) {
                o.table.dataSource.stateColumn = _.find(o.table.initialColumns, function (item) { return item.text.toLowerCase().indexOf("state") > -1 && item.dataType === constants.sqlDataTypes.nVarChar; });
                if (!_.isNull(o.table.dataSource.stateColumn) && !_.isUndefined(o.table.dataSource.stateColumn))
                    o.table.dataSource.stateColumn = o.table.dataSource.stateColumn.name;
                else
                    o.table.dataSource.stateColumn = null;
            }

            if (!o.table.dataSource.zipColumnIsDirty) {
                o.table.dataSource.zipColumn = _.find(o.table.initialColumns, function (item) { return item.text.toLowerCase().indexOf("zip") > -1 && (item.dataType === constants.sqlDataTypes.nVarChar || item.dataType === constants.sqlDataTypes.sqlFloat || item.dataType === constants.sqlDataTypes.sqlInt); });
                if (!_.isNull(o.table.dataSource.zipColumn) && !_.isUndefined(o.table.dataSource.zipColumn))
                    o.table.dataSource.zipColumn = o.table.dataSource.zipColumn.name;
                else
                    o.table.dataSource.zipColumn = null;
            }

            var floatColumns = _.filter(o.table.initialColumns, function(o){ return o.dataType === constants.sqlDataTypes.sqlFloat });

            if (!o.table.dataSource.latitudeColumnIsDirty) {
                o.table.dataSource.latitudeColumn = _.find(floatColumns, function (item) { return item.dataType === constants.sqlDataTypes.sqlFloat && (item.text.toLowerCase().indexOf("latitude") > -1 || item.text.toLowerCase().startsWith("lat")); });
                if (!_.isNull(o.table.dataSource.latitudeColumn) && !_.isUndefined(o.table.dataSource.latitudeColumn))
                    o.table.dataSource.latitudeColumn = o.table.dataSource.latitudeColumn.name;
                else
                    o.table.dataSource.latitudeColumn = null;
            }

            if (!o.table.dataSource.longitudeColumnIsDirty) {
                o.table.dataSource.longitudeColumn = _.find(floatColumns, function (item) { return item.dataType === constants.sqlDataTypes.sqlFloat && (item.text.toLowerCase().indexOf("longitude") > -1 || item.text.toLowerCase().startsWith("lon") || item.text.toLowerCase().indexOf("lng") > -1); });
                if (!_.isNull(o.table.dataSource.longitudeColumn) && !_.isUndefined(o.table.dataSource.longitudeColumn))
                    o.table.dataSource.longitudeColumn = o.table.dataSource.longitudeColumn.name;
                else
                    o.table.dataSource.longitudeColumn = null;
            }
        }
        else {
            if (!o.table.dataSource.nameColumnIsDirty) {
                o.table.dataSource.nameColumn = _.find(o.table.initialColumns, function (item) { return item.headerlessText.toLowerCase().indexOf("name") > -1 && item.headerlessDataType === constants.sqlDataTypes.nVarChar; });
                if (!_.isNull(o.table.dataSource.nameColumn) && !_.isUndefined(o.table.dataSource.nameColumn))
                    o.table.dataSource.nameColumn = o.table.dataSource.nameColumn.headerlessName;
                else
                    o.table.dataSource.nameColumn = null;
            }

            if (!o.table.dataSource.addressColumnIsDirty) {
                o.table.dataSource.addressColumn = _.find(o.table.initialColumns, function (item) { return item.headerlessText.toLowerCase().indexOf("address") > -1 && item.headerlessDataType === constants.sqlDataTypes.nVarChar; });
                if (!_.isNull(o.table.dataSource.addressColumn) && !_.isUndefined(o.table.dataSource.addressColumn))
                    o.table.dataSource.addressColumn = o.table.dataSource.addressColumn.headerlessName;
                else
                    o.table.dataSource.addressColumn = null;
            }

            if (!o.table.dataSource.cityColumnIsDirty) {
                o.table.dataSource.cityColumn = _.find(o.table.initialColumns, function (item) { return item.headerlessText.toLowerCase().indexOf("city") > -1 && item.headerlessDataType === constants.sqlDataTypes.nVarChar; });
                if (!_.isNull(o.table.dataSource.cityColumn) && !_.isUndefined(o.table.dataSource.cityColumn))
                    o.table.dataSource.cityColumn = o.table.dataSource.cityColumn.headerlessName;
                else
                    o.table.dataSource.cityColumn = null;
            }

            if (!o.table.dataSource.stateColumnIsDirty) {
                o.table.dataSource.stateColumn = _.find(o.table.initialColumns, function (item) { return item.headerlessText.toLowerCase().indexOf("state") > -1 && item.headerlessDataType === constants.sqlDataTypes.nVarChar; });
                if (!_.isNull(o.table.dataSource.stateColumn) && !_.isUndefined(o.table.dataSource.stateColumn))
                    o.table.dataSource.stateColumn = o.table.dataSource.stateColumn.headerlessName;
                else
                    o.table.dataSource.stateColumn = null;
            }

            if (!o.table.dataSource.zipColumnIsDirty) {
                o.table.dataSource.zipColumn = _.find(o.table.initialColumns, function (item) { return item.headerlessText.toLowerCase().indexOf("zip") > -1 && (item.headerlessDataType === constants.sqlDataTypes.nVarChar || item.headerlessDataType === constants.sqlDataTypes.sqlFloat || item.headerlessDataType === constants.sqlDataTypes.sqlInt); });
                if (!_.isNull(o.table.dataSource.zipColumn) && !_.isUndefined(o.table.dataSource.zipColumn))
                    o.table.dataSource.zipColumn = o.table.dataSource.zipColumn.headerlessName;
                else
                    o.table.dataSource.zipColumn = null;
            }

            var myFloatColumns = _.filter(o.table.initialColumns, function(o){ return o.headerlessDataType === constants.sqlDataTypes.sqlFloat });

            if (!o.table.dataSource.latitudeColumnIsDirty) {
                o.table.dataSource.latitudeColumn = _.find(myFloatColumns, function (item) { return item.headerlessDataType === constants.sqlDataTypes.sqlFloat && (item.headerlessText.toLowerCase().indexOf("latitude") > -1 || item.headerlessText.toLowerCase().startsWith("lat")); });
                if (!_.isNull(o.table.dataSource.latitudeColumn) && !_.isUndefined(o.table.dataSource.latitudeColumn))
                    o.table.dataSource.latitudeColumn = o.table.dataSource.latitudeColumn.headerlessName;
                else
                    o.table.dataSource.latitudeColumn = null;
            }

            if (!o.table.dataSource.longitudeColumnIsDirty) {
                o.table.dataSource.longitudeColumn = _.find(myFloatColumns, function (item) { return item.headerlessDataType === constants.sqlDataTypes.sqlFloat && (item.headerlessText.toLowerCase().indexOf("longitude") > -1 || item.headerlessText.toLowerCase().startsWith("lon") || item.text.toLowerCase().indexOf("lng") > -1); });
                if (!_.isNull(o.table.dataSource.longitudeColumn) && !_.isUndefined(o.table.dataSource.longitudeColumn))
                    o.table.dataSource.longitudeColumn = o.table.dataSource.longitudeColumn.headerlessName;
                else
                    o.table.dataSource.longitudeColumn = null;
            }
        }
    }
}

