// React imports
import { useEffect, useState } from 'react';

// App imports
import { DropDown } from '../../../base/dropDown/dropDown';
import { translate } from '../../../../utils/translation';
import { TextBox } from '../../../base/textBox/textBox';
import { TextArea } from '../../../base/textArea/textArea';
import { errorToast } from '../../../base/toast/toast';
import { trip2Trade as trip2TradeModule } from '../../../../modules/trip2Trade';

const _ = require('lodash');

export function CreateRendererStep1({trip2TradeJob,renderer,updateRenderer,onReadyNext}){

    useEffect(() => {
        if (onReadyNext)
            onReadyNext(renderer.name != '' && isNameValid(renderer.name)); //todo replace with validation controls

    }, [onReadyNext])    

    var providerTypes = [
        { name: translate('consumer'), id: 1},
        { name: translate('fleet'), id: 2},
        { name: translate('both'), id: 3}
    ];
    
    var gridTypes = [
        { name: translate('hex'), id: 0},
        { name: translate('quadkey'), id: 1}
    ];  

    var gridSizes = trip2TradeModule.getRendererGridSizeData();

    var visualizationTypes = [
        { name: translate('grid'), id: 1},
        { name: translate('merged_grid'), id: 2} 
    ];       
    
    var isNameValid = (rendererName) =>{
        return trip2TradeJob && !trip2TradeJob.renderers.some(x => x.name === rendererName && x.name !== renderer.name);
    };    

	return <> 

        <div className='app-trip-2-trade-body'>
            <div>            
                <TextBox className='app-trip-2-trade-renderer-wizard-name' height='47px' label={translate('name')} value={renderer.name}
                    onChange={e => {            
                            if(!isNameValid(e.value))
                                errorToast(translate('renderer_name_already_exists'));
                            updateRenderer({...renderer, name: e.value});
                    } }                 
                />
                <TextArea className='app-trip-2-trade-renderer-wizard-description' minHeight='100px' maxHeight='165px' label={translate('description')} value={renderer.description} 
                    onChange={(e) => { 
                        updateRenderer({...renderer, description: e.value});
                    } } 
                />      
            </div> 
            <div> 
                <DropDown className='app-trip-2-trade-renderer-wizard-provider-type' 
                            height='47px' 
                            selected={renderer.providerType} 
                            items={providerTypes}
                            label={translate('provider_type')}  
                            display='name' 
                            valueProperty='id' 
                            onChange={(o) => { 
                                updateRenderer({...renderer, providerType: o.value});                       
                            }}/>                                                                 
            </div>              
            <div>               
                <DropDown className='app-trip-2-trade-renderer-wizard-grid-type' 
                            height='47px' 
                            selected={renderer.gridType} 
                            items={gridTypes}
                            label={translate('grid_type')}  
                            display='name' 
                            valueProperty='id' 
                            onChange={(o) => { 
                                updateRenderer({...renderer, gridType: o.value});                       
                            }}                            
                            />                    

                <DropDown className='app-trip-2-trade-renderer-wizard-grid-size' 
                            height='47px' 
                            items={gridSizes} 
                            selected={renderer.gridSizeId.toUpperCase()} 
                            label={translate('grid_size')} 
                            display='name' 
                            valueProperty='id'
                            onChange={(o) => { 
                                updateRenderer({...renderer, gridSizeId: o.value.toLowerCase()});                        
                            }}                                  
                            />                                               
            </div>     
            <div> 
                <DropDown className='app-trip-2-trade-renderer-wizard-visualization-type' 
                            height='47px' 
                            selected={renderer.visualizationType} 
                            items={visualizationTypes}
                            label={translate('visualization_type')}  
                            display='name' 
                            valueProperty='id' 
                            onChange={(o) => { 
                                updateRenderer({...renderer, visualizationType: o.value});                     
                            }}                                  
                            />                                                                 
            </div>                 

        </div>
    </>
}