// React imports
import { useState, useEffect } from 'react';

// App imports
import { SelectableItems } from '../../../base/selectableItems/selectableItems';
import { CollapsibleSection } from '../../../base/collapsibleSection/collapsibleSection';
import { OptionSelector } from '../../../base/optionSelector/optionSelector';
import { DropDown } from '../../../base/dropDown/dropDown';
import { Bar } from '../../../base/bar/bar';
import { Icon } from '../../../base/icon/icon';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { constants } from '../../../../utils/constants'
import { map } from '../../map/map';

export function MobilityReportsStep1({reportTypes, reportType, updateReportType, tradeAreas, tradeArea, updateTradeArea, dataSources, updateDataSources, dataSource, updateDataSource, timeOfDay, updateTimeOfDay, onReadyNext}) {

    const reportTypeMessages = [ 
        translate('mobility_report_type_message_1'), 
        translate('mobility_report_type_message_2'), 
        translate('mobility_report_type_message_3'),
        translate('mobility_report_type_message_4')
    ]

    const [reportTypeMessageIndex, setReportTypeMessageIndex] = useState(0);
    const [disableTradeAreas, setDisableTradeAreas] = useState(false);

    useEffect(() => {

        if (reportType >= 0) {
            setDisableTradeAreas(reportTypes[reportType].IsCurrentViewOnly);

            if (reportTypes[reportType].IsCurrentViewOnly)
                setReportTypeMessageIndex(1);
            else if (!reportTypes[reportType].IsCurrentViewAllowed && map.layers.filter(layer => layer.type === constants.layers.types.tradeArea).length == 0)
                setReportTypeMessageIndex(2);
            else
                setReportTypeMessageIndex(3);
        }
        else {
            setDisableTradeAreas(false);
            setReportTypeMessageIndex(0);
        }

        if (onReadyNext)
            onReadyNext(reportType != -1 && tradeArea != null && dataSource != null && timeOfDay != -1);

    }, [onReadyNext, reportTypes, reportType, tradeArea, dataSource, timeOfDay])

    const selectDataSource = (o) =>{
        updateDataSource(o.selected ? o.item.id : null);

        const newDataSources = dataSources.map(newSource => ({
            ...newSource,
            sources: newSource.sources.map(src => {
                    if (src.id !== o.item.id)
                        return {...src, selected: false };
                    else
                        return {...src, selected: o.selected };
                })
            }
        ));

        updateDataSources(newDataSources);        
    };

    return <>
        <div className='app-mobility-data-step-1-container'>
            <div className='app-mobility-data-labels'>{translate('select_report_type')}</div>
            <div className='app-mobility-data-report-rows'>
                {
                    reportTypes.map((report, i) => {
                        return (
                            <div key={i} className='app-option-selector'>
                                {reportType === i ? 
                                <div className='app-option-selector-check'>
                                    <Icon icon={icons.check} />
                                </div> : ''}
                                <Bar className={'app-mobility-data-bar-buttons app-mobility-data-bar-long'} 
                                    icon={icons.mobile}
                                    text={report.Name} 
                                    active={reportType === i} 
                                    onClick={() => { updateReportType(reportType === i ? -1 : i); }} 
                                    disabled={false} 
                                /> 
                            </div>
                        );
                    })
                }
            </div>
            <div className={reportTypeMessageIndex === 2 ? 'app-mobility-data-labels app-mobility-data-warning' : 'app-mobility-data-labels'}>
                {reportTypeMessages[reportTypeMessageIndex]}
            </div>
            <div className='app-mobility-data-report-rows'>
                <div className='app-mobility-data-inputs'>
                    <DropDown
                        items={tradeAreas} 
                        height={'55'} 
                        display={'text'} 
                        valueProperty={'id'} 
                        selected={tradeArea}
                        disabled={disableTradeAreas}
                        onChange={(o) => { updateTradeArea(o.value); }} 
                    />
                </div>
            </div>
            <div className='app-mobility-data-labels'>{translate('select_time_of_day')}</div>
            <div className='app-mobility-data-report-rows'>
                {
                    [
                        { icon: icons.sun, text: translate('day') },
                        { icon: icons.moon, text: translate('night') },
                    ].map((dayPart, i) => {
                        return (
                            <div key={i} className='app-option-selector'>
                                {timeOfDay === i ? 
                                <div className='app-option-selector-check'>
                                    <Icon icon={icons.check} />
                                </div> : ''}
                                <Bar className={'app-mobility-data-bar-buttons app-mobility-data-bar-short'} 
                                    icon={dayPart.icon}
                                    text={dayPart.text} 
                                    active={timeOfDay === i} 
                                    onClick={() => { updateTimeOfDay(timeOfDay === i ? -1 : i); }} 
                                    disabled={false} 
                                /> 
                            </div>
                        )
                    })
                }
            </div>
            <div className='app-mobility-data-report-columns'>
                <SelectableItems
                    items={dataSources}
                    selectedItems={dataSources.map((item) => { return item.sources.filter(x => x.selected) }).filter(item => item.length > 0).flat()}
                    onSelect={(o) => { updateDataSources([...o.items]); }}
                    onRender={(o) => {
                        return <div className='app-mobility-data-rows-container'>
                        {
                            o.items.map((group, i) => {
                                return <CollapsibleSection key={i} text={group.text} count={group.sources.length} selectedCount={group.sources.filter(x => x.selected).length}>
                                {
                                    group.sources.map((item, j) =>{
                                        return <OptionSelector key={j} text={item.text} icon={icons.shoppingBag} active={item.selected} onClick={() =>{ 
                                            selectDataSource({ item: item, selected: !item.selected }); 
                                        }}/>
                                    })
                                }
                                </CollapsibleSection>
                            })
                        }
                        </div>
                    }}
                    hideFooter={true}
                />
            </div>
        </div>
    </>
}