import { useCallback, useEffect, useState } from 'react';

// Third party imports
import SelectBox from 'devextreme-react/select-box';
import DataSource from 'devextreme/data/data_source';

export function DropDown({id, className, items, display, valueProperty, selected, searchEnabled = false, label, disabled, width, height, itemRender, fieldRender, onChange, onSelect, group = 'group', grouped = false, placeholder, inputAttr = {}}) {

    var classes = ['app-drop-down'];

    if (className)
        classes.push(className);

    var dataSource = items;

    if (grouped)
        dataSource = new DataSource({
            store: {
                type: 'array',
                data: items,
                key: valueProperty,
            },
            group: group,
        });

    return <div className={classes.join(' ')}>
        <SelectBox dataSource={dataSource}
            wrapItemText={true}
            id={id ? id : null}
            searchEnabled={searchEnabled}
            displayExpr={display}
            valueExpr={valueProperty}
            placeholder={placeholder}
            value={selected}
            label={label}
            stylingMode='outlined'
            inputAttr={inputAttr}
            disabled={disabled}
            grouped={grouped}
            group={grouped ? group : null}
            width={width? width : undefined}
            height={height? height : '30px'}
            dropDownOptions={{ wrapperAttr: { class: 'app-drop-down-item-disabled' }}}
            itemRender={itemRender? itemRender : null}
            fieldRender={fieldRender? fieldRender : null}
            onValueChanged={(e)=>{
                if (e.value == null || JSON.stringify(e.value) === JSON.stringify(e.previousValue))
                    return

                if (onChange)
                    onChange({
                        value: e.value,
                        userChanged: e.event !== undefined
                    });
            }}
            onSelectionChanged={(e)=>{
                if (onSelect)
                    onSelect({
                        value: e.selectedItem
                    });
            }}
        />
    </div>    
}