export function Hideable ({ className, hide, style, children}){

    var classes = [];
    
    if (className)
        classes.push(className);

    if (hide)
        classes.push('app-hideable-hidden');

	return <div className={classes.join(' ')} style={style}>
        {children}
    </div>
}