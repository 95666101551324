// App imports
import { ParcelForm } from '../components/app/forms/parcelForm/parcelForm';
import { activityHub } from '../components/app/activityHub/activityHub';
import { constants } from '../utils/constants';
import { translate } from '../utils/translation';
import { helpers } from '../utils/helpers';
import { legacyEndpoints } from '../services/legacyEndpoints';

const _ = require("lodash");

export const parcel = {
    parcelInformation: (o) =>{

        var highlights = [];

        activityHub.open({
            entity: { text: `${translate('loading')}...`, location: o.location },
            type: 'parcel',
            renderContent: async () =>{

                return [
                    { id: constants.activityHub.actions.information, component: <ParcelForm location={o.location} onOpen={(p) => { 
                            highlights = p.highlights; 
                        }} /> 
                    },
                    { id: constants.activityHub.actions.download, onClick: ()=>{
                            legacyEndpoints.service({
                                name: 'DownloadParcelInformation',
                                parameters: {
                                    latitude: o.location.lat,
                                    longitude: o.location.lon
                                },
                                success: function (results) {
                                    helpers.navigateToUrl(legacyEndpoints.handlers.getFileUrl({ id: results }));
                                }
                            });
                        }    
                    }
                ];

            },
            onClose: () =>{
                if (highlights.length === 0)
                    return;

                highlights.forEach(highlight => {
                    highlight.dispose();
                });
            }        
        });

    },
    getParcelInformation: async function (o) {      
    
        return(await legacyEndpoints.service({
            name: 'GetParcelInformation',
            parameters: {
                latitude: o.latitude,
                longitude: o.longitude
            }
        }));     

    }
}