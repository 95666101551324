import { useEffect, useState } from 'react';

import { Bar } from '../../../base/bar/bar';
import { InputForm } from '../../forms/inputForm/inputForm';
import { Button } from '../../../base/button/button';
import { RadioButtonSet } from '../../../base/radioButtonSet/radioButtonSet';
import { Loader } from '../../../base/loader/loader';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { constants } from '../../../../utils/constants';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { Description } from '../../../base/description/description';
import { TextBox } from '../../../base/textBox/textBox';
import { forms as formModule } from '../../../../modules/forms';

const _ = require("lodash");

export function ModelWizardStep2({ projection, mode, generateMode, setGenerateMode, locationResult, existingResult, onUpdate, generating, openProposedLayerResult, setOpenProposedLayerResult, zoomToProposedResult, setZoomToProposedResult, jobName, setJobName }){

    const [form, setForm] = useState(null);
	const [collapsedForm, setCollapsedForm] = useState(null); 

    useEffect(() =>{
		(async ()=>{
			
			var form = null;
			
			if (projection.type === constants.projections.relocation)
				form = await legacyEndpoints.service({
					name: 'GetAnalyticsProjectionEditForm',
					parameters: {
						projectionId: projection.id,
						customQueryId: existingResult.QueryID, 
						pointId: existingResult.PointID
					}
				});
			else
				switch(mode){
					case constants.projections.modes.new:
						form = await legacyEndpoints.service({
							name: 'GetAnalyticsProjectionProposedSiteForm',
							parameters: {
								projectionId: projection.id,
								lat: locationResult.location.lat, 
								lon: locationResult.location.lon
							}
						});							
						break;
					case constants.projections.modes.existing:
						form = await legacyEndpoints.service({
							name: 'GetAnalyticsProjectionEditForm',
							parameters: {
								projectionId: projection.id,
								customQueryId: existingResult.QueryID, 
								pointId: existingResult.PointID
							}
						});
						break;
				}
							
			setForm(form);
			setCollapsedForm(formModule.collapseForm({form: form}));
		})();
    }, [projection]);

	return <div className='modelWizardStep2 app-element'>
		<Loader loaded={form !== null} />
		<Loader theme='generate' loaded={generating===false} />
		<div className='modelWizardHeader'>
		{			
            projection.type === constants.projections.interactive && projection.providerType === constants.projections.providers.intalytics ? 
			<>
				{projection.hasRecaptureModel === true ? '' : <Description className='modelWizardStep2Description' description={translate('interactive_description')} />}
				<RadioButtonSet className='modelWizardStep1Mode'>
					<Button theme='secondary' size='medium' text={translate('one_click')} active={generateMode===constants.projections.oneClick} icon={icons.boltLightning} onClick={()=>{setGenerateMode(constants.projections.oneClick)}} />
					<Button theme='secondary' size='medium' text={translate('interactive')} active={generateMode===constants.projections.interactive} icon={icons.chartMixed} onClick={()=>{setGenerateMode(constants.projections.interactive)}} />
				</RadioButtonSet>
			</>            
            : <Description className='modelWizardStep2Description' description={translate('one_click_description')} />
        }		
		</div>
		{
			mode === constants.projections.modes.new && projection.providerType === constants.projections.providers.intalytics ?
			<Bar className='modelWizardStep2Options' icon={icons.gear} text={translate('options')} opened={false}>
				<table className='modelWizardStep2OptionsTable'>
					<tr>
						<td>
							<b>{translate('zoom_to_result')}:</b>
						</td>
						<td>
							<Button theme='secondary' size='small' icon={icons.check} text={translate('zoom_to_result')} active={zoomToProposedResult === true } onClick={() => {setZoomToProposedResult(!zoomToProposedResult);}} />
						</td>
					</tr>
					<tr>
						<td>
							<b>{translate('open_result_layer')}:</b>
						</td>
						<td>
							<Button theme='secondary' size='small' icon={icons.check} text={translate('open_result_layer')} active={openProposedLayerResult === true } onClick={() => {setOpenProposedLayerResult(!openProposedLayerResult);}} />
						</td>
					</tr>
				</table>
			</Bar>			
			 : ''
		}
		{
			projection.hasRecaptureModel === true ? 
			<Description className='modelWizardStep2DescriptionRecap'  description={translate('wizard_recap_text')} />
			:
			<>
				<Bar className='modelWizardStep2Options' icon={icons.text} text={translate('job_name')} opened={true}>
					<TextBox value={jobName} onChange={(o) => {setJobName(o.value)}} />
				</Bar>
				<Bar className='modelWizardStep2SiteForm' icon={icons.rectangleList} text={translate('site_characteristics')} opened={true}>
					<InputForm form={form} collapsedForm={collapsedForm} forceChange={true} flatten={true} onUpdate={onUpdate} locationResult={locationResult} />
				</Bar>
			</>			
		}
		{console.log("Form: ", form, "Collapsed Form: ", collapsedForm)}
    </div>;
}