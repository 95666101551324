// React imports
import { useState } from 'react'

// Third party imports
import Draggable from 'react-draggable';

// App imports
import { Hideable } from '../../base/hideable/hideable';
import { SaveMap } from './saveMap';
import { Button } from '../../base/button/button';
import { translate } from '../../../utils/translation';
import { icons } from '../../base/icon/icon';

const _ = require("lodash");

export var saveMapContainer;

export function SaveMapContainer() {
    
    const [open, setOpen] = useState(false);    
    const [hide, setHide] = useState(false);     


    saveMapContainer = {
            open: (o) =>{
                setOpen(true);
        },
        hide: () =>{
            setHide(true);
        },
        show: () =>{
            setHide(false);
        }          
    };

    function onSave() {
     
    };  

    function onClose() {
        setOpen(false);
    };

    return <>
    {
        open ?
        <Hideable hide = {hide}>
          <Draggable handle=".app-save-map-title">
                <div className='app-save-map'>
                    <div className='app-save-map-title'>
                        {translate('save_map')}
                        <Button className='app-save-map-close' theme='action' icon={icons.x} tooltip={translate('close')} onClick={()=>{onClose() }}/>
                    </div>                
                    <SaveMap onClose={onClose}  />
                </div>                     
            </Draggable>
        </Hideable>
        : ''
    }
    </>

  
}