// React imports
import { useState } from 'react';

// App imports
import { Button } from '../../../base/button/button'
import { SelectableItems } from '../../../base/selectableItems/selectableItems';
import { Panel } from '../../../base/panel/panel';
import { Bar } from '../../../base/bar/bar';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';

const _ = require("lodash");

export function LayersMenu({text,icon,theme,size,disabled,notLicensed,onSave,onSelect,onLoad,onLoadRender,getSelected,showTags,toolbar=()=>{},onDelete}) {

	const [active, setActive] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [items, setItems] = useState([]);
	const [originalItems, setOriginalItems] = useState([]);

	const open = () =>{

		setActive(true);
	
		if (loaded)
			setSelected({ items: items });
		else
			load();
	};

	const createFolderStructure = (o) =>{
		var items = [];

		o.forEach((item) => {
			var pointDataSourceFilterList = [];

			if (_.isArray(item.PointDataSourceFilterList))
			{
				_.each(item.PointDataSourceFilterList, (filter) => {
					pointDataSourceFilterList.push({ id: filter });
				});
			}

			var parent = items.find((val) => val.id === item.ApplicationSymbology.Id);
			var child = {
				id: item.Id,
				text: item.Name,
				type: item.Type,
				subType: _.isNumber(item.SubType) ? item.SubType : item.DataType,
				data: {
					isCompetitiveInsights: item.IsCompetitiveInsights,
					isAdhoc: item.IsAdhoc,
					isCustomerMap: item.IsCustomerMap,
					isDynamic: item.IsDynamic,
					isTile: item.IsTile,
					pointDataSourceFilterList: pointDataSourceFilterList,
					hasLabel: item.HasLabel,
					useBoundingBoxTiles: item.UseBoundingBoxTiles,
					isLabeled: item.IsLabeled,
					cluster: _.isBoolean(item.Cluster) ? item.Cluster : false
				}
			};

			if (parent)
				parent.items.push(child);
			else
				items.push({
					id: item.ApplicationSymbology.Id,
					text: item.ApplicationSymbology.Title,
					icon: item.ApplicationSymbology.TASOnline,
					type: 0,
					items: [child]
				});
		});

		return items;
	};

	const setSelected = (o)=>{		
		var selected = _.isFunction(getSelected) ? getSelected() : [];
				
		if (_.isArray(o.items) && o.items.length > 0 && _.isArray(o.items[0].items))
			o.items.forEach(item =>{
				item.items.forEach(item =>{					
					item.selected = selected.find(x => x === item.id) !== undefined;
				});
			});		

		setItems([...o.items]);
		setOriginalItems([..._.cloneDeep(o.items)]);
	};	

	const load = async ()=>{
		setLoaded(false);
		setSelected({ items: _.isFunction(onLoadRender) ? onLoadRender(await onLoad()) : createFolderStructure(await onLoad())});
		setLoaded(true);
	};

	const select = (o) =>{
		
		if (_.isFunction(onSelect))
			setItems([...onSelect({
				items: items,
				item: o.item
			})]);
			
		o.item.selected = o.selected;
		setItems([...items]);			
    };

	const save = (o)=>{

		if (o.items.length === 0)
			return;

		setActive(false);
		onSave({items: o.items});
	};

	const close = ()=>{
		setActive(false);
		setItems([...originalItems]);
	};	

	return <>
		<Button className='app-legend-menu-button' theme={theme ?? 'secondary'} size={size ?? 'small'} icon={icon} tooltip={text} notLicensed={notLicensed} disabled={disabled ?? false} onClick={() => {open();}}/>
		<Panel text={text} open={active} loaded={loaded} onReload={() => { load(); }} onClose={() => { close(); }}>
			<SelectableItems
				items={items}
				selectedItems={ items.map((item) => { return _.isArray(item.items) ? item.items.filter(x => x.selected) : item.selected ? [item] : [] }).filter(item => item.length > 0).flat()}
				onSelect={(o) => { setItems([...o.items]); }}
				maxTagWidth={195}
				showTags={showTags}
				toolbar={toolbar({
					reload: () => { load() }
				})}
				onRender={(o) => {
					return o.items.map((item,i) => {
						if (_.isArray(item.items))
							return <Bar key={i} text={item.text} icon={item.icon}>
							{
								item.items.map((item, j) => {
									return <Bar
										key={j}
										className='app-legend-menu-item'
										text={item.text}
										active={item.selected}
										actions={[
											{ icon: icons.infoCircle, tooltip: translate('information'), disabled: true },
											{ icon: item.selected ? icons.x : icons.plus, tooltip: item.selected ? translate('remove') : translate('add'), default: true }
										]}
										onClick={()=>{select({ item: item, selected: !item.selected });}}
									/>
								})
							}
							</Bar>
						else
							return <Bar 
								key={i} 
								className='app-legend-menu-item' 
								text={item.text} 
								iconClass={item.iconClass} 
								icon={item.icon} 
								active={item.selected} 
								onClick={()=>{select({ item: item, selected: !item.selected });}}
								actions={[
									{ icon: icons.trash, tooltip: translate('delete'), onClick: () => { onDelete(item); load();  } }
								]}								
							/>
					})
				}}
				onSave={(o) =>{
					save(o);
				}}
				onClose={() =>{
					close();
				}}
			/>
		</Panel>
	</>
}