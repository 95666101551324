// React imports
import { useState, useEffect } from 'react'


// App imports
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { TextBox } from '../../../base/textBox/textBox';
import { TextArea } from '../../../base/textArea/textArea';
import { multimedia as multimediaModule } from '../../../../modules/multimedia';

const _ = require("lodash");

export var saveMap;

export function UrlEdit({entity,onClose}) {
    
    const defaultURL = {
                                sourceId : entity.id,
                                dataSourceId: entity.layer.parentId,
                                ManagerType: 0,
                                name: '', 
                                description: '', 
                                url: '',
                                hashtags: '',
                                placeHolders: ''
                            };                              

    const [url, setURL] = useState(defaultURL);

    const onSave = async (o) =>{
        await multimediaModule.addURL(url);
        onClose();
    }    

    function close() {
        setURL(defaultURL);
        onClose();
    };

    return <>
                <div className='app-multimedia-url-edit'>
                    <div className='app-multimedia-url-edit-actions-rows app-multimedia-header'>
                        <Button className='app-multimedia-url-edit-button' theme='secondary' tooltip={translate('close')} size='small' icon={icons.arrowTurnDownLeft} onClick={()=>{close() }} />	                    
                        <Button className='app-multimedia-url-edit-button' theme='primary' tooltip={translate('save')} size='small' icon={icons.save} onClick={()=>{onSave() }} />	
                    </div>                    
                    
                    <div className='app-multimedia-url-edit-rows'>
                        <TextBox className='app-multimedia-url-edit-inputs' height='47px' label={translate('name')} value={url.name} onChange={(o) => { setURL({ ...url, name: o.value }); }}  />                        
                        <TextBox className='app-multimedia-url-edit-inputs' height='47px' label={translate('hashTags')} value={url.hashtags} onChange={(o) => { setURL({ ...url, hashtags: o.value }); }}  />
                        <TextBox className='app-multimedia-url-edit-inputs' height='47px' label={translate('placeHolders')} value={url.placeHolders} onChange={(o) => { setURL({ ...url, placeHolders: o.value }); }}  />
                    </div>
                    <div className='app-multimedia-url-edit-rows'>
                        <TextBox className='app-multimedia-url-edit-inputs-url' height='47px' label={translate('url')} value={url.url}
                            onChange={(o) => { setURL({ ...url, url: o.value }); }}  />                            
                    </div>
                    <div className='app-multimedia-url-edit-rows'>                            
                        <TextArea className='app-multimedia-url-edit-inputs-description' minHeight={150} label={translate('description')} value={url.description}
                            onChange={(o) => { setURL({ ...url, description: o.value }); }}  />                  
                    </div>                                                                        
                </div>
            </>
}