// App imports
import { Icon, icons } from '../icon/icon';
import { Loader } from '../loader/loader';
import { translate } from "../../../utils/translation";

export function LargePanel({className, children, tools, loaded, generating, text, onClose, visible=true}) {   
   
    const close = () =>{
        onClose();
    };

    var classes = ['app-large-panel'];

    if (className)
        classes.push(className);

    return <>
            {!visible ? '' :
            <div className={classes.join(' ')}>
                <div className='app-large-panel-title'>
                    <div className='app-large-panel-back'>
                        <Icon className='app-large-panel-back-icon' icon={icons.caret}/>
                        <span className='app-large-panel-back-link' onClick={() => {close();}}>{translate('go_back')}</span>
                    </div>
                    {text}
                </div>
                <div className='app-large-panel-container'>
                    {
                        tools ? 
                            <div className='app-large-panel-tool-bar'> 
                            { loaded ? tools : <Loader /> }
                            </div>
                        : ''
                    }            
                    <div className='app-large-panel-body'> 
                    { generating ? <Loader theme='generate' showLogo={true}/> : ''}           
                    { loaded ? children : <Loader /> }
                    </div>
                </div>            
            </div>
        }
    </>
}