// React imports
import { useState, useEffect } from 'react';

import { useDroppable } from '@dnd-kit/core';

// App imports
import { Icon, icons } from '../icon/icon';
import { Clickable } from '../clickable/clickable';
import { Hideable } from '../hideable/hideable';
import { Button } from '../button/button';
import { translate } from '../../../utils/translation';

export function CollapsibleSection({ id, children, className, count, expanded, selectedCount, text, actions, onClick }) {

    const [isExpanded, setExpanded] = useState(expanded === false ? false : true);

    const { isOver, setNodeRef } = useDroppable({
        id: id,
    });

    useEffect(() => {
        setExpanded(expanded === false ? false : true);
    }, [expanded])

    return <div ref={setNodeRef} >
        <Clickable
            className= {`app-collapsible-section ${isOver ? 'app-collapsible-section-drag-is-over':''}`}
            backgroundClassName={className ? className : ''}
            onClick={(() => { setExpanded(!isExpanded); if (onClick) onClick(); })}
        >
            <span>
                <Icon className='app-collapsible-section-icon' icon={isExpanded ? icons.arrowDown : icons.arrowUp} />
            </span>
            {
                count || count === 0 ? <div className='app-collapsible-section-count'>{count}</div> : ''
            }
            {text}
            {
                actions ? actions.map((action, i) => {
                    return <Button id={action.id} key={action.id} className='app-collapsible-section-action' theme='simple' icon={action.icon} tooltip={action.tooltip} disabled={action.disabled} active={action.active} onClick={action.onClick} />
                })
                    : ''
            }
            {
                selectedCount > 0 ? <div className='app-collapsible-section-selected'>{selectedCount} {translate("selected")}</div> : ''
            }
        </Clickable>
        <Hideable className='app-collapsible-section-content' hide={!isExpanded}>
            {children}
        </Hideable>
    </div>
}