import * as ar from '../utils/locale/ar.json';
import * as en from '../utils/locale/en.json';
import * as es from '../utils/locale/es.json';
import * as fr from '../utils/locale/fr.json';
import * as id from '../utils/locale/id.json';
import * as it from '../utils/locale/it.json';
import * as ja from '../utils/locale/ja.json';
import * as ms from '../utils/locale/ms.json';
import * as pt from '../utils/locale/pt.json';
import * as ru from '../utils/locale/ru.json';

export var language = ((window.navigator.userLanguage || window.navigator.language).toLowerCase().split('-')[0]) || 'en';

export const translate = (key, o) => {

    o = typeof o  === 'object' ? o : {};    

    var lang = o.language ? o.language : language;

    var lookup = {
        ar: ar.default,
        en: en.default,
        es: es.default,
        fr: fr.default,
        id: id.default,
        it: it.default,
        ja: ja.default,
        ms: ms.default,
        pt: pt.default,
        ru: ru.default
    };        

    if (o.override && o.override[lang])
        key = o.override[lang];

    if (!lookup[lang])
        console.log('Unsupported translation language: ' + lang);
    else if (!lookup[lang][key])
        console.log('Undefined translation key: ["' + lang + ', "' + key + '"]');
    else
        return lookup[lang][key];
            
    return key;
};