// React imports
import { useEffect, useState} from 'react';

// App imports
import { constants } from '../../../utils/constants';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { TextBox } from '../../base/textBox/textBox';
import { cosmetic } from '../../../modules/cosmetic';
import { Button } from '../../base/button/button';
import { Loader } from '../../base/loader/loader';
import { DropDown } from '../../base/dropDown/dropDown';

const _ = require('lodash');

export function ShapeDownloader({entity, isGeometryCollection = false}) {
    const [title, setTitle] = useState(entity.text);
    const [downloadOption, setDownloadOption] = useState(constants.geometryCollectionDownloadType.combined);
    const [downloadType, setDownloadType] = useState(constants.dataSetType.KML);
    const [generating, setGenerating] = useState(false);
    const [allowedDownloadTypes, setAllowedDownloadTypes] = useState([]);

    useEffect(() => {
        var downloadTypes = [
            { key: constants.dataSetType.KML, name: "KML"},
            { key: constants.dataSetType.KMZ, name: "KMZ"},
            { key: constants.dataSetType.TAB, name: "TAB"}
        ];
        
        if (!isGeometryCollection || downloadOption !== constants.geometryCollectionDownloadType.combined)
            downloadTypes.push({ key: constants.dataSetType.SHAPE, name: "SHP"});

        setAllowedDownloadTypes(downloadTypes);
    }, []);

    const onSave = () =>{
        setGenerating(true);

        cosmetic.downloadShape({name: title, downloadDataType: downloadType, downloadOption: downloadOption, entity: entity, isGeometryCollection: isGeometryCollection, callback: () => {setGenerating(false);}});  
    };

    return  <>
        { generating ? <Loader theme='generate' showLogo={true}/> : ''}
        <div className='app-shape-downloader-container'>
            <TextBox height='50px' label={translate('name')} value={title} onChange={(o) => {setTitle(o.value);}}/>
            {isGeometryCollection ? 
                <DropDown height='50px' label={translate("shape_format")} display={"name"} valueProperty={"key"} 
                items={
                    [
                        { key: constants.geometryCollectionDownloadType.combined, name: translate("combined")},
                        { key: constants.geometryCollectionDownloadType.separate, name: translate("separate")}
                    ]
                } 
                selected={downloadOption}
                onChange={(o) => {
                    setDownloadOption(o.value);
                    
                    if (o.value === constants.geometryCollectionDownloadType.combined)
                        setAllowedDownloadTypes(_.cloneDeep(allowedDownloadTypes.filter(o => o.key !== constants.dataSetType.SHAPE)));
                    else
                        setAllowedDownloadTypes(_.cloneDeep(allowedDownloadTypes.concat({ key: constants.dataSetType.SHAPE, name: "SHP"})));
                }} 
                /> 
                : ''
            }
            <DropDown height='50px' label={translate("file_type")} display={"name"} valueProperty={"key"} 
                items={allowedDownloadTypes} 
                selected={downloadType}
                onChange={(o) => {setDownloadType(o.value);}} 
            />
            <Button theme='primary' size='medium' icon={icons.download} tooltip={translate('download')} onClick={() => {onSave()}} />
        </div>
        
    </>
}