// React imports
import { useEffect, useState } from 'react';

//App imports
import { DropDown } from '../../../../base/dropDown/dropDown';
import { translate } from '../../../../../utils/translation';
import { Button } from '../../../../base/button/button';
import { icons } from '../../../../base/icon/icon';
import { constants } from '../../../../../utils/constants';
import { adHocManagement as adHocModule } from '../../../../../modules/adHocManagement';
import { TextBox } from '../../../../base/textBox/textBox';
import { Icon } from '../../../../base/icon/icon';
import { Bar } from '../../../../base/bar/bar';
import { helpers } from '../../../../../utils/helpers';
import { List } from 'devextreme-react';
import { ItemDragging } from 'devextreme-react/cjs/list';

const _ = require('lodash');

export function AdHocCreationWizardStep1({ columns, updateColumns, dataSource, updateDataSource, table, onReadyNext, isSpatial, geocoders }) {
    const [availableGeocoders, updateAvailableGeocoders] = useState(null);
    const [availableGeocodes, updateAvailableGeocodes] = useState("N/A");
    const [requiredGeocodes, updateRequiredGeocodes] = useState("N/A");
    const dataTypeOptions = adHocModule.createAdHocTypeDropDownItems(isSpatial);

    const addColumns = () => {
        var newColumn = adHocModule.createDefaultAdHocColumn();
        columns.push(newColumn);
        updateColumns([...columns]);
    }

    const updateColumnMapping = (o) => {

        //Unmap previous mappings
        if (dataSource.addressColumn == o.column.name) {
            dataSource.addressColumn = null;
            dataSource.addressColumnIsDirty = true;
        }
        if (dataSource.cityColumn == o.column.name) {
            dataSource.cityColumn = null;
            dataSource.cityColumnIsDirty = true;
        }
        if (dataSource.stateColumn == o.column.name) {
            dataSource.stateColumn = null;
            dataSource.stateColumnIsDirty = true;
        }
        if (dataSource.zipColumn == o.column.name) {
            dataSource.zipColumn = null;
            dataSource.zipColumnIsDirty = true;
        }
        if (dataSource.latitudeColumn == o.column.name) {
            dataSource.latitudeColumn = null;
            dataSource.latitudeColumnIsDirty = true;
        }
        if (dataSource.longitudeColumn == o.column.name) {
            dataSource.longitudeColumn = null;
            dataSource.longitudeColumnIsDirty = true;
        }
        if (dataSource.spatialColumn == o.column.name) {
            dataSource.spatialColumn = null;
            dataSource.spatialColumnIsDirty = true;
        }

        //Map new mapping
        switch (o.mapping.value) {
            case constants.columnMappingTypes.address:
                dataSource.addressColumn = o.column.name
                dataSource.addressColumnIsDirty = true;
                break;
            case constants.columnMappingTypes.city:
                dataSource.cityColumn = o.column.name
                dataSource.cityColumnIsDirty = true;
                break;
            case constants.columnMappingTypes.state:
                dataSource.stateColumn = o.column.name
                dataSource.stateColumnIsDirty = true;
                break;
            case constants.columnMappingTypes.zip:
                dataSource.zipColumn = o.column.name
                dataSource.zipColumnIsDirty = true;
                break;
            case constants.columnMappingTypes.latitude:
                dataSource.latitudeColumn = o.column.name
                dataSource.latitudeColumnIsDirty = true;
                break;
            case constants.columnMappingTypes.longitude:
                dataSource.longitudeColumn = o.column.name
                dataSource.longitudeColumnIsDirty = true;
                break;
            case constants.columnMappingTypes.spatial:
                o.column.isVisible = false;
                dataSource.spatialColumn = o.column.name
                dataSource.spatialColumnIsDirty = true;
                break;
            default:
                break;
        }
    }

    const lookupColumnMapping = (o) => {
        switch (o) {
            case dataSource.addressColumn: return constants.columnMappingTypes.address
            case dataSource.cityColumn: return constants.columnMappingTypes.city
            case dataSource.stateColumn: return constants.columnMappingTypes.state
            case dataSource.zipColumn: return constants.columnMappingTypes.zip
            case dataSource.latitudeColumn: return constants.columnMappingTypes.latitude
            case dataSource.longitudeColumn: return constants.columnMappingTypes.longitude
            case dataSource.spatialColumn: return constants.columnMappingTypes.spatial
            default: return constants.columnMappingTypes.none;
        }
    }

    const onDelete = (o) => {
        columns = _.without(columns, o);
        updateColumns([..._.cloneDeep(columns)]);

        if (dataSource.nameColumn == o.name) {
            dataSource.nameColumn = null;
            dataSource.nameColumnIsDirty = true;
            updateDataSource(_.cloneDeep(dataSource));
        }
        if (dataSource.latitudeColumn == o.name) {
            dataSource.latitudeColumn = null;
            dataSource.latitudeColumnIsDirty = true;
            updateDataSource(_.cloneDeep(dataSource));
        }
        if (dataSource.longitudeColumn == o.name) {
            dataSource.longitudeColumn = null;
            dataSource.longitudeColumnIsDirty = true;
            updateDataSource(_.cloneDeep(dataSource));
        }
        if (dataSource.addressColumn == o.name) {
            dataSource.addressColumn = null;
            dataSource.addressColumnIsDirty = true;
            updateDataSource(_.cloneDeep(dataSource));
        }
        if (dataSource.cityColumn == o.name) {
            dataSource.cityColumn = null;
            dataSource.cityColumnIsDirty = true;
            updateDataSource(_.cloneDeep(dataSource));
        }
        if (dataSource.stateColumn == o.name) {
            dataSource.stateColumn = null;
            dataSource.stateColumnIsDirty = true;
            updateDataSource(_.cloneDeep(dataSource));
        }
        if (dataSource.zipColumn == o.name) {
            dataSource.zipColumn = null;
            dataSource.zipColumnIsDirty = true;
            updateDataSource(_.cloneDeep(dataSource));
        }
    }

    const onReorder = (o) => {
        if (o.fromIndex === o.toIndex) return;

        var column = columns[o.fromIndex];
        columns.splice(o.fromIndex, 1);
        columns.splice(o.toIndex, 0, column);
        updateColumns([...columns]);
    }

    const onGeocoderChange = (o) => {
        dataSource.geocoder = o.mapping.value;
        updateDataSource(_.cloneDeep(dataSource));
        if(o.mapping.value == constants.geocoders.noProduct)
        {
            updateAvailableGeocodes("N/A");
            updateRequiredGeocodes("N/A");
        }
        else {
            updateAvailableGeocodes(geocoders.find(x => x.product == o.mapping.value).transactions)
            updateRequiredGeocodes(table.totalRows);
        }
    }

    const onGeocodeBehaviorChange = (o) => {
        dataSource.geocodingBehavior = o.mapping.value;
        updateDataSource(_.cloneDeep(dataSource));
    }

    useEffect(() => {
        if (onReadyNext)
            if (isSpatial)
                onReadyNext(columns.length >= 2 && _.isString(dataSource.nameColumn) && _.isString(dataSource.spatialColumn));
            else {
                var hasLabelAndLatLon = columns.length >= 3 
                    && _.isString(dataSource.nameColumn) 
                    && _.isString(dataSource.latitudeColumn) 
                    && _.isString(dataSource.longitudeColumn);

                var hasLabelAndGeocodable = dataSource.geocoder != constants.geocoders.noProduct 
                    && _.isNumber(availableGeocodes)
                    && availableGeocodes >= requiredGeocodes
                    && columns.length >= 5 
                    && _.isString(dataSource.nameColumn) 
                    && _.isString(dataSource.addressColumn) 
                    && _.isString(dataSource.cityColumn) 
                    && _.isString(dataSource.stateColumn) 
                    && _.isString(dataSource.zipColumn);
                    
                onReadyNext(hasLabelAndLatLon || hasLabelAndGeocodable);
            }
    }, [onReadyNext, columns, dataSource, isSpatial, availableGeocodes, requiredGeocodes]);

    useEffect(() => {
        updateAvailableGeocoders([{product: -1, name: translate("none"), transactions: 0}].concat(geocoders));
    }, [geocoders]);

    const adHocColumnDataListRow = (column) =>{
        return <table key={helpers.newGuid()} className='app-ad-hoc-columns-table'>
                    <tbody>
                        <tr className='app-ad-hoc-columns-table-row'>
                            <td className='app-ad-hoc-columns-table-column'>
                                <div className='app-ad-hoc-column-actions'>
                                    <button className='app-ad-hoc-column-move'>
                                        <Icon icon={icons.ellipses} />
                                    </button>
                                </div>
                            </td>
                            <td className='app-ad-hoc-columns-table-column'>
                                <TextBox label={translate('column_label')} value={column.text} onChange={(o) => { column.text = o.value; updateColumns([..._.cloneDeep(columns)]); }} />
                            </td>
                            <td className='app-ad-hoc-columns-table-column'>
                                <DropDown label={translate('data_type')} display={'name'} valueProperty={'key'}
                                    items={dataTypeOptions}
                                    selected={column.dataType}
                                    onChange={(o) => {
                                        column.dataType = o.value;
                                        column.headerlessDataType = o.value;
                                        column.columnMapping = constants.columnMappingTypes.none;
                                        updateColumnMapping({ mapping: { value: constants.columnMappingTypes.none }, column: column });
                                        updateColumns([..._.cloneDeep(columns)]);
                                    }
                                    } />
                            </td>
                            <td className='app-ad-hoc-columns-table-column'>
                                <DropDown label={translate('mapping')} display={'name'} valueProperty={'key'}
                                    items={adHocModule.getMappingsForDataType(column.dataType)}
                                    selected={lookupColumnMapping(column.name)}
                                    onChange={(o) => {
                                        updateColumnMapping({ mapping: o, column: column });
                                        updateDataSource(_.cloneDeep(dataSource));
                                    }}
                                />
                            </td>                                
                            <td className='app-ad-hoc-columns-table-column'>
                                <DropDown label={translate('format')} display={'name'} valueProperty={'key'} items={[{ key: 'T', name: 'Text' }]} selected={'T'} />
                            </td>
                            <td className='app-ad-hoc-columns-table-column'>
                                <div className='app-option-selector'>
                                    {(table.firstRowContainsHeader ? column.name : column.headerlessName) == dataSource.nameColumn ?
                                        <div className='app-option-selector-check'>
                                            <Icon icon={icons.check} />
                                        </div> : ''}
                                    <Bar className={'app-ad-hoc-columns-bar-button'}
                                        text={translate('label')}
                                        active={false}
                                        onClick={() => {
                                            dataSource.nameColumn = (table.firstRowContainsHeader ? column.name : column.headerlessName);
                                            dataSource.nameColumnIsDirty = true;
                                            updateDataSource(_.cloneDeep(dataSource));
                                        }}
                                        disabled={false}
                                    />
                                </div>
                            </td>
                            <td className='app-ad-hoc-columns-table-column'>
                                <div className='app-ad-hoc-column-actions'>
                                    <Button theme='action' icon={column.isVisible ? icons.eye : icons.eyeSlash} onClick={() => { column.isVisible = !column.isVisible; updateColumns([..._.cloneDeep(columns)]); }} />                                            
                                    <Button theme='action' disabled={column.dataType == constants.sqlDataTypes.spatial} icon={icons.trash} onClick={() => { onDelete(column) }} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
    };

    return <div className='app-ad-hoc-creation-step-container'>
        {!isSpatial && table.totalRows != 0 ? <div className='app-ad-hoc-creation-geocoder-container'>
            <div className='app-ad-hoc-creation-geocoder-row'>
                <DropDown label={translate('selected_geocoder')} display={'name'} valueProperty={'product'}
                    items={availableGeocoders}
                    selected={dataSource.geocoder}
                    width={550}
                    onChange={(o) => {
                        onGeocoderChange({ mapping: o });
                        updateDataSource(_.cloneDeep(dataSource));
                    }}
                />
                <div className='app-ad-hoc-creation-data-labels'>{translate('available_geocodes')+": "+availableGeocodes}</div>
            </div>
            <div className='app-ad-hoc-creation-geocoder-row'>
                <DropDown label={translate('geocoding_behavior')} display={'name'} valueProperty={'key'}
                    items={[{key: constants.geocodingBehavior.fillIn, name: translate('geocode_empty_lat_lon')}, {key: constants.geocodingBehavior.overwrite, name: translate('geocode_over_lat_lon')}]} 
                    selected={dataSource.geocodingBehavior}
                    width={550}
                    onChange={(o) => {
                        onGeocodeBehaviorChange({ mapping: o });
                        updateDataSource(_.cloneDeep(dataSource));
                    }}
                />
                <div className='app-ad-hoc-creation-data-labels'>{translate('required_geocodes')+": "+requiredGeocodes}</div>
            </div> 
        </div> : ''}
        <div className='app-ad-hoc-creation-field-header'>
            <div className='app-ad-hoc-creation-data-labels'>{translate('data_fields')}</div>
            <div className='app-ad-hoc-right-aligned-button-container'>
                <Button className='test2' theme='primary' size='small' icon={icons.plus} onClick={addColumns} />
            </div>
        </div>
        <div className='app-ad-hoc-columns-table-container'>
            {
                columns.length === 0 ? <div className='app-ad-hoc-columns-table-empty'>{translate('add_columns')}</div> :
                <List
                    keyExpr={'id'}
                    dataSource={columns}
                    itemRender={adHocColumnDataListRow}
                    selectionMode={'none'}
                    displayExpr={'name'}
                    searchEnabled={false}
                    selectByClick={false}
                    pageLoadMode={'scrollBottom'}
                    focusStateEnabled={false}
                    activeStateEnabled={false}>
                    <ItemDragging
                        allowReordering={true}
                        handle={'.app-ad-hoc-column-move'}
                        onReorder={onReorder}
                        boundary={'.app-ad-hoc-columns-table-container'}
                    />
                </List>  
            }
        </div>
    </div>
}