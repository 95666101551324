// App imports
import { Button } from '../../../../base/button/button';
import { icons } from '../../../../base/icon/icon';
import { translate } from '../../../../../utils/translation';
import { TextArea } from '../../../../base/textArea/textArea';

const _ = require("lodash");

export var saveMap;

export function GeoFeedDataEdit({geoFeed,updateGeoFeed,onClose}) {        
    return <>
    {
        _.isObject(geoFeed) ?
            <div className='app-multimedia-url-edit'>          
                        <div className='app-multimedia-url-edit-rows'>                            
                            <TextArea className='app-multimedia-url-edit-inputs-description' minHeight={400} maxHeight={400} label={translate('provide_valid_geo_feed_data')} value={geoFeed.data}
                                    onChange={(o) => {
                                        updateGeoFeed((prevState) => ({...prevState, sources: prevState.sources.map(geo => {
                                            if (geo.id === geoFeed.id) {
                                                return { ...geo, data: o.value };
                                            } else {
                                                return geo;
                                            }
                                        })}));
                            }}/>                 
                        </div>                                                                        
                        <div className='app-multimedia-url-edit-actions-rows app-multimedia-header'>
                            <Button className='app-multimedia-url-edit-button' theme='secondary' tooltip={translate('close')} size='small' icon={icons.arrowTurnDownLeft} onClick={()=>{ onClose() }} />	                    
                            <Button className='app-multimedia-url-edit-button' theme='primary' tooltip={translate('save')} size='small' icon={icons.save} onClick={()=>{ onClose()}} />	
                        </div>                    
                    </div>
            : ''
    }

    </>
}