// App imports
const _ = require("lodash");

export const modelWizard = {
    currentListener: null,
    setSelectSiteListener: (listener) =>{
        modelWizard.currentListener = (entity) =>{
            listener(entity);
        };
    },
    removeSelectSiteListener: () =>{
        modelWizard.currentListener = null;
    }
};