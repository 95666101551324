// React imports
import { useState, useEffect, useMemo } from 'react';

// App imports
import { Button } from '../../../base/button/button';
import { DropDown } from '../../../base/dropDown/dropDown';
import { translate } from "../../../../utils/translation"
import { icons } from '../../../base/icon/icon';
import { constants } from '../../../../utils/constants';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { TradeAreaDropDown } from '../../../base/tradeAreaDropDown/tradeAreaDropDown';

const _ = require("lodash");

export function QuickReportOptions({ quickReportOptions, onApply }) {

    const [_quickReportOptions, setQuickReportOptions] = useState(null);
    const [reports, setReports] = useState([]);

    const chartLocations = useMemo(() => [
        { key: constants.print.legendLocation.none, text: translate('none') }, 
        { key: constants.print.legendLocation.topLeft, text: translate('upper_left') }, 
        { key: constants.print.legendLocation.topRight, text: translate('upper_right') },
        { key: constants.print.legendLocation.bottomLeft, text: translate('lower_left') },
        { key: constants.print.legendLocation.bottomRight, text: translate('lower_right') }
    ], []);

    useEffect(() =>{

        if (!_.isObject(quickReportOptions))
            return;

        setQuickReportOptions(_.cloneDeep(quickReportOptions));

        legacyEndpoints.service({
            name: 'GetAvailableChartReports',
            success: (r) => {
                setReports(r);
            }
        });  

    }, [quickReportOptions]);

    return <>
        { _quickReportOptions === null ? '' :
        <div className='app-projects-print-cols'>
            <div className='app-projects-print-rows'>
                <Button className='app-projects-print-apply-button app-projects-print-align-right' theme='secondary' tooltip={translate('reset')} size='small' icon={icons.clockRotateLeft} onClick={()=>{ setQuickReportOptions(_.cloneDeep(quickReportOptions)); }} />
                <Button className='app-projects-print-apply-button' theme='primary' tooltip={translate('apply_changes')} size='small' icon={icons.check} onClick={()=>{ onApply(_quickReportOptions); }} />
            </div>
            <div className='app-projects-print-rows app-projects-print-centered-rows'>
                <div className='app-projects-print-cols'>
                    <DropDown className={'app-projects-print-input'} valueProperty='ID' display='Name' items={reports} 
                        selected={_quickReportOptions.chartId} label={translate('select_report')} 
                        placeholder={translate('select_report')}
                        width={'180px'} height={'40px'}  
                        onChange={(o) => { setQuickReportOptions({ ..._quickReportOptions, chartId: o.value }); }}
                    />  
                </div>
                <div className='app-projects-print-cols'>
                    <TradeAreaDropDown 
                        className={'app-projects-print-input'}
                        maxSelections={2}
                        showCurentView={true}
                        types={null}
                        height={'40px'}
                        onChange={(o) => { 
                            var selectedTradeArea = o[0];
                            if(_.isObject(o) && o.length > 0) 
                                setQuickReportOptions({ ..._quickReportOptions, tradeArea: selectedTradeArea }); 
                            else
                                setQuickReportOptions({ ..._quickReportOptions, tradeArea: null });

                        }}
                    />
                </div>
                <div className='app-projects-print-cols'>
                    <DropDown className={'app-projects-print-input'} items={chartLocations} label={translate('chart_location')} width={'160px'} height={'40px'} 
                        display='text' valueProperty='key' selected={_quickReportOptions.chartLocation} 
                        onChange={(o) => { setQuickReportOptions({ ..._quickReportOptions, chartLocation: o.value }); }} />
                </div>
            </div>
        </div>
        }
    </>	
}