// React imports
import { useState, useEffect } from 'react'

// App imports
import { Clickable } from '../clickable/clickable';

var _ = require("lodash");

export function ColorSwatches({colors, selected, onClick}) {

    const [activeSwatch, setActiveSwatch] = useState(-1);    

    useEffect(() => {

        var activeIndex = -1;
        if (selected)
            activeIndex = _.findIndex(colors, function (color) { return color.value === selected });

        setActiveSwatch(activeIndex);

    }, [selected, colors]);

    return <>
        {
            colors.map((color, i) =>{
                return <Clickable
                    key={i} 
                    className={'app-color-swatch-container'}
                    tooltip={color.name}
                    active={activeSwatch === i}
                    onClick={(e) => {
                        
                        setActiveSwatch(i);

                        if (_.isFunction(onClick))
                            onClick(color);
                    }}
                    >
                        <div className="app-color-swatch" style={{backgroundColor: color.rgb}}></div>
                </Clickable>
            })
        }		
    </>
}