import { useState } from 'react';

import { legacyEndpoints } from "../../../services/legacyEndpoints";
import { translate } from "../../../utils/translation";
import { Button } from "../../base/button/button";
import { icons } from "../../base/icon/icon";
import { Loader } from "../../base/loader/loader";
import { TextBox } from "../../base/textBox/textBox";
import { SimpleTable } from '../../base/simpleTable/simpleTable';
import { TextArea } from '../../base/textArea/textArea';
import { Description } from '../../base/description/description';
import { artificialIntelligence } from '../../../modules/artificialIntelligence';
import { Bar } from '../../base/bar/bar';
import { LocationChat } from './locationChat';

const _ = require("lodash");

export function ArtificialIntelligence({ onClose=()=>{} }){

	const [showLocationChat, setShowLocationChat] = useState(false);

	return <>
		<Bar icon={icons.locationDot} text={translate('location_chat')} onClick={()=>{setShowLocationChat(true)}} />
		{showLocationChat === true ? <LocationChat onClose={()=>{setShowLocationChat(false)}} /> : ''}
	</>;
}