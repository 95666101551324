// React imports
import { useEffect } from 'react';

// App imports
import { DataList } from "../../../base/dataList/dataList";
import { translate } from "../../../../utils/translation";

const _ = require('lodash');

export function ManagementReportsStep1({availableReports, selectedReport, updateSelectedReport, onReadyNext}) {

    useEffect(() => {

        if (onReadyNext)
            onReadyNext(_.isObject(selectedReport));

    }, [onReadyNext, selectedReport])
    
    return <>
        <div className='app-management-report-step-1-container'>
            <div className='app-management-report-labels'>{translate('available_reports')}</div>
            <div className='app-management-report-available-reports'>
                <DataList
                    keyExpr={'id'}
                    dataSource={availableReports}
                    height={'auto'}
                    selectionMode={'single'}
                    selectByClick={true}
                    searchEnabled={true}
                    selectedItems={[selectedReport.ID]}
                    selectedItemsChange={(o)=> {
                        updateSelectedReport(o.length > 0 ? o[0] : null);
                    }}
                />
            </div>
        </div>
    </>
}