// React imports
import React, { useState } from 'react';

// App imports
import { Hideable } from '../hideable/hideable';
import { Clickable } from '../clickable/clickable';
import { Button}  from '../button/button';
import { Icon, icons } from '../icon/icon';
import { translate } from '../../../utils/translation';

const _ = require("lodash");

export const Bar = React.forwardRef(({className,children,onClick,loading,actions,active,icon,color,iconWidth,iconClass,text,subText,disabled,notLicensed,comingSoon,opened,tooltip,draggable,attributes,listeners}, ref) => {
    
    const [open, setOpen] = useState(opened);
    const [hovered, setHovered] = useState(false);

    React.useImperativeHandle(ref, () => ({ collapse: () =>{ setOpen(false); }}));
    
    let empty = !disabled && children?.length === 0;
    let isDisabled = empty || disabled;
    let defaultActions = [];    

    const expand = ()=>{
        if (children)
            setOpen(!open);

        if (onClick)
            onClick();
    };

    if (children)
        defaultActions.push({ 
            icon: loading ? icons.spinner: open && children ? icons.arrowUp : icons.rightArrow, 
            default: true,
            tooltip: open ? translate('collapse') : translate('expand'),
            className: 'app-bar-action-icon'
        });    

    if (_.isArray(actions))
        defaultActions = actions.concat(defaultActions);

    defaultActions.filter(action => action.default).forEach(action =>{
        action.onClick = (e) =>{
            e.stopPropagation();
            expand();
        };
    });

    var classes = ['app-bar-container'];

    if (className)
        classes.push(className);

    var buttonClasses = ['app-bar'];
    var bodyClasses = ['app-bar-body'];
    
    if (empty)
        buttonClasses.push('app-bar-empty');
    else if (open)
    {
        buttonClasses.push('app-bar-open');
        bodyClasses.push('app-bar-open');
    }       
    
    return <div className={classes.join(' ')}>
        <Clickable
            className={buttonClasses.join(' ')}
            disabled={isDisabled}
            notLicensed={notLicensed}
            comingSoon={comingSoon}
            active={active}
            tooltip={tooltip}
            onHover={(o) =>{
                //o.event.stopPropagation();
                setHovered(o.hovered);
            }}
            onClick={() => {
                expand();
            }}          
        >
            <table className='app-bar-table'>
                <tbody>
                    <tr>
                        {(!draggable ? '' :
                            <td className='app-legend-layer-dnd-icon-container'>
                                <button {...attributes} {...listeners} className='app-legend-layer-dnd-button'><Icon icon={icons.ellipses}/></button>
                            </td>
                        )}
                        {(!icon && !color ? '' :
                            <td className='app-bar-icon-container' style={{width: iconWidth + 'px'}}>
                                {(icon ? _.isString(icon) && icon.startsWith('http') ? <img src={icon} alt={text} /> : <Icon className={iconClass} icon={icon}  /> : '')}
                                {(color ? <div className='app-bar-color' style={{ backgroundColor: _.isObject(color) ? `rgba(${color.r},${color.g},${color.b},${color.a})` : color }} /> : '')}
                            </td>
                        )}
                        <td className='app-bar-text'>
                            {text}
                        </td>
                        <td className='app-bar-action-container'>
                        {
                            defaultActions.map((action,i) =>{
                                return <Button key={i} className={action.className} theme='simple' icon={action.icon} tooltip={action.tooltip} hovered={hovered && action.default} disabled={action.disabled || isDisabled} active={action.active} onClick={action.onClick} />
                            })
                        }
                        </td>
                    </tr>
                    {(subText ? <tr>
                        {!draggable ? '' :  <td />}
                        {!icon && !color ? '' :  <td />}
                        <td>
                            <div className='app-bar-sub-text'>{subText}</div>
                        </td>
                        <td />                        
                    </tr> : '')}
                </tbody>
            </table>
           
        </Clickable>        
        {
            !children ? '' :
            <Hideable className={bodyClasses.join(' ')} hide={!open || empty}>
                {children}
            </Hideable>
        }
    </div>
})