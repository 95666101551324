// App imports
import { TextBox } from '../../../base/textBox/textBox';
import { Button } from '../../../base/button/button';
import { ConfirmButton } from '../../../base/confirmButton/confirmButton';
import { icons } from '../../../base/icon/icon';
import { Icon } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';

export function EditPhotoUpload({photo,onDelete,onPhotoEdit,isPhoto,onHandleChange}){

    function onInputChange (e) {
        onHandleChange(photo.id, e.name, e.value );
    }           

	return (
            <table>
                <tbody>
                    <tr>
                        <td className='app-photos-edit-photo-upload-photo'>                          
                        { isPhoto ? <img className='app-photos-edit-photo-upload-photo-img' src={legacyEndpoints.handlers.getUploadUrl({ fileId: photo.id, task: 'get' }) +'&dummy=' + crypto.randomUUID()} alt={translate('store_photo')} /> : 
                            <Icon className={'app-photos-edit-photo-upload-edit-details-file'} icon={icons.file} />
                        }
                        </td>
                        <td>
                            <div className="app-photos-edit-photo-upload-edit-details">
                                <TextBox className='app-photos-edit-photo-upload-edit-details-textbox-title' label={translate('title')} name='title' value={photo.title} onChange={ onInputChange  }  />
                                { isPhoto ? <TextBox className='app-photos-edit-photo-upload-edit-details-textbox-tags' label={translate('hashTags')} name='tags' value={photo.tags} onChange={ onInputChange  }  />  : '' }                                                                                  
                            </div>                            
                            <div>
                                <TextBox className='app-photos-edit-photo-upload-edit-details-textbox-description' label={translate('description')} name='description' value={photo.description} onChange={ onInputChange  } />            
                            </div>
                        </td>
                        <td className='app-photos-edit-photo-upload-edit-details-actions'>
                            { isPhoto ? <Button className='app-photos-edit-photo-upload-edit-details-actions-button' theme='simple' tooltip={translate('edit')} icon={icons.paintBrushFine} onClick={()=>{ onPhotoEdit(photo.id); }} /> : '' }                 
                            <ConfirmButton className='app-photos-edit-photo-upload-edit-details-actions-button' align='right' theme='simple' icon={icons.trash} tooltip={translate('delete')} clearText={translate('delete')} onConfirm={() => { onDelete(photo.id) }} />                            
                        </td>
                    </tr>
                </tbody>
            </table>
    );
}