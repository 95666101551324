// React imports
import { useState, useEffect } from 'react'

//App imports
import { Button } from "../button/button";
import { AutoCloser } from "../autoCloser/autoCloser";

const _ = require("lodash");

export function MenuButton({className, tooltip, align, theme, size, icon, text, items, itemsDataSource, showDropDown, disabled, active, showIcon}) {
    
    const [visible, setVisible] = useState(false);
    const [boundItems, setBoundItems] = useState(items ?? []);
    const [selectedIcon, setSelectedIcon] = useState(icon ? icon : boundItems?.filter(x => x.header !== true).length > 0 ? boundItems.filter(x => x.header !== true)[0].icon : null);

    var classes = ['app-button-menu-container'];

    showIcon = showIcon === undefined ? true : showIcon;

    //if (align === 'right')
    //    classes.push('app-button-menu-right');

    var buttonClasses = [];

    if (className)
        buttonClasses.push(className);

    if (visible)
        buttonClasses.push('app-button-menu-open');

    useEffect(()=>
    {
        async function bindItems() {
            if (visible)
            {
                if (_.isFunction(itemsDataSource))
                {
                    setBoundItems(await itemsDataSource());
                    setSelectedIcon(icon ? icon : boundItems?.filter(x => x.header !== true).length > 0 ? boundItems.filter(x => x.header !== true)[0].icon : null);
                } 
                else 
                {
                    setBoundItems(items);
                }
            }
        };

        bindItems();
    }, [visible]);

	return <AutoCloser className={classes.join(' ')} open={visible} onClose={() => { setVisible(false); }}>
        <Button {...{disabled, active, theme, size, tooltip, text}} className={buttonClasses.join(' ')} icon={showIcon ? selectedIcon : null} showDropDown={showDropDown === undefined ? true : showDropDown} onClick={() => { setVisible(!visible); }} />
        {
			boundItems && visible ? <div className={align === 'right' ? 'app-button-menu-right' : 'app-button-menu'}>
			{
				boundItems.map((item, i) =>{
                    if (item.header)
                        return <div key={i} className='app-button-menu-title'>
                            {item.text}
                        </div>
                    else
                        return <Button key={i} theme='row' icon={item.icon} text={item.text} image={item.image} disabled={item.disabled} selected={item.selected} onClick={(e) =>{

                            if (item.onClick)
                                item.onClick({e, item});

                            setVisible(false);

                            if (!icon)
                                setSelectedIcon(item.icon);
                        }} />
				})
			}
			</div> : ''
		}
    </AutoCloser>         
}