// React imports
import { useEffect, useState, useCallback, Fragment } from 'react';

// App imports
import { Bar } from '../../../base/bar/bar';
import { RadioButtonSet } from '../../../base/radioButtonSet/radioButtonSet';
import { Button } from '../../../base/button/button';
import { icons, Icon } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { constants } from '../../../../utils/constants';
import { customerMaps } from '../../../../modules/customerMaps';

const _ = require("lodash");

export function GeofenceJobRenderers({job, entityLayerId, onClose}) {

    const [mapTypes, setMapTypes] = useState([]);
    const [filteredPinWarning, setFilteredPinWarning] = useState('');
    const [filteredLineWarning, setFilteredLineWarning] = useState('');
    const [errorDescription, setErrorDescription] = useState('');

    useEffect(()=>{        

        var items = [        
            { icon: icons.braille, text: translate('pin_map'), type: constants.customerDataRenderers.pin, selected: false, daytimeRenderer: 0, eveningRenderer: 0,
                renderers: [{ Id: '', Type: constants.customerDataRenderers.pin, Name: translate('none'), Description: translate('none'), icon: icons.ban, 
                    daytimeDisabled: true, daytimeDisabledLocked: false, eveningDisabled: true, eveningDisabledLocked: false 
                }] 
            },
            { icon: icons.bars, text: translate('desire_lines'), type: constants.customerDataRenderers.desireLine, selected: false, daytimeRenderer: 0, eveningRenderer: 0,
                renderers: [{ Id: '', Type: constants.customerDataRenderers.desireLine, Name: translate('none'), Description: translate('none'), icon: icons.ban, 
                    daytimeDisabled: true, daytimeDisabledLocked: false, eveningDisabled: true, eveningDisabledLocked: false 
                }] 
            },
            { icon: icons.fire, text: translate('heat_map'), type: constants.customerDataRenderers.heat, selected: false, daytimeRenderer: 0, eveningRenderer: 0,
                renderers: [{ Id: '', Type: constants.customerDataRenderers.heat, Name: translate('none'), Description: translate('none'), icon: icons.ban, 
                    daytimeDisabled: true, daytimeDisabledLocked: false, eveningDisabled: true, eveningDisabledLocked: false 
                }] 
            }
        ]
        
        job.RendererList.forEach(renderer => {
            var item = items.find((t) => t.type == renderer.Type);
            if (item)
                item.renderers.push({ ...renderer, icon: item.icon, daytimeDisabled: true, daytimeDisabledLocked: false, eveningDisabled: true, eveningDisabledLocked: false });        
        });

        lockRenderers({items: items, rendererType: constants.customerDataRenderers.pin});
        lockRenderers({items: items, rendererType: constants.customerDataRenderers.desireLine});

        setMapTypes(items);

    },[job]);  

    const lockRenderers = (o)=>{
        var daytimeRendererId = null;
        var eveningRendererId = null;

        var layerType = customerMaps.getMapType({ type: o.rendererType });
        const uberLayers = customerMaps.getLayers().filter(layer => layer.metaData.isUber === true);

        if (uberLayers && uberLayers.length > 0)
            uberLayers.forEach(layer => {
                if (layer.type === layerType)
                    if (layer.metaData.timeType === 0)
                        daytimeRendererId = layer.metaData.themeId;
                    else if (layer.metaData.timeType === 1)
                        eveningRendererId = layer.metaData.themeId;
            });

        if (daytimeRendererId != null || eveningRendererId != null) {
            o.items.forEach(item => {
                if (item.type === o.rendererType) {
                    if (daytimeRendererId != null) {
                        item.renderers.forEach(renderer => {
                            if (renderer.Id != '') {
                                if (daytimeRendererId.toLowerCase() == renderer.Id.toLowerCase()) {
                                    renderer.eveningDisabledLocked = true;
                                }
                                else {
                                    renderer.daytimeDisabledLocked = true;
                                }
                            }
                        });
                    }
                    if (eveningRendererId != null) {
                        item.renderers.forEach(renderer => {
                            if (renderer.Id != '') {
                                if (eveningRendererId.toLowerCase() == renderer.Id.toLowerCase()) {
                                    renderer.daytimeDisabledLocked = true;
                                }
                                else {
                                    renderer.eveningDisabledLocked = true;
                                }
                            }
                        });
                    }
                }
            });

            switch (o.rendererType)
            {
                default:
                case constants.customerDataRenderers.pin:
                    setFilteredPinWarning(translate('filtered_renderers_warning'));
                    break;
                case constants.customerDataRenderers.desireLine:
                    setFilteredLineWarning(translate('filtered_renderers_warning'));
                    break;
            }
        }
    };

	const anyMapTypesSelected = useCallback(
        ()=>{
            var anySelected = false;

            mapTypes.forEach(mapType => {
                if (mapType.selected)
                    anySelected = true;
            });

            return anySelected;
	    },
        [mapTypes]
    );

	const areRenderersValid = useCallback(
        ()=>{
            var isValid = [ false, false, false ];

            mapTypes.forEach((mapType, i) => {
                if (mapType.selected) {
                    if (mapType.daytimeRenderer > 0 || mapType.eveningRenderer > 0)
                        isValid[i] = true;
                }
                else    
                    isValid[i] = true;
            });

            return isValid.every(val => val === true);
        },
        [mapTypes]
    );

	const validRenderers = ()=>{
        return anyMapTypesSelected() && areRenderersValid();
	};		

    useEffect(()=>{        

        if (!anyMapTypesSelected())
            setErrorDescription(translate('one_renderer_type_required'));
        else if (!areRenderersValid())
            setErrorDescription(translate('one_renderer_per_type_required'));
        else 
            setErrorDescription('');

    },[anyMapTypesSelected, areRenderersValid]);  

    const selectMapType = (o) =>{

        const newMapTypes = mapTypes.map((newMapType, i) => {
            if (i === o.index)
                return {...newMapType, selected: !o.mapType.selected, renderers: newMapType.renderers.map(newRenderer => {
                        if (!o.mapType.selected)
                            return {...newRenderer, daytimeDisabled: false, eveningDisabled: false };  
                        else
                            return {...newRenderer, daytimeDisabled: true, eveningDisabled: true };                    
                    })
                };
            else
                return {...newMapType};
        });

        setMapTypes([...newMapTypes]);        
    };

    const selectDaytimeRenderer = (o) =>{

        const newMapTypes = mapTypes.map((newMapType, i) => {
            if (i === o.typeIndex)
                return {...newMapType, daytimeRenderer: o.rendererIndex, renderers: newMapType.renderers.map((newRenderer, j) => {
                        if (j === o.rendererIndex && j !== 0)
                            return {...newRenderer, eveningDisabled: true };  
                        else
                            return {...newRenderer, eveningDisabled: false };                    
                    })
                };
            else
                return {...newMapType};
        });

        setMapTypes([...newMapTypes]);        
    };

    const selectEveningRenderer = (o) =>{

        const newMapTypes = mapTypes.map((newMapType, i) => {
            if (i === o.typeIndex)
                return {...newMapType, eveningRenderer: o.rendererIndex, renderers: newMapType.renderers.map((newRenderer, j) => {
                        if (j === o.rendererIndex && j !== 0)
                            return {...newRenderer, daytimeDisabled: true };  
                        else
                            return {...newRenderer, daytimeDisabled: false };                    
                    })
                };
        else
                return {...newMapType};
        });

        setMapTypes([...newMapTypes]);        
    };

    const displayRenderers = ()=>{

        mapTypes.forEach(mapType => {
            if (mapType.selected) {
                var name;
                var rendererId;
                var dataType;

                if (mapType.daytimeRenderer > 0) {

                    name = getLayerName({ type: mapType.type, timeType: 0 });
                    rendererId = getLayerRendererId({ type: mapType.type, jobId: job.Id, timeType: 0, customQueryId: entityLayerId });
                    dataType = getDataType({ type: mapType.renderers[mapType.daytimeRenderer].Type });

                    customerMaps.refresh({ id: rendererId, type: mapType.type, name: name, pointId: job.Id, isUber: true, timeType: 0, dataType: dataType, themeId: mapType.renderers[mapType.daytimeRenderer].Id, customQueryId: entityLayerId });
                }

                if (mapType.eveningRenderer > 0) {

                    name = getLayerName({ type: mapType.type, timeType: 1 });
                    rendererId = getLayerRendererId({ type: mapType.type, jobId: job.Id, timeType: 1, customQueryId: entityLayerId });
                    dataType = getDataType({ type: mapType.renderers[mapType.eveningRenderer].Type });

                    customerMaps.refresh({ id: rendererId, type: mapType.type, name: name, pointId: job.Id, isUber: true, timeType: 1, dataType: dataType, themeId: mapType.renderers[mapType.eveningRenderer].Id, customQueryId: entityLayerId });
                }
            }
        });

        onClose();
    };

    const getLayerRendererId = (o)=>{

        var rendererId = `${o.jobId}_${o.customQueryId}_${o.timeType}`;
        var type = customerMaps.getMapType({ type: o.type });
        var id = `${type}_${rendererId}`;

        var layer = customerMaps.getLayers().find(layer => { return layer.id.toLowerCase() === id.toLowerCase() });
        
        if (_.isObject(layer)) {
            
            // remove if they exist, and customerMaps (refresh) will add back in
            var index = layer.metaData.points.indexOf(o.jobId);
            if (index > -1) {
                layer.metaData.points.splice(index, 1);

                index = layer.metaData.customQueryList.indexOf(o.customQueryId);
                if (index > -1)
                    layer.metaData.customQueryList.splice(index, 1);
            }
        }

        return rendererId;
    };

	const getDataType = (o)=>{
		switch (o.type)
		{
            default:
			case constants.customerDataRenderers.pin:
				return 0;
			case constants.customerDataRenderers.desireLine:
				return 1;
			case constants.customerDataRenderers.heat:
				return 3;
		}
	};

    const getLayerName = (o)=>{
        var timeName = '';

        switch (o.timeType)
        {
            default:
            case 0:
                timeName = translate('daytime');
                break;
            case 1:
                timeName = translate('evening');
                break;
        }

        var suffix = '';

        switch (o.type)
        {
            default:
            case constants.customerDataRenderers.pin:
                suffix = translate('count');
                break;
            case constants.customerDataRenderers.desireLine:
                suffix = translate('trips');
                break;
            case constants.customerDataRenderers.heat:
                suffix = translate('heat_map');
                break;
        }

        return `${translate('uberRetail')} ${timeName} ${suffix}`
    }

    return <>
        <div className='app-geofence-job-details-action-container'>      
            <div className='app-geofence-job-renderers-row-options'>      
                <div className='app-geofence-job-renderers-type-label'>{translate('renderer_types')}</div>
                <div className='app-geofence-job-renderers-label'>{translate('daytime_renderer')}</div>
                <div className='app-geofence-job-renderers-label'>{translate('evening_renderer')}</div>
            </div>
            {
                mapTypes.map((mapType, i) => {
                    return ( <Fragment key={i}>
                        <div key={i} className='app-geofence-job-renderers-row-options app-geofence-job-renderers-section'>      
                            <div key={`type_${i}`} className='app-geofence-job-renderers-col-options'>      
                                <div key={i} className='app-option-selector'>
                                    {mapType.selected ? 
                                    <div className='app-option-selector-check'>
                                        <Icon icon={icons.check} />
                                    </div> : ''}
                                    <Bar className={'app-geofence-job-renderers-bar-buttons'} 
                                        icon={mapType.icon}
                                        text={mapType.text} 
                                        active={mapType.selected} 
                                        onClick={() => { selectMapType({ mapType: mapType, index: i }); }} 
                                        disabled={false} 
                                    /> 
                                </div>
                            </div>
                            <div key={`daytime_${i}`} className='app-geofence-job-renderers-col-options'>
                                <RadioButtonSet className='app-geofence-job-renderers-radio'>
                                {
                                    mapType.renderers.map((renderer, j) => {
                                        return (
                                            <Button 
                                                key={j} 
                                                active={!renderer.daytimeDisabled && mapType.daytimeRenderer === j} 
                                                tooltip={renderer.Name} 
                                                theme='secondary' 
                                                size='small' 
                                                icon={renderer.icon} 
                                                disabled={renderer.daytimeDisabled || renderer.daytimeDisabledLocked}
                                                onClick={() => { selectDaytimeRenderer({ mapType: mapType, typeIndex: i, renderer: renderer, rendererIndex: j }); }} 
                                            />                                        
                                        )
                                    })
                                }
                                </RadioButtonSet>
                                <div className='app-geofence-job-details-description app-geofence-job-renderers-name'>{mapType.renderers[mapType.daytimeRenderer].Name}</div>
                            </div>
                            <div key={`evening_${i}`} className='app-geofence-job-renderers-col-options'>      
                                <RadioButtonSet className='app-geofence-job-renderers-radio'>
                                {
                                    mapType.renderers.map((renderer, j) => {
                                        return (
                                            <Button 
                                                key={j} 
                                                active={!renderer.eveningDisabled && mapType.eveningRenderer === j} 
                                                tooltip={renderer.Name} 
                                                theme='secondary' 
                                                size='small' 
                                                icon={renderer.icon} 
                                                disabled={renderer.eveningDisabled || renderer.eveningDisabledLocked} 
                                                onClick={() => { selectEveningRenderer({ mapType: mapType, typeIndex: i, renderer: renderer, rendererIndex: j }); }} 
                                            />                                        
                                        )
                                    })
                                }
                                </RadioButtonSet>
                                <div className='app-geofence-job-details-description app-geofence-job-renderers-name'>{mapType.renderers[mapType.eveningRenderer].Name}</div>
                            </div>
                        </div>
                        { mapType.type === constants.customerDataRenderers.pin && mapType.selected && filteredPinWarning.length > 0 ?
                                <div className='app-geofence-job-renderers-row-options'>      
                                    <div className='app-geofence-job-renderers-warning'>{filteredPinWarning}</div>
                                </div>
                            : ''
                        }
                        { mapType.type === constants.customerDataRenderers.desireLine && mapType.selected && filteredLineWarning.length > 0 ?
                                <div className='app-geofence-job-renderers-row-options'>      
                                    <div className='app-geofence-job-renderers-warning'>{filteredLineWarning}</div>
                                </div>
                            : ''
                        }
                    </Fragment>
                    )
                })
            }
            <div className='app-geofence-job-details-btn-row'>
                { errorDescription.length == 0 ? '' :
                    <div className='app-geofence-job-details-error'>{errorDescription}</div>
                }
                <Button className='app-selectable-items-close-button' theme='secondary' size='small' icon={icons.x} tooltip={translate('cancel')} onClick={() => { onClose(); }} />
                <Button theme='primary' size='small' icon={icons.check} tooltip={translate('display')} disabled={!validRenderers()} onClick={()=>{ displayRenderers(); }} />
           </div>
        </div>
    </>
}