// React imports
import { useState, useEffect } from 'react';

// App imports
import { Button } from '../../../../base/button/button';
import { DropDown } from '../../../../base/dropDown/dropDown';
import { icons } from '../../../../base/icon/icon';
import { translate } from '../../../../../utils/translation';

const _ = require("lodash");

export function PointDetailReportOptions({mapBookContentTypes, pdr, onSave, onClose}) {

    const [pdrCustomQueryKey, setPdrCustomQueryKey] = useState(null);
    const [pdrCustomQuery, setPdrCustomQuery] = useState(pdr.customQueryId);
    const [pdrCustomQueryName, setPdrCustomQueryName] = useState(pdr.customQueryName);
    const [pdrReport, setPdrReport] = useState(pdr.reportId);
    const [pdrReportName, setPdrReportName] = useState(pdr.reportName);
    const [isGoldOrg, setIsGoldOrg] = useState(pdr.isGoldOrg);
    const noCustomQueryKey = '_';
    const noCustomQuery = '';

    useEffect(() => {
        var data = getData().find((element) => element.value === pdrCustomQuery);
        setPdrCustomQueryKey(_.isUndefined(data) ? noCustomQueryKey : data.key);
    }, []);

    const saveOptions = () => {
        if (_.isFunction(onSave)) {
            onSave({
                customQueryId: pdrCustomQuery,
                customQueryName: pdrCustomQueryName,
                isGoldOrg: isGoldOrg,
                reportId: pdrReport,
                reportName: pdrReportName
            });
        }
    }

    const getData = (o) => {
        return  mapBookContentTypes.pointDetailReportCustomQueryGroups.map(customQueryGrouping => { 
            return customQueryGrouping.customQueries
            .map(customQuery => { 
                return { key: `${customQueryGrouping.id}_${customQuery.id}`, 
                            value: customQuery.id, 
                            name: customQuery.name, 
                            group: customQueryGrouping.name, 
                            isSelected: customQuery.isSelected
                        }}) })
            .flat();
    }; 

    return <div className='app-map-book-builder-panel-container'>
            <div className='app-map-book-builder-button-container'>
                <Button className='app-projects-print-small-button' theme='secondary' tooltip={translate('cancel')} size='small' icon={icons.x}
                    onClick={()=>{ if (_.isFunction(onClose)) onClose(); }} 
                />	
                <Button className='app-projects-print-small-button' theme='primary' tooltip={translate('save')} size='small' icon={icons.save}
                    onClick={()=>{ saveOptions(); }} 
                />	                
            </div>
            <DropDown label={translate('custom_query')} 
                valueProperty={'key'}
                display={'name'}
                selected={pdrCustomQueryKey}
                items={getData()}
                grouped={true}
                onSelect={(o) =>{
                    setPdrCustomQueryKey(o.value.key);
                    setPdrCustomQuery(o.value.value);
                    setPdrCustomQueryName(o.value.name);                     
                }}                                                  
            />     
            <DropDown label={translate('point_detail_reports')} 
                placeholder={translate('select_a_report')}
                valueProperty={'id'}
                display={'name'}
                selected={pdrReport}
                items={
                    (pdrCustomQuery === null || pdrCustomQuery === noCustomQuery) ? mapBookContentTypes.pointDetailReportCustomQueryGroups[0].customQueries[0].reports :
                        mapBookContentTypes.pointDetailReportCustomQueryGroups.flatMap(x => x.customQueries).find(i => i.id === pdrCustomQuery).reports                    
                }
                onSelect={(o) =>{
                    setPdrReport(o.value.id);
                    setPdrReportName(o.value.name);
                    setIsGoldOrg(o.value.isGoldOrg);
                }}                            
            />                                 
        </div>        
}