// Third party imports
import { DndContext, closestCenter, KeyboardSensor,PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

// App imports
import { DraggableItem } from '../../../base/dragAndDrop/draggableItem';
import { Button } from '../../../base/button/button';
import { DropDown } from '../../../base/dropDown/dropDown';
import { PrintPointSourceLabelPopover } from './printPointSourceLabelPopover';
import { PrintPointSourceStorePopover } from './printPointSourceStorePopover';
import { translate } from "../../../../utils/translation"
import { helpers } from '../../../../utils/helpers';
import { icons } from '../../../base/icon/icon';

const _ = require("lodash");

export function PrintPointSources({ available, selected, className, onSelectedChanged }) {

	const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );	

	var classes = ['app-drag-and-drop-content'];

    if (className)
        classes.push(className);
    
	const handleDragEnd = (event)=>{ 
		const {active, over} = event;
	
		if (active.id !== over.id) {
            var newSelected = _.cloneDeep(selected);

            const oldIndex = newSelected.findIndex((sel) => sel.id === active.id);
            const newIndex = newSelected.findIndex((sel) => sel.id === over.id);	

            newSelected = arrayMove(newSelected, oldIndex, newIndex);
            
            onSelectedChanged(newSelected);
        }
	}	
    
    const addItem = () => {

        if (!_.isArray(available) || available.length === 0)
            return;

        const newItem = _.find(available, function (item) { return !item.disabled; });
        if (!_.isObject(newItem))
            return;
        const newSelectedItem = { ...newItem, 
            id: helpers.newGuid(), 
            text: newItem.text, 
            originalId: newItem.id,
            label: false,
            labelColor: { r: 255, g: 255, b: 255, a: 1 },
            labelFillColor: { r: 0, g: 0, b: 0, a: 1 },
            closestStore: false,
            closestStoreCount: 5,
            closestStoreDistanceCutoff: 20,
            closestStoreDistanceMeasurement: 4,
            closestStoreDistanceTextColor: { r: 255, g: 255, b: 255, a: 1 },
            closestStoreLineColor: { r: 0, g: 0, b: 0, a: 1 },
            closestStoreLineWidth: 1,
            type: newItem.type
        };

        onSelectedChanged([ ...selected, newSelectedItem ]);
    }

    const updateItem = (o) => {

        if (_.isUndefined(o.value))
            return;

        const newItem = _.find(available, function (item) { return item.id === o.value; });
        if (!_.isObject(newItem))
            return;
        const newSelected = selected.map((item) => {
            if (item.id === o.id) {
                return { ...item, text: newItem.text, originalId: o.value, type: newItem.type };
            } else {
                return item;
            }
        });

        onSelectedChanged(newSelected);
    }

    const updatePointSource = (o) => {

        const newSelected = selected.map((item) => {
            if (item.id === o.id) {
                return { ...o };
            } else {
                return item;
            }
        });

        onSelectedChanged(newSelected);
    }
    
    return <>
        <div className={classes.join(' ')}>
            <div className={'app-drag-and-drop-header'}>
                <div className={'app-drag-and-drop-label'}>{translate('available_point_sources')}</div>
                <Button className={'app-button-row'} theme='simple' icon={icons.plus} tooltip={translate('add')} disabled={!_.isArray(available) || available.length === 0}
                    onClick={() => { addItem(); }}
                />
            </div>
            <div className={'app-drag-and-drop-body'}>
            { 
                selected.length === 0 ? <div className='app-drag-and-drop-empty'>{translate('add_point_sources')}</div> :
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}> 
                    <SortableContext items={selected} strategy={verticalListSortingStrategy}>
                    {
                        selected.map((item, i) => <DraggableItem 
                            key={item.id}
                            id={item.id} 
                            onDelete={() => { onSelectedChanged(selected.filter(theme => theme.id !== item.id)); }} 
                        >
                            <div className='app-projects-print-content-rows app-projects-print-drag-container'>
                                <DropDown className={'app-projects-print-stretch'} items={available} height={'40px'} display='text' valueProperty='id' selected={item.originalId}
                                    onChange={(o) => { updateItem({ id: item.id, value: o.value }); }}
                                />
                                <PrintPointSourceLabelPopover parentId={`app-point-source-label-options-${i}`} title={`${translate('label')} ${translate('options')}`} pointSource={item} 
                                    updatePointSource={(o) => { updatePointSource(o); }} active={true} />
                                <Button id={`app-point-source-label-options-${i}`} className='app-projects-print-dnd-button' theme='secondary' tooltip={`${translate('label')} ${translate('options')}`} 
                                    size='small' icon={icons.tag} />
                                <PrintPointSourceStorePopover parentId={`app-point-source-store-options-${i}`} title={`${translate('store')} ${translate('options')}`} pointSource={item} 
                                    updatePointSource={(o) => { updatePointSource(o); }} active={true} />
                                <Button id={`app-point-source-store-options-${i}`} className='app-projects-print-dnd-button' theme='secondary' tooltip={`${translate('store')} ${translate('options')}`} 
                                    size='small' icon={icons.store} />
                            </div>
                        </DraggableItem>)
                    } 
                    </SortableContext>
                </DndContext>		
            }			
            </div>
        </div>
    </>
}