// React imports
import { useState, useEffect, useCallback } from 'react';

// App imports
import { Wizard } from '../../../base/wizard/wizard';
import { ManagementReportsStep1 } from './managementReportsStep1';
import { ManagementReportsStep2 } from './managementReportsStep2';
import { reports as reportsModule } from '../../../../modules/reports';
import { translate } from "../../../../utils/translation";
import { userPreferences } from '../../app';
import { helpers } from '../../../../utils/helpers';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { successToast, errorToast } from '../../../base/toast/toast';

const _ = require('lodash');

export function ManagementReports({reportCategory, onClose}) {

    const wizardTitle = translate("management_reports");

    const [loaded, setLoaded] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [managementReports, setManagementReports] = useState([]);
    const [availableReports, setAvailableReports] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [reportParams, setReportParams] = useState([]);	
    const [outputFormat, setOutputFormat] = useState([]);
    const [continueDisabled, setContinueDisabled] = useState(true);
    const [goToStep, setGoToStep] = useState(-1);

    var getReportList = useCallback(
        async () =>{
            setLoaded(false);      
            
            setOutputFormat(userPreferences.ReportOutput);

            const reports = await reportsModule.getReportListForCategory({ reportCategory: reportCategory });

            setManagementReports(reports);
            setAvailableReports(reports.map((report) => { return { id: report.ID, name: report.Name }; }));

            if (_.isArray(reports) && reports.length > 0)
                setSelectedReport(reports[0]);

            setLoaded(true);
        },
        [reportCategory]
    );

    useEffect(() =>{
        getReportList();
    }, [getReportList]);

    const generateReport = async () =>{

        if (!_.isObject(selectedReport))
            return;

        if (reportParams == null)
            return;

        setGenerating(true);

        var allReportParams = [..._.cloneDeep(reportParams), {Key: 'OutputFormat', Value: outputFormat}];

        var result = await reportsModule.createAdminReportRequest({
            reportId: selectedReport.ID,
            reportParams: allReportParams,
            title: selectedReport.Name,
        });     

        if (result === null) {
            errorToast(selectedReport.Name + ' Failed');
        }
        else if (result === "jobcreated") {
            successToast(`${translate('successfully_queued')}: ${selectedReport.Name}`);
        } 
        else {
            helpers.navigateToUrl(legacyEndpoints.handlers.getReportUrl({
                id: result,
                type: 'Management',
                output: outputFormat
            }));                    
        }  

        setGenerating(false);

        onClose();
    };

    const nextStep = (o) =>{
        if (o.step == 2)
            generateReport();
    };

    const updateStateForKey = (o) => {

        setReportParams(currentItems =>
            currentItems.map(item =>
            item.Key === o.key ? { ...item, Value: o.newValue } : item
            )
        );
    };     

    var steps = [
        { title: wizardTitle, text: translate("management_report_step_1"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children: 
            <ManagementReportsStep1 availableReports={availableReports} selectedReport={selectedReport} 
                updateSelectedReport={(o) =>{ setSelectedReport(managementReports.find(r => r.ID === o)); }} 
                onReadyNext={(o) => { setContinueDisabled(!o); }}
            /> 
        },
        { title: wizardTitle, text: translate("management_report_step_2"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children: 
            <ManagementReportsStep2 selectedReport={selectedReport}
            outputFormat={outputFormat} updateOutputFormat={(value) =>{ setOutputFormat(value); }}
                reportParams={reportParams} updateReportParams={(value) =>{ updateStateForKey(value); }} initReportParams={(value) =>{ setReportParams(value); }}
                onReadyNext={(o) => { setContinueDisabled(!o); }}
            /> 
        }
	];
    
    return <>
        <Wizard steps={steps} goToStep={goToStep} completeText={translate('generate')} onClose={() =>{ onClose(); }} onNextStep={(o) =>{ nextStep(o); }} />
    </>
}