// React imports
import { useState } from 'react';

// App imports
import { UserPreferences } from '../../app/userPreferences/userPreferences';
import { Button }  from '../../base/button/button';
import { icons } from '../../base/icon/icon';
import { translate } from "../../../utils/translation";
import { msalConfig } from "../../../config/auth";
import { legacyEndpoints } from '../../../services/legacyEndpoints';

export function UserBadge({context, userImage, userName, userEmail, onClose}) {    

    const [activeUserPreferences, setActiveUserPreferences] = useState(false);		
    const [name, setName] = useState(userName);		

    return <>
        <div className='app-user-badge'>
            <div className='app-user-badge-title'>
                {translate("user_profile")}
            </div>
            <div className='app-user-badge-body'>
                <div>
                    <img className='app-user-badge-userImage' src={userImage} alt={name} />
                </div>
                <div className='app-user-badge-userName'>
                    {name}
                </div>
                <div className='app-user-badge-accountName'>
                    {userEmail?.toLowerCase()}
                </div>
                <div className='app-user-badge-footer'>
                    <Button theme='secondary' size='medium' icon={icons.gear} tooltip={translate('preferences')} onClick={() => {setActiveUserPreferences(!activeUserPreferences);}} />
                    <Button theme='secondary' size='medium' icon={icons.share} tooltip={translate('sign_out')} onClick={() => {
                        if (window.confirm(translate('exit_message')))
                        {
                            legacyEndpoints.beforeUnloadController.abort();
                            context.instance.logoutRedirect({
                                account: context.instance.getAllAccounts()[0],
                                postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
                                authority: msalConfig.auth.authority
                            });
                        }
                    }}/>
                </div>
            </div>            
        </div>
		{activeUserPreferences ?
            <UserPreferences userImage={userImage} onNameChange={(o)=>{ setName(o.value) }} onBack={()=>{ 
                // TBD: this is a hack to delay so that the autoCloser doesn't close the user profile/preferences components
                setTimeout(function() {
                    setActiveUserPreferences(false); 
                }, 100);
            } }/> 
		: ''}
    </>
}