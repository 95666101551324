// React imports
import { useEffect, useState } from 'react';

// Third party imports
import {DndContext,closestCenter,KeyboardSensor,PointerSensor,useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable';

// App imports
import { Button } from '../../base/button/button';
import { Bar } from '../../base/bar/bar';
import { Hideable } from '../../base/hideable/hideable';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { constants } from '../../../utils/constants'
import { map } from '../map/map'
import { DirectionSettings } from './directionSettings/directionSettings'
import { Destination } from './destination/destination';
import { RadioButtonSet } from '../../base/radioButtonSet/radioButtonSet';
import { legacyEndpoints } from "../../../services/legacyEndpoints";
import { helpers } from '../../../utils/helpers';

var _ = require("lodash");

export var directionsManager;

export function DrivingDirections({entity, add}) {

	const [directionsBarCollapsed, setDirectionsBarCollapsed] = useState(true);
    const [selectedTravelMode, setTravelMode] = useState(constants.drivingDirections.travelModes.driving);
	const defaultDestinations = [{ id: crypto.randomUUID(), pointId: null, sortOrder: 1, label: 'startingPoint', value: '', disabled: false }, 
								 { id: crypto.randomUUID(), pointId: null, label: 'endingPoint', value: '', disabled: false }];
	const [destinations, setDestinations] = useState(defaultDestinations);
	const [directionSettings, setDirectionSettings] = useState(null);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
		  coordinateGetter: sortableKeyboardCoordinates,
		})
	  );	

	directionsManager = {
		getAllWaypoints: () => {
			return destinations;
		},
		loadDestinations: (o) => {
		    if (o.destinations.length > 0) {
				onSave({ destinations: o.destinations, zoomToLayer: false });
				setDestinations(o.destinations);
            }
		}
	};

	useEffect(() =>{
		setFromEntity();
    }, [entity]);

	var setFromEntity = async () =>{
		if(entity === null) return; //TODO this shouldn't happen

		const existingDestination = destinations.find(x => x.pointId === entity.id)
		if (add === true && existingDestination === undefined) {
			
			const lat = entity.location.lat; //todo revist, entity change but accessing private?
			const lon = entity.location.lon;

			var emptyDestination =_.find(destinations, d => d.value === '')

			if (emptyDestination === undefined) {
				setDestinations([...destinations, { 
					id: crypto.randomUUID(), 
					pointId: entity.id, 
					label: 'destination', 
					location: entity.location,
					mapValue: lat +',' + lon, 
					value: entity.text, 
					disabled: true  }]); 
			} else {
				emptyDestination.pointId = entity.id;
				emptyDestination.label = 'destination';
				emptyDestination.location = entity.location;
				emptyDestination.mapValue = lat +',' + lon;
				emptyDestination.value = entity.text;
				emptyDestination.disabled = true;
				setDestinations([...destinations])				
			}
		} else {
			if (existingDestination !== undefined)
				setDestinations(destinations.filter(x => x !== existingDestination))
		}
    };	

	const addDestination = (o) =>{
		setDestinations([...destinations, { id: crypto.randomUUID(), pointId: null, label: 'destination', value: '', disabled: false  }]);   	
    };	

	const saveUserPreferences = () =>{		
		legacyEndpoints.service({
			name: 'SaveUserPreferences',
			parameters: {
				Columns: ["DrivingDirections_AvoidHighways", "DrivingDirections_AvoidTollRoads", "DrivingDirections_RoundTrip", "DrivingDirections_Unit", "DrivingDirections_RouteOptimization"],
				Values: [directionSettings.avoidHighways, directionSettings.avoidTollRoads, directionSettings.roundTrip, directionSettings.units, Number(directionSettings.shortestTime)]
				//todo look into RouteOptimization		
			},
			success: function(r) {
				// alert('success'); 
			},
			error: function(e) {
				alert(e); //todo error message handling?
			}
		});			   
    };	

	const clear = ()=>{
		setDirectionsBarCollapsed(true)
		map.clearByType(constants.layers.types.directions);
		setDestinations(defaultDestinations);		
	}	

	const onClear = ()=>{
		//todo add confirmation
		clear();
	};	
	
	const refresh = (o)=>{
		if (o.clear) {
			clear();
		} else {
			setDirectionsBarCollapsed(!o.success);
			if (o.success === false)
				map.clearByType(constants.layers.types.directions);
		}
	};

	const onSave = (o)=>{
		saveUserPreferences();

		const myDestinations = _.isObject(o) ? o.destinations : destinations;

		var mapdestinations = myDestinations.map((x) => {
			if (x.pointId === null)
				return {id: x.id, value: x.value};
			else 
				return {id: x.id, value: x.mapValue};
		});					
		
		map.getDirections({
			panel: document.getElementById('routePanel'), 
			onRefresh: (o) =>{ refresh(o) }, 
			destinations: mapdestinations, 
			travelMode: selectedTravelMode, 
			directionSettings: directionSettings,
			zoomToLayer: _.isObject(o) ? o.zoomToLayer : true
		});
	};

	const onPrint = ()=>{          
		//todo google specific, cleanup, use css file and move   
		let routePanel = document.getElementById("routePanel").cloneNode(true);            

		const header = routePanel.getElementsByClassName("adp-listheader");
		while(header.length > 0){
			header[0].parentNode.removeChild(header[0]);		
		}		

		const route = routePanel.getElementsByClassName("adp-listsel");
		const tbody = route[0]?.parentNode?.parentNode;
		if (tbody)
			for(var i = 0; i < tbody.children.length; i++) {
				if (tbody.children[i].children.length === 0) 
					continue;

				var element = tbody.children[i].children[0];

				if (element.className !== 'adp-listsel') {
					element.remove();
				} else {
					element.style.color = '#2B328C';
					element.style.fontWeight = 400;
					element.style.fontSize = '16px';
					element.style.paddingBottom = '20px';
					routePanel.getElementsByClassName("adp-placemark")[0].childNodes[0].childNodes[0].childNodes[0].style.paddingBottom = '20px';
					routePanel.getElementsByClassName("adp-placemark")[1].childNodes[0].childNodes[0].childNodes[0].style.padding = '20px 0px';
					routePanel.getElementsByClassName("adp-summary")[0].style.paddingBottom = '15px';
					routePanel.getElementsByClassName("adp-marker2")[0].parentNode.style.paddingRight = '10px';
					routePanel.getElementsByClassName("adp-marker2")[1].parentNode.style.paddingRight = '10px';
				}
			}

		routePanel.innerHTML.replace(/[Ã¢Â€Â¢]/g, '-');

		legacyEndpoints.service({
			name: 'PrintHtml',
			parameters: {
				Html: routePanel.innerHTML.replace(/®/g, "&reg;").replace(/™/g, "&trade;").replace(/©/g, "&copy;").replace(/[Ã¢Â€Â¢]/g, '-'),
				CSS: '' //todo create style sheet for all style changes above
			},
			success: function(r) {

				helpers.navigateToUrl(legacyEndpoints.handlers.getPrintUrl({
					id: r,
					isHtml: true,
					isPrintPreview: false,
					outputType: 2,
				}));

			},
			error: function(e) {
				alert(e); 
			}
		});			

	};		

	const validDestinations = ()=>{
		return destinations.length < 2;
	};		

	const showTravelModes = ()=>{
		return <RadioButtonSet className='app-driving-directions-travel-modes'>
				{[{ 
					key: constants.drivingDirections.travelModes.driving, tooltip: translate('driving'), icon: icons.car, disabled: false  
				},			
				{ 
					key: constants.drivingDirections.travelModes.transit, tooltip: translate('transit'), icon: icons.trainSubway, disabled: false 
				},			
				{ 
					key: constants.drivingDirections.travelModes.walking, tooltip: translate('walking'), icon: icons.personWalking, disabled: false 
				},			
				{ 
					key: constants.drivingDirections.travelModes.cycling, tooltip: translate('cycling'), icon: icons.bicycle, disabled: false 
				}]
				.map((travelMode, i) =>{
					return <Button key={travelMode.key} active={selectedTravelMode===travelMode.key} tooltip={travelMode.tooltip} theme='secondary' size='small' icon={travelMode.icon} disabled={false} onClick={() => { setTravelMode(selectedTravelMode === travelMode.key ? null : travelMode.key); }} />
				})}			
			</RadioButtonSet>
	};		

	const showDestinations = ()=>{
		return <div className='app-driving-directions-destinations'>	
			<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}> 
				<SortableContext items={destinations} strategy={verticalListSortingStrategy}>
					{
						destinations.map((destination, i) => <Destination key={destination.id} meta={{'index':i, 'length':destinations.length}} id={destination.id} destination={destination}
									onChangeClick={(o) => {
										destination.value = o;
										setDestinations([...destinations]);
									}}
									onDeleteClick={() => {setDestinations(destinations.filter(x => x !== destination))}} 
									onButtonClick={useCurrentLocation} 
									/>)
					} 
				</SortableContext>
			</DndContext>					
		</div>	
	};		

	const useCurrentLocation = (id)=>{ 
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((o)=>{ setCurrentLocation(o.coords, id)}, (error)=>{alert(error)}); //todo how to handle error messages, alert for now
		  } else {
			alert("Geolocation not supported");//todo how to handle error messages, alert for now
		  }
	}	
	
	const setCurrentLocation = (coords, id)=> {
		const existingDestination = destinations.find(x => x.id === id)

		if (existingDestination !== undefined) {
			existingDestination.pointId = 'currentLocation'; //todo change to a type
			existingDestination.mapValue = coords.latitude +',' + coords.longitude;
			existingDestination.value = translate('currentLocation');
			existingDestination.disabled = true;
			setDestinations([...destinations])			
		}
	}

	const handleDragEnd = (event)=>{ 
		const {active, over} = event;
	
		if (active.id !== over.id) {
		  setDestinations(([...destinations]) => {
			const oldIndex = destinations.findIndex((destination) => destination.id === active.id);
			const newIndex = destinations.findIndex((destination) => destination.id === over.id);	

			return arrayMove(destinations, oldIndex, newIndex);
		  });
		}
	}	

	return <>
		{showTravelModes()}

		{showDestinations()}

		<div className='app-driving-directions-buttons'>
			<div className='app-driving-directions-confim'>
				<Button theme='secondary' size='small' icon={icons.plus} onClick={() => {addDestination()}} />	
				<Button className='app-driving-directions-close-button' theme='secondary' size='small' icon={icons.eraser} onClick={() => {onClear()}} />
				<Button theme='primary' size='small' disabled={validDestinations()} icon={icons.check} onClick={() => {onSave()}} />
			</div>
		</div>			
		
		<div className='app-driving-directions-panel'>
			<DirectionSettings onRefresh={(o)=>{ setDirectionSettings(o) }}/>

			<Hideable hide={directionsBarCollapsed}>
				<Bar className='app-driving-directions-direction-settings' text={translate('directions')} expandable='true' actions={[{
						icon: icons.print,			
						onClick: (e) =>{
							e.stopPropagation();
							onPrint();
						}
					}]} />
			</Hideable>

			<div id='routePanel' />				
		</div>
	</>

}