// React imports
import { useState } from 'react'

// App imports
import { Button }  from '../../base/button/button';
import { Card } from '../../base/card/card';

export function TabbedContent({items}) {

    const [activeTab, setActiveTab] = useState(0);    

    return <div className='app-tabbed-content'>
        <div className='app-tabbed-content-tabs'>
        {
            items.map((item, i) => {
                return <Button 
                    key={i}
                    className='app-navigation-button' 
                    theme='navigation' 
                    text={item.text} 
                    icon={item.icon} 
                    active={activeTab === i}
                    disabled={item.disabled} 
                    onClick={() => { 
                        setActiveTab(i); 
                    }}
                />
            })
        }
        </div>
        <div className='app-tabbed-content-cards'>
        {
            items.map((item, i) =>{
                return <Card key={i} className={item.className} loaded={item.loaded} open={activeTab === i}>
                    {item.children}
                </Card>									
            })
        }		
        </div>
    </div>
}