// Third party imports
import {NumberBox as DxNumberBox} from 'devextreme-react/number-box';

export function NumberBox({ className, label, value, disabled, height, maxValue, minValue, format='#', onChange }) {

    var classes = ['app-text-box'];

    if (className)
        classes.push(className); 

    return <div className={classes.join(' ')}>
        <DxNumberBox 
            label={label} 
            value={value}
            disabled={disabled}
            stylingMode='outlined'
            height={height? height : '30px'}
            max={maxValue}
            min={minValue}
            format={format}
            onValueChanged={(e)=>{                
                if (e.value === null || e.value === e.previousValue)
                    return

                if (onChange)
                    onChange({                        
                        value: e.value,
                        userChanged: e.event !== undefined
                    });
            }}
        />
    </div>    
}