// Third party imports
import moment from 'moment';

// React imports
import { useState, useRef } from 'react';

// App imports
import { LargePanel } from '../../../base/largePanel/largePanel';
import { Button } from '../../../base/button/button';
import { ConfirmButton } from '../../../base/confirmButton/confirmButton';
import { DataGrid } from '../../../base/dataGrid/dataGrid'
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { translate } from "../../../../utils/translation";
import { helpers } from "../../../../utils/helpers";
import { constants } from '../../../../utils/constants';
import { Icon, icons } from '../../../base/icon/icon';
import { successToast } from '../../../base/toast/toast';
import { reports as reportsModule } from '../../../../modules/reports';
import { SharedUsers } from '../../sharedUsers/sharedUsers';
import { ModalCard } from '../../../base/modalCard/modalCard';

const _ = require('lodash');

export function ReportManager({onClose}) {

    const grid = useRef(null);
    const [generating, setGenerating] = useState(false);
    const [showSharedUsers, setShowSharedUsers] = useState({show: false, objectId: null});

    const renderType = (o)=>{

        const renderIcon = (icon, text) =>{
            return <span className='app-job-management-icon-wrapper' title={text}>
                <Icon className='app-global-icon-body' icon={icon} />
            </span>
        };

        const text = reportsModule.getReportCategoryTypeName(o.ReportType);

        switch (o.ReportType) {
            default:
                return null;
            case constants.reportCategoryTypes.demographicSummary:
                return renderIcon(icons.fileLines, text);
            case constants.reportCategoryTypes.demographicDetail:
                return renderIcon(icons.fileLines, text);
            case constants.reportCategoryTypes.demographicComparison:
                return renderIcon(icons.fileLines, text);
            case constants.reportCategoryTypes.demographicIndex:
                return renderIcon(icons.fileLines, text);
            case constants.reportCategoryTypes.demographicQuadrant:
                return renderIcon(icons.pieChart, text);
            case constants.reportCategoryTypes.pointDetail:
                return renderIcon(icons.braille, text);
            case constants.reportCategoryTypes.management:
                return renderIcon(icons.people, text);
            case constants.reportCategoryTypes.reportPackages:
                return renderIcon(icons.braille, text);
            case constants.reportCategoryTypes.voidAnalysis:
                return renderIcon(icons.empty, text);
            case constants.reportCategoryTypes.uberRetail:
                return renderIcon(icons.mobile, text);
            case constants.reportCategoryTypes.marketShare:
                return renderIcon(icons.pieChart, text);
            case constants.reportCategoryTypes.mapBook:
                return renderIcon(icons.bookBookmark, text);
            case constants.reportCategoryTypes.analyticsProjection:
                return renderIcon(icons.chartMixed, text);
        }
    };

    const renderName = (o) =>{
        return <div className='app-job-management-name-wrapper'>{o.ReportName}</div>
    };    

    const renderOwner = (o) =>{
        return <div className='app-job-management-name-wrapper'>{o.OwnerName}</div>
    };    

    const renderDaysLeft = (o) =>{
        const daysLeft = Math.round((o.ExpirationDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24));

        return <div className='app-report-manager-cell-display'>
            <div>{helpers.formatDate({ date: o.ExpirationDate, format: "MM/DD/YYYY" })}</div>
            <div className={daysLeft < 5 ? 'app-report-manager-days-left' : ''}>{daysLeft} {translate('days')}</div>
        </div>
    };    

    const refresh = () =>{
        grid.current.instance.refresh();
    };

    const shareReport = async (o) =>{
        setShowSharedUsers({show: true, objectId: o.Id});
    };

    const deleteReport = async (o) =>{

        setGenerating(true);

        const success = await reportsModule.deleteSavedReport({ Id: o.Id });

        if (success)
            successToast(translate('success'));
 
        setGenerating(false);

    };

    const retainReport = async (o) =>{

        setGenerating(true);

        const success = await reportsModule.retainSavedReport({ Id: o.Id });

        if (success)
            successToast(translate('success'));
 
        setGenerating(false);
        
    };
    
    const renderActions = (o)=>{
        var actions = [];

        // Retain
        if (!o.IsRetained && !o.IsShared)
            actions.push(<Button theme='simple' icon={icons.clock} 
                tooltip={translate('retain')} 
                onClick={() => { 
                    retainReport(o);
                }} 
            />);
        else
            actions.push(<div style={{width: '20px'}}></div>);

        // Share
        if (!o.IsShared)
            actions.push(<Button theme='simple' icon={icons.shareAlt} 
                tooltip={translate('share')} 
                onClick={() => { 
                    shareReport(o); 
                }}
            />);
        else
            actions.push(<div style={{width: '20px'}}></div>);

        // Download
        if (_.isString(o.FileId) && o.FileId.length > 0)
            actions.push(<Button theme='simple' icon={icons.download} 
                tooltip={translate('download')} 
                onClick={() => {console.log(o.FileId); helpers.navigateToUrl(legacyEndpoints.handlers.getFileUrl({ id: o.FileId }))}} 
            />);
        else
            actions.push(<div style={{width: '20px'}}></div>);

        // Delete
        if (!o.IsShared)
            actions.push(<ConfirmButton theme='simple' icon={icons.trash} 
                tooltip={translate('delete')} 
                clearText={translate('delete')}
                onConfirm={() => { 
                    deleteReport(o); 
                }} 
            />);
        else
            actions.push(<div style={{width: '20px'}}></div>);

        return <div className='app-report-manager-cell-display'>
            {
                actions.map((action ,i) => { 
                    return <div key={i}>
                        {action}
                    </div>
                })
            }
        </div>
    }

    const createHeaderFilter = (options)=>{
        options.dataSource.useDefaultSearch = false;
        options.dataSource.load = async (o) => {

            var filters = helpers.getFilterBuilder({ filter: options.dataSource.filter });

            var result = await reportsModule.getSavedReportsColumnFilter({
                field: options.fieldName,
                skip: _.isNumber(o.skip) ? o.skip : 0,
                take: _.isNumber(o.take) ? o.take : 20,
                searchOperation: helpers.getFilterOperator(o.searchOperation),
                searchValue: o.searchValue,
                filters: filters
            });

            if (options.fieldName === 'ReportType') {
                result.data = _.map(result.data, (o) => {
                    return { ...o, text: reportsModule.getReportCategoryTypeName(parseInt(o.text)) };
                });
            }
            else if (options.fieldName === 'CreationDate' || options.fieldName === 'ExpirationDate') {
                result.data = _.map(result.data, (o) => {
                    return { ...o, text: moment(o.text).format("MM/DD/YYYY") };
                });
            }

            return {
                data: result.data,
                totalCount: result.total
            };
        }
    }

    return  <LargePanel text={translate('report_manager')} loaded={true} generating={generating} onClose={() =>{ onClose(); }}>
        <div className='app-report-manager-header'>
            <div></div>
            <Button theme='primary' size='small' icon={icons.refresh} tooltip={translate('refresh')} onClick={() => { refresh(); }} />
        </div>
        <DataGrid
            ref={grid}
            className='app-job-management'
            defaultPageSize={10}
            remoteOperations={ {groupPaging: true, remoteOperations: true} }
            showHeaderFilter={true}
            showFilterRow={true}
            activeRefreshOnly={true}
            key='Id'
            columns={[
                { name: 'ReportType', alignment:'center', caption: translate('type'), width: '100px', allowFiltering: true, allowSorting: false, headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'ReportType' }); }, onRender: (o) => { return renderType(o); }},
                { name: 'ReportName', caption: translate('report'), width: '410px', allowFiltering: true, allowSorting: true, headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'ReportName' }); }, onRender: (o) => { return renderName(o); }},
                { name: 'ExpirationDate', caption: translate('expires'), width: '170px', allowFiltering: true, allowSorting: true, dataType: 'date', format: 'MM/dd/yyyy', allowHeaderFiltering: false, onRender: (o) => { return renderDaysLeft(o); }},
                { name: 'OwnerName', caption: translate('owner'), width: '190px', allowFiltering: true, allowSorting: true, headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'OwnerName' }); }, onRender: (o) => { return renderOwner(o); }},
                { name: 'CreationDate', caption: translate('created'), width: '120px', allowFiltering: true, allowSorting: true, dataType: 'date', format: 'MM/dd/yyyy', allowHeaderFiltering: false},
                { name: 'Actions', alignment:'center', caption: translate('actions'), allowFiltering: false, allowSorting: false, onRender: (o) => { return renderActions(o); }}
            ]}
            onCellPrepared={(e)=>{
                // for ReportType, we allow filtering in the header, but we don't want to show the filter row
                if (e.rowType === "filter" && e.column.dataField === "ReportType")
                    e.cellElement.innerHTML = '';
            }}
            onLoad={async (o)=>{        
                var filters = helpers.getFilterBuilder({ filter: o.filter });

                var sorts = [];
                if (_.isArray(o.sort))
                    o.sort.forEach(sort => { 
                        sorts.push({ fieldId: o.sort[0].selector, sortOrder: sort.desc ? 1 : 0 });
                    });

                var data = await reportsModule.getFilteredSavedReports({ skip: o.skip, take: o.take, filters: filters, sorts: sorts });

                var dataSource = {
                    results: []
                };

                data.results.forEach(result => {
                    var report = {};

                    Object.entries(result).forEach(([key, value]) => {
                        if (key === 'CreationDate' || key === 'ExpirationDate')
                            value = helpers.convertJSONDate(value);

                        report[key] = value;
                    });

                    dataSource.results.push(report);
                });

                return {
                    data: dataSource.results,
                    totalCount: data.total
                };
            }}
        />
        {
            !showSharedUsers.show ? '' :
                <ModalCard className='app-shared-users-modal-card' loaded={true} open={showSharedUsers.show}>
                    <SharedUsers objectId={showSharedUsers.objectId} sharingObjectType={constants.sharingObjectType.SavedReport} 
                                    onClose={() => { setShowSharedUsers({show: false, objectId: null}); }} />
                </ModalCard>        
        }    
    </LargePanel>
}