// React imports
import React, { useCallback, useEffect, useState } from 'react';

import { DataList } from '../../base/dataList/dataList';
import { Button } from '../../base/button/button';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';

import _ from 'lodash';
import { selections } from '../../../modules/selections';

export const Selections = ({entities, onSelect}) => {
    
    const [selected, setSelected] = useState([]);
    const [available, setAvailable] = useState([]);
    
    useEffect(()=>{

        var availableItems = [];
        var selectedItems = [];
                    
        entities.forEach(entity =>{

            var listItem = { id: `${entity.layer.id}_${entity.id}`, text: entity.text, entity: entity };
            
            var layer = availableItems.find(x => x.id === entity.layer.id);
            if (!_.isObject(layer)){
                layer = { id: entity.layer.id, key: entity.layer.text, items: [listItem] };
                availableItems.push(layer);
            }
            else
                layer.items.push(listItem);

            selectedItems.push(listItem.id);
        })        

        availableItems.forEach(layer => {
            delete layer.id;
            layer.key = `${layer.key} (${layer.items.length})`;
        });

        setAvailable(availableItems);
        setSelected(selectedItems);        

    }, [entities]);

    const getEntities = useCallback(() =>{
        var entities = [];
        
        available.forEach(item =>{
            item.items.forEach(item =>{
                
                if (selected.filter(x => x === `${item.entity.layer.id}_${item.entity.id}`).length === 0)
                    return;
    
                entities.push(item.entity);
            });
        });

        return entities;
    }, [selected, available]);

    const newSelection = useCallback(()=>{
        onSelect(getEntities());
    }, [selected, available]);

    const appendSelection = useCallback(()=>{        
        onSelect(
            selections.append(getEntities())
        );
    }, [selected, available]);

    const removeSelection = useCallback(()=>{
        onSelect(
            selections.remove(getEntities())
        );
    }, [selected, available]);

    const inverseSelection = useCallback(()=>{
        onSelect(
            selections.inverse(getEntities())
        );        
    }, [selected, available]);

    return <div className='app-selections-container'>
        <div className='app-selections-actions'>
            <Button className='app-selections-cancel' theme='secondary' size='small' icon={icons.x} tooltip={translate('cancel_selection_action')} />                    
            <Button theme='primary' size='small' icon={icons.minus} tooltip={translate('remove_points_within_area')} onClick={removeSelection} />
            <Button theme='primary' size='small' icon={icons.circlesOverlap} tooltip={translate('select_nonselected_points')} onClick={inverseSelection} />
            <Button theme='primary' size='small' icon={icons.add} tooltip={translate('append_points_selection')} onClick={appendSelection} />
            <Button theme='primary' size='small' icon={icons.star} tooltip={translate('remove_and_replace_points')} onClick={newSelection} />
        </div>
        <div className='app-selections'>
            <DataList
                keyExpr={'id'}
                grouped={true}
                dataSource={available}
                height={'auto'}
                itemRender={(item) =>{
                    return item.text;
                }}                
                selectByClick={true}
                selectedItems={selected}
                selectedItemsChange={setSelected}
            />
        </div>        
    </div>
}