// React imports
import { useState, useEffect } from 'react';

// App imports
import { Bar } from '../../../base/bar/bar';
import { Icon } from '../../../base/icon/icon';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { constants } from '../../../../utils/constants';

export function BulkClosestStoresStep3({originFormat, destinationFormat, originExcelFile, originDataSources, originDataSource, destinationExcelFile, 
    destinationDataSources, destinationDataSource, jobName, calculationMethod, initialRadius, maxRadius, destinationReturn, excludeDestinations, includeOriginData, onEditStep}) {

    const [originFormatText, setOriginFormatText] = useState('');
    const [destinationFormatText, setDestinationFormatText] = useState('');
    const [originDataSourceText, setOriginDataSourceText] = useState('');
    const [originDataSourceTooltip, setOriginDataSourceTooltip] = useState('');
    const [destinationDataSourceText, setDestinationDataSourceText] = useState('');
    const [destinationDataSourceTooltip, setDestinationDataSourceTooltip] = useState('');
    const [jobNameText, setJobNameText] = useState('');
    const [jobNameTooltip, setJobNameTooltip] = useState('');
    const [calculationMethodText, setCalculationMethodText] = useState('');
    const [initialRadiusText, setInitialRadiusText] = useState('');
    const [maxRadiusText, setMaxRadiusText] = useState('');
    const [destinationReturnText, setDestinationReturnText] = useState('');
    const [excludeDestinationsText, setExcludeDestinationsText] = useState('');
    const [includeOriginDataText, setIncludeOriginDataText] = useState('');

    useEffect(() => {

        setOriginFormatText(`${translate('origin_format')}\n\n${originFormat}`);
        setDestinationFormatText(`${translate('destination_format')}\n\n${destinationFormat}`);

        setJobNameText(`${translate('job_name')}\n\n${jobName.length > 40 ? jobName.substring(0, 40).trim() + '...' : jobName}`);
        if (jobName.length > 40)
            setJobNameTooltip(jobName);

        switch (calculationMethod) {
            default:
            case constants.bulkReports.calculationMethods.euclidian:
                setCalculationMethodText(`${translate('closest_calculation_method')}\n\n${translate('euclidean_distance')}`);
                break;
            case constants.bulkReports.calculationMethods.driveTime:
                setCalculationMethodText(`${translate('closest_calculation_method')}\n\n${translate('drive_time')}`);
                break;
            case constants.bulkReports.calculationMethods.driveDistance:
                setCalculationMethodText(`${translate('closest_calculation_method')}\n\n${translate('drive_distance')}`);
                break;
        }

        setInitialRadiusText(`${translate('initial_radius')}\n\n${initialRadius.toString()}`);
        setMaxRadiusText(`${translate('max_radius')}\n\n${maxRadius.toString()}`);
        setDestinationReturnText(`${translate('destination_return')}\n\n${destinationReturn.toString()}`);
        setExcludeDestinationsText(`${translate('exclude_destination')}\n\n${excludeDestinations ? translate('yes') : translate('no')}`);
        setIncludeOriginDataText(`${translate('include_origin_data_results')}\n\n${includeOriginData ? translate('yes') : translate('no')}`);

        if (originFormat === constants.bulkReports.dataSources.excel) {
            setOriginDataSourceText(`${translate('origin_source')}\n\n${originExcelFile.name.length > 40 ? originExcelFile.name.substring(0, 40).trim() + '...' : originExcelFile.name}`);
            if (originExcelFile.name.length > 40)
                setOriginDataSourceTooltip(originExcelFile.name);
        }
        else {
            const originSources = originDataSources.filter(group => group.sources.some(source => source.id === originDataSource));
        
            originSources.forEach((group) =>{
                group.sources.forEach((source) =>{
                    if (source.id === originDataSource) {
                        setOriginDataSourceText(`${translate('origin_source')}\n\n${source.text.length > 40 ? source.text.substring(0, 40).trim() + '...' : source.text}`);
                        if (source.text.length > 40)
                            setOriginDataSourceTooltip(source.text);
                    }
                });
            });
        }

        if (destinationFormat === constants.bulkReports.dataSources.excel) {
            setDestinationDataSourceText(`${translate('destination_source')}\n\n${destinationExcelFile.name.length > 40 ? destinationExcelFile.name.substring(0, 40).trim() + '...' : destinationExcelFile.name}`);
            if (destinationExcelFile.name.length > 40)
                setDestinationDataSourceTooltip(destinationExcelFile.name);
        }
        else {
            const destinationSources = destinationDataSources.filter(group => group.sources.some(source => source.id === destinationDataSource));
        
            destinationSources.forEach((group) =>{
                group.sources.forEach((source) =>{
                    if (source.id === destinationDataSource) {
                        setDestinationDataSourceText(`${translate('destination_source')}\n\n${source.text.length > 40 ? source.text.substring(0, 40).trim() + '...' : source.text}`);
                        if (source.text.length > 40)
                            setDestinationDataSourceTooltip(source.text);
                    }
                });
            });
        }

        if (onEditStep) onEditStep({ step: -1 });

    }, [originFormat, destinationFormat, originExcelFile, originDataSources, originDataSource, destinationExcelFile, destinationDataSources, destinationDataSource, 
        jobName, calculationMethod, initialRadius, maxRadius, destinationReturn, excludeDestinations, includeOriginData, onEditStep])

    return <>
        <div className='app-tools-bulk-divided-container app-tools-bulk-container-top'>
            <div className='app-tools-bulk-divided-column'>
                {
                    [
                        { text: jobNameText, toolTip: jobNameTooltip },
                        { text: originFormatText, toolTip: '' },
                        { text: originDataSourceText, toolTip: originDataSourceTooltip },
                        { text: destinationFormatText, toolTip: '' },
                        { text: destinationDataSourceText, toolTip: destinationDataSourceTooltip }
                    ].map((editButton, i) => {
                        return (
                            <div key={i} className='app-option-selector app-tools-bulk-bar-edit'>
                                <div className='app-option-selector-check'>
                                    <Icon icon={icons.edit} />
                                </div>
                                <Bar className={'app-tools-bulk-bar-buttons app-tools-bulk-bar-long'} 
                                    height={'75'}
                                    text={editButton.text} 
                                    tooltip={editButton.toolTip} 
                                    onClick={() => { if (onEditStep) onEditStep({ step: i === 0 ? 1 : 0 }); }} 
                                    disabled={false} 
                                /> 
                            </div>
                        )
                    })
                }
            </div>
            <div className='app-tools-bulk-divided-column'>
                {
                    [
                        { text: calculationMethodText, toolTip: '' },
                        { text: initialRadiusText, toolTip: '' },
                        { text: maxRadiusText, toolTip: '' },
                        { text: destinationReturnText, toolTip: '' },
                        { text: excludeDestinationsText, toolTip: '' },
                        { text: includeOriginDataText, toolTip: '' }
                    ].map((editButton, i) => {
                        return (
                            <div key={i} className='app-option-selector app-tools-bulk-bar-edit'>
                                <div className='app-option-selector-check'>
                                    <Icon icon={icons.edit} />
                                </div>
                                <Bar className={'app-tools-bulk-bar-buttons app-tools-bulk-bar-long'} 
                                    height={'75'}
                                    text={editButton.text} 
                                    tooltip={editButton.toolTip} 
                                    onClick={() => { if (onEditStep) onEditStep({ step: 1 }); }} 
                                    disabled={false} 
                                /> 
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </>
}