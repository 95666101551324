// React imports
import { useEffect, useState } from 'react';

// App imports
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { constants } from '../../../../utils/constants'
import { Bar } from '../../../base/bar/bar';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";

const _ = require('lodash');

export function DirectionSettings({onRefresh}) {   
   var defaultDirectionSettings = { 
									   avoidHighways: false,
									   avoidTollRoads: false,
									   roundTrip: false,
									   units: constants.drivingDirections.units.imperial,
									   shortestTime: false,
									   shortestDistance: false
								  };
	const [directionSettings, setDirectionSettings] = useState(defaultDirectionSettings);

	useEffect(()=>{
		legacyEndpoints.service({
			name: 'GetUserPreferences',
			success: function(r) {
				directionSettings.avoidHighways = r.DirectionsPreferences.AvoidHighways;
				directionSettings.avoidTollRoads = r.DirectionsPreferences.AvoidTollRoads;
				directionSettings.roundTrip = r.DirectionsPreferences.RoundTrip;
				directionSettings.units = r.DirectionsPreferences.Unit;
				directionSettings.shortestTime = r.DirectionsPreferences.RouteOptimization;
				// directionSettings.shortestDistance = r.DirectionsPreferences.RouteOptimization; //todo just a bing thing?

				setDirectionSettings(_.cloneDeep(directionSettings))
			},
			error: function(e) {
				alert(e);
			}
		});	
	},[]);

    useEffect(()=>{
		if (onRefresh)
			onRefresh(directionSettings);	
    },[directionSettings]);

	return <>
		<Bar className='app-driving-directions-direction-settings' text={translate('directionSettings')} expandable='true'>
			
			<div className='app-driving-directions-direction-settings-label'>{translate('routeOptions')}</div>

			<Bar
					icon={icons.road}
					className='app-driving-directions-direction-settings-item'
					text={translate('avoidHighways')}
					active={directionSettings.avoidHighways}
					disabled={false}
					onClick={ ()=>{directionSettings.avoidHighways = !directionSettings.avoidHighways; setDirectionSettings(_.cloneDeep(directionSettings))} }
			/>		
			<Bar
					icon={icons.roadBarrier}
					className='app-driving-directions-direction-settings-item'
					text={translate('avoidTollRoads')}
					active={directionSettings.avoidTollRoads}
					disabled={false}
					onClick={ ()=>{directionSettings.avoidTollRoads = !directionSettings.avoidTollRoads; setDirectionSettings(_.cloneDeep(directionSettings))} }
			/>	
			<Bar
					icon={icons.arrowsRotate}
					className='app-driving-directions-direction-settings-item'
					text={translate('roundTrip')}
					active={directionSettings.roundTrip}
					disabled={false}
					onClick={ ()=>{directionSettings.roundTrip = !directionSettings.roundTrip; setDirectionSettings(_.cloneDeep(directionSettings))} }
			/>							

			<div className='app-driving-directions-direction-settings-label'>{translate('units')}</div>

			<div className='app-driving-directions-direction-settings-units'>
				<div>
					<Bar
					icon={icons.ruler}
					className='app-driving-directions-direction-settings-units'
					text={translate('mi')}
					active={directionSettings.units===constants.drivingDirections.units.imperial}
					onClick={ ()=>{directionSettings.units = constants.drivingDirections.units.imperial; setDirectionSettings(_.cloneDeep(directionSettings))} }
					/>								
				</div>
				<div>
					<Bar
					icon={icons.ruler}
					className='app-driving-directions-direction-settings-units'
					text={translate('km')}
					active={directionSettings.units===constants.drivingDirections.units.metric}
					onClick={ ()=>{directionSettings.units = constants.drivingDirections.units.metric; setDirectionSettings(_.cloneDeep(directionSettings))} }
					/>								
				</div>					
			</div>

			<div className='app-driving-directions-direction-settings-label'>{translate('routeOptimization')}</div>

			<Bar
				icon={icons.clockEight}
				className='app-driving-directions-direction-settings-item'
				text={translate('shortestTime')}
				active={directionSettings.shortestTime}
				onClick={ ()=>{directionSettings.shortestTime = !directionSettings.shortestTime; setDirectionSettings(_.cloneDeep(directionSettings))}}
			/>								
			<Bar
				icon={icons.ruler}
				className='app-driving-directions-direction-settings-item'
				text={translate('shortestDistance')}
				active={directionSettings.shortestDistance}
				onClick={ ()=>{directionSettings.shortestDistance = !directionSettings.shortestDistance; setDirectionSettings(_.cloneDeep(directionSettings))}}
			/>				
		</Bar>
	</>
}