// React imports
import { useState, useEffect } from 'react';

// Third party imports
import validator from 'validator';

// App imports
import { Loader } from '../../../base/loader/loader';
import { TextBox } from '../../../base/textBox/textBox';
import { Button }  from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { userPreferences } from '../../app';
import { successToast, errorToast } from '../../../base/toast/toast';

export function UserProfile({userImage, onNameChange}) {

    const textBoxHeight = '55px';

    const [userProfileLoaded, setUserProfileLoaded] = useState(false);
    const [userProfile, setUserProfile] = useState(null);		

    useEffect(() => {
        legacyEndpoints.service({
            name: 'GetUserInformation',
            parameters: {
                Id: userPreferences.UserGUID
            },
            success: function(r) {
                setUserProfile(r);
                setUserProfileLoaded(true);
            }
        });
    }, []);
    
    return <>
        <Loader className='app-card-body-loader' loaded={userProfileLoaded} />
        {!userProfileLoaded ? '' : 
            <div className='app-user-profile'>
                <div className='app-user-profile-image'>
                    <img className='app-user-badge-userImage' src={userImage} alt={userProfile.Name} />
                    <div className='app-user-profile-image-btns'>
                        <Button theme='tertiary' size='small' icon={icons.upload} tooltip={translate('upload')} disabled={true} />
                        <Button theme='tertiary' size='small' icon={icons.trash} tooltip={translate('delete')} disabled={true} />
                    </div>
                </div>
                <div className='app-user-profile-details'>
                    <TextBox label={translate('display_name')} value={userProfile.Name} height={textBoxHeight} onChange={(o) => { setUserProfile({ ...userProfile, Name: o.value }); }} />
                    <TextBox label={translate('email_address')} value={userProfile.Email} height={textBoxHeight} disabled={true} />
                    <TextBox label={translate('job_title')} value={userProfile.Title} height={textBoxHeight} onChange={(o) => { setUserProfile({ ...userProfile, Title: o.value }); }} />
                    <TextBox label={translate('company')} value={userProfile.Organization} height={textBoxHeight} disabled={true} />
                    <TextBox label={translate('department')} value={userProfile.Department} height={textBoxHeight} onChange={(o) => { setUserProfile({ ...userProfile, Department: o.value }); }} />
                    <TextBox label={translate('phone_number')} value={userProfile.Phone} height={textBoxHeight} onChange={(o) => { setUserProfile({ ...userProfile, Phone: o.value }); }} />
                    <TextBox label={translate('linkedin_profile_url')} value={userProfile.LinkedIn} height={textBoxHeight} onChange={(o) => { setUserProfile({ ...userProfile, LinkedIn: o.value }); }} />
                    <div className='app-user-preferences-save'>
                        <Button theme='tertiary' size='medium' icon={icons.check} tooltip={translate('save')} onClick={()=>{ 
                            if (!userProfile.Name.trim()) {
                                errorToast(translate('user_preferences_error_display_name'));
                                return;
                            }

                            if (userProfile.Phone.trim().length > 0)
                                if (!validator.isMobilePhone(userProfile.Phone.trim())) {
                                    errorToast(translate('user_preferences_error_phone_number'));
                                    return;
                                }

                            if (userProfile.LinkedIn.trim().length > 0)
                                if (!validator.isURL(userProfile.LinkedIn.trim())) {
                                    errorToast(translate('user_preferences_error_linkedin'));
                                    return;
                                }

                            legacyEndpoints.service({
                                name: 'SaveUserInformation',
                                parameters: {
                                    Columns: ["UserName", "Phone", "Department", "Title", "LinkedInUrl"],
                                    Values: [userProfile.Name.trim(), userProfile.Phone.trim(), userProfile.Department.trim(), userProfile.Title.trim(), userProfile.LinkedIn.trim()]
                                },
                                success: function(r) {
                                    userPreferences.UserName = userProfile.Name.trim();

                                    userProfile.Name = userProfile.Name.trim();
                                    userProfile.Title = userProfile.Title.trim();
                                    userProfile.Department = userProfile.Department.trim();
                                    userProfile.Phone = userProfile.Phone.trim();
                                    userProfile.LinkedIn = userProfile.LinkedIn.trim();

                                    if (onNameChange) onNameChange({ value: userProfile.Name.trim() });

                                    successToast(translate('success'));
                                }
                            });
                        }} />
                    </div>
                </div>
            </div>
        }
    </>
}