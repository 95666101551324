// App imports
import { Button } from '../button/button';
import { Hideable } from '../hideable/hideable';
import { Loader } from '../loader/loader';
import { icons } from '../icon/icon';
import { translate } from '../../../utils/translation';

export function Panel ({className, open, text, onClose, onReload, children, loaded, generated, generatingLogo, tools}) {

    var classes = ['app-panel'];

    if (className)
        classes.push(className);

    return <Hideable className={classes.join(' ')} hide={!open}>
        <div className='app-panel-title'>
            {text}
            {onReload ? <Button className='app-panel-reload' theme='action' icon={icons.repeatArrow} tooltip={translate('reload')} onClick={() => onReload() } /> : ''}
            <Button className='app-panel-help' theme='action' icon={icons.question} tooltip={translate('help')} disabled='true'/>
            <Button className='app-panel-close' theme='action' icon={icons.x} tooltip={translate('close')} onClick={() => { onClose() }}/>
        </div>
        <div className='app-panel-body'>
            {tools ? 
                <div className='app-panel-tool-bar'> 
                { loaded ? tools : '' }
                </div> : 
                ''
            }            
            {children}
        </div>
        { loaded===false ? <Loader className='app-panel-body-loader' /> : '' }
        { generated===false ? <Loader theme='generate' logo={generatingLogo} showLogo={generatingLogo !== undefined} /> : '' }
    </Hideable>
}