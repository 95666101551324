// Third party imports
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

// App imports
import { icons } from '../../../base/icon/icon';
import { Icon } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { TextBox } from '../../../base/textBox/textBox'
import { Button } from '../../../base/button/button';

export function Destination({destination, meta, onDeleteClick, onChangeClick, onButtonClick}) {

	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging
	  } = useSortable({id: destination.id});

	  const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		zIndex: isDragging ? "100" : "auto",
		opacity: isDragging ? 0.3 : 1
		
	  };

	const onDelete = (o) => {		
		onDeleteClick();
	}	
	  
	const onChange = (o) => {		
		onChangeClick(o);
	}	

	const getLabel = (o) => {
		
        switch(o.index){
            default:
                return translate('destination') ;
            case 0:
                return translate('startingPoint');
            case o.length - 1:
                return translate('endingPoint');
        }		
	}	
	
	const currentLocationAction = {
        icon: icons.crosshair,
        onClick: () => {
            onButtonClick(destination.id);
        },
    };  

	return <div ref={setNodeRef}  key={destination.id} style={style} className='app-driving-directions-destination-row'>
							<table>
								<tbody>
									<tr>
										<td>
											<TextBox className='app-driving-directions-destination' height='47px' label={getLabel(meta)} value={destination.value} disabled={destination.disabled} 
												onChange={
													(o) => {onChange(o.value);}
												}
												actions = {[currentLocationAction]}
											/>	
										</td>
										<td>
											<div className='app-driving-directions-destination-actions' >												
												<button {...attributes} {...listeners} className='app-driving-directions-destination-move'><Icon icon={icons.ellipses} /></button>
												<Button size='small' icon={icons.trash} disabled={meta.length <= 2} onClick={onDelete}/>										
											</div>
										</td>
									</tr>
								</tbody>
							</table>
				</div>


}