// React imports
import React, { useState, useEffect, useRef, useCallback } from 'react'

// App imports
import { Loader }  from '../../../../base/loader/loader';
import { PieChart } from '../../../../base/pieChart/pieCart';
import { DataGrid } from '../../../../base/dataGrid/dataGrid';
import { LineChart } from '../../../../base/lineChart/lineChart';
import { Popover } from '../../../../base/popover/popover';
import { DataList } from '../../../../base/dataList/dataList';
import { Button } from '../../../../base/button/button';
import { helpers } from '../../../../../utils/helpers';
import { icons } from '../../../../base/icon/icon';
import { constants } from '../../../../../utils/constants';
import { translate } from '../../../../../utils/translation';
import { CIChannelFilter } from '../ciChannelFilter';
import { competitiveInsights as ciModule } from '../../../../../modules/competitiveInsights';
import { map } from '../../../map/map';
import { legacyEndpoints } from '../../../../../services/legacyEndpoints';

const _ = require("lodash");

export const CIDashboard = React.forwardRef(({data, siteId, dashboardId, settings, updateSettings, onChartFileSaving, loaded, visible}, ref) => {	

    const [charts, setCharts] = useState([]);
	const [baseChannels, setBaseChannels] = useState([]);
	const [customChannels, setCustomChannels] = useState([]);
    const [rankTypeGeoLevelId, setRankTypeGeoLevelId] = useState(null);
    const [marketTypeGeoLevelId, setMarketTypeGeoLevelId] = useState(null);
	const chartReferences = useRef([]);
    const channelFilterPopover = useRef(null);
    const geographyFilterPopover = useRef(null);
	
    React.useImperativeHandle(ref, () => {
        return {
            refs: chartReferences
        }
    }, []);

	const setChannels = useCallback(
        (channels)=>{

            var newChannels = _.cloneDeep(channels);
            const selectedChannels = settings.channelList.map(channel => { return channel.id });

            newChannels = newChannels.map(channel => {
                channel.isSelected = selectedChannels.indexOf(channel.id) > -1;
                return channel;
            });

            setBaseChannels(newChannels.filter(channel => channel.type === 0));
            setCustomChannels(newChannels.filter(channel => channel.type === 1));

        },
        [settings]
    );

	const load = useCallback(
        ()=>{

            if (!_.isObject(settings))
                return;

            if (dashboardId === constants.competitiveInsights.dashboards.crossShop) {
                var currentFilters = ciModule.getLayer()?.metaData.channels;		
    
                if (_.isArray(currentFilters))
                    setChannels(currentFilters);
                else
                {
                    legacyEndpoints.service({
                        name: 'GetCompetitiveInsightsUserChannelFilter',
                        success: (result) => {
                            setChannels(result);
                        }
                    });
                }			
            }
            else if (dashboardId === constants.competitiveInsights.dashboards.siteDetail) {
                setRankTypeGeoLevelId(settings.rankTypeGeoLevel.id);
            }
            else if (dashboardId === constants.competitiveInsights.dashboards.marketShare) {
                setMarketTypeGeoLevelId(settings.marketTypeGeoLevel.id);
            }
    
        },
        [dashboardId, settings, setChannels]
    );
	
	useEffect(()=>{
        load();
    }, [load]);

    useEffect(() => {
        
        if (!data || !visible)
            return; 

        var charts = _.cloneDeep(data.charts);
        charts = _.sortBy(charts, [function(o) { return o.sort; }]);

        var setColumnProps = (column) =>{
            // the DataGrid component expects name and className properties
            column.name = column.dataField;
            column.className = column.cssClass;

            if (_.isArray(column.columns) && column.columns.length > 0)
                column.columns.forEach(col => {
                    setColumnProps(col);
                });
        };

        charts.forEach(chart => {
            chart.data = ciModule.processDashboardChartData(chart);
            
            switch (chart.type) {
                case constants.competitiveInsights.chartTypes.pie:
                    chart.settings.legend.visible = false;
                    break;

                case constants.competitiveInsights.chartTypes.grid:
                    if (_.isArray(chart.settings.columns))
                        chart.settings.columns.forEach(col => {
                            setColumnProps(col);
                        });

                    chart.dataSource.splice(0,
                        0,
                        chart.dataSource.splice(_.findIndex(chart.dataSource,
                            function(row) { return row[chart.series.IdField] === siteId; }),
                            1)[0]);
        
                    break;

                case constants.competitiveInsights.chartTypes.line:
                    chart.settings.commonSeriesSettings.point.visible = false;
                    chart.settings.argumentAxis.grid.opacity = 0.3;
                    chart.settings.argumentAxis.argumentType = "datetime";
                    chart.settings.argumentAxis.aggregationInterval = "week";
                    chart.settings.argumentAxis.tickInterval = "week";
                    chart.settings.argumentAxis.label = {
                        customizeText: function(point) {
                            return helpers.formatDate({ date: point.value, format: "YYYY-MM-DD" });
                        }
                    };

                    if (!_.isObject(chart.settings.valueAxis)) {
                        chart.settings.valueAxis = {
                            grid: {
                                opacity: 0.3
                            }
                        };
                    } else {
                        chart.settings.valueAxis.grid = {
                            opacity: 0.3
                        };
                    }

                    if (!chart.data.hasNegatives)
                        chart.settings.valueAxis.visualRange = {
                            startValue: 0,
                            endValue: null
                        };

                    if (!_.isObject(chart.settings.valueAxis.visualRange))
                        chart.settings.valueAxis.visualRange = {
                            startDate: null,
                            endDate: null,
                            minStartDate: null,
                            maxEndDate: null,
                        };

                    chart.data.title = {
                        text: chart.title,
                        font: { size: 20 },
                        margin: { left: 40 },
                        subtitle: {
                            text: `${chart.subtitle} ${translate('by')} ${helpers.toTitleCase(chart.settings.argumentAxis.aggregationInterval)}`,
                            font: { size: 14 }
                        }
                    }

                    break;

                default:
                    break;
            }

        });

        setCharts(charts);

    }, [data, siteId, visible]);
    
	chartReferences.current = [];

    return <>
        <Loader loaded={loaded} />
        { charts.length === 0 ? '' :
            charts.map((chart, i) =>{
                return <div className='app-ci-dashboards' key={i}>
                    {
                        chart.type === constants.competitiveInsights.chartTypes.pie ?
                        <>
                            <PieChart 
                                data={chart.data.dataSource} 
                                series={chart.data.series}
                                title={chart.title}
                                subtitle={chart.subtitle}
                                legendVisible={false}
                                resolveLabelOverlapping={'shift'}
                                exportOptions={chart.data.exportOptions}
                                chartRef={(element) => { if (element) chartReferences.current.push({ id: chart.id, chartRef: element, dashboardId: dashboardId }); }}
                                onFileSaving={(o) => { onChartFileSaving(o); }}
                            />
                            <Popover 
                                ref={geographyFilterPopover} 
                                className='app-ci-dashboards-filter-wrapper'
                                parentId={'#app-dashboard-geography-filter-2'}
                                title={translate('geography_filter')}
                                height={400}
                                width={320}
                            >
                                { !_.isObject(settings) ? '' :
                                <>
                        			<div className='app-ci-dashboards-filter-actions'>
                                        <Button className={'app-ci-dashboards-filter-buttons'} theme='secondary' size='small' icon={icons.x} tooltip={translate('cancel')} onClick={() => { 
                                            load();
                                            geographyFilterPopover.current.instance.hide(); 
                                        }} />
                                        <Button className={'app-ci-dashboards-filter-buttons'} theme='primary' size='small' icon={icons.check} tooltip={translate('save')} onClick={() => { 
                                            updateSettings({ ...settings, marketType: constants.competitiveInsights.marketType.geography, marketTypeGeoLevel: settings.geoLevelList.find(geoLevel => geoLevel.id.toUpperCase() === marketTypeGeoLevelId.toUpperCase())});
                                            geographyFilterPopover.current.instance.hide();
                                        }} />
                                    </div>
                                    <div className='app-ci-dashboards-channel-filter'>
                                        <DataList
                                            keyExpr={'id'}
                                            dataSource={settings.geoLevelList}
                                            height={'auto'}
                                            selectionMode={'single'}
                                            selectByClick={true}
                                            searchEnabled={false}
                                            selectedItems={[marketTypeGeoLevelId]}
                                            selectedItemsChange={(o)=> {
                                                setMarketTypeGeoLevelId(o.length > 0 ? o[0] : settings.marketTypeGeoLevel.id);
                                            }}
                                        />
                                    </div>
                                </>
                                }
                            </Popover>
                            <DataGrid 
                                key={chart.series.IdField}
                                remoteOperations={'false'}
                                selectionMode={'none'}
                                showSearchPanel={true}
                                columns={[
                                    {
                                        name: chart.series.ArgumentField,
                                        caption: settings.marketType === constants.competitiveInsights.marketType.site ? translate('site') : translate('chain'),
                                        dataType: "string",
                                        className: "tasDxCustom center bottom"
                                    },
                                    {
                                        caption: translate('current_52_weeks'),
                                        className: "tasDxCustom center",
                                        columns: [
                                            {
                                                name: "Visitors_Estimated",
                                                caption: translate('visits'),
                                                dataType: "number",
                                                format: "fixedPoint",
                                                className: "tasDxCustom right"
                                            },
                                            {
                                                name: "share_pct",
                                                caption: translate('share'),
                                                format: { type: "percent", precision: 1 },
                                                className: "tasDxCustom right"
                                            }
                                        ]
                                    },
                                    {
                                        caption: translate('prior_52_weeks'),
                                        className: "tasDxCustom center",
                                        columns: [
                                            {
                                                name: "Visitors_Estimated_LY",
                                                caption: translate('visits'),
                                                dataType: "number",
                                                format: "fixedPoint",
                                                className: "tasDxCustom right"
                                            },
                                            {
                                                name: "share_pct_LY",
                                                caption: translate('share'),
                                                format: { type: "percent", precision: 1 },
                                                className: "tasDxCustom right"
                                            }
                                        ]
                                    }                                
                                ]}
                                onLoad={async (o)=>{
                                    return chart.dataSource;
                                }}
                                onCellPrepared={(e)=>{
                                    if (e.rowType === "data") {
                                        if (e.data[chart.series.IdField] === siteId)
                                            e.cellElement.classList.add("focused");
                                    }                                
                                }}
                                onToolbarPreparing={(e)=>{
                                    if (dashboardId === constants.competitiveInsights.dashboards.marketShare) {
                                        e.toolbarOptions.items.unshift({
                                            location: 'before',
                                            widget: 'dxButton',
                                            options: {
                                                icon: `<svg data-prefix="fal" class="svg-inline--fa" viewBox="0 0 ${icons.filter.icon[0]} ${icons.filter.icon[1]}"><path fill="currentColor" d="${icons.filter.icon[4]}"></svg>`,
                                                elementAttr: {
                                                    id: 'app-dashboard-geography-filter-2'
                                                }
                                            }
                                        });
                                    }
                                }}
                            />
                        </>
                        : chart.type === constants.competitiveInsights.chartTypes.grid ?
                        <>
                            <div className='app-ci-dashboards-header'>
                                <div className='app-ci-dashboards-title'>{chart.title}</div>
                                <div className='app-ci-dashboards-subtitle'>{chart.subtitle}</div>
                            </div>
                            <Popover 
                                ref={channelFilterPopover} 
                                className='app-ci-dashboards-filter-wrapper'
                                parentId={'#app-dashboard-channel-filter'}
                                title={translate('channel_filter')}
                                height={600}
                                width={320}
                            >
                                { !_.isObject(settings) ? '' :
                                <>
                        			<div className='app-ci-dashboards-filter-actions'>
                                        <Button className={'app-ci-dashboards-filter-buttons'} theme='secondary' size='small' icon={icons.x} tooltip={translate('cancel')} onClick={() => { 
                                            load();
                                            channelFilterPopover.current.instance.hide(); 
                                        }} />
                                        <Button className={'app-ci-dashboards-filter-buttons'} theme='primary' size='small' icon={icons.check} tooltip={translate('save')} onClick={() => { 
                                            updateSettings({ ...settings, channelList: [...baseChannels.filter(channel => channel.isSelected), ...customChannels.filter(channel => channel.isSelected)] });
                                            channelFilterPopover.current.instance.hide();
                                        }} />
                                    </div>
                                    <div className='app-ci-dashboards-channel-filter'>
                                        <CIChannelFilter baseChannels={baseChannels} updateBaseChannels={(items) => { setBaseChannels(items); }} 
                                            customChannels={customChannels} updateCustomChannels={(items) => { setCustomChannels(items); }}
                                        />
                                    </div>
                                </>
                                }
                            </Popover>
                            <Popover 
                                ref={geographyFilterPopover} 
                                className='app-ci-dashboards-filter-wrapper'
                                parentId={'#app-dashboard-geography-filter'}
                                title={translate('geography_filter')}
                                height={400}
                                width={320}
                            >
                                { !_.isObject(settings) ? '' :
                                <>
                        			<div className='app-ci-dashboards-filter-actions'>
                                        <Button className={'app-ci-dashboards-filter-buttons'} theme='secondary' size='small' icon={icons.x} tooltip={translate('cancel')} onClick={() => { 
                                            load();
                                            geographyFilterPopover.current.instance.hide(); 
                                        }} />
                                        <Button className={'app-ci-dashboards-filter-buttons'} theme='primary' size='small' icon={icons.check} tooltip={translate('save')} onClick={() => { 
                                            updateSettings({ ...settings, rankType: constants.competitiveInsights.marketType.geography, rankTypeGeoLevel: settings.geoLevelList.find(geoLevel => geoLevel.id.toUpperCase() === rankTypeGeoLevelId.toUpperCase())});
                                            geographyFilterPopover.current.instance.hide();
                                        }} />
                                    </div>
                                    <div className='app-ci-dashboards-channel-filter'>
                                        <DataList
                                            keyExpr={'id'}
                                            dataSource={settings.geoLevelList}
                                            height={'auto'}
                                            selectionMode={'single'}
                                            selectByClick={true}
                                            searchEnabled={false}
                                            selectedItems={[rankTypeGeoLevelId]}
                                            selectedItemsChange={(o)=> {
                                                setRankTypeGeoLevelId(o.length > 0 ? o[0] : settings.rankTypeGeoLevel.id);
                                            }}
                                        />
                                    </div>
                                </>
                                }
                            </Popover>
                            <DataGrid 
                                key={chart.series.IdField}
                                remoteOperations={'false'}
                                selectionMode={'single'}
                                showSearchPanel={true}
                                columns={chart.settings.columns}
                                allowExport={true}
                                onLoad={async (o)=>{
                                    return chart.dataSource;
                                }}
                                onCellPrepared={(e)=>{
                                    if (e.rowType === "data") {
                                        if (e.data[chart.series.IdField] === siteId)
                                            e.cellElement.classList.add("focused");
                                    }                                
                                }}
                                onSelectionChanged={(e)=>{
                                    if (_.isArray(e.selectedRowsData) && e.selectedRowsData.length > 0) {
                                        var data = e.selectedRowsData[0];

                                        var location = { lat: data[chart.series.LatitudeField], lon: data[chart.series.LongitudeField] };

                                        map.center = location;
                                        map.showLocator({
                                            location: location,
                                            xOffset: 0,
                                            yOffset: 0
                                        }); 
                                    }
                                }}
                                onToolbarPreparing={(e)=>{
                                    if (dashboardId === constants.competitiveInsights.dashboards.crossShop) {
                                        e.toolbarOptions.items.unshift({
                                            location: 'before',
                                            widget: 'dxButton',
                                            options: {
                                                icon: `<svg data-prefix="fal" class="svg-inline--fa" viewBox="0 0 ${icons.filter.icon[0]} ${icons.filter.icon[1]}"><path fill="currentColor" d="${icons.filter.icon[4]}"></svg>`,
                                                elementAttr: {
                                                    id: 'app-dashboard-channel-filter'
                                                }
                                            }
                                        });
                                    }
                                    else if (dashboardId === constants.competitiveInsights.dashboards.siteDetail) {
                                        e.toolbarOptions.items.unshift({
                                            location: 'before',
                                            widget: 'dxButton',
                                            options: {
                                                icon: `<svg data-prefix="fal" class="svg-inline--fa" viewBox="0 0 ${icons.filter.icon[0]} ${icons.filter.icon[1]}"><path fill="currentColor" d="${icons.filter.icon[4]}"></svg>`,
                                                elementAttr: {
                                                    id: 'app-dashboard-geography-filter'
                                                }
                                            }
                                        });
                                    }
                                }}
                            />
                        </>
                        : chart.type === constants.competitiveInsights.chartTypes.line ?
                        <>
                            <LineChart 
                                data={chart.data.dataSource} 
                                series={chart.data.series}
                                title={chart.data.title}
                                commonSeriesSettings={chart.settings.commonSeriesSettings}
                                argumentAxis={chart.settings.argumentAxis}
                                valueAxis={chart.settings.valueAxis}
                                crosshair={chart.settings.crosshair}
                                legend={chart.settings.legend}
                                tooltip={chart.settings.tooltip}
                                dataPrepareSettings={{ sortingMethod: false }}
                                exportOptions={chart.data.exportOptions}
                                chartRef={(element) => { if (element) chartReferences.current.push({ id: chart.id, chartRef: element, dashboardId: dashboardId }); }}
                                onFileSaving={(o) => { onChartFileSaving(o); }}
                            />
                        </>
                        : ''
                    }
                </div>
            })
        }
    </>
})