// Third party imports
import { Tooltip as DevExTooltip } from 'devextreme-react/tooltip';

export function Tooltip({ target, renderContent }) {
    return <DevExTooltip
        target={target}
        showEvent="dxhoverstart"
        hideEvent="dxhoverend"
        contentRender={renderContent}
    >
    </DevExTooltip>
}