// React imports
import { useEffect, useState, useCallback } from 'react';

// App imports
import { Loader } from '../../base/loader/loader';
import { Button } from '../../base/button/button';
import { ConfirmButton } from '../../base/confirmButton/confirmButton';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { helpers } from '../../../utils/helpers';
import { DataGrid } from '../../base/dataGrid/dataGrid'
import { ModalCard } from '../../base/modalCard/modalCard';
import { GeofenceJobs } from './geofenceJobs/geofenceJobs';
import { geofences as geofencesModule } from '../../../modules/geofences';
import { activityHub } from '../../../components/app/activityHub/activityHub';

export function Geofences({entity,hide}){

    const [geofencesLoaded, setGeofencesLoaded] = useState(false);
    const [dataGridLoaded, setDataGridLoaded] = useState(false);
    const [geofences, setGeofences] = useState([]);
    const [jobsGeofence, setJobsGeofence] = useState(null);

    var getGeofences = useCallback(
        async () =>{ 
            setGeofencesLoaded(false);
            setDataGridLoaded(false);

            var data = await geofencesModule.get({ aServiceID: entity.layer.parentId, sPointID: entity.id })

            if (data)
                setGeofences([...data.results]);

            setGeofencesLoaded(true);
        },
        [entity]
    );

    useEffect(()=>{        
        getGeofences(); 
    },[getGeofences]);  
    
    var onComplete = () =>{
        getGeofences(); 
    };

    var createGeofence = () => {
        activityHub.close();
        geofencesModule.drawGeofence(entity, onComplete);
    };   
            
    var loadGeofence = (geofence) =>{
        geofencesModule.loadGeofence(geofence, entity.text);      
    };  
 
    var deleteGeofence = async (id) => {
        var result = await geofencesModule.deleteGeofence(id);

        setGeofences(geofences.filter(x => x.id !== id)) 

        return result;
    };         
    
	return <>
        <Loader className='app-card-body-loader' loaded={dataGridLoaded} />
        { !geofencesLoaded ? '' : 
            <div className='app-geofences'>      
                <div className='app-geofences-header'>      
                    <div className='app-geofences-actions'>
                        <Button theme='primary' size='small' icon={icons.plus} tooltip={translate('create_geofence')} onClick={() =>{ createGeofence() }}/>
                    </div>  
                </div>  
                <div className='app-geofences-datagrid'>
                    { jobsGeofence !== null ? '' :
                        <DataGrid 
                            key='id'
                            height='360px'
                            showFilterRow={true}
                            noDataText={translate('no_geofences')}
                            columns={[
                                { name: 'name', caption: translate('name'), onRender: (o) => { return o.name; }},
                                { name: 'date', caption: translate('last_modified'), onRender: (o) => { return helpers.formatDate({ date: helpers.convertJSONDate(o.date), format: 'MM/DD/YYYY h:mm a' })} },                
                                { name: 'createdBy', caption: translate('created_by'), onRender: (o) => { return o.createdBy.Name; }},
                                { name: 'actions', caption: translate('actions'), allowSorting: false, alignment: 'center', width: '110px', className: 'app-photos-list-view-column-text', allowFiltering: false, 
                                    onRender: (o) => { 
                                        return <div className='app-geofences-grid-actions'>
                                                    <div className='app-geofences-grid-actions-buttons'>
                                                        <Button className='app-geofences-grid-actions-button' theme='simple' icon={icons.download} tooltip={translate('load')} onClick={()=>{ loadGeofence(o); }} />                                                  
                                                        <ConfirmButton className='app-geofences-grid-actions-button' theme='simple' disabled={!o.allowModify} icon={icons.trash} tooltip={translate('delete')} clearText={translate('delete')} onConfirm={()=>{ deleteGeofence(o.id); }} />
                                                        <Button className='app-geofences-grid-actions-button' theme='simple' icon={icons.car} tooltip={translate('mobile_data_query')} onClick={()=>{ setJobsGeofence(o); }} />
                                                    </div> 
                                                </div>
                                        }
                                },    
                            ]}
                            disableLoadPanel={true}
                            onLoad={async (o)=>{
                                setDataGridLoaded(true);
                                return {
                                    data: geofences,
                                    totalCount: geofences.length
                                };
                            }}
                        />
                    }
                </div> 
                <ModalCard loaded={true} open={jobsGeofence !== null}>
                    { jobsGeofence === null ? '' :
                        <GeofenceJobs entity={entity} geofence={jobsGeofence} onClose={()=>{ setJobsGeofence(null); }} />
                    }
                </ModalCard>	
            </div>                 
        }
    </>
}
