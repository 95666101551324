import { useEffect, useState, useRef, useCallback } from "react";
import { Button } from "../../base/button/button";
import { DropDown } from "../../base/dropDown/dropDown";
import { Loader } from "../../base/loader/loader";
import { analogs as analogsModule } from "../../../modules/analogs";
import { constants } from "../../../utils/constants";
import { helpers } from "../../../utils/helpers";
import { icons } from "../../base/icon/icon";
import { translate } from "../../../utils/translation";
import { DataGrid } from "../../base/dataGrid/dataGrid";
import { activityHub } from '../activityHub/activityHub';

const _ = require("lodash");

export function AnalogSelector({entity}){
    const [loaded, setLoaded] = useState(false);
    const [availableTemplates, setAvailableTemplates] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [analogs, setAnalogs] = useState(null);
    const grid = useRef(null);

	const getAnalogs = useCallback(
        async ({entity}) => {

            setLoaded(false);
        
            const templates = await analogsModule.getAvailableTemplates({customQueryId: entity.layer.id, pointId: entity.id})
            if (!_.isArray(templates) || templates.length === 0) {
                setLoaded(true);
                return;
            }

            setAvailableTemplates(templates);
    
            var analogs = [];
            templates.forEach(async template => {
                setAnalogs([...analogs, ...await getAnalogsForTemplates({entity: entity, template: template})]);
            });
    
            setLoaded(true);

        }, 
        []
    );

    const getAnalogsForTemplates = async function ({entity, template}) {
        let savedAnalogs = await analogsModule.getSavedAnalogs({customQueryId: entity.layer.id, pointId: entity.id, templateId: template.ID})
        
        var analogs = savedAnalogs.LockedAnalogs.map(analog => {
            return {
                Id : analog.Id,
                TemplateId : template.ID,
                TemplateName: template.Name,
                Date : helpers.convertJSONDate(analog.Date),
                UserGuid : analog.UserGUID,
                UserId : analog.UserId,
                UserName : analog.UserName,
                Type : constants.analogTypes.locked
            }
        })

        analogs = [...analogs, ...savedAnalogs.SavedParameters.map(analog => {
            return {
                Id : analog.Id,
                TemplateId : template.ID,
                TemplateName: template.Name,
                Date : helpers.convertJSONDate(analog.Date),
                UserGuid : analog.UserGUID,
                UserId : analog.UserId,
                UserName : analog.UserName,
                Type : constants.analogTypes.saved
            }
        })]

        return analogs;
    };

    const openAnalog = (analog) => {

        activityHub.close();

        analogsModule.generate({
            customQueryId: entity.layer.id,
            pointId: entity.id,
            templateId: selectedTemplate.ID,
            savedAnalogId: analog != null ? analog.Id : null,
            locked: analog != null && analog.Type == constants.analogTypes.locked
        });
    };

    useEffect(() => {
        getAnalogs({entity: entity});
    }, [getAnalogs, entity]);

    const renderName = (analog) => { return analog.Date.toLocaleString() + " (" + analog.UserName + ")"};
    const renderStatus = (analog) => { return analog.Type == constants.analogTypes.locked ? translate('finalized') : translate('saved')};
    const renderTemplate = (analog) => { return analog.TemplateName};
    const renderActions = (analog) => {
        return <Button 
            theme='simple' 
            tooltip={analog.Type == constants.analogTypes.locked? translate("open") : translate("edit")} 
            icon={analog.Type == constants.analogTypes.locked? icons.send : icons.edit} 
            onClick={()=>{openAnalog(analog)}}
        />
    };

    const getColumns = () => {
        return [
            { name: 'Name',  caption: translate('name'), allowFiltering: false, allowSorting: true, onRender: (o) => { return renderName(o); }, calculateSortValue: (o) => { return renderName(o); }},
            { name: 'Status', caption: translate('status'), width: '100px', allowFiltering: false, allowSorting: true,  onRender: (o) => { return renderStatus(o); }, calculateSortValue: (o) => { return renderStatus(o); }},
            { name: 'Template', caption: translate('template'), width: '200px', allowFiltering: false, allowSorting: true, onRender: (o) => { return renderTemplate(o); }, calculateSortValue: (o) => { return renderTemplate(o); }},
            { name: '', alignment:'center', width: '50px', allowFiltering: false, allowSorting: false, onRender: (o) => { return renderActions(o); }}
        ];
    };

    return <>
        <Loader className='app-form-loader' loaded={loaded} />
        <div className="app-analog-template-container">
            <DropDown 
                label={translate('template')}
                width={'300px'}
                items={availableTemplates} 
                display={'Name'} 
                selected={selectedTemplate ?? (availableTemplates != null && availableTemplates.length > 0) ? availableTemplates[0] : null}
                onChange={(e)=>{ setSelectedTemplate(e.value); }}
            />
            <Button 
                theme='primary'
                size={'small'} 
                tooltip={translate('create_new_analog')} 
                icon={icons.plus} 
                onClick={()=>{openAnalog()}}
            />
        </div>
        <div className="app-analog-list-container">
            <DataGrid 
                ref={grid}
                keyExpr={'Id'}
                remoteOperations={'false'}
                showScrollbar='onHover'
                showFilterRow={false}
                selectionMode='none'  
                selectedItems={[]}            
                columns={getColumns()}
                items={analogs}
                disablePaging={true}
                hidePager={true}
                onLoad={async (o)=> {return analogs;}}
            /> 
        </div>
    </>
}