import { useEffect, useState } from 'react';
import { Wizard } from '../../../base/wizard/wizard';
import { ModelWizardStep1 } from './modelWizardStep1';
import { ModelWizardStep2 } from './modelWizardStep2';
import { translate } from '../../../../utils/translation';
import { map } from '../../map/map';
import { cosmetic } from '../../../../modules/cosmetic';
import { projections } from '../../../../modules/projections';
import { successToast } from '../../../base/toast/toast';
import { constants } from '../../../../utils/constants';
import { helpers } from '../../../../utils/helpers';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { layers } from '../../layers/layers';
import { sources } from '../../../../modules/sources';
import { modelWizard } from '../../../../modules/modelWizard';

const _ = require("lodash");

export function ModelWizard({projection, onClose}){

    const [mode, setMode] = useState(constants.projections.modes.new);
    const [loaded, setLoaded] = useState(false);
    const [generateMode, setGenerateMode] = useState(projection.type === constants.projections.interactive && projection.providerType === constants.projections.providers.intalytics ? constants.projections.interactive : constants.projections.oneClick);
    const [zoomToResult, setZoomToResult] = useState(true);
    const [zoomToProposedResult, setZoomToProposedResult] = useState(true);
    const [openLayerResult, setOpenLayerResult] = useState(true);
    const [openProposedLayerResult, setOpenProposedLayerResult] = useState(true);
    const [existingSearch, setExistingSearch] = useState(null);
    const [existingSiteSearches, setExistingSiteSearches] = useState([]);
    const [existingResult, setExistingResult] = useState(null);
    const [locationSearch, setLocationSearch] = useState(null);
    const [locationResult, setLocationResult] = useState(null);
    const [currentPushpin, setCurrentPushpin] = useState(null);
    const [relocationRing, setRelocationRing] = useState(null);    
    const [mapLocationListener, setMapLocationListener] = useState(null);
    const [selectSite, setSelectSite] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [updateForm, setUpdateForm] = useState(null);
    const [jobName, setJobName] = useState(`${(_.isString(projection.pointName) ? `${projection.pointName}-` : '')}${projection.name} ${helpers.formatDate({ date: new Date() })}`);
    const [activeError, setActiveError] = useState(true);
    const [activeWarning, setActiveWarning] = useState(false);

    const enableNewLocation = projection.providerType === constants.projections.providers.eSite ||  (projection.proposedSiteLayer !== null && projection.proposedSiteLayer.addFormId !== helpers.emptyGuid());
    const enableExistingLocation = projection.providerType !== constants.projections.providers.eSite && projection.layers.length > 0;

    const setSearches = async () =>{
        switch(projection.providerType)
        {
            case constants.projections.providers.intalytics:
                if (enableExistingLocation)
                    setExistingSiteSearches([{ Id: helpers.emptyGuid(), Name: translate('none')}, ...await legacyEndpoints.service({
                        name: 'GetProjectionCustomQueriesWithSearches',
                        parameters: {
                            projectionId: projection.id
                        }
                    })]);
                break;
            case constants.projections.providers.eSite:
                break;
        }

        if (enableNewLocation && enableExistingLocation || (enableNewLocation && !enableExistingLocation))
            setMode(constants.projections.modes.new)
        else if (!enableNewLocation && enableExistingLocation)
            setMode(constants.projections.modes.existing)

        setLoaded(true);
    };

    const wizardStep1 = {
        title: projection.name,
        loaded: loaded,
        generating: false,
        continueDisabled: (projection.type === constants.projections.relocation && (!_.isObject(locationResult) || !_.isObject(existingResult))) || 
                            (projection.type !== constants.projections.relocation && !_.isObject(locationResult) && !_.isObject(existingResult)),
        children: <ModelWizardStep1

            loaded={loaded}

            projection={projection}

            jobName={jobName}
            setJobName={setJobName}

            enableNewLocation={enableNewLocation}
            enableExistingLocation={enableExistingLocation}

            existingSiteSearches={existingSiteSearches}

            currentPushpin={currentPushpin}

            mode={mode}
            setMode={setMode}

            zoomToResult={zoomToResult}
            setZoomToResult={setZoomToResult}

            openLayerResult={openLayerResult}
            setOpenLayerResult={setOpenLayerResult}

            mapLocationListener={mapLocationListener}
            setMapLocationListener={setMapLocationListener}

            locationSearch={locationSearch}
            setLocationSearch={setLocationSearch}

            locationResult={locationResult}
            setLocationResult={setLocationResult}

            existingSearch={existingSearch}
            setExistingSearch={setExistingSearch}

            existingResult={existingResult}
            setExistingResult={setExistingResult}

            selectSite={selectSite}
            setSelectSite={setSelectSite}

            relocationRing={relocationRing}
        />
    };

    const wizardStep2 = {
        title: projection.name,
        loaded: true,
        generating: generating,
        continueDisabled: activeError,
        children: <ModelWizardStep2

            projection={projection}

            mode={mode}

            jobName={jobName}
            setJobName={setJobName}
            
            generateMode={generateMode}
            setGenerateMode={setGenerateMode}

            openProposedLayerResult={openProposedLayerResult}
            setOpenProposedLayerResult={setOpenProposedLayerResult}

            zoomToProposedResult={zoomToProposedResult}
            setZoomToProposedResult={setZoomToProposedResult}

            locationResult={locationResult}

            existingResult={existingResult}

            generating={generating}

            onUpdate={(o)=>{ setUpdateForm(o.updateForm); 
                setActiveError(o.activeError); 
                setActiveWarning(o.activeWarning) }}
        />
    };

    useEffect(() =>{

        setSearches();
        setCurrentPushpin(cosmetic.createGeocodePushpin({ location: null, temporary: true }));
        setRelocationRing(map.layers.find(x => x.type === constants.layers.types.temporary).addEntity({
            type: constants.entities.polygon,
            paths: [],
            fillColor: { r: 0, g: 0, b: 0, a: 0},
            strokeColor: { r: 0, g: 0, b: 0, a: 1 },
            strokeWidth: 1,
            visible: false
        }));

    }, [projection]);

    useEffect(() =>{

        if (currentPushpin === null || relocationRing === null)
            return;

        if (projection.type === constants.projections.relocation)
            relocationRing.visible = true;
        else
            switch(mode)
            {
                case constants.projections.modes.new:
                    currentPushpin.visible = true;
                    break;
                case constants.projections.modes.existing:
                    currentPushpin.visible = false;
                    break;
            }

    }, [mode, currentPushpin]);

    var projectionSteps = [wizardStep1];

    if (projection.providerType === constants.projections.providers.eSite ||  (projection.proposedSiteLayer !== null && projection.proposedSiteLayer.addFormId !== helpers.emptyGuid() || projection.layers.filter(layer => layer.editFormId !== helpers.emptyGuid()).length > 0))
        projectionSteps.push(wizardStep2);

    const removeStep1Artifacts = () =>{
        map.setCursor({ cursor: '' });
        map.removeListener(mapLocationListener);
        map.enableEntityClicking();
        setSelectSite(false);
        
        if (modelWizard.currentListener !== null)
            modelWizard.removeSelectSiteListener();
    }

    const close = ()=>{
        currentPushpin?.dispose();
        relocationRing?.dispose();
        removeStep1Artifacts();
        onClose();
    };

	return <Wizard steps={projectionSteps} onlyOneStep={projectionSteps.length === 1} size='small' className='app-analytics-projection-wizard' goToStep={-1} completeText={translate('generate')} onClose={() =>{close()}} onNextStep={async (o) =>{
        switch(o.step)
        {
            case 1:
                removeStep1Artifacts();
                break
            case 2:

                setGenerating(true);

                if (projection.type === constants.projections.relocation)
                    projections.generateRelocation({
                        id: projection.id,
                        customQueryId: existingResult.QueryID,
                        pointId: existingResult.PointID,
                        location: { lat: existingResult.Latitude, lon: existingResult.Longitude },
                        relocation: locationResult.location,
                        updateForm: updateForm,
                        jobName: jobName
                    });
                else
                    switch (projection.providerType)
                    {
                        case constants.projections.providers.intalytics:
                            switch(mode)
                            {
                                case constants.projections.modes.new:
                                    var result = await projections.generateProposedLocation({
                                        id: projection.id,
                                        type: generateMode,
                                        location: locationResult.location,
                                        updateForm: updateForm,
                                        jobName: jobName
                                    });

                                    if (openLayerResult === true)
                                        sources.refresh({
                                            layers: [{
                                                id: result.pointResult.Key,
                                                text: translate('loading'),
                                                subType: 0,
                                                data: { isCompetitiveInsights: false }
                                            }],
                                            onRefresh: (o) =>{
                                                layers.refreshDataLayers(o);
                                            }
                                        });

                                    if (zoomToProposedResult === true)
                                        map.locate({ location: locationResult.location });

                                    break;
                                case constants.projections.modes.existing:
                                    await projections.generate({
                                        id: projection.id,
                                        name: projection.name,
                                        recapture: projection.hasRecaptureModel,
                                        type: generateMode,
                                        customQueryId: existingResult.QueryID,
                                        pointId: existingResult.PointID,
                                        location: { lat: existingResult.Latitude, lon: existingResult.Longitude },
                                        updateForm: updateForm,
                                        jobName: jobName
                                    });
                                    break;
                            }
                            break;
                        case constants.projections.providers.eSite:
                            await projections.generateAdhoc({
                                id: projection.id,
                                location: locationResult.location,
                                updateForm: updateForm,
                                jobName: jobName
                            });
                            break;
                    }

                    if (projection.hasRecaptureModel !== true)
                        successToast(translate('successfully_submitted'));
                    
                    close();
                    break
            }
    }} />;
}