import { useCallback, useRef, useState, createRef } from 'react';

// Third party imports
import DropDownBox from 'devextreme-react/drop-down-box';
import TreeView from 'devextreme-react/tree-view';

export function TreeDropDown({className, items, dataStructure, itemsExpr, keyProperty, valueProperty, parentProperty, placeholder, onSelect}) {
    const treeViewRef = useRef(createRef());
    const [treeBoxValue, setTreeBoxValue] = useState(null);

    var classes = ['app-drop-down'];

    if (className)
        classes.push(className);

    const treeViewItemSelectionChanged = useCallback((e) => {
        var selectedNodeKeys = [];
        var selectedNodes = e.component.getSelectedNodes();

        //only select leaf nodes
        selectedNodes.forEach((node) => {
            // if (node.children.length === 0) //todo not working with tree data structure
                selectedNodeKeys.push(node.key);
        });

        if (onSelect)
            onSelect({
                value: selectedNodeKeys
            });        

        setTreeBoxValue(selectedNodeKeys);
    }, []);

    const syncTreeViewSelection = useCallback(
        (e) => {
            const treeView = (e.component.selectItem && e.component) || (treeViewRef.current && treeViewRef.current.instance);
            if (treeView) {
                if (e.value === null) {
                    treeView.unselectAll();
                } else {
                    const values = e.value || treeBoxValue;
                    values && values.forEach((value) => {
                        treeView.selectItem(value);
                    });
                }
            }

            if (e.value !== undefined) {
                setTreeBoxValue(e.value);
            }
        },
        [treeBoxValue]
      );

    const treeViewRender = useCallback(
        () => (
          <TreeView
            dataSource={items}
            itemsExpr={itemsExpr}
            ref={treeViewRef}
            dataStructure={dataStructure}
            keyExpr={keyProperty}
            parentIdExpr={parentProperty}
            displayExpr={valueProperty}
            selectionMode="multiple"
            showCheckBoxesMode="normal"
            selectNodesRecursive={true}
            selectByClick={false}
            onContentReady={syncTreeViewSelection}
            onItemSelectionChanged={treeViewItemSelectionChanged}
          />
        ),
        [items, dataStructure, itemsExpr, keyProperty, parentProperty, valueProperty, syncTreeViewSelection, treeViewItemSelectionChanged],
      );

    return <div className={classes.join(' ')}>
        <DropDownBox
        value={treeBoxValue}
        valueExpr={keyProperty}
        displayExpr={valueProperty}
        placeholder={placeholder}
        showClearButton={true}
        dataSource={items}
        onValueChanged={syncTreeViewSelection}
        contentRender={treeViewRender}
        />  
    </div>

    
}



    
