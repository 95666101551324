// React imports
import { useState, useEffect, useCallback } from 'react';

// Third party imports
import { TreeView } from 'devextreme-react/tree-view';

// App imports
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { translate } from "../../../utils/translation";
import { LargePanel } from '../../base/largePanel/largePanel';
import { Description } from '../../base/description/description';
import { filtersModule } from '../../../modules/filters';
import { Button } from '../../base/button/button';
import { DataGrid } from '../../base/dataGrid/dataGrid';
import { icons } from '../../base/icon/icon';
import { ConfirmButton } from '../../base/confirmButton/confirmButton';
import { successToast } from '../../base/toast/toast';
import { helpers } from '../../../utils/helpers';
import { constants } from '../../../utils/constants';
import { FilterCreate } from '../filters/filterCreate';
import { filterCreate } from './filterCreate';
import { userPreferences } from '../app';

const _ = require('lodash');

export var filters;

export function Filters()
{
    var [visible, setVisible ] = useState(false);
    var [loaded, setLoaded] = useState(false);
    var [generating, setGenerating] = useState(false);
    var [title, setTitle] = useState('');
    var [dataSourceId, setDataSourceId] = useState(null);
    var [customQueryId, setCustomQueryId] = useState(helpers.emptyGuid());
    var [sharingType, setSharingType] = useState(constants.sharing.all);
    var [filterList, setFilterList] = useState([]);

    const folders = [{
            id: constants.sharing.all,
            name: translate('all'),
            expanded: true
        },
        {
            id: constants.sharing.shared,
            categoryId: '1',
            name: translate('shared'),
            expanded: true
        }, 
        {
            id: constants.sharing.user,
            categoryId: '1',
            name: translate('personal'),
            expanded: true
        }];

      filters = {
        setContent: (o) => {         
            setVisible(true);   
            setTitle(o.title ? o.title : '');
            setDataSourceId(o.dataSourceId ? o.dataSourceId : null);
            setCustomQueryId(o.customQueryId ? o.customQueryId : helpers.emptyGuid());
            setLoaded(true);
            setSharingType(o.sharingType ? o.sharingType : constants.sharing.all);
        },
        close: async ()=> {
            setVisible(false);
            setTitle('');
            setDataSourceId(null);
            setCustomQueryId(helpers.emptyGuid());
            setLoaded(false);
            setSharingType(constants.sharing.all);
        }
    };

    var selectSharingType = useCallback((e) =>
    {
        setSharingType(e.node.key);
    },[setSharingType]);

    var getFilterList = useCallback(
        async() =>
        {
            setLoaded(false);

            var result = await filtersModule.getPinnedPointDataSourceFilters({ dataSourceId: dataSourceId });
                          
            if (_.isObject(result))    
                setFilterList(result.filterList ?? []);
            else
                setFilterList([]);

            setLoaded(true);  
        },[dataSourceId]);

    useEffect(() =>
    {
        getFilterList();
    }, [getFilterList]);

    return <>
        <FilterCreate />
        <LargePanel
            className='app-filters-large-panel'
            visible={visible}
            text={title + ' ' + translate('filter_manager')} 
            loaded={loaded} 
            generating={generating} 
            tools=
            {        
                <div className='app-filters-header'>
                    <div className='app-filters-instructions'>
                            <Description description={translate('filters_instructions')} />
                    </div>
                    <div className='app-filters-actions'>
                        <Button theme='secondary' size='small' icon={icons.plus} tooltip={translate('create_filter')} onClick={async()=> {
                            var filterSetList = await filtersModule.getFilterSets({ dataSourceId });

                            filterCreate.setContent({
                                title: title,
                                dataSourceId: dataSourceId,
                                customQueryId: customQueryId,
                                filterSetList: filterSetList,
                                onSave: async(o) => {
                                    await filtersModule.createPointDataSourceFilter({
                                        dataSourceId: o.dataSourceId,
                                        customQueryId: o.customQueryId,
                                        filterSet: o.filterSet,
                                        filters: o.filters,
                                        name: o.name,
                                        description: o.description
                                    });

                                    getFilterList();
                                }
                            });
                        }}/>
                    </div>
                </div>
            }
            onClose={async() =>{ await filters.close(); }}>
                <div className='app-filters-divided-container'>
                    <div className='app-filters-divided-column left'>
                        <TreeView
                            id="simple-treeview"
                            items={folders}
                            dataStructure="plain"
                            displayExpr="name"
                            parentIdExpr="categoryId"
                            keyExpr="id"
                            width={175}
                            onItemClick={selectSharingType}
                        />   
                    </div>
                    <div className='app-filters-divided-column right'>
                        <DataGrid 
                            key='id'
                            showFilterRow={false}
                            remoteOperations={'false'}
                            showHeaderFilter={false}
                            showScrollbar='onHover'
                            defaultPageSize={8}
                            expandOneRowOnly={true}
                            columns={[          
                                {
                                    name: 'name',
                                    caption: translate('name'),
                                    className: 'app-filters-column-text',
                                    allowFiltering: false, 
                                    width:'25%',
                                    onRender: (o) =>
                                    { 
                                        return o.name; 
                                    }
                                },
                                {
                                    name: 'description',
                                    caption: translate('description'),
                                    className: 'app-filters-column-text',
                                    allowFiltering: false,
                                    width:'50%',
                                    onRender: (o) =>
                                    { 
                                        return o.description; 
                                    }
                                },                                                                                                  
                                {
                                    name: 'owner',
                                    caption: translate('owner'),
                                    allowFiltering: false,
                                    width:'25%',
                                    onRender: (o) => 
                                    {
                                        var userName = o.createdBy.Name;
                                        var userPhotoUrl = legacyEndpoints.handlers.getPhotoUrl({ userId: o.createdBy.Id, isUserPhoto: true, width: 150, height: 150 });

                                        if (o.createdBy.Id === helpers.emptyGuid())
                                        {
                                            userName = translate('system');
                                            userPhotoUrl = "https://cdn.tradeareasystems.net/Images/Rebrand/KalibrateHeader.png";
                                        }

                                        return (<>
                                            <img className='app-filters-user-image' src={userPhotoUrl} alt={translate('user_image')} />
                                            <span className='app-filters-username'>
                                                {userName}
                                            </span>
                                        </>);
                                    }
                                },
                                {
                                    name: 'actions',
                                    caption: translate('actions'),
                                    allowFiltering: false,
                                    width:'25%',
                                    onRender: (o) => 
                                    {
                                        return (<>
                                            <div className='app-filters-button-container'>
                                                <ConfirmButton theme='simple' icon={icons.trash} tooltip={translate('delete')} clearText={translate('delete')} disabled={!userPreferences.IsAdmin} //disabled={data.isShared} 
                                                    onConfirm={async(e) =>
                                                    {
                                                        await filtersModule.deletePointDataSourceFilter({ idList: [o.id] });
                                                        getFilterList();
                                                    }} 
                                                />
                                            </div>
                                        </>);
                                    }
                                }
                            ]}
                            onLoad={async (o)=>
                            {
                                return {
                                    data: filterList,
                                    totalCount: filterList.length
                                };
                            }}
                        />
                    </div>
                </div>
        </LargePanel>
    </>
}