// React imports
import { useState, useEffect, useCallback } from 'react';

// Third party imports
import { TreeView } from 'devextreme-react/tree-view';

// App imports
import { translate } from "../../../../utils/translation";
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { LargePanel } from '../../../base/largePanel/largePanel';
import { Description } from '../../../base/description/description';
import { geoFeeds as geoFeedsModule } from '../../../../modules/geoFeeds';
import { Button } from '../../../base/button/button';
import { DataGrid } from '../../../base/dataGrid/dataGrid';
import { icons } from '../../../base/icon/icon';
import { ConfirmButton } from '../../../base/confirmButton/confirmButton';
import { successToast } from '../../../base/toast/toast';

const _ = require('lodash');

export function GeoFeeds({text,setPanelContents,onClose}) {
    
    const [loaded, setLoaded] = useState(true);
    const [generating, setGenerating] = useState(false);
    const [geoFeeds, setGeoFeeds] = useState([]);
    const [filter, setFilter] = useState('');
    const allId = '1';
    const sharedId = '1_1';

    const folders = [
        {
          ID: allId,
          name: translate('all'),
          expanded: true,
        },
        {
          ID: sharedId,
          categoryId: '1',
          name: translate('shared'),
          expanded: true,
        }, 
        {
          ID: '1_2',
          categoryId: '1',
          name: translate('personal'),
          expanded: true,
        },    
      ]

    var getGeoFeeds = useCallback(
        async () =>{
            setLoaded(false);

            const result = await geoFeedsModule.getGeoFeeds();
                          
            if (_.isObject(result))    
                setGeoFeeds(result.results);

            setLoaded(true);  
        },
        []
    );

    useEffect(() =>{
        getGeoFeeds();
        
    }, [getGeoFeeds]);

    const editGeoFeed = async (o) =>{
        const geoFeedLayer = await geoFeedsModule.getGeoFeedLayer(o);

        setPanelContents({ panelId: 10, additionalData: geoFeedLayer });
    };

    const selectItem = useCallback(
        (e) => {
            setFilter(e.node.key);
        },
        [setFilter],
      );


    return  <>
        <LargePanel 
        text={text} 
        loaded={loaded} 
        generating={generating} 
        onClose={() =>{ onClose(); }}
        tools={        
                <div className='app-geo-feeds-header'>
                    <div className='app-geo-feeds-instructions'>
                            <Description description={translate('geo_feeds_instructions')} />
                    </div>
                    <div className='app-geo-feeds-actions'>
                        <Button theme='secondary' size='small' icon={icons.plus} tooltip={translate('create_geo_feed')} onClick={() =>{ setPanelContents({ panelId: 10, additionalData: geoFeedsModule.defaultGeoFeed() }); }}/>                  
                    </div>
                </div>}
        >
        <div className='app-geo-feeds-divided-container app-geo-feeds'>
            <div className='app-geo-feeds-divided-column'>
                <TreeView
                    id="simple-treeview"
                    items={folders}
                    dataStructure="plain"
                    displayExpr="name"
                    parentIdExpr="categoryId"
                    keyExpr="ID"
                    width={175}
                    onItemClick={selectItem}
                />   
            </div>
            <div className='app-geo-feeds-divided-column'>
                {<DataGrid 
                        key='id'
                        height='500px'
                        showFilterRow={true}
                        remoteOperations={'false'}
                        enableMasterDetail={true}
                        detailTemplate={(o)=>{       
                            const data = o.data.data;         
                            return <>
                                <div className='app-multimedia-grid-detail-description'>
                                    {data.description}
                                </div>
                                <div>                                                                                
                                    <Button className='app-multimedia-grid-detail-actions' theme='simple' icon={icons.check} 
                                        tooltip={translate('map')} 
                                        onClick={()=>{ 
                                            geoFeedsModule.loadGeoFeed({id: data.id}); 
                                            successToast(translate('success')); 
                                        }} 
                                    />	        

                                    <Button className='app-multimedia-grid-detail-actions' theme='simple' icon={icons.edit} 
                                        tooltip={translate('edit')} 
                                        disabled={data.isShared}                                     
                                        onClick={()=>{ editGeoFeed(data); }} 
                                    />	                                                                                                

                                    <span className='app-multimedia-grid-detail-delete-action'>
                                        <ConfirmButton theme='simple' icon={icons.trash} tooltip={translate('delete')} clearText={translate('delete')} disabled={data.isShared} 
                                                                onConfirm={async ()=>{ 
                                                                    await geoFeedsModule.deleteGeoFeed(data.id); 
                                                                    getGeoFeeds();
                                                                }} 
                                                            />                             
                                    </span>         
                                </div>   
                            </>
                        }}                    
                        columns={[          
                            { name: 'name', caption: translate('name'), className: 'app-geo-feeds-column-text', allowFiltering: false,  width:'200px', onRender: (o) => { 
                                return o.name; 
                            }},
                            { name: 'description', caption: translate('description'), className: 'app-geo-feeds-column-text', allowFiltering: false,  width:'400px', onRender: (o) => { 
                                return o.description; 
                            }},                                                                                                  
                            { name: 'owner', caption: translate('owner'), allowFiltering: false, width:'200px', onRender: (o) => 
                            {                        
                                return ( <>
                                    <img className='app-geo-feeds-user-image' src={legacyEndpoints.handlers.getPhotoUrl({ userId: o.user.Id, isUserPhoto: true, width: 150, height: 150 })} alt={translate('user_image')} />
                                    <span className='app-geo-feeds-username'>
                                        {o.user.Name}
                                    </span>
                                </>
                                ) 
                            }},
                        ]}
                        onLoad={async (o)=>{
                            return {
                                data: geoFeeds.filter(g => (filter===allId) || (filter===sharedId && g.isShared === true) || (filter!==sharedId && g.isShared === false)),
                                totalCount: geoFeeds.length
                            };
                        }}
                    />      
                    }
            </div>
        </div>            
    </LargePanel>
    </>
}