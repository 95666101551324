// React imports
import { useState, useEffect, useCallback } from 'react'

// App imports
import { Button } from '../../../base/button/button';
import { Bar } from '../../../base/bar/bar';
import { FilterDetailsLD } from './filterDetailsLD';
import { FilterDetailsC } from './filterDetailsC';
import { FilterDetailsT } from './filterDetailsT';
import { icons, Icon } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { analogs } from '../../../../modules/analogs';

const _ = require("lodash");

export function ModifyFilters({ analogSettings, analogFilters, onClose, onRecalculate }) {
    
    const [variableFields, setVariableFields] = useState([]);
    const [activeVariable, setActiveVariable] = useState(null);
    const [activeFilters, setActiveFilters] = useState([]);
    const [distribution, setDistribution] = useState([]);
    const [distributionFieldId, setDistributionFieldId] = useState(null);

	useEffect(() =>{	

        if (!_.isArray(analogSettings.filters) || analogSettings.filters.length === 0)
            return;

        var newFields = _.cloneDeep(analogSettings.filters[0].PredicateFields);
        var newVariables = [];

        newFields.forEach(field => {
            
            var filter = analogFilters.find(o => o.ID === field.ID);

            if (_.isObject(filter)) {
                field.HighValue = filter.HighValue;
                field.LowValue = filter.LowValue;
                field.Value = filter.Value;
                field.IsChecked = true;

                if (filter.ValueList != null) {
                    field.ValueList.forEach(item => {
                        if (_.indexOf(filter.ValueList, item.Value) != -1)
                            item.IsChecked = true;
                        else
                            item.IsChecked = false;
                    });
                }
            }
            else
                field.IsChecked = false;

            newVariables.push({ ...field, IsModified: false, IsValid: true });
        });

        setVariableFields([...newVariables]);
        setActiveVariable( newVariables.length > 0 ? newVariables[0] : null );
        setDistributionFieldId(null);

    }, [analogSettings.filters, analogFilters]);

	useEffect(() =>{	

        if (!_.isArray(variableFields) || variableFields.length === 0)
            return;

        var newFilters = [];
        
        variableFields.forEach(variable => {

            var minVal = variable.MinValue;
            var maxVal = variable.MaxValue;

            if ((variable.SubjectSiteValue != null) && (variable.SubjectSiteValue.length > 0)) {
                minVal = variable.LowValue;
                maxVal = variable.HighValue;
            }

            if (variable.IsChecked == true) {

                switch (variable.Type)
                {
                    case "L":
                    case "D":
                        var myNewArray = [];

                        variable.ValueList.forEach(item =>{
                            if (item.IsChecked == true)
                                myNewArray.push(item.Value);
                        });

                        newFilters.push({
                            ID: variable.ID,
                            LowValue: 0,
                            HighValue: 0,
                            Value: null,
                            ValueList: myNewArray
                        });

                        break;
                    case "C":
                        newFilters.push({
                            ID: variable.ID,
                            LowValue: minVal,
                            HighValue: maxVal,
                            Value: null,
                            ValueList: null
                        });

                        break;
                    default:
                        newFilters.push({
                            ID: variable.ID,
                            LowValue: 0,
                            HighValue: 0,
                            Value: variable.Value,
                            ValueList: null
                        });
                        
                        break;
                }
            }
        });

        setActiveFilters([...newFilters]);

    }, [variableFields]);

    const refreshDistribution = useCallback(
        async () =>{

            if (!_.isArray(variableFields) || variableFields.length === 0)
                return;

            var newVariables = [];
            
            variableFields.forEach(item => {
                if (item.IsChecked) {
                    var variable = { 
                        ID: item.ID, 
                        LowValue: item.LowValue,
                        HighValue: item.HighValue,
                        Value: item.Value,
                        ValueList: null
                    };

                    if (item.Type == "L" || item.Type == "D") {
                        variable.ValueList = !_.isArray(item.ValueList) || item.ValueList.length == 0 ? [] : item.ValueList.filter(x => x.IsChecked).map(o => o.Value);
                    }

                    newVariables.push(variable);
                }
            });

            var newDistribution = [];

            if (newVariables.length > 0)
                newDistribution = await analogs.getRawAndWeightedColumnDistribution({
                    templateId: analogSettings.templateId,
                    id: distributionFieldId,
                    lat: analogSettings.subjectSite.Location.Lat,
                    lon: analogSettings.subjectSite.Location.Lon,
                    valueList: newVariables
                });

            newDistribution.forEach(newDist => {
                var oldDist = distribution.find(x => x.Id === newDist.Id);

                if (_.isObject(oldDist)) {
                    if (distributionFieldId !== oldDist.Id && distributionFieldId != null)
                        newDist.Raw = oldDist.Raw;
                }
            });

            setDistribution([...newDistribution]);

        },
        [analogSettings, variableFields, distributionFieldId]
    );

    useEffect(() =>{	
        refreshDistribution();
    }, [refreshDistribution]);

    const updateVariableLD = useCallback(
        (o) =>{
            o.variable.ValueList = [...o.valueList];
            o.variable.IsModified = true;
            setVariableFields([...variableFields]);
            setDistributionFieldId(o.variable.ID);
        },
        [variableFields]
    );
    
    const updateVariableC = useCallback(
        (o) =>{
            if (o.variable.SubjectSiteValue !== null && o.variable.SubjectSiteValue.length > 0) {
                o.variable.LowValue = o.minValue.toString();
                o.variable.HighValue = o.maxValue.toString();
            }
            else {
                o.variable.MinValue = o.minValue.toString();
                o.variable.MaxValue = o.maxValue.toString();
            }

            o.variable.IsModified = true;
            o.variable.IsValid = o.isValid;
            setVariableFields([...variableFields]);
            setDistributionFieldId(o.variable.ID);
        },
        [variableFields]
    );

    const updateVariableT = useCallback(
        (o) =>{
            o.variable.Value = o.value.toString();
            o.variable.IsModified = true;
            setVariableFields([...variableFields]);
            setDistributionFieldId(o.variable.IsScoreCondition ? null : o.variable.ID);
        },
        [variableFields]
    );

    const renderFilterDetails = (variable) => {
        switch (variable.Type) {
            default:
                return <div>{translate('no_data')}</div>;
            case "L":
            case "D":
                return <FilterDetailsLD 
                    key={variable.ID} 
                    isLocked={analogSettings.isLocked}
                    distribution={distribution} 
                    distributionFieldId={distributionFieldId}
                    variable={variable} 
                    updateVariable={(o) => { updateVariableLD({ valueList: o, variable: variable }); }} 
                />;
            case "C":
                return <FilterDetailsC 
                    key={variable.ID} 
                    isLocked={analogSettings.isLocked}
                    distribution={distribution} 
                    distributionFieldId={distributionFieldId}
                    variable={variable} 
                    updateVariable={(o) => { updateVariableC({ ...o, variable: variable }); }} 
                />;
            case "T":
                return <FilterDetailsT 
                    key={variable.ID} 
                    isLocked={analogSettings.isLocked}
                    distribution={distribution} 
                    distributionFieldId={distributionFieldId}
                    variable={variable} 
                    updateVariable={(o) => { updateVariableT({ value: o, variable: variable }); }} 
                />;
            }
    }

    return <>
        <div className='app-point-scenarios-title'>
            { analogSettings.isLocked ? translate('view_filters') : translate('modify_filters') }
            <Button className='app-point-scenarios-close' theme='action' icon={icons.x} tooltip={translate('close')} onClick={() => { onClose(); }}/>
        </div>
        { analogSettings.isLocked ? '' :
            <div className='app-analogs-filter-recalculate'>
                <div>
                    {   variableFields.filter(x => !x.IsValid && x.IsChecked).length > 0 ?
                            <div className='app-analogs-filter-changes-error-text'>
                                <Icon className={'app-analogs-filter-changes-warning-icon'} icon={icons.octogonExclamation}/>{translate('is_active_with_errors')}
                            </div>
                        : variableFields.filter(x => x.IsModified && !x.IsChecked).length > 0 ?
                            <div className='app-analogs-filter-changes-warning-text'>
                                <Icon className={'app-analogs-filter-changes-warning-icon'} icon={icons.warning}/>{translate('not_active_with_changes')}
                            </div>
                        : ''
                    }
                </div>
                <Button 
                    className='app-point-scenarios-create-button' 
                    theme='secondary' 
                    size='small' 
                    tooltip={translate('recalculate')} 
                    icon={icons.check} 
                    disabled={variableFields.filter(x => !x.IsValid && x.IsChecked).length > 0} 
                    onClick={()=>{
                        onRecalculate(activeFilters);
                    }} 
                />
            </div>
        }
        <div className='app-analogs-filters'>
            <div className='app-analogs-filter-items'>
            {
                variableFields.map((item) => {
                    return <div key={`selection_${item.ID}`} className='app-analogs-filter-row'>
                        <Button 
                            className={ !item.IsValid && item.IsChecked ? 'app-analogs-filter-changes-error-button' : item.IsModified && !item.IsChecked ? 'app-analogs-filter-changes-warning-button' : ''}
                            key={`select_${item.ID}`}
                            theme='action' 
                            icon={item.IsChecked ? icons.squareCheck : icons.squareEmpty} 
                            tooltip={item.IsChecked ? translate('exclude') : translate('include')} 
                            disabled={item.IsReadOnly || analogSettings.isLocked}
                            onClick={() => { 
                                item.IsChecked = !item.IsChecked;
                                setVariableFields([...variableFields]);
                                setActiveVariable(item);
                                setDistributionFieldId(item.IsChecked ? item.ID : null);
                            }}
                        />
                        <Bar
                            key={`edit_${item.ID}`}
                            className='app-legend-menu-item'
                            text={item.Label}
                            active={item.IsChecked}
                            actions={[ { icon: item.IsReadOnly || analogSettings.isLocked ? icons.memoCircleInfo : icons.edit, tooltip: item.IsReadOnly || analogSettings.isLocked ? translate('view') : translate('edit'), default: true } ]}
                            onClick={()=>{ 
                                setActiveVariable(item);
                            }}
                        />
                    </div>
                })
            }
            </div>
            <div className='app-analogs-filter-details'>
            { activeVariable == null ? '' : 
                <div className='app-analogs-filter-details-content'>
                    <div className='app-analogs-filter-details-title'>{activeVariable.Label}</div>
                    { renderFilterDetails(activeVariable) }
                </div>
            }
            </div>
        </div>
    </>
}