import { useState } from 'react';

import { legacyEndpoints } from "../../../services/legacyEndpoints";
import { translate } from "../../../utils/translation";
import { Button } from "../../base/button/button";
import { Icon, icons } from "../../base/icon/icon";
import { TextBox } from "../../base/textBox/textBox";
import { SimpleTable } from '../../base/simpleTable/simpleTable';
import { TextArea } from '../../base/textArea/textArea';
import { Description } from '../../base/description/description';
import { artificialIntelligence } from '../../../modules/artificialIntelligence';
import { Panel } from '../../base/panel/panel';

const _ = require("lodash");

export function LocationChat({ onClose=()=>{} }){
		
	const [query, setQuery] = useState('');
	const [layerName, setLayerName] = useState('');
	const [data, setData] = useState(null);
	const [generating, setGenerating] = useState(false);

	return <Panel className='app-location-chat-panel' text={translate('location_chat')} open={true} loaded={true} generated={!generating} generatingLogo={'https://cdn.tradeareasystems.net/Images/Components/Misc/OpenAI2.png'} onClose={()=>{onClose()}}>
		<div className='app-hot-spot-header'>
			<Button theme='secondary' size={'medium'} icon={icons.x} onClick={()=>{onClose()}} />
			<Button theme='primary' size={'medium'} icon={icons.check} disabled={data === null || data.length === 0 || layerName === ''} onClick={()=>{
				artificialIntelligence.create({ data: data, layerName: layerName });
				onClose();
			}} />
		</div>
		<div className='app-location-chat'>					
            <Description className='app-location-chat-desciption' description={translate('location_chat_description')} />
            <div>
                <table className='app-location-chat-shield-table'>
                    <tbody>
                        <tr>
                            <td className='app-location-chat-shield'>
                                <div className='app-location-chat-shield-float' title={translate('protected')}>
                                    <Icon icon={icons.sheildHalved} />
                                </div>                                
                            </td>
                            <td>
                                <div className='app-location-chat-shield-text'>
                                    {translate('protected')}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>                         
            </div>            
            <TextBox label={translate('layer_name')} placeholder={translate('ai_drug_stores')} value={layerName} onChange={(o) => {setLayerName(o.value)}} />
            <TextArea label={translate('prompt')} placeholder={translate('location_chat_placeholder')} minHeight={'100px'} value={query} onChange={(o) => {setQuery(o.value)}} />
            <div className='app-location-chat-button-disclaimer'>{translate('powered_by_chatgpt')}</div>
            <div className='app-location-chat-button'>
                <Button theme='primary' size='small' icon={icons.comments} disabled={query.length === 0} onClick={async () =>{

                    setGenerating(true);
                    await legacyEndpoints.service({
                        name: 'LocationChat',
                        parameters: {
                            query: query
                        },
                        success: function(result) {
                            var locations = result.results;
                            locations = locations.length > 0 ? locations.filter(x => x !== locations[0]) : locations;
                            setData(locations);
                        }
                    });
                    setGenerating(false);

                }} />
            </div>
            {
                data === null ? 
                    <div className='app-location-chat-body-null'>
                        <div className='app-location-chat-body-empty'>
                            {translate('ask_something_to_review_results')}
                        </div>		
                    </div>		
            :
                data.length === 0 ? 
                    <div className='app-location-chat-body-null'>
                        <div className='app-location-chat-body-empty'>
                            {translate('no_results')}
                        </div>
                    </div>	
                :
                <>
                    <SimpleTable headers={[translate('name'), translate('address')]} data={[]} />
                    <div className='app-location-chat-body'>
                        <SimpleTable data={data.map((value)=>{return([value.name,value.address]);})} />
                    </div>
                </>
            }
		</div>
	</Panel>;
}