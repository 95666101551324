import { legacyEndpoints } from '../services/legacyEndpoints';

const _ = require("lodash");

export const tasks = {
    getFilteredTasks: async (o) =>{
        return await legacyEndpoints.service({
            name: 'GetFilteredTasks',
            parameters: {
                skip: o.skip,
                take: o.take,
                filters: o.filters,
                sorts: o.sorts
            }
        });
    },
    getColumnFilter: async (o) =>{
        return await legacyEndpoints.service({
            name: 'GetTasksColumnFilter',
            parameters: {
                field: o.field,
                filters: o.filters,
                searchOperation: o.searchOperation,
                searchValue: o.searchValue,
                skip: o.skip,
                take: o.take
            }
        });
    }
};