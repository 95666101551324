import { constants } from '../../../utils/constants';
import { translate } from '../../../utils/translation';

const _ = require('lodash');

export function ViewerInformation(){

	return <div className='app-viewer-information'>
        <b>{translate('viewer_information_title')}</b>
        <br />
        <br />
        {translate('viewer_information_paragraph_1')}
        <br />
        <br />
        {translate('viewer_information_paragraph_2')}
        <br />
        <br />
        {translate('viewer_information_list_title')}
        <ul>
            <li>{translate('viewer_information_list_1')}</li>
            <li>{translate('viewer_information_list_2')}</li>
            <li>{translate('viewer_information_list_3')}</li>
            <li>{translate('viewer_information_list_4')}</li>
            <li>{translate('viewer_information_list_5')}</li>            
            <li>{translate('viewer_information_list_6')}</li>
        </ul>
        <b>{translate('viewer_information_free_title')}</b>
        <br />
        <br />
        {translate('viewer_information_free_paragraph')}
        <br />
        <br />
        <b>{translate('viewer_information_more_info_title')}</b>
        <br />
        <br />
        <a target="blank" href={constants.company_website}>{translate('click_here')}</a>
        {translate('viewer_information_more_info_paragraph')}
    </div>
}