import { legacyEndpoints } from "../services/legacyEndpoints";
import { constants } from "../utils/constants";
import { helpers } from "../utils/helpers";

const _ = require("lodash");

export const forms = {
    debug: {
        on: false,
        evaluateTrue: true,
        evaluateFalse: true
    },
    validateForm: ({collapsedForm, updateForm}) => {
        var activeFailures = false;
        var activeWarnings = false;
        var collapsedObjects = forms.buildCollapsedObjects({collapsedForm: collapsedForm, updateForm: updateForm});

        _.forEach(collapsedObjects, function(collapsedObject){
            forms.evaluateConditions({collapsedObject: collapsedObject, collapsedObjects: collapsedObjects, updateForm: updateForm});  
        });
        //check required and warning fields
        _.forEach(collapsedObjects, function(collapsedObject){
            var invalidResponse = forms.checkInvalid(collapsedObject);
            if (invalidResponse.isInvalid){
                activeFailures = true;
                collapsedObject.field.showError = true;
                collapsedObject.field.errorMessage = invalidResponse.message;
            }
            else {
                collapsedObject.field.showError = false;
                collapsedObject.field.errorMessage = null
            }
                
            var warningResponse = forms.checkWarning(collapsedObject);
            if (warningResponse.isWarning){
                activeWarnings = true;
                collapsedObject.field.showWarning = true;
                collapsedObject.field.warningMessage = warningResponse.message;
            }
            else {
                collapsedObject.field.showWarning = false;
                collapsedObject.field.warningMessage = null;
            }
        });
        return {
            activeFailures: activeFailures,
            activeWarnings: activeWarnings,
            collapsedObjects: collapsedObjects
        };
    },
    collapseForm: ({form}) => {
        var collapsedObjects = [];
                
        if (form == null)
            return collapsedObjects;
        //Iterate through form fields
        _.forEach(form.Tabs, function(tab, tabIndex){
            _.forEach(tab.Pages, function(page, pageIndex){
                _.forEach(page.Sections, function(section, sectionIndex){
                    _.forEach(section.Rows, function(row, rowIndex) {
                        _.forEach(row.Fields, function(field, fieldIndex) {
                            collapsedObjects.push({
                                field: _.cloneDeep(field),
                                readOnly: _.cloneDeep(field.Component.ComponentReadOnly),
                                required: _.cloneDeep(field.Component.ComponentRequired),
                                requiredBehavior: _.cloneDeep(field.Component.RequiredBehavior),
                                section: _.cloneDeep(section),
                                tab: _.cloneDeep(tab),
                                value: _.cloneDeep(field.Component.ComponentValue),
                                path: {
                                    tabIndex: tabIndex,
                                    pageIndex: pageIndex,
                                    sectionIndex: sectionIndex,
                                    rowIndex: rowIndex,
                                    fieldIndex: fieldIndex
                                }
                            });
                        });
                    });
                });
            });
        }); 
        return collapsedObjects;
    },
    buildCollapsedObjects: ({collapsedForm, updateForm}) => {
        //Get Values
        _.forEach(updateForm.UpdateSections, function(updateSection){
            _.forEach(updateSection.UpdateFields, function(updateField) {
                _.forEach(collapsedForm, function(collapsedObject){
                    if (collapsedObject.field.Id === updateField.FieldId){
                        collapsedObject.value = updateField.Value;
                        collapsedObject.field.Component.ComponentValue = updateField.Value;
                    }
                });
            });
        });

        return collapsedForm;
    },
    checkInvalid: (collapsedObject) => {
        //TODO Remove this when we have a better way to handle photos
        if (collapsedObject.field.Component.ComponentType === constants.forms.components.photo)
            return false;

        var isInvalid = collapsedObject.required 
            && collapsedObject.requiredBehavior === constants.forms.componentRequiredBehaviors.invalid 
            && (_.isNull(collapsedObject.value) || (_.isArrayLike(collapsedObject.value) && _.isEmpty(collapsedObject.value)));
        var message = null;

        _.forEach(collapsedObject.field.validObjectList, function(validObject){
            if (validObject.valid === false && validObject.behavior === constants.forms.componentRequiredBehaviors.invalid) {
                isInvalid = true;
                if(_.isString(validObject.message) && validObject.message.length > 0) 
                    message = validObject.message;
            }
        });

        return {isInvalid, message};
    },
    checkWarning: (collapsedObject) => {
        //TODO Remove this when we have a better way to handle photos
        if (collapsedObject.field.Component.ComponentType === constants.forms.components.photo)
            return false;

        var isWarning = collapsedObject.required 
            && collapsedObject.requiredBehavior === constants.forms.componentRequiredBehaviors.warning 
            && (_.isNull(collapsedObject.value) || (_.isArrayLike(collapsedObject.value) && _.isEmpty(collapsedObject.value)));
        var message = null;

        _.forEach(collapsedObject.field.validObjectList, function(validObject){
            if (validObject.valid === false && validObject.behavior === constants.forms.componentRequiredBehaviors.warning) {
                isWarning = true;
                if(_.isString(validObject.message) && validObject.message.length > 0) 
                    message = validObject.message;
            }
        });

        return {isWarning, message};
    },
    validateInputForm: ({form, collapsedForm, updateForm}) => {
        var validation = forms.validateForm({form: form, collapsedForm: collapsedForm, updateForm: updateForm});
        var markedForm = forms.markConditions({form: form, collapsedObjects: validation.collapsedObjects});
        return {
            form: markedForm,
            collapsedForm: validation.collapsedObjects,
            errors: validation.activeFailures,
            warnings: validation.activeWarnings
        }
    },
    markConditions: ({form, collapsedObjects}) => {
        var formClone = form;

        _.forEach(collapsedObjects, function(collapsedObject){
            var currentSection = formClone.Tabs[collapsedObject.path.tabIndex].Pages[collapsedObject.path.pageIndex].Sections[collapsedObject.path.sectionIndex];
            //Handle Hidden Sections
            if (collapsedObject.section.hidden != null)
                currentSection.hidden = collapsedObject.section.hidden;
           
            var currentField = currentSection.Rows[collapsedObject.path.rowIndex].Fields[collapsedObject.path.fieldIndex];
            
            //Handle Marking Error Fields
            if (collapsedObject.field.showError) {
                currentField.showError = true;
                currentField.errorMessage = collapsedObject.field.errorMessage;
            }

            //Handle Marking Warning Fields
            if (collapsedObject.field.showWarning){
                currentField.showWarning = true;
                currentField.warningMessage = collapsedObject.field.warningMessage;
            }

            //Handle Hidden Fields
            currentField.hidden = collapsedObject.field.hidden != null ? collapsedObject.field.hidden : false;

            //Handle Marking Required Fields
            if (collapsedObject.required === true)
                currentField.Component.ComponentRequired  = true;
            
            //Handle Marking Not Required Fields
            if (collapsedObject.required === false)
                currentField.Component.ComponentRequired  = false;

            //Handle Marking Enabled Fields
            if (collapsedObject.field.readOnly === true)
                currentField.Component.ComponentReadOnly  = true;

            //Handle Marking Disabled Fields
            if (collapsedObject.field.readOnly === false)
                currentField.Component.ComponentReadOnly  = false;

            //Handle Marking Styles
            if (_.isObject(collapsedObject.field.styleOverride)) {
                currentField.styleOverride = collapsedObject.field.styleOverride;
            }
            //Handle Marking Default Styles
            if (_.isNull(collapsedObject.field.styleOverride) || _.isUndefined(collapsedObject.field.styleOverride))
                currentField.styleOverride = null;

            //Handle Marking Clear On Save Fields
            if (collapsedObject.field.clearOnSave)
                currentField.clearOnSave = true;
            else
                currentField.clearOnSave = false;
        });

        return formClone;
    },
    saveAddForm: async ({entity, form, collapsedForm, updateForm}) => {
        var validation = forms.validateForm({form: form, collapsedForm: collapsedForm, updateForm: updateForm});
        form = forms.markConditions({form: form, collapsedObjects: validation.collapsedObjects});

        if (!validation.activeFailures) {
            updateForm = forms.processClearOnSave({form: form, updateForm: updateForm});            

            if (entity.type == constants.entities.pushpin || entity.type == constants.entities.point)
            {
                await legacyEndpoints.service({
                    name: "SetDataForInsertV2",
                    parameters: {
                        FormId: form.Id,
                        AddForm: updateForm
                    }
                });
            }
            else {
                await legacyEndpoints.service({
                    name: "SetDataForInsertV2",
                    parameters: {
                        FormId: form.Id,
                        AddForm: updateForm,
                        EncodedString: helpers.encodedLocations(entity.paths)
                    }
                });
            }
        }
            
        return {
            errors: validation.activeFailures,
            warnings: validation.activeWarnings,
            form: form
        };
    },
    saveEditForm: async ({customQueryId, pointId, form, collapsedForm, updateForm}) => {
        var validation = forms.validateForm({form: form, collapsedForm: collapsedForm, updateForm: updateForm});
        form = forms.markConditions({form: form, collapsedObjects: validation.collapsedObjects});

        if (!validation.activeFailures) {
            updateForm = forms.processClearOnSave({form: form, updateForm: updateForm});

            await legacyEndpoints.service({
                name: "SetDataForUpdateV2",
                parameters: {
                    ServiceID: customQueryId,
                    PointID: pointId,
                    UpdateForm: updateForm
                }
            });
        }
            
        return {
            errors: validation.activeFailures,
            warnings: validation.activeWarnings,
            form: form
        };
    },
    evaluateConditions: async function ({collapsedObject, collapsedObjects, updateForm}) {
        collapsedObject.field.Component.FormConditions = _.isArray(collapsedObject.field.Component.FormConditions) ? collapsedObject.field.Component.FormConditions : [];
        collapsedObject.field.Component.Conditions = _.isArray(collapsedObject.field.Component.Conditions) ? collapsedObject.field.Component.Conditions : [];
        
        _.each(collapsedObject.field.Component.FormConditions.concat(collapsedObject.field.Component.Conditions), function (condition, i) {
            if (condition.filterBuilders.length === 0)
                return;

            if (forms.debug.on)
                console.log("Evaluating: " + condition.name);

            var result = forms.evaluateFilterBuilders({
                filterBuilders: condition.filterBuilders,
                collapsedField: collapsedObject,
                collapsedObjects: collapsedObjects,
                updateForm: updateForm
            });

            var evaluation = null;

            try {
                evaluation = eval(result.expression);
            }
            catch (e) {
                console.log("ERROR:", condition, (result.debugFormulaExpression.length > 0 ? result.debugFormulaExpression + "\n" : "") + result.debugExpression, e);
            }

            if (_.isNull(evaluation) || _.isUndefined(evaluation))
                return;

            if (forms.debug.on && ((forms.debug.evaluateTrue && evaluation) || (forms.debug.evaluateFalse && !evaluation)))
            {
                console.log("(" + (i + 1) + ") " + condition.name + " ---------------------------------------------------");

                if (result.debugFormulaExpression.length > 0)
                    console.log(result.debugFormulaExpression);

                console.log(result.debugExpression);
                console.log(result.expression);
                console.log("Result: " + evaluation);
                console.log("-------------------------------------------------------------");
            }
            _.each(condition.actions, function (action) {

                if (action.evaluation !== evaluation)
                    return;
                var promises = [];
                switch (action.actionObject) {
                    case constants.forms.formConditions.actionObjects.tab:
                        var tabFilteredCollapsedObjects = _.filter(collapsedObjects, function (f) { return f.tab.Id.toLowerCase() === action.tabId.toLowerCase(); });
                        if (tabFilteredCollapsedObjects.length === 0)
                            break;

                        forms.performTabAction({
                            action: action,
                            tab: tabFilteredCollapsedObjects[0].tab,
                            collapsedObjects: collapsedObjects,
                            conditionId: condition.id
                        });

                        tabFilteredCollapsedObjects.forEach(async tabFilteredCollapsedObject => {
                            promises.push(
                                new Promise(async (resolve) => {
                                    forms.performFieldAction({
                                        action: action,
                                        field: tabFilteredCollapsedObject.field,
                                        collapsedObjects: collapsedObjects,
                                        conditionId: condition.id
                                    });

                                    resolve();
                                })
                            );
                            
                            await Promise.all(promises);
                        });

                        break;
                    case constants.forms.formConditions.actionObjects.section:
                        var sectionFilteredCollapsedObjects = _.filter(collapsedObjects, function (f) { return f.section.Id.toLowerCase() === action.sectionId.toLowerCase(); });
                        if (sectionFilteredCollapsedObjects.length === 0)
                            break;

                        forms.performSectionAction({
                            action: action,
                            section: sectionFilteredCollapsedObjects[0].section,
                            collapsedObjects: collapsedObjects,
                            conditionId: condition.id
                        });

                        sectionFilteredCollapsedObjects.forEach(async sectionFilteredCollapsedObject => {
                            promises.push(
                                new Promise(async (resolve) => {
                                    forms.performFieldAction({
                                        action: action,
                                        field: sectionFilteredCollapsedObject.field,
                                        collapsedObjects: collapsedObjects,
                                        conditionId: condition.id
                                    });

                                    resolve();
                                })
                            );
                            
                            await Promise.all(promises);
                        }); 
                        break;
                    case constants.forms.formConditions.actionObjects.field:

                        var collapsedField = _.filter(collapsedObjects, function (f) { return f.field.Id.toLowerCase() === action.fieldId.toLowerCase(); });
                        if (collapsedField.length === 0)
                            break;

                        forms.performFieldAction({
                            action: action,
                            field: collapsedField[0].field,
                            collapsedObjects: collapsedObjects,
                            conditionId: condition.id
                        });

                        break;
                    default:
                        break;
                }
            });
        });
    },
    evaluateFilterBuilders: function ({filterBuilders, collapsedField, collapsedObjects, updateForm}) {
        var expression = "";
        var debugExpression = "";
        var debugFormulaExpression = "";

        _.each(filterBuilders, function (filterBuilder) {

            switch (filterBuilder.expression) {
                case constants.expressions.none:
                    expression += " (";
                    debugExpression += " (";
                    break;
                case constants.expressions.and:
                    expression += " && (";
                    debugExpression += " && (";
                    break;
                case constants.expressions.andNot:
                    expression += " && !(";
                    debugExpression += " && !(";
                    break;
                case constants.expressions.or:
                    expression += " || (";
                    debugExpression += " || (";
                    break;
                case constants.expressions.orNot:
                    expression += " || !(";
                    debugExpression += " || !(";
                    break;
                default:
                    break;
            }

            _.each(filterBuilder.filters, function (filter) {

                var evaluation = null;
                var debugEvaluation = null;
                var debugFormulaEvaluation = "";
                var _collapsedField = _.isString(filter.field) && filter.field.length > 0 ? _.filter(collapsedObjects, function (f) { return f.field.Id.toLowerCase() === filter.field.toLowerCase(); })[0] : collapsedField;

                var value = _collapsedField.value;

                if (_collapsedField.field.Component.ComponentType === constants.forms.components.photo)
                    value = _collapsedField.field.Component.ComponentMultimedia.length.toString();
                
                var debugValue = value;
                var filterLabel = _.isString(filter.field) && filter.field.length > 0 ? _.filter(collapsedObjects, function (f) { return f.field.Id.toLowerCase() === filter.field.toLowerCase(); })[0].field.Label : "User Defined";
                var valueLabel = filter.type === constants.filterTypes.specificValue ? "Condition Value" : "Formula Value";

                switch (filter.expression) {
                    case constants.expressions.and:
                        expression += " && ";
                        debugExpression += " && ";
                        break;
                    case constants.expressions.andNot:
                        expression += " && !";
                        debugExpression += " && !";
                        break;
                    case constants.expressions.or:
                        expression += " || ";
                        debugExpression += " || ";
                        break;
                    case constants.expressions.orNot:
                        expression += " || !";
                        debugExpression += " || !";
                        break;
                    default:
                        break;
                }

                switch (filter.operator) {
                    case constants.operators.contains:
                    case constants.operators.notContains:
                    case constants.operators.startsWith:
                    case constants.operators.notStartsWith:
                    case constants.operators.endsWith:
                    case constants.operators.notEndsWith:
                    case constants.operators.equals:
                    case constants.operators.notEquals:
                    case constants.operators.isNull:
                    case constants.operators.isNotNull:
                    case constants.operators.isEmpty:
                    case constants.operators.isNotEmpty:
                        debugValue = _.isNull(value) || _.isUndefined(value) || value.length === 0 ? "NULL" : "\"" + value + "\"";
                        break;
                    case constants.operators.between:
                    case constants.operators.notBetween:
                    case constants.operators.lessThan:
                    case constants.operators.greaterThan:
                    case constants.operators.lessThanEquals:
                    case constants.operators.greaterThanEquals:
                        debugValue = _.isNull(value) || _.isUndefined(value) || value.length === 0 ? "NULL" : value;
                        break;
                    case constants.operators.isAnyOf:
                    case constants.operators.isNoneOf:
                        // Not Implemented
                        break;
                    default:
                        break;
                }

                var filterValue;
                if (filter.type === constants.filterTypes.specificValue)
                    filterValue = filter.value;
                else {
                    var formulaResult = forms.evaluateFormulaBuilders({
                        formulaBuilders: filter.formula.formulaBuilders,
                        collapsedObjects: collapsedObjects,
                        updateForm: updateForm
                    });

                    filterValue = formulaResult.value || "NULL";

                    _.each(formulaResult.evaluations, function (formulaEvaluation) {
                        debugFormulaEvaluation += "Formula Value: " + formulaEvaluation + "\n";
                    });
                }

                switch (filter.operator) {
                    case constants.operators.contains:
                        evaluation = !_.isNull(value) && value.toString().toLowerCase().indexOf(filterValue.toString().toLowerCase()) !== -1;
                        debugEvaluation = "[" + filterLabel + "]" + debugValue + ".Contains([" + valueLabel + "]\"" + filterValue + "\")";
                        break;
                    case constants.operators.notContains:
                        evaluation = !_.isNull(value) && value.toString().toLowerCase().indexOf(filterValue.toString().toLowerCase()) === -1;
                        debugEvaluation = "[" + filterLabel + "]!" + debugValue + ".Contains([" + valueLabel + "]\"" + filterValue + "\")";
                        break;
                    case constants.operators.startsWith:
                        evaluation = !_.isNull(value) && value.toString().toLowerCase().startsWith(filterValue.toString().toLowerCase());
                        debugEvaluation = "[" + filterLabel + "]" + debugValue + ".StartsWith([" + valueLabel + "]\"" + filterValue + "\")";
                        break;
                    case constants.operators.notStartsWith:
                        evaluation = !_.isNull(value) && !value.toString().toLowerCase().startsWith(filterValue.toString().toLowerCase());
                        debugEvaluation = "[" + filterLabel + "]!" + debugValue + ".StartsWith([" + valueLabel + "]\"" + filterValue + "\")";
                        break;
                    case constants.operators.endsWith:
                        evaluation = !_.isNull(value) && value.toString().toLowerCase().endsWith(filterValue.toString().toLowerCase());
                        debugEvaluation = "[" + filterLabel + "]" + debugValue + ".EndsWith([" + valueLabel + "]\"" + filterValue + "\")";
                        break;
                    case constants.operators.notEndsWith:
                        evaluation = !_.isNull(value) && !value.toString().toLowerCase().endsWith(filterValue.toString().toLowerCase());
                        debugEvaluation = "[" + filterLabel + "]!" + debugValue + ".EndsWith([" + valueLabel + "]\"" + filterValue + "\")";
                        break;
                    case constants.operators.equals:
                        evaluation = !_.isNull(value) && value.toString().toLowerCase() === filterValue.toString().toLowerCase();
                        debugEvaluation = "[" + filterLabel + "]" + debugValue + " == [" + valueLabel + "]\"" + filterValue + "\"";
                        break;
                    case constants.operators.notEquals:
                        evaluation = !_.isNull(value) && value.toString().toLowerCase() !== filterValue.toString().toLowerCase();
                        debugEvaluation = "[" + filterLabel + "]" + debugValue + " != [" + valueLabel + "]\"" + filterValue + "\"";
                        break;
                    case constants.operators.between:
                        evaluation = parseFloat(value) >= parseFloat(filterValue) && parseFloat(value) <= parseFloat(filter.maxValue);
                        debugEvaluation = "[" + filterLabel + "]" + debugValue + " >= [" + valueLabel + "]\"" + filterValue + "\" && " + value + " <= [" + valueLabel + "]\"" + filter.maxValue + "\"";
                        break;
                    case constants.operators.notBetween:
                        evaluation = parseFloat(value) < parseFloat(filterValue) || parseFloat(value) > parseFloat(filter.maxValue);
                        debugEvaluation = "[" + filterLabel + "]" + debugValue + " < [" + valueLabel + "]\"" + filterValue + "\" && " + value + " > [" + valueLabel + "]\"" + filter.maxValue + "\"";
                        break;
                    case constants.operators.lessThan:
                        evaluation = parseFloat(value) < parseFloat(filterValue);
                        debugEvaluation = "[" + filterLabel + "]" + debugValue + " < [" + valueLabel + "]" + filterValue;
                        break;
                    case constants.operators.greaterThan:
                        evaluation = parseFloat(value) > parseFloat(filterValue);
                        debugEvaluation = "[" + filterLabel + "]" + debugValue + " > [" + valueLabel + "]" + filterValue;
                        break;
                    case constants.operators.lessThanEquals:
                        evaluation = parseFloat(value) <= parseFloat(filterValue);
                        debugEvaluation = "[" + filterLabel + "]" + debugValue + " <= [" + valueLabel + "]" + filterValue;
                        break;
                    case constants.operators.greaterThanEquals:
                        evaluation = parseFloat(value) >= parseFloat(filterValue);
                        debugEvaluation = "[" + filterLabel + "]" + debugValue + " >= [" + valueLabel + "]" + filterValue;
                        break;
                    case constants.operators.isNull:
                        
                        var isNull = _.isNull(value) || _.isUndefined(value) ? "" : value;

                        if (_collapsedField.field.Component.ComponentType === constants.forms.components.photo)
                            evaluation = parseInt(value) === 0;
                        else
                            evaluation = _.isNull(isNull) || _.trim(isNull.toString()).length === 0;

                        debugEvaluation = "[" + filterLabel + "]" + debugValue + " == null || [" + filterLabel + "]" + debugValue + ".Length() == 0";
                        break;
                    case constants.operators.isNotNull:
                        var isNotNull = _.isNull(value) || _.isUndefined(value) ? "" : value;

                        if (_collapsedField.field.Component.ComponentType === constants.forms.components.photo)
                            evaluation = parseInt(value) > 0;
                        else
                            evaluation = !_.isNull(isNotNull) && _.trim(isNotNull.toString()).length > 0;

                        debugEvaluation = "[" + filterLabel + "]" + debugValue + " != null || [" + filterLabel + "]" + debugValue + ".Length() > 0";
                        break;
                    case constants.operators.isEmpty:

                        if (_collapsedField.field.Component.ComponentType === constants.forms.components.photo)
                            evaluation = parseInt(value) === 0;
                        else
                            evaluation = value != null || _.trim(value.toString()).length === 0;

                        debugEvaluation = "[" + filterLabel + "]" + debugValue + " == null || [" + filterLabel + "]" + debugValue + ".Length() == 0";
                        break;
                    case constants.operators.isNotEmpty:
                        if (_collapsedField.field.Component.ComponentType === constants.forms.components.photo)
                            evaluation = parseInt(value) > 0;
                        else
                            evaluation = value != null && _.trim(value.toString()).length > 0;

                        debugEvaluation = "[" + filterLabel + "]" + debugValue + " != null && [" + filterLabel + "]" + debugValue + ".Length() > 0";
                        break;
                    case constants.operators.isAnyOf:
                        // Not Implemented
                        break;
                    case constants.operators.isNoneOf:
                        // Not Implemented
                        break;
                    default:
                        break;
                }

                var result = forms.evaluateFilterBuilders({
                    filterBuilders: filter.filterBuilders,
                    collapsedField: _collapsedField,
                    collapsedObjects: collapsedObjects,
                    updateForm: updateForm
                });

                expression += evaluation.toString() + result.expression;
                debugExpression += debugEvaluation + result.debugExpression;
                debugFormulaExpression += debugFormulaEvaluation + result.debugFormulaExpression;
            });

            expression += ")";
            debugExpression += ")";
        });

        return {
            expression: _.trim(expression),
            debugExpression: _.trim(debugExpression),
            debugFormulaExpression: _.trim(debugFormulaExpression)
        };
    },
    evaluateFormulaBuilders: function ({value, label, formulaBuilders, collapsedObjects, updateForm}) {
        var evaluations = [];

        _.each(formulaBuilders, function (formulaBuilder)
        {
            var formulaResult = forms.evaluateFormulas({
                value: value,
                formulas: formulaBuilder.formulas,
                collapsedObjects: collapsedObjects,
                label: label,
                updateForm: updateForm
            });
            var formulaValue = formulaResult.value;
            var debugFormula = "";
            var filterLabel = _.isString(formulaBuilder.field) && formulaBuilder.field.length > 0
                ? _.filter(collapsedObjects, function (f) { return f.field.Id.toLowerCase() === formulaBuilder.field.toLowerCase(); })[0].field.Label
                : "Condition Value";

            if (!_.isNumber(formulaValue)) {
                label = filterLabel;
                value = formulaValue;

                if (filterLabel !== "Condition Value")
                    debugFormula += label + " = " + (!_.isString(formulaValue) || formulaValue.length === 0 ? "NULL" : formulaValue);
            }
            else {
                var result = null;
                var debugOptionsValue = value;
                var debugFormulaValue = formulaValue;
                var evaluateToNull = false;

                if (!_.isNumber(value)) {
                    debugOptionsValue = "NULL";
                    evaluateToNull = true;
                }

                if (!_.isNumber(formulaValue)) {
                    debugFormulaValue = "NULL";
                    evaluateToNull = true;
                }

                switch (formulaBuilder.operation) {
                    case constants.operations.none:
                        label = filterLabel;

                        if (_.isNumber(formulaValue))
                            result = parseFloat(formulaValue);
                        break;
                    case constants.operations.add:
                        if (!evaluateToNull)
                            result = parseFloat(value) + parseFloat(formulaValue);

                        debugFormula += "([" + label + "]" + debugOptionsValue + " + [" + filterLabel + "]" + debugFormulaValue + ") = " + result;
                        break;
                    case constants.operations.subtract:
                        if (!evaluateToNull)
                            result = parseFloat(value) - parseFloat(formulaValue);

                        debugFormula += "([" + label + "]" + debugOptionsValue + " - [" + filterLabel + "]" + debugFormulaValue + ") = " + result;
                        break;
                    case constants.operations.multiply:
                        if (!evaluateToNull)
                            result = parseFloat(value) * parseFloat(formulaValue);

                        debugFormula += "([" + label + "]" + debugOptionsValue + " * [" + filterLabel + "]" + debugFormulaValue + ") = " + result;
                        break;
                    case constants.operations.divide:
                        if (!evaluateToNull)
                            result = parseFloat(value) / parseFloat(formulaValue);

                        debugFormula += "([" + label + "]" + debugOptionsValue + " / [" + filterLabel + "]" + debugFormulaValue + ") = " + result;
                        break;
                    default:
                        console.log("Error: Formula builder error invalid operator");
                        result = null;
                        break;
                }

                value = result;
            }

            evaluations = _.union(evaluations, formulaResult.evaluations);

            if (debugFormula.length > 0)
                evaluations.push(debugFormula);
        });

        return {
            value: value,
            evaluations: evaluations
        };
    },
    evaluateFormulas: function ({value, formulas, collapsedObjects, label, updateForm}) {
        var evaluations = [];

        _.each(formulas, function (formula) {
            var formulaValue = null;
            var debugFormula = "";
            var field = _.isString(formula.field) && formula.field.length > 0
                ? _.filter(collapsedObjects, function (f) { return f.field.Id.toLowerCase() === formula.field.toLowerCase(); })[0].field
                : null;
            var filterLabel = null;

            switch (formula.type) {
                case constants.formulaTypes.field:
                    
                    if (_.isObject(field)) {
                        _.each(updateForm.UpdateSections, function (section) {
                            _.each(section.UpdateFields, function (updateField) {
                                if (updateField.FieldId.toLowerCase() === formula.field.toLowerCase()) {
                                    formulaValue = updateField.Value;
                                }
                            });
                        });

                        if (!_.isString(formulaValue))
                            formulaValue = field.Component.ComponentValue;

                        filterLabel = field.Label;
                    }

                    break;
                case constants.formulaTypes.specificValue:
                    formulaValue = formula.value;
                    filterLabel = "Condition Value";
                    break;
                default:
                    break;
            }
            
            if (!_.isNumber(formulaValue)) {
                label = filterLabel;
                value = formulaValue;

                if (_.isObject(field))
                    debugFormula += label + " = " + (!_.isString(formulaValue) || formulaValue.length === 0 ? "NULL" : formulaValue);
            }
            else {
                var result = null;
                var debugOptionsValue = value;
                var debugFormulaValue = formulaValue;
                var evaluateToNull = false;

                if (!_.isNumber(value)) {
                    debugOptionsValue = "NULL";
                    evaluateToNull = true;
                }

                if (!_.isNumber(formulaValue)) {
                    debugFormulaValue = "NULL";
                    evaluateToNull = true;
                }

                switch (formula.operation) {
                    case constants.operations.none:
                        label = filterLabel;

                        if (_.isNumber(formulaValue))
                            result = parseFloat(formulaValue);
                        break;
                    case constants.operations.add:
                        if (!evaluateToNull)
                            result = parseFloat(value) + parseFloat(formulaValue);

                        debugFormula += "([" + label + "]" + debugOptionsValue + " + [" + filterLabel + "]" + debugFormulaValue + ") = " + result;
                        break;
                    case constants.operations.subtract:
                        if (!evaluateToNull)
                            result = parseFloat(value) - parseFloat(formulaValue);

                        debugFormula += "([" + label + "]" + debugOptionsValue + " - [" + filterLabel + "]" + debugFormulaValue + ") = " + result;
                        break;
                    case constants.operations.multiply:
                        if (!evaluateToNull)
                            result = parseFloat(value) * parseFloat(formulaValue);

                        debugFormula += "([" + label + "]" + debugOptionsValue + " * [" + filterLabel + "]" + debugFormulaValue + ") = " + result;
                        break;
                    case constants.operations.divide:
                        if (!evaluateToNull)
                            result = parseFloat(value) / parseFloat(formulaValue);

                        debugFormula += "([" + label + "]" + debugOptionsValue + " / [" + filterLabel + "]" + debugFormulaValue + ") = " + result;
                        break;
                    default:
                        console.log("Error: Formula builder error invalid operator");
                        result = null;
                        break;
                }

                value = result;
            }

            var builderResult = forms.evaluateFormulaBuilders({
                value: value,
                formulaBuilders: formula.formulaBuilders,
                collapsedObjects: collapsedObjects,
                label: label,
                updateForm: updateForm
            });

            value = builderResult.value;
            evaluations = _.union(evaluations, builderResult.evaluations);

            if (debugFormula.length > 0)
                evaluations.push(debugFormula);
        });
        
        return {
            value: value,
            evaluations: evaluations
        };
    },
    performTabAction: function ({action, tab}) {
        switch (action.action) {
            case constants.forms.formConditions.actions.show:
                tab.hidden = false;
                break;
            case constants.forms.formConditions.actions.hide:
                tab.hidden = true;
                break;
            default:
                break;
        }
    },
    performSectionAction: function ({action, section}) {
        switch (action.action) {
            case constants.forms.formConditions.actions.show:
                section.hidden = false;
                break;
            case constants.forms.formConditions.actions.hide:
                section.hidden = true;
                break;
            default:
                break;
        }
    },
    performFieldAction: function ({action, field, collapsedObjects, conditionId}) {
        var validObject;

        //Hide/Show on tab/section is handled separately
        if ((action.action === constants.forms.formConditions.actions.show || action.action === constants.forms.formConditions.actions.hide) 
            && action.actionObject !== constants.forms.formConditions.actionObjects.field)
            return;

        switch (action.action) {
            case constants.forms.formConditions.actions.show:
                field.hidden = false;
                break;
            case constants.forms.formConditions.actions.hide:
                field.hidden = true;
                break;
            case constants.forms.formConditions.actions.required:
                _.find(collapsedObjects, function(collapsedObject){return collapsedObject.field.Id === field.Id }).required = true;
                field.Component.ComponentRequired = true;
                field.required = true;
                break;
            case constants.forms.formConditions.actions.notRequired:
                _.find(collapsedObjects, function(collapsedObject){return collapsedObject.field.Id === field.Id }).required = false;
                field.Component.ComponentRequired = false;    
                field.required = false;
                break;
            case constants.forms.formConditions.actions.enable:
                field.readOnly = false;
                field.Component.ComponentReadOnly = false;
                break;
            case constants.forms.formConditions.actions.disable:
                field.readOnly = true;
                field.Component.ComponentReadOnly = true;
                break;
            case constants.forms.formConditions.actions.style:
                field.styleOverride = {
                    Alignment: action.style.textAlign,
                    VerticalAlign: action.style.textVerticalAlign,
                    Bold: action.style.bold,
                    Italic: action.style.italic,
                    Underline: action.style.underline,
                    Strikeout: action.style.strikeout,
                    Font: action.style.font,
                    Size: action.style.fontSize,
                    Color: action.style.color != null ?  {Red: action.style.color.r, Green: action.style.color.g, Blue: action.style.color.b, Alpha: action.style.color.a*100} : {Red: 0, Green: 0, Blue: 0, Alpha: 0},
                    Fill: action.style.background != null ? {Red: action.style.background.r, Green: action.style.background.g, Blue: action.style.background.b, Alpha: action.style.background.a*100} : {Red: 0, Green: 0, Blue: 0, Alpha: 0},
                    Border: action.style.borderColor != null ? {Red: action.style.borderColor.r, Green: action.style.borderColor.g, Blue: action.style.borderColor.b, Alpha: action.style.borderColor.a*100} : {Red: 0, Green: 0, Blue: 0, Alpha: 0}
                };
                break;
            case constants.forms.formConditions.actions.defaultStyle:
                field.styleOverride = null;
                break;
            case constants.forms.formConditions.actions.valid:
                if (_.isNull(field.validObjectList) || _.isUndefined(field.validObjectList))
                    field.validObjectList = [];

                validObject = _.find(field.validObjectList, function(validObject){ return validObject.conditionId === conditionId });

                if (!_.isObject(validObject)) {

                    validObject = {
                        valid: true,
                        message: "",
                        behavior: constants.forms.componentRequiredBehaviors.invalid,
                        validated: true,
                        conditionId: conditionId
                    };

                    field.validObjectList.push(validObject);
                }

                validObject.valid = true;

                break;
            case constants.forms.formConditions.actions.invalid:
                if (_.isNull(field.validObjectList) || _.isUndefined(field.validObjectList))
                    field.validObjectList = [];
            
                validObject = _.find(field.validObjectList, function(validObject){ return validObject.conditionId === conditionId });

                if (!_.isObject(validObject)) {

                    validObject = {
                        valid: false,
                        message: "",
                        behavior: constants.forms.componentRequiredBehaviors.invalid,
                        validated: true,
                        conditionId: conditionId
                    };

                    field.validObjectList.push(validObject);
                }

                validObject.valid = false;
                validObject.behavior = constants.forms.componentRequiredBehaviors.invalid;
                validObject.message = !_.isString(action.message) || _.trim(action.message.toString()).length === 0 ? "" : action.message;

                break;
            case constants.forms.formConditions.actions.warning:
                if (_.isNull(field.validObjectList) || _.isUndefined(field.validObjectList))
                    field.validObjectList = [];

                validObject = _.find(field.validObjectList, function(validObject){ return validObject.conditionId === conditionId });

                if (!_.isObject(validObject)) {

                    validObject = {
                        valid: false,
                        message: "",
                        behavior: constants.forms.componentRequiredBehaviors.warning,
                        validated: true,
                        conditionId: conditionId
                    };

                    field.validObjectList.push(validObject);
                }

                validObject.valid = false;
                validObject.behavior = constants.forms.componentRequiredBehaviors.warning;
                validObject.message = !_.isString(action.message) || _.trim(action.message.toString()).length === 0 ? "" : action.message;

                break;
            case constants.forms.formConditions.actions.clearOnSave:
                field.clearOnSave = true;
                break;
            case constants.forms.formConditions.actions.doNotClearOnSave:
                field.clearOnSave = false;
                break;
            default:
                break;
        }
    },
    buildStyleObject: function (o) {
        var style = {};

        if (!_.isObject(o))
            return {};

        if (o.Size)
            style.fontSize = `${o.Size}pt`;

        if (o.Font)
            style.fontFamily = o.Font;

        if (o.Bold)
            style.fontWeight = o.Bold ? 'bold' : 'normal';

        if (o.Border)
            style.border = `1 px solid rgb(${o.Border.Red},${o.Border.Green},${o.Border.Blue})`;

        if (o.Fill)
            style.background = `rgb(${o.Fill.Red},${o.Fill.Green},${o.Fill.Blue})`;

        if (o.Color)
            style.color = `rgb(${o.Color.Red},${o.Color.Green},${o.Color.Blue})`;

        if (o.hasOwnProperty('Alignment'))
            switch (o.Alignment) {
                default:
                    style.textAlign = 'center';
                    break;
                case constants.textAlignment.left:
                    style.textAlign =  'left';
                    break;
                case constants.textAlignment.center:
                    style.textAlign =  'center';
                    break;
                case constants.textAlignment.right:
                    style.textAlign =  'right';
                    break;
            }

        if (o.hasOwnProperty('VerticalAlign'))
            switch (o.VerticalAlign) {
                default:
                    style.verticalAlign = 'middle';
                    break;
                case constants.textAlignment.top:
                    style.verticalAlign =  'left';
                    break;
                case constants.textAlignment.middle:
                    style.verticalAlign =  'middle';
                    break;
                case constants.textAlignment.bottom:
                    style.verticalAlign =  'bottom';
                    break;
            }

        return style;
    },
    convertStyleObject: function(styleObject) {
        var styleString = "";

        if (!_.isObject(styleObject))
            return {};

        if (styleObject.hasOwnProperty('Size'))
            styleString += `font-size:${styleObject.Size}pt;`

        if (styleObject.hasOwnProperty('Bold') && styleObject.Bold)
            styleString += 'font-weight:bold;'

        if (styleObject.hasOwnProperty('Italic') && styleObject.Italic)
            styleString += 'font-style:italic;'

        if (styleObject.hasOwnProperty('Font') && _.isString(styleObject.Font))
            styleString += `font-family:"${styleObject.Font}";`

        if (styleObject.hasOwnProperty('Underline') || styleObject.hasOwnProperty('Strikeout'))
            {
                if (styleObject.Underline && styleObject.Strikeout)
                    styleString += 'text-decoration:underline line-through;'
                else if (styleObject.Underline)
                    styleString += 'text-decoration:underline;'
                else if (styleObject.Strikeout)
                    styleString += 'text-decoration:line-through;'
            }

        if (styleObject.hasOwnProperty('Color') && _.isObject(styleObject.Color))
            styleString += `color:rgba(${styleObject.Color.Red},${styleObject.Color.Green},${styleObject.Color.Blue},${styleObject.Color.Alpha/100});`

        if (styleObject.hasOwnProperty('Border') && _.isObject(styleObject.Border))
            styleString +=  `border:rgba(${styleObject.Border.Red},${styleObject.Border.Green},${styleObject.Border.Blue},${styleObject.Border.Alpha/100});`;

        if (styleObject.hasOwnProperty('Fill') && _.isObject(styleObject.Fill))
            styleString += `background:rgba(${styleObject.Fill.Red},${styleObject.Fill.Green},${styleObject.Fill.Blue},${styleObject.Fill.Alpha/100});`;

        if (styleObject.hasOwnProperty('Alignment'))
            switch (styleObject.Alignment) {
                default:
                    styleString += 'text-align:center;';
                    break;
                case constants.textAlignment.left:
                    styleString += 'text-align:left;';
                    break;
                case constants.textAlignment.center:
                    styleString += 'text-align:center;';
                    break;
                case constants.textAlignment.right:
                    styleString += 'text-align:right;';
                    break;
            }

        if (styleObject.hasOwnProperty('VerticalAlign'))
            switch (styleObject.VerticalAlign) {
                default:
                    styleString += 'vertical-align:middle;';
                    break;
                case constants.textAlignment.top:
                    styleString += 'vertical-align:top;';
                    break;
                case constants.textAlignment.middle:
                    styleString += 'vertical-align:middle;';
                    break;
                case constants.textAlignment.bottom:
                    styleString += 'vertical-align:bottom;';
                    break;
            }
        

        return {style: `${styleString}`}
    },
    processClearOnSave: function ({form, updateForm}) {
        var updateFormClone = _.cloneDeep(updateForm);
        var fieldsToClear = [];
        
        _.forEach(form.Tabs, function(tab){
            _.forEach(tab.Pages, function(page){
                _.forEach(page.Sections, function(section){
                    _.forEach(section.Rows, function(row) {
                        _.forEach(row.Fields, function(field) {
                            if (field.clearOnSave) 
                                fieldsToClear.push(field.Id);
                        });
                    });
                });
            });
        });

        _.forEach(updateFormClone.UpdateSections, function(section){
            _.forEach(section.UpdateFields, function(field){
                if (fieldsToClear.includes(field.FieldId))
                    field.Value = null;
            });
        });

        return updateFormClone;
    }
}