// React imports
import { useEffect, useState, useCallback } from 'react';

// App imports
import { GeofenceJobRenderers } from "./geofenceJobRenderers";
import { GeofenceJobDelete } from "./geofenceJobDelete";
import { GeofenceJobEdit } from "./geofenceJobEdit";
import { ConfirmButton } from '../../../base/confirmButton/confirmButton';
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { helpers } from '../../../../utils/helpers';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { geofenceJobsHelpers } from './geofenceJobs';
import { successToast, errorToast } from '../../../base/toast/toast';

export function GeofenceJobDetail(props) {

    const jobInput = props.data.data;

    const [jobDetails, setJobDetails] = useState(null);
    const [activeAction, setActiveAction] = useState('');

    var getGeofenceJob = useCallback(
        () =>{ 

            legacyEndpoints.service({
                name: 'GetUberRetailJob',
                parameters: {
                    Id: jobInput.Id
                },
                success: function(results) {
                    setJobDetails(results);
                }
            });

        },
        [jobInput.Id]
    );

    useEffect(()=>{        
        getGeofenceJob();
    },[getGeofenceJob]);  

    const reprocessJob = ()=>{

        legacyEndpoints.service({
            name: 'ReprocessUberRetailJob',
            parameters: {
                Id: jobDetails.Id
            },
            success: function(results) {
                successToast(translate('success'));
                geofenceJobsHelpers.refreshGeofenceJobs();
            },
            error: function(e) {
                errorToast(e);
            }
        });
    };

    return <>
        <div className='app-geofence-job-details'>      
            <div className='app-geofence-job-details-description'>      
                {jobInput.Description == null || jobInput.Description.length == 0 ? translate('no_description') : jobInput.Description}
            </div>
            <div className='app-geofence-job-details-header'>      
                <div className='app-geofence-job-details-info'>      
                    <div className='app-geofence-job-details-row'>
                        <div className='app-geofence-job-details-label'>{translate('created_by')}:</div>
                        <div className='app-geofence-job-details-value'>{jobInput.UserName}</div>
                    </div>
                    { jobInput.PIPReportTypeName == null || jobInput.PIPReportTypeName.length == 0 ? '' :
                        <div className='app-geofence-job-details-row'>
                            <div className='app-geofence-job-details-label'>{translate('point_in_polygon_report')}:</div>
                            <div className='app-geofence-job-details-value'>{jobInput.PIPReportTypeName}</div>
                        </div>
                    }
                    { jobInput.DIDReportTypeName == null || jobInput.DIDReportTypeName.length == 0 ? '' :
                        <div className='app-geofence-job-details-row'>
                            <div className='app-geofence-job-details-label'>{translate('device_id_report')}:</div>
                            <div className='app-geofence-job-details-value'>{jobInput.DIDReportTypeName}</div>
                        </div>
                    }
                    { jobInput.P2PReportTypeName == null || jobInput.P2PReportTypeName.length == 0 ? '' :
                        <div className='app-geofence-job-details-row'>
                            <div className='app-geofence-job-details-label'>{translate('path_purchase_report')}:</div>
                            <div className='app-geofence-job-details-value'>{jobInput.P2PReportTypeName}</div>
                        </div>
                    }
                </div>
                <div className='app-geofences-job-details-grid-actions'>
                    <div className='app-geofences-grid-actions-buttons'>
                        <ConfirmButton className='app-geofences-job-details-button' theme='simple' icon={icons.refresh} tooltip={translate('reprocess')} 
                            disabled={!jobInput.AllowReprocess || jobInput.Status == 0 || jobInput.Status == 1} clearText={translate('reprocess')}
                            onConfirm={()=>{ reprocessJob(); }} />                                                  
                        <Button className='app-geofences-job-details-button' theme='simple' icon={icons.download} tooltip={translate('download')} 
                            disabled={!jobInput.AllowDataDownload || jobInput.FileId == null || jobInput.FileId.length == 0} 
                            onClick={()=>{ helpers.navigateToUrl(legacyEndpoints.handlers.getFileUrl({ id: jobInput.FileId })); }} />                                                  
                        <Button className='app-geofences-job-details-button' theme='simple' active={activeAction === translate('delete')} icon={icons.trash} 
                            tooltip={translate('delete')} disabled={!jobInput.AllowModify || jobInput.Status == 0 || jobInput.Status == 1} 
                            onClick={()=>{ activeAction === translate('delete') ? setActiveAction('') : setActiveAction(translate('delete')); }} />
                        <Button className='app-geofences-job-details-button' theme='simple' active={activeAction === translate('edit')} icon={icons.edit} 
                            tooltip={translate('edit')} disabled={!jobInput.AllowModify || jobInput.Status == 0 || jobInput.Status == 1} 
                            onClick={()=>{ activeAction === translate('edit') ? setActiveAction('') : setActiveAction(translate('edit')); }} />	                                    
                        <Button className='app-geofences-job-details-button' theme='simple' active={activeAction === translate('renderers')} icon={icons.drawCircle} 
                            tooltip={translate('renderers')} disabled={!jobInput.AllowDataRender || jobInput.Status == 0 || jobInput.Status == 1 || jobInput.Status == 4} 
                            onClick={()=>{ activeAction === translate('renderers') ? setActiveAction('') : setActiveAction(translate('renderers')); }} />	                                    
                    </div> 
                </div>
            </div>
            { jobInput.Error == null || jobInput.Error.length == 0 ? '' :
                <div className='app-geofence-job-details-error'>{jobInput.Error}</div>
            }
            { activeAction === translate('delete') ?
                    <GeofenceJobDelete jobId={jobDetails.Id} onClose={(o)=>{ setActiveAction(''); if (o.refresh) geofenceJobsHelpers.refreshGeofenceJobs(); }} />
                : ''
            }
            { activeAction === translate('edit') ?
                    <GeofenceJobEdit job={jobDetails} onClose={(o)=>{ setActiveAction(''); if (o.refresh) geofenceJobsHelpers.refreshGeofenceJobs(); }} />
                : ''
            }
            { activeAction === translate('renderers') ?
                    <GeofenceJobRenderers job={jobDetails} entityLayerId={jobInput.EntityLayerId} onClose={()=>{ setActiveAction(''); }} />
                : ''
            }
        </div>
    </>
}