// React imports
import { useState, useEffect, useCallback } from 'react';

// App imports
import { Loader } from '../loader/loader';
import { ColorSwatches } from '../colorSwatches/colorSwatches';
import { ImageDropDown } from '../dropDown/imageDropDown';
import { DropDown } from '../dropDown/dropDown';
import { TextBox } from '../textBox/textBox';
import { helpers } from '../../../utils/helpers';
import { translate } from '../../../utils/translation';
import { constants } from '../../../utils/constants';
import { legacyEndpoints } from "../../../services/legacyEndpoints";

const _ = require("lodash");

export function SymbolManager({symbol, disabled, onChange}) {

    const textBoxHeight = '55px';

    if (!symbol) symbol = "images/symbols/20x20/cir_l-grn_20x20.png";

    const [symbolPath, setSymbolPath] = useState(symbol);
    const [symbolUrl, setSymbolUrl] = useState(null);
    const [symbolCollectionLoaded, setSymbolCollectionLoaded] = useState(false);
    const [shapes, setShapes] = useState([]);
    const [shape, setShape] = useState(null);
    //const [shapeGroups, setShapeGroups] = useState([]);
    const [colors, setColors] = useState([]);
    const [color, setColor] = useState(null);
    const [sizes, setSizes] = useState([]);
    const [size, setSize] = useState(null);
    const [attributes, setAttributes] = useState([]);
    const [attribute, setAttribute] = useState(null);
    const [hex, setHex] = useState(null);

    const buildSymbolPath = (o)=>{
        var attr = "";
        if (o.attribute && o.attribute !== 'n')
            attr = o.attribute + "_";;

        return ("images/symbols/" + o.size.value + "/" + attr + o.shape.value + "_" + o.color.value + "_" + o.size.value + ".png").trim();
    };

    const getSymbolProperties = useCallback(
        (path)=>{
            var result = { attribute: null, shape: null, color: null, size: null, name: null, type: null, height: null };

            try {
                var temp = path.split("/").pop();
                var myIndex = 0;

                temp = temp.replace(".png", "");
                temp = temp.split("_");

                result.attribute = "";

                if (temp.length === 4) {
                    result.attribute = temp[myIndex];
                    myIndex++;
                }

                if (temp.length >= 3) {
                    result.shape = _.find(shapes, function (shape) { return shape.value === temp[myIndex] });
                    myIndex++;

                    result.color = _.find(colors, function (color) { return color.value === temp[myIndex] });
                    myIndex++;

                    result.size = _.find(sizes, function (size) { return size.value === temp[myIndex] });
                    myIndex++;
                }

                result.type = constants.symbols.types.stock;
                result.height = parseInt(result.size.value.split("x").slice(0,1));
                result.name = buildSymbolPath(result);
            }
            catch (e) {
                result = {
                    attribute: "s",
                    shape: { value: "x" },
                    color: { value: "blk" },
                    size: { value: "24x24" },
                    type: constants.symbols.types.stock,
                    height: 24
                };

                result.name = buildSymbolPath(result);
            }

            return result;
        },
        [shapes, colors, sizes]
    );

    const updateShapePreview = (o)=>{
        if (o.shape && o.color && o.size) {

            var result = {
                attribute: o.attribute,
                shape: { value: o.shape },
                color: { value: o.color },
                size: { value: o.size },
                type: null, 
                height: null
            };

            var path = buildSymbolPath(result);
            setSymbolPath(path);

            if (onChange) onChange(path);
        }
    };

    const syncColorChange = (o)=>{
        // the hex color textbox is disabled for now because we cannot use custom colors with our predefined symbols
        if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(o.value) || o.value === "") {
            setHex(o.value);

            var col = colors.find(c => c.hex === o.value.toLowerCase());
            if (col !== undefined) {
                updateShapePreview({ size: size, shape: shape, color: col.value, attribute: attribute });
            }
        }
    };

    useEffect(() => {

        if (shapes?.length > 0)
            return;
            
        legacyEndpoints.service({
            name: 'GetSymbolManagerCollection',
            success: function(r) {
                setShapes(r.SymbolManagerShapeList.map(shape => ( { value: shape.Value, group: shape.GroupId, image: legacyEndpoints.handlers.getSymbolUrl({ imageUrl: "images/symbols/" + shape.SampleImage }) } )));

                //setShapeGroups(r.SymbolManagerShapeGroups.map(shapeGroup => ( { value: shapeGroup.Key, text: shapeGroup.Value } )));

                setColors(r.SymbolManagerColorList.map(color => {
                        if (color.R === -1 && color.G === -1 && color.B === -1) {
                            return { value: color.Value, name: color.Name, rgb: "rgba(0,0,0,0.0)", hex: helpers.rgbToHex(0,0,0).toLowerCase() + '00' };
                        } else {
                            return { value: color.Value, name: color.Name, rgb: "rgb(" + color.R + ", " + color.G + ", " + color.B + ")", hex: helpers.rgbToHex(color.R, color.G, color.B).toLowerCase() };
                        }
                    })
                );

                setSizes(r.SymbolManagerSizeList.map(size => ( { value: size.Value, text: size.Name } )));

                setAttributes([...r.SymbolManagerAttributeList.map(attr => ( { id: attr.Id, value: attr.Value, name: attr.Name } )), { id: 3, name: translate('none'), value: 'n' } ]);
                
                setSymbolCollectionLoaded(true);
            }
        });
    
    }, [shapes?.length]);

    useEffect(() => {

        if (shapes?.length === 0)
            return;
        setSymbolUrl(legacyEndpoints.handlers.getSymbolUrl({ imageUrl: symbolPath }));

        var props = getSymbolProperties(symbolPath);
        setShape(props.shape.value);
        setColor(props.color.value);
        setHex(props.color.hex);
        setSize(props.size.value);
        setAttribute(props.attribute ? props.attribute : 'n');

    }, [symbolPath, shapes?.length, getSymbolProperties]);

    return <>
        <Loader className='app-card-body-loader' loaded={symbolCollectionLoaded} />
        {!symbolCollectionLoaded ? '' : 
            <div className='app-symbol-manager'>
                <div className='app-symbol-manager-pickers app-symbol-manager-pickers-first'>
                    <ImageDropDown className='app-symbol-manager-inputs' items={shapes} label={translate('shape')} height={textBoxHeight} display='value' valueProperty='value' imageSrc='image'
                        selected={shape} disabled={disabled} onChange={(o) => { updateShapePreview({ size: size, shape: o.value, color: color, attribute: attribute }); }} 
                    />
                    <DropDown className='app-symbol-manager-inputs' items={attributes} label={translate('style')} height={textBoxHeight} display='name' valueProperty='value'
                        selected={attribute} disabled={disabled} onChange={(o) => { updateShapePreview({ size: size, shape: shape, color: color, attribute: o.value }); }} 
                    />
                </div>
                <div className='app-symbol-manager-pickers'>
                    <DropDown className='app-symbol-manager-inputs' items={sizes} label={translate('size')} height={textBoxHeight} display='text' valueProperty='value'
                        selected={size} disabled={disabled} onChange={(o) => { updateShapePreview({ size: o.value, shape: shape, color: color, attribute: attribute }); }} 
                    />
                    <TextBox className='app-symbol-manager-inputs' label={translate('color')} value={hex} height={textBoxHeight} 
                        onChange={(o) => { syncColorChange(o); }} disabled={true}
                    />
                </div>
                <div className='app-symbol-manager-pickers app-symbol-manager-pickers-last'>
                    <div className='app-symbol-manager-label'>{translate('shape_preview')}</div>
                    <div className='app-symbol-preview'>
                        <img src={symbolUrl} alt={translate('shape_preview')} />
                    </div>
                </div>
                <div className='app-symbol-manager-swatches'>
                    <ColorSwatches colors={colors} selected={color} onClick={(o) => { updateShapePreview({ size: size, shape: shape, color: o.value, attribute: attribute }); }} />
                </div>
            </div>
        }
    </>
}
