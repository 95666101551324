// React imports
import { useState, useEffect, useRef, useCallback } from 'react';

// App imports
import { Hideable } from '../../base/hideable/hideable';
import { Comment } from './comment/comment';
import { TextArea } from '../../base/textArea/textArea';
import { Button } from '../../base/button/button';
import { Loader }  from '../../base/loader/loader'
import { icons } from '../../base/icon/icon';
import { legacyEndpoints } from "../../../services/legacyEndpoints";
import { translate } from '../../../utils/translation';

export function Comments({entity, hide}) {

    const [loaded, setLoaded] = useState(false);
    const [comments, setComments] = useState([]);
    const [commentText, setCommentText] = useState('');
    const [replyCommentId, setReplyCommentId] = useState(null);
    const [replyText, setReplyText] = useState('');
    const [editCommentId, setEditCommentId] = useState(null);
    const [editSubCommentId, setEditSubCommentId] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [commentPlaceholder, setCommentPlaceholder] = useState(translate('new_comment_prompt'));
    
    const textAreaRef = useRef(null);

    const getComments = useCallback(
        (o)=>{
            setLoaded(false);

            legacyEndpoints.service({
                name: 'GetCommentList',
                parameters: {
                    aServiceID: entity.layer.id,
                    aPointID:  entity.id
                },
                success: function(r) {
                    if (r === null)
                        setComments([]);
                    else {
                        r.reverse();
                        setComments([...r]);
                    }
                    
                    setLoaded(true);
                }
            });
        },
        [entity]
    );

    useEffect(() =>{
        getComments();
    }, [getComments]);

    useEffect(() =>{

        if (replyText.length > 0)
            setCommentPlaceholder(translate('reply_comment_prompt'));
        else 
            setCommentPlaceholder(translate('new_comment_prompt'));

    }, [replyText]);

    const saveComment = ()=>{
        var message = commentText.trim();
        if (message.length === 0) {
            setCommentPlaceholder(translate('new_comment_prompt_continue'));
            return;
        }

        setProcessing(true);

        replyCommentId == null ?
            legacyEndpoints.service({
                name: 'AddCommentWithAdditionalData',
                parameters: {
                    aServiceID: entity.layer.id,
                    aPointID: entity.id,
                    aComment: message
                },
                success: function(r) {
                    setComments([ ...comments, r ]);
                    setCommentText('');
                    setProcessing(false);
                    setCommentPlaceholder(translate('new_comment_prompt'));
                },
                error: function(e) {
                    setProcessing(false);
                }
            })
        :
            legacyEndpoints.service({
                name: 'AddSubCommentWithAdditionalData',
                parameters: {
                    CommentId: replyCommentId,
                    Comment: message
                },
                success: function(r) {
                    const newComments = comments.map(newComment => {
                        if (newComment.ID !== replyCommentId)
                            return newComment;
                        else
                            return {...newComment, SubComments: [ ...newComment.SubComments, r ] };
                    });

                    setComments(newComments);
                    setCommentText('');
                    setReplyCommentId(null);
                    setReplyText('');
                    setProcessing(false);
                },
                error: function(e) {
                    setProcessing(false);
                }
            })
    }

	return <Hideable className='app-comments' hide={hide}>
        <Loader loaded={loaded} />
        {
            comments.length === 0 ? <div className='app-comments-empty'>{translate('no_comments')}</div> :
            <div className='app-comments-body'>
                <div className='app-comments-container'>
                    {
                        comments.map((comment, i) => {
                            return (
                                <Comment key={comment.ID} comment={comment} isSubComment={false} editSubCommentId={editSubCommentId} 
                                    replyActive={replyCommentId === comment.ID} editActive={editCommentId === comment.ID} 
                                    onReply={()=>{ 
                                        setEditCommentId(null);
                                        setEditSubCommentId(null);
                                        setCommentText('');

                                        if (replyCommentId === comment.ID) {
                                            setReplyCommentId(null);
                                            setReplyText('');
                                        }
                                        else {
                                            setReplyCommentId(comment.ID); 
                                            setReplyText(`${translate('replying_to')} ${comment.UserName}`);
                                            textAreaRef.current.instance.focus();
                                        }
                                    }} 
                                    onEdit={()=>{ 
                                        setEditSubCommentId(null);
                                        setReplyCommentId(null);
                                        setReplyText('');

                                        if (editCommentId === comment.ID) {
                                            setEditCommentId(null);
                                        }
                                        else {
                                            setEditCommentId(comment.ID); 
                                        }
                                    }}
                                    onEditSubComment={(o)=>{
                                        setEditCommentId(null);
                                        setReplyCommentId(null);
                                        setReplyText('');

                                        if (editSubCommentId === o.ID) {
                                            setEditSubCommentId(null);
                                        }
                                        else {
                                            setEditSubCommentId(o.ID);
                                        }
                                    }}
                                    onEditSave={(o)=>{
                                        const newComments = comments.map(newComment => {
                                            if (newComment.ID !== comment.ID)
                                                return newComment;
                                            else
                                                return {...newComment, Comment: o.comment, Date: o.date };
                                        });

                                        setComments(newComments);
                                        setEditCommentId(null);
                                        setEditSubCommentId(null);
                                    }}
                                    onEditSaveSubComment={(o)=>{
                                        const newComments = comments.map(newComment => {
                                            if (newComment.ID !== comment.ID)
                                                return newComment;
                                            else
                                                return {...newComment, SubComments: o.SubComments };
                                        });

                                        setComments(newComments);
                                        setEditCommentId(null);
                                        setEditSubCommentId(null);
                                    }}
                                    onDelete={()=>{
                                        setEditCommentId(null);
                                        setEditSubCommentId(null);
                                        setCommentText('');
                                        setReplyCommentId(null);
                                        setReplyText('');

                                        setComments(comments.filter(c => c.ID !== comment.ID));
                                    }}
                                    onDeleteSubComment={(o)=>{
                                        setEditCommentId(null);
                                        setEditSubCommentId(null);
                                        setCommentText('');
                                        setReplyCommentId(null);
                                        setReplyText('');

                                        const newComments = comments.map(newComment => {
                                            if (newComment.ID !== comment.ID)
                                                return newComment;
                                            else
                                                return {...newComment, SubComments: o.SubComments };
                                        });

                                        setComments(newComments);
                                    }}
                                />
                            );
                        })                    
                    }
                </div>
            </div>  
        }
        <div className='app-comments-footer'>
            <div className='app-comments-new'>
                <TextArea className='app-comments-new-input' label={replyText} value={commentText} minHeight={39} maxHeight={200} placeholder={commentPlaceholder} 
                    valueChangeEvent={'change keyup'} textAreaRef={textAreaRef}
                    onChange={(o) => { 
                        setCommentText(o.value); 
                    }} 
                    onEnter={() => { 
                        saveComment(); 
                    }}
                />
                <Button className='app-comments-new-button' theme='primary' size='small' icon={icons.send} tooltip={translate('save')} disabled={processing} 
                    onClick={()=>{ 
                        saveComment(); 
                    }} 
                />
            </div>        
        </div>
    </Hideable>			
}
