import { Fragment } from "react";

const _ = require("lodash");

export function Description({className, description, bullets, align}) {

    var classes = ['app-description'];

    if (className)
        classes.push(className);

    switch(align)
    {
        default:
            break;
        case 'left':
            classes.push('app-description-left');
            break;
        case 'right':
            classes.push('app-description-right');
            break;
    }

    const displayBullets = (bullets) => {
        return (
            <ul>
                {
                    bullets.map((bullet) => {
                        return <Fragment key={bullet.id}>
                            { _.isArray(bullet.bullets) ? 
                                displayBullets(bullet.bullets)
                            : 
                                <li key={bullet.id} >
                                    <span>{bullet.text}</span>
                                </li>
                            }
                        </Fragment>
                    })
                }
            </ul>
        );
    };

    return <div className={classes.join(' ')} >
        {description}
        { _.isArray(bullets) ? displayBullets(bullets) : '' }
    </div>
}