const _ = require("lodash");

export class GoogleImageOverlay {

    constructor(o) {

        o.map._private._imageOverlays.push(this);

        this._map = o.map;
        this._googleMap = o.googleMap;
        this._overlay = null;
        this._visible = o.visible;
        this._zIndex = o.zIndex;

        this.refresh = async () =>{ 
                    
            if (this._overlay !== null)
                this._overlay.setMap(null);

            if (this._visible === false)
                return;

            this._overlay = new window.google.maps.GroundOverlay(
                await o.imageUrl({
                    bounds: this._map.bounds,
                    zoom: this._map.zoom,
                    layer: o.layer
                }),
                {
                    north: this._map.bounds.northWest.lat,
                    south: this._map.bounds.southEast.lat,
                    east: this._map.bounds.southEast.lon,
                    west: this._map.bounds.northWest.lon,
                },
                {
                    map: this._googleMap,
                    clickable: false
                }
            );
        };

        this.refresh();
         
        this._map._private._imageOverlays = _.sortBy(this._map._private._imageOverlays, '_zIndex');    
    }

    dispose() {

        if (this._overlay === null)
            return;

        this._overlay.setMap(null);
        this._overlay = null;

        this._map._private._imageOverlays = this._map._private._imageOverlays.filter(overlay => overlay != this);
    }

    setVisible(value){

        if (this._visible === value)
            return;

        this._visible = value;
            
        this._map._private._imageOverlays.forEach(layer => layer.refresh());
    }

    setZIndex(value){

        if (this._zIndex === value)
            return;

        this._zIndex = value;

        this._map._private._imageOverlays = _.sortBy(this._map._private._imageOverlays, '_zIndex');        
        this._map._private._imageOverlays.forEach(layer => layer.refresh());
    }
};