// App imports
import { ExcelUploader } from '../excelUploader'
import { Bar } from '../../../base/bar/bar';
import { Button } from '../../../base/button/button';
import { SelectableItems } from '../../../base/selectableItems/selectableItems';
import { CollapsibleSection } from '../../../base/collapsibleSection/collapsibleSection';
import { OptionSelector } from '../../../base/optionSelector/optionSelector';
import { Icon, icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { helpers } from "../../../../utils/helpers";
import { constants } from '../../../../utils/constants';

export function DefineDataSource({sourceLabel, excelFile, updateExcelFile, dataSources, updateDataSources, updateDataSource, sourceFormat, updateSourceFormat}) {

    const formats = [
        { icon: icons.excel, format: constants.bulkReports.dataSources.excel, text: translate('excel_document') },
        { icon: icons.database, format: constants.bulkReports.dataSources.datasource, text: translate('data_source') }
    ];

    const selectDataSource = (o) =>{
        updateDataSource(o.selected ? o.item.id : null);

        const newDataSources = dataSources.map(newSource => ({
            ...newSource,
            sources: newSource.sources.map(src => {
                    if (src.id !== o.item.id)
                        return {...src, selected: false };
                    else
                        return {...src, selected: o.selected };
                })
            }
        ));

        updateDataSources(newDataSources);        
    };

    return <>
        <div className='app-tools-bulk-labels'>{sourceLabel}</div>
        <div className='app-tools-bulk-rows'>
        {
            formats.map((format, i) => {
                return (
                    <div key={i} className='app-option-selector'>
                        {sourceFormat === format.format ? 
                        <div className='app-option-selector-check'>
                            <Icon icon={icons.check} />
                        </div> : ''}
                        <Bar className='app-tools-bulk-bar-buttons app-tools-bulk-bar-short'
                            icon={format.icon}
                            text={format.text} 
                            active={sourceFormat === format.format} 
                            onClick={() => { updateSourceFormat(sourceFormat === format.format ? null : format.format); }} 
                        /> 
                    </div>
                )
            })
        }
        </div>
        <div className='app-tools-bulk-download_button'>
            <Button theme='simple' icon={icons.download} tooltip={translate('download_template')} 
                onClick={()=>{ helpers.navigateToUrl('https://tasonline.com/Files/BulkProcessing_Demographics_Template.xls'); }} 
            />                                                  
        </div>
        <div className='app-tools-bulk-columns app-tools-bulk-container-top'>
            { sourceFormat !== constants.bulkReports.dataSources.excel ? '' :
                <ExcelUploader excelFile={excelFile} updateExcelFile={(value) =>{ updateExcelFile(value); }} />
            }
            { sourceFormat !== constants.bulkReports.dataSources.datasource ? '' :
                <SelectableItems
                    items={dataSources}
                    selectedItems={dataSources.map((item) => { return item.sources.filter(x => x.selected) }).filter(item => item.length > 0).flat()}
                    onSelect={(o) => { updateDataSources([...o.items]); }}
                    onRender={(o) => {
                        return <div className='app-tools-bulk-selectable-items'>
                        {
                            o.items.map((group, i) => {
                                return <CollapsibleSection key={i} text={group.text} count={group.sources.length} expanded={false} selectedCount={group.sources.filter(x => x.selected).length}>
                                {
                                    group.sources.map((item, j) =>{
                                        return <OptionSelector key={j} text={item.text} icon={icons.shoppingBag} active={item.selected} onClick={() =>{ 
                                            selectDataSource({ item: item, selected: !item.selected }); 
                                        }}/>
                                    })
                                }
                                </CollapsibleSection>
                            })
                        }
                        </div>
                    }}
                    hideFooter={true}
                />
            }
        </div>
    </>
}