// React imports
import { useState, useEffect } from 'react';

// App imports
import { Loader } from '../../../base/loader/loader';
import { DropDown } from '../../../base/dropDown/dropDown';
import { Button }  from '../../../base/button/button';
import { Description }  from '../../../base/description/description';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { userPreferences } from '../../app';
import { successToast } from '../../../base/toast/toast';

export function UserSearch() {

    const textBoxHeight = '55px';

    const [geographySearchesLoaded, setGeographySearchesLoaded] = useState(false);
    const [geographySearches, setGeographySearches] = useState([]);		
    const [geographySearch, setGeographySearch] = useState(userPreferences.GeographySearchID);		
    const [querySearchesLoaded, setQuerySearchesLoaded] = useState(false);
    const [querySearches, setQuerySearches] = useState([]);		
    const [querySearch, setQuerySearch] = useState(userPreferences.QuerySearchID);		
    const [searchType, setSearchType] = useState(userPreferences.DefaultSearchType);		

    var searchTypes = [
        { Type: translate('address'), Id: 'B'},
        { Type: translate('custom_query'), Id: 'C'},
        { Type: translate('geography'), Id: 'G'}
    ]

    useEffect(() => {

        if (geographySearches.length > 0)
            return;

        legacyEndpoints.service({
            name: 'GetAvailableGeographySearches',
            success: function(r) {
                var data = r.map(d => ({ ...d, Display: d.Category + " - " + d.Name }))
                setGeographySearches(data);
                setGeographySearchesLoaded(true);
            }
        });

    }, [geographySearches.length]);

    useEffect(() => {

        if (querySearches.length > 0)
            return;
    
        legacyEndpoints.service({
            name: 'GetAvailableQuerySearches',
            success: function(r) {
                setQuerySearches(r);
                setQuerySearchesLoaded(true);
            }
        });

    }, [querySearches.length]);

    return <>
        <Loader className='app-card-body-loader' loaded={geographySearchesLoaded && querySearchesLoaded} />
        {!geographySearchesLoaded && !querySearchesLoaded ? '' : 
            <div className='app-user-preferences-card'>
                <DropDown className='app-user-preferences-inputs' items={searchTypes} label={translate('default_search_type')} height={textBoxHeight} display='Type' valueProperty='Id' 
                    selected={searchType} disabled={!userPreferences.AllowSetDefaultSearchType} onChange={(o) => { setSearchType(o.value); }} 
                />
                <DropDown className='app-user-preferences-inputs' items={geographySearches} label={translate('geography_search')} height={textBoxHeight} display='Display' valueProperty='Id' 
                    selected={geographySearch} disabled={!userPreferences.AllowSetGeographySearchID} onChange={(o) => { setGeographySearch(o.value); }} 
                />
                <DropDown className='app-user-preferences-inputs' items={querySearches} label={translate('custom_query_search')} height={textBoxHeight} display='Name' valueProperty='Id' 
                    selected={querySearch} disabled={!userPreferences.AllowSetQuerySearchID} onChange={(o) => { setQuerySearch(o.value); }} 
                />
                <div className='app-user-preferences-save'>
                    <Button theme='tertiary' size='medium' icon={icons.check} tooltip={translate('save')} 
                        disabled={!userPreferences.AllowSetDefaultSearchType && !userPreferences.AllowSetGeographySearchID && !userPreferences.AllowSetQuerySearchID} onClick={()=>{ 
                        legacyEndpoints.service({
                            name: 'SetGeneralPrefs',
                            parameters: {
                                DefaultSearchType: searchType,
                                DefaultGeogSearch: geographySearch,
                                DefaultQuerySearch: querySearch
                            },
                            success: function(r) {
                                userPreferences.DefaultSearchType = searchType;
                                userPreferences.GeographySearchID = geographySearch;
                                userPreferences.QuerySearchID = querySearch;
                                successToast(translate('success'));
                            }
                        });
                    }} />
                </div>
                <Description description={translate('default_search_tas1')} bullets={[ 
                        {id: 0, text: translate('default_search_tas2')}, 
                        {id: 1, text: translate('default_search_tas3')},
                        {id: 2, text: translate('default_search_tas4')} 
                    ]} 
                />
            </div>
        }
    </>
}