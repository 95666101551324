// App imports
import { constants } from '../../utils/constants';
import { map } from '../../components/app/map/map';

var _ = require("lodash");

export const drivingDirections = {

    getDirections: (props) => {
      
        var directionsService = new window.google.maps.DirectionsService();
        var startingPoint;
        var endingPoint;
        var waypoints = [];
        var directionsRenderer;

        var refresh = () =>{
          props.onRefresh({clear: true});
        };

        var layer = map.layers.find((layer) => layer.type === constants.layers.types.directions);

        if (layer === undefined) {
          var newLayer = map.addLayer({
              id: crypto.randomUUID(),
              type: constants.layers.types.directions,
              onChange: ()=>{
                refresh();
              }
          });   
          directionsRenderer = newLayer._private._layer.renderer;    
        } else {
          directionsRenderer = layer._private._layer.renderer;
        }

        props.destinations.forEach((destination, i) => {
          if (i === 0)
            startingPoint = destination.value
          else if (i === props.destinations.length - 1)
            endingPoint = destination.value
          else
            waypoints.push({location: destination.value});          
        });     
        
        if (props.directionSettings.roundTrip) {
          waypoints.push({location: endingPoint});
          endingPoint = startingPoint;
        }        

        var directionsRequest = {
            origin: startingPoint,
            destination: endingPoint,
            waypoints: waypoints,
            provideRouteAlternatives: true,
            travelMode: props.travelMode ? props.travelMode : constants.drivingDirections.travelModes.driving,            
            avoidHighways: props.directionSettings?.avoidHighways,
            avoidTolls: props.directionSettings?.avoidTollRoads,
            unitSystem: props.directionSettings?.units === 1 ? window.google.maps.UnitSystem.IMPERIAL : window.google.maps.UnitSystem.METRIC,
            drivingOptions: {
              departureTime: new Date(),
              trafficModel: 'pessimistic'
            },
          }

          directionsService.route(directionsRequest, function(result, status) {
                if (status === 'OK') {
                  directionsRenderer.setMap(null);

                  directionsRenderer.setOptions({
                    draggable: true,
                    suppressMarkers: false,
                    preserveViewport: _.isBoolean(props.zoomToLayer) ? !props.zoomToLayer : false,
                    polylineOptions: getPolyline(props.travelMode),
                    suppressBicyclingLayer: true
                  });

                    directionsRenderer.setDirections(result);
                    directionsRenderer.setMap(props.map);  
                    directionsRenderer.setPanel(props.panel);  
                    props.onRefresh({success: true});                                        
                } else {
                  props.onRefresh({success: false}); 
                  const message = 'One or more locations specified are invalid or require more information.'
                  alert(message);  //todo add error handling
                }
          });
    }
};

function getPolyline(travelMode) {
  var polyLine = null;

    if (travelMode === constants.drivingDirections.travelModes.walking ) {
      polyLine =  {
        strokeColor: '#00A5D3',
        strokeOpacity: 0,
        strokeWeight: 5,
        fillOpacity: 0,
        clickable: true,
        icons: [{
          icon: {
                  path: window.google.maps.SymbolPath.CIRCLE,
                  fillOpacity: 1,
                  scale: 3
          },
          offset: '0',
          repeat: '10px'
        }],            
      }
    } 
    return polyLine;
}