// React imports
import { useState } from 'react';

// App imports
import { Button } from '../../../base/button/button';
import { TextBox } from '../../../base/textBox/textBox';
import { TextArea } from '../../../base/textArea/textArea';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { successToast, errorToast } from '../../../base/toast/toast';

export function GeofenceJobEdit({job, onClose}) {

    const [name, setName] = useState(job.Name);
    const [description, setDescription] = useState(job.Description);

	const saveJob = ()=>{

        legacyEndpoints.service({
            name: 'UpdateUberRetailJobInformation',
            parameters: {
                Id: job.Id,
                Name: name.trim(),
                Description: description.trim()
            },
            success: function(results) {
                successToast(translate('success'));
                onClose({ refresh: true });
            },
            error: function(e) {
                errorToast(e);
                onClose({ refresh: false });
            }
        });
        
    };		

    return <>
        <div className='app-geofence-job-details-action-container'>
            <div className='app-geofence-job-details-row'>      
                <div className='app-geofence-job-details-action-row'>      
                    <TextBox label={translate('name')} value={name} height={'36px'} onChange={(o) => { setName(o.value); }} />
                </div>
            </div>
            <div className='app-geofence-job-details-row'>      
                <div className='app-geofence-job-details-action-row'>      
                    <TextArea label={translate('description')} value={description} minHeight={40} maxHeight={200} valueChangeEvent={'change keyup'} onChange={(o) => { setDescription(o.value); }} /> 
                </div>
            </div>
            <div className='app-geofence-job-details-btn-row'>      
                <Button className='app-selectable-items-close-button' theme='secondary' size='small' icon={icons.x} tooltip={translate('cancel')} onClick={() => { onClose({ refresh: false }); }} />
                <Button theme='primary' size='small' icon={icons.check} tooltip={translate('save')} disabled={name.trim().length === 0 || description.trim().length === 0} onClick={()=>{ saveJob(); }} />
            </div>
        </div>
    </>
}
