import { Entity } from './entity';
import { zIndexes } from '../utils/zIndex';
import { constants } from '../utils/constants';
import { ActionListener } from './actionListener';

const _ = require("lodash");

export class Layer extends ActionListener {

    constructor(map, o) {
        super(o);
        
        o = _.isObject(o) ? o : {};

        this._private = {
            _actions: _.isArray(o.actions) ? o.actions : [],
            _active: _.isBoolean(o.active) ? o.active : true,
            _entities: [],
            _group: _.isInteger(o.group) ? o.group : null,
            _id: _.isNull(o.id) || _.isUndefined(o.id) ? crypto.randomUUID() : o.id,
            _labeled: _.isBoolean(o.labeled) ? o.labeled : false,
            _labels: [],
            _layer: {},            
            _legend: _.isNull(o.legend) || _.isUndefined(o.legend) ? [] : o.legend,
            _loading: _.isBoolean(o.loading) ? o.loading : false,
            _metaData: _.isObject(o.metaData) ? o.metaData : {},
            _secondaryMetaData: _.isObject(o.secondaryMetaData) ? o.secondaryMetaData : {},
            _map: map,
            _oppositeLabeledEntities: _.isNull(o.oppositeLabeledEntities) || _.isUndefined(o.oppositeLabeledEntities) ? [] : o.oppositeLabeledEntities,
            _oppositeVisibilityEntities: _.isNull(o.oppositeVisibilityEntities) || _.isUndefined(o.oppositeVisibilityEntities) ? [] : o.oppositeVisibilityEntities,
            _secondaryOppositeVisibilityEntities: _.isNull(o.secondaryOppositeVisibilityEntities) || _.isUndefined(o.secondaryOppositeVisibilityEntities) ? [] : o.secondaryOppositeVisibilityEntities,
            _outOfRange: _.isBoolean(o.outOfRange) ? o.outOfRange : false,
            _parentId: _.isNull(o.parentId) || _.isUndefined(o.parentId) ? null : o.parentId,
            _subText: _.isNull(o.subText) || _.isUndefined(o.subText) ? null : o.subText,
            _subType: _.isInteger(o.subType) ? o.subType : null,
            _tradeAreas: _.isArray(o.tradeAreas) ? o.tradeAreas : [],
            _text: _.isNull(o.text) || _.isUndefined(o.text) ? '' : o.text,
            _tileUrl: _.isFunction(o.tileUrl) ? o.tileUrl : null,
            _imageUrl: _.isFunction(o.imageUrl) ? o.imageUrl : null,
            _toolbars: _.isNull(o.toolbars) || _.isUndefined(o.toolbars) ? [] : o.toolbars,
            _type: o.type,
            _visible: _.isBoolean(o.visible) ? o.visible : true,
            _zIndex: zIndexes({ type: o.type, subType: o.subType }),            
            _errors: {
                _readOnly: (o) =>{
                    throw new Error('Layer "' + o.oproperty + '" property is read only and cannot be set to "' + o.value + '".');
                },
                _invalidTypeProperty: (o) => {
                    if (!_.includes(o.validTypes, this._private._type))
                        throw new Error('Layer "' + o.property + '" property is invalid ' + (_.isNull(o.value) || _.isUndefined(o.value) ? '.' : ' and cannot be changed to "' + o.value + '".'));
                }
            },
            _onChange: _.isFunction(o.onChange) ? o.onChange : () => {},
            _data: _.isObject(o.data) ? o.data : null,
            _cluster: _.isBoolean(o.cluster) ? o.cluster : false
        };

        this._private._map._private._layers.push(this);
        this._private._map._private._layers = _.sortBy(this.map.layers, 'zIndex');

        return this;
    };

    get actions(){ return this._private._actions; }
    set actions(value){ this._private._actions = value; }

    get active(){ return this._private._active; }
    set active(value){
        this._private._active = value;
        this._private._loading = value;

        if (!this._private._active)
            this.clear();

        this._private._onChange({ layer: this._private._loading ? this : null });
    }

    get entities(){ return this._private._entities; }
    set entities(value) { this._private._errors._readOnly({ property: 'entities', value: value }); }

    get group(){ return this._private._group; }
    set group(value){ this._private._errors._readOnly({ property: 'group', value: value }); }

    get id(){ return this._private._id; }
    set id(value){ this._private._errors._readOnly({ property: 'id', value: value }); }    

    get labels(){ return this._private._labels; }
    set labels(value) { this._private._errors._readOnly({ property: 'labels', value: value }); }

    get labeled(){ return this._private._labeled; }
    set labeled(value){ 
        this._private._labeled = value;
        this._private._oppositeLabeledEntities = [];
        this._private._onChange(); 
    }

    get legend(){ return this._private._legend; }
    set legend(value) { 
        this._private._legend = value;
        this._private._onChange(); 
        
    }

    get loading(){ return this._private._loading; }
    set loading(value) { 
        this._private._loading = value;
        this._private._onChange(); 
    }

    get metaData(){ return this._private._metaData; }
    set metaData(value){ this._private._metaData = value; }

    get secondaryMetaData(){ return this._private._secondaryMetaData; }
    set secondaryMetaData(value){ this._private._secondaryMetaData = value; }

    get map(){ return this._private._map; }
    set map(value) { this._private._errors._readOnly({ property: 'map', value: value }); }

    get oppositeLabeledEntities(){ return this._private._oppositeLabeledEntities; }
    set oppositeLabeledEntities(value){ 
        this._private._oppositeLabeledEntities = value;
        this._private._onChange();
    }

    get oppositeVisibilityEntities(){ return this._private._oppositeVisibilityEntities; }
    set oppositeVisibilityEntities(value){ 
        this._private._oppositeVisibilityEntities = value;
        this._private._onChange();
    }

    get secondaryOppositeVisibilityEntities(){ return this._private._secondaryOppositeVisibilityEntities; }
    set secondaryOppositeVisibilityEntities(value){ 
        this._private._secondaryOppositeVisibilityEntities = value;
        this._private._onChange();
    }    

    get outOfRange(){ return this._private._outOfRange; }
    set outOfRange(value){ this._private._outOfRange = value; }

    get parentId(){ return this._private._parentId; }
    set parentId(value){ this._private._parentId = value; }

    get subText(){ return this._private._subText; }
    set subText(value){ 
        this._private._subText = value;
        this._private._onChange();
    }

    get subType(){ return this._private._subType; }
    set subType(value) { this._private._errors._readOnly({ property: 'subType', value: value }); }

    get text(){ return this._private._text; }
    set text(value){ 
        this._private._text = value;
        this._private._onChange();
    }

    get toolbars(){ return this._private._toolbars; }
    set toolbars(value){ 
        this._private._toolbars = value;
        this._private._onChange();
    }

    get tradeAreas(){ return this._private._tradeAreas; }
    set tradeAreas(value){ this._private._tradeAreas = value; }

    get type(){ return this._private._type; }
    set type(value) { this._private._errors._readOnly({ property: 'type', value: value }); }

    get visible(){ return this._private._visible; }
    set visible(value){
        this._private._entities.forEach(x => x.visible = value);
        this._private._labels.forEach(x => x.visible = value);
        this._private._visible = value;
        this._private._oppositeVisibilityEntities = [];
        this._private._secondaryOppositeVisibilityEntities = [];
        this._private._onChange();
    }

    get zIndex(){ return this._private._zIndex; }
    set zIndex(value){
        this._private._entities.forEach(x => x.zIndex = value);
        this._private._entities.filter(x => x.metaData.isHalo).forEach(x => x.zIndex = value - 1);
        this._private._labels.forEach(x => x.zIndex = value);
        this._private._zIndex = value;
        this._private._onChange();
    }

    get data(){ return this._private._data; }
    set data(value){ this._private._data = value; } 

    addEntity(o){
        var entity = _.isObject(o.entity) ? o.entity : new Entity(this, o);
        this._private._onChange();
        return entity;
    };

    addLabel(o){
        var label = _.isObject(o.label) ? o.label : new Entity(this, o);
        this._private._onChange();
        return label;
    };

    clearLabels(){
        this._private._labels.forEach(label => label.dispose());
        this._private._labels = [];        
    };

    clear(o){

        o = _.isObject(o) ? o : {};

        this.clearLabels();
        this._private._entities.forEach(entity => {

            if (o.clearSelections && _.isObject(entity.metaData.selectionEntity))
                entity.metaData.selectionEntity.dispose();

            entity.dispose();

        });

        this._private._entities = [];
        this._private._legend = [];
        this._private._onChange();
    };

    addFeature(o){
        this._private._layer.add(o);
    }

    setStyle(o){
        this._private._layer.setStyle(o);
    }

    dispose(){
        this._private._map._private._layers = this._private._map._private._layers.filter(layer => layer !== this);
        this.clear({clearSelections: true});

        this._private._layer.dispose();

        this._private._map._executeListeners({ type: constants.listeners.layerRemove, properties: { layer: this } });
    };

    refresh(){        
        switch(this.type){
            default:
                if (_.isFunction(this._private._layer.refresh))
                    this._private._layer.refresh();
                break;
            case constants.layers.types.customerMaps.pin:
            case constants.layers.types.customerMaps.desireLine:
            case constants.layers.types.customerMaps.marketShare:
            case constants.layers.types.customerMaps.heat:
                this._private._map._private._heatMaps.forEach(heatMap => heatMap.refresh());
                this._private._map._private._imageOverlays.forEach(overlay => overlay.refresh());
                this._private._map._private._dataLayers.forEach(dataLayer => dataLayer.refresh());
                break;
        }

        this._private._onChange();
    }
};