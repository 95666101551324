import { useState } from 'react';
import { translate } from '../../../../utils/translation';

import { Bar } from '../../../base/bar/bar';
import { DropDown } from '../../../base/dropDown/dropDown';
import { Button } from '../../../base/button/button';
import { RadioButtonSet } from '../../../base/radioButtonSet/radioButtonSet';
import { map } from '../../map/map';
import { icons } from '../../../base/icon/icon';
import { constants } from '../../../../utils/constants';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { Hideable } from '../../../base/hideable/hideable';
import { sources } from '../../../../modules/sources';
import { layers } from '../../layers/layers';

import TextArea from 'devextreme-react/text-area';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { Description } from '../../../base/description/description';
import { Loader } from '../../../base/loader/loader';
import { helpers } from '../../../../utils/helpers';
import { TextBox } from '../../../base/textBox/textBox';
import { modelWizard } from '../../../../modules/modelWizard';
import { errorToast } from '../../../base/toast/toast';

const _ = require("lodash");
            
export function ModelWizardStep1({ loaded, projection, currentPushpin, enableNewLocation, enableExistingLocation, existingSiteSearches, mode, setMode, zoomToResult, setZoomToResult, openLayerResult, 
                                mapLocationListener, setMapLocationListener, locationSearch, setLocationSearch, locationResult, setLocationResult, existingSearch, setExistingSearch, existingResult, setExistingResult,
                                selectSite, setSelectSite, jobName, setJobName, relocationRing }){  
    
    const [locations, setLocations] = useState([]);
    const [currentSourceSearch, setCurrentSourceSearch] = useState(null);    
    const [existingSites, setExistingSites] = useState([]);

    const searchLocation = (e) => {

        map.addressSearch({
            search: e.value,
            callback: (results) =>{
                if (!_.isNull(results) && results.length > 0)
                    setLocations(results.map(result => { return result.description;  }));
            }
        });

        if (e.value.length > 0)
            e.component.open();
        else
            e.component.close();
	};

    const updateLocation = (o)=>{

        if (!_.isString(o.query) || o.query.length === 0)
            return;        

        map.geocode({
            query: o.query,
            callback: (result) => {
                if (projection.type === constants.projections.relocation) 
                    if (existingResult === null)
                    {
                        errorToast(translate('select_an_existing_site_first'));
                        return;
                    }
                    else if (projection.enforceRelocationRing && projection.relocationRingSize > 0 && !map.polygonContainsPoint(relocationRing.paths, result.location))
                    {
                        errorToast(translate('location_must_be_within_ring'));
                        return;
                    }        

                setLocationResult(result);

                if (o.updateSearchWithGeocode)
                    setLocationSearch(result.address.full);
                else if (o.updateSearchWithQuery)
                    setLocationSearch(o.query);

                if (o.location == null)
                    currentPushpin.location = result.location;
                else
                    currentPushpin.location = o.location;

                if (zoomToResult === true && o.zoom !== false)
                    map.locate({ location: result.location });
            }
        });
    };

    const toggleMapLocator = () =>{
        if (mapLocationListener === null)
        {
            map.setCursor({ cursor: 'crosshair' });
            map.disableEntityClicking();
            setMapLocationListener(
                map.addListener({
                    type: constants.listeners.click,
                    action: (e) => {                    
                        updateLocation({
                            query: `${e.location.lat} ${e.location.lon}`,
                            updateSearchWithGeocode: true,
                            zoom: false,
                            location: e.location
                        });
                    }
                })
            );
        }
        else
        {
            map.enableEntityClicking();
            map.setCursor({ cursor: '' });
            map.removeListener(mapLocationListener);
            setMapLocationListener(null);
        }

        if (selectSite === true)
            toggleSiteSelector();
    };    

    const searchExisting = async (e) =>{

        if (_.isObject(e.value))
            return;

		var data = await legacyEndpoints.service({
			name: 'ProjectionPointSearch',
			parameters: {
                projectionId: projection.id,
                customQueryId: currentSourceSearch === null ? helpers.emptyGuid() : currentSourceSearch.Id,
                query: e.value,
                skip: 0,
                take: 10
            }
		});

        if (data.customQuerySearchResults.length > 0)
            e.component.open();
        else
            e.component.close();

        setExistingSites(data.customQuerySearchResults);
	};

    const updateExistingSite = (o) =>{

        if (!_.isObject(o.result))
            return;

        if (projection.type === constants.projections.relocation)
            setLocationResult(null);

        if (openLayerResult === true)
            sources.refresh({
                layers: [{
                    id: o.result.QueryID,
                    text: translate('loading'),
                    subType: o.result.QueryType,
                    data: { isCompetitiveInsights: false }
                }],
                onRefresh: (o) =>{
                    layers.refreshDataLayers(o);
                }
            });

        if (zoomToResult === true)
            map.locate({ location: { lat: o.result.Latitude, lon: o.result.Longitude } });

        setExistingResult(o.result);
    };

    const toggleSiteSelector = () =>{
        if(modelWizard.currentListener === null)
        {
            modelWizard.setSelectSiteListener((entity) =>{
                
                if (projection.layers.filter(x => x.customQueryId.toLowerCase() === entity.layer.id.toLowerCase()).length === 0 && 
                    (!_.isObject(projection.proposedSiteLayer) || projection.proposedSiteLayer.customQueryId.toLowerCase() !== entity.layer.id.toLowerCase()))
                    {
                        errorToast(translate('point_not_valid_for_forecast'));
                        return;
                    }
                    
                updateExistingSite({
                    result: {
                        PointID: entity.id,
                        Name: entity.text,
                        QueryID: entity.layer.id,
                        QueryType: 0,
                        Latitude: entity.location.lat,
                        Longitude: entity.location.lon                        
                    }
                });

                if (projection.relocationRingSize > 0)
                    relocationRing.paths = helpers.createCircle({
                        location: entity.location,
                        radius: projection.relocationRingSize,
                        lengthMeasurement: constants.lengthMeasurements.miles
                    });

            });
            setSelectSite(true);
        }
        else{
            modelWizard.removeSelectSiteListener();
            setSelectSite(false);
        }

        if (mapLocationListener !== null)
            toggleMapLocator();
    };

    return <div className='modelWizardStep1'> 
        <Loader loaded={loaded} />      
        <div className='modelWizardHeader'>
            <Description className='modelWizardStep1Description' description={translate('new_location_description')} />
            {
                enableNewLocation && enableExistingLocation ? 
                <>                    
                    <RadioButtonSet className='modelWizardStep1Mode'>
                        <Button theme='secondary' size='medium' text={translate('new')} active={mode===constants.projections.modes.new} icon={icons.locationDot} onClick={()=>{setMode(constants.projections.modes.new);}} />
                        <Button theme='secondary' size='medium' text={translate('existing')} active={mode===constants.projections.modes.existing} icon={icons.store} onClick={()=>{setMode(constants.projections.modes.existing);}} />
                    </RadioButtonSet>
                </> : ''
            }
        </div>
        <div className='modelWizardBody'>
            <Bar icon={icons.gear} text={translate('options')} opened={false}>
                <table className='modelWizardStep1Options'>
                    <tbody>
                        <tr>
                            <td>
                                <b>{translate('zoom_to_result')}:</b>
                            </td>
                            <td>
                                <Button theme='secondary' size='small' icon={icons.check} text={translate('zoom_to_result')} active={zoomToResult === true } onClick={() => {setZoomToResult(!zoomToResult)}} />
                            </td>
                        </tr>
                        {
                            mode === constants.projections.modes.existing ? 
                            <tr>
                                <td>
                                    <b>{translate('open_result_layer')}:</b>
                                </td>
                                <td>
                                    <Button theme='secondary' size='small' icon={icons.check} text={translate('open_result_layer')} active={openLayerResult === true } onClick={() => {setExistingSearch(!openLayerResult);}} />
                                </td>
                            </tr> : ''
                        }
                    </tbody>
                </table>
            </Bar>
            {
                projection.hasRecaptureModel === true ? '':
                <Bar icon={icons.text} text={translate('job_name')} opened={true}>
                    <TextBox value={jobName} onChange={(o) => {setJobName(o.value)}} />
                </Bar>
            }            
            <Hideable hide={mode !== constants.projections.modes.existing && projection.type !== constants.projections.relocation}>
                <Bar icon={icons.store} text={`${(projection.type===constants.projections.relocation ? `${translate('step')} 1: ` : '')}${translate('existing_site')}`} opened={true}>
                    <div className='modelWizardStep1Input'>
                        {
                            existingSiteSearches.length > 2 ? 
                                <DropDown className='modelWizardStep1InputSourceFilter' display='Name' items={existingSiteSearches} selected={currentSourceSearch ?? existingSiteSearches[0]} label={translate('source_filter')} onChange={(e)=>{setCurrentSourceSearch(e.value); }}/>    
                            : 
                                ''
                        }                    
                        <div className='app-text-box'>
                            <TextArea
                                label={translate('existing_site')}
                                height='75px'
                                placeholder={translate('existing_location_place_holder')}
                                value={existingSearch}
                                onKeyDown={(e)=>{
                                    if (e.event.originalEvent.key !== 'Enter')
                                        return;

                                    e.event.preventDefault();
                                }}
                                onKeyUp={(e)=>{
                                    setExistingSearch(e.component.option('text'));
                                }}
                            />
                        </div>
                        <Autocomplete
                            items={existingSites}
                            value={existingSearch}
                            onValueChanged={searchExisting}
                            minSearchLength={2}
                            searchTimeout={500}
                            height='0px'
                            placeholder=''
                            itemRender={(e) => { return e.Name; }}
                            onSelectionChanged={(e) =>{
                                setExistingSearch(e.selectedItem.Name);
                                updateExistingSite({ result: e.selectedItem });
                            }}
                        />
                    </div>
                    <div className='modelWizardStep1InputSave'>
                        <Button className='modelWizardStep1Button' theme='secondary' icon={icons.crosshair} text={translate('select_site')} active={selectSite} onClick={() => {toggleSiteSelector()}} />
                    </div>
                </Bar>
            </Hideable>
            <Hideable hide={mode !== constants.projections.modes.new && projection.type !== constants.projections.relocation}>
                <Bar icon={icons.locationDot} text={`${(projection.type===constants.projections.relocation ? `${translate('step')} 2: ` : '')}${translate('new_location')}`} opened={true}>
                    <div className='modelWizardStep1Input'>
                        <div className='app-text-box'>
                            <TextArea
                                height='75px'
                                value={locationSearch}
                                label={translate('new_location')}
                                placeholder={translate('new_location_place_holder')}
                                onKeyDown={(e)=>{
                                    if (e.event.originalEvent.key !== 'Enter')
                                        return;

                                    e.event.preventDefault();
                                }}
                                onKeyUp={(e)=>{
                                    setLocationSearch(e.component.option('text'));

                                    if (e.event.originalEvent.key !== 'Enter')
                                        return;

                                    updateLocation({ query: e.component.option('text') });
                                }}
                                onValueChanged={(e) =>{
                                    if (e.event === undefined)
                                        return;

                                    setLocationSearch(e.value);
                                    updateLocation(e.value);
                                }}
                            />
                        </div>
                        <Autocomplete
                            items={locations}
                            value={locationSearch}
                            onValueChanged={searchLocation}
                            minSearchLength={2}
                            searchTimeout={500}
                            height='0px'
                            placeholder=''
                            onSelectionChanged={(e) =>{
                                updateLocation({ query: e.selectedItem, updateSearchWithQuery: true });
                            }}
                        />
                        <div className='modelWizardStep1InputSave'>
                            <Button className='modelWizardStep1Button' theme='secondary' icon={icons.crosshair} text={translate('place_site')} active={mapLocationListener !== null} onClick={() => {toggleMapLocator()}} />
                            <Button className='modelWizardStep1Button' theme='primary' icon={icons.magnifyingGlass} text={translate('search')} onClick={() => {updateLocation({ query: locationSearch });}} />
                        </div>
                    </div>
                </Bar>
            </Hideable>               
            <Bar icon={icons.rectangleList} text={translate('summary')} opened={false}>
                {
                    mode === constants.projections.modes.existing || projection.type === constants.projections.relocation ? 
                    <table className='modelWizardStep1Options app-selectable-text'>
                        <tbody>
                            {
                                projection.type === constants.projections.relocation ?
                                <tr>
                                    <td className='modelWizardSummaryTitle-nopadding' colSpan={2} valign='top'>
                                        <b>{translate('existing_site')}</b>
                                    </td>
                                </tr> : ''
                            }
                            <tr>
                                <td valign='top'>
                                    <b>{translate('name')}:</b>                                
                                </td>
                                <td>
                                    { existingResult === null ? translate('none') : existingResult.Name }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>{translate('latitude')}:</b>
                                </td>
                                <td>
                                    { existingResult === null ? translate('none') : existingResult.Latitude.toFixed(6) }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>{translate('longitude')}:</b>
                                </td>
                                <td>
                                    { existingResult === null ? translate('none') : existingResult.Longitude.toFixed(6) }
                                </td>
                            </tr>
                        </tbody>
                    </table> : ''
                }
                {
                    mode === constants.projections.modes.new || projection.type === constants.projections.relocation ? 
                    <table className='modelWizardStep1Options app-selectable-text'>
                        <tbody>
                            {
                                projection.type === constants.projections.relocation ?
                                <tr>
                                    <td className='modelWizardSummaryTitle' colSpan={2} valign='top'>
                                        <b>{translate('new_location')}</b>
                                    </td>
                                </tr> : ''
                            }
                            <tr>
                                <td valign='top'>
                                    <b>{translate('address')}:</b>
                                </td>
                                <td>
                                    { locationResult === null ? translate('none') : locationResult.address.full }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>{translate('latitude')}:</b>
                                </td>
                                <td>
                                    { locationResult === null ? translate('none') : locationResult.location.lat.toFixed(6) }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>{translate('longitude')}:</b>
                                </td>
                                <td>
                                    { locationResult === null ? translate('none') : locationResult.location.lon.toFixed(6) }
                                </td>
                            </tr>
                        </tbody>
                    </table> : ''
                }
            </Bar>
        </div>
        
    </div>
}