// React imports
import { useState, useEffect, Fragment } from 'react';

// App imports
import { Description } from '../../../base/description/description';
import { TextBox, inputBoxTypes } from '../../../base/textBox/textBox';
import { translate } from '../../../../utils/translation';

const _ = require("lodash");

export function FilterDetailsT({ isLocked, distribution, variable, updateVariable }) {

    const [description, setDescription] = useState('');
    const [origValue, setOrigValue] = useState(null);

    useEffect(() => {
        setOrigValue(variable.Value);
    }, [variable]);

    useEffect(() => {
        
        if (!_.isArray(distribution) || distribution.length === 0)
            return;

        const dist = distribution.find(o => o.Id === variable.ID);

        if (_.isObject(dist))
            setDescription(`${translate('stores_within_range')}: ${dist.Raw}\n${translate('available_analogs')}: ${dist.Weighted}`);
        else
            setDescription(`${translate('stores_within_range')}: ${translate('n_a')}\n${translate('available_analogs')}: ${translate('n_a')}`);

    }, [variable, distribution]);

    return <>
        { variable.Value == null ?
            <div>{translate('no_data')}</div>
            : 
            <Fragment key={variable.ID}>
                <Description description={translate('view_alter_value_variable')} />
                <TextBox 
                    className={'app-analogs-filter-details-input'} 
                    value={variable.Value} 
                    valueChangeEvent={'change keyup'}
                    readOnly={(!variable.IsModifiable && variable.IsReadOnly) || isLocked} 
                    type={inputBoxTypes.numeric}
                    allowNull={false}
                    onChange={(o) => { 
                        if (o.value === origValue)
                            return;

                        updateVariable(o.value); 
                    }} 
                />
                { variable.IsScoreCondition || description.length === 0 ? '' :
                    <Description description={description} />
                }
             </Fragment>
        }
    </>
}