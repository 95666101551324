
// App imports
import { Layer } from './bingLayer';
import { translate } from '../../utils/translation';
import { drivingDirections } from './bingDrivingDirections'

var map;
var viewChangeListeners = [];

export class BingMap {    

    constructor() {
       this._layers = [];
    };

    load(o) {        
        var script = document.createElement("script");
        script.type = 'text/javascript';
        script.src = 'https://www.bing.com/api/maps/mapcontrol?branch=release';
        document.head.appendChild(script);

        script.onload = () => {
            setTimeout(() =>{
                map = new window.Microsoft.Maps.Map("#map",
                {
                    credentials: `${process.env.REACT_APP_MAPS_BING_API_KEY}`,
                    center: new window.Microsoft.Maps.Location(41.827108, -71.386886),
                    mapTypeId: window.Microsoft.Maps.MapTypeId.road,
                    zoom: 13,
                    enableClickableLogo: false,
                    showScalebar: true,
                    showCopyright: false,
                    enableInertia: false,
                    disablePanning: false,
                    disableScrollWheelZoom: false,
                    disableZooming: false,
                    streetsideOptions: { onSuccessLoading: () => {} },
                    disableStreetsideAutoCoverage: true,
                    disableKeyboardInput: true,
                    pitch: 0,
                    heading: 0,
                    showMapTypeSelector: false,
                    showLocateMeButton: false,
                    showZoomButtons: false
                });

                window.Microsoft.Maps.Events.addHandler(map, "viewchangeend", () =>{                                     
                    viewChangeListeners.forEach(fn => {
                        fn();
                    });
                });
    
                if (o.callback)
                    o.callback();
                
            }, 2000);
        };
    };

    clear(){
        this._layers.forEach(layer =>{
            layer.dispose();
        });
    };

    getTypes(){
        return [
            { id: window.Microsoft.Maps.MapTypeId.road, name: translate('road'), image: 'https://cdn.tradeareasystems.net/Images/TASOnline/Map/MapType_Road.png' },
            { id: window.Microsoft.Maps.MapTypeId.canvasLight, name: translate('light'), image: 'https://cdn.tradeareasystems.net/Images/TASOnline/Map/MapType_Light.png' },
            { id: window.Microsoft.Maps.MapTypeId.grayscale, name: translate('gray'), image: 'https://cdn.tradeareasystems.net/Images/TASOnline/Map/MapType_Gray.png' },
            { id: window.Microsoft.Maps.MapTypeId.canvasDark, name: translate('dark'), image: 'https://cdn.tradeareasystems.net/Images/TASOnline/Map/MapType_Dark.png' },
            { id: window.Microsoft.Maps.MapTypeId.aerial, name: translate('satellite'), image: 'https://cdn.tradeareasystems.net/Images/TASOnline/Map/MapType_Aerial.png' },
            { id: window.Microsoft.Maps.MapTypeId.birdseye, name:  translate('birds_eye'), image: 'https://cdn.tradeareasystems.net/Images/TASOnline/Map/MapType_BirdsEye.png' }
        ];
    };

    setType(id){
        map.setMapType(id);
    };

    getCenter(){
        var center = map.getCenter();
        return { lat: center.latitude, lon: center.longitude};
    };

    getBounds(){        
        var bounds = map.getBounds();
        var northWest = bounds.getNorthwest();
        var southEast = bounds.getSoutheast();

        return {
            northEast: { lat: northWest.latitude, lon: southEast.longitude },
            northWest: { lat: northWest.latitude, lon: northWest.longitude },
            southEast: { lat: southEast.latitude, lon: southEast.longitude },
            southWest: { lat: southEast.latitude, lon: northWest.longitude }
        };
    };

    getZoom(){
        return map.getZoom();
    };

    getDirections(props) {
        props.map = map;
        drivingDirections.getDirections(props);
    }    

    addViewChangeListener(o){
        viewChangeListeners.push(o.listener);
    };

    layers = {        
        get: () =>{
            return this._layers;
        },
        add: (o) =>{
            o.map = map;
            o.parent = this;

            var layer = new Layer(o);

            this._layers.push(layer);

            return layer;
        }
    };
};