import { useEffect, useState } from 'react';

import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { TextBox } from '../../../base/textBox/textBox';

const _ = require("lodash");

export function FolderEditForm({ group, onUpdateGroup, onClose }){
    const [folderName, setFolderName] = useState('');

    useEffect(() =>{
		(async ()=>{
            setFolderName(group.text);
		})();
    }, [group]);

	return <>
        <div className='app-projects-available-maps-folder-edit-header'>
            <TextBox className='app-projects-available-maps-folder-edit-name' label={translate('name')} value={folderName} valueChangeEvent='keyup'
                onChange={(o) => {
                    if (o.userChanged)
                        setFolderName(o.value);
                                                    
                }}
            />
            <div>
                <Button className='app-projects-available-maps-folder-edit-button' theme='primary' size='tiny' icon={icons.check} disabled={folderName.length <= 0} 
                    onClick={async ()=>{      
                        onUpdateGroup({...group, text: folderName});            
                        onClose();
                    }}
                />
                <Button className='app-projects-available-maps-folder-edit-button' theme='primary' size='tiny' icon={icons.x}         
                    onClick={async ()=>{ 
                        setFolderName('');          
                        onClose();
                    }}
                />                                                
            </div>
        </div>
    </>;
}