// React imports
import React, { useEffect, useState } from 'react';

import { Window } from '../../../base/window/window';
import _ from 'lodash';

export var mapWindow;

export const MapWindow = () => {
    
    const [title, setTitle] = useState(null);
    const [visible, setVisible] = useState(false);
    const [content, setContent] = useState(null);
    const [width, setWidth] = useState(450);
    const [height, setHeight] = useState(600);
    const [resizable, setResizable] = useState(true);

    mapWindow = {
        show: (o) =>{
            
            setVisible(true);
            setContent(o.content);
            setTitle(o.title);
            setWidth(_.isInteger(o.width) ? o.width : 450);
            setHeight(_.isInteger(o.height) ? o.height : 600);
            setResizable(_.isBoolean(o.resizable) ? o.resizable : true);
            
        },
        hide: () =>{
            setVisible(false);
            setContent(null);
            setTitle(null);
            setWidth(450);
            setHeight(600);
            setResizable(true);
        }
    };

    return <Window visible={visible} setVisible={setVisible} title={title} width={width} height={height} resizable={resizable}>
        {content}
    </Window>
}