// Third party imports
import {DateBox as DxDateBox} from 'devextreme-react/date-box';

export function DateBox({ className, label, value, min, max, disabled, height, onChange, actions, inputAttr = {} }) {
    
    const now = new Date();

    var classes = ['app-date-box'];

    if (className)
        classes.push(className);

    return <div className={classes.join(' ')}>
        <DxDateBox 
            type="date"
            inputAttr={inputAttr}
            height={height? height : '30px'}
            value={value}
            min={min}
            max={max}
            onValueChanged={(e)=>{

                if (e.value === null || e.value === e.previousValue)
                    return

                if (onChange)
                    onChange({                        
                        value: e.value,
                        userChanged: e.event !== undefined
                    });
            }}
        />
    </div>
}