// React imports
import { useState, useEffect, useCallback } from 'react';

// App imports
import { Wizard } from '../../../base/wizard/wizard';
import { MobilityReportsStep1 } from './mobilityReportsStep1';
import { MobilityReportsStep2 } from './mobilityReportsStep2';
import { MobilityReportsStep3 } from './mobilityReportsStep3';
import { reports as reportsModule } from '../../../../modules/reports';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { translate } from "../../../../utils/translation";
import { helpers } from "../../../../utils/helpers";
import { constants } from '../../../../utils/constants';
import { map } from '../../map/map';
import { userPreferences } from '../../app';
import { errorToast } from '../../../base/toast/toast';

export function MobilityReports({reportCategory, onClose}) {

    const wizardTitle = translate("mobility_data") + " " + translate("reports");

    const [loaded, setLoaded] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [reportType, setReportType] = useState(-1);		
    const [reportTypes, setReportTypes] = useState([]);
    const [tradeArea, setTradeArea] = useState(null);		
    const [tradeAreas, setTradeAreas] = useState([]);
    const [resetTradeAreas, setResetTradeAreas] = useState(false);
    const [dataSource, setDataSource] = useState(null);
    const [dataSources, setDataSources] = useState([]);
    const [timeOfDay, setTimeOfDay] = useState(-1);		
    const [jobList, setJobList] = useState([]);
    const [expandedJobs, setExpandedJobs] = useState([]);
    const [selectAllJobs, setSelectAllJobs] = useState([]);
    const [reportTitle, setReportTitle] = useState('');		
    const [outputFormat, setOutputFormat] = useState(userPreferences.ReportOutput);		
    const [continueDisabled, setContinueDisabled] = useState(true);
    const [goToStep, setGoToStep] = useState(-1);

    var getReportList = useCallback(
        async () =>{
            setLoaded(false);      

            setReportTypes(await reportsModule.getReportListForCategory({ reportCategory: reportCategory }));

            setReportType(-1);
            setTradeArea(null);
            setTradeAreas([]);
            setDataSource(null);

            var data = await reportsModule.getPointServicesWithGeoFences();
            var sources = [];

            data.forEach((source) => {
                var groupTitle = translate('others');
                var groupId = '0';

                if (source.ApplicationSymbology) {
                    groupId = source.ApplicationSymbology.Id;
                    groupTitle = source.ApplicationSymbology.Title;
                }

                var parent = sources.find((val) => val.id.toLowerCase() === groupId.toLowerCase());

                var child = {
                    id: source.Id,
                    text: source.Name,
                    type: source.Type
                };

                if (parent)
                    parent.sources.push(child);
                else
                    sources.push({
                        id: groupId,
                        text: groupTitle,
                        sources: [child]
                    });
            });

            setDataSources([...sources]);

            setLoaded(true);
        },
        [reportCategory]
    );

    useEffect(() =>{
        getReportList();
    }, [reportCategory, getReportList]);

    useEffect(() =>{

        var newTradeAreas = [];

        if (reportType >= 0) {
            if (reportTypes[reportType].IsCurrentViewAllowed)
                newTradeAreas.push({
                    id: 'current_view',
                    text: translate('current_view')
                });

            if (!reportTypes[reportType].IsCurrentViewOnly)
                map.layers.filter(layer => layer.type === constants.layers.types.tradeArea).forEach(layer => {
                    newTradeAreas.push({
                        id: layer.id,
                        text: layer.text.replace(/ TAL[_]*[0-9]*/i, "")
                    });
                });            
        }
        
        setReportTitle(newTradeAreas.length > 0 ? newTradeAreas[0].text : '');
        setTradeArea(newTradeAreas.length > 0 ? newTradeAreas[0].id : null);
        setTradeAreas([...newTradeAreas]);    

    }, [reportTypes, reportType, resetTradeAreas]);

    var getJobList = useCallback(
        async () =>{
            if (reportType < 0 || tradeArea == null  || dataSource == null || timeOfDay < 0)
                return;

            setLoaded(false);  

            // TBD: always uses current view (ignores any selected trade area) - logic from KLI 1.0
            const shape = {
                Name: translate('current_view'),
                Type: constants.tradeAreas.types.userDrawn,
                CenterLat: map.center.lat,
                CenterLon: map.center.lon,
                Interval: 0,
                EncodedPoints: helpers.encodedLocations(helpers.createRectangle({ topLeft: map.bounds.northEast, bottomRight: map.bounds.southWest})),
                PointFormat: 0
            };

            var data = await reportsModule.getJobsInShape({ 
                reportId: reportTypes[reportType].ID, 
                shape: shape, 
                dataSourceId: dataSource, 
                dataType: 1, 
                timeType: timeOfDay 
            });

            var jobs = [];

            data.forEach((item) => {

                var site = jobs.find((val) => val.id.toLowerCase() === item.SiteId.toLowerCase());

                var job = {
                    id: item.GeoFenceId + '_' + item.JobId,
                    text: item.GeoFenceName + ' (' + item.JobName + ')',
                    geoFenceId: item.GeoFenceId,
                    geoFenceName: item.GeoFenceName,
                    jobId: item.JobId,
                    jobName: item.JobName
                };

                if (site)
                    site.jobs.push(job);
                else
                    jobs.push({
                        id: item.SiteId,
                        text: item.SiteName.length > 22 ? item.SiteName.substring(0, 22).trim() + '...' : item.SiteName,
                        jobs: [job]
                    });
            });

            setJobList([...jobs]);

            var expanded = [];
            var selectAll = [];
            jobs.forEach(() => {
                expanded.push(false);
                selectAll.push(false);
            });
            
            setExpandedJobs([...expanded]);
            setSelectAllJobs([...selectAll]);
    
            setLoaded(true);
        },
        [reportTypes, reportType, tradeArea, dataSource, timeOfDay]
    );

    const generateReport = async () =>{

        if (reportType < 0) {
            errorToast(translate('mobility_report_type_error'));
            return;
        }

        if (timeOfDay < 0) {
            errorToast(translate('mobility_time_of_day_error'));
            return;
        }

        if (dataSource == null) {
            errorToast(translate('mobility_data_source_error'));
            return;
        }

        if (reportTitle.length == 0) {
            errorToast(translate('mobility_report_title_error'));
            return;
        }

        if (outputFormat == null || outputFormat.length == 0) {
            errorToast(translate('mobility_output_format_error'));
            return;
        }

        setGenerating(true);

        var shapes = [];

        if (tradeArea == 'current_view')
            shapes.push({
                Name: translate('current_view'),
                Type: constants.tradeAreas.types.userDrawn,
                CenterLat: map.center.lat,
                CenterLon: map.center.lon,
                EncodedPoints: helpers.encodedLocations(helpers.createRectangle({ topLeft: map.bounds.northEast, bottomRight: map.bounds.southWest})),
                GeographyIds: [],
                GeographyVintageId: -1,
                Interval: 0,
                LengthMeasurement: constants.lengthMeasurements.miles,
                PointFormat: 0
            });
        else {
            var layer = map.layers.filter(layer => layer.type === constants.layers.types.tradeArea).find(layer => { return layer.id.toLowerCase() === tradeArea.toLowerCase() });

            if (layer && layer.tradeAreas)
                layer.tradeAreas.forEach((tradeArea) =>{
                    var type = tradeArea.type.substring(0, 1).toUpperCase();
                    var distance = tradeArea.distance;
                    var encodedString = tradeArea.polygon.polygonString || "";
                    
                    if (type === "P")
                        distance = 0;

                    shapes.push({
                        Name: tradeArea.name,
                        Type: type,
                        CenterLat: tradeArea.polygon.centroid.lat,
                        CenterLon: tradeArea.polygon.centroid.lon,
                        EncodedPoints: encodedString,
                        GeographyIds: tradeArea.geographyIds,
                        GeographyVintageId: tradeArea.geographyVintage,
                        Interval: distance,
                        PointFormat: tradeArea.polygon.polygonType === constants.encodedString.google ? constants.demographicShape.encodedString : constants.demographicShape.wkt
                    });
                });
            else {
                setGenerating(false);
                errorToast(translate('mobility_trade_area_exists_error'));

                // most likely the user deleted the selected trade area, so force the trade area list to be re-generated
                setResetTradeAreas(!resetTradeAreas);

                return;
            }
        }

        if (shapes.length == 0) {
            setGenerating(false);
            errorToast(translate('mobility_trade_area_error'));
            return;
        }

        var jobIds = [];
        const selectedJobs = jobList.filter(site => site.jobs.some(item => item.selected === true));

        selectedJobs.forEach((site) =>{
            site.jobs.forEach((job) =>{
                if (job.selected)
                    jobIds.push(job.jobId);
            });
        });

        if (jobIds.length == 0) {
            setGenerating(false);
            errorToast(translate('mobility_jobs_error'));
            return;
        }

        const reportId = reportTypes[reportType].ID;
        var isAJob = await reportsModule.isDemoReportAJob({ reportId: reportId });

        var results = { cancel: true, success: false, message: '' };

        if (isAJob == true) {

            var requestId = await reportsModule.createDemoReportRequestForDataSource({ 
                reportId: reportId,
                reportTitle: reportTitle,
                shapes: shapes,
                reportType: "UberRetail",
                outputFormat: outputFormat,
                dataSourceId: dataSource,
                dataType: 1,
                timeType: timeOfDay,
                jobList: jobIds
            });

            if (requestId != "") {
                results.cancel = false;
                results.success = true;
                results.message = `${reportTitle} ${translate('successfully_queued')}: ${requestId}`;
            }
            else {
                results.cancel = false;
                results.success = false;
                results.message = translate('request_failed');
            }

        }
        else {
            
            var demoReport = await reportsModule.generateDemoReportForDataSource({ 
                reportId: reportId,
                reportTitle: reportTitle,
                shapes: shapes,
                outputFormat: outputFormat,
                dataSourceId: dataSource,
                dataType: 1,
                timeType: timeOfDay,
                jobList: jobIds
            });

            if (demoReport != null) {
                helpers.navigateToUrl(legacyEndpoints.handlers.getReportUrl({
                    id: demoReport.ReportID,
                    fileId: demoReport.ReportFileID,
                    dictionaryId: demoReport.ReportDictionaryID,
                    type: 'Demographics',
                    output: outputFormat
                }));
            }
            else {
                errorToast(reportTitle + ' Failed');
            }
        }

        setGenerating(false);

        onClose(results);

        // TBD: open the job managment component
    };

    const nextStep = (o) =>{
        if (o.step == 1)
            getJobList();
        else if (o.step == 3)
            generateReport();
    };

    var steps = [
        { title: wizardTitle, text: translate("mobility_data_report_step_1"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children: 
            <MobilityReportsStep1 reportTypes={reportTypes} reportType={reportType} updateReportType={(value) =>{ setReportType(value); }} 
                tradeAreas={tradeAreas} tradeArea={tradeArea} updateTradeArea={(value) =>{ setTradeArea(value); }} 
                dataSources={dataSources} updateDataSources={(sources) =>{ setDataSources([...sources]); }} 
                dataSource={dataSource} updateDataSource={(value) =>{ setDataSource(value); }} 
                timeOfDay={timeOfDay} updateTimeOfDay={(value) =>{ setTimeOfDay(value); }}
                onReadyNext={(o) => { setContinueDisabled(!o); }} 
            /> 
        },
        { title: wizardTitle, text: translate("mobility_data_report_step_2"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children: 
            <MobilityReportsStep2 jobList={jobList} updateJobList={(jobs) =>{ setJobList([...jobs]); }} 
                expandedJobs={expandedJobs} updateExpandedJobs={(expanded) =>{ setExpandedJobs(expanded); }}
                selectAllJobs={selectAllJobs} updateSelectAllJobs={(selected) =>{ setSelectAllJobs(selected); }}
                reportTitle={reportTitle} updateReportTitle={(value) =>{ setReportTitle(value); }}
                outputFormat={outputFormat} updateOutputFormat={(value) =>{ setOutputFormat(value); }}
                onReadyNext={(o) => { setContinueDisabled(!o); }} 
            /> 
        },
        { title: wizardTitle, text: translate("mobility_data_report_step_3"), loaded: loaded, generating: generating, continueDisabled: continueDisabled, children: 
            <MobilityReportsStep3 reportTypes={reportTypes} reportType={reportType} tradeAreas={tradeAreas} tradeArea={tradeArea}
                dataSources={dataSources} dataSource={dataSource} timeOfDay={timeOfDay} jobList={jobList} reportTitle={reportTitle} outputFormat={outputFormat} 
                onEditStep={(o) => { setGoToStep(o.step); }} 
            /> 
        }
	];

    return <>
        <Wizard steps={steps} goToStep={goToStep} completeText={translate('generate')} onClose={() =>{ onClose({ cancel: true, success: false, message: '' }); }} onNextStep={(o) =>{ nextStep(o); }}/>
    </>
}