// Third party imports
import {TextArea as DxTextArea } from 'devextreme-react/text-area';

import { icons } from '../../base/icon/icon';

export function TextArea({ className, label, value, placeholder, name, disabled, minHeight, maxHeight, onChange, valueChangeEvent, onEnter, textAreaRef }) {

    var classes = ['app-text-box'];

    if (className)
        classes.push(className); 

    return <div className={classes.join(' ')}>
        <DxTextArea 
            label={label} 
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            valueChangeEvent={valueChangeEvent? valueChangeEvent : 'change'}
            stylingMode='outlined'
            minHeight={minHeight ? minHeight : '40px'}
            maxHeight={maxHeight ? maxHeight : '100px'}
            autoResizeEnabled={true}
            ref={textAreaRef}
            onKeyDown={(e)=>{
                if (onEnter && e.event.key === 'Enter' && !e.event.shiftKey) {  
                    e.event.preventDefault();  

                    onEnter({                        
                        name: name,
                        event: e,
                        userChanged: e.event !== undefined
                    });
                }  
            }}
            onValueChanged={(e)=>{

                if (e.value === null || e.value === e.previousValue)
                    return

                if (onChange)
                    onChange({        
                        event: e,
                        value: e.value,
                        name: name,
                        userChanged: e.event !== undefined
                    });
            }}>
        </DxTextArea>
    </div>    
}