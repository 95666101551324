// React imports
import { useState, useEffect } from 'react'


// App imports
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { TextBox } from '../../../base/textBox/textBox';
import { TextArea } from '../../../base/textArea/textArea';
import { multimedia as multimediaModule } from '../../../../modules/multimedia';

const _ = require("lodash");

export var saveMap;

export function DetailsEdit({media,onClose}) {
    
    const [updatedMedia, setUpdatedMedia] = useState(null);

    useEffect(() =>{
        setUpdatedMedia(media);
    }, [media]);

    const onSave = async (o) =>{

        const updates = {
            id: updatedMedia.id,
            name: updatedMedia.name,
            description: updatedMedia.description         
        }        
        await multimediaModule.updateMultimedia(updates);
        onClose();
    }    

    function close() {
        onClose();
    };

    return <>
    { !updatedMedia ? '' : 
                <div className='app-multimedia-details-edit'>
                    <div className='app-multimedia-details-edit-actions-rows app-multimedia-header'>
                        <Button className='app-multimedia-details-edit-button' theme='secondary' tooltip={translate('close')} size='small' icon={icons.arrowTurnDownLeft} onClick={()=>{close() }} />	                    
                        <Button className='app-multimedia-details-edit-button' theme='primary' tooltip={translate('save')} size='small' icon={icons.save} onClick={()=>{onSave() }} />	
                    </div>                    
                    
                    <div className='app-multimedia-details-edit-rows'>
                        <TextBox className='app-multimedia-details-edit-inputs' height='47px' label={translate('name')} value={updatedMedia.name} onChange={(o) => { setUpdatedMedia({ ...updatedMedia, name: o.value }); }}  />                        
                        <TextBox className='app-multimedia-details-edit-inputs' height='47px' label={translate('hashTags')} disabled={true} value='' onChange={(o) => {  }}  />
                        <TextBox className='app-multimedia-details-edit-inputs' height='47px' label={translate('placeHolders')} disabled={true} value='' onChange={(o) => {  }}  />
                    </div>
                    <div className='app-multimedia-details-edit-rows'>                            
                        <TextArea className='app-multimedia-details-edit-inputs-description' minHeight={150} label={translate('description')} value={updatedMedia.description}
                            onChange={(o) => { setUpdatedMedia({ ...updatedMedia, description: o.value }); }}  />                  
                    </div>                                                                        
                </div>
        }
    </>
}