// React imports
import { useState, useEffect } from 'react'

// App imports
import { Loader }  from '../../../../base/loader/loader';
import { DataGrid } from '../../../../base/dataGrid/dataGrid';
import { DropDown } from '../../../../base/dropDown/dropDown';
import { translate } from '../../../../../utils/translation';

const _ = require("lodash");

export function CICustomerProfileDashboard({ data, settings, reports, onReportChanged, percents, onPercentChanged, siteId, siteName, loaded, visible }) {

    const [columns, setColumns] = useState([]);

    useEffect(() => {

        if (!settings)
            return;

        var newColumns = [];

        newColumns.push({
            name: "fieldId",
            caption: "",
            dataType: "string",
            fixed: true,
            fixedPosition: "left",
            className: "tasDxCustom"
        });

        var j = 1;
        _.each([{ id: siteId, name: siteName }].concat(
            _.without(settings.pointList,
                _.find(settings.pointList,
                    { id: siteId }))),
            function(item, i) {
                newColumns.push({
                    id: item.id,
                    fixed: i === 0,
                    fixedPosition: "left",
                    caption: item.name,
                    dataType: "string",
                    name: item.id,
                    className: "tasDxCustom center",
                    visible: true,
                    columns: [
                        {
                            id: j.toString(),
                            fixed: i === 0,
                            fixedPosition: "left",
                            caption: `${(settings.tradeAreaPercentage * 100)}${translate('percent_trade_area')}`,
                            allowFiltering: false,
                            allowSorting: false,
                            dataType: "string",
                            name: j.toString(),
                            className: "tasDxCustom right",
                            visible: true
                        },
                        {
                            id: (j + 1).toString(),
                            fixed: i === 0,
                            fixedPosition: "left",
                            caption: `${(settings.tradeAreaPercentage * 100)}${translate('percent_customer_profile')}`,
                            allowFiltering: false,
                            allowSorting: false,
                            dataType: "string",
                            name: (j + 1).toString(),
                            className: "tasDxCustom right",
                            visible: true
                        }
                    ]
                });

                j += 2;
            });

        setColumns(newColumns);

    }, [settings, siteId, siteName]);

    return <>
        <Loader loaded={loaded} />
        { !_.isObject(settings) || !_.isArray(columns) || columns.length === 0 ? '' :
            <div className='app-ci-dashboards'>
                <div className='app-ci-dashboards-input'>
                    <div className='app-ci-dashboards-labels'>{translate('trade_area')}:</div>
                    <DropDown
                        items={percents} 
                        width={'100px'}
                        height={'40px'} 
                        display={'text'} 
                        valueProperty={'id'} 
                        selected={percents.find((p) => p.value == settings.tradeAreaPercentage).id}
                        onChange={(o) => { onPercentChanged(o); }} 
                    />
                    <div className='app-ci-dashboards-labels'>{translate('report')}:</div>
                    <DropDown
                        items={reports} 
                        width={'360px'}
                        height={'40px'} 
                        display={'text'} 
                        valueProperty={'id'} 
                        selected={settings.demographicReportId.toString()}
                        onChange={(o) => { onReportChanged(o); }} 
                    />
                </div>
                <DataGrid 
                    key={'fieldId'}
                    columns={columns}
                    showFilterRow={true}
                    disablePaging={true}
                    hidePager={true}
                    onLoad={async (o)=>{

                        if (!visible || !_.isObject(data) || !_.isArray(data.data))
                            return { data: [], totalCount: 0 };

                        return data;

                    }}
                    onCellPrepared={(e)=>{
                        if (e.rowType === "data") {
                            if (e.columnIndex === 0) {
                                if (e.data["isCategory"]) {
                                    e.cellElement.style = `${e.cellElement.style}; font-weight: 500;`;
                                    e.cellElement.classList.add("tasDxCategory");
                                }
                                else {
                                    e.cellElement.style = `${e.cellElement.style}; padding-left: ${((e.data["padding"] * 2) + 17)}px;`;
                                }
                            }
                            else if (e.data["isCategory"]) {
                                e.cellElement.classList.add("tasDxCategory");
                            }
                        }                                
                    }}
                />
            </div>        
        }
    </>
}