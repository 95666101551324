// React imports
import React, { Fragment, useEffect, useState } from 'react';

import { Hideable } from "../../../base/hideable/hideable";
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import _ from 'lodash';

import  Draggable from 'react-draggable';

export var mapTooltip;
var onHide = () => {}; //TODO: Fix this
export const MapTooltip = () => {
    
    const [hide, setHide] = useState(true);
    const [title, setTitle] = useState('');
    const [pixel, setPixel] = useState({ x: -1, y: -1 });
    const [sections, setSections] = useState([]);
    const [classes, setClasses] = useState([]);

    mapTooltip = {
        show: (o) =>{
            
            setHide(false);     
            let classes = [];          
            if (o.className)
                classes.push(o.className); 

            setClasses(classes);
            
            if (_.isObject(o.pixel))
                setPixel({ x: o.pixel.x - o.xOffset, y: o.pixel.y - o.yOffset});
            
            if (_.isArray(o.sections))
                setSections(o.sections);

            if (_.isString(o.title))
                setTitle(o.title);
            
            onHide = _.isFunction(o.onHide) ? o.onHide : () => {};
        },
        hide: () =>{
            setHide(true);
        }
    };  

    return <Hideable className='app-map-tooltip' hide={hide} style={{top: pixel.y + 'px', left: pixel.x + 'px' }}>
        <Draggable handle='.app-map-tooltip-title' defaultPosition={{x: 0, y: 0}}>
            <div className={classes.join(' ')}>
                <div className='app-map-tooltip-title'>
                    {title}
                    <Button className='app-map-tooltip-close' theme='action' icon={icons.x} tooltip={translate('close')} onClick={() =>{
                        mapTooltip.hide();
                        if (_.isFunction(onHide))
                            onHide();
                    }} />
                </div>                
                {
                    sections.map((section, i) => {
                        return <div key={i} className={`app-map-tooltip-section app-selectable-text ${i===0 ? 'app-map-tooltip-first' : ''}`}>{section}</div>
                    })
                }
            </div>
        </Draggable>
    </Hideable>
        
}