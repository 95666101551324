// React imports
import { useEffect, useState } from 'react'

// Third party imports
import { Tabs as DevExTabs } from 'devextreme-react/tabs';

// App imports
import { Hideable } from '../hideable/hideable';

export function Tabs({ tabs, selectedTab, onTabClick }) {

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {

        if (selectedTab >= 0 && selectedTab < tabs.length)
            setActiveTab(selectedTab);

    }, [selectedTab, tabs.length])

    const onItemClick = (e) => {
        setActiveTab(e.itemIndex);

        if (onTabClick)
            onTabClick(e.itemData.id);
    }

    return <>
        <DevExTabs
            selectedIndex={activeTab}
            dataSource={tabs}
            onItemClick={onItemClick}
        />
        {
            tabs.map((tab,i) =>{
                return tab.component ? <Hideable className='app-tabs app-element' key={i} hide={activeTab !== i}> {tab.component} </Hideable> : ''
            })
        }
    </>
}