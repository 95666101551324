import { legacyEndpoints } from '../services/legacyEndpoints';
import { helpers } from '../utils/helpers';
import { translate } from '../utils/translation';
import { constants } from '../utils/constants';

const _ = require('lodash');

export const reports = {    
    getReportListForCategory: (o) =>{
        return legacyEndpoints.service({
            name: 'GetReportListForCategory',
            parameters: {
                aCategory: o.reportCategory
            }
        });
    },
    
    getFilterListForReport: (o) =>{
        return legacyEndpoints.service({
            name: 'GetFilterListForReport',
            parameters: o
        });
    },
      
    getReportFilterUserHierarchy: () =>{
        return legacyEndpoints.service({
            name: 'GetReportFilterUserHierarchy',
            parameters: null
        });
    },

    createAdminReportRequest: (o) =>{
        return legacyEndpoints.service({
            name: 'CreateAdminReportRequest',
            parameters: {
                aReportID: o.reportId,
                aUIParams: o.reportParams,
                aTitle: o.title,
            }  
          });
    } ,       

    getFilterListForReportTemplateParam: (o) =>{
        return legacyEndpoints.service({
            name: 'GetFilterListForReportTemplateParam',
            parameters: o
        });
    },     

    getPointServicesWithGeoFences: () =>{
        return legacyEndpoints.service({
            name: 'GetPointServicesWithGeoFences'
        });
    },

    getJobsInShape: (o) =>{
        return legacyEndpoints.service({
            name: 'GetJobsInShape',
            parameters: {
                aReportID: o.reportId,
                aShape: o.shape,
                aServiceId: o.dataSourceId,
                aDataType: o.dataType,
                aTimeType: o.timeType
            }
        });
    },

    isDemoReportAJob: (o) =>{
        return legacyEndpoints.service({
            name: 'IsDemoReportAJob',
            parameters: {
                RptId: o.reportId
            }
        });
    },

    createDemoReportRequestForDataSource: (o) =>{
        return legacyEndpoints.service({
            name: 'CreateDemoReportRequestForDataSource',
            parameters: {
                aReportID: o.reportId,
                aTitle: o.reportTitle,
                aShapes: o.shapes,
                aReportType: o.reportType,
                aOutputFormat: o.outputFormat,
                aServiceId: o.dataSourceId,
                aDataType: o.dataType,
                aTimeType: o.timeType,
                aJobList: o.jobList
            }
        });
    },

    generateDemoReportForDataSource: (o) =>{
        return legacyEndpoints.service({
            name: 'GenerateDemoReportForDataSource',
            parameters: {
                aReportID: o.reportId,
                aTitle: o.reportTitle,
                aShapes: o.shapes,
                aOutputFormat: o.outputFormat,
                aServiceId: o.dataSourceId,
                aDataType: o.dataType,
                aTimeType: o.timeType,
                aJobList: o.jobList
            }
        });
    },

    getAvailablePointDetailReports: () =>{
        return legacyEndpoints.service({
            name: 'GetAvailablePointDetailReports',

        });
    },

    submitPointDetailReportPackage: (o) =>{
        return legacyEndpoints.service({
            name: 'SubmitPointDetailReportPackage',
            parameters: o
        });
    },
    
    generateDemoReports: (o) =>{ 
        _.forEach(o.items, (item) => {
            legacyEndpoints.service({
                name: 'GenerateDemoReport',
                parameters: {
                    aReportID: item.id,
                    aTitle: o.title,
                    aShapes: o.tradeAreas,
                    reportOutputType: o.format
                },
                success: (r) => {
                    helpers.navigateToUrl(legacyEndpoints.handlers.getReportUrl({
                        id: r.ReportID,
                        fileId: r.ReportFileID,
                        dictionaryId: r.ReportDictionaryID,
                        type: 'Demographics',
                        output: o.format === constants.reports.output.pdf ? "PDF" : "Excel"
                    })); 

                    if (_.isFunction(o.callback))
                        o.callback();
                },
                error: () => {
                    if (_.isFunction(o.callback))
                        o.callback();
                }
            });
        })

    },

    getOutputTypes: function() {
        return [
          { name: translate('excel_document'), id: constants.reports.output.excel, image: 'https://cdn.tradeareasystems.net/Images/Components/Misc/ExcelIcon_20x20.png' },
          { name: translate('pdf_document'), id: constants.reports.output.pdf, image: 'https://cdn.tradeareasystems.net/Images/Components/Misc/PDFIcon_20x20.png'},
      ];
    }, 

    getFilteredSavedReports: async (o) =>{
        return await legacyEndpoints.service({
            name: 'GetFilteredSavedReports',
            parameters: {
                skip: o.skip,
                take: o.take,
                filters: o.filters,
                sorts: o.sorts
            }
        });
    },

    getSavedReportsColumnFilter: async (o) =>{
        return await legacyEndpoints.service({
            name: 'GetSavedReportsColumnFilter',
            parameters: {
                field: o.field,
                skip: o.skip,
                take: o.take,
                searchOperation: o.searchOperation,
                searchValue: o.searchValue,
                filters: o.filters
            }
        });
    },

    deleteSavedReport: async (o) =>{
        const result = await legacyEndpoints.service({
            name: 'DeleteSavedReport',
            parameters: {
                reportId: o.Id
            }
        });

        return result;
    },

    retainSavedReport: async (o) =>{
        const result = await legacyEndpoints.service({
            name: 'RetainSavedReport',
            parameters: {
                reportId: o.Id
            }
        });

        return result;
    },

    getReportCategoryTypeName: (type) => {
        switch (type) {
            default:
                return '';
            case constants.reportCategoryTypes.demographicSummary:
                return translate('demographic_summary');
            case constants.reportCategoryTypes.demographicDetail:
                return translate('demographic_detail');
            case constants.reportCategoryTypes.demographicComparison:
                return translate('demographic_comparison');
            case constants.reportCategoryTypes.demographicIndex:
                return translate('demographic_index');
            case constants.reportCategoryTypes.demographicQuadrant:
                return translate('demographic_quadrant');
            case constants.reportCategoryTypes.pointDetail:
                return translate('point_detail');
            case constants.reportCategoryTypes.management:
                return translate('management_reports');
            case constants.reportCategoryTypes.reportPackages:
                return translate('report_packages');
            case constants.reportCategoryTypes.voidAnalysis:
                return translate('void_analysis');
            case constants.reportCategoryTypes.uberRetail:
                return translate('mobility_report');
            case constants.reportCategoryTypes.marketShare:
                return translate('market_share');
            case constants.reportCategoryTypes.mapBook:
                return translate('map_books');
            case constants.reportCategoryTypes.analyticsProjection:
                return translate('analytics_projection');
        }
    }
};
