// React imports
import { useState } from 'react';

// Third party imports
import List from 'devextreme-react/list';
import CustomStore from 'devextreme/data/custom_store';

// App imports
import { Notification } from './notification';
import { RadioButtonSet } from '../../base/radioButtonSet/radioButtonSet';
import { Button } from '../../base/button/button';
import { Loader } from '../../base/loader/loader';
import { constants } from '../../../utils/constants';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { legacyEndpoints } from '../../../services/legacyEndpoints';

var _ = require("lodash");

export function Notifications({title, categories, activeCategories, updateActiveCategories, noneAvailable, isGlobal, isNewExists, refreshNewNotifications, onClose}) {    

    /*
    const [showOnlyUnread, setShowOnlyUnread] = useState(false);
    <div className='app-notifications-mark-read'>
        <Button icon={icons.eye} tooltip={'Show only unread'} theme='map' size='small' disabled={!isNewExists} onClick={() => { setShowOnlyUnread(!showOnlyUnread); }} />
    </div>
    */

    const [generating, setGenerating] = useState(false);

	var notifications = new CustomStore({		
		load: async (o) => {

            if (generating) 
                return {
                    data: [],
                    totalCount: 0
                };
    
            var result = await legacyEndpoints.service({
                name: 'GetAllFilteredNotificationsForSession',
                parameters: {
                    SessionKey: legacyEndpoints.authenticationToken.TokenInformation.SessionKey,
                    skip: o.skip || 0,
                    take: o.take || 10,
                    isGlobal: isGlobal,
                    notificationTypes: activeCategories
                }
            });		
    
            //if (showOnlyUnread)
            //    notices = notices.filter(item => !item.IsViewed);

            return {
                data: result.Notifications,
                totalCount: result.Notifications.length
            };
		}
	});

	var markNotification = async (o) =>{

        setGenerating(true);

        await legacyEndpoints.service({
            name: 'MarkNotification',
            parameters: {
                Id: o.id,
                Type: o.type,
                IsViewed: o.isViewed
            }
        });

        refreshNewNotifications();
        setGenerating(false);
    };

    var markAllAsRead = () =>{

        setGenerating(true);

        legacyEndpoints.service({
			name: 'GetAllNotificationsForSession',
            parameters: {
                SessionKey: legacyEndpoints.authenticationToken.TokenInformation.SessionKey
            },
			success: (result) =>{

                var notViewed = [];
                result.categories.forEach(category => { 
                    if (_.isArray(category.items)) {
                        const items = category.items.filter(item => !item.isViewed && item.isGlobal == isGlobal);
                        if (items.length > 0) {
                            notViewed = [ ...notViewed, ...items.map(item => { return { 
                                id: item.id,
                                type: category.type
                            }}) ];
                        }
                    }
                });

                notViewed.forEach(item => {
                    legacyEndpoints.service({
                        name: 'MarkNotification',
                        parameters: {
                            Id: item.id,
                            Type: item.type,
                            IsViewed: true
                        }
                    });
                });

                setTimeout(() => {
                    refreshNewNotifications();
                }, 1000);

                setGenerating(false);
            }
		});		
    };

    return <>
        <div className='app-notifications'>
            <div className='app-notifications-title-container'>
                <div className='app-notifications-title'>
                    {title}
                </div>
                <div className='app-notifications-close-button'>
                    <Button className='app-panel-close' theme='action' icon={icons.x} tooltip={translate('close')} onClick={() => { onClose(); }}/>
                </div>
            </div>
            { isGlobal || categories.length === 0 ? '' :
                <div className='app-notifications-actions'>
                    <div className='app-notifications-filters'>
                        <RadioButtonSet>				
                            { 
                                categories.map((category, index) => {
                                    return <Button key={index} icon={category.icon} tooltip={category.name} theme='map'
                                        active={activeCategories.indexOf(category.type) > -1} onClick={() => { 
                                            const cats = [...activeCategories];
                                            if (cats.indexOf(category.type) > -1) {
                                                cats.splice(cats.indexOf(category.type), 1);
                                            } else {
                                                cats.push(category.type);
                                            }
                                            updateActiveCategories(cats);
                                        }} 
                                    />
                                })
                            }
                        </RadioButtonSet>		
                    </div>
                    <div className='app-notifications-mark-read'>
                        <Button icon={icons.check} tooltip={translate('mark_all_as_read')} theme='map' size='small' disabled={!isNewExists} onClick={() => { markAllAsRead(); }} />
                    </div>
                </div>
            }		
            <div className='app-notifications-body'>
                <Loader theme='generate' loaded={!generating} />
                <List
                    keyExpr={'Id'}
                    dataSource={{
                        store: notifications,
                        paginate: true,
                        pageSize: 10
                    }}
                    noDataText={noneAvailable}
                    pageLoadMode={'scrollBottom'}
                    itemRender={(notification) =>{
                        return <Notification key={notification.Id} notification={notification} markNotification={markNotification} />
                    }}
                />          
            </div>            
        </div>
   </>
}