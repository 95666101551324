// Third party imports
import { Chart, Series, Size, Legend as ChartLegend } from 'devextreme-react/chart';

export function BarChart({data,width}) {

    return <Chart
        dataSource={data}
        rotated={true}
    >
        <Series
            valueField={'value'}
            argumentField={'field'}
            type="bar"
            color="#2B328C"
        />
        <Size width={width} />
        <ChartLegend
            verticalAlignment="bottom"
            horizontalAlignment="center"
            itemTextPosition="top"
            visible={false}
        />
    </Chart>   
}