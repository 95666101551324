// React imports
import { useState } from 'react';

// App imports
import { translate } from '../../../../utils/translation';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { helpers } from '../../../../utils/helpers';

export function ThumbNailPhoto({photo,onPhotoClick}){

    const [hover, setHover] = useState(false)

	return (
                <div key={crypto.randomUUID()} className='app-thumb-nail-photo-container' onClick={onPhotoClick} onPointerOver={() => setHover(true)} onPointerOut={() => setHover(false)}>
                    
                    <img className='app-thumb-nail-photo' src={photo.thumbnail} alt={translate('store_photo')} />
                    {hover ? (<div className='app-thumb-nail-photo-hover'>
                                <div className='app-thumb-nail-photo-title'>{photo.title}</div>
                                <div className='app-thumb-nail-photo-date'>{helpers.formatDate({ date: photo.date, format: 'MM/DD/YYYY' })}</div>
                                <div className='app-thumb-nail-photo-user-section'>
                                    <img className='app-thumb-nail-photo-user-image' src={legacyEndpoints.handlers.getPhotoUrl({ userId: photo.userId, isUserPhoto: true, width: 150, height: 150 })} alt={translate('user_image')} />
                                    <div className='app-thumb-nail-photo-user-name'>{photo.userName}</div>
                                </div>
                               </div>) : (<div></div>)}
                </div>
            );
}