// App imports
import { Popover } from '../../../base/popover/popover';
import { Bar } from '../../../base/bar/bar';
import { ColorBox } from 'devextreme-react';
import { Icon, icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation"

const _ = require("lodash");

export function PrintPointSourceLabelPopover({ parentId, title, active = true, pointSource, updatePointSource }) {

    return <>
        { !active || !_.isObject(pointSource) ? '' :
        <Popover 
            parentId={`#${parentId}`}
            title={title}
            height={280}
            width={275}
        >
            <div className='app-projects-print-cols'>
                <div className='app-projects-print-rows'>
                    <div className='app-projects-print-labels'>{`${translate('show_labels')}:`}</div>
                    <div className='app-option-selector'>
                        {pointSource.label === true ? 
                        <div className='app-option-selector-check'>
                            <Icon icon={icons.check} />
                        </div> : ''}
                        <Bar className='app-projects-print-zoom-bar'
                            icon={icons.tag}
                            tooltip={translate('show_labels')}
                            active={pointSource.label === true} 
                            onClick={() => { updatePointSource({ ...pointSource, label: !pointSource.label }); }} 
                        /> 
                    </div>
                </div>
                <div className='app-projects-print-rows'>
                    <ColorBox 
                        label={translate('text_color')} 
                        defaultValue={ _.isObject(pointSource.labelColor) ? `rgba(${pointSource.labelColor.r},${pointSource.labelColor.g},${pointSource.labelColor.b},${pointSource.labelColor.a})` : 'white'} 
                        value={ _.isObject(pointSource.labelColor) ? `rgba(${pointSource.labelColor.r},${pointSource.labelColor.g},${pointSource.labelColor.b},${pointSource.labelColor.a})` : 'white'} 
                        editAlphaChannel={true}
                        onValueChange={(o) => {
                            var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                            updatePointSource({ ...pointSource, labelColor: { r: parseFloat(colors[0]), g: parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) } });
                        }} 
                    />
                </div>
                <div className='app-projects-print-rows'>
                    <ColorBox 
                        label={translate('fill_color')} 
                        defaultValue={ _.isObject(pointSource.labelFillColor) ? `rgba(${pointSource.labelFillColor.r},${pointSource.labelFillColor.g},${pointSource.labelFillColor.b},${pointSource.labelFillColor.a})` : 'white'} 
                        value={ _.isObject(pointSource.labelFillColor) ? `rgba(${pointSource.labelFillColor.r},${pointSource.labelFillColor.g},${pointSource.labelFillColor.b},${pointSource.labelFillColor.a})` : 'black'} 
                        editAlphaChannel={true}
                        onValueChange={(o) => {
                            var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                            updatePointSource({ ...pointSource, labelFillColor: { r: parseFloat(colors[0]), g: parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) } });
                        }} 
                    />
                </div>
           </div>
        </Popover>
        }
    </>
}