// React imports
import { useEffect, useState } from 'react';

// App imports
import { BingMap } from '../../../modules/bing/bing';
import { GoogleMap } from '../../../modules/google/googleMap';
import { mapLocator, MapLocator } from './mapLocator/mapLocator';
import { mapTooltip, MapTooltip } from './mapTooltip/mapTooltip';
import { mapEntityTooltip, MapEntityTooltip } from './mapEntityTooltip/mapEntityTooltip';
import { MapWindow } from './mapWindow/mapWindow';
import { constants } from '../../../utils/constants';
import { MapModeBanner } from './mapModeBanner/mapModeBanner';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { helpers } from '../../../utils/helpers';
import { mapControl } from './mapControl/mapControl';

const _ = require("lodash");

export var map;
export var multiview;

export function Map({provider, onLoad }) {

    const [streetMapVisible, setStreetMapVisible] = useState(false);
    const [aerialMapVisible, setAerialMapVisible] = useState(false);
    const [banner, setBanner] = useState(null);

    useEffect(()=>
    {
        var parameters = {
            center: { lat: 37, lon: -27 },
            zoom: 4, 
            heading: 0,
            tilt: 0,
            onLoad: onLoad
        };

        multiview = {
            streetMap: null,
            aerialMap: null,
            showBiview: () => {
                setStreetMapVisible(true);
                setAerialMapVisible(false);
            },
            showTriview: () => {
                setStreetMapVisible(true);
                setAerialMapVisible(true);
            },
            hide: () => {
                setStreetMapVisible(false);
                setAerialMapVisible(false);
            }
        };
            
        switch(provider)
        {
            default:
            case 'google':
                map = new GoogleMap({ ...parameters, mapType: "map", googleType: constants.map.types.road, mapElement: "map" });
                multiview.streetMap = new GoogleMap({ ...parameters, mapType: "streetview", googleType: -1, mapElement: "street_map", bindMap: map });
                multiview.aerialMap = new GoogleMap({ ...parameters, mapType: "map", googleType: constants.map.types.aerial, mapElement: "aerial_map" });
                break;
            case 'bing':
                map = new BingMap(parameters);
                break;
        }

        map.showLocator = (o) =>{
            mapLocator.show({
                pixel: map.latLonToXY(o.location),
                xOffset: o.xOffset,
                yOffset: o.yOffset
            });
        };
        
        map.showTooltip = (o) =>{
            mapTooltip.show({
                className: o.className,
                pixel: o.pixel,
                title: o.title,
                xOffset: -15,
                yOffset: -15,
                sections: o.sections,
                onHide: o.onHide
            });
        };

        map.hideTooltip = () =>{
            mapTooltip.hide();
        };

        map.showEntityTooltip = (o) =>{
            mapEntityTooltip.show({
                className: o.className,
                pixel: o.pixel,
                title: o.title,
                text: o.text,
                onHide: o.onHide
            });
        };

        map.hideEntityTooltip = () =>{
            mapEntityTooltip.hide();
        };

        map.locate = ({ location, entity, zoom=13, zoomTo=true }) =>{  

            if (helpers.isViewer() && mapControl.isCurrentMapFixed())
                return;

            map.showLocator({
                location: map.center,
                xOffset: _.isObject(entity) && _.isNumber(entity.width) ? entity.width / 2 : 0,
                yOffset: _.isObject(entity) && _.isNumber(entity.height) ? entity.height / 2 : 0
            });

            if (zoomTo === false)
                return;

            map.zoom = map.zoom > zoom ? map.zoom : zoom;
            map.center = _.isObject(entity) ? entity.location : location;
        };

        map.showBanner = (o) =>{
            setBanner({
                title: o.title,
                content: o.content,
                onClose: o.onClose
            })
        };

        map.hideBanner = () =>{
            setBanner(null);
        };

         // Add a listener for console input
         window.addEventListener('message', (event) => {
            if (event.data.command === 'setMapView') {
                const { lat, lng, zoom } = event.data.payload;
                setMapView({ lat, lng, zoom });
            }
        });

    }, []);

     // Function to set the map view
     const setMapView = ({ lat, lng, zoom }) => {
        map.center = { lat, lon: lng };
        map.zoom = zoom;
    };

    return <>
        <div className='app-map-triview-row'>
            <div className='app-map-triview-column'>
                <div id='street_map' className='app-street-map' style={{ display: streetMapVisible ? 'block' : 'none' }}/>
                { streetMapVisible ? <div className='app-map-horizonatal-divider'></div> : '' }
                <div id='map' className={ streetMapVisible ? 'app-map-triview' : 'app-map' } />
            </div>
            { aerialMapVisible ? <div className='app-map-vertical-divider'></div> : '' }
            <div id='aerial_map' className='app-aerial-map' style={{ display: aerialMapVisible ? 'block' : 'none' }}/>
            { !aerialMapVisible && !streetMapVisible ?
                <div className='app-map-logo-container'>
                    <img className="app-map-logo" src={legacyEndpoints.handlers.getLogoUrl()} />
                </div> : null
            }            
        </div>
        <MapLocator />
        <MapTooltip />
        <MapEntityTooltip />
        <MapWindow />
        {banner === null ? '' : <MapModeBanner state={banner} />} 
    </>
}