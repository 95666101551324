// React imports
import { useState } from 'react';

// App imports
import { Button } from "../../../base/button/button";
import { navigation } from "../../navigation/navigation";
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation'

export function MapTabBar() {

	const [collapsed, setCollapsed] = useState(false);
	const [activeSection, setActiveSection] = useState(false);

	navigation.onExpand({
		listener: (o)=>{
			setCollapsed(o.collapsed);
			setActiveSection(o.activeSection);
		}
	});

	var leftClasses = ['app-map-tab-bar'];

	if (collapsed)
		leftClasses.push('app-map-tab-bar-collapsed');
	else if (activeSection)
		leftClasses.push('app-map-tab-bar-active');

    return <>
		<div className={leftClasses.join(' ')}>
			<Button
				className='app-map-tab-bar-tab'
				theme='tab'
				icon={icons.mapLocation}
				text={translate('new_map')}
				active={true}				
				actions={[{icon: icons.x, disabled: true }]}
			/>
			<Button theme='simple' icon={icons.plus} tooltip={translate('add_workspace')} disabled={true} />
		</div>
	</>
}