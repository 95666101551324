// App imports
import { map } from '../components/app/map/map';
import { activityHub } from '../components/app/activityHub/activityHub';
import { layerActions } from '../components/app/layers/layer/layer';
import { constants } from '../utils/constants';
import { helpers } from '../utils/helpers';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { translate } from '../utils/translation';
import { userPreferences } from '../components/app/app';
import { InfoForm } from '../components/app/forms/infoForm/infoForm';
import { ShapeEdit } from '../components/app/shapeEdit/shapeEdit';
import { ProjectionAddForm } from '../components/app/projections/projectionAddForm';
import { AddForm } from '../components/app/forms/addForm/addForm';
import { icons } from '../components/base/icon/icon';
import { ShapeDownloader } from '../components/app/shapeDownloader/shapeDownloader';
import { selections } from './selections';
import { standardGeographies } from './standardGeographies';
import { Loader } from '../components/base/loader/loader';
import { tradeAreas } from './tradeAreas';
import { workBench } from '../components/base/workBench/workBench';
import { CompetitiveInsightsReportObject } from '../components/app/competitiveInsightsReportObject/competitiveInsightsReportObject';
import { quickReports } from '../components/app/quickReports/quickReports';

const _ = require("lodash");

//TODO: Split into a map tools file
export const cosmetic = {
    merging: false,
    entitiesToMerge: [],
    create: (o) => {
        var cosmeticLayer = map.addLayer({
			text: translate('cosmetic_layer'),
			group: constants.layers.groups.cosmetic,
			type: constants.layers.types.cosmetic,
			actions: [{
				id: layerActions.visible,
				getActive: () => {
					return cosmeticLayer.visible;
				},
				onClick: () => {
					cosmeticLayer.visible = !cosmeticLayer.visible;
				}
			},
			{
				id: layerActions.clear,
				onClick: () =>{
					cosmeticLayer.clear();
                    selections.refresh();
				}
			}],
            onChange: (c)=>{                
                o.onRefresh({
                    layers: [cosmeticLayer]
                });
			}
		});

        map.addLayer({
			text: translate('temporary_layer'),
			group: constants.layers.groups.temporary,
			type: constants.layers.types.temporary
		});

        o.onRefresh({
            layers: [cosmeticLayer]
        });
    },
    split: (entity) =>{
        entity.split();
    },
    getPushpinActions: async (o) =>{
        return _.isObject(o.location) ? 
            await legacyEndpoints.service({ 
                name: 'GetCosmeticActionsWithLocation',
                parameters: {
                    latitude: o.location.lat,
                    longitude: o.location.lon
                } 
            }) 
        :
            await legacyEndpoints.service({ name: 'GetCosmeticActions' });
    },
    createPoi: (o) => {

        const getPoiVisibilty = (poi) =>{ return layer.oppositeVisibilityEntities.indexOf(poi) > -1 ? !layer.visible : layer.visible; };

        var layer = map.addLayer({
            text: translate('points_of_interest'),
            group: constants.layers.groups.poi,
            type: constants.layers.types.poi,
            visible: true,
            actions: [{
                id: layerActions.visible,
                getActive: () => {
                    return layer.visible;
                },
                onClick: () => {
                    layer.visible = !layer.visible;
                    map.togglePois(map.getPoiKeys().map(poi => { return { key: poi, visible: layer.visible } }));
                }
            }],
            legend: map.getPoiKeys().map(poi =>{
                return {
                    icon: map.pois[poi].icon,
                    iconWidth: 28,
                    iconClass: 'app-legend-layer-chain-icon',
                    text: map.pois[poi].text,
                    actions: [{
                        id: layerActions.visible,
                        getActive: () => {
                            return getPoiVisibilty(poi);
                        },
                        onClick: () =>{

                            if (layer.oppositeVisibilityEntities.indexOf(poi) > -1)
                                layer.oppositeVisibilityEntities = layer.oppositeVisibilityEntities.filter(x => x !== poi);
                            else
                                layer.oppositeVisibilityEntities.push(poi);                            

                            map.togglePois([{ key: poi, visible: getPoiVisibilty(poi) }]);

                            o.onRefresh({ layers: [layer] });
                        }
                    }]
                }
            }),
            onChange: ()=>{
                o.onRefresh({ layers: [layer] });
			}
        });

        o.onRefresh({
            layers: [layer]
        });
    },
    getLayer: () =>{
		return map.layers.find(layer => layer.type === constants.layers.types.cosmetic);
	},
    refresh: (o) => {

        var layer = cosmetic.getLayer();

        if (_.isObject(o)) {
            if (_.isArray(o.shapes) && o.shapes.length > 0) {
                layer.clear();

                o.shapes.forEach(shape => {
                    cosmetic.createEntity(shape);
                });
            }   

            layer.visible = !_.isUndefined(o.isVisible) ? o.isVisible : layer.visible;
        }

        selections.refresh();        
        quickReports.update();
        
        layer.legend = layer.entities.map(entity => {
            var image = null;
            var color = null;

            switch(entity.type){
                case constants.entities.pushpin:
                    image = entity.image;
                    break;
                default:
                    color = entity.strokeColor;
                    break;
            }

            return {
                text: entity.text,
                icon: image,
                color: color,
                actions: [{
                    id: layerActions.zoom,
                    onClick: () =>{
                        map.locate({ entity: entity });
                    }
                },
                {
                    id: layerActions.delete,
                    onClick: () =>{
                        entity.dispose();
                        cosmetic.refresh();                        
                    }
                }]
            };
        });
    },
    geocode: (o) => {

        map.geocode({
            query: `${o.location.lat} ${o.location.lon}`,
            callback: (result) => {

                var tempLayer = map.layers.find(layer => layer.type === constants.layers.types.temporary);
                tempLayer.clear();
                cosmetic.createGeocodePushpin({ location: o.location, temporary: true });

                map.showTooltip({
                    pixel: map.latLonToXY(o.location),
                    title: translate('results'),
                    sections: [<>
                        <div>{`${o.location.lat.toFixed(6)}, ${o.location.lon.toFixed(6)}`}</div>
                        <div>{`${result.address.full}`}</div>
                    </>],
                    onHide: () =>{
                        tempLayer.clear();
                    }
                });

                map.setCursor({ cursor: ''});
            }
        });
        
    },       
    addPushpin: (o) =>{        
        cosmetic.createPushpin({
            location: o.location,
            symbol: legacyEndpoints.handlers.getSymbolUrl({ imageUrl: userPreferences.DefaultPushpinSymbol })
        });
    },
    thematicInformation: (o) =>{

        var temporaryLayer = map.layers.find(layer => layer.type === constants.layers.types.temporary);
        temporaryLayer.clear();

        legacyEndpoints.service({
            name: 'GetThemeAndCustomermapPointInformation',
            parameters: {
                aThemeID: o.id,
                latitude: o.location.lat,
                longitude: o.location.lon,
                zoom: map.zoom,
                IsCustomerMap: false,
                Data: []
            },
            success: (information) =>{

                temporaryLayer.addEntity({
                    type: constants.entities.polygon,
                    paths: helpers.decodeLocations(information.EncodedPointsList[0]),
                    fillColor: { r: information.R, g: information.G, b: information.B, a: (information.A / 255) }
                });

                map.showTooltip({
                    title: translate('information'),
                    pixel: map.latLonToXY(o.location),
                    sections: [<>
                        <div>{`${translate('id')}: ${information.Id}`}</div>
                        <div>{`${information.label}: ${helpers.formatNumber(information.value)}`}</div>
                    </> ],
                    onHide: () =>{
                        temporaryLayer.clear();
                    }
                });
            }
        });
    },
    locate: async () =>{

        var position = await (() => {
            return new Promise((success, error) => {
                navigator.geolocation.getCurrentPosition(
                    success,
                    error,
                    {
                        timeout: 5000,
                        enableHighAccuracy: false
                    }
                );
            });
        })();

        map.center = { lat: position.coords.latitude, lon: position.coords.longitude };

        var symbolDimensions = helpers.getDimensionsFromSymbolUrl(legacyEndpoints.handlers.getSymbolUrl({ imageUrl: userPreferences.DefaultPushpinSymbol }));

        var entity = cosmetic.getLayer().addEntity({
            text: translate('current_location'),
            type: constants.entities.pushpin,
            location: map.center,
            image: legacyEndpoints.handlers.getSymbolUrl({ imageUrl: userPreferences.DefaultPushpinSymbol }),
            width: symbolDimensions.width,
            height: symbolDimensions.height,
            onClick: () =>{
                map.locate({ entity: entity });
            },
            listeners: [{
                type: constants.listeners.click,
                action: (e) =>{
                    cosmetic.showActivityHub(e.entity);
                }
            }]
        });

        map.locate({ entity: entity });

        cosmetic.refresh();
    },
    createPushpin: (o) =>{

        var layer = o.temporary ? map.layers.find(layer => layer.type === constants.layers.types.temporary) : cosmetic.getLayer();
        
        var symbolDimensions = helpers.getDimensionsFromSymbolUrl(o.symbol);
    
        var entity = layer.addEntity({
            text: `${translate('pushpin')} ${cosmetic.getNextNumber({ name: translate('pushpin'), type: constants.entities.pushpin })}`,
            type: constants.entities.pushpin,
            location: o.location,
            image: o.symbol,
            width: symbolDimensions.width,
            height: symbolDimensions.height,
            onClick: () =>{
                map.locate({ entity: entity });
            },
            listeners: [{
                type: constants.listeners.click,
                action: (e) =>{
                    cosmetic.showActivityHub(e.entity);
                }
            }]
        });
        cosmetic.refresh();
        return entity;
    },
    createGeocodePushpin: (o) =>{
        return cosmetic.createPushpin({
            location: o.location,
            temporary: o.temporary,
            symbol: legacyEndpoints.handlers.getSymbolUrl({ imageUrl: userPreferences.DefaultGeocodeSymbol })
        });
    },
    getNextNumber: (o) =>{
        var largestNumber = 1;

        cosmetic.getLayer().entities.filter(x => x.type === o.type).forEach(entity =>{
            var name = parseInt(entity.text.replace(o.name, ''));
            if (_.isNumber(name) && largestNumber <= name)
                largestNumber = name + 1;
        });

        return largestNumber;
    },
    showActivityHub: (entity)=>{

        if (selections.active && entity.type === constants.entities.pushpin){
			selections.selectEntity(entity);
			return;
		}

        if (cosmetic.merging) {
            cosmetic.entitiesToMerge.push(entity);
            return;
        }

        activityHub.open({
            entity: entity,
            type: 'cosmetic',
            renderContent: async () =>{

                var tabs = [
                    { id: constants.activityHub.actions.information, component: <InfoForm form={cosmetic.getInformation(entity)} /> },
                    { id: constants.activityHub.actions.add, component: <AddForm entity={entity} onClose={() => {activityHub.close();}} /> },
                    { 
                        id: constants.activityHub.actions.delete, 
                        onClick: () => {                            
                            if (_.isFunction(entity.onDelete)) entity.onDelete();

                            entity.dispose();
                            activityHub.close();
                            cosmetic.refresh();
                        } 
                    }
                ];

                if (userPreferences.AllowMapBookManagement)	
					tabs = [...tabs, { id: constants.activityHub.actions.mapBooks, component: <div></div> }];

                switch(entity.type)
                {
                    default:
                        return []
                    case constants.entities.pushpin:
                        var actionButtons = await cosmetic.getPushpinActions({ location: entity.location });
                        return [...tabs,
                            { id: constants.activityHub.actions.tradeAreas },
                            { id: constants.activityHub.actions.drivingDirections },
                            { id: constants.activityHub.actions.edit, component: <div></div> },    
                            { id: constants.activityHub.actions.move, onClick: ()=>{ entity.move(); activityHub.close(); } },                    
                            ...actionButtons.ActionButtons.map(action => {
                                switch(action.Id) {
                                    default:
                                        return {  
                                            id: constants.activityHub.actions.customActionButton,
                                            customButton: action
                                        }; 
                                    case constants.activityHub.actions.trip2Trade:
                                        return { };
                                    case constants.activityHub.actions.projections:
                                        return { id: action.Id, items: action.Items
                                            .filter(item => item.Parameters.length > 0)
                                            .map(item => {return { id: `${action.Id}_${item.Id}`, text: item.Text, component: <ProjectionAddForm projection={item.Parameters[0].Value} entity={entity} /> }})};
                                }
                            })
                        ];
                    case constants.entities.polyline:
                        return [...tabs];
                    case constants.entities.circle:
                    case constants.entities.polygon:
                    case constants.entities.rectangle:

                        //TODO: consider geofence.showActivityHub()
                        if (entity.metaData.isCompetitiveInsights === true) {
                            return [...tabs,                            
                                { id: constants.activityHub.actions.select },
                                { id: constants.activityHub.actions.demographics },
                                { id: constants.activityHub.actions.download, component: <ShapeDownloader entity={entity} /> },
                                { id: constants.activityHub.actions.reportIncorrectCompetitiveInsightsObject, component: <CompetitiveInsightsReportObject type={constants.competitiveInsights.reportedObjectTypes.geoFence} entity={entity} onClose={()=>{activityHub.close();}} /> }
                            ];
                        }

                        return [...tabs,                            
                            { id: constants.activityHub.actions.edit, component: <ShapeEdit entity={entity} />},
                            { id: constants.activityHub.actions.move, onClick: ()=>{ entity.move(); activityHub.close(); } },
                            { id: constants.activityHub.actions.reshape, onClick: ()=>{ entity.reshape(); activityHub.close();} },
                            { id: constants.activityHub.actions.select },
                            { id: constants.activityHub.actions.demographics },
                            { id: constants.activityHub.actions.download, component: <ShapeDownloader entity={entity} /> }
                        ];
                    case constants.entities.wkt:
                        return [...tabs,
                            { id: constants.activityHub.actions.download, component: <ShapeDownloader entity={entity} /> }
                        ];
                    case constants.entities.standardGeography:
                        return [
                            { id: constants.activityHub.actions.information, component: <InfoForm form={cosmetic.getInformation(entity)} /> },
                            { id: constants.activityHub.actions.edit, component: <ShapeEdit entity={entity} />},
                            { 
                                id: constants.activityHub.actions.delete, 
                                onClick: () => {                            
                                    if (_.isFunction(entity.onDelete)) entity.onDelete();
        
                                    entity.dispose();
                                    activityHub.close();
                                    cosmetic.refresh();
                                } 
                            },
                            { id: constants.activityHub.actions.download, component: <ShapeDownloader entity={entity} isGeometryCollection={true} /> }
                        ];
                }
            }
        });
    },
    getInformation: (entity) =>{
        return {
            TabsActive: true,
            TabDisplay: constants.forms.tabDisplays.imageAndText,
            Tabs: [{
                Title: translate('information'),
                Pages:[{
                    Sections: [{
                        Title: translate('information'),
                        Rows: (() =>{

                            var locationLabel = null;
                            var addressLabel = null;
                            var addressValue = '';
                            var lengthLabel = null;
                            var showAddress = false;
                            var showArea = false;
                            var showRadius = false;
                            var showPerimeter = false;

                            switch(entity.type){
                                default:
                                    break;
                                case constants.entities.pushpin:
                                    locationLabel = translate('location');
                                    addressLabel = translate('address');
                                    addressValue = entity.address;
                                    showAddress = true;
                                    break;
                                case constants.entities.polyline:
                                    lengthLabel = translate('length');
                                    locationLabel = translate('center');
                                    showPerimeter = true;
                                    break;
                                case constants.entities.circle:
                                case constants.entities.polygon:
                                case constants.entities.rectangle:
                                    lengthLabel = translate('perimeter');
                                    locationLabel = translate('center');                                    
                                    showArea = true;

                                    showPerimeter = true;

                                    if (entity.type !== constants.entities.circle)
                                        break;

                                    showRadius = true;
                                    lengthLabel = translate('circumference');

                                    break;
                            }

                            const getField = (o) => {
                                return {
                                    Label: o.label ? o.label : '',
                                    LabelStyle: { Bold: true, Size: 11 },
                                    ValueStyle: { Bold: _.isBoolean(o.labelValue) && o.labelValue, Size: 11 },
                                    ShowLabel: _.isBoolean(o.showLabel) && o.showLabel,
                                    Span: _.isNumber(o.span) ? o.span : 1,
                                    Merged: false,
                                    RenderedValue: { value: o.value }
                                };
                            };

                            var labels = [];

                            if (showArea)
                                labels.push(getField({ value: translate('area'), labelValue: true }));

                            if (showPerimeter)
                                labels.push(getField({ value: lengthLabel, labelValue: true }));

                            if (showRadius)
                                labels.push(getField({ value: translate('radius'), labelValue: true }));

                            return [{
                                    Fields: [getField({ showLabel: true, span: 2, label: translate('description'), value: entity.description.length === 0 ? translate('no_description') : entity.description }), { Merged: true }, { Merged: true }]
                                },
                                ...[showAddress && addressValue?.length > 0 ? {Fields: [getField({ showLabel: true, span: 2, label: addressLabel, value: addressValue })]} : null],
                                {
                                    Fields: [getField({ showLabel: true, span: 2, label: locationLabel, value: `${entity.location.lat.toFixed(6)}, ${entity.location.lon.toFixed(6)}` })]
                                },
                                {
                                    Fields: labels
                                },
                                ...[
                                    { length: { type: constants.lengthMeasurements.miles, label: 'mi' }, area: { type: constants.areaMeasurements.squareMiles, label: 'mi²' }},
                                    { length: { type: constants.lengthMeasurements.kilometers, label: 'km' }, area: { type: constants.areaMeasurements.squareKilometers, label: 'km²' }},
                                    { length: { type: constants.lengthMeasurements.meters, label: 'm' }, area: { type: constants.areaMeasurements.squareMeters, label: 'm²' }},
                                    { length: { type: constants.lengthMeasurements.feet, label: 'ft' }, area: { type: constants.areaMeasurements.squareFeet, label: 'ft²' }},
                                    { area: { type: constants.areaMeasurements.acres, label: 'ac' }}
                                ].map((calculation) => {

                                    var fields = [];

                                    if (calculation.area && showArea)
                                        fields = [...fields, getField({ value: `${helpers.formatNumber(helpers.convertArea(entity.area, constants.areaMeasurements.squareMeters, calculation.area.type).toFixed(2))} ${calculation.area.label}` })];

                                    if (calculation.length && showPerimeter)
                                        fields = [...fields, getField({ value: `${helpers.formatNumber(helpers.convertLength(entity.length, constants.lengthMeasurements.meters, calculation.length.type).toFixed(2))} ${calculation.length.label}` })];

                                    if (calculation.length && showRadius)
                                        fields = [...fields, getField({ value: `${helpers.formatNumber(helpers.convertLength(entity.radius, constants.lengthMeasurements.meters, calculation.length.type).toFixed(2))} ${calculation.length.label}` })];

                                    return {
                                        Fields: fields
                                    };
                                })
                            ].filter(x => x !== null);
                        })()
                    }]
                }]
            }]
        }
    },
    createEntity: (o) =>{

        var entity;

        o.id = _.isString(o.id) ? o.id : helpers.newGuid();

        if (o.type === constants.entities.pushpin) {

            const symbolDimensions = helpers.getDimensionsFromSymbolUrl(o.symbol);

            entity = cosmetic.getLayer().addEntity({
                id: o.id,
                text: o.name,
                type: o.type,
                location: o.location,
                image: o.symbol,
                width: symbolDimensions.width,
                height: symbolDimensions.height,
                onClick: () =>{
                    map.locate({ entity: entity });
                },
                onEdit: () =>{
                    cosmetic.refresh();
                },
                listeners: [{
                    type: constants.listeners.click,
                    action: (e) =>{
                        cosmetic.showActivityHub(e.entity);
                    }
                }]
            });

        }
        else {

            const fillColor = o.fillColor ? o.fillColor : constants.polygons.defaults.fillColor;
            const strokeColor = o.strokeColor ? o.strokeColor : constants.polygons.defaults.strokeColor;
            const strokeWidth = o.strokeWidth ? o.strokeWidth : constants.polygons.defaults.strokeWidth;

            entity = cosmetic.getLayer().addEntity({
                id: o.id,
                text: o.name,
                type: o.type,
                paths: o.locations,
                fillColor: { r: fillColor.r, g: fillColor.g, b: fillColor.b, a: fillColor.a },
                strokeColor: { r: strokeColor.r, g: strokeColor.g, b: strokeColor.b, a: strokeColor.a },
                strokeWidth: strokeWidth,
                listeners: [{
                    type: constants.listeners.click,
                    action: (e) =>{
                        cosmetic.showActivityHub(e.entity);
                    }
                }],
                onEdit: () =>{
                    cosmetic.refresh();
                },
                onDelete: () =>{
                    entity.dispose();
                }
            });
        }

        return entity;
    },
    draw: (o) =>{
        
        map.startDrawing({
            type: o.type,
            clearTooltipOnFinish: _.isBoolean(o.createEntity) && o.createEntity,
            onTooltipRender: (result) =>{

                switch(o.type)
                {
                    default:
                        if (result.locations.length < 3)
                            return {
                                sections: [<div>{translate('add_at_least_3_vertices')}</div>]
                            };

                        var areaMeters = map.computeArea({ locations: result.locations });

                        return { sections: [<>
                            <div>{`${helpers.formatNumber(helpers.convertArea(areaMeters, constants.areaMeasurements.squareMeters, constants.areaMeasurements.squareMiles).toFixed(2))} mi²`}</div>
                            <div>{`${helpers.formatNumber(helpers.convertArea(areaMeters, constants.areaMeasurements.squareMeters, constants.areaMeasurements.squareKilometers).toFixed(2))} km²`}</div>
                            <div>{`${helpers.formatNumber(helpers.convertArea(areaMeters, constants.areaMeasurements.squareMeters, constants.areaMeasurements.acres).toFixed(2))} ac`}</div>
                            <div>{`${helpers.formatNumber(areaMeters.toFixed(2))} m²`}</div>
                            <div>{`${helpers.formatNumber(helpers.convertArea(areaMeters, constants.areaMeasurements.squareMeters, constants.areaMeasurements.squareFeet).toFixed(2))} ft²`}</div>
                        </>] };
                    case constants.entities.polyline:
                        if (result.locations.length < 2)
                            return {
                                sections: [<div>{translate('add_at_least_2_vertices')}</div>]
                            };

                        var distanceMeters = map.computeLength({ locations: result.locations });

                        return { sections: [<>
                            <div>{`${helpers.formatNumber(helpers.convertLength(distanceMeters, constants.lengthMeasurements.meters, constants.lengthMeasurements.miles).toFixed(2))} mi`}</div>
                            <div>{`${helpers.formatNumber(helpers.convertLength(distanceMeters, constants.lengthMeasurements.meters, constants.lengthMeasurements.kilometers).toFixed(2))} km`}</div>
                            <div>{`${helpers.formatNumber(distanceMeters.toFixed(2))} m`}</div>
                            <div>{`${helpers.formatNumber(helpers.convertLength(distanceMeters, constants.lengthMeasurements.meters, constants.lengthMeasurements.feet).toFixed(2))} ft`}</div>
                        </>] };
                }
            },
            onFinish: (shape) =>{

                if (!o.createEntity)
                    return;
                
                var fillColor = _.isObject(o.fillColor) ? o.fillColor : helpers.randomColor();
                var strokeColor = _.isObject(o.strokeColor) ? o.strokeColor : _.cloneDeep(fillColor);
                
                fillColor.a = 0.3;
                strokeColor.a = 0.7;

                var entity = cosmetic.createEntity({ 
                    name: `${o.name} ${cosmetic.getNextNumber(o)}`, 
                    type: o.type, 
                    locations: shape.locations, 
                    fillColor: fillColor, 
                    strokeColor: strokeColor,
                    strokeWidth: constants.polygons.defaults.strokeWidth
                });

                if (o.labelResult)
                {
                    var label = '';
                    var location = { x: 0, y: 0 };

                    switch(o.type)
                    {
                        default:         
                        
                            location = helpers.getNorthernLocation(shape.locations);

                            var areaMeters = map.computeArea({ locations: shape.locations });
                            label = `${helpers.formatNumber(areaMeters.toFixed(2))} m² \r\n
                                    ${helpers.formatNumber(helpers.convertArea(areaMeters, constants.areaMeasurements.squareMeters, constants.areaMeasurements.squareFeet).toFixed(2))} ft²`;
                            break;
                        case constants.entities.polyline:                     
                        
                            location = helpers.getMidpoint(shape.locations[0], shape.locations[shape.locations.length - 1]);

                            var distanceMeters = map.computeLength({ locations: shape.locations });
                            label = `${helpers.formatNumber(helpers.convertLength(distanceMeters, constants.lengthMeasurements.meters, constants.lengthMeasurements.miles).toFixed(2))} mi \r\n
                                    ${helpers.formatNumber(helpers.convertLength(distanceMeters, constants.lengthMeasurements.meters, constants.lengthMeasurements.kilometers).toFixed(2))} km`;
                            break;
                    }

                    var svgLabel = helpers.createSvgLabel({ 
                        text: label,
                        parentWidth: 10,
                        parentHeight: 10,
                        padding: 5,
                        lineSpacingOffset: 0,
                        label: {
                            position: constants.labelPosition.middleMiddle,
                            style: {
                                color: { r: 255, g: 255, b: 255, a: 1 },
                                background: { r: 0, g: 0, b: 0, a: 1 },
                                fontSize: 8,
                                bold: true,
                                italic: false,
                                underline: false,
                                textAlign: constants.textAlignment.center          
                            }
                        }
                    });

                    cosmetic.getLayer().addEntity({ 
                        type: constants.entities.label, 
                        location: location,
                        image: svgLabel.icon,
                        anchor: svgLabel.anchor,
                        draggable: false,
                        metaData: { parentId: entity.id }
                    });
                }                    

                cosmetic.refresh();
                
            }
        });
    },
    mergeShapes: () =>{

        cosmetic.merging = true;
        cosmetic.entitiesToMerge = [];

        map.addListener({
            type: constants.listeners.rightClick,
            executeOnce: true,
            action: (e) => {
                var result = map.mergeShapes({ entities: cosmetic.entitiesToMerge });
                console.log(result);
                
                cosmetic.entitiesToMerge = [];
                cosmetic.merging = false;  
            }
        });        
    },
    measureArea: () =>{
        cosmetic.draw({
            name: translate('polygon'),
            type: constants.entities.polygon,
            createEntity: true,
            labelResult: true
        });
    },
    measureDistance: () =>{
        cosmetic.draw({
            name: translate('line'),
            type: constants.entities.polyline,
            createEntity: true,
            fillColor: constants.polygons.defaults.fillColor,
            strokeColor: constants.polygons.defaults.strokeColor,
            labelResult: true
        });
    },
    drawLine: ()=>{
        cosmetic.draw({
            name: translate('line'),
            type: constants.entities.polyline,
            createEntity: true,
            fillColor: constants.polygons.defaults.fillColor,
            strokeColor: constants.polygons.defaults.strokeColor
        });
    },
    drawPolygon: ()=>{
        cosmetic.draw({
            name: translate('polygon'),
            type: constants.entities.polygon,
            createEntity: true
        });
    },
    drawCircle: ()=>{
        cosmetic.draw({
            name: translate('circle'),
            type: constants.entities.circle,
            createEntity: true
        });
    },
    drawRectangle: ()=>{
        cosmetic.draw({
            name: translate('rectangle'),
            type: constants.entities.rectangle,
            createEntity: true
        });
    },
    drawGeography: async () =>{

        var title = `${translate('geography')} ${cosmetic.getNextNumber({ name: translate('geography'), type: constants.entities.standardGeography })}`;

        const result = workBench.setContent({ 
            title: title,
            content: <Loader />,
            height: '700px',
            maxHeight: '1000px',
            startMinimized: true
        });

        if (!result)
            return;

        var defaults = await tradeAreas.getDefaults();

        standardGeographies.open({
            title: title,
            tradeArea: defaults.defaultTradeArea,
            vintages: defaults.standardGeographies,
            onSave: (o) =>{
                cosmetic.getLayer().addEntity({
                    text: title,
                    type: constants.entities.standardGeography,
                    paths: helpers.parseWkt(o.wkt),
                    fillColor: constants.polygons.defaults.fillColor,
                    strokeColor: constants.polygons.defaults.strokeColor,
                    strokeWidth: constants.polygons.defaults.strokeWidth,
                    metaData: { 
                        geoLevelId: o.geoLevelId, 
                        geographyIds: o.ids, 
                        vintageId: o.vintageId,
                        wkt: o.wkt
                    },
                    listeners: [{
                        type: constants.listeners.click,
                        action: (e) =>{ cosmetic.showActivityHub(e.entity); }
                    }]
                });

                cosmetic.refresh();
            }
        });
    },
    downloadShape: (o)=>{

        var polygonString = null;
        var polygonStringType = null;

        switch(o.entity.type){
            case constants.entities.standardGeography:
                polygonString = o.entity.metaData.wkt;
                polygonStringType = constants.encodedString.wkt;
                break;
            default:
                polygonString = helpers.encodedLocations(o.entity.paths);
                polygonStringType = constants.encodedString.google;
                break;
        };

        legacyEndpoints.service({
            name: 'DownloadShape',
            parameters: {
                Name: o.name,
                DownloadFileType: o.downloadDataType,
                PolygonString: polygonString,
                PolygonStringType: polygonStringType,
                DownloadType: o.downloadOption
            },
            success: (r) => {
                helpers.navigateToUrl(legacyEndpoints.handlers.getFileUrl({ id: r }));

                if (_.isFunction(o.callback))
                    o.callback();
            },
            error: () => {
                if (_.isFunction(o.callback))
                    o.callback();
            }
        });
    }
};