import { legacyEndpoints } from '../services/legacyEndpoints';

const _ = require("lodash");

export const multimedia = {    
    getMultimedia: (o) =>{
        return legacyEndpoints.service({
            name: 'GetFilteredMultimedia',
            parameters: {
                SourceId : o.sourceId,
                DataSourceId: o.dataSourceId,
                ManagerType: 0,
                Columns: 'ContentTypeExtension,Name,DateModified,UserName',
                Page: 0,
                ResultCount: 10,
                Filter: '',
                SortColumn: 1,
                SortDirection: 'asc'
            }
        });
    },        
    deleteMultimedia: async (o) =>{
        return await legacyEndpoints.service({
            name: 'DeleteMultimediaFile',
            parameters: {
                Id: o.id,
                SourceId : o.sourceId,
                DataSourceId: o.dataSourceId,
                ManagerType: o.managerType            
            }
        });
    }, 
    updateMultimedia: async (o) =>{
        return await legacyEndpoints.service({
            name: 'UpdateMultimediaFileWithAdditionalData',
            parameters: {
                Id: o.id,
                Name: o.name,
                Description: o.description         
            }
        });
    },    
    addURL: async (o) =>{
        return await legacyEndpoints.service({
            name: 'AddMultimediaURLWithAdditionalData',
            parameters: {
                SourceId : o.sourceId,
                DataSourceId: o.dataSourceId,
                ManagerType: o.managerType,
                Name: o.name,
                Description: o.description,
                URL: o.url              
            }
        });
    },             
    
};