import { useEffect, useState } from 'react';

import { translate } from "../../../../utils/translation";
import { Panel } from "../../../base/panel/panel";
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { constants } from '../../../../utils/constants';
import { helpers } from '../../../../utils/helpers';
import { map } from '../../map/map';
import { icons } from '../../../base/icon/icon';
import { Button } from '../../../base/button/button';
import { tradeAreas } from '../../../../modules/tradeAreas';
import { DropDown } from '../../../base/dropDown/dropDown';
import { NumberBox } from '../../../base/numberBox/numberBox';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { hotSpotAnalysis } from '../../../../modules/hotSpotAnalysis';
import { navigation } from '../../navigation/navigation';

const _ = require("lodash");

export function HotSpotAnalysis({onClose}){
    
    const [loaded, setLoaded] = useState(false);
    const [geoLevel, setGeoLevel] = useState(null);
    const [geoLevels, setGeoLevels] = useState([]);
    const [availableFields, setAvailableFields] = useState([]);
    const [fields, setFields] = useState([]);
    const [generating, setGenerating] = useState(false);

    useEffect(() =>{
        (async () =>{
            
            var defaults = await tradeAreas.getDefaults();
            
            var geoLevels = defaults.standardGeographies.map(vintage => { 
                return vintage.geoLevels.map(geoLevel => { 
                    geoLevel.group = vintage.name;
                    return geoLevel; 
                });
            }).flat();
            
            setGeoLevel(geoLevels[0]);
            setGeoLevels(geoLevels);

        })();

    },[]);

    useEffect(() =>{
        (async () =>{
            
            if (geoLevel == null)
                return;

            setLoaded(false);

            var fields = await legacyEndpoints.service({
                name: 'GetReportFieldsForUser',
                parameters: {
                    geographyVintageId: geoLevel.GeographyVintageId
                }
            });
            setLoaded(true);
            setFields([]);
            setAvailableFields(fields.map(x => { x.group = `${x.supplierDatabase} - ${x.supplier}`; return x;}));

        })();

    }, [geoLevel]);

    const addField = () =>{
        setFields([...fields, { key: helpers.newGuid(), id: availableFields[0].id, weight: 1 }]);
    };

    const mapLayer = async () =>{

        setGenerating(true);

        await hotSpotAnalysis.refresh({
            id: helpers.newGuid(),
            geographyVintageId: geoLevel.GeographyVintageId,
            geoLevelId: geoLevel.Id,
            geoLevelName: geoLevel.Name,
            optimalMaxZoom: geoLevel.OptimalMaxZoom,
            fields: fields
        });

        setGenerating(false);

        onClose();
        navigation.forceExpand({ section: constants.navigation.sections.layers });
    };

	return <Panel text={translate('hot_spot_analysis')} open={true} loaded={loaded} generated={!generating} onClose={()=>{onClose()}}>
        <div className='app-hot-spot-header'>
            <Button theme='secondary' size={'medium'} icon={icons.x} onClick={()=>{onClose()}} />
            <Button theme='primary' size={'medium'} icon={icons.check} disabled={fields.length === 0} onClick={()=>{mapLayer()}} />
        </div>
        <div className='app-hot-spot-body'>
            <DropDown                
                grouped={true}
                display='Name'
                selected={geoLevel} 
                label={translate('geography')}
                items={geoLevels}
                onChange={(o) => {
                    setGeoLevel(o.value);
                }}
            />            
            <div className='app-hot-spot-fields'>
                <div className='app-hot-spot-fields-header'>
                    <Button theme='secondary' size='small' icon={icons.plus} onClick={()=>{addField()}} />
                </div>
                <div className='app-hot-spot-fields-body'>
                {fields.length === 0 ? <div className='app-hot-spot-fields-body-empty'>{translate('add_fields')}</div> : ''}
                {
                    fields.map((field) =>{
                        return <div key={field.key} className='app-hot-spot-fields-row'>
                            <DropDown
                                className='app-hot-spot-fields-drop-down'                
                                grouped={true}                                
                                searchEnabled={true}
                                display='description'
                                valueProperty="id"
                                selected={field.id} 
                                label={translate('field')}
                                items={availableFields}
                                onChange={(o) => {
                                    field.id = o.value;
                                    setFields([...fields]);
                                }}
                            />
                            <NumberBox
                                className='app-hot-spot-fields-weight' 
                                label={translate('weight')}
                                value={field.weight}
                                onChange={(o) => {
                                    field.weight = o.value;
                                    setFields([...fields]);
                                }}
                            />
                            <Button className='app-hot-spot-fields-delete'  theme='simple' icon={icons.trash} onClick={() =>{
                                setFields([...fields.filter(x => x !== field)]);
                            }} />
                        </div>;
                    })
                }
                </div>
            </div>
        </div>        
    </Panel>
}