// React imports
import { useState, useEffect } from 'react';

// App imports
import { Bar } from '../../../base/bar/bar';
import { Icon } from '../../../base/icon/icon';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";

const _ = require("lodash");

export function MobilityReportsStep3({reportTypes, reportType, tradeAreas, tradeArea, dataSources, dataSource, timeOfDay, jobList, reportTitle, outputFormat, onEditStep}) {

    const [reportTypeText, setReportTypeText] = useState('');
    const [tradeAreaText, setTradeAreaText] = useState('');
    const [timeOfDayText, setTimeOfDay] = useState('');
    const [dataSourceText, setDataSourceText] = useState('');
    const [jobListText, setJobListText] = useState('');
    const [reportTitleText, setReportTitleText] = useState('');
    const [reportTitleTooltip, setReportTitleTooltip] = useState('');
    const [outputFormatText, setOutputFormatText] = useState('');

    useEffect(() => {

        setReportTypeText(`${translate('report_type')}\n\n${reportTypes[reportType].Name}`);
        setTimeOfDay(`${translate('time_of_day')}\n\n${(timeOfDay === 0 ? translate('day') : translate('night'))}`);
        setReportTitleText(`${translate('job_name')}\n\n${reportTitle.length > 40 ? reportTitle.substring(0, 40).trim() + '...' : reportTitle}`);
        setOutputFormatText(`${translate('output_format')}\n\n${outputFormat}`);

        if (reportTitle.length > 40)
            setReportTitleTooltip(reportTitle);

        const selectedTradeArea = tradeAreas.filter(ta => ta.id === tradeArea)
        if (_.isArray(selectedTradeArea) && selectedTradeArea.length > 0)
            setTradeAreaText(`${translate('trade_area')}\n\n${selectedTradeArea[0].text}`);

        const selectedDataSources = dataSources.filter(group => group.sources.some(source => source.id === dataSource));
    
        selectedDataSources.forEach((group) =>{
            group.sources.forEach((source) =>{
                if (source.id === dataSource)
                    setDataSourceText(translate('data_source') + '\n\n' + source.text);
            });
        });

        var jobCount = 0;
        const selectedJobs = jobList.filter(site => site.jobs.some(item => item.selected === true));

        selectedJobs.forEach((site) =>{
            site.jobs.forEach((job) =>{
                if (job.selected)
                    jobCount++;
            });
        });

        setJobListText(`${translate('included_jobs')}\n\n${jobCount} ${translate('included')}`);

        if (onEditStep) onEditStep({ step: -1 });

    }, [reportTypes, reportType, tradeAreas, tradeArea, dataSources, dataSource, timeOfDay, jobList, reportTitle, outputFormat, onEditStep])

    return <>
        <div className='app-mobility-data-step-3-container'>
            <div className='app-mobility-data-step-2-column'>
                {
                    [
                        { text: reportTypeText },
                        { text: tradeAreaText },
                        { text: timeOfDayText },
                        { text: dataSourceText }
                    ].map((editButton, i) => {
                        return (
                            <div key={i} className='app-option-selector app-mobility-data-bar-edit'>
                                <div className='app-option-selector-check'>
                                    <Icon icon={icons.edit} />
                                </div>
                                <Bar className={'app-mobility-data-bar-buttons app-mobility-data-bar-long'} 
                                    height={'75'}
                                    text={editButton.text} 
                                    onClick={() => { if (onEditStep) onEditStep({ step: 0 }); }} 
                                    disabled={false} 
                                /> 
                            </div>
                        )
                    })
                }
            </div>
            <div className='app-mobility-data-step-2-column'>
                {
                    [
                        { text: jobListText },
                        { text: reportTitleText },
                        { text: outputFormatText }
                    ].map((editButton, i) => {
                        return (
                            <div key={i} className='app-option-selector app-mobility-data-bar-edit'>
                                <div className='app-option-selector-check'>
                                    <Icon icon={icons.edit} />
                                </div>
                                <Bar className={'app-mobility-data-bar-buttons app-mobility-data-bar-long'} 
                                    height={'75'}
                                    text={editButton.text} 
                                    tooltip={i === 1 && reportTitleTooltip.length > 0 ? reportTitleTooltip : ''} 
                                    onClick={() => { if (onEditStep) onEditStep({ step: 1 }); }} 
                                    disabled={false} 
                                /> 
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </>
}