import { navigation } from '../components/app/navigation/navigation';
import { errorToast, infoToast, successToast } from '../components/base/toast/toast';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { constants } from '../utils/constants';
import { helpers } from '../utils/helpers';
import { translate } from '../utils/translation';

const _ = require("lodash");

export const customReports = {
    generateCustomReport: async ({ id, entity }) =>{ 

        var properties = [
            { Key: "Title", Value: entity.text },
            { Key: "Latitude", Value: entity.location.lat },
            { Key: "Longitude", Value: entity.location.lon },
        ];

        if (entity.type === constants.entities.point)
            properties.push({ Key: "ServiceId", Value: entity.layer.id });

        infoToast(`${translate('generating')}...`);

        await legacyEndpoints.service({
            name: "GenerateCustomReport",
            parameters: {
                siteId: entity.type === constants.entities.point ? entity.id : '',
                actionButtonId: id,
                additionalData: properties,
                token: legacyEndpoints.authenticationToken.TokenInformation.Token
            },
            success: function(result)
            {
                if (result === null)
                    errorToast(`${translate('an_error_has_occurred')}`);
                else if (result.length === 36)
                    helpers.navigateToUrl(legacyEndpoints.handlers.getFileUrl({ id: result }));                    
                else
                {                    
                    navigation.updateJobs();
                    successToast(translate('successfully_submitted_report'));
                }
            }
        });
            
    },
    generateOpenStoreReport: async ({ entity }) =>{ 

        infoToast(`${translate('generating')}...`);

        await legacyEndpoints.service({
            name: "GetStoreReport",
            parameters: {
                serviceId: entity.layer.id,
                pointId: entity.id,
                tagId: ''
            },
            success: function(result)
            {
                if (result === null || result.length === 0)
                    errorToast(`${translate('an_error_has_occurred')}`);
                else
                    helpers.navigateToUrl(legacyEndpoints.handlers.getFileUrl({ id: result }));
            }
        });
    }
};
