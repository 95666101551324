import { map } from "../components/app/map/map";
import { helpers } from "../utils/helpers";
import { legacyEndpoints } from "../services/legacyEndpoints";
import { constants } from "../utils/constants";
import { translate } from "../utils/translation";
import { Icon, icons } from "../components/base/icon/icon";
import { Button } from "../components/base/button/button";
import { warningToast } from "../components/base/toast/toast";
import { workBench } from "../components/base/workBench/workBench";
import { StandardGeographies } from "../components/app/standardGeographies/standardGeographies";

const _ = require("lodash");

export const standardGeographies = {
    maxLayerGeographies: 20000,
    open: ({ title, tradeArea, vintages, onSave, onClose }) => {
        
        var disposeHook = () => {};
        workBench.setContent({ 
            title: title,
            content: <StandardGeographies 
                tradeArea={tradeArea}
                vintages={vintages}
                onOpen={({ dispose })=>{ disposeHook = dispose; }}
                onSave={(o) => {
                    onSave(o);
                }} 
            />,
            height: '700px',
            maxHeight: '1000px',
            startMinimized: true,
            onClose: () =>{
                if(_.isFunction(onClose))
                    onClose();
                disposeHook();
            }
        });
    },
    updateOverlayLayer: async (overlayLayer, selectedGeographyIds) =>{

        if (map.zoom < overlayLayer.metaData.geoLevel.OptimalMaxZoom)
        {
            overlayLayer.clear();
            warningToast(translate('please_zoom_to_see_overlay').replace('{zoom}', overlayLayer.metaData.geoLevel.OptimalMaxZoom));
            return;
        }

        var geographies = await legacyEndpoints.service({
            name: 'GetGeographyVintageShapes',
            parameters: {
                vintageId: overlayLayer.metaData.geoLevel.GeographyVintageId,
                geoLevelId: overlayLayer.metaData.geoLevel.Id,
                currentView: {
                    polygonString: helpers.encodedLocations(helpers.createRectangle({ topLeft: map.bounds.northEast, bottomRight: map.bounds.southWest})),
                    polygonType: constants.encodedString.google
                }
            }
        });
        if (overlayLayer.entities.length > standardGeographies.maxLayerGeographies)
            overlayLayer.clear();

        _.each(geographies.shapes, geography => {

            if (overlayLayer.entities.find(x => x.id === geography.id) !== undefined)
                return;

            var entity = {
                id: geography.id,
                text: geography.polygon.name.length === 0 ? translate('no_description') : geography.polygon.name,
                type: constants.entities.polygon,
                paths: helpers.parseWkt(geography.polygon.polygonString),
                fillColor: constants.polygons.outline.fillColor,
                strokeColor: constants.polygons.outline.strokeColor,
                strokeWidth: constants.polygons.outline.strokeWidth,
                metaData: { wkt: geography.polygon.polygonString, area: geography.polygon.area },
                listeners: [{
                    type: constants.listeners.click,
                    action: (e) =>{
                        if (overlayLayer.metaData.behavior === constants.selections.behaviors.single)
                        {
                            standardGeographies.createTradeAreaEntity(overlayLayer.metaData.tradeAreaLayer, e.entity);
                            e.entity.layer.metaData.onRefresh();
                        }
                        else
                            standardGeographies.showTooltip(e, true);
                    }
                }]
            }
            overlayLayer.addEntity(entity);

            if (selectedGeographyIds.includes(geography.id))
            {
                standardGeographies.createTradeAreaEntity(overlayLayer.metaData.tradeAreaLayer, entity);
                overlayLayer.metaData.onRefresh();
            }
        });

    },
    updateData: async ({ overlayLayer, selectionType, selectionBehavior, polygonSelection, selectedGeographies, skip, take, filters, sorts }) =>{
        
        var results =  await legacyEndpoints.service({
            name: 'GetStandardGeographyShapes',
            parameters: {
                vintageId: overlayLayer.metaData.geoLevel.GeographyVintageId,
                geoLevelId: overlayLayer.metaData.geoLevel.Id,
                reportId: overlayLayer.metaData.report.id,
                selectionType: selectionType,
                selectionBehavior: selectionBehavior,
                polygonSelection: polygonSelection,
                selectedGeographies: selectedGeographies,
                skip: skip, 
                take: take, 
                filters: filters, 
                sorts: sorts,
                data: null,
                modelOutput: null,
                showTheme: false
            }
        });

        return { 
            columns: [
                {
                    name: helpers.newGuid(), 
                    caption: translate('remove'), 
                    className: `app-standard-geography-button`,
                    fixed: true, 
                    fixedPosition: 'left', 
                    onRender: (o) => {
                        return <Button theme='action' icon={icons.minus} tooltip={translate('remove')} 
                            onClick={() => {                             
                                overlayLayer.metaData.tradeAreaLayer.entities.find(x => x.id === o[0].value).dispose();
                                overlayLayer.metaData.onRefresh();
                            }} 
                        />
                    }
                },
                ,
                {
                    name: helpers.newGuid(), 
                    caption: translate('zoom'),
                    className: `app-standard-geography-button`, 
                    fixed: true, 
                    fixedPosition: 'left',
                    onRender: (o) => {
                        return <Button theme='action' icon={icons.magnifyingGlass} tooltip={translate('zoom')} 
                            onClick={() => {
                                map.locate({ location: o[1].value });
                            }} 
                        />
                    }
                },
                {
                    name: helpers.newGuid(), 
                    caption: translate('geography'), 
                    fixed: true, 
                    fixedPosition: 'left', 
                    onRender: (o) => { return o[2].value; }
                },
                {
                    name: helpers.newGuid(), 
                    caption: translate('description'), 
                    fixed: true, 
                    fixedPosition: 'left', 
                    onRender: (o) => { return o[3].value; }
                },
                ...results.columns.map((column, i)=> { 
                    return {
                        name: i,
                        caption: column.label,
                        columns:[{                            
                            name: `${i}_variable`,
                            caption: !_.isString(column.formattedValue) || column.formattedValue.length === 0 ? '-' : column.formattedValue,
                            className: `app-standard-geography-sum`,
                            onRender: (o) => {  
    
                                if (!o[i+4])
                                    return '';
    
                                return o[i+4].formattedValue; 
                            }
                        }]
                    }
                })
            ], 
            data: results.shapes.map(shape => {
                return [
                    { value: shape.id },                    
                    { value: shape.polygon.centroid },
                    { value: shape.id },
                    { value: shape.polygon.name },                    
                    ...shape.results
                ]
            }),
            totalCount: results.total
        }
    },
    getBulkData: async ({ overlayLayer, type, bulkData, currentData }) =>{

        return await legacyEndpoints.service({
            name: 'AddBulkStandardGeographies',
            parameters: {
                vintageId: overlayLayer.metaData.geoLevel.GeographyVintageId,
                geoLevelId: overlayLayer.metaData.geoLevel.Id,
                reportId: -1,
                selectionType: type,
                bulkGeographies: bulkData,
                selectedGeographies: currentData,
                geographyLimitList: null
            }
        });
        
    },
    append: (selection, geographies) =>{

		selection.forEach(geography =>{
            if (geographies.find(x => x.id === geography.id) === undefined)
                geographies.push(geography);
        });

		return geographies;
	},
	inverse: (selection, geographies) =>{

		var resultEntities = [];
		var removedEntities = [];

		// Remove overlap
		geographies.forEach(currentEntity =>{
			if (selection.filter(entity => entity.id === currentEntity.id).length === 0)
				resultEntities.push(currentEntity);
			else
				removedEntities.push(currentEntity);
		});

		// Add in new
		selection.forEach(entity =>{
			if (geographies.filter(x => x.id === entity.id).length === 0 && removedEntities.filter(x => x.id === entity.id).length === 0)
				resultEntities.push(entity);
		});

		return resultEntities;
	},
	remove: (selection, geographies) =>{

        var resultEntities = [];

        geographies.forEach(geography =>{
            if (selection.find(x => x.id === geography.id) === undefined)
                resultEntities.push(geography);
        });

		return resultEntities;
	},
    new: (selection) =>{
        return selection;
    },
    showTooltip: (e, isOverlay) =>{
        
        var temporaryLayer = map.layers.find(x => x.type === constants.layers.types.temporary);
        temporaryLayer.clear();
        temporaryLayer.addEntity({
            type: constants.entities.polygon,
            paths: e.entity.paths,
            fillColor: constants.polygons.pending.fillColor,
            strokeColor: constants.polygons.pending.strokeColor,
            strokeWidth:  constants.polygons.pending.strokeWidth,
        });
        
        map.showTooltip({
            title: e.entity.id,
            pixel: map.latLonToXY(e.location),
            sections: [
            <Button className='app-projections-geography-toggle' theme='action' icon={isOverlay ? icons.add : icons.minus} text={isOverlay ? translate('add') : translate('remove')} onClick={()=>{

                map.showTooltip({
                    title: translate('specify_selection_action'),
                    pixel: map.latLonToXY(e.location),
                    sections: [<div className='app-selections-geographies'>
                        <Icon icon={icons.spinner}/> {`${translate('applying')}...`}
                    </div>],
                    onHide: ()=>{
                        temporaryLayer.clear();
                    }
                });

                setTimeout(() =>{

                    if (isOverlay)
                        standardGeographies.createTradeAreaEntity(e.entity.layer.metaData.tradeAreaLayer, e.entity);
                    else
                        e.entity.dispose();

                    e.entity.layer.metaData.onRefresh();
                    temporaryLayer.clear();
                    map.hideTooltip();
                }, 50);

            }} />,
            <>
                <div>{`${translate('description')}: ${e.entity.text}`}</div>
                <div>{`${translate('area')} (mi): ${helpers.formatNumber(e.entity.metaData.area.toFixed(2))}`}</div>
                <div>{`${translate('area')} (km): ${helpers.formatNumber(helpers.convertArea(e.entity.metaData.area, constants.areaMeasurements.squareMiles, constants.areaMeasurements.squareKilometers).toFixed(2))}`}</div>
                <div>{`${translate('center')}: ${e.entity.location.lat.toFixed(6)}, ${e.entity.location.lon.toFixed(6)}`}</div>
            </>],
            onHide: () =>{
                temporaryLayer.clear();
            }
        });

    },
    createTradeAreaEntity: (tradeAreaLayer, entity)=>{
        tradeAreaLayer.addEntity({
            id: entity.id,
            text: entity.text,
            type: constants.entities.polygon,
            paths: entity.paths,
            fillColor: constants.polygons.selected.fillColor,
            strokeColor: constants.polygons.selected.strokeColor,
            strokeWidth: constants.polygons.selected.strokeWidth,
            metaData: entity.metaData,
            listeners: [{
                type: constants.listeners.click,
                action: (e) =>{
                    if (tradeAreaLayer.metaData.behavior === constants.selections.behaviors.single)
                    {
                        e.entity.dispose();
                        e.entity.layer.metaData.onRefresh();
                    }
                    else
                        standardGeographies.showTooltip(e, false);
                }
            }]
        });
    },
    select: ({ overlayLayer, tradeAreaLayer, behavior, onSelect }) =>{
        
        map.startDrawing({
            type: constants.entities.polygon,			
			onTooltipRender: (result) =>{

                if (result.locations.length < 3)
					return {
						sections: [<div>{translate('add_at_least_3_vertices')}</div>]
					};

				return { sections: [] };
            },
            onFinish: async (shape) =>{  

                var selectionPolygon = {
                    PolygonType: constants.encodedString.google,
                    PolygonString: helpers.encodedLocations(shape.locations)
                };

                map.showTooltip({
                    title: translate('specify_selection_action'),
                    pixel: map.latLonToXY(shape.locations[0]),
                    sections: [<div className='app-selections-geographies'>
                        <Icon icon={icons.spinner}/> {`${translate('calculating')}...`}
                    </div>]
                });

                var selection = await legacyEndpoints.service({
                    name: 'GetGeographySelection',
                    parameters: {
                        selectionBehavior: behavior,
                        vintageId: overlayLayer.metaData.geoLevel.GeographyVintageId,
                        geoLevelId: overlayLayer.metaData.geoLevel.Id,
                        polygon: selectionPolygon
                    }
                });

                var temporaryLayer = map.layers.find(x => x.type === constants.layers.types.temporary);
                selection.forEach(id =>{
                    var entity = overlayLayer.entities.find(x => x.id === id);

                    temporaryLayer.addEntity({
                        id: id,
                        text: entity.text,
                        type: constants.entities.polygon,
                        paths: entity.paths,
                        fillColor: constants.polygons.pending.fillColor,
                        strokeColor: constants.polygons.pending.strokeColor,
                        strokeWidth: constants.polygons.pending.strokeWidth,
                        metaData: entity.metaData
                    });

                });
                                
                const select = (geographies)=>{
                    
                    map.showTooltip({
                        title: translate('specify_selection_action'),
                        pixel: map.latLonToXY(shape.locations[0]),
                        sections: [<div className='app-selections-geographies'>
                            <Icon icon={icons.spinner}/> {`${translate('applying')}...`}
                        </div>]
                    });

                    setTimeout(() =>{

                        temporaryLayer.clear();
                        tradeAreaLayer.clear();

                        geographies.forEach(geography => standardGeographies.createTradeAreaEntity(tradeAreaLayer, geography));                       

                        onSelect();
                        map.hideTooltip();

                    }, 50);
                };

                map.showTooltip({
                    title: translate('specify_selection_action'),
                    pixel: map.latLonToXY(shape.locations[0]),
                    sections: [<div className='app-selections-geographies'>  
                        <Button theme='action' icon={icons.minus} tooltip={translate('remove_geographies_within_area')} onClick={()=>{ select(standardGeographies.remove(temporaryLayer.entities, tradeAreaLayer.entities)); }} />
                        <Button theme='action' icon={icons.circlesOverlap} tooltip={translate('select_nonselected_geographies')} onClick={()=>{ select(standardGeographies.inverse(temporaryLayer.entities, tradeAreaLayer.entities)); }} />
                        <Button theme='action' icon={icons.add} tooltip={translate('append_geographies_selection')} onClick={()=>{ select(standardGeographies.append(temporaryLayer.entities, tradeAreaLayer.entities)); }} />
                        <Button theme='action' icon={icons.star} tooltip={translate('remove_and_replace_geographies')} onClick={()=>{ select(standardGeographies.new(temporaryLayer.entities, tradeAreaLayer.entities)); }} />
                    </div>],
                    onHide: () =>{
                        temporaryLayer.clear();
                    }
                });                
            }
        });
    }
};