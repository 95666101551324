import React, { useCallback, useMemo } from 'react';

// Third party imports
import { ContextMenu as DxContextMenu } from 'devextreme-react/context-menu';
import { Button } from '../button/button';

var _ = require("lodash");

export function ContextMenu({ className, target, items, visible, onHide }) {       

    var classes = ['app-context-menu'];

    if (className)
        classes.push(className);

    const itemChanged = useCallback((o)=>{        
        
        if (o.itemData.header)
            return;

        if (_.isFunction(o.itemData.onClick))
            o.itemData.onClick();

    }, [items]);

    const itemRender = useCallback((item)=>{
        return item.header ? <div className="app-button-menu-title">{item.text}</div> : <Button theme='row' icon={item.icon} text={item.text} disabled={item.disabled} showExpander={item.items?.length > 0} />
    }, [items]);

    const _onHide = useCallback(()=>{
        onHide();
    }, [onHide]);

    return visible ? <DxContextMenu
        cssClass={classes.join(' ')}
        dataSource={items}
        target={target}        
        onHidden={_onHide}
        itemRender={itemRender}
        onItemClick={itemChanged}
    /> : ''
}