// React imports
import { useEffect, useState } from 'react';

// App imports
import { Button } from '../../../base/button/button';
import { DropDown } from '../../../base/dropDown/dropDown';
import { inputBoxTypes, TextBox } from '../../../base/textBox/textBox';
import { helpers } from '../../../../utils/helpers';
import { constants } from '../../../../utils/constants';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { tradeAreas as tradeAreaModule } from '../../../../modules/tradeAreas';

const _ = require('lodash');

export function BulkDemographicsTradeAreas({tradeAreas, updateTradeAreas}) {

    const [tradeAreaTypes, setTradeAreaTypes] = useState([]);

    var setDefaults = async () =>{

        const entity = {id: null, location: {lat: null, lon: null}, layer: {id: null, parentId: null} };
        const filteredTypes = [constants.tradeAreas.types.ring, constants.tradeAreas.types.driveTime];

        const defaults = await tradeAreaModule.getDefaults({
            dataSourceId: entity.layer.parentId,
            customQueryId: entity.layer.id,
            pointId: entity.id,
            latitude: entity.location.lat,
            longitude: entity.location.lon,
            filteredTypes: filteredTypes
        });

        setTradeAreaTypes(defaults?.types && defaults?.types.length > 0 ? defaults.types : []);

    };

    useEffect(()=>{
        setDefaults();
    },[]);

    var getRowClass = (type) =>{
        switch (type)
        {
            default:
            case constants.tradeAreas.types.ring:
                return 'app-trade-area-row-ring';
            case constants.tradeAreas.types.driveTime:
                return 'app-trade-area-row-drive-time';
        }
    };

    return <>
        <div className='app-tools-bulk-trade-areas'>
            <div className='app-trade-areas-content'>
            {
                tradeAreas.length === 0 ? <div className='app-trade-areas-empty'>{translate('add_trade_areas')}</div> :
                tradeAreas.map((tradeArea) => { 
                    
                    return <div key={tradeArea.id} className={`app-trade-area-row ${getRowClass(tradeArea.key)}`}>
                        <DropDown
                            className='app-trade-area-type'
                            selected={tradeArea.key}
                            items={tradeAreaTypes}
                            label={translate('type')}
                            display='name'
                            valueProperty="key"
                            onSelect={(o) => {
                                tradeArea.key = o.value.key;
                                tradeArea.type = o.value.name;
                                updateTradeAreas([..._.cloneDeep(tradeAreas)]);
                            }}
                        />
                        <TextBox
                            className='app-trade-area-distance'
                            value={tradeArea.distance}
                            type={inputBoxTypes.numeric}
                            maxLength={2}                
                            allowNegative={false}
                            allowDecimals={true}
                            allowZero={false}
                            allowLeadingZeros={false}
                            onChange={(o) => {
                                tradeArea.distance = o.value;
                                updateTradeAreas([..._.cloneDeep(tradeAreas)]);
                            }}
                        />
                        <TextBox className='app-trade-area-minutes' value={tradeArea.key === constants.tradeAreas.types.driveTime ? translate('minutes') : translate('mi')} disabled={true} />                    
                        <div className='app-trade-area-actions'>
                            <Button theme='simple' icon={icons.trash} tooltip={translate('remove')} onClick={() => {
                                updateTradeAreas(tradeAreas.filter(x => x !== tradeArea));
                            }} />
                        </div>
                    </div>
                })
            }
            </div>
        </div>
        <div className='app-tools-bulk-trade-areas-add'>
            <Button className='app-map-book-builder-button' theme='secondary' size='small' icon={icons.plus} tooltip={translate('add')} disabled={tradeAreas.length===5} onClick={() =>{
                var tradeArea = { id: helpers.newGuid(), key: constants.tradeAreas.types.ring, type: translate('ring'), distance: '1', fillColor: '', lineColor: '' };
                tradeAreas.push(tradeArea);
                updateTradeAreas([..._.cloneDeep(tradeAreas)]);
            }} />
        </div>
    </>
}