// React imports
import { useEffect } from 'react';

// App imports
import { DefineDataSource } from './defineDataSource';
import { translate } from "../../../../utils/translation";
import { constants } from '../../../../utils/constants';

export function BulkClosestStoresStep1({originExcelFile, updateOriginExcelFile, originDataSource, updateOriginDataSource, originDataSources, updateOriginDataSources, 
    destinationExcelFile, updateDestinationExcelFile, destinationDataSource, updateDestinationDataSource, destinationDataSources, updateDestinationDataSources, 
    originFormat, updateOriginFormat, destinationFormat, updateDestinationFormat, onReadyNext}) {
    
    useEffect(() => {

        var originReady = false;
        var destinationReady = false;

        if (originFormat != null)
            if (originFormat === constants.bulkReports.dataSources.excel && originExcelFile != null && originExcelFile.resultId.length > 0)
                originReady = true;
            else if (originFormat === constants.bulkReports.dataSources.datasource && originDataSource != null && originDataSource.length > 0)
                originReady = true;

        if (destinationFormat != null)
            if (destinationFormat === constants.bulkReports.dataSources.excel && destinationExcelFile != null && destinationExcelFile.resultId.length > 0)
                destinationReady = true;
            else if (destinationFormat === constants.bulkReports.dataSources.datasource && destinationDataSource != null && destinationDataSource.length > 0)
                destinationReady = true;
    
        if (onReadyNext)
            onReadyNext(originReady && destinationReady);

    }, [onReadyNext, originExcelFile, originDataSource, destinationExcelFile, destinationDataSource, originFormat, destinationFormat])
    
    return <>
        <div className='app-tools-bulk-divided-container'>
            <div className='app-tools-bulk-divided-column'>
                <DefineDataSource sourceLabel={translate('origin_source')} excelFile={originExcelFile} updateExcelFile={updateOriginExcelFile}
                    dataSources={originDataSources} updateDataSources={updateOriginDataSources} updateDataSource={updateOriginDataSource}
                    sourceFormat={originFormat} updateSourceFormat={updateOriginFormat} 
                />
            </div>
            <div className='app-tools-bulk-vertical-line'></div>
            <div className='app-tools-bulk-divided-column'>
                <DefineDataSource sourceLabel={translate('destination_source')} excelFile={destinationExcelFile} updateExcelFile={updateDestinationExcelFile}
                    dataSources={destinationDataSources} updateDataSources={updateDestinationDataSources} updateDataSource={updateDestinationDataSource}
                    sourceFormat={destinationFormat} updateSourceFormat={updateDestinationFormat} 
                />
            </div>
        </div>
    </>
}