// App imports
import { constants } from '../utils/constants';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { translate } from '../utils/translation';
const _ = require("lodash");

export const competitiveInsightsReportObject = {    
    getReasons: (o) =>{
        if (o === constants.competitiveInsights.reportedObjectTypes.point) {
          return [
            { name: translate('other'), id: 0},
            { name: translate('incorrect_location'), id: 1},
            { name: translate('incorrect_segment'), id: 2}
          ];
        } else {
            return [
                { name: translate('other'), id: 0},
                { name: translate('incorrect_location'), id: 1},
                { name: translate('parking_lot'), id: 2}
            ];
        }
    },    
    reportCompetitiveInsightGeoFence: async function (o) {     
        return legacyEndpoints.service({
            name: 'ReportCompetitiveInsightGeoFence',
            parameters: {
                reportedGeoFence: {
                    PointId: o.pointId,
                    Latitude: o.latitude,
                    Longitude: o.longitude,
                    GeoFenceId: o.geoFenceId,
                    Reason: o.reason,
                    Description: o.description
                }
            }
        }); 
    },   
    reportCompetitiveInsightPoint: async function (o) {     
        return legacyEndpoints.service({
            name: 'ReportCompetitiveInsightPoint',
            parameters: {
                reportedPoint: {
                    PointId: o.pointId,
                    Latitude: o.latitude,
                    Longitude: o.longitude,
                    Reason: o.reason,
                    Description: o.description
                }
            }
        });   
    },   
};