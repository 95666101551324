// React imports
import { useState, useEffect, useCallback, useRef } from 'react';

// App imports
import { Description } from '../../../base/description/description';
import { TextBox, inputBoxTypes, validatorTypes } from '../../../base/textBox/textBox';
import { translate } from '../../../../utils/translation';
import { helpers } from '../../../../utils/helpers';

const _ = require("lodash");

export function FilterDetailsC({ isLocked, distribution, variable, updateVariable }) {

    const [description, setDescription] = useState('');
    const [origLowValue, setOrigLowValue] = useState(null);
    const [origHighValue, setOrigHighValue] = useState(null);
    const [lowValue, setLowValue] = useState(null);
    const [highValue, setHighValue] = useState(null);
    const [minValue, setMinValue] = useState(null);
    const [maxValue, setMaxValue] = useState(null);
    const [subjectSiteValue, setSubjectSiteValue] = useState("0");

    const variableLowValueValidatorRef = useRef(null);
    const variableHighValueValidatorRef = useRef(null);

    const lowValueComparison = useCallback(() => lowValue, [lowValue]);
    const highValueComparison = useCallback(() => highValue, [highValue]);

    useEffect(() => {

        var subject = "0";
        var low = variable.MinValue;
        var high = variable.MaxValue;
        var min = variable.MinValue;
        var max = variable.MaxValue;

        if (variable.SubjectSiteValue !== null && variable.SubjectSiteValue.length > 0) {
            subject = variable.SubjectSiteValue;
            low = variable.LowValue;
            high = variable.HighValue;
        }

        const precision = (variable.Step + "").split(".").length > 1 ? (variable.Step + "").split(".").pop().length : 0;

        switch (variable.Format)
        {
            default:
            case "N":
                subject = `${helpers.formatNumber(parseFloat(subject).toFixed(precision))}`;
                low = helpers.truncateDecimalPlaces({ value: (low), places: precision });
                high = helpers.truncateDecimalPlaces({ value: (high), places: precision });
                break;
            case "P":
                subject = `${helpers.formatNumber(parseFloat(subject * 100).toFixed(precision))}%`;
                low = helpers.truncateDecimalPlaces({ value: (low * 100), places: precision });
                high = helpers.truncateDecimalPlaces({ value: (high * 100), places: precision });
                min = min * 100;
                max = max * 100;
                break;
            case "C":
                subject = `$${helpers.formatNumber(parseFloat(subject).toFixed(precision))}`;
                low = helpers.truncateDecimalPlaces({ value: (low), places: precision });
                high = helpers.truncateDecimalPlaces({ value: (high), places: precision });
                break;
        }

        setSubjectSiteValue(subject);
        setOrigLowValue(low);
        setOrigHighValue(high);
        setLowValue(low);
        setHighValue(high);
        setMinValue(min);
        setMaxValue(max);

    }, [variable]);

    useEffect(() => {
        
        if (!_.isArray(distribution) || distribution.length === 0)
            return;

        const dist = distribution.find(o => o.Id === variable.ID);

        if (_.isObject(dist))
            setDescription(`${translate('stores_within_range')}: ${dist.Raw}\n${translate('available_analogs')}: ${dist.Weighted}`);
        else
            setDescription(`${translate('stores_within_range')}: ${translate('n_a')}\n${translate('available_analogs')}: ${translate('n_a')}`);

    }, [variable, distribution]);

    useEffect(() => {
        
        if (lowValue === origLowValue && highValue === origHighValue)
            return;

        updateVariable({ 
            minValue: variable.Format == "P" ? lowValue / 100 : lowValue,
            maxValue: variable.Format == "P" ? highValue / 100 : highValue,
            isValid: variableLowValueValidatorRef.current.instance.validate().isValid && variableHighValueValidatorRef.current.instance.validate().isValid
        }); 

    }, [variable.Format, origLowValue, origHighValue, lowValue, highValue]);

    return <>
        <Description description={translate('variable_range_slider_explain')} />
        <div className='app-analogs-filter-row'>
            <div className='app-analogs-filter-row'>
                <div className={'app-analogs-filter-details-input'}>{`${translate('min')}:`}</div>
                <TextBox 
                    className={'app-analogs-filter-details-input'} 
                    value={lowValue} 
                    valueChangeEvent={'change keyup'}
                    readOnly={(!variable.IsModifiable && variable.IsReadOnly) || isLocked} 
                    type={inputBoxTypes.numeric}
                    allowNull={false}
                    validator={
                        {
                            ref: variableLowValueValidatorRef,
                            rules: [
                                {
                                    type: validatorTypes.compareRule,
                                    message: translate('min_greater_than_max'),
                                    comparisonTarget: highValueComparison,
                                    comparisonType: '<='
                                },
                                {
                                    type: validatorTypes.rangeRule,
                                    message: `${translate('values_out_of_range')}: ${minValue} - ${maxValue}`,
                                    min: minValue,
                                    max: maxValue
                                },
                            ]
                        }
                    }
                    onChange={(o) => { 
                        setLowValue(o.value); 
                    }} 
                />
            </div>
            <div className={'app-analogs-filter-details-input'}>{`${translate('subject_val')}: ${subjectSiteValue}`}</div>
            <div className='app-analogs-filter-row'>
                <div className={'app-analogs-filter-details-input'}>{`${translate('max')}:`}</div>
                <TextBox 
                    className={'app-analogs-filter-details-input'} 
                    value={highValue} 
                    valueChangeEvent={'change keyup'}
                    readOnly={(!variable.IsModifiable && variable.IsReadOnly) || isLocked} 
                    type={inputBoxTypes.numeric}
                    allowNull={false}
                    validator={
                        {
                            ref: variableHighValueValidatorRef,
                            rules: [
                                {
                                    type: validatorTypes.compareRule,
                                    message: translate('max_less_than_min'),
                                    comparisonTarget: lowValueComparison,
                                    comparisonType: '>='
                                },
                                {
                                    type: validatorTypes.rangeRule,
                                    message: `${translate('values_out_of_range')}: ${minValue} - ${maxValue}`,
                                    min: minValue,
                                    max: maxValue
                                },
                            ]
                        }
                    }
                    onChange={(o) => { 
                        setHighValue(o.value); 
                    }} 
                />
            </div>
        </div>
        { variable.IsScoreCondition || description.length === 0 ? '' :
            <Description description={description} />
        }
    </>
}