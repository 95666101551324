import { useEffect, useState } from 'react';

import { InputForm } from '../inputForm/inputForm';
import { Loader } from '../../../base/loader/loader';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { sources } from '../../../../modules/sources';
import { successToast, errorToast } from '../../../base/toast/toast';
import { translate } from '../../../../utils/translation';
import { DropDown } from '../../../base/dropDown/dropDown';
import { map } from '../../map/map';
import { constants } from '../../../../utils/constants';
import { forms as formModule} from '../../../../modules/forms';
import { ConfirmButton } from '../../../base/confirmButton/confirmButton';

const _ = require("lodash");

export function AddForm({ entity, onClose }){

    const [form, setForm] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);
    const [collapsedForm, setCollapsedForm] = useState(null);
    const [availableForms, setAvailableForms] = useState(null);
    const [generating, setGenerating] = useState(false);   
    const [loading, setLoading] = useState(false);    
    const [updateForm, setUpdateForm] = useState(null);
    const [locationResult, setLocationResult] = useState(null);
    const [activeWarning, setActiveWarning] = useState(false);
    const [activeError, setActiveError] = useState(true);

    useEffect(() =>{        
        (async () =>{

            map.geocode({
                query: `${entity.location.lat} ${entity.location.lon}`,
                callback: (result) => {
                    result.location.lat = entity.location.lat;
                    result.location.lon = entity.location.lon;
                    setLocationResult(result);
                }
            });

            var forms = await legacyEndpoints.service({
                name: 'GetCombinedAvailableAddForms',
                parameters: {
                    Lat: entity.location.lat,
                    Lon: entity.location.lon,
                    IsShape: !(entity.type == constants.entities.point || entity.type == constants.entities.pushpin)
                }
            });

            forms.forEach(item => item.group = item.IsAdHoc ? translate('ad_hoc_add_forms') : translate('add_forms'));            

            setAvailableForms(_.sortBy(forms.filter(item => item.ServiceId.indexOf("-") > -1), 'IsAdHoc'));

        })();

    }, [entity]);

    useEffect(() =>{  
        
        if (selectedForm === null)
            return;
            
        (async () =>{
            setLoading(true);
            var formData = await legacyEndpoints.service({
                name: 'GetAddFormV2',
                parameters: {
                    FormId: selectedForm.FormId,
                    Lat: entity.location.lat,
                    Lon: entity.location.lon,
                }
            })
            setForm(formData);
            setCollapsedForm(formModule.collapseForm({form: formData}));
            setLoading(false);
        })();

    }, [selectedForm]);

    const save = async () =>{
        setGenerating(true);

        var validation = await formModule.saveAddForm({entity: entity, form: form, collapsedForm: collapsedForm, updateForm: updateForm});

        if (!validation.errors) {
            successToast(translate('success'));
            sources.refresh();
            entity?.dispose();
        }
        else {
            setForm(_.cloneDeep(validation.form));
            errorToast(translate('form_save_error'));
        }

        setGenerating(false);
        
        if(_.isFunction(onClose)) 
            onClose();
    };
    
	return <>
        <div className='app-proposed-site-header'>            
            {
                (activeError === true) ? <Button theme='primary' size='medium' icon={icons.check} disabled={true} /> 
                : (activeWarning === true) ? <ConfirmButton className='app-button-warning' theme='primary' size='medium' align='right' icon={icons.check} clearText={translate('save')} headerText={translate('save_with_warnings')} onConfirm={()=>{save()}}/> 
                : <Button theme='primary' size='medium' icon={icons.check} onClick={()=>{save()}}/> 
            }
        </div>
        <div className='app-proposed-site-header'>
            <DropDown
                className='app-add-form-drop-down'                
                display='Name'
                grouped={true}
                placeholder={translate('select_add_form')}
                label={translate('add_forms')}
                selected={selectedForm}
                items={availableForms ?? []}                
                onChange={(o) => {
                    setSelectedForm(o.value);
                }}
            />
        </div>
        <Loader loaded={availableForms !== null} />
        <Loader theme='generate' loaded={!generating} />
        <div className='app-element'>
            <Loader loaded={!loading} />
            {
                form === null ? <div className="app-empty-content">{translate('select_add_form')}</div>
                :   <InputForm form={form} collapsedForm={collapsedForm} forceChange={true} locationResult={locationResult} 
                        onUpdate={(o) => { setUpdateForm(o.updateForm); 
                            setActiveError(o.activeError); 
                            setActiveWarning(o.activeWarning) }
                        } 
                    />
            }   
        </div>             
    </>;
}