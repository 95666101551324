// React imports
import { useState } from 'react';

// App imports
import { AvailableMaps } from './availableMaps/availableMaps';
import { PrintMapPanel } from './printMap/printMapPanel';
import { SaveProjectMap } from './saveMap/saveProjectMap';
import { translate } from "../../../utils/translation";
import { constants } from '../../../utils/constants';
import { navigation } from '../navigation/navigation';
import { helpers } from '../../../utils/helpers';

export var projects;

export function Projects({onClose}) {
    
    const panels ={
        saveMap: 0,
        printMap: 1,
        availableMaps: 2,
    };   

    const defaultPanel = panels.availableMaps;

    const [activePanel, setActivePanel] = useState(defaultPanel);
	const [activeTab, setActiveTab] = useState(0);
	const [activeMap, setActiveMap] = useState(null);

    projects = {
        openPrintMap: () => {
            navigation.forceExpand({                     
                section: constants.navigation.sections.projects 
            });

            setActivePanel(panels.printMap);
        },
        openSaveMap: () => {
            navigation.forceExpand({                     
                section: constants.navigation.sections.projects 
            });

            setActivePanel(panels.saveMap);
        },
        openAvailableMaps: (o) => {
            navigation.forceExpand({                     
                section: constants.navigation.sections.projects 
            });

            setActivePanel(panels.availableMaps);
			
			if (o.tab)
				setActiveTab(o.tab);
			
			if (o.map)
				setActiveMap(o.map);
        }
    };

    return  <>
        {( activePanel === panels.saveMap ? <SaveProjectMap text={translate("save_map")} open={activePanel===panels.saveMap}  onClose={() => { setActivePanel(defaultPanel); onClose(); }} /> : '' )} 
        {( activePanel === panels.printMap ? <PrintMapPanel text={translate("print_map")} onClose={() => { setActivePanel(defaultPanel); onClose(); }} /> : '' )} 
        {( activePanel === panels.availableMaps && !helpers.isViewer() ? 
            <AvailableMaps 
                text={translate("available_maps")}
                activeTab={activeTab} 
                updateActiveTab={(o) => { setActiveTab(o.tab); }} 
                activeMap={activeMap} 
                onClose={() => { 
                    setActivePanel(defaultPanel); 
                    setActiveTab(0); 
                    setActiveMap(null); 
                    onClose(); 
                }} />
        : '' )} 
    </>
}