// React imports
import { useEffect } from 'react';

//App imports
import { DropDown } from '../../../../base/dropDown/dropDown';
import { translate } from '../../../../../utils/translation';
import { Button } from '../../../../base/button/button';
import { icons } from '../../../../base/icon/icon';
import { constants } from '../../../../../utils/constants';
import { TextBox } from '../../../../base/textBox/textBox';
import { TextArea } from '../../../../base/textArea/textArea';
import { Icon } from '../../../../base/icon/icon';
import { helpers } from '../../../../../utils/helpers';

const _ = require('lodash');

export function GeoFeedCreationWizardStep2({geoFeed,updateGeoFeed,onReadyNext}) {
    
    useEffect(() => {
        if (onReadyNext)
                onReadyNext(!geoFeed.name.trim().length == 0);
    }, [onReadyNext])


    return <div className='app-geo-feeds-creation-wizard-container'>
        <TextBox className='app-geo-feeds-creation-wizard-geo-feed-name' label={translate('name')} value={geoFeed.name} valueChangeEvent={'change keyup'}
                            onChange={(o) => {
                                        updateGeoFeed({...geoFeed, name: o.value});
                            }} />        

        <TextArea className='app-geo-feeds-creation-wizard-geo-feed-name' minHeight={150} label={translate('description')} value={geoFeed.description}
                            onChange={(o) => {
                                updateGeoFeed({...geoFeed, description: o.value});
                    }} />    

        {/* <div className='app-mobility-data-labels'>{translate('share_geo_feed')}</div>     */}

        
    </div>
}