// React imports
import { useState } from 'react';
import { translate } from '../../../utils/translation';

var _ = require("lodash");

export function Clickable({id, className, children, tooltip, active, disabled, notLicensed, comingSoon, hovered, onHover, onClick, onDoubleClick}) {

    const [press, setPress] = useState(false);
    const [hover, setHover] = useState(hovered);

    var generateClasses = function(){

        let classes = ['app-clickable'];

        if (className)
            classes.push(className);

        if (comingSoon)
            classes.push('app-clickable-coming-soon');
        else if (notLicensed)
            classes.push('app-clickable-not-licensed');
        else if (disabled)
            classes.push('app-clickable-disabled');
        else{

            if (active)
                classes.push('app-clickable-active');
            
            if (press)
                classes.push('app-clickable-pressed');

            if (hover || hovered)
                classes.push('app-clickable-hovered');            
        }

        return classes.join(' ');
    };

    const getTooltip = function(){

        var suffix = '';

        if (comingSoon)
            suffix =  translate('coming_soon');
        else if (notLicensed)
            suffix =  translate('not_licensed');
        
        return tooltip && suffix ? `${tooltip} (${suffix})` : suffix ? suffix : tooltip; 
    };

    return <div
        id={id}
        className={generateClasses()}
        title={getTooltip()}
        onMouseDown={()=>{

            if (disabled || notLicensed || comingSoon)
                return;

            setPress(true);
        }}
        onMouseUp={()=>{

            if (disabled || notLicensed || comingSoon)
                return;

            setPress(false);
        }}
        onMouseEnter={(e)=>{
            
            if (disabled || notLicensed || comingSoon)
                return;

            setHover(true);

            if (_.isFunction(onHover))
                onHover({ event: e, hovered: true });
        }}
        onMouseLeave={(e)=>{

            if (disabled || notLicensed || comingSoon)
                return;

            setPress(false);
            setHover(false);

            if (_.isFunction(onHover))
                onHover({ event: e, hovered: false });
        }}
        onDoubleClick={(e)=>{

            if (!disabled && !notLicensed && !comingSoon && _.isFunction(onDoubleClick)) {
                onDoubleClick(e);
            }
        }}        
        onClick={(e)=>{
            
            if (!disabled && !notLicensed && !comingSoon && _.isFunction(onClick))
                onClick(e);

        }}>
            {children}
    </div>
}