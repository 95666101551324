const _ = require("lodash");

export class ActionListener  {
    constructor(o) {
        o = _.isObject(o) ? o : {};
        
        this._listeners = [];
        this._executeListeners = (o) => {

            o.properties.entity = _.isObject(o.properties.entity) ? o.properties.entity : this;

            this._listeners.filter(x => x.type === o.type).forEach(x => {
        
                if (_.isBoolean(o.onlyDrawing) && o.onlyDrawing && !x.isDrawing)
                    return;
                
                x.action(o.properties);
                
                if (x.executeOnce)
                    this.removeListener(x.id);
            });
      
        };

        if (_.isArray(o.listeners))
            o.listeners.forEach(x => this.addListener(x));        
    }

    addListener(o){

        var id = o.id ?? crypto.randomUUID();
        this._listeners.push({ id: id, executeOnce: _.isBoolean(o.executeOnce) && o.executeOnce, type: o.type, isDrawing: _.isBoolean(o.isDrawing) && o.isDrawing, action: o.action });
        return id;
    };

    removeListener(id){        
        this._listeners = this._listeners.filter(x => x.id !== id);
    };

    removeAllListeners(){        
        this._listeners.forEach(x => this.removeListener(x.id));        
    };
};