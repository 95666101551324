import React, { useRef } from 'react'

import { Popover as DxPopover } from 'devextreme-react';

const _ = require("lodash");

export const Popover = React.forwardRef(({ children, className, parentId, title, width=300, height=400, showTitle = true}, ref) => {    
    
    const popover = useRef(null);
	
    React.useImperativeHandle(ref, () => {
        return popover.current
    }, []);

    var classes = ['app-popover'];

    if (className)
        classes.push(className);

    return <DxPopover
        ref={popover}
        wrapperAttr={{class: classes.join(' ') }}
        target={parentId}
        hideOnOutsideClick={true}
        showEvent="mouseup"        
        position="top"
        width={width}
        height={height}
        showTitle={showTitle}
        title={title}
    >
     {children}
  </DxPopover>
})