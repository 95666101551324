// React imports
import { useState, useEffect } from 'react';

// App imports
import { Bar } from '../../../base/bar/bar';
import { Icon } from '../../../base/icon/icon';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { constants } from '../../../../utils/constants';

export function BulkDemographcsStep3({excelFile, demoReports, demoReport, jobName, outputFormat, tradeAreas, onEditStep}) {

    const [excelFileText, setExcelFileText] = useState('');
    const [excelFileTooltip, setExcelFileTooltip] = useState('');
    const [demoReportText, setDemoReportText] = useState('');
    const [demoReportTooltip, setDemoReportTooltip] = useState('');
    const [jobNameText, setJobNameText] = useState('');
    const [jobNameTooltip, setJobNameTooltip] = useState('');
    const [outputFormatText, setOutputFormatText] = useState('');
    const [tradeAreasText, setTradeAreasText] = useState('');
    const [tradeAreasTooltip, setTradeAreasTooltip] = useState('');

    useEffect(() => {

        setExcelFileText(`${translate('excel_document')}\n\n${excelFile.name.length > 40 ? excelFile.name.substring(0, 40).trim() + '...' : excelFile.name}`);
        if (excelFile.name.length > 40)
            setExcelFileTooltip(excelFile.name);

        setJobNameText(`${translate('job_name')}\n\n${jobName.length > 40 ? jobName.substring(0, 40).trim() + '...' : jobName}`);
        if (jobName.length > 40)
            setJobNameTooltip(jobName);

        setOutputFormatText(`${translate('output_format')}\n\n${outputFormat === constants.bulkReports.excelOutputFormat.single ? translate('all_on_one_sheet') : translate('one_sheet_per_trade_area') }`);

        var taText = '';
        tradeAreas.forEach((ta) => { taText += `${ta.type}: ${ta.distance}, `; });
        taText = taText.substring(0, taText.length - 2);

        setTradeAreasText(`${translate('trade_areas')}\n\n${taText.length > 40 ? taText.substring(0, 40).trim() + '...' : taText}`);
        if (taText.length > 40)
            setTradeAreasTooltip(taText);

        const reports = demoReports.filter(group => group.items.some(item => item.id === demoReport));
    
        reports.forEach((group) =>{
            group.items.forEach((item) =>{
                if (item.id === demoReport) {
                    setDemoReportText(`${translate('demographic_report')}\n\n${item.text.length > 40 ? item.text.substring(0, 40).trim() + '...' : item.text}`);
                    if (item.text.length > 40)
                        setDemoReportTooltip(item.text);
                }
            });
        });

        if (onEditStep) onEditStep({ step: -1 });

    }, [excelFile, demoReports, demoReport, jobName, outputFormat, tradeAreas, onEditStep])

    return <>
        <div className='app-tools-bulk-divided-container app-tools-bulk-container-top'>
            <div className='app-tools-bulk-divided-column'>
                {
                    [
                        { text: excelFileText, toolTip: excelFileTooltip },
                        { text: demoReportText, toolTip: demoReportTooltip }
                    ].map((editButton, i) => {
                        return (
                            <div key={i} className='app-option-selector app-tools-bulk-bar-edit'>
                                <div className='app-option-selector-check'>
                                    <Icon icon={icons.edit} />
                                </div>
                                <Bar className={'app-tools-bulk-bar-buttons app-tools-bulk-bar-long'} 
                                    height={'75'}
                                    text={editButton.text} 
                                    tooltip={editButton.toolTip} 
                                    onClick={() => { if (onEditStep) onEditStep({ step: 0 }); }} 
                                    disabled={false} 
                                /> 
                            </div>
                        )
                    })
                }
            </div>
            <div className='app-tools-bulk-divided-column'>
                {
                    [
                        { text: jobNameText, toolTip: jobNameTooltip },
                        { text: outputFormatText, toolTip: '' },
                        { text: tradeAreasText, toolTip: tradeAreasTooltip }
                    ].map((editButton, i) => {
                        return (
                            <div key={i} className='app-option-selector app-tools-bulk-bar-edit'>
                                <div className='app-option-selector-check'>
                                    <Icon icon={icons.edit} />
                                </div>
                                <Bar className={'app-tools-bulk-bar-buttons app-tools-bulk-bar-long'} 
                                    height={'75'}
                                    text={editButton.text} 
                                    tooltip={editButton.toolTip} 
                                    onClick={() => { if (onEditStep) onEditStep({ step: 1 }); }} 
                                    disabled={false} 
                                /> 
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </>
}