// React imports
import { useState, useEffect } from 'react';

// 3rd party
import Linkify from 'react-linkify';

// App imports
import { Clickable } from '../../base/clickable/clickable';
import { Button } from '../../base/button/button';
import { helpers } from '../../../utils/helpers';
import { translate } from '../../../utils/translation';
import { constants } from '../../../utils/constants';
import { Icon, icons } from '../../base/icon/icon';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { projects } from '../projects/projects';
import { navigation } from '../navigation/navigation';
import { tools } from '../tools/tools';
import { reports } from '../reports/reports';
import { trip2Trade as trip2TradeModule } from '../../../modules/trip2Trade';
import { app } from '../app';

var _ = require("lodash");

export function Notification({notification, markNotification}) {    
    
    const [userImage, setUserImage] = useState(null);
    const [expandMessage, setExpandMessage] = useState(false);

    useEffect(() =>{

        if (notification.type !== constants.notifications.types.job && _.isObject(notification.owner)) {
            const userGUID = notification.owner.GUID || notification.owner.Id;
            setUserImage(legacyEndpoints.handlers.getPhotoUrl({ isUserPhoto: true, userId: userGUID, width: 50, height: 50 }));
        }

    }, [notification]);

    const getJobMetaData = (o) => {

        var taskTypeValue = -1;
        const taskType = o.metaData.find(item => item.Key === "taskType");
        if (_.isObject(taskType)) 
            taskTypeValue = taskType.Value;

        var taskTypeNameValue = '';
        const taskTypeName = o.metaData.find(item => item.Key === "taskTypeName");
        if (_.isObject(taskTypeName)) 
            taskTypeNameValue = taskTypeName.Value;

        var customTaskTypeValue = -1;
        const customTaskType = o.metaData.find(item => item.Key === "customTaskType");
        if (_.isObject(customTaskType)) 
            customTaskTypeValue = customTaskType.Value;

        var statusValue = -1;
        const status = o.metaData.find(item => item.Key === "status");
        if (_.isObject(status)) 
            statusValue = status.Value;

        return {
            taskType: taskTypeValue,
            taskTypeName: taskTypeNameValue,
            customTaskType: customTaskTypeValue,
            status: statusValue
        }
    };

    const getStatus = (o)=>{
        const jobMetadata = getJobMetaData(o);

        switch (jobMetadata.status) {
            default:
                return <span style={{fontWeight: 700}}>{translate('n_a')}</span>
            case constants.jobs.statuses.pending:
                return <span style={{fontWeight: 700}}>{translate('pending')}</span>
            case constants.jobs.statuses.processing:
                return <span style={{fontWeight: 700}}>{translate('processing')}</span>
            case constants.jobs.statuses.completed:
                return <span style={{fontWeight: 700}}>{translate('completed')}</span>
            case constants.jobs.statuses.completedWithErrors:
                return <span style={{fontWeight: 700}}>{translate('completed_with_errors')}</span>
            case constants.jobs.statuses.failed:
                return <span style={{fontWeight: 700}}>{translate('failed')}</span>
        }
    };

    const getType = (o)=>{

        const jobMetadata = getJobMetaData(o);

        switch (jobMetadata.taskType) {
            default:
                return !_.isEmpty(jobMetadata.taskTypeName) ? <span><i>{jobMetadata.taskTypeName}</i></span> : '';
            case constants.jobs.types.demographics:
                return <span><i>{translate('bulk_demographics')}</i></span>
            case constants.jobs.types.closestStore:
                return <span><i>{translate('bulk_closest_stores')}</i></span>
            case constants.jobs.types.mapBook:
                return <span><i>{translate('map_books')}</i></span>
            case constants.jobs.types.custom:
                switch (jobMetadata.customTaskType) {
                    default:
                        return !_.isEmpty(jobMetadata.taskTypeName) ? <span><i>{jobMetadata.taskTypeName}</i></span> : '';
                    case constants.jobs.types.customTypes.managementReport:
                        return <span><i>{translate('management_report')}</i></span>
                    case constants.jobs.types.customTypes.voidAnalysis:
                        return <span><i>{translate('void_analysis')}</i></span>
                    case constants.jobs.types.customTypes.mobility:
                        return <span><i>{translate('mobility_report')}</i></span>
                    case constants.jobs.types.customTypes.demographicReport:
                        return <span><i>{translate('demographic_report')}</i></span>
                    case constants.jobs.types.customTypes.importExport:
                        return <span><i>{translate('import_export')}</i></span>                    
                    case constants.jobs.types.customTypes.adHocDataSource:
                        return <span><i>{translate('ad_hoc_data_source')}</i></span>
                    case constants.jobs.types.customTypes.pointReports:
                        return <span><i>{translate('point_detail_reports')}</i></span>
                    case constants.jobs.types.customTypes.submitOfflinePackage:
                        return <span><i>{translate('submit_offline_package')}</i></span>
                    case constants.jobs.types.customTypes.analyticsProjection:
                        return <span><i>{translate('analytics_projection')}</i></span>
                    case constants.jobs.types.customTypes.createOfflinePackage:
                        return <span><i>{translate('create_offline_package')}</i></span>
                    case constants.jobs.types.customTypes.trip2Trade:
                        return <span><i>{translate('trip_2_trade')}</i></span>
                    case constants.jobs.types.customTypes.ciDashboardReport:
                        return <span><i>{translate('ci_dashboard_report')}</i></span>
                    case constants.jobs.types.customTypes.bulkAnalyticsProjection:
                        return <span><i>{translate('bulk_analytics_projection')}</i></span>
                    case constants.jobs.types.customTypes.ciCannibalization:
                        return <span><i>{translate('ci_cannibalization')}</i></span>
                    case constants.jobs.types.customTypes.externalAnalyticsProjection:
                        return <span><i>{translate('external_analytics_projection')}</i></span>
                    case constants.jobs.types.customTypes.ciCustomChannels:
                        return <span><i>{translate('ci_custom_channels')}</i></span>
                    case constants.jobs.types.customTypes.analyticsRelocation:
                        return <span><i>{translate('analytics_relocation')}</i></span>
                    case constants.jobs.types.customTypes.ciKeyInsightsReport:
                        return <span><i>{translate('ci_key_insights_report')}</i></span>
                    }
        }
    };

    const getIcon = (o)=>{

        switch (o.type) {
            default:
                return ''
            case constants.notifications.types.adHocDataSource:
                return <Icon className='app-notification-icon' icon={icons.folderArrowUp} />
            case constants.notifications.types.map:
                return <Icon className='app-notification-icon' icon={icons.map} />
                case constants.notifications.types.savedReport:
                    return <Icon className='app-notification-icon' icon={icons.fileBarChart} />                
            case constants.notifications.types.task:
                return <Icon className='app-notification-icon' icon={icons.task} />
            case constants.notifications.types.message:
                return <img src='https://cdn.tradeareasystems.net/Images/Rebrand/KalibrateHeader.png' alt={constants.company} />
            case constants.notifications.types.job:
                const jobMetadata = getJobMetaData(o);
        
                switch (jobMetadata.taskType) {
                    default:
                        return <Icon className='app-notification-icon' icon={icons.gear} />
                    case constants.jobs.types.demographics:
                        return <Icon className='app-notification-icon' icon={icons.people} />
                    case constants.jobs.types.closestStore:
                        return <Icon className='app-notification-icon' icon={icons.store} />
                    case constants.jobs.types.mapBook:
                        return <Icon className='app-notification-icon' icon={icons.bookBookmark} />
                    case constants.jobs.types.custom:

                        switch (jobMetadata.customTaskType) {
                            default:
                                return <Icon className='app-notification-icon' icon={icons.gear} />
                            case constants.jobs.types.customTypes.managementReport:
                                return <Icon className='app-notification-icon' icon={icons.people} />
                            case constants.jobs.types.customTypes.voidAnalysis:
                                return <Icon className='app-notification-icon' icon={icons.empty} />
                            case constants.jobs.types.customTypes.mobility:
                                return <Icon className='app-notification-icon' icon={icons.mobile} />
                            case constants.jobs.types.customTypes.demographicReport:
                                return <Icon className='app-notification-icon' icon={icons.fileLines} />
                            case constants.jobs.types.customTypes.importExport:
                                return <Icon className='app-notification-icon' icon={icons.folderArrowUp} />
                            case constants.jobs.types.customTypes.adHocDataSource:
                                return <Icon className='app-notification-icon' icon={icons.folderArrowUp} />
                            case constants.jobs.types.customTypes.pointReports:
                                return <Icon className='app-notification-icon' icon={icons.braille} />
                            case constants.jobs.types.customTypes.submitOfflinePackage:
                                return <Icon className='app-notification-icon' icon={icons.wifiSlash} />
                            case constants.jobs.types.customTypes.analyticsProjection:
                                return <Icon className='app-notification-icon' icon={icons.chartMixed} />
                            case constants.jobs.types.customTypes.createOfflinePackage:
                                return <Icon className='app-notification-icon' icon={icons.wifiSlash} />
                            case constants.jobs.types.customTypes.trip2Trade:
                                return <Icon className='app-notification-icon' icon={icons.suitcase} />
                            case constants.jobs.types.customTypes.ciDashboardReport:
                                return <Icon className='app-notification-icon' icon={icons.pieChart} />
                            case constants.jobs.types.customTypes.bulkAnalyticsProjection:
                                return <Icon className='app-notification-icon' icon={icons.chartMixed} />
                            case constants.jobs.types.customTypes.ciCannibalization:
                                return <Icon className='app-notification-icon' icon={icons.circleThreeQuarters} />
                            case constants.jobs.types.customTypes.externalAnalyticsProjection:
                                return <Icon className='app-notification-icon' icon={icons.external} />
                            case constants.jobs.types.customTypes.ciCustomChannels:
                                return <Icon className='app-notification-icon' icon={icons.store} />
                            case constants.jobs.types.customTypes.analyticsRelocation:
                                return <Icon className='app-notification-icon' icon={icons.truckRampBox} />
                            case constants.jobs.types.customTypes.ciKeyInsightsReport:
                                return <Icon className='app-notification-icon' icon={icons.binoculars} />
                            }
                }
        }
    };

    const getLinkifyText = (text)=>{
        return <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                    </a>
                )}
            >
                {text}
            </Linkify>
    }

    const getDetail = (o)=>{

        switch (o.type) {
            default:
                return <div className='app-notification-announcement'>{o.message}</div>
            case constants.notifications.types.map:
                return <><span style={{fontWeight: 700}}>{o.owner.Name}</span> {translate('shared_the_map')} <span style={{fontWeight: 700}}>{o.title}</span></>
            case constants.notifications.types.savedReport:
                return <><span style={{fontWeight: 700}}>{o.owner.Name}</span> {translate('shared_the_report')} <span style={{fontWeight: 700}}>{o.title}</span></>                
            case constants.notifications.types.adHocDataSource:
                return <><span style={{fontWeight: 700}}>{o.owner.Name}</span> {translate('shared_the_adhoc')} <span style={{fontWeight: 700}}>{o.title}</span></>
            case constants.notifications.types.task:
                return <span style={{fontWeight: 700}}>{o.title}</span>
            case constants.notifications.types.job:
                const jobMetadata = getJobMetaData(o);
                if (jobMetadata.taskType === constants.jobs.types.custom && jobMetadata.customTaskType === constants.jobs.types.customTypes.adHocDataSource)
                    return <>{getType(o)} <span style={{fontWeight: 700}}>{o.title}</span> {translate('was_shared_with_you')}</>
                else
                    return <>{getType(o)} {translate('job')} <span style={{fontWeight: 700}}>{o.title}</span> {translate('finished_processing')} {getStatus(o)}</>
        }
    };

    const renderViewed = (o)=>{        
        if (o.isViewed)
            return <Button className='app-job-management-mark-read' theme='simple' icon={icons.circle} 
                tooltip={translate('mark_as_unread')} 
                onClick={() => { 
                    markNotification({ id: o.id, type: o.type, isViewed: false });
                }} 
            />
        else
            return <Button className='app-job-management-mark-unread' theme='simple' icon={icons.circle} 
                tooltip={translate('mark_as_read')} 
                onClick={() => { 
                    markNotification({ id: o.id, type: o.type, isViewed: true });
                }} 
            />
    };

    const viewNotification = (o)=>{

        switch (o.type) {
            default:
                // unknow notification type
                break;
            case constants.notifications.types.message:
                setExpandMessage(!expandMessage);
                break;
            case constants.notifications.types.job:
                const jobMetadata = getJobMetaData(o);
                switch (jobMetadata.taskType) {
                    default:
                        break;
                    case constants.jobs.types.demographics:
                    case constants.jobs.types.closestStore:
                        navigation.forceExpand({ section: constants.navigation.sections.jobs });
                        break;
                    case constants.jobs.types.custom:
                        switch (jobMetadata.customTaskType) {
                            // TBD: where should these custom job types navigate to?
                            default:
                            case constants.jobs.types.customTypes.voidAnalysis:
                            case constants.jobs.types.customTypes.importExport:
                            case constants.jobs.types.customTypes.submitOfflinePackage:
                            case constants.jobs.types.customTypes.analyticsProjection:
                            case constants.jobs.types.customTypes.createOfflinePackage:
                            case constants.jobs.types.customTypes.bulkAnalyticsProjection:
                            case constants.jobs.types.customTypes.ciCannibalization:
                            case constants.jobs.types.customTypes.externalAnalyticsProjection:
                            case constants.jobs.types.customTypes.ciCustomChannels:
                            case constants.jobs.types.customTypes.analyticsRelocation:
                                break;
                            case constants.jobs.types.customTypes.managementReport:
                            case constants.jobs.types.customTypes.mobility:
                            case constants.jobs.types.customTypes.demographicReport:
                            case constants.jobs.types.customTypes.pointReports:
                            case constants.jobs.types.customTypes.ciDashboardReport:
                            case constants.jobs.types.customTypes.ciKeyInsightsReport:
                                navigation.forceExpand({ section: constants.navigation.sections.jobs });
                                break;
                            case constants.jobs.types.customTypes.trip2Trade:
                                // TBD: T2T shouldn't be under jobs, but in its own category (below)
                                /*
                                if (jobMetadata.status === constants.jobs.statuses.completed)
                                    trip2TradeModule.showLayers([o.id]);
                                */
                                break;
                            case constants.jobs.types.customTypes.adHocDataSource:
                                tools.openAdhocDatasources();
                                break;
                        }
                        break;
                }            
                break;
            case constants.notifications.types.map:
                projects.openAvailableMaps({ tab: 1, map: o.objectId });
                break;
            case constants.notifications.types.savedReport:
                reports.openReportManager();
                break;                
            case constants.notifications.types.task:
                app.toggleTasks();
                break;
            case constants.notifications.types.offlinePackage:
                // TBD
                break;
            case constants.notifications.types.geoFeeds:
                // TBD
                break;
            case constants.notifications.types.siteTours:
                // TBD
                break;
            case constants.notifications.types.trip2Trade:
                // TBD: T2T category should have status in metadata - load renderer using the id
                /*
                const t2tMetadata = getJobMetaData(o);
                if (t2tMetadata.status === constants.jobs.statuses.completed)
                    trip2TradeModule.showLayers([o.id]);
                */
                break;
            case constants.notifications.types.adHocDataSource:
                tools.openAdhocDatasources();
                break;
        }
    };
    
    // display notification details based upon the notification type:
    // display messages with ellipsis by default, then display the full message when the user clicks on it
    // display tasks with 2 details: title and message
    // display the date for all notifications
    // display the user badge and name for all notifications that have an owner (all except jobs)
    var classes = ['app-notification-container'];
    if (notification.type === constants.notifications.types.message && expandMessage)
        classes.push('app-notification-container-expand');    

    return <>
        <div className={classes.join(' ')}>
            <Clickable
                className={`app-map-notification ${!notification.isViewed ? 'app-notification-new' : ''}`} onClick={() => { viewNotification(notification); }}
            >
                <div className='app-notification-icon-container'>
                    {getIcon(notification)}
                </div>
                <div className='app-notification-details'>
                    { notification.type !== constants.notifications.types.message || !expandMessage ?
                        <div className='app-notification-detail'>
                            {getDetail(notification)}
                        </div>
                    : '' }
                    { notification.type === constants.notifications.types.task || (notification.type === constants.notifications.types.message && expandMessage) ?
                        <div className='app-notification-detail'>
                            {getLinkifyText(notification.message)}  
                        </div>
                    : ''}
                    <div className='app-notification-detail app-notification-date'>
                        {helpers.formatDate({ date: helpers.convertJSONDate(notification.date) })}
                    </div>
                    { _.isObject(notification.owner) && notification.type !== constants.notifications.types.message?
                        <div className='app-notification-user'>
                            <div className='app-notification-user-image-container'>
                                <img className='app-notification-user-image' src={userImage} alt={notification.owner.Name} title={notification.owner.Name} />
                            </div>
                            <div className='app-notification-user-name-container'>
                                <span style={{fontWeight: 700}}>{notification.owner.Name}</span>
                            </div>
                        </div>
                    : ''}
                </div>
            </Clickable>
            <div className='app-notification-viewed-container'>
                {renderViewed(notification)}
            </div>
        </div>
    </>
}
