// App imports
import { map } from '../components/app/map/map';
import { constants } from '../utils/constants';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { translate } from '../utils/translation';
import { helpers } from '../utils/helpers';
import { layers } from '../components/app/layers/layers';
import { layerActions } from '../components/app/layers/layer/layer';

const _ = require("lodash");

export const hotSpotAnalysis = {
	getLayers: () =>{
		return map.layers.filter(layer => layer.group === constants.layers.groups.hotSpotAnalysis);
	},
    getSelectedIds: () =>{
        return hotSpotAnalysis.getLayers().map(layer => {return layer.id;});
    },
	getActiveLayers: () =>{
		return hotSpotAnalysis.getLayers().filter(layer => layer.active);
	},
    refresh: async ({ id, geographyVintageId, geoLevelId, geoLevelName, optimalMaxZoom, fields }) =>{

        if (hotSpotAnalysis.getLayers().length === 0 && !_.isString(id))
            return;
        else if (!_.isString(id))
            id = hotSpotAnalysis.getLayers()[0].id;        

        //Remove any layers that aren't current id
        hotSpotAnalysis.getLayers().filter(x => x.id !== id).forEach(x => x.dispose());

        var layer = hotSpotAnalysis.getLayers().find(x => x.id == id);
		
        if (!_.isObject(layer))
            layer = map.addLayer({
                id: id,
                group: constants.layers.groups.hotSpotAnalysis,
                type: constants.layers.types.data,
                text: translate('hot_spot_analysis'),
                subText: geoLevelName,
                actions: [{
                    id: layerActions.visible,
                    getActive: () => { return layer.visible; },
                    getHalfActive: () => { return layer.oppositeVisibilityEntities.length > 0; },
                    onClick: () =>{                        
                        layer.visible = !layer.visible;
                    }
                },
                {
                    id: layerActions.delete,
                    onClick: () =>{
                        layer.dispose();
                    }
                }],
                onChange: (c)=>{
                    layers.update();
                },
                metaData:{
                    geographyVintageId: geographyVintageId, 
                    geoLevelId: geoLevelId, 
                    optimalMaxZoom: optimalMaxZoom, 
                    fields: fields
                }
            });

        const getEntityVisibility = (groupId) =>{ return layer.oppositeVisibilityEntities.indexOf(groupId) > -1 ? !layer.visible : layer.visible; };
            
        layer.clear();
                    
        layer.outOfRange = layer.metaData.optimalMaxZoom > map.zoom;
            
        if (layer.outOfRange)
            return;

        layer.loading = true;

        var result = await legacyEndpoints.service({
            name: 'GenerateHotSpotAnalysis',
            suppressError: true,
            parameters: {
                geographyVintageId: layer.metaData.geographyVintageId, 
                geoLevelID: layer.metaData.geoLevelId, 
                fields: layer.metaData.fields, 
                boundingBox: {
                    polygonType: constants.encodedString.google,
                    polygonString:  helpers.encodedLocations(helpers.createRectangle({ topLeft: map.bounds.northEast, bottomRight: map.bounds.southWest}))
                }, 
                indexIds: null, 
                colorRamp: null
            }
        });

        if (result === undefined)
        {
            layer.loading = false;
            layer.hasData = false;
            return;
        }

        JSON.parse(result.data).features.forEach(feature =>{
            layer.addEntity({
                id: feature.id,
                groupId: feature.properties.fill, 
                type: constants.entities.geoJson,
                data: feature,
                fillColor: helpers.stringToColor(feature.properties.fill, parseFloat(feature.properties["fill-opacity"])),
                strokeColor: helpers.stringToColor(feature.properties.fill, 0),
                strokeWidth: parseInt(feature.properties["stroke-width"]),
                visible: getEntityVisibility(feature.properties.fill)
            });
        });

        layer.legend = result.legend.map(legend =>{

            legend.groupId = legend.Symbol;

            return {
                groupId: legend.groupId,
                text: legend.Name, 
                color: legend.Symbol,
                actions: [{
                    id: layerActions.visible,
                    getActive: () => { return getEntityVisibility(legend.groupId); },
                    onClick: () =>{

                        if (layer.oppositeVisibilityEntities.indexOf(legend.groupId) > -1)
                            layer.oppositeVisibilityEntities = layer.oppositeVisibilityEntities.filter(x => x !== legend.groupId);
                        else
                            layer.oppositeVisibilityEntities.push(legend.groupId);
                        
                        layer.entities.filter(x => x.groupId === legend.groupId).forEach(x => x.visible = getEntityVisibility(legend.groupId));                            
                    }
                }]
            };
        });

        layer.loading = false;

	}
};