// App imports
import { legacyEndpoints } from '../services/legacyEndpoints';
import { map } from '../components/app/map/map';

const _ = require("lodash");

export const favorites = {    
    get: () => {
        return legacyEndpoints.service({ 
            name: 'GetFavorites',
            parameters: {
                LocationID: 2
            }
        });
    },
    add: (name) =>{        
        return legacyEndpoints.service({ 
            name: 'CreateNewFavorite',
            parameters: {
                aDirectoryID: 2,
                aName: name,
                aLat: map.center.lat,
                aLon: map.center.lon,
                aZoom: map.zoom,
                aStyle: 'R'
            }
        });
    },
    delete: (id) =>{
        return legacyEndpoints.service({ 
            name: 'DeleteFavorite',
            parameters: {
                aDirectoryID: 2,
                aFavoriteIdList: [id]
            }
        });
    }
};