// Third party imports
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

// App imports
import { Button } from '../button/button';
import { Icon, icons } from '../icon/icon';
import { translate } from '../../../utils/translation';

const _ = require("lodash");

export function DraggableItem({id, children, onDelete}) {

	const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({id: id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? "100" : "auto",
        opacity: isDragging ? 0.3 : 1
    };

    return <div ref={setNodeRef} key={id} style={style} className={'app-drag-and-drop-row'}>
        <div className='app-drag-and-drop-button app-button-row'>
            <button {...attributes} {...listeners} className='app-drag-and-drop-move-button'><Icon icon={icons.ellipses}/></button>
        </div>
        <div className={'app-drag-and-drop-item'}>
            {children}
        </div>
        { !_.isFunction(onDelete) ? '' :
            <div className='app-drag-and-drop-button app-button-row'>												
                <Button size='small' icon={icons.trash} tooltip={translate('remove')} onClick={onDelete} />
            </div>
        }
    </div>
}