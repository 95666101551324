// React imports
import { useEffect, useState } from 'react';

// App imports
import { TextBox } from '../../../base/textBox/textBox';
import { TextArea } from '../../../base/textArea/textArea';
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';

export function EditPhotoDetails({photo,onClear,onSave}){

    const [editPhoto, setEditPhoto] = useState({id: '', photoDescription: '', title: ''});

    useEffect(()=>{
        setEditPhoto({
            id: photo?.id,
            title: photo?.title,
            photoDescription: photo?.photoDescription,
        });
    },[photo]);      
    
    function handleChange(e) {
        setEditPhoto((editPhoto) => ({ ...editPhoto, [e.name]: e.value }));
    }    

	return (
        <div className='app-photos-edit-details'>
            <TextBox className='app-photos-details-textbox-title' height='47px' label={translate('title')} name='title' value={editPhoto?.title} onChange={ handleChange }  />
            <TextArea className='app-photos-details-textbox-description' minHeight={141} label={translate('description')} name='photoDescription' value={editPhoto?.photoDescription} onChange={ handleChange } />            
            <TextBox className='app-photos-details-textbox-tags' height='47px' label={translate('hashTags')} name='tags' disabled={true} />            
                                                
            <div className='app-photos-edit-details-actions-section'>
                <Button className='app-photos-edit-details-actions-section-icon' theme='tertiary' tooltip={translate('clear')} size='medium' icon={icons.arrowTurnDownLeft} onClick={()=>{onClear() }} />	                    
                <Button className='app-photos-edit-details-actions-section-icon' theme='tertiary' tooltip={translate('save')} size='medium'icon={icons.save} onClick={()=>{onSave(editPhoto) }} />	
            </div>
        </div>
    );
}