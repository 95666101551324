// React imports
import { useState } from 'react';

import { Window } from '../window/window';
import { warningToast } from '../toast/toast';
import { translate } from '../../../utils/translation';

const _ = require("lodash");

export var workBench;

export function WorkBench() {

    var [content, setContent] = useState(null);
    var [visible, setVisible ] = useState(false);
    var [title, setTitle] = useState('');
    var [width, setWidth] = useState('70%');
    var [height, setHeight] = useState('500px');
    var [maxWidth, setMaxWidth] = useState(null);
    var [maxHeight, setMaxHeight] = useState(null);
    var [onClose, setOnClose] = useState({});
    var [startMinimized, setStartMinimized] = useState(false);
    var [preventClose, setPreventClose] = useState(false);

    workBench = {
        setContent: (o) => {

            const close = () => {
                setPreventClose(false);

                if (_.isFunction(o.onClose))
                    o.onClose(); 
            };

            if (preventClose) {
                warningToast(translate('close_workbench_warning'));
                return false;
            }
            else {
                setVisible(true);
                setContent(o.content); 
                setTitle(o.title ? o.title : '');
                setWidth(o.width ? o.width : '70%');
                setHeight(o.height ? o.height : '500px');
                setMaxWidth(o.maxWidth ? o.maxWidth : null);
                setMaxHeight(o.maxHeight ? o.maxHeight : null);
                setOnClose({ action: close });
                setStartMinimized(_.isBoolean(o.startMinimized) ? o.startMinimized : false);
                setPreventClose(_.isBoolean(o.preventClose) ? o.preventClose : false);
            }

            return true;
        },
        close: ()=> {
            setVisible(false);
            setContent(null);
            setPreventClose(false);
        }
    };
    
    return <Window 
        position='bottom' 
        visible={visible} 
        setVisible={setVisible} 
        title={title} 
        width={width} 
        height={height} 
        maxWidth={maxWidth} 
        maxHeight={maxHeight}
        dragEnabled={false}
        allowMinimize={true}
        startMinimized={startMinimized}
        onClose={onClose.action}
    >
        {content}
    </Window>
}