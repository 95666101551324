// React imports
import { useState, useEffect } from 'react';

// App imports
import { SelectableItems } from '../../../base/selectableItems/selectableItems';
import { CollapsibleSection } from '../../../base/collapsibleSection/collapsibleSection';
import { OptionSelector } from '../../../base/optionSelector/optionSelector';
import { TextBox } from '../../../base/textBox/textBox';
import { Button } from '../../../base/button/button';
import { Bar } from '../../../base/bar/bar';
import { Icon } from '../../../base/icon/icon';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { helpers } from "../../../../utils/helpers";
import { userPreferences } from '../../app';

export function MobilityReportsStep2({jobList, updateJobList, expandedJobs, updateExpandedJobs, selectAllJobs, updateSelectAllJobs, reportTitle, updateReportTitle, outputFormat, updateOutputFormat, onReadyNext}) {

    const [searchText, setSearchText] = useState('');

    useEffect(() => {

        const selected = jobList.filter(site => site.jobs.some(item => item.selected === true));

        if (onReadyNext)
            onReadyNext(selected.length > 0 && reportTitle.length > 0 && outputFormat != null);

    }, [onReadyNext, jobList, reportTitle, outputFormat])

    useEffect(() => {

        const newJobs = jobList.map((newGroup, i) => ({
            ...newGroup,
            jobs: newGroup.jobs.map(job => {
                    return {...job, hidden: !(new RegExp(helpers.escapeRegex(searchText), 'i').test(job.text)) };
                })
            }
        ));

        var newExpanded = [];

        if (searchText.length === 0)
            newJobs.forEach((group) =>{
                newExpanded.push(false);
            });
        else
            newJobs.forEach((group) =>{

                var expand = false;
                group.jobs.forEach((job) =>{
                    if (!job.hidden)
                        expand = true;
                });

                newExpanded.push(expand);
            });

        updateJobList([...newJobs]);        
        updateExpandedJobs(newExpanded);

        // disable missing dependencies warning - only care about searchText changing and adding others causes issues with expand/collapse
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText])

    const selectJobs = (o) =>{

        if (!o.selected) {
            const newSelected = selectAllJobs.map((selected, j) => {
                if (o.groupIndex === j)
                    return false;
                else
                    return selected;
            });

            updateSelectAllJobs(newSelected);
        }

        o.item.selected = o.selected;
        updateJobList([...jobList]);        
    };

    const resetSelectAllButtons = (allJobs) =>{

        allJobs.forEach((site, i) =>{
            site.jobs.forEach((job) =>{
                if (!job.selected)
                    selectAllJobs[i] = false;
            });
        });
    };
    
    const getSelectAllAction = (o) =>{
        return {
            icon: selectAllJobs[o.index] ? icons.squareCheck : icons.squareEmpty,
            active: selectAllJobs[o.index],
            tooltip: selectAllJobs[o.index] ? translate('unselect_all') : translate('select_all'),
            disabled: o.count === 0,
            onClick: (e) => {
                e.stopPropagation();

                const newSelected = selectAllJobs.map((selected, j) => {
                    if (o.index !== j)
                        return selected;
                    else
                        return !selected;
                });

                const newJobs = jobList.map((newGroup, i) => ({
                    ...newGroup,
                    jobs: newGroup.jobs.map(job => {
                            if (!job.hidden && i === o.index)
                                return {...job, selected: newSelected[o.index] };
                            else
                                return {...job };
                        })
                    }
                ));

                updateSelectAllJobs(newSelected);
                updateJobList([...newJobs]);        
            }
        }
    };  

    return <>
        <div className='app-mobility-data-step-2-container'>
            <div className='app-mobility-data-step-2-column'>
                {
                    jobList.length === 0 ?
                        <div className='app-mobility-data-labels app-mobility-data-warning'>{translate('no_jobs_exist')}</div>
                    : <>
                        <div className='app-mobility-data-labels'>{translate('included_jobs')}</div>
                        <div className='app-mobility-search-container'>
                            <TextBox className='app-mobility-search-input' value={searchText} height={40} placeholder={translate('search') + '...'} 
                                valueChangeEvent={'change keyup'} onChange={(o) => { setSearchText(o.value); }} 
                            />								
                            <Button className='app-mobility-search-button' theme='primary' size='small' icon={icons.magnifyingGlass} tooltip={translate('search')} 
                                onClick={(o) => { setSearchText(searchText.trim()); }}
                            />
                        </div>
                        <div className='app-mobility-data-report-columns'>
                            <SelectableItems
                                items={jobList}
                                selectedItems={jobList.map((item) => { return item.jobs.filter(x => x.selected) }).filter(item => item.length > 0).flat()}
                                onSelect={(o) => { updateJobList([...o.items]); resetSelectAllButtons(o.items); }}
                                onRender={(o) => {
                                    return <div className='app-mobility-data-rows-container'>
                                    {
                                        o.items.map((group, i) => {
                                            return <CollapsibleSection key={i} text={group.text} count={group.jobs.filter(j => !j.hidden).length} expanded={expandedJobs[i]} 
                                                selectedCount={group.jobs.filter(x => x.selected).length} 
                                                actions={[getSelectAllAction({ index: i, count: group.jobs.filter(j => !j.hidden).length })]}
                                                onClick={() =>{ 
                                                    const newExpanded = expandedJobs.map((expanded, j) => {
                                                        if (i !== j)
                                                            return expanded;
                                                        else
                                                            return !expanded;
                                                    });

                                                    updateExpandedJobs(newExpanded);
                                                }}
                                                >
                                            {
                                                group.jobs.map((item, j) =>{
                                                    return (
                                                        item.hidden ? '' :
                                                        <OptionSelector key={j} text={item.text} icon={icons.mobileButton} active={item.selected} onClick={() =>{ 
                                                            selectJobs({ groupIndex: i, item: item, selected: !item.selected }); 
                                                        }}/>
                                                    )
                                                })
                                            }
                                            </CollapsibleSection>
                                        })
                                    }
                                    </div>
                                }}
                                hideFooter={true}
                            />
                        </div>
                    </> 
                }
            </div>
            <div className='app-mobility-data-vertical-line'></div>
            <div className='app-mobility-data-step-2-column'>
                <div className='app-mobility-data-labels'>{translate('name_your_job')}</div>
                <TextBox className={'app-mobility-name-input'} label={translate('job_name')} value={reportTitle} height={55} onChange={(o) => { updateReportTitle(o.value); }} />
                <div className='app-mobility-data-labels'>{translate('output_format')}</div>
                <div className='app-mobility-data-report-rows'>
                {
                    [
                        { icon: icons.excel, format: 'Excel', text: translate('excel_document'), class: 'app-mobility-data-bar-excel' },
                        { icon: icons.pdf, format: 'PDF', text: translate('pdf_document'), class: 'app-mobility-data-bar-pdf' },
                    ].map((dayPart, i) => {
                        var classes = ['app-mobility-data-bar-buttons', 'app-mobility-data-bar-short'];
                        classes.push(dayPart.class);

                        return (
                            <div key={i} className='app-option-selector'>
                                {outputFormat === dayPart.format ? 
                                <div className='app-option-selector-check'>
                                    <Icon icon={icons.check} />
                                </div> : ''}
                                <Bar className={classes.join(' ')}
                                    icon={dayPart.icon}
                                    text={dayPart.text} 
                                    active={outputFormat === dayPart.format} 
                                    onClick={() => { updateOutputFormat(outputFormat === dayPart.format ? null : dayPart.format); }} 
                                    disabled={!userPreferences.AllowSetReportOutput} 
                                /> 
                            </div>
                        )
                    })
                }
            </div>
            </div>
        </div>
    </>
}