
// React imports
import { useState, useEffect } from 'react';

// App imports
import { Header } from './header/header';
import { Navigation, navigation }from './navigation/navigation';
import { Map }  from './map/map';
import { Tasks }  from './tasks/tasks';
import { Dashboards }  from './dashboards/dashboards';
import { Loader } from '../../components/base/loader/loader';
import { ActivityHub } from './activityHub/activityHub';
import { ChatBot } from './chatBot/chatbot';
import { MapControl } from './map//mapControl/mapControl';
import { Hideable } from '../base/hideable/hideable';
import { MapTabBar } from './map/mapTabBar/mapTabBar';
import { WorkBench } from '../base/workBench/workBench';
import { RightPanel } from '../base/rightPanel/rightPanel';
import { Screen } from './screen/screen';
import { legacyEndpoints } from "../../services/legacyEndpoints";
import { translate } from '../../utils/translation';
import { clarity } from 'react-microsoft-clarity';
import { projects } from '../../modules/projects';
import { constants } from '../../utils/constants';
import { helpers } from '../../utils/helpers';

const _ = require("lodash");

export var app;
export var userPreferences = {};

export function App({context, onLoad})
{
    if (_.isFunction(onLoad))
        context = onLoad();

    const [mapLoaded, setMapLoaded] = useState(false);
    const [savedMapLoaded, setSavedMapLoaded] = useState(false);
    const [servicesLoaded, setServicesLoaded] = useState(false);
    const [mapVisible, setMapVisible] = useState(true);
    const [tasksVisible, setTasksVisible] = useState(false);
    const [searchVisible, setSearchVisible] = useState(true);
    const [dashboardsVisible, setDashboardsVisible] = useState(false);
    const [showConfirmPasswordPrompt, setShowConfirmPasswordPrompt] = useState(false);
    const beforeUnloadController = new AbortController();
    const { signal: beforeUnloadSignal } = beforeUnloadController;
    const [navigationLoaded, setNavigationLoaded] = useState(false);

    app = {
        toggleMap: () => {
            setMapVisible(true);
            setTasksVisible(false);
            setDashboardsVisible(false);
        },
        toggleTasks: () => {
            setMapVisible(false);
            setTasksVisible(true);
            setDashboardsVisible(false);
        },
        toggleDashboards: () =>{
            setMapVisible(false);
            setTasksVisible(false);
            setDashboardsVisible(true);
        }
    };

    useEffect(() => {
        legacyEndpoints.init({
            context: context,
            beforeUnloadController: beforeUnloadController,
            callback: () => {
                if (process.env.REACT_APP_VERSION_BRANCH === 'main')
                    clarity.init(`${process.env.REACT_APP_AZURE_CLARITY_API_KEY}`);

                setServicesLoaded(true);

                userPreferences = legacyEndpoints.authenticationToken.UserPreferences;
                
                if (userPreferences.RequireChangePassword && !userPreferences.IsSSO)
                    setShowConfirmPasswordPrompt(true);
            }
        });
    }, [context]);

    useEffect(() =>
    {
        const handleBeforeUnload = (e) => {
            return e.returnValue = translate('exit_message');
        };
        
        window.addEventListener('unload', (e) => {            
            legacyEndpoints.service({ name: 'SetUserSignOutDate' });
        });

        window.addEventListener('beforeunload', handleBeforeUnload, { signal: beforeUnloadSignal });
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, []);

    useEffect(() =>
    {
        const loadMap = async () => {

            var map = await projects.loadMap({mapId: userPreferences.DefaultMap});
            navigation.forceExpand({ section: constants.navigation.sections.layers });

            setSavedMapLoaded(true);            
            setSearchVisible(!helpers.isViewer() || map?.Information?.IsFixed === false);
        };

        if (!mapLoaded || userPreferences.DefaultMap === null || userPreferences.DefaultMap.length === 0)
        {
            setSavedMapLoaded(mapLoaded);
            return;
        }
        
        setSavedMapLoaded(false);
        loadMap();

    }, [mapLoaded]);
    
    return <div className={servicesLoaded && helpers.isViewer() ? 'app-element app-viewer' : 'app-element'}>
        {servicesLoaded && mapLoaded && savedMapLoaded ? '' : <Loader showLogo='true' theme='primary' size='large'/>}
        {servicesLoaded ? <Header context={context} hideSearch={!searchVisible} navigationLoaded={navigationLoaded} /> : '' }
        <div className='app-element'>            
            <Hideable className='app-element' hide={!dashboardsVisible}>
                {servicesLoaded ? <Dashboards visible={dashboardsVisible} /> : ''}
            </Hideable>            
            <Hideable className='app-element' hide={!tasksVisible}>
                {servicesLoaded ? <Tasks /> : ''}
            </Hideable>            
            <Hideable className='app-element app-map-element' hide={!mapVisible}>
                {servicesLoaded ?
                    <>
                        {
                            mapLoaded ? <>
                                <Navigation context={context} setNavigationLoaded={()=>{setNavigationLoaded(true);}} />
                                {userPreferences.UserGUID.toUpperCase() === 'CAD7F20B-8FDB-4B93-B983-EF8E42903150' ||
                                userPreferences.UserGUID.toUpperCase() === '136B9A12-A783-4FE6-81BF-95E88500F933' ||
                                userPreferences.UserGUID.toUpperCase() === '82BEC304-380D-4927-B990-A271E4959F4C' ? <ChatBot /> : null}
                                <ActivityHub />
                                {
                                    helpers.isViewer() ? null : 
                                    <>
                                        <MapTabBar />
                                        <WorkBench />
                                        <RightPanel />
                                        <Screen showConfirmPasswordPrompt={showConfirmPasswordPrompt} setShowConfirmPasswordPrompt={setShowConfirmPasswordPrompt} />
                                    </>
                                }                                
                            </> : ''
                        }
                        <div className='app-map-container'>
                            {mapLoaded ? <MapControl /> : ''}
                            <Map provider='google' onLoad={()=>{setMapLoaded(true)}} />
                        </div>
                    </> : ''
                }
            </Hideable>
        </div>
    </div>
}