// React imports
import { useState, useEffect} from 'react'; 

// App imports
import { constants } from '../../../utils/constants';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { Button } from '../../base/button/button';
import { Loader } from '../../base/loader/loader';
import { mapBooks } from '../../../modules/mapbooks';
import { TextBox } from '../../base/textBox/textBox';
import { helpers } from '../../../utils/helpers';
import { Icon } from '../../base/icon/icon';
import { DropDown } from '../../base/dropDown/dropDown';
import { Bar } from '../../base/bar/bar';
import { DataList } from '../../base/dataList/dataList';
import { Panel } from '../../base/panel/panel';
import { map } from '../map/map';
import { navigation } from '../navigation/navigation';

const _ = require('lodash');

export function MapBookJob({entities,close}) {
    const [generating, setGenerating] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [jobName, setJobName] = useState(new Date().toLocaleString());
    const [availableMapBooks, setAvailableMapBooks] = useState([]);
    const [selectedMapBook, setSelectedMapBook] = useState(null);
    const [outputFormat, setOutputFormat] = useState(mapBooks.getMapBookOutputFormats()[0]?.key);
    const [outerBookmark, setOuterBookmark] = useState(false);
    const [selectedPoints, setSelectedPoints] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [points, setPoints] = useState([]);
    
    useEffect(()=>{
        load();
    }, []);

    const load = ()=>{

        var points = entities.map(entity => {					
            return {
                id: entity.id,
                name: entity.text,
            };
        });		

		if (_.isArray(points))
        {
            setPoints(points);
            points.forEach((point) => {selectedPoints.push(point.id)});
            setSelectedItems(selectedPoints);
        }
	
	};

    const getMapBookList = async () =>{
        setLoaded(false);    

        var items = [];
        var data = await mapBooks.getMapBooksForUser();
        data.adminMapBooks.forEach((item) => {
            items.push({ id: item.id, name: item.name, type: constants.mapBooks.type.admin });
        });

        data.userMapBooks.forEach((item) => {
            items.push({ id: item.id, name: item.name, type: constants.mapBooks.type.user });
        });

        setAvailableMapBooks([...items]);
        setSelectedMapBook(items[0]?.id);
        setOutputFormat(mapBooks.getMapBookOutputFormats()[0]?.key);

        setLoaded(true);      
    };

    useEffect(() => {
        getMapBookList();
    }, [setAvailableMapBooks])

    const generate = () =>{
        setGenerating(true);

        const selectedEntities = entities.filter(site => selectedPoints.some(item => item === site.id));
        
        var points = selectedEntities.map((entity) => {

                var point = {
                    BaseImage: null,
                    BaseImageType: null,
                    Geocode: {},
                    Id: entity.id,
                    Latitude: entity.location.lat,
                    Longitude: entity.location.lon,
                    Shape: null,
                    FillColor: entity.type != constants.entities.point && entity.type != constants.entities.pushpin ? entity.fillColor : null,
                    LineColor: entity.type != constants.entities.point && entity.type != constants.entities.pushpin ? entity.strokeColor : null,
                    LineStyle: entity.type != constants.entities.point && entity.type != constants.entities.pushpin ? entity.strokeStyle : null,
                    LineWidth: entity.type != constants.entities.point && entity.type != constants.entities.pushpin ? entity.strokeWidth : 0,
                    Name: entity.label,
                    PointId: _.isNull(entity.groupId) ? null : entity.id ,
                    Selected: true,
                    ServiceId: _.isNull(entity.groupId) ? null : entity.layer.id,
                    Type: null
                } 

                switch(entity.type)
                {
                    case constants.entities.pushpin:
                        point.BaseImage = helpers.getDataFromSymbolUrl(entity.image).name;
                        point.BaseImageType = helpers.getDataFromSymbolUrl(entity.image).type;
                        point.Type = constants.mapBooks.mapBookPointType.pushpin;

                        map.geocode({
                            query: `${entity.location.lat} ${entity.location.lon}`,
                            callback: (result) => {      
                                point.Geocode.Latitude = entity.location.lat;
                                point.Geocode.Longitude = entity.location.lon;
                                point.Geocode.Address = result.street;
                                point.Geocode.City = result.city;
                                point.Geocode.State = result.state;
                                point.Geocode.Zip = result.zip;
                            }
                        });
                        break;
                    case constants.entities.point:
                        point.BaseImage = helpers.getDataFromSymbolUrl(entity.image).name;
                        point.BaseImageType = helpers.getDataFromSymbolUrl(entity.image).type;
                        point.Type = constants.mapBooks.mapBookPointType.point;
                        break;
                    default:
                        point.Type = constants.mapBooks.mapBookPointType.shape;
                        point.BaseImageType = constants.symbols.types.shapeStyle;
                        point.Shape = {
                            polygonString: helpers.pointsToWkt(entity.paths),
                            polygonType: constants.encodedString.wkt
                        }
                        break;
                }

                return point;
            }
        );

        const mapBook = availableMapBooks.find((m) => m.id === selectedMapBook);

        mapBooks.addMapBookJob({
            id: mapBook.id,
            name: jobName,
            customQueryId: null,
            executionType: constants.mapBooks.executionType.selection,
            outputFormat: outputFormat,
            bookmark: outerBookmark,
            type: mapBook.type,
            points: points,
            callBack: () => {
                navigation.updateJobs();
                setGenerating(false); 
                close();
            }
        });
    };
  
    const dataListRow = (point) =>{

        return <div key={point.id}>
                    <table>
                        <tbody>
                            <tr>
                                <td className='app-trip-2-trade-renderers-name'>
                                    {point.name} 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>        
    };       

    return  <>
    <Panel text={translate('add_map_book_job')} open={true} loaded={loaded} generated={true}  onClose={() => { close(); }}>
        { generating ? <Loader theme='generate' showLogo={true}/> : ''}
        { loaded ? '' : <Loader/>} 
                <div>
                    <Button className={'app-map-book-job-row-button'} theme='primary' size='small' icon={icons.check} text={translate('generate')}  tooltip={translate('generate')} onClick={() =>{ generate(); }}/>
                    <Button className={'app-map-book-job-row-button'} theme='secondary' size='small' icon={icons.x} text={translate('close')}  tooltip={translate('close')} onClick={() =>{ close(); }}/>                    
                </div>
                <TextBox className='app-map-book-job-textBox'
                    height='40px' label={translate('job_name')} placeholder={translate('job_name')}
                    value={jobName}
                    onChange={(o) => {setJobName(o.value)}} 
                />
                    <DropDown label={translate('map_books')} 
                        className={'app-map-book-job-dropdown'}
                        height='40px'
                        placeholder={translate('select')}
                        valueProperty={'id'}
                        display={'name'}
                        selected={selectedMapBook}
                        items={availableMapBooks}
                        onChange={(o) => { setSelectedMapBook(o.value);}}
                    />
                    <DropDown label={translate('output_type')} 
                        className={'app-map-book-job-dropdown'}
                        height='40px'
                        placeholder={translate('select')}
                        valueProperty={'key'}
                        display={'name'}
                        selected={outputFormat}
                        items={mapBooks.getMapBookOutputFormats()}
                        onChange={(o) => { setOutputFormat(o.value);}}
                    />

                    <div>
                        <div className='app-option-selector'>
                            {outerBookmark ? 
                            <div className='app-option-selector-check'>
                                <Icon icon={icons.check} />
                            </div> : ''}
                            <Bar className={'app-map-book-job-bar-buttons app-map-book-job-bar-short'} 
                                icon={icons.bookBookmark} 
                                text={translate('outer_bookmark')} 
                                active={outerBookmark} 
                                onClick={() => { setOuterBookmark(!outerBookmark); }} 
                            /> 
                        </div>
                    </div>

                    <div className='app-map-book-job-point-selections'>                       
                        <DataList
                                    keyExpr={'id'}
                                    dataSource={points}
                                    height={200}
                                    itemRender={dataListRow}
                                    selectedItemsChange={(o)=> {setSelectedPoints(o)}}
                                    selectedItems={selectedItems}
                                    selectByClick={false}
                                />             
                    </div>
    </Panel>
   </>
}