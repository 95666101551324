// React imports
import { useMemo } from 'react';

// App imports
import { DropDown } from '../../../base/dropDown/dropDown';
import { Popover } from '../../../base/popover/popover';
import { NumberBox } from '../../../base/numberBox/numberBox';
import { translate } from "../../../../utils/translation"
import { constants } from '../../../../utils/constants';

const _ = require("lodash");

export function PrintCustomerMapPopover({ parentId, title, active = true, storeCount, updateStoreCount, maxDistance, updateMaxDistance, distanceMeasurement, updateDistanceMeasurement }) {

    const numberOfStores = useMemo(() => [
        { key: 1, text: '1' }, 
        { key: 2, text: '2' }, 
        { key: 3, text: '3' }, 
        { key: 4, text: '4' },
        { key: 5, text: '5' },
        { key: 6, text: '6' },
        { key: 7, text: '7' },
        { key: 8, text: '8' },
        { key: 9, text: '9' },
        { key: 10, text: '10' }
    ], []);

    const measurements = useMemo(() => [
        { key: constants.lengthMeasurements.miles, text: 'mi' },
        { key: constants.lengthMeasurements.kilometers, text: 'km' }
    ], []);

    return <>
        { !active ? '' :
        <Popover 
            parentId={`#${parentId}`}
            title={title}
            height={260}
            width={275}
        >
            <div className='app-projects-print-cols'>
                { _.isUndefined(storeCount) ? '' :
                <div className='app-projects-print-rows'>
                    <div className='app-projects-print-labels app-projects-print-stretch'>{translate('number_of_stores')}:</div>
                    <DropDown className={'app-projects-print-input'} items={numberOfStores} height={'36px'} width={'70px'}
                        display='text' valueProperty='key' selected={storeCount} 
                        onChange={(o) => { updateStoreCount(o.value); }} />
                </div>
                }
                { _.isUndefined(maxDistance) ? '' :
                <div className='app-projects-print-rows'>
                    <div className='app-projects-print-labels app-projects-print-stretch'>{translate('distance_cutoff_2')}:</div>
                    <div className='app-projects-print-textbox'>
                    <NumberBox 
                        className={'app-projects-print-input'} 
                        height={'36px'} 
                        value={maxDistance}
                        maxValue={100}
                        minValue={0}
                        format={'##0.#'}
                        onChange={(o) => { updateMaxDistance(o.value); }} 
                    />
                    </div>
                </div>
                }
                { _.isUndefined(distanceMeasurement) ? '' :
                <div className='app-projects-print-rows'>
                    <div className='app-projects-print-labels app-projects-print-stretch'>{translate('measurement')}:</div>
                    <DropDown className={'app-projects-print-input'} items={measurements} height={'36px'} width={'70px'}
                        display='text' valueProperty='key' selected={distanceMeasurement} 
                        onChange={(o) => { updateDistanceMeasurement(o.value); }} />
                </div>
                }
            </div>
        </Popover>
        }
    </>
}