import { Hideable } from '../hideable/hideable';
import { constants } from '../../../utils/constants';

export function Loader({ className, loaded, size, showLogo, logo='https://cdn.tradeareasystems.net/Images/Rebrand/KalibrateK.png', theme }) {

    let dots = [1,3,5];
    let classes = ['app-loader-container'];

    if (className)
        classes.push(className);

    switch (theme)
    {
        default:
            classes.push('app-loader-default');
            break;
        case 'primary':
            classes.push('app-loader-primary');
            break;
        case 'generate':
            classes.push('app-loader-generate');
            break;
    }

    switch (size)
    {
        default:
            break;
        case 'large':
            dots = [1,2,3,4,5];
            classes.push('app-loader-large');
            break;
    }

    return <>
        {loaded ?
            '' :
            <Hideable className={classes.join(' ')} fillHeight={true}>
                <div className='app-loader'>
                    {(showLogo ? <img className='app-loader-logo' src={logo} title={constants.company} alt={constants.company} /> : '')}
                    <div>
                    {
                        dots.map((dot, i) => {
                            return <div key={i} className={'app-loader-dot app-loader-dot' + dot} />
                        })
                    }
                    </div>
                </div>
            </Hideable>
        }
    </>
}