// React imports
import { useState, useEffect, useCallback } from 'react'

// App imports
import { successToast } from '../../../../base/toast/toast';
import { DropDown } from '../../../../base/dropDown/dropDown';
import { TextBox } from '../../../../base/textBox/textBox';
import { Button } from '../../../../base/button/button';
import { Loader }  from '../../../../base/loader/loader';
import { icons } from '../../../../base/icon/icon';
import { helpers } from "../../../../../utils/helpers";
import { constants } from '../../../../../utils/constants';
import { translate } from '../../../../../utils/translation';
import { competitiveInsights as ciModule } from '../../../../../modules/competitiveInsights';

const _ = require("lodash");

export function CIDashboardReport({ siteId, settings, updateSettings, renderedCharts, title, percents, reports, loaded, visible, exportMetadata, updateExportMetadata, updateDashboardData }) {
    
    const [generating, setGenerating] = useState(false);

    const generateExportCharts = useCallback(
        () => {

            if (!exportMetadata.active || !exportMetadata.create || exportMetadata.images.length > 0  || exportMetadata.sessionId == null || renderedCharts.length < 6)
                return;


            renderedCharts.forEach(chart => {
                if (_.isObject(chart.chartRef)) {
                    // this export will cause the chart's onFileSaving event to fire
                    chart.chartRef.instance.exportTo(chart.id, "PNG");
                }
            });

            const sessionId = exportMetadata.sessionId;

            updateExportMetadata({
                sessionId: null,
                images: [],
                active: false,
                init: false,
                create: false
            });

            // TBD: would like to poll for the completion of image exports, but metadata image array does not get updated with mutliple saves firing quickly
            //const timeoutId = setInterval(() => {
            setTimeout(() => {
                //if (exportMetadata.images.length >= 6) {
                //clearTimeout(timeoutId);

                ciModule.createDashboardReportJob({
                    siteId: siteId,
                    sessionId: sessionId,
                    settings: settings,
                    title: title
                });

                successToast(translate('successfully_submitted_report'));
                setGenerating(false);

                //}
            }, 10000);

        },

        [exportMetadata, siteId, settings, renderedCharts, title, updateExportMetadata]
    );

    useEffect(() => {
        generateExportCharts();
    }, [generateExportCharts]); 

    const generateUpdateData = useCallback(
        async () => {

            if (!exportMetadata.active || !exportMetadata.init || exportMetadata.images.length > 0  || exportMetadata.sessionId == null)
                return;

            var dashboards = [
                constants.competitiveInsights.dashboards.siteDetail,
                constants.competitiveInsights.dashboards.marketShare,
                constants.competitiveInsights.dashboards.multiSite
            ];

            renderedCharts.forEach(chart => {
                if (_.isObject(chart.chartRef)) {
                    dashboards = _.without(dashboards, chart.dashboardId);
                }
            });

            if (dashboards.length > 0) {
                const data = await ciModule.getDashboards({
                    dashboards: dashboards,
                    siteId: siteId,
                    settings: settings
                });

                var updateData = [];

                data.forEach((d,i) => {
                    updateData.push({ id: dashboards[i], data: d });
                });

                updateDashboardData(updateData);
            }    

            // this update will cause generateExportCharts() to be called
            updateExportMetadata({
                sessionId: exportMetadata.sessionId,
                images: exportMetadata.images,
                active: true,
                init: false,
                create: true
            });
        },

        [exportMetadata, siteId, settings, renderedCharts, updateDashboardData, updateExportMetadata]
    );

    useEffect(() => {
        generateUpdateData();
    }, [generateUpdateData]);

    const generateInit = async () => {

        if (settings.report == 33) {
            ciModule.createDashboardReportJob({
                siteId: siteId,
                sessionId: helpers.newGuid(),
                settings: settings,
                title: title
            });

            successToast(translate('successfully_submitted_report'));
        }
        else {
            setGenerating(true);
            
            // this update will cause generateUpdateData() to be called
            updateExportMetadata({
                sessionId: helpers.newGuid(),
                images: [],
                active: true,
                init: true,
                create: false
            });
        }
    }

    return <>
        <Loader loaded={loaded && !generating} />
        { !_.isObject(settings) || !visible ? '' :
            <div className='app-ci-dashboards'>
                <div className='app-ci-dashboards-report-header'>
                    <div className='app-ci-dashboards-title'>{translate('report_settings')}</div>
                </div>
                <div className='app-ci-dashboards-input'>
                    <div className='app-ci-dashboards-report-labels'>{translate('report')}</div>
                    <div className='app-ci-dashboards-report-options'>
                        <DropDown
                            items={settings.reportList} 
                            height={'40px'} 
                            display={'name'} 
                            valueProperty={'id'} 
                            selected={settings.report}
                            onChange={(o) => { updateSettings({ ...settings, report: o.value}); }} 
                        />
                    </div>
                </div>
                <div className='app-ci-dashboards-input'>
                    <div className='app-ci-dashboards-report-labels'>{translate('title')}</div>
                    <div className='app-ci-dashboards-report-options'>
                        <TextBox value={title} height={40} disabled={true} />								
                    </div>
                </div>
                <div className='app-ci-dashboards-input'>
                    <div className='app-ci-dashboards-report-labels'>{translate('channels')}</div>
                    <div className='app-ci-dashboards-report-options'>
                        <DropDown
                            items={settings.channelList} 
                            height={'40px'} 
                            display={'name'} 
                            valueProperty={'id'} 
                            selected={settings.channel.id}  // TBD: needs to have all channels selected (need dropdown with multiple selection mode)
                            disabled={true}
                        />
                    </div>
                </div>
                <div className='app-ci-dashboards-input'>
                    <div className='app-ci-dashboards-report-labels'>{translate('rank_market')}</div>
                    <div className='app-ci-dashboards-report-options'>
                        <DropDown
                            items={settings.rankGeoLevels} 
                            height={'40px'} 
                            display={'name'} 
                            valueProperty={'id'} 
                            selected={settings.rankType === constants.competitiveInsights.marketType.site ? settings.rankGeoLevels[0].id : settings.rankTypeGeoLevel.id}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className='app-ci-dashboards-input'>
                    <div className='app-ci-dashboards-report-labels'>{translate('market_share_market')}</div>
                    <div className='app-ci-dashboards-report-options'>
                        <DropDown
                            items={settings.marketGeoLevels} 
                            height={'40px'} 
                            display={'name'} 
                            valueProperty={'id'} 
                            selected={settings.marketType === constants.competitiveInsights.marketType.site ? settings.marketGeoLevels[0].id : settings.marketTypeGeoLevel.id}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className='app-ci-dashboards-input'>
                    <div className='app-ci-dashboards-report-labels'>{translate('multi_site_list')}</div>
                    <div className='app-ci-dashboards-report-options'>
                        <DropDown
                            items={settings.multiSiteList} 
                            height={'40px'} 
                            display={'name'} 
                            valueProperty={'id'} 
                            selected={settings.multiSiteList[0].id}     // TBD: needs to have all sites selected (need dropdown with multiple selection mode)
                            disabled={true}
                        />
                    </div>
                </div>
                <div className='app-ci-dashboards-input'>
                    <div className='app-ci-dashboards-report-labels'>{translate('trade_area_percentage')}</div>
                    <div className='app-ci-dashboards-report-options'>
                        <DropDown
                            items={percents} 
                            height={'40px'} 
                            display={'text'} 
                            valueProperty={'id'} 
                            selected={percents.find((p) => p.value == settings.tradeAreaPercentage).id}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className='app-ci-dashboards-input'>
                    <div className='app-ci-dashboards-report-labels'>{translate('customer_profile_report')}</div>
                    <div className='app-ci-dashboards-report-options'>
                        <DropDown
                            items={reports} 
                            height={'40px'} 
                            display={'text'} 
                            valueProperty={'id'} 
                            selected={settings.demographicReportId.toString()}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className='app-ci-dashboards-generate'>
                    <Button theme='primary' size='small' icon={icons.check} tooltip={translate('generate')} onClick={()=>{ generateInit(); }} />
                </div>
            </div>
        }       
    </>
}