// React imports
import React, { useState } from 'react';

import { Hideable } from "../../../base/hideable/hideable";

export var mapLocator;

export const MapLocator = () => {

    const [timeoutHandle, setTimeoutHandle] = useState(null);
    const [hide, setHide] = useState(true);
    const [pixel, setPixel] = useState({ x: -1, y: -1 });

    mapLocator = {
        show: (o) =>{          
            setHide(false);
            setPixel({ x: o.pixel.x - 53, y: o.pixel.y - o.yOffset - 53});
            
            if (timeoutHandle)
                window.clearTimeout(timeoutHandle)
            
            setTimeoutHandle(setTimeout(()=>{
                setHide(true);
                setPixel({ x: -1, y: -1 }); 
            }, 1500));
        }
    }

    return <Hideable className='app-map-locator' hide={hide} style={{top: pixel.y + 'px', left: pixel.x + 'px' }} />
}