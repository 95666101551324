// App imports
import FileManager from '../../../base/fileManager/fileManager';
import {LargePanel} from '../../../base/largePanel/largePanel';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { Description } from '../../../base/description/description';
import { MenuButton } from '../../../base/menuButton/menuButton';

export function AdHocManager({text, setPanelContents, onClose}) {   
    return <LargePanel 
        children={<FileManager setPanelContents={setPanelContents}></FileManager>} 
        tools={
            <div className='app-ad-hoc-manager-header'>
                <Description description={translate('ad_hoc_manager_description_text')}></Description>
                <MenuButton icon={icons.plus} 
                    tooltip={translate('create_new_data_source')} 
                    theme='secondary' 
                    size='medium'
                    items={[
                        {icon: icons.plus, text: translate("create_point_data_source"), onClick: () =>{ setPanelContents({ panelId: 4, additionalData: {isSpatial: false} });}},
                        {icon: icons.plus,  text: translate("create_shape_data_source"), onClick: () =>{ setPanelContents({ panelId: 4, additionalData: {isSpatial: true} }); }}
                    ]}
                />
                <MenuButton 
                    icon={icons.upload}
                    tooltip={translate('upload_new_data_source')} 
                    theme='secondary' 
                    size='medium'
                    items={[
                        {icon: icons.upload, text: translate("import_point_data_source"), onClick: () =>{ setPanelContents({ panelId: 5, additionalData: {isSpatial: false}}); }},
                        {icon: icons.upload, text: translate("import_shape_data_source"), onClick: () =>{ setPanelContents({ panelId: 8}); }}
                    ]}
                />
            </div>
        } 
        text={text} 
        loaded={true} 
        generating={false} 
        onClose={() =>{ onClose(); }}>
    </LargePanel>
}