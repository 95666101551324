import { useEffect, useState } from 'react'

// App imports
import { Icon, icons } from '../icon/icon';
import { Clickable } from '../clickable/clickable';
import { Hideable } from '../hideable/hideable';

export function Button({className, active, disabled, notLicensed, comingSoon, image, children, hovered, id, icon, spinIcon, spinIconSpeed, text, tooltip, size, theme, onClick, showDropDown, showExpander, selected}) {

	const [activeState, setActiveState] = useState(active);
	const [iconState, setIconState] = useState(icon);
	const [classState, setClassState] = useState(className);

	useEffect(() =>{
		setIconState(icon);
	}, [icon]);

	useEffect(() =>{
		setClassState(className);
	}, [className]);

	useEffect(() =>{
		setActiveState(active);
	}, [active]);

	let classes = ['app-button-container'];
	var buttonClasses = ['app-button'];

	if (classState)
		classes.push(classState);

	if (size)
		classes.push('app-button-' + size);

	if (theme)
		classes.push('app-button-' + theme);

	if (showDropDown)
		buttonClasses.push('app-button-show-drop-down');

	if (showExpander)
		buttonClasses.push('app-button-show-expander');

	return <div id={id} className={classes.join(' ')}>
		{theme==='navigation' || theme==='header' || theme==='tab' ? <Hideable className='app-button-active-element' hide={!activeState} />	: ''}
		<Clickable				
				className={buttonClasses.join(' ')}
				hovered={hovered}
				notLicensed={notLicensed}
				comingSoon={comingSoon}
				disabled={disabled}
				active={activeState}
				tooltip={tooltip}
				onClick={(e) => {
					if (onClick)
						onClick(e, {
							setIcon: ({ icon }) =>{
								setIconState(icon);
							},
							setActive: ({ active }) =>{
								setActiveState(active);
							},
							setClass: ({ className }) =>{
								setClassState(className);
							}
						});
				}}
			>
				{image ? <img className='app-button-image' src={image} alt={text ? text : ''} /> : ''}
				{iconState ? <Icon className='app-button-icon' icon={iconState} spin={spinIcon} spinSpeed={spinIconSpeed} /> : ''}				
				{(size === 'small' && iconState) || !text || theme==='navigation' ? '' : <div className={iconState || image ? 'app-button-text' : ''}>{text}</div>}
				{showDropDown ? <Icon icon={icons.caretDown} /> : ''}
				{showExpander ? <Icon className='app-button-expander' icon={icons.rightArrow} /> : ''}
				{selected ? <Icon className='app-button-selected' icon={icons.check} /> : ''}
				{children}
		</Clickable>
		{theme==='navigation' ? <div className='app-button-sub-text'>{text}</div> : ''}
	</div>
}