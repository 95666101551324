// React imports
import { useState } from 'react';

// App imports
import { LargePanel } from "../../../../base/largePanel/largePanel";
import { Uploader } from '../../../../base/uploader/uploader';
import { adHocManagement as adHocModule } from '../../../../../modules/adHocManagement';
import { TextBox } from '../../../../base/textBox/textBox';
import { translate } from '../../../../../utils/translation';
import { DropDown } from '../../../../base/dropDown/dropDown';
import { TextArea } from '../../../../base/textArea/textArea';
import { constants } from '../../../../../utils/constants';

const _ = require("lodash");

export function AdHocSpatialFileImport({onClose, onComplete, text}) {

    const [isLoaded, setIsLoaded] = useState(true);
    const [jobName, setJobName] = useState(null);
    const [jobDescription, setJobDescription] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(4);
    const [nullText, setNullText] = useState(null);
    const [importType, setImportType] = useState(constants.dataSetType.KML);
    const [allowedExtensions, setAllowedExtensions] = useState(["kml"]);
    const [allowedTypesMessage, setAllowedTypesMessage] = useState();

    const onQueuePublish = async (queueReponse) => {
        setIsLoaded(false);

        var fileIdList = [];
        _.each(queueReponse.items, function(item){
            fileIdList.push(item.resultId);
        })

        var response = await adHocModule.importSpatialAdHocDataSource({
            name: jobName,
            description: jobDescription,
            nullText: nullText,
            zoomLevel: zoomLevel,
            fileIdList: fileIdList
        });

        setIsLoaded(true);
        onComplete(response);
    }
    
    const uploadRequirements = (files) => {
        //Auto fail if no files are uploaded
        if (files.length < 1)
            return false;

        //Require Name
        if (jobName == null || jobName.length < 1)
            return false;

        //Require Description
        if (jobDescription == null || jobDescription.length < 1)
            return false;

        //Require Zoom Level between 2 and 20
        if (zoomLevel < 2 || zoomLevel > 20)
            return false;

        //Require Null Text
        if (nullText == null || nullText.length < 1)
            return false;

        //Validate proper files for specific file upload types
        
        /*
        TODO use file.type instead of file.name
        the uploader component fails to properly find the file.type for certain types
        current implementation allows any file as long as it has the proper extension
        */

        switch(importType){
            case constants.dataSetType.KML:
                if (files.length !== 1)
                    return false;
                if (!_.some(files, (file) => {return file.name.toLowerCase().endsWith("kml")})) 
                    return false;
                break;
            case constants.dataSetType.KMZ:
                if (files.length !== 1)
                    return false;
                if (!_.some(files, (file) => {return file.name.toLowerCase().endsWith("kmz")})) 
                    return false;
                break;
            case constants.dataSetType.SHAPE:  
            if (files.length !== 3)
                    return false;
                if (!_.some(files, (file) => {return file.name.toLowerCase().endsWith("shp")})) 
                    return false;
                if (!_.some(files, (file) => {return file.name.toLowerCase().endsWith("shx")})) 
                    return false;
                if (!_.some(files, (file) => {return file.name.toLowerCase().endsWith("dbf")})) 
                    return false;
                break;
            case constants.dataSetType.TAB:
                if (files.length !== 4)
                    return false;
                if (!_.some(files, (file) => {return file.name.toLowerCase().endsWith("tab")})) 
                    return false;
                if (!_.some(files, (file) => {return file.name.toLowerCase().endsWith("dat")})) 
                    return false;
                if (!_.some(files, (file) => {return file.name.toLowerCase().endsWith("map")})) 
                    return false;
                if (!_.some(files, (file) => {return file.name.toLowerCase().endsWith("id")})) 
                    return false;
                break;
            default:
                return false;
        }

        return true;
    }

    const onImportTypeChanged = (importType) => {
        switch(importType){
            case constants.dataSetType.KML:
                setAllowedExtensions(["kml"]);
                setAllowedTypesMessage(null);
                break;
            case constants.dataSetType.KMZ:
                setAllowedExtensions(["kmz"]);
                setAllowedTypesMessage(null);
                break;
            case constants.dataSetType.SHAPE:
                setAllowedExtensions(["shp", "shx", "dbf"]);    
                setAllowedTypesMessage(translate("shape_set_import"));
                break;
            case constants.dataSetType.TAB:
                setAllowedExtensions(["tab", "dat", "map","id"]);
                setAllowedTypesMessage(translate("tab_set_import"));
                break;
            default:
                break;
        }
    }

    return <LargePanel
        children={
            [
                <div className='app-ad-hoc-import-container'>
                    <table className='app-ad-hoc-spatial-import-table'>
                        <tbody>
                            <tr>
                                <td>
                                <TextBox className='app-ad-hoc-spatial-import-textbox' height='55px' label={translate('job_name')} placeholder={translate('job_name')}
                                    value={jobName}
                                    onChange={(o) => {setJobName(o.value)}} 
                                />
                                </td>
                                <td rowspan="3">
                                    <TextArea className='app-ad-hoc-spatial-import-textarea' minHeight='220px' maxHeight='220px' label={translate('description')} placeholder={translate("description")}
                                        value={jobDescription}
                                        onChange={(o) => {setJobDescription(o.value)}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextBox className='app-ad-hoc-spatial-import-textbox' height='55px' label={translate('null_text')}
                                        placeholder={translate("null_na")}
                                        value={nullText}
                                        onChange={(o) => {setNullText(o.value)}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <DropDown className='table app-ad-hoc-spatial-import-dropdown' height='55px' label={translate("zoom_level")} display={"name"} valueProperty={"key"} 
                                        items={
                                            [
                                                { key: 3, name: "3 (" + translate("country") + ")" },
                                                { key: 4, name: "4 (" + translate("country") + ")" },
                                                { key: 5, name: "5 (" + translate("country") + ")" },
                                                { key: 6, name: "6 (" + translate("region") + ")" },
                                                { key: 7, name: "7 (" + translate("region") + ")" },
                                                { key: 8, name: "8 (" + translate("region") + ")" },
                                                { key: 9, name: "9 (" + translate("region") + ")" },
                                                { key: 10, name: "10 (" + translate("region") + ")" },
                                                { key: 11, name: "11 (" + translate("city") + ")" },
                                                { key: 12, name: "12 (" + translate("city") + ")" },
                                                { key: 13, name: "13 (" + translate("city") + ")" },
                                                { key: 14, name: "14 (" + translate("city") + ")" },
                                                { key: 15, name: "15 (" + translate("city") + ")" },
                                                { key: 16, name: "16 (" + translate("street") + ")" },
                                                { key: 17, name: "17 (" + translate("street") + ")" },
                                                { key: 18, name: "18 (" + translate("street") + ")" },
                                                { key: 19, name: "19 (" + translate("street") + ")" },
                                                { key: 20, name: "20 (" + translate("street") + ")" }
                                            ]
                                        } selected={zoomLevel}
                                        onChange={(o) => {setZoomLevel(o.value)}} 
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <DropDown height='55px' label={translate("file_type")} display={"name"} valueProperty={"key"} 
                        items={
                            [
                                { key: constants.dataSetType.KML, name: "KML"},
                                { key: constants.dataSetType.KMZ, name: "KMZ"},
                                { key: constants.dataSetType.SHAPE, name: "SHP"},
                                { key: constants.dataSetType.TAB, name: "TAB"}
                            ]
                        } 
                        selected={importType}
                        onChange={(o) => {setImportType(o.value); onImportTypeChanged(o.value);}} 
                    />
                    <Uploader allowedExtensions = {allowedExtensions} allowedTypesMessage={allowedTypesMessage} onQueuePublish={(o) => onQueuePublish(o)} uploadRequirements={(o) => uploadRequirements(o)}/> 
                </div>
            ]  
        } 
        text={text}
        loaded={isLoaded} 
        generating={false} 
        onClose={() =>{ onClose(); }}>
    </LargePanel>
}
