// React imports
import { useState, useEffect } from 'react';

// 3rd party
import FileUploader from 'devextreme-react/file-uploader';

// App imports
import { translate } from '../../../utils/translation';
import { upload as uploadModule } from '../../../modules/upload';
import { Button } from '../button/button';
import { Loader } from '../loader/loader';
import { icons } from '../icon/icon';
import { helpers } from '../../../utils/helpers';

const _ = require("lodash");

export function Uploader({ allowedExtensions = [], dropZoneMessage, allowedTypesMessage, onQueuePublish, uploadRequirements}) {
    const [files, setFiles] = useState([]);
    const [uploadQueueId, setUploadQueueId] = useState(crypto.randomUUID());
    const [dzMessage, setDzMessage] = useState(translate("default_file_drop_zone_message"));
    const [typesMessage, setTypesMessage] = useState(translate("allowed_file_types") + "." + allowedExtensions.join(", ."));
    const [loaded, setLoaded] = useState(true);
    const [allowPublish, setAllowPublish] = useState(false);

    useEffect(() => {
        if (!_.isFunction(uploadRequirements))
            uploadRequirements = () => {return files.length > 0};

        if (!_.isEmpty(dropZoneMessage))
            setDzMessage(dropZoneMessage);

        if (!_.isEmpty(allowedTypesMessage))
            setTypesMessage(allowedTypesMessage);
        else
            setTypesMessage(translate("allowed_file_types") + "." + allowedExtensions.join(", ."));

        if (uploadRequirements(files))
            setAllowPublish(true);
        else
            setAllowPublish(false);
    }, [uploadRequirements, allowedExtensions, dropZoneMessage, allowedTypesMessage, files])

    const uploadFile = async (file) => {
        setLoaded(false);
        //Check file extensions
        var regExpresson = new RegExp(".(" + allowedExtensions.join("|") + ")$", "i");
        if (allowedExtensions.length > 0 && !(regExpresson).test(file.name)) {
            alert(translate('file_type_not_allowed'));
            setLoaded(true);
            return false;
        }

        //Check file Size
        if (file.size > 10000000) {
            alert(translate('file_size_too_large'));
            setLoaded(true);
            return false;
        }

        //Upload file chunk
        var tempFileId = await uploadModule.uploadFileChunk(file, {
            uploadQueueId: uploadQueueId,
            fileId: crypto.randomUUID()
        });

        
        //Add file to queue
        var queueFileId = await uploadModule.addFileToQueueFromTempDirectory({
            uploadQueueId: uploadQueueId,
            fileId: tempFileId,
            fileName: file.name,
            contentType: file.type
        });

        //Add file to state
        var uploadedFile = {
            id: queueFileId,
            tempFileId: tempFileId,
            name: file.name,
            type: file.type,
            size: file.size
        };
        
        files.push(uploadedFile);
        setFiles([...files]);
        setLoaded(true);

        return queueFileId;
    };

    const finish = async () => {
        var response = await uploadModule.publish({
            uploadQueueId: uploadQueueId,
            data: [],
        });

        if (onQueuePublish)
            onQueuePublish(response);
    }

    const deleteFile = async (file) => {
        uploadModule.deleteFileFromQueue({
            queueFileId: file.id,
            tempFileId: file.tempFileId,
            uploadQueueId: uploadQueueId 
        });

        setFiles(files.filter(x => x !== file));
    }

    return <div className="app-uploader-container">
        <Loader loaded={loaded} theme='generate' showLogo={true}></Loader>
        <div className="app-uploader-help-container">
            {typesMessage}
        </div>
        <div className="app-uploader-file-region">
            {
                files.map((file) => {
                    return <div key={file.id} className="app-uploader-file">
                        <table className="app-uploader-file-table">
                            <tbody>
                                <tr>
                                    <td>
                                        <div>{file.name}</div>
                                    </td>
                                    <td>
                                        <div>{helpers.convertFileSize(file.size)}</div>
                                    </td>
                                    <td>
                                        <div className="app-uploader-file-button-container">
                                            <Button size='small' icon={icons.trash} onClick={() => { deleteFile(file) }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                })
            }
            <div id="uploader-drop-zone" className="app-uploader-drop-zone">
                {dzMessage}
            </div>
        </div>
        <div className="app-uploader-button-container">
            <Button disabled={!allowPublish} theme='primary' size='large' icon={icons.upload} tooltip={translate('import')} onClick={() => { finish() }} />
        </div>
        <FileUploader
            id="file-uploader"
            multiple={true}
            accept={'*'}
            uploadMode={'instantly'}
            uploadFile={uploadFile}
            dialogTrigger="#uploader-drop-zone"
            dropZone="#uploader-drop-zone"
            visible={false}
        />
    </div>
}