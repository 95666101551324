import { legacyEndpoints } from '../services/legacyEndpoints';
import { helpers } from '../utils/helpers';
import { translate } from '../utils/translation';
import { constants } from '../utils/constants';

const _ = require("lodash");

export const mapBooks = {
    getMapBooksForUser: async () => {  
        return await legacyEndpoints.service({ name: 'GetMapBooksForUser' });
	},
    getFilteredMapBooksForUser: async (o) => {
        return await legacyEndpoints.service(
            {
                name: "GetFilteredMapBooksForUser",
                parameters: {
                    TypeFilter: o.typeFilter,
                    Columns: o.columns,
                    Page: o.page,
                    ResultCount: o.resultCount,
                    Filter: o.filter,
                    SortColumn: o.sortColumn,
                    SortDirection: o.sortDirection
               }
            }
        )
    },
    getMapBookForUser: async (o) => {
        return await legacyEndpoints.service(
            {
                name: "GetMapBookForUser",
                parameters: {
                    Id: o.id
                }
            }
        );
    },
    getMapBookContentTypesForUser: async () => {
        return await legacyEndpoints.service({ name: 'GetMapBookContentTypesForUser' });
    },
    getMapBookJobPages: async (o) => {
        return await legacyEndpoints.service(
            {
                name: "GetMapBookJobPages",
                parameters: {
                    Id: o.id,
                    Type: o.type
               }
            }
        )
    },
    addMapBookJob: async (o) => {
        var pages = await mapBooks.getMapBookJobPages({id: o.id, type: o.type});

        var response = await legacyEndpoints.service(
            {
                name: "AddMapBookJob",
                parameters: {
                    Id: o.id,
                    Name: o.name,
                    Bookmark: "",
                    CustomQueryId: o.customQueryId,
                    ExecutionType: o.executionType,
                    OutputFormat: o.outputFormat,
                    Pages: pages,
                    Points: o.points,
                    ShowBookMark: false,
                    Type: o.type
               }
            }
        )

        if(_.isFunction(o.callBack)){
            o.callBack(response);
        }
    },
    isMapBookValidForUser: async (o) => {
        return await legacyEndpoints.service(
            {
                name: "IsMapBookValidForUser",
                parameters: {
                    Id: o.id,
                    Name: o.name
               }
            }
        )
    },
    updateMapBookForUser: async ({mapBook}) => {
        return await legacyEndpoints.service(
            {
                name: "UpdateMapBookForUser",
                parameters: {
                    MapBook: mapBook
               }
            }
        )
    },
    deleteMapBookForUser: async ({id}) => {
        return await legacyEndpoints.service(
            {
                name: "DeleteMapBookForUser",
                parameters: {
                    Id: id
               }
            }
        )
    },
    getNewTradeAreaLibraryForMapBook: (tradeAreas = null) => {
        return {
            allowShapeDelete: false,
            allowShapeEdit: false,
            allowShapeView: false,
            center: {
                lat: 0,
                lon: 0,
                tag: null
            },
            customQueryId: null,
            dataSourceGUID: null,
            dataSourceId: null,
            generated: true,
            id: helpers.newGuid(),
            isVisible: false,
            name: "",
            pointId: null,
            tradeAreaTemplateId: helpers.emptyGuid(),
            tradeAreas: _.isNull(tradeAreas) ? null : tradeAreas,
            zIndex: 0,
            zoomToLayer: false
        }
    },
    getNewMapBook: () => {
        return {
            id: helpers.newGuid(),
            name: translate("new_map_book"),
            description: null,
            masterBookmark: {
                Id: helpers.newGuid(),
                Text: translate('new_bookmark'),
                Words: []
            },
            pages: [],
            tradeAreaLibrary: mapBooks.getNewTradeAreaLibraryForMapBook(),
            errors: {
                errorPages: [],
                errors: []
            }
        }
    },
    getNewMapBookPage: (sequence) => {
        return {
            adHocMap: null,
            bookmark: null,
            demographicReportId: null,
            demographicReportName: null,
            pointDetailReport: null,
            savedMapId: null,
            savedMapName: null,
            sequence: _.isInteger(sequence) ? sequence : 1,
            tradeAreaLibrary: null,
            tradeAreaLibraryType: constants.mapBooks.mapBookTradeAreaType.default,
            type: constants.mapBooks.mapBookPageType.adHocMap
        }
    },
    getErrorObjectTypeName: (type) =>{
        switch (type)
        {
            default:
                return '';
            case constants.mapBooks.validation.objectTypes.driveTimeEngine:
                    return  `${translate('trade_area_library')} ${translate('drive_time')}`; //todo drive_time_engine
        }        
    },     
    getPageErrorObjectName: (type) =>{

        switch (type)
        {
            default:
            case constants.mapBooks.validation.objectTypes.demographicReport:
                    return translate('demographic_report');
            case constants.mapBooks.validation.objectTypes.pointDetailReport:
                return translate('point_detail_report')
            case constants.mapBooks.validation.objectTypes.savedMap:
                return translate('saved_map');
            case constants.mapBooks.validation.objectTypes.fixedSavedMap:
                return translate('fixed_saved_map');
            case constants.mapBooks.validation.objectTypes.demographicQuadrant:
                return translate('demographic_quadrant_report');
            case constants.mapBooks.validation.objectTypes.pointSource:
                return translate('point_source');
            case constants.mapBooks.validation.objectTypes.rangeThematic:
                return translate('range_thematic');
            case constants.mapBooks.validation.objectTypes.dotDensityThematic:
                return translate('dot_density_thematic');
            case constants.mapBooks.validation.objectTypes.polygonThematic:
                return translate('polygon_thematic');
            case constants.mapBooks.validation.objectTypes.driveTimeEngine:
                return translate('trade_area_library') + translate('drive_time_engine');
        }        
    },    
    getErrorCodeName: (type) =>{

        switch (type)
        {
            default:
            case constants.mapBooks.validation.errorCodes.doesNotExistOrNoAccess:
                    return translate('object_not_exist');
        }        
    },    
    getMapBookOutputFormats: (type) =>{

        return [{key: constants.mapBooks.outputFormat.onePDF, name: translate('one_pdf_all_points')},
        {key: constants.mapBooks.outputFormat.multiplePDFs, name: translate('one_pdf_per_point')}]; 
    },           
}
