import { useCallback, useRef, useState, useEffect, createRef } from 'react';

// Third party imports
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, {Selection, FilterRow} from 'devextreme-react/data-grid';

const _ = require("lodash");

export function GridDropDown({className, items, columns, keyProperty, valueProperty, gridHeight, dropDownHeight, placeholder, onSelect, selectedItems}) {
    const gridBoxRef = useRef(createRef());
    const [gridBoxRows, setGridBoxRows] = useState([]);

    var classes = ['app-drop-down'];

    if (className)
        classes.push(className);

    useEffect(()=>{
		setGridBoxRows(selectedItems);
    },[selectedItems]);

    const dataGridOnSelectionChanged = useCallback((e) => {
        setGridBoxRows((e.selectedRowKeys.length && e.selectedRowKeys) || []);

        if (onSelect)
            onSelect({
                value: e.selectedRowKeys
            });

      }, [onSelect]);

    const syncDataGridSelection = useCallback((e) => {
        setGridBoxRows(e.value || []);
      }, []);

    const dataGridRender = useCallback(
        () => (
          <DataGrid
            ref={gridBoxRef}
            height={gridHeight ? gridHeight : '100%'}         
            keyExpr={keyProperty}
            dataSource={items}
            columns={columns}
            hoverStateEnabled={true}
            selectedRowKeys={gridBoxRows}
            onSelectionChanged={dataGridOnSelectionChanged}
          >
            <Selection mode="multiple" />
            <FilterRow visible={true} />
          </DataGrid>
        ),
        [items, columns, keyProperty, gridBoxRows, dataGridOnSelectionChanged],
    );
    
    
    return <div className={classes.join(' ')}> 
        <DropDownBox
            height={dropDownHeight}
            value={gridBoxRows}
            valueExpr={keyProperty}
            displayExpr={valueProperty}
            deferRendering={false}
            placeholder={placeholder}
            showClearButton={true}
            dataSource={items}
            contentRender={dataGridRender}
            onValueChanged={syncDataGridSelection}
        />  
    </div>
}
