import { useState } from 'react';
// Third party imports
import {TextBox as DxTextBox, Button as TextBoxButton} from 'devextreme-react/text-box';
import {NumberBox as DxNumberBox} from 'devextreme-react/number-box';
import { iconHelpers } from '../icon/icon';
import {
    Validator,
    RequiredRule,
    CompareRule,
    EmailRule,
    PatternRule,
    StringLengthRule,
    RangeRule,
    AsyncRule,
    CustomRule,
  } from 'devextreme-react/validator';
  
export const inputBoxTypes = {
    text: 0,
    numeric: 1,
    currency: 2,
    percent: 3    
};

export const validatorTypes = {
    requiredRule: 0,
    compareRule: 1,
    emailRule: 2,
    patternRule: 3,
    stringLengthRule: 4,
    rangeRule: 5,
    asyncRule: 6,
    customRule: 7
};

const _ = require("lodash");
const s = require("underscore.string");

export function TextBox({className, label, value, name, placeholder, actions, validator, type = inputBoxTypes.text, mode = 'text', disabled=false, height='30px', maxLength=null, onChange=()=>{}, valueChangeEvent,
    allowNegative=true, allowDecimals=true, allowCommas=true, allowNull=true, readOnly=false, visible=true, elementAttr={}, inputAttr={}, storedAsDecimal = false, maxDecimals = 2, maxDigits = 16 }) {

    const [allowLeadingZeros, setAllowLeadingZeros] = useState(true);

    var classes = ['app-text-box'];

    if (className)
        classes.push(className);    

    const getValue = (e) =>{
                
        if ((e.value === null && allowNull === false) || e.value === e.previousValue)
            return

        if (onChange)
            onChange({
                value: e.value,
                name: name,
                userChanged: e.event !== undefined
            });
    };

    const getActions = () => {
        return actions?.map((action,i) => {
            action.type = 'textBoxButton';

            if (_.isObject(action.icon))
                action.icon = iconHelpers.toSVG(action.icon);

            return <TextBoxButton key={i} name={crypto.randomUUID()} location='after' options={action} />
        });
    };

    const getValidator = () => {
        if (_.isObject(validator))
        {
            var rules = validator.rules?.map((rule,i) => {
                rule.message = s.stripTags(rule.message);

                switch(rule.type)
                {
                    case validatorTypes.requiredRule:
                        return <RequiredRule type='required' key={i} message={rule.message} trim={rule.trim ?? true} />
                    case validatorTypes.compareRule:
                        return <CompareRule type='compare' key={i} message={rule.message} comparisonTarget={rule.comparisonTarget} comparisonType={rule.comparisonType ?? '==' } ignoreEmptyValue={rule.ignoreEmptyValue ?? false} />
                    case validatorTypes.emailRule:
                        return <EmailRule type='email' key={i} message={rule.message} ignoreEmptyValue={rule.ignoreEmptyValue ?? false} />
                    case validatorTypes.patternRule:
                        return <PatternRule type='pattern' key={i} message={rule.message} pattern={rule.pattern} ignoreEmptyValue={rule.ignoreEmptyValue ?? false} />
                    case validatorTypes.stringLengthRule:
                        return <StringLengthRule type='stringLength' key={i} message={rule.message} min={rule.min} max={rule.max} trim={rule.trim ?? true} ignoreEmptyValue={rule.ignoreEmptyValue ?? false} />
                    case validatorTypes.rangeRule:
                        return <RangeRule type='range' key={i} message={rule.message} min={rule.min} max={rule.max} ignoreEmptyValue={rule.ignoreEmptyValue ?? false} reevaluate={rule.reevaluate ?? false} />
                    case validatorTypes.asyncRule:
                        return <AsyncRule type='async' key={i} message={rule.message} validationCallback={rule.callback} ignoreEmptyValue={rule.ignoreEmptyValue ?? false} reevaluate={rule.reevaluate ?? false} />
                    case validatorTypes.customRule:
                        return <CustomRule type='custom' key={i} message={rule.message} validationCallback={rule.callback} ignoreEmptyValue={rule.ignoreEmptyValue ?? false} reevaluate={rule.reevaluate ?? false} />
                    default:
                        break;
                }
            });
    
            if (rules)
                return <Validator ref={validator.ref} onValidated={validator.onValidated}>{rules}</Validator>;
        }
    };

    const getNumberValue = (value) =>{
        value = parseFloat(value);
        return _.isNaN(value) ? null : value;
    };

    const getFormatString = () =>{
        let format = allowLeadingZeros ? '0' : '#';
        const decimals = '#'.repeat(maxDecimals);
        
        format = allowCommas ? '#,##' + format : format;
        if (allowDecimals && maxDecimals > 0) {
            format += `.${decimals}`;
        }

        switch(type){
            case inputBoxTypes.currency:
                format = "$" + format;
                break;
            case inputBoxTypes.percent:
                storedAsDecimal ? format += '%' : format += `'%'`;
                break;
        }

        return format;
    }

    const renderComponent = () => {
        switch(type){
            default:
            case inputBoxTypes.text:
                return <DxTextBox 
                    elementAttr={elementAttr}
                    inputAttr={inputAttr}
                    label={label} 
                    value={value?.toString()}
                    placeholder={placeholder}
                    mode={mode}
                    disabled={disabled}
                    readOnly={readOnly}
                    visible={visible}
                    stylingMode='outlined'
                    height={height}
                    maxLength={maxLength} 
                    onValueChanged={getValue}
                    valueChangeEvent={valueChangeEvent? valueChangeEvent : 'change'}>
                    {getActions()}
                    {getValidator()}
                </DxTextBox>
            case inputBoxTypes.numeric:
            case inputBoxTypes.currency:
            case inputBoxTypes.percent:
                let format = getFormatString();
                                
                return <DxNumberBox
                    elementAttr={elementAttr}
                    inputAttr={inputAttr}
                    min={allowNegative ? null : 0}
                    label={label} 
                    value={getNumberValue(value)}
                    disabled={disabled}
                    readOnly={readOnly}
                    visible={visible}
                    stylingMode='outlined'
                    height={height}
                    maxLength={maxLength}
                    format={format}
                    valueChangeEvent={valueChangeEvent? valueChangeEvent : 'change'}
                    onValueChanged={getValue}
                    onKeyDown={(e) => {
                        const inputElement = e.event.target;
                        const cursorPosition = inputElement.selectionStart;
                        const currentValue = inputElement.value;

                        if (e.event.key === 'Backspace' || e.event.key === 'Delete') {
                            

                            const newValue = currentValue.slice(0, cursorPosition - 1) + currentValue.slice(cursorPosition);
                            if (newValue === '' || newValue === '$' || newValue === '%') {
                                getValue({ value: null, event: e.event });
                                setAllowLeadingZeros(false);
                            }
                        }
                        else if (e.event.key === '0') {
                            const newValue = currentValue.slice(0, cursorPosition) + '0' + currentValue.slice(cursorPosition);
                            if (parseFloat(newValue) === 0) {
                                setAllowLeadingZeros(true);
                            }
                        }
                    }}>
                        
                    {getActions()}
                    {getValidator()}
                </DxNumberBox>
        }
    };

    return <div className={classes.join(' ')}>{renderComponent()}</div>    
}