var _ = require("lodash");

export const drivingDirections = {

    getDirections: (props) => {      

        window.Microsoft.Maps.loadModule('Microsoft.Maps.Directions', function () {
          var directionsManager = new window.Microsoft.Maps.Directions.DirectionsManager(props.map);
          directionsManager.clearDisplay();
          directionsManager.setRequestOptions({ routeMode: window.Microsoft.Maps.Directions.RouteMode.driving });

          _.sortBy(props.destinations, o => o.id).forEach((destination, i) => {
            directionsManager.addWaypoint(new window.Microsoft.Maps.Directions.Waypoint({ address: destination.value }))         
          });          
          
          directionsManager.setRenderOptions({ itineraryContainer: props.panel });
          directionsManager.calculateDirections();
      });
    }
};
